import { getDiseaseSynonyms } from 'apiServices/Diseases/diseases';
import { ReactComponent as FingerprintSVG } from 'assets/UI/Fingerprint.svg';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import {
  ExploreLayoutInnerContent,
  ExploreLayoutSelectTitle,
} from 'Atoms/explore/ExploreLayoutContent';
import { Icon } from 'Atoms/Icon';
import { Label } from 'Atoms/Label';
import { H1, H2 } from 'Atoms/text';
import { LandingMap } from 'Explore/Organisms/LandingMap';
import { TrendingNow } from 'Explore/Organisms/TrendingNow';
import { MapMainLayout } from 'layouts/MapMainLayout';
import { AsyncSelect } from 'Molecules/select/AsyncSelect';
import { FingerprintModal } from 'Organisms/FingerprintModal';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useMobile } from 'services/useMobile';
import { useSelectSynonyms } from 'services/useSelectSynonyms.hook';
import { useDiseasesResource } from 'store/diseasesStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';

const MainLayoutStyled = styled(MapMainLayout)`
  min-height: 800px;
  position: relative;
`;

const StyledTitle = styled(H1)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 70px;
  z-index: 2;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    top: 20px;
  }
`;

const StyledSubTitle = styled(H2)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 120px;
  z-index: 2;
  line-height: 18px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    top: 90px;
  }
`;

const TrendingNowStyled = styled(TrendingNow)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 210px;
  z-index: 2;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    top: 165px;
  }
`;

const ContentContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 280px;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 0;
    top: 205px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 400px;
  min-width: 280px;

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    margin: 30px 50px;
  }
`;

const LabelStyled = styled(Label)`
  margin: 20px 0 0 0;
`;

const FingerprintIcon = styled(Icon)`
  width: 50px;
  height: 50px;
  fill: ${props => props.theme.colors.text.inactive};
  cursor: pointer;
  &:hover {
    fill: ${props => props.theme.colors.text.lightLink};
  }
`;

const SelectStyled = styled(AsyncSelect<SelectOption, false>())`
  max-height: 44px;

  justify-self: center;
  align-self: end;

  margin-top: 20px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .${props => props.classNamePrefix}__control {
      padding: 4px 5px 4px 9px;
    }
    .${props => props.classNamePrefix}__search-icon {
      margin-right: 8px;
    }
  }
`;

export const Diseases: FC = () => {
  const [isFingerprintOpen, setFingerprintOpen] = useState<boolean>(false);
  const diseasesState = useDiseasesResource();
  const isMobile = useMobile('s');

  assertIsNotStoreError(diseasesState);

  const selectOptions = !isLoading(diseasesState)
    ? diseasesState.list.map(id => ({
        value: id.toString(),
        label: diseasesState.data[id].name,
        isSynonym: false,
      }))
    : [];

  const loadSynonymOptions = async (inputValue: string): Promise<SelectOption[]> => {
    const synonyms = await getDiseaseSynonyms(inputValue);
    return !isLoading(diseasesState)
      ? synonyms.map(x => ({
          label: x.name,
          value: x.id.toString(),
          synonymOf: diseasesState.data[x.id].name,
        }))
      : [];
  };

  const { onChange, loadOptions } = useSelectSynonyms(
    'diseases',
    selectOptions,
    loadSynonymOptions
  );

  return (
    <MainLayoutStyled>
      <Helmet>
        <title>Explore diseases - GIDEON</title>
        <meta
          name="description"
          content="Explore information on 361 infectious diseases, discover diseases by their fingerprint, view 23,500+ country notes and nearly 26,000 outbreak records."
        />
      </Helmet>
      <StyledTitle color="exploreLandingTitle" font="Quicksand">
        Explore GIDEON data on diseases
      </StyledTitle>
      <StyledSubTitle size="smallRegular" font="Inter">
        Includes bacterial, fungal, parasitic, prion and viral infections
      </StyledSubTitle>
      <TrendingNowStyled section="diseases" />
      <ContentContainer>
        <InnerContainer role="group" aria-labelledby="explore-vaccines-title">
          <ExploreLayoutSelectTitle id="explore-title" />
          <LabelStyled size="small" color="main" htmlFor="explore-select-input">
            Filter and search across all disease records
          </LabelStyled>
          <SelectStyled
            classNamePrefix="explore-layout-select"
            placeholder={isMobile ? 'Enter disease name' : 'Start typing disease name'}
            loadOptions={loadOptions}
            onChange={onChange}
            showIcon
            cacheOptions
            defaultOptions={selectOptions}
            showDropdown
            openMenuOnClick={false}
            id="explore-select"
            inputId="explore-select-input-diseases" //Unique ID for Cypress testing
          />
        </InnerContainer>
        <ExploreLayoutInnerContent
          optionTitle="Search by fingerprint"
          tooltipContent="A fingerprint is an epidemiological attribute shared by a group of diseases - a type of agent, a reservoir in nature, vector and vehicle."
        >
          <InvisibleButton onClick={() => setFingerprintOpen(true)} aria-label="Open fingerprint">
            <FingerprintIcon svgComponent={FingerprintSVG} />
          </InvisibleButton>
          <FingerprintModal isOpen={isFingerprintOpen} onClose={() => setFingerprintOpen(false)} />
        </ExploreLayoutInnerContent>
      </ContentContainer>
      <LandingMap section="diseases" />
    </MainLayoutStyled>
  );
};
