import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as Icon } from '../assets/ErrorPage/Image.svg';

const IconStyled = styled(Icon)`
  width: 100%;
  height: 100%;
`;

interface ErrorPageImageProps {
  className?: string;
}

export const ErrorPageImage: FC<ErrorPageImageProps> = ({ className }) => {
  return (
    <div className={className}>
      <IconStyled />
    </div>
  );
};
