import { getVaccineDetails, getVaccineDetailsPublic } from 'apiServices/Vaccines/vaccines';
import React, { useEffect } from 'react';
import { newStoreError } from 'store/storeError';
import { Dispatch, Loading, Resource } from 'store/types';
import { VaccineDetails, VaccineDetailsPublic } from 'types/vaccine';

import {
  Action,
  State,
  VaccineDetailsDispatchContext,
  VaccineDetailsStateContext,
} from './provider';

export const useState = (): State => {
  const state = React.useContext(VaccineDetailsStateContext);
  if (state === undefined) {
    throw new Error('Vaccine details store is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(VaccineDetailsDispatchContext);
  if (dispatch === undefined) {
    throw new Error('Vaccine details store is not initialized');
  }
  return dispatch;
};

export const useVaccineDetails = (id: number): Resource<VaccineDetails> => {
  const state = useState();
  const dispatch = useDispatch();
  const vaccineDetails = state.vaccineDetails[id];

  useEffect(() => {
    if (!vaccineDetails) {
      dispatch({ type: 'VaccineDetails/SingleLoadInitiated', payload: { id } });
      getVaccineDetails(id)
        .then(data => dispatch({ type: 'VaccineDetails/SingleLoaded', payload: { id, data } }))
        .catch(err => {
          dispatch({
            type: 'VaccineDetails/SingleLoadFailed',
            payload: { id, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, id, vaccineDetails]);

  return vaccineDetails || Loading;
};

export const useVaccineDetailsPublic = (id: number): Resource<VaccineDetailsPublic> => {
  const state = useState();
  const dispatch = useDispatch();
  const vaccineDetails = state.vaccineDetailsPublic[id];

  useEffect(() => {
    if (!vaccineDetails) {
      dispatch({ type: 'VaccineDetails/Public/SingleLoadInitiated', payload: { id } });
      getVaccineDetailsPublic(id)
        .then(data =>
          dispatch({ type: 'VaccineDetails/Public/SingleLoaded', payload: { id, data } })
        )
        .catch(err => {
          dispatch({
            type: 'VaccineDetails/Public/SingleLoadFailed',
            payload: { id, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, id, vaccineDetails]);

  return vaccineDetails || Loading;
};
