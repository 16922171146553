import { useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

interface ResizeObserverEntry {
  readonly target: Element;
  readonly contentRect: DOMRectReadOnly;
}

interface Bounds {
  x: number;
  y: number;
  width: number;
  height: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
}

/**
 * useMeasure notifies whenever element changes its position, bounds or is resized.
 * @returns `ref` to attach to the element.
 * @returns `bounds` indicating element bounds and its position.
 */
export const useMeasure = <T extends HTMLElement>(): [(element: T) => void, Bounds] => {
  const ref = useRef<T>();

  const [bounds, setBounds] = useState<Bounds>({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    bottom: 0,
    right: 0,
  });
  const [resizeObserver] = useState(
    () => new ResizeObserver((entry: ResizeObserverEntry[]) => setBounds(entry[0].contentRect))
  );

  useEffect(() => {
    return () => resizeObserver.disconnect();
  }, [resizeObserver]);

  const actualRef = (element: T): void => {
    if (!element || ref.current === element) {
      return;
    }

    ref.current = element;
    resizeObserver.observe(ref.current);
  };

  return [actualRef, bounds];
};
