import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  MouseEvent,
  ReactNode,
  useCallback,
} from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { usePageName } from 'services/pageName.service';
import styled, { DefaultTheme } from 'styled-components/macro';
import { FontSizeCollection } from 'types/fontSizeCollection';

type Size = 'reference' | 'small' | 'normal' | 'big' | 'veryBig';
const sizes: FontSizeCollection<Size> = {
  reference: {
    desktop: '13px',
    mobile: '12px',
  },
  small: {
    desktop: '15px',
    mobile: '12px',
  },
  normal: {
    desktop: '16px',
    mobile: '14px',
  },
  big: {
    desktop: '20px',
    mobile: '16px',
  },
  veryBig: {
    desktop: '25px',
    mobile: '16px',
  },
};

export interface Location<T = unknown> {
  pathname?: string;
  search?: string;
  hash?: string;
  state?: T;
}

interface Props extends ComponentPropsWithoutRef<'a'> {
  className?: string;
  children: ReactNode;
  to: string | Location;
  isActive?: boolean;
  onClick?: () => void;
  size?: Size;
  color?: keyof DefaultTheme['colors']['text'];
  weight?: '400' | '500' | '600' | '700';
  noUnderline?: boolean;
  replace?: boolean;
  newTab?: boolean;
}

const BasicLink = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      className,
      children,
      to,
      onClick,
      isActive = false,
      replace,
      newTab,
      noUnderline: _noUnderline,
      ...rest
    },
    ref
  ) => {
    const pageName = usePageName();
    const { state } = useLocation();
    const onClickHandler = useCallback(
      (e: MouseEvent) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      },
      [onClick]
    );

    const newState = replace
      ? state
      : {
          pageName: pageName,
        };

    const newTo: Location =
      typeof to === 'string'
        ? {
            pathname: to,
            state: newState,
          }
        : {
            ...to,
            state: newState,
          };

    return (
      <RouterLink
        className={className}
        to={newTo}
        onClick={onClickHandler}
        data-active={isActive}
        replace={replace}
        target={newTab ? '_blank' : undefined}
        ref={ref}
        {...rest}
      >
        {children}
      </RouterLink>
    );
  }
);

export const Link = styled(BasicLink)`
  color: ${props => props.theme.colors.text[props.color || 'darkLink']};
  font-family: ${props => props.theme.fontFamily.Inter};
  font-weight: ${props => props.weight || '400'};
  font-size: ${props => sizes[props.size || 'normal'].desktop};
  margin: 0;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: ${props => sizes[props.size || 'normal'].mobile};
  }

  &:focus {
    outline: 2px solid ${props => props.theme.colors.focus};
  }

  ${props => (props.noUnderline ? 'text-decoration: none;' : '')}
`;
