import { ReactComponent as SearchIcon } from 'assets/Navbar/Search.svg';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon } from 'Atoms/Icon';
import { H1, P, Span } from 'Atoms/text';
import { NotFoundImage } from 'Molecules/NotFoundImage';
import React, { FC, FormEvent, KeyboardEvent, useState } from 'react';
import Autosuggest, { ChangeEvent, SuggestionsFetchRequested } from 'react-autosuggest';
import { Helmet } from 'react-helmet';
import { usePushState } from 'services/usePushState.hook';
import { useAccountController } from 'store/accountStore/hooks';
import { useState as useSearchState } from 'store/searchStore/hooks';
import { useSearchSuggestions } from 'store/searchStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';

const NotFoundPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 100px 10px 32px;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) and (min-width: ${props =>
      props.theme.breakpoints.m}) {
    padding: 100px 20px 32px;
  }
  @media (min-width: ${props => props.theme.breakpoints.l}) {
    padding: 100px 48px 32px;
  }
`;

const GridSection = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: auto 40%;
  width: 100%;
  height: 100%;
`;

const SuggestionContainer = styled.div`
  height: 44px;
  grid-row: 2 / 3;
  margin-top: 20px;
  @media (min-width: ${props => props.theme.breakpoints.s}) {
    grid-column: 2 / 3;
    max-width: 500px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    grid-column: 1 / 3;
  }

  position: relative;
  padding: 0 5px;
  display: flex;

  border: 1px solid ${props => props.theme.colors.accountMenu.default.borderColor};
  border-radius: 10px;

  .react-autosuggest__container {
    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;
  }

  .react-autosuggest__input {
    width: 100%;
    height: 100%;

    padding: 0 10px;

    background: unset;
    border: none;
    border-radius: 10px;

    color: ${props => props.theme.colors.text.input};

    font-size: 30px;
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      font-size: 20px;
    }

    font-weight: 600;
    &::-webkit-input-placeholder,
    &::placeholder {
      font-weight: 500;
    }
  }

  .react-autosuggest__suggestions-container {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 100;
    max-height: 300px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    overflow: auto;

    background: ${props => props.theme.colors.background.searchBar};
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 10px 0;
  }

  .react-autosuggest__suggestions-container--open {
    border: 1px solid ${props => props.theme.colors.accountMenu.default.borderColor};
    border-radius: 10px;
  }

  .react-autosuggest__suggestion {
    list-style: none;
    cursor: pointer;
    margin: 0;

    padding: 10px 40px;

    @media (max-width: ${props => props.theme.breakpoints.m}) {
      padding: 10px 20px;
    }

    &:hover {
      background-color: ${props => props.theme.colors.select.hover.option};
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: ${props => props.theme.colors.select.hover.option};
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const ExplanationSection = styled.div`
  display: flex;
  margin: 20px 0 0 0;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    justify-content: center;
  }
  @media (min-width: ${props => props.theme.breakpoints.s}) {
    justify-content: flex-end;
  }
`;

const NotFoundImageStyled = styled(NotFoundImage)`
  width: 100%;
  justify-self: end;

  @media (min-width: ${props => props.theme.breakpoints.s}) {
    grid-row: 1 / 3;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    grid-row: 1 / 2;
  }
`;

const TitleStyled = styled(H1)`
  text-align: unset;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font: ${props => props.theme.fonts.HeaderBold};
    margin: 0 0 20px 0;
  }
  @media (min-width: ${props => props.theme.breakpoints.s}) and (max-width: ${props =>
      props.theme.breakpoints.m}) {
    margin: 0 0 30px 0;
  }
  @media (min-width: ${props => props.theme.breakpoints.m}) {
    margin: 0 0 40px 0;
  }
`;

const InvisibleButtonStyled = styled(InvisibleButton)`
  cursor: pointer;
`;

export const NotFound: FC = () => {
  const [value, setValue] = useState('');
  const { suggestions } = useSearchState();
  const { push } = usePushState();
  const getSearchSuggestions = useSearchSuggestions();

  const { isAuthenticated } = useAccountController();

  const onChange = (_: FormEvent<HTMLElement>, params: ChangeEvent): void => {
    setValue(params.newValue);
  };

  const onClickSubmit = (): void => {
    onPushToSearchPage(value);
  };

  const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      onClickSubmit();
    }
  };

  const onSuggestionsFetchRequested: SuggestionsFetchRequested = (params): void => {
    if (params.reason !== 'input-focused') {
      getSearchSuggestions(params.value);
    }
  };

  const onPushToSearchPage = (value: string): void => {
    const serachParams = new URLSearchParams({ q: value });

    push(`/search?${serachParams.toString()}`);
  };

  assertIsNotStoreError(suggestions);

  return (
    <NotFoundPage>
      <Helmet>
        <title>Not found - GIDEON</title>
      </Helmet>
      <GridSection>
        <NotFoundImageStyled />
        <TextContainer>
          <TitleStyled
            weight="600"
            font="Inter"
            size="veryBig"
            color="errorTitle"
            lineHeight="unset"
          >
            Hmm... I don’t recognize this bug.
          </TitleStyled>
          <P weight="600" size="veryBig" color="navbar">
            Let’s try identifying it together.
          </P>
        </TextContainer>
        {isAuthenticated && (
          <SuggestionContainer>
            <Autosuggest
              suggestions={isLoading(suggestions) ? [] : suggestions}
              renderSuggestion={suggestion => <Span size="big">{suggestion}</Span>}
              getSuggestionValue={suggestion => suggestion}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionSelected={(_, data) => onPushToSearchPage(data.suggestion)}
              onSuggestionsClearRequested={() => void 0}
              inputProps={{
                placeholder: 'Search',
                value: value,
                onChange: onChange,
                onKeyDown: onInputKeyDown,
              }}
            />
            <InvisibleButtonStyled onClick={onClickSubmit}>
              <Icon svgComponent={SearchIcon} fill="input" size="medium" />
            </InvisibleButtonStyled>
          </SuggestionContainer>
        )}
      </GridSection>
      <ExplanationSection>
        <P textAlign="center" size="big" color="inactive">
          For the techies among us, this is error 404
        </P>
      </ExplanationSection>
    </NotFoundPage>
  );
};
