import { FilledButton } from 'Atoms/buttons/FilledButton';
import { P } from 'Atoms/text';
import { Span } from 'Atoms/text/Span';
import { CompareExplanation } from 'Molecules/CompareExplanation';
import { ListItem } from 'Molecules/ListItem';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { SimpleDisease } from 'types/simpleDisease';

const Results = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 37px;
`;

const ResultsTitle = styled(P)`
  margin: 0 0 10px 0;
`;

const ResultsSubTitle = styled(P)`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const SpanStyled = styled(Span)`
  margin-right: 5px;
`;

const LoadButton = styled(FilledButton)`
  width: 120px;
  min-height: 30px;
  padding: 0;
`;

const ResultsList = styled.div`
  margin-top: 20px;
  overflow: auto;
`;

const StyledListItem = styled(ListItem)`
  max-width: 450px;
`;

interface Props {
  results: SimpleDisease[];
  isLoading: boolean;
  total: number;
}

export const FingerprintSearchResults: React.FC<Props> = ({ results, isLoading, total }) => {
  const [fullListLoaded, setFullListLoaded] = useState(false);
  const isEmpty = results && results.length === 0;
  if (isLoading) {
    return (
      <Results>
        <ResultsTitle size="big">Loading...</ResultsTitle>
      </Results>
    );
  }

  if (results.length === total) {
    return (
      <Results>
        <ResultsTitle size="big">{total} diseases match your search.</ResultsTitle>
        <ResultsSubTitle size="normal">
          {!fullListLoaded && <SpanStyled>Use selectors to specify your query or</SpanStyled>}
          <LoadButton onClick={() => setFullListLoaded(!fullListLoaded)}>
            {fullListLoaded ? 'hide list' : 'load full list'}
          </LoadButton>
        </ResultsSubTitle>
        {fullListLoaded && <CompareExplanation />}

        {fullListLoaded && (
          <ResultsList>
            {results.map(disease => (
              <StyledListItem
                key={disease.id}
                name={disease.name}
                to={`/explore/diseases/${disease.id}`}
                compareButtonSection="diseases"
                compareButtonId={disease.id}
              />
            ))}
          </ResultsList>
        )}
      </Results>
    );
  }

  if (isEmpty) {
    return (
      <Results>
        <ResultsTitle size="big" role="alert" aria-live="polite">
          No results found
        </ResultsTitle>
      </Results>
    );
  }

  return (
    <Results>
      <ResultsTitle
        size="big"
        role="alert"
        aria-live="polite"
      >{`${results.length} of ${total} diseases match your search!`}</ResultsTitle>
      <CompareExplanation />
      <ResultsList>
        {results.map(disease => (
          <StyledListItem
            key={disease.id}
            name={disease.name}
            to={`/explore/diseases/${disease.id}`}
            compareButtonSection="diseases"
            compareButtonId={disease.id}
          />
        ))}
      </ResultsList>
    </Results>
  );
};
