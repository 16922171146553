import { AxiosError } from 'axios.cached';
import { CustomError, ErrorCodes } from 'types/errorTypes';

export interface StoreError {
  type: 'StoreError';
  message: string;
  code?: ErrorCodes;
  innerError?: Error | AxiosError;
}

export const newStoreError = (
  message: string,
  code: ErrorCodes,
  innerError?: Error | AxiosError
): StoreError => ({
  type: 'StoreError',
  message,
  code,
  innerError,
});

export const isStoreError = (error: unknown): error is StoreError =>
  typeof error === 'object' && error !== null && (error as StoreError).type === 'StoreError';

export function assertIsNotStoreError<T>(object: StoreError | T): asserts object is T {
  if (!isStoreError(object)) {
    return;
  }

  if (object.innerError) {
    throw object.innerError;
  } else {
    throw new CustomError(object.message, object.code, 'Error');
  }
}
