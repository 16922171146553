import { ReactComponent as DiseasesSvg } from 'assets/ExploreFocus/Diseases.svg';
import { ReactComponent as DrugsSvg } from 'assets/ExploreFocus/Drugs.svg';
import { ReactComponent as BacteriaSvg } from 'assets/Microbes/Bacteria.svg';
import { ReactComponent as MycobacteriaSvg } from 'assets/Microbes/Mycobacteria.svg';
import { ReactComponent as YeastsSvg } from 'assets/Microbes/Yeasts.svg';
import {
  CompareBacteria,
  CompareDiseases,
  CompareDrugs,
  CompareMycobacteria,
  CompareYeasts,
} from 'Organisms/compare/CompareCategories';
import { FC } from 'react';
import { useState as useCompareState } from 'store/ComparisonListsStore/hooks';
import { ComparisonSectionKeys } from 'store/ComparisonListsStore/types';
import { CompareTabSection } from 'types/tab';

export interface Section extends CompareTabSection {
  Component: FC;
  slug: ComparisonSectionKeys;
}

export const useSections = (): Section[] => {
  const { diseases, drugs, bacteria, mycobacteria, yeasts } = useCompareState();

  return [
    {
      name: 'Diseases',
      slug: 'diseases',
      Component: CompareDiseases,
      count: diseases.length,
      icon: DiseasesSvg,
    },
    { name: 'Drugs', slug: 'drugs', Component: CompareDrugs, count: drugs.length, icon: DrugsSvg },
    {
      name: 'Bacteria',
      slug: 'bacteria',
      Component: CompareBacteria,
      count: bacteria.length,
      icon: BacteriaSvg,
    },
    {
      name: 'Mycobacteria',
      slug: 'mycobacteria',
      Component: CompareMycobacteria,
      count: mycobacteria.length,
      icon: MycobacteriaSvg,
    },
    {
      name: 'Yeasts and Algae',
      slug: 'yeasts',
      Component: CompareYeasts,
      count: yeasts.length,
      icon: YeastsSvg,
    },
  ];
};
