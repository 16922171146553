import { RedirectWithState } from 'Atoms/routes/RedirectWithState';
import { MainLayout } from 'layouts/MainLayout';
import { ComparePageTitle } from 'Molecules/compare/ComparePageTitle';
import { CompareTab } from 'Molecules/compare/CompareTab';
import { Tabs } from 'Molecules/Tabs';
import { CompareMobileMenu } from 'Organisms/compare/CompareMobileMenu';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { match, Redirect, Route, Switch } from 'react-router-dom';
import { useMobile } from 'services/useMobile';
import { useDispatch } from 'store/ComparisonListsStore/hooks';
import styled from 'styled-components/macro';

import { Section, useSections } from './ComparePageCategories';

const Wrapper = styled.div`
  width: 97%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const MobileWrapper = styled.div`
  width: 86%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

interface PropsCompare {
  match: match;
  sections: Section[];
}

export const CompareDesktop: FC<PropsCompare> = ({ match, sections }) => {
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <ComparePageTitle />
      <Wrapper>
        <Tabs
          sections={sections}
          path={match.url}
          Tab={CompareTab}
          onClick={section => {
            dispatch({
              type: 'ComparisonLists/SetActiveSection',
              payload: section.slug,
            });
          }}
        />
        <Switch>
          <RedirectWithState exact from={match.url} to={`${match.url}/diseases`} />
          {sections.map(section => (
            <Route
              path={`${match.url}/${section.slug}`}
              key={section.slug}
              render={() => <section.Component />}
            />
          ))}
          <Redirect to="/404" />
        </Switch>
      </Wrapper>
    </Wrapper>
  );
};

export const CompareMobile: FC<PropsCompare> = ({ match, sections }) => (
  <MobileWrapper>
    <ComparePageTitle />
    <MobileWrapper>
      <Switch>
        <Route exact from={match.url} render={() => <CompareMobileMenu sections={sections} />} />
        <Wrapper>
          {sections.map(section => (
            <Route
              path={`${match.url}/${section.slug}`}
              key={section.slug}
              render={() => <section.Component />}
            />
          ))}
        </Wrapper>
        <Redirect to="/404" />
      </Switch>
    </MobileWrapper>
  </MobileWrapper>
);

interface Props {
  match: match;
}

export const Compare: FC<Props> = ({ match }) => {
  const sections = useSections();
  const isMobile = useMobile('m');

  return (
    <MainLayout>
      <Helmet>
        <title>Compare - GIDEON</title>
      </Helmet>
      {isMobile ? (
        <CompareMobile match={match} sections={sections} />
      ) : (
        <CompareDesktop match={match} sections={sections} />
      )}
    </MainLayout>
  );
};
