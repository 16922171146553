import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React from 'react';
import { Pathogen } from 'types/disease';
import { DynamicContent } from 'types/dynamicContent';

interface DiseaseDetails {
  disease: string;
  clinicalNote: DynamicContent;
  pathogens?: Pathogen[];
}

interface Props {
  details: DiseaseDetails;
}

export const ClinicalFindingsSection: React.FC<Props> = ({ details }) => {
  return (
    <>
      <DynamicHtml content={details.clinicalNote} />
    </>
  );
};
