import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { DesktopExploreFocus } from './DesktopExploreFocus';
import { MobileExploreFocus } from './MobileExploreFocus';

interface Props {
  className?: string;
}

const Wrapper = styled.div`
  width: 100%;
`;

export const ExploreFocus: FC<Props> = ({ className }) => {
  return (
    <Wrapper className={className}>
      <DesktopExploreFocus />
      <MobileExploreFocus />
    </Wrapper>
  );
};
