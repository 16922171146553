import { CompareOptionsWrapper } from 'Atoms/CompareOptionsWrapper';
import { Switch } from 'Atoms/Switch';
import { CompareProbabilityKeys } from 'Molecules/compare/CompareProbabilityKeys';
import React, { FC } from 'react';
import { OnChangeValue } from 'react-select';
import styled from 'styled-components/macro';
import { Probability } from 'types/compare';
import { SelectOption } from 'types/select';

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -20px;
`;

const StyledKeys = styled(CompareProbabilityKeys)`
  margin: 20px 100px 0 0;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-right: 0;
  }
`;

const Switches = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
  margin-top: 20px;
`;

interface Props {
  onProbabilityChange?: (selection: OnChangeValue<SelectOption, false>) => void;
  probabilities: Probability[];
  showMatches: boolean;
  onSwitchChange: () => void;
}

export const CompareMicrobeOptions: FC<Props> = ({
  onProbabilityChange,
  probabilities,
  showMatches,
  onSwitchChange,
}) => {
  return (
    <CompareOptionsWrapper>
      <Content>
        <StyledKeys onChange={onProbabilityChange} probabilities={probabilities} />
        <Switches>
          <Switch
            label="Show only distinguishing characteristics"
            checked={!showMatches}
            onClick={onSwitchChange}
          />
          <Switch label="Compare phenotypes" checked={showMatches} onClick={onSwitchChange} />
        </Switches>
      </Content>
    </CompareOptionsWrapper>
  );
};
