import { AZListSection } from 'Molecules/az/AZListSection';
import React, { FC, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import styled from 'styled-components/macro';
import { SectionsDictionary } from 'types/az';
import { ComparisonListsSection } from 'types/comparisonLists';
import { CustomError, ErrorCodes } from 'types/errorTypes';
import { alphabet } from 'utils/alphabet';

const Section = styled(AZListSection)`
  margin: 53px 0 20px;
`;

interface Props {
  data: SectionsDictionary;
  basePath: string;
  exploreUrlPrefix: string;
  compareSection: ComparisonListsSection | undefined;
  useDOMOptimization: boolean;
}

export const AZResultsList: FC<Props> = ({
  data,
  basePath,
  exploreUrlPrefix,
  compareSection,
  useDOMOptimization,
}) => {
  const match = useRouteMatch<{ letter: string }>(`${basePath}/:letter`);

  useEffect(() => {
    if (!data || !match) {
      return;
    }

    if (alphabet.some(letter => letter === match.params.letter)) {
      scroller.scrollTo(match.url[match.url.length - 1], { duration: 1500, offset: -130 });
    } else {
      throw new CustomError('Invalid AZ url', ErrorCodes.NotFound);
    }
  }, [data, basePath, match]);

  return (
    <div>
      {Object.keys(data).map(key => (
        <Element key={key} name={key}>
          <Section
            data={data[key]}
            letter={key}
            exploreUrlPrefix={exploreUrlPrefix}
            compareSection={compareSection}
            useDOMOptimization={useDOMOptimization}
          />
        </Element>
      ))}
    </div>
  );
};
