import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'MapControl/ToggleAccessibility': {
      return {
        ...state,
        accessibility: !state.accessibility,
      };
    }
  }
};
