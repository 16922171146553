import { ModifierArguments, Placement as PopperPlacement } from '@popperjs/core';
import maxSize from 'popper-max-size-modifier';
import React, { createContext, FC, ReactNode, useCallback, useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components/macro';

const StyledPopper = styled.span`
  z-index: 250;
`;

interface PopperValuesContext {
  maxHeight: number;
}

export const PopperContext = createContext<PopperValuesContext | undefined>(undefined);

interface Props {
  referenceElement: Element | undefined | null;
  className?: string;
  position?: PopperPlacement;
  children: ReactNode;
  offsetDistance?: number;
  isOpen?: boolean;
  id?: string;
  boundary?: HTMLElement | null;
}

export const Popper: FC<Props> = ({
  referenceElement,
  position = 'bottom',
  children,
  className,
  offsetDistance = 0,
  isOpen,
  id,
  boundary,
}) => {
  const [popperElement, setPopperElement] = useState<HTMLSpanElement | null>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  const setPopperState = useCallback(({ state }: ModifierArguments<Record<string, unknown>>) => {
    const { height } = state.modifiersData.maxSize;
    setMaxHeight(height);
  }, []);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: position,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, offsetDistance],
        },
      },
      {
        ...maxSize,
        options: {
          boundary,
        },
      },
      {
        name: 'applyMaxSize',
        enabled: true,
        phase: 'beforeWrite',
        requires: ['maxSize'],
        fn: setPopperState,
      },
    ],
  });

  return (
    <StyledPopper
      id={id}
      className={className}
      ref={ref => setPopperElement(ref)}
      style={{
        ...styles.popper,
        visibility: isOpen ? 'visible' : 'hidden',
        opacity: isOpen ? 1 : 0,
      }}
      {...attributes.popper}
      role="tooltip"
    >
      <PopperContext.Provider value={{ maxHeight }}>{children}</PopperContext.Provider>
    </StyledPopper>
  );
};
