import axios from 'axios.cached';
import { Country } from 'types/country';
import { CountryDetails, CountryDetailsPublic, CountryDisease } from 'types/countryDetails';
import { getOriginalCountryCode } from 'utils/customCountryCodes';

import { DataDTO } from '../common/DTO';
import { mapCountryDetailsDTO, mapCountryDetailsPublicDTO } from './country.mapper';
import {
  CdcRecommendationDTO,
  ChloroquineResistanceDTO,
  CountryDetailsPublicDTO,
  CountryDiseaseDTO,
  CountryDTO,
  MalariaGuidelineDTO,
  ProphylaxisRecommendationDTO,
  RegionVaccineScheduleDTO,
  VaccinationChartDTO,
  YellowFeverGuidelineDTO,
} from './DTO';
import { inappropriateSynonyms } from './inappropriateSynonyms';

export const getCountryDetails = async (code: string): Promise<CountryDetails> => {
  const [
    yellowFever,
    cdcRecommendation,
    malariaGuideline,
    choloroquineResistance,
    prophylaxisRecommendation,
    vaccineSchedule,
    vaccinationChart,
  ] = await Promise.all([
    axios.get<DataDTO<YellowFeverGuidelineDTO>>(
      `/api/travel/countries/${code}/yellow-fever-guideline`
    ),
    axios.get<DataDTO<CdcRecommendationDTO>>(`/api/travel/countries/${code}/cdc-recommendation`),
    axios.get<DataDTO<MalariaGuidelineDTO>>(`/api/travel/countries/${code}/malaria-guideline`),
    axios.get<DataDTO<ChloroquineResistanceDTO>>(
      `/api/travel/countries/${code}/chloroquine-resistance`
    ),
    axios.get<DataDTO<ProphylaxisRecommendationDTO>>(
      `/api/travel/countries/${code}/prophylaxis-recommendation`
    ),
    axios.get<DataDTO<RegionVaccineScheduleDTO[]>>(
      `/api/travel/countries/${code}/vaccine-schedule`
    ),
    axios.get<DataDTO<VaccinationChartDTO>>(
      `/api/travel/countries/${code}/vaccination-coverage-chart`
    ),
  ]);

  const maxTimeStamp = Math.max(
    yellowFever.data.timestamp ?? 0,
    cdcRecommendation.data.timestamp?? 0,
    malariaGuideline.data.timestamp?? 0,
    choloroquineResistance.data.timestamp?? 0,
    prophylaxisRecommendation.data.timestamp?? 0,
    vaccineSchedule.data.timestamp ?? 0
  )

  return mapCountryDetailsDTO({
    yellowFeverRequirements: yellowFever.data.data.yellow_fever_country_requirements,
    cdcRecommendation: cdcRecommendation.data.data.yellow_fever_cdc_recommendations,
    malariaGuideline: malariaGuideline.data.data.malaria_areas_of_risk,
    choloroquineResistance: choloroquineResistance.data.data.malaria_chloroquine_resistance,
    prophylaxisRecommendation:
      prophylaxisRecommendation.data.data.malaria_prophylaxis_recommendations,
    prophylaxisDrugs: prophylaxisRecommendation.data.data.drugs,
    vaccineSchedule: vaccineSchedule.data.data,
    vaccinationChart: vaccinationChart.data.data,
    maxTimeStamp
  });
};

export const getCountryDetailsPublic = async (code: string): Promise<CountryDetailsPublic> => {
  const details = await axios.get<DataDTO<CountryDetailsPublicDTO>>(
    `/api/public/travel/countries/${code}`
  );

  return mapCountryDetailsPublicDTO(details.data.data);
};

export const getCountryRelevantDiseases = async (code: string): Promise<CountryDisease[]> => {
  const relevantDiseases = await axios.get<DataDTO<CountryDiseaseDTO>>(
    `/api/diseases/countries/${getOriginalCountryCode(code)}/all`
  );
  const maxTimeStamp = relevantDiseases.data.timestamp??0
  const data =  relevantDiseases.data.data.map(d=>(
    {
      id: d.id,
      name: d.name,
      href: d.href,
      timestamp:maxTimeStamp,
      annual_rate: d.annual_rate,
      is_endemic:d.is_endemic,
      has_country_note:d.has_country_note
    }
  ))

  return data
};

export const getCountriesSynonyms = async (search?: string, limit = 50): Promise<Country[]> => {
  const countrySynonyms = await axios.get<DataDTO<CountryDTO[]>>(
    `/api/diagnosis/countries/synonyms?search=${search}&limit=${limit}`
  );

  const filteredSynonyms = countrySynonyms.data.data.filter(
    synonym => !inappropriateSynonyms.includes(synonym.country)
  );

  return filteredSynonyms.map(synonym => ({
    id: synonym.country_code,
    name: synonym.country,
  }));
};
