import { Tab } from 'Molecules/buttons/Tab';
import { Slider } from 'Molecules/Tabs';
import styled, { css } from 'styled-components/macro';

export const CompareTab = styled(Tab)`
  width: 20%;

  &:nth-child(1) {
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: ${props.count ? '160px' : '128px'};
          left: 0;
        }
      `}
  }

  &:nth-child(2) {
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: ${props.count ? '130px' : '98px'};
          left: 20%;
        }
      `}
  }

  &:nth-child(3) {
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: ${props.count ? '157px' : '118px'};
          left: 40%;
        }
      `}
  }

  &:nth-child(4) {
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: ${props.count ? '204px' : '169px'};
          left: 60%;
        }
      `}
  }

  &:nth-child(5) {
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: ${props.count ? '237px' : '203px'};
          left: 80%;
        }
      `}
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    &:nth-child(1) {
      width: 19%;
      ${props =>
        props.active &&
        css`
          & ~ ${Slider} {
            width: ${props.count ? '144px' : '117px'};
          }
        `}
    }

    &:nth-child(2) {
      width: 17%;
      ${props =>
        props.active &&
        css`
          & ~ ${Slider} {
            left: 19%;
            width: ${props.count ? '117px' : '91px'};
          }
        `}
    }

    &:nth-child(3) {
      width: 19%;
      ${props =>
        props.active &&
        css`
          & ~ ${Slider} {
            left: 36%;
            width: ${props.count ? '135px' : '112px'};
          }
        `}
    }

    &:nth-child(4) {
      width: 22%;
      ${props =>
        props.active &&
        css`
          & ~ ${Slider} {
            left: 55%;
            width: ${props.count ? '182px' : '156px'};
          }
        `}
    }

    &:nth-child(5) {
      width: 23%;
      ${props =>
        props.active &&
        css`
          & ~ ${Slider} {
            left: 77%;
            width: ${props.count ? '213px' : '187px'};
          }
        `}
    }
  }
`;
