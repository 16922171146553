import { getDrugDetails, getDrugDetailsPublic } from 'apiServices/Drugs/drugs';
import React, { useEffect } from 'react';
import { newStoreError } from 'store/storeError';
import { Dispatch, Loading, Resource } from 'store/types';
import { DrugDetails, DrugDetailsPublic } from 'types/drug';

import { Action, DrugDetailsDispatchContext, DrugDetailsStateContext, State } from './provider';

export const useState = (): State => {
  const state = React.useContext(DrugDetailsStateContext);
  if (state === undefined) {
    throw new Error('Drug details store is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(DrugDetailsDispatchContext);
  if (dispatch === undefined) {
    throw new Error('Drug details store is not initialized');
  }
  return dispatch;
};

export const useDrugDetails = (id: number): Resource<DrugDetails> => {
  const state = useState();
  const dispatch = useDispatch();
  const drugResource = state.drugDetails[id];

  useEffect(() => {
    if (!drugResource) {
      dispatch({ type: 'DrugDetails/SingleLoadInitiated', payload: { id } });
      getDrugDetails(id)
        .then(data => dispatch({ type: 'DrugDetails/SingleLoaded', payload: { id, data } }))
        .catch(err => {
          dispatch({
            type: 'DrugDetails/SingleLoadFailed',
            payload: { id, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, state, drugResource, id]);

  return drugResource || Loading;
};

export const useDrugDetailsPublic = (id: number): Resource<DrugDetailsPublic> => {
  const state = useState();
  const dispatch = useDispatch();
  const drugResource = state.drugDetailsPublic[id];

  useEffect(() => {
    if (!drugResource) {
      dispatch({ type: 'DrugDetails/Public/SingleLoadInitiated', payload: { id } });
      getDrugDetailsPublic(id)
        .then(data => dispatch({ type: 'DrugDetails/Public/SingleLoaded', payload: { id, data } }))
        .catch(err => {
          dispatch({
            type: 'DrugDetails/Public/SingleLoadFailed',
            payload: { id, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, state, drugResource, id]);

  return drugResource || Loading;
};
