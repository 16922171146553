import { Span } from 'Atoms/text';
import { rgba } from 'polished';
import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components/macro';

const StyledMessage = styled(Span)`
  text-align: center;
`;

interface Props {
  className?: string;
  message?: string;
}

const LoaderSvg: FC<Props> = ({ className, message }) => (
  <div className={className}>
    <svg viewBox="0 0 16 12" width="16px" height="12px" className="loader">
      <polyline
        className="loader-polyline loader-back-line"
        points="1 6 4 6 6 11 10 1 12 6 15 6"
      ></polyline>
      <polyline
        className="loader-polyline loader-front-line"
        points="1 6 4 6 6 11 10 1 12 6 15 6"
      ></polyline>
    </svg>
    {message && <StyledMessage size="normal">{message}</StyledMessage>}
  </div>
);

const dashKeyframe = keyframes`
  62.5% {
    opacity: 0
  }
  to {
    stroke-dashoffset: 0
  }
`;

export const Loader = styled(LoaderSvg)`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  transform: translate(-50%, -50%);

  .loader {
    height: 24px;
    width: 32px;
  }

  .loader-polyline {
    fill: none;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    &.loader-back-line {
      stroke: ${props => rgba(props.theme.colors.loader, 0.3)};
    }
    &.loader-front-line {
      stroke: ${props => props.theme.colors.loader};
      stroke-dasharray: 12, 36; /* Dash 12 & Gap 36 */
      stroke-dashoffset: 48;
      animation: ${dashKeyframe} 1s linear infinite;
    }
  }
`;
