import React, { FC } from 'react';
import styled from 'styled-components/macro';

const Form = styled.form`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 15px;
`;

export const Input = styled.input`
  background: transparent;
  width: 75%;
  height: 100%;
  margin-right: 5px;
  padding: 0 8px;
  font: ${props => props.theme.fonts.normalText};
  color: ${props => props.theme.colors.text.main};
  border: 0;
  outline: 0;
`;

export const SendButton = styled.button`
  flex-grow: 1;
  border: 0;
  border-radius: 4px;
  background: ${props => props.theme.colors.expirationButtons.talk};
  color: #fff;
  cursor: pointer;
  font: ${props => props.theme.fonts.normalText};
  transition: all 0.2s ease;
  padding: 6px 0;

  &:hover {
    opacity: 0.8;
  }
`;

interface Props {
  className?: string;
  onEmailChange: (email: string) => void;
  sendPDFemail: () => void;
  email: string;
}

export const EmailForm: FC<Props> = ({ className, email, onEmailChange, sendPDFemail }) => (
  <Form
    className={className}
    onSubmit={e => {
      e.preventDefault();
      sendPDFemail();
    }}
  >
    <Input
      type="email"
      placeholder="Enter email"
      required
      onChange={e => onEmailChange(e.target.value)}
      value={email}
      autoComplete="email"
      aria-label="Email"
    />
    <SendButton type="submit">Send</SendButton>
  </Form>
);
