import { getDrugsSynonyms } from 'apiServices/Drugs/drugs';
import { ReactComponent as PillIcon } from 'assets/Button/Pill.svg';
import { ReactComponent as ToxicityIcon } from 'assets/Button/ToxicityIcon.svg';
import { ExploreLayout } from 'layouts/ExploreLayout';
import { SearchByTypeButton } from 'Molecules/buttons/SearchByTypeButton';
import { DrugSpectrumModal } from 'Molecules/drug/DrugSpectrumModal';
import { SearchByModal } from 'Molecules/modal/SearchByModal';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { OnChangeValue } from 'react-select';
import { useMobile } from 'services/useMobile';
import { useDrugsFiltered, useDrugsList } from 'store/drugsStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';
import { mapResource, mapToSearchSelectOption } from 'utils/mapResource';

const ButtonStyled = styled(SearchByTypeButton)`
  max-height: 44px;
  margin: 10px 10px 0;
  padding: 10px;
`;

export const Drugs: React.FC = () => {
  const isMobile = useMobile('s');
  const { drugsState, filterDrugs, total } = useDrugsFiltered();
  const drugsList = useDrugsList();
  const [isToxicityOpen, setToxicityOpen] = useState<boolean>(false);
  const [isSpectrumOpen, setSpectrumOpen] = useState<boolean>(false);
  const [isInteractionOpen, setInteractionOpen] = useState<boolean>(false);

  const [toxicity, setToxicity] = useState<SelectOption | null>(null);
  const [interaction, setInteraction] = useState<SelectOption | null>(null);

  const drugOptions = mapResource(drugsState.drugs, mapToSearchSelectOption);
  const toxicityOptions = mapResource(drugsState.toxicities, mapToSearchSelectOption);
  const interactionOptions = mapResource(drugsState.interactions, mapToSearchSelectOption);

  assertIsNotStoreError(drugOptions);

  const onChangeToxicity = (value: OnChangeValue<SelectOption, false>): void => {
    setToxicity(value);

    if (value) {
      filterDrugs('toxicity', value.value);
    }
  };

  const onChangeInteraction = (value: OnChangeValue<SelectOption, false>): void => {
    setInteraction(value);

    if (value) {
      filterDrugs('interaction', value.value);
    }
  };

  const selectOptions = !isLoading(drugOptions) && drugOptions ? drugOptions : [];

  const loadSynonymOptions = async (inputValue: string): Promise<SelectOption[]> => {
    const synonyms = await getDrugsSynonyms(inputValue);
    return synonyms.map(x => ({
      label: x.name,
      value: x.id.toString(),
      synonymOf: drugsList.find(drug => drug.id === x.id)?.name,
    }));
  };

  return (
    <ExploreLayout
      title="Explore GIDEON data on drugs"
      imageName="drugs"
      placeholder={isMobile ? 'Enter generic | trade name' : 'Start typing generic or trade name'}
      section="drugs"
      loadSynonymOptions={loadSynonymOptions}
      defaultOptions={selectOptions}
      optionTitle="Search by..."
      selectLabel="Filter and search across all drug records"
    >
      <Helmet>
        <title>Explore drugs - GIDEON</title>
        <meta
          name="description"
          content="A wealth of information on 29,000+ trade names of anti-infective drugs. Includes tools to find drugs by toxicity, interaction, drug class, or spectrum."
        />
      </Helmet>
      <ButtonStyled Icon={ToxicityIcon} onClick={() => setToxicityOpen(true)}>
        Toxicity
      </ButtonStyled>
      <SearchByModal
        title="Searching for drugs by Toxicity"
        subTitle="Toxicity is any untoward effect of a drug or vaccine."
        placeholder="Type a letter or select from the list"
        isOpen={isToxicityOpen}
        onClose={() => setToxicityOpen(false)}
        options={toxicityOptions}
        onChange={onChangeToxicity}
        type="drugs"
        results={drugsState.filteredDrugs}
        total={total}
        value={toxicity}
      />
      <ButtonStyled Icon={PillIcon} onClick={() => setSpectrumOpen(true)}>
        {isMobile ? 'Drug class | Spectrum' : 'Drug class or Spectrum'}
      </ButtonStyled>
      <DrugSpectrumModal isOpen={isSpectrumOpen} onClose={() => setSpectrumOpen(false)} />
      <ButtonStyled Icon={PillIcon} onClick={() => setInteractionOpen(true)}>
        Interaction
      </ButtonStyled>
      <SearchByModal
        title="Searching for drugs by Interaction"
        subTitle="A reaction between two drugs."
        placeholder="Type a letter or select from the list"
        isOpen={isInteractionOpen}
        onClose={() => setInteractionOpen(false)}
        options={interactionOptions}
        onChange={onChangeInteraction}
        type="drugs"
        results={drugsState.filteredDrugs}
        total={total}
        value={interaction}
      />
    </ExploreLayout>
  );
};
