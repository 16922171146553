import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { CountryPageTitle } from 'Molecules/CountryPageTitle';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { CountryGuidelinesSection } from 'Molecules/section/CountryGuidelinesSection';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import { Paywall } from 'Organisms/paywall/Paywall';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Element } from 'react-scroll';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { useCountriesResource } from 'store/countriesStore/hooks';
import { useCountryDetailsPublic } from 'store/countryDetailsStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import { CustomError, ErrorCodes } from 'types/errorTypes';

type Props = {
  countryId: string;
};

export const CountryPublic: FC<Props> = ({ countryId }) => {
  const [showSummary, setShowSummary] = useState(true);

  const countryDetails = useCountryDetailsPublic(countryId);
  const countries = useCountriesResource();

  assertIsNotStoreError(countries);

  if (isLoading(countryDetails) || isLoading(countries)) {
    return <Loader />;
  }

  const country = countries.data.find(x => x.id === countryId);

  if (!country) {
    throw new CustomError('Country not found', ErrorCodes.NotFound);
  }

  const sections: RecordLink[] = [
    'Guidelines',
    `Diseases relevant to ${country.name}`,
    'Vaccination coverage graph',
    'Vaccination schedule',
    'Downloads and references',
  ].map(section => ({
    label: section,
    'aria-label': `${country.name} ${section.toLowerCase()}`,
  }));

  const description = `GIDEON - ${country.name}. ${sections.join(', ')}`;

  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>{country.name} - GIDEON</title>
          <meta name="description" content={description} />
        </Helmet>
        <ReferenceLabelContext.Provider value={country.name}>
          <RecordLayout>
            <CountryPageTitle country={country.name} countryId={country.id} hideStatsButton />
            <RecordLinks links={sections} />
            <RecordSection
              link={sections[0]}
              show={showSummary}
              onCollapse={() => setShowSummary(!showSummary)}
              accent="first"
              id="country-public-guidelines"
            >
              <CountryGuidelinesSection country={country.name} countryDetails={countryDetails} />
            </RecordSection>
            <Element name={`Diseases relevant to ${country.name}`}>
              <Element name="Vaccination coverage graph">
                <Element name="Vaccination schedule">
                  <Element name="Downloads and references">
                    <Paywall />
                  </Element>
                </Element>
              </Element>
            </Element>
          </RecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};
