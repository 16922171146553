import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

const LayoutStyled = styled.div`
  flex: 1 0 auto;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    min-height: calc(100vh - 130px);
  }
`;

interface Props {
  children: ReactNode;
  className?: string;
}

export const MainLayout: FC<Props> = ({ children, className }) => (
  <LayoutStyled className={className}>{children}</LayoutStyled>
);
