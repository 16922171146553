import { Loader } from 'Atoms/Loader';
import { ErrorBoundary } from 'errorBoundary';
import { ScrollToTopButton } from 'Molecules/buttons/ScrollToTopButton';
import { Footer } from 'Molecules/footer/Footer';
import { MobileSecondaryNavbar } from 'Molecules/mobileSecondaryNavbar/MobileSecondaryNavbar';
import { BreadcrumbContainer, BreadcrumbContainerContent } from 'Organisms/BreadcrumbContainer';
import { Navbar } from 'Organisms/navbar/Navbar';
import { ErrorPage } from 'pages/Error';
import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useBreadcrumb } from 'services/breadcrumb.service';
import { useFooterStatus } from 'services/footer.service';
import { ContextValue } from 'services/localStorage/localStorage.factory';
import { useMobileSecondaryNavbarStatus } from 'services/mobileSecondaryNavbar/mobileSecondaryNavbar';
import { useMobile } from 'services/useMobile';
import { useAccountController } from 'store/accountStore/hooks';
import styled from 'styled-components/macro';

const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const OuterContent = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    &[data-breadcrumb-visible='true'] {
      padding-bottom: 50px;
    }
    &[data-navbar-visible='true'] {
      padding-bottom: 88px;
    }
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

const ScrollToTopContainer = styled.div`
  position: sticky;
  bottom: 41px;

  z-index: 1000;
  margin: 0 auto;
  width: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    bottom: 60px;
  }
`;

export const AppLayoutFullscreenContext = createContext<ContextValue<boolean>>(undefined);
export const BreadcrumbContainerContentContext = createContext<
  ContextValue<BreadcrumbContainerContent>
>(undefined);

interface Props {
  children: ReactNode;
  className?: string;
}

const AppLayoutBase: FC<Props> = ({ children, className }) => {
  const mobileSecondaryNavbarStatus = useMobileSecondaryNavbarStatus();
  const props = useBreadcrumb();
  const footer = useFooterStatus();
  const { pathname } = useLocation();
  const { isLoading } = useAccountController();

  const content = useState<BreadcrumbContainerContent>({ content: null });
  const [fullscreen, setFullscreen] = useState(false);

  const fullscreenMemo = useMemo(
    (): [boolean, Dispatch<SetStateAction<boolean>>] => [fullscreen, setFullscreen],
    [fullscreen, setFullscreen]
  );

  const isBreadcrumbVisible = !!props && !fullscreenMemo[0];
  const isFooterVisible = footer && !fullscreenMemo[0];
  const isMobileSecondaryNavbarVisible = mobileSecondaryNavbarStatus && !fullscreenMemo[0];

  const isMobile = useMobile('m');

  return (
    <AppLayoutFullscreenContext.Provider value={fullscreenMemo}>
      <BreadcrumbContainerContentContext.Provider value={content}>
        {isLoading ? (
          <Loader />
        ) : (
          <LayoutStyled className={className}>
            {!fullscreenMemo[0] && <Navbar />}
            <OuterContent
              data-navbar-visible={isMobileSecondaryNavbarVisible}
              data-breadcrumb-visible={isBreadcrumbVisible}
            >
              <ErrorBoundary pathname={pathname} error={({ error }) => <ErrorPage error={error} />}>
                {props && !fullscreenMemo[0] && (
                  <BreadcrumbContainer breadcrumbProps={props} content={content[0]} />
                )}
                <Main>{children}</Main>
              </ErrorBoundary>
              {!isMobile && (
                <ScrollToTopContainer>
                  <ScrollToTopButton />
                </ScrollToTopContainer>
              )}
              {isFooterVisible && <Footer />}
            </OuterContent>
            {isMobileSecondaryNavbarVisible && <MobileSecondaryNavbar />}
          </LayoutStyled>
        )}
      </BreadcrumbContainerContentContext.Provider>
    </AppLayoutFullscreenContext.Provider>
  );
};

export const AppLayout: FC<Props> = props => (
  <ErrorBoundary error={({ error }) => <ErrorPage error={error} />}>
    <AppLayoutBase {...props} />
  </ErrorBoundary>
);
