import { ExploreBackground } from 'Atoms/explore/ExploreBackground';
import { ExploreLandingTitles } from 'Atoms/explore/ExploreLandingTitles';
import { ExploreLayoutContent } from 'Atoms/explore/ExploreLayoutContent';
import { TrendingNow } from 'Explore/Organisms/TrendingNow';
import React, { FC, ReactNode } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { SelectOption } from 'types/select';

import { MainLayout } from './MainLayout';

type ExploreLandingPages = 'drugs' | 'vaccines' | 'microbes' | 'countries' | 'diseases';

const MainLayoutStyled = styled(MainLayout)`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;

  margin-top: 20px;
`;

interface Props {
  children?: ReactNode;
  className?: string;
  title: string;
  subtitle?: string;
  imageName?: keyof DefaultTheme['backgroundImages'];
  placeholder: string;
  isLoading?: boolean;
  loadSynonymOptions?: (inputValue: string) => Promise<SelectOption[]>;
  defaultOptions: SelectOption[];
  section: ExploreLandingPages;
  optionTitle?: string;
  tooltipContent?: string;
  selectLabel: string;
}

export const ExploreLayout: FC<Props> = ({
  className,
  children,
  title,
  subtitle,
  imageName,
  placeholder,
  section,
  optionTitle,
  loadSynonymOptions,
  defaultOptions,
  tooltipContent,
  selectLabel,
}) => (
  <MainLayoutStyled className={className}>
    <ExploreBackground imageName={imageName} />
    <ExploreLandingTitles title={title} subtitle={subtitle} />
    <TrendingNow section={section} />
    <ExploreLayoutContent
      placeholder={placeholder}
      section={section}
      loadSynonymOptions={loadSynonymOptions}
      defaultOptions={defaultOptions}
      optionTitle={optionTitle}
      tooltipContent={tooltipContent}
      selectLabel={selectLabel}
    >
      {children}
    </ExploreLayoutContent>
  </MainLayoutStyled>
);
