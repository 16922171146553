import { Loader } from 'Atoms/Loader';
import { VaccinationSection } from 'Molecules/section/VaccinationSection';
import React, { FC } from 'react';
import { useCountryDetails } from 'store/countryDetailsStore/hooks';
import { useCountryNotes } from 'store/countryNotesStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';

interface CountryProps {
  countryId: string;
}

export const CountryVaccinationSchedule: FC<CountryProps> = ({ countryId }) => {
  const countryDetails = useCountryDetails(countryId);

  assertIsNotStoreError(countryDetails.details);
  if (isLoading(countryDetails.details) || !countryDetails.details) {
    return <Loader />;
  }

  if (!countryDetails.details.vaccineSchedule) {
    return null;
  }

  return <VaccinationSection data={countryDetails.details.vaccineSchedule} />;
};

interface DiseaseInCountryProps {
  diseaseId: number;
  countryId: string;
}

export const CountryVaccinationScheduleForDisease: FC<DiseaseInCountryProps> = ({
  countryId,
  diseaseId,
}) => {
  const countryNotes = useCountryNotes(diseaseId, countryId);

  assertIsNotStoreError(countryNotes);
  if (isLoading(countryNotes) || !countryNotes.vaccineSchedule) {
    return <Loader />;
  }

  return <VaccinationSection data={[countryNotes.vaccineSchedule]} />;
};
