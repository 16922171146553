import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { Action as GenericAction, ActionWithPayload, Resource } from 'store/types';

import { reducer } from './reducer';

export type Action =
  | GenericAction<'Vaccines/LoadInitiated'>
  | ActionWithPayload<'Vaccines/Loaded', VaccineWithSlug[]>
  | ActionWithPayload<'Vaccines/LoadFailed', StoreError>
  | GenericAction<'Vaccines/Toxicity/LoadInitiated'>
  | ActionWithPayload<'Vaccines/Toxicity/Loaded', Toxicity[]>
  | ActionWithPayload<'Vaccines/Toxicity/LoadFailed', StoreError>
  | GenericAction<'Vaccines/Contraindications/LoadInitiated'>
  | ActionWithPayload<'Vaccines/Contraindications/Loaded', Contraindications[]>
  | ActionWithPayload<'Vaccines/Contraindications/LoadFailed', StoreError>
  | GenericAction<'Vaccines/Filter/LoadInitiated'>
  | ActionWithPayload<'Vaccines/Filter/Loaded', Vaccine[]>
  | ActionWithPayload<'Vaccines/Filter/LoadFailed', StoreError>
  | GenericAction<'VaccinesDetailed/LoadInitiated'>
  | ActionWithPayload<'VaccinesDetailed/Loaded', DetailedVaccine[]>
  | ActionWithPayload<'VaccinesDetailed/LoadFailed', StoreError>;

export interface Vaccine {
  id: number;
  name: string;
}

export interface VaccineWithSlug extends Vaccine {
  slug: string;
}

export interface DetailedVaccine extends Vaccine {
  type: string;
}

export interface Toxicity {
  id: string;
  name: string;
}

export interface Contraindications {
  id: string;
  name: string;
}

export interface Vaccines {
  vaccines: Resource<VaccineWithSlug[]> | null;
  toxicities: Resource<Toxicity[]> | null;
  contraindications: Resource<Contraindications[]> | null;
  filteredVaccines: Resource<Vaccine[]> | [];
  detailedVaccines: Resource<DetailedVaccine[]> | null;
}

export type State = Vaccines;

const initialState: State = {
  vaccines: null,
  toxicities: null,
  contraindications: null,
  filteredVaccines: [],
  detailedVaccines: null,
};

export const {
  dispatchContext: VaccinesDispatchContext,
  stateContext: VaccinesStateContext,
  provider: VaccinesStoreProvider,
} = storeFactory(reducer, initialState);
