import { Icon, SvgComponent } from 'Atoms/Icon';
import { Label } from 'Atoms/Label';
import { CompleteTooltip } from 'Atoms/tooltip/CompleteTooltip';
import { AsyncSelect, Props as AsyncProps } from 'Molecules/select/AsyncSelect';
import { SearchSelect } from 'Molecules/select/SearchSelect';
import React, { FC } from 'react';
import { ActionMeta, OnChangeValue } from 'react-select';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CaptionWrapper = styled.div`
  position: relative;
  display: flex;
`;

const Caption = styled(Label)`
  margin: 0 0 10px 0;
`;

const Tooltip = styled(CompleteTooltip)`
  position: absolute;
  top: -5px;
  right: -25px;
  cursor: pointer;

  width: 17px;
  height: 17px;
`;

const StyledSelect = styled(SearchSelect)`
  min-width: 210px;
  max-width: 370px;

  .${props => props.classNamePrefix}__control--menu-is-open {
    .${props => props.classNamePrefix}__single-value {
      display: none;
    }
  }
`;

const StyledIcon = styled(Icon)`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

interface Props {
  className?: string;
  caption: string;
  placeholder: string;
  tooltipInfo?: string;
  selectOptions: SelectOption[];
  onChange?: (
    selected: OnChangeValue<SelectOption, false>,
    actionMeta: ActionMeta<SelectOption>
  ) => void;
  name?: string;
  value?: string | null;
  isSearchable?: boolean;
  icon?: SvgComponent;
  id: string;
}

export const SelectWithTooltip: FC<Props> = ({
  className,
  caption,
  placeholder,
  selectOptions,
  onChange,
  tooltipInfo,
  name,
  value,
  isSearchable,
  icon,
  id,
}) => {
  const selectedOption = selectOptions.find(x =>
    value === null ? x.value === 'null' : x.value === value
  );

  return (
    <Wrapper className={className}>
      <CaptionWrapper>
        {icon && <StyledIcon svgComponent={icon} />}
        <Caption color="main" size="medium" htmlFor={id}>
          {caption}
        </Caption>
        {tooltipInfo && <Tooltip content={tooltipInfo} />}
      </CaptionWrapper>

      <StyledSelect
        placeholder={placeholder}
        options={selectOptions}
        showDropdown
        onChange={onChange}
        classNamePrefix="search-select"
        name={name}
        value={selectedOption}
        isSearchable={isSearchable}
        inputId={id}
      />
    </Wrapper>
  );
};

const StyledAsyncSelect = styled(AsyncSelect<SelectOption, false>())`
  && {
    min-width: 210px;
    max-width: 370px;
  }
`;

interface AsyncSelectProps extends AsyncProps<SelectOption, false> {
  className?: string;
  caption: string;
  icon?: SvgComponent;
}

export const AsyncSelectWithTooltip: FC<AsyncSelectProps> = ({
  className,
  caption,
  icon,
  inputId,
  ...rest
}) => {
  return (
    <Wrapper className={className}>
      <CaptionWrapper>
        {icon && <StyledIcon svgComponent={icon} />}
        <Caption color="main" size="medium" htmlFor={inputId}>
          {caption}
        </Caption>
      </CaptionWrapper>

      <StyledAsyncSelect inputId={inputId} {...rest} />
    </Wrapper>
  );
};
