import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { Loader } from './Loader';

const slide = keyframes`
  0% { transform:translateX(-100%); }
  50% { transform:translateX(100%); }
`;

const Container = styled.div`
  height: 220px;
  max-height: 100vh; // This is to guaranty that if screen is to small and threshold doesn't fit if element is too big
  width: 100%;
  background: ${props => props.theme.colors.lazyLoadPlaceholder.background};
  position: relative;
  overflow: hidden;
`;

const Shine = styled.div`
  top: 0;
  transform: translateX(100%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  animation: ${slide} 2s infinite;
  background: ${props => props.theme.colors.lazyLoadPlaceholder.shine};
`;

interface Props {
  className?: string;
  height?: number;
  width?: number;
  showShine?: boolean;
  showLoader?: boolean;
  noBackground?: boolean;
}

export const LazyLoadPlaceholder: FC<Props> = ({
  className,
  height,
  showShine = true,
  showLoader = true,
  noBackground,
}) => {
  return (
    <Container
      className={className}
      style={{ height, backgroundColor: noBackground ? 'transparent' : undefined }}
    >
      {showShine && <Shine />}
      {showLoader && <Loader />}
    </Container>
  );
};
