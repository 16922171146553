import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { DrugSummarySection } from 'Molecules/drug/DrugSummarySection';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import { getStringFromDynamicHtml } from 'Organisms/dynamicContent/DynamicHtmlToString';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import { Paywall } from 'Organisms/paywall/Paywall';
import { SmartCompareButton } from 'Organisms/SmartCompareButton';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { Element } from 'react-scroll';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { useDrugDetailsPublic } from 'store/drugDetailsStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import { isRecordLocationState } from 'types/record';

type Props = RouteComponentProps & {
  drugId: number;
};

export const DrugPublic: FC<Props> = ({ drugId, location }) => {
  const [showSummary, setShowSummary] = useState(true);

  const drug = useDrugDetailsPublic(drugId);

  const synonymName = isRecordLocationState(location.state)
    ? location.state.synonymName
    : undefined;

  if (isLoading(drug)) {
    return <Loader />;
  }

  assertIsNotStoreError(drug);

  const description = `GIDEON - ${drug.drug}. Mechanism of Action: ${getStringFromDynamicHtml(
    drug.mechanismOfAction
  )}`;

  const sections: RecordLink[] = [
    'Summary',
    'Warnings',
    'Trade names',
    'Spectrum',
    'Downloads and references',
  ].map(section => ({
    label: section,
    'aria-label': `${drug.drug} ${section.toLowerCase()}`,
  }));

  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>{drug.drug} - GIDEON</title>
          <meta name="description" content={description} />
        </Helmet>
        <ReferenceLabelContext.Provider value={drug.drug}>
          <RecordLayout>
            <RecordTitle
              title={drug.drug}
              synonymName={synonymName}
              buttons={<SmartCompareButton section="drugs" id={drug.code} size="big" showTooltip />}
            />
            <RecordLinks links={sections} />
            <RecordSection
              link={sections[0]}
              show={showSummary}
              onCollapse={() => setShowSummary(!showSummary)}
              accent="first"
              id="drug-public-summary"
            >
              <DrugSummarySection record={drug} />
            </RecordSection>
            <Element name="Warnings">
              <Element name="Trade names">
                <Element name="Spectrum">
                  <Element name="Downloads and references">
                    <Paywall />
                  </Element>
                </Element>
              </Element>
            </Element>
          </RecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};
