import { ReactComponent as ArrowRightSvg } from 'assets/UI/ArrowRight.svg';
import { Button } from 'Atoms/buttons/Button';
import { Icon } from 'Atoms/Icon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const ArrowRight = styled(ArrowRightSvg)`
  fill: ${props => props.theme.colors.mobileNavigationRow.default.borderColor};
  width: 20px;
  height: 20px;

  margin: 0 0 0 auto;
`;

const ButtonStyled = styled(Button)`
  width: 100%;
  background: ${props => props.theme.colors.mobileNavigationRow.default.backgroundColor};
  border: 1px solid ${props => props.theme.colors.mobileNavigationRow.default.borderColor};
  color: ${props => props.theme.colors.mobileNavigationRow.default.textColor};
  border-radius: 1px;
  padding: 15px 15px 15px 4%;
  display: flex;
  box-shadow: none;

  & + ${Button} {
    border-top: 0;
  }

  &:active {
    padding: 13px 15px 13px 4%;
    border-top: 0px solid;
    border-color: ${props => props.theme.colors.mobileNavigationRow.active.borderColor};
    color: ${props => props.theme.colors.mobileNavigationRow.active.textColor};
    border-width: 3px;
    margin-top: -1px;

    &:first-child {
      margin-top: 0px;
    }

    ${ArrowRight} {
      fill: ${props => props.theme.colors.mobileNavigationRow.active.borderColor};
    }
  }
`;

interface Props {
  className?: string;
  caption: string;
  onClick?(): void;
}

export const MobileIndexNavigationButton: FC<Props> = ({ caption, className, onClick }) => {
  return (
    <ButtonStyled weight="500" size="normal" className={className} onClick={onClick}>
      {caption}
      <Icon svgComponent={ArrowRight} />
    </ButtonStyled>
  );
};
