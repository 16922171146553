import { EtymologyDTO } from "apiServices/Microbes/DTO";
import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { DynamicContent } from "types/dynamicContent";

const checkFirstCapitalLetter = (s: string): boolean => s[0] === s[0].toUpperCase();

export const parseEtymologyData = (etymology: EtymologyDTO[]): DynamicContent => {
  etymology.sort((a, b) => {
    if (checkFirstCapitalLetter(a.term) && !checkFirstCapitalLetter(b.term)) return -1;
    if (!checkFirstCapitalLetter(a.term) && checkFirstCapitalLetter(b.term)) return 1;
    return 0;
  })

  return parseDynamicContent(etymology.map(item => {
    let referenceTexts = "";
    if (item.references && item.references.length > 0) {
      referenceTexts = item.references.map(r => `{${r.reference_number}}`).join(' ');
    }
    return `<i>${item.term}</i>: ${item.meaning} ${referenceTexts}`;
  }).join('\n'));
}