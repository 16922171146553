import { Span } from 'Atoms/text';
import { rgba } from 'polished';
import React, { FC, ReactNode } from 'react';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'services/useMeasure.hook';
import styled from 'styled-components/macro';

const padding = 5;

interface StyleProps {
  type: 'error' | 'success';
}

const Container = styled(animated.div)<StyleProps>`
  width: 100%;
  border: solid
    ${props =>
      props.type === 'error' ? props.theme.colors.text.error : props.theme.colors.trial.success};
  border-radius: 4px;
  background-color: ${props =>
    props.type === 'error'
      ? rgba(props.theme.colors.text.error, 0.07)
      : rgba(props.theme.colors.trial.success, 0.1)};

  padding: 0 10px;
`;

const SpanStyled = styled(Span)<StyleProps>`
  color: ${props =>
    props.type === 'error' ? props.theme.colors.text.error : props.theme.colors.trial.success};
`;

interface Props {
  className?: string;
  message?: ReactNode;
  type: 'error' | 'success';
  id?: string;
}

const zeroValues = {
  height: 0,
  borderWidth: 0,
  marginTop: 0,
  paddingTop: 0,
  paddingBottom: 0,
};

export const FormMessage: FC<Props> = ({ className, message, type, id }) => {
  const [ref, bounds] = useMeasure<HTMLDivElement>();

  const values = message
    ? {
        height: bounds.height + 2 * padding,
        borderWidth: 1,
        marginTop: 10,
        paddingTop: padding,
        paddingBottom: padding,
      }
    : zeroValues;

  const props = useSpring(values);

  return (
    <Container
      id={id}
      className={className}
      style={{
        ...props,
        overflow: props.height.to(x => (x !== bounds.height ? 'hidden' : 'visible')),
      }}
      type={type}
    >
      <div ref={ref}>
        <SpanStyled type={type} role="alert" aria-live="polite">
          {message}
        </SpanStyled>
      </div>
    </Container>
  );
};
