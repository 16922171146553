import { isStoreError } from 'store/storeError';
import { isLoading, Resource } from 'store/types';
import { CompareDrug, CompareDrugKeyType, CompareDrugRows } from 'types/compare';

import { generateArray } from '../../utils/generateArray';

export type ColumnsGeneratorData = {
  code: number;
  name: string;
  category: string;
};

export const getKeyInfo = (type: CompareDrugKeyType): string => {
  switch (type) {
    case 'Star': {
      return 'Drug of choice';
    }
    case 'Check': {
      return 'Included in known spectrum of drug';
    }
    case 'Combine': {
      return 'Used in combination';
    }
    case 'Surgery': {
      return 'Excision or surgery often required';
    }
    default:
      return '';
  }
};

export const getRows = (data: Resource<CompareDrug>[]): CompareDrugRows => {
  const pathogens: CompareDrugRows = {};

  const loadedData = data.map(x => {
    if (!isStoreError(x) && !isLoading(x)) {
      return x;
    } else return undefined;
  });

  loadedData.forEach((drug, i) => {
    drug &&
      drug.pathogens.forEach(d => {
        if (!pathogens[d.pathogen]) {
          pathogens[d.pathogen] = {
            value: d.pathogenCode,
            label: d.pathogen,
            drugColumns: generateArray(10, null),
            microbiologyAgent:
              d.microbiologyAgents.length > 0 ? d.microbiologyAgents[0] : undefined,
          };
        }
        pathogens[d.pathogen].drugColumns[i] = [
          d.drugOfChoice,
          true,
          d.usedInCombination,
          d.excisionRequired || d.surgeryRequired,
        ];
      });
  });

  return pathogens;
};
