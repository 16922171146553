import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { CountryNotes, CountryNotesPublic } from 'types/countryNotes';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'CountryNotes/SingleLoadInitiated', { indentifier: string }>
  | ActionWithPayload<'CountryNotes/SingleLoaded', { indentifier: string; data: CountryNotes }>
  | ActionWithPayload<'CountryNotes/SingleLoadFailed', { indentifier: string; error: StoreError }>
  | ActionWithPayload<'CountryNotes/Public/SingleLoadInitiated', { indentifier: string }>
  | ActionWithPayload<
      'CountryNotes/Public/SingleLoaded',
      { indentifier: string; data: CountryNotesPublic }
    >
  | ActionWithPayload<
      'CountryNotes/Public/SingleLoadFailed',
      { indentifier: string; error: StoreError }
    >;

export type State = {
  countryNotes: { [indentifier: string]: Resource<CountryNotes> | undefined };
  countryNotesPublic: { [indentifier: string]: Resource<CountryNotesPublic> | undefined };
};

const initialState: State = {
  countryNotes: {},
  countryNotesPublic: {},
};

export const {
  dispatchContext: CountryNotesDispatchContext,
  stateContext: CountryNotesStateContext,
  provider: CountryNotesStoreProvider,
} = storeFactory(reducer, initialState);
