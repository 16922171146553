import { Link } from 'Atoms/links/Link';
import { ErrorBoundary } from 'errorBoundary';
import { ContentError } from 'Molecules/ContentError';
import { DynamicTable } from 'Organisms/dynamicContent/DynamicTable';
import { ParserError } from 'Organisms/dynamicContent/ParserError';
import { ReferenceMarker } from 'Organisms/dynamicContent/ReferenceMarker';
import {
  CountryVaccinationSchedule,
  CountryVaccinationScheduleForDisease,
} from 'Organisms/dynamicContent/VaccinationSchedule';
import { GraphAndTableLazy } from 'Organisms/GraphAndTable';
import React, { FC, ReactElement } from 'react';
import {
  DynamicContent,
  Node,
  ReferenceMNode,
  ReferenceNode,
  ReferencePNode,
  TextNode,
} from 'types/dynamicContent';

import { ReferenceGroupMarker } from './ReferenceGroupMarker';

interface BuilderProps {
  node: Node;
}

const renderChildren = (children: Node[]): ReactElement[] => {
  let referenceArray: {
    key: number;
    child: ReferenceNode;
  }[] = [];
  const renderContent = [];
  let index = 0;
  let loopMore = true;

  while (index < children.length) {
    const loopChild = children[index];

    if (loopChild.type.indexOf('reference') > -1) {
      const child = loopChild as ReferenceMNode | ReferencePNode | ReferenceNode;
      let checkIndex = -1;
      if (child.type === 'reference m' || child.type === 'reference p') {
        checkIndex = referenceArray.findIndex(
          temp => temp.child.code === child.type.split(' ')[1] && temp.child.value === child.value
        );
      } else if (child.type === 'reference') {
        checkIndex = referenceArray.findIndex(
          temp => temp.child.code === child.code && temp.child.value === child.value
        );
      }
      if (checkIndex === -1) {
        referenceArray.push({
          key: index,
          child: {
            type: 'reference',
            code:
              child.type === 'reference m' || child.type === 'reference p'
                ? child.type.split(' ')[1]
                : child.code,
            value: child.value,
          },
        });
      }

      if (children.length >= 3 && index <= children.length - 3) {
        if (children[index + 1].type === 'text') {
          const nextChild = children[index + 1] as TextNode;
          if (
            nextChild.value.trim().length === 0 &&
            children[index + 2].type.indexOf('reference') > -1
          ) {
            index++;
          } else {
            loopMore = false;
          }
        } else if (children[index + 1].type.indexOf('reference') === -1) {
          loopMore = false;
        }
      } else if (
        children.length >= 2 &&
        index <= children.length - 2 &&
        children[index + 1].type.indexOf('reference') === -1
      ) {
        loopMore = false;
      } else {
        loopMore = false;
      }

      if (!loopMore) {
        if (referenceArray.length >= 3) {
          renderContent.push(
            <DynamicHtmlBuilder
              key={'group' + index}
              node={{
                type: 'reference_group',
                children: referenceArray.map(item => item.child),
              }}
            />
          );
        } else {
          for (const item of referenceArray) {
            renderContent.push(<DynamicHtmlBuilder key={item.key} node={item.child} />);
            renderContent.push(
              <DynamicHtmlBuilder key={'space' + item.key} node={{ type: 'text', value: ' ' }} />
            );
          }
        }
        loopMore = true;
        referenceArray = [];
      }
    } else {
      renderContent.push(<DynamicHtmlBuilder key={index} node={loopChild} />);
    }

    index++;
  }

  return renderContent;
};

const DynamicHtmlBuilder = ({ node }: BuilderProps): ReactElement => {
  switch (node.type) {
    case 'text': {
      return <>{node.value}</>;
    }
    case 'b': {
      return <b>{renderChildren(node.children)}</b>;
    }
    case 'i': {
      return <i>{renderChildren(node.children)}</i>;
    }
    case 'u': {
      return <u>{renderChildren(node.children)}</u>;
    }
    case 'newLine': {
      return <br />;
    }
    case 'reference m': {
      return <ReferenceMarker type="m" value={node.value} />;
    }
    case 'reference p': {
      return <ReferenceMarker type="p" value={node.value} />;
    }
    case 'reference': {
      return <ReferenceMarker type={node.code} value={node.value} />;
    }
    case 'reference_group': {
      return <ReferenceGroupMarker data={node.children} />;
    }
    case 'ul': {
      return <ul>{renderChildren(node.children)}</ul>;
    }
    case 'li': {
      return <li>{renderChildren(node.children)}</li>;
    }
    case 'Link':
      return <Link to={node.to}>{renderChildren(node.children)}</Link>;
    case 'chart': {
      return (
        <ErrorBoundary error={props => <ContentError title="Chart Error" {...props} />}>
          <GraphAndTableLazy
            graphId={node.id}
            diseaseId={node.diseaseId}
            countryId={node.countryId}
          />
        </ErrorBoundary>
      );
    }
    case 'table': {
      return (
        <ErrorBoundary error={props => <ContentError title="Outbreaks Table Error" {...props} />}>
          <DynamicTable
            tableType={node.tableType}
            diseaseId={node.diseaseId}
            countryId={node.countryId}
            title={node.title === 'Notable outbreaks' ? 'Outbreaks' : node.title}
            subTitle={node.subTitle}
          />
        </ErrorBoundary>
      );
    }
    case 'vaccineSchedule': {
      const diseaseId = parseInt(node.diseaseId, 10);
      return (
        <ErrorBoundary error={props => <ContentError title="Vaccine Schedule Error" {...props} />}>
          {diseaseId >= 0 ? (
            <CountryVaccinationScheduleForDisease
              countryId={node.countryId}
              diseaseId={diseaseId}
            />
          ) : (
            <CountryVaccinationSchedule countryId={node.countryId} />
          )}
        </ErrorBoundary>
      );
    }
    case 'unsupported': {
      return React.createElement(node.tagName, node.attributes, renderChildren(node.children));
    }
    case 'parsererror': {
      return <ParserError node={node} />;
    }
    default: {
      const _ignore: never = node;
      return <></>; // this should be unreachable
    }
  }
};

export interface Props {
  content: DynamicContent;
}

export const DynamicHtml: FC<Props> = ({ content }) => {
  const result = (
    <ErrorBoundary error={props => <ContentError title="Dynamic Content Error" {...props} />}>
      {renderChildren(content)}
    </ErrorBoundary>
  );

  return result;
};

export const dynamicContentToString = (dynamicContent: DynamicContent): string => {
  let value = '';

  for (let i = 0; i < dynamicContent.length; i++) {
    const node = dynamicContent[i];
    switch (node.type) {
      case 'text':
        value += node.value;
        break;
      case 'reference m':
      case 'reference p':
        value += ' ';
        break;
      case 'i':
      case 'b':
      case 'u':
        value += dynamicContentToString(node.children);
        break;
      default:
        break;
    }
  }

  return value;
};
