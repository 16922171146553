import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { LatestOutbreak } from 'types/countryLatestOutbreaks';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'CountryLatestOutbreaks/SingleLoadInitiated', { id: string }>
  | ActionWithPayload<'CountryLatestOutbreaks/SingleLoaded', { id: string; data: LatestOutbreak[] }>
  | ActionWithPayload<'CountryLatestOutbreaks/SingleLoadFailed', { id: string; error: StoreError }>;

export type State = {
  [id: string]: Resource<LatestOutbreak[]>;
};

const initialState: State = {};

export const {
  dispatchContext: CountryLatestOutbreaksDispatchContext,
  stateContext: CountryLatestOutbreaksStateContext,
  provider: CountryLatestOutbreaksStoreProvider,
} = storeFactory(reducer, initialState);
