import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { Action as GenericAction, ActionWithPayload, Resource } from 'store/types';

import { reducer } from './reducer';

export type Action =
  | GenericAction<'Diseases/LoadInitiated'>
  | ActionWithPayload<'Diseases/Loaded', Disease[]>
  | ActionWithPayload<'Diseases/LoadFailed', StoreError>;

export interface Disease {
  id: number;
  name: string;
  slug: string;
}

export interface Diseases {
  data: {
    [id: number]: Disease;
  };
  list: number[];
}

export type State = Resource<Diseases> | null;

const initialState: State = null;

export const {
  dispatchContext: DiseasesDispatchContext,
  stateContext: DiseasesStateContext,
  provider: DiseasesStoreProvider,
} = storeFactory(reducer, initialState);
