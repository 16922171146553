import { getTrendingData } from 'Explore/apiServices/trending/trending';
import React, { useEffect } from 'react';
import { newStoreError } from 'store/storeError';
import { Dispatch, Resource } from 'store/types';
import { TrendingData } from 'types/trending';

import { Action, State, TrendingDispatchContext,TrendingStateContext } from './provider';

export const useState = (): State => {
  const state = React.useContext(TrendingStateContext);
  if (state === undefined) {
    throw new Error('Trending store is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(TrendingDispatchContext);
  if (dispatch === undefined) {
    throw new Error('Trending store is not initialized');
  }
  return dispatch;
};

export const useTrendingData = (section: string): Resource<TrendingData> => {
  const state = useState();
  const dispatch = useDispatch();

  const trending = state[section];

  useEffect(() => {
    if (!trending) {
      dispatch({ type: 'Trending/LoadInitiated', payload: { section } });
      getTrendingData(section)
        .then(data => {
          dispatch({ type: 'Trending/Loaded', payload: { section, data } });
        })
        .catch(err => {
          dispatch({
            type: 'Trending/LoadFailed',
            payload: { section, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, section, trending]);

  return trending;
};