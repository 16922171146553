import { getVaccineSynonyms } from 'apiServices/Vaccines/vaccines';
import { ReactComponent as ContraindicationsIcon } from 'assets/Button/ContraindicationsIcon.svg';
import { ReactComponent as ToxicityIcon } from 'assets/Button/ToxicityIcon.svg';
import { ReactComponent as All } from 'assets/UI/All.svg';
import { ReactComponent as Antibody } from 'assets/UI/Antibody.svg';
import { ReactComponent as Antigen } from 'assets/UI/Antigen.svg';
import {
  ExploreLayoutInnerContent,
  ExploreLayoutSelectTitle,
} from 'Atoms/explore/ExploreLayoutContent';
import { Label } from 'Atoms/Label';
import { H1 } from 'Atoms/text/H';
import { LandingMap } from 'Explore/Organisms/LandingMap';
import { TrendingNow } from 'Explore/Organisms/TrendingNow';
import { MapMainLayout } from 'layouts/MapMainLayout';
import { SearchByTypeButton } from 'Molecules/buttons/SearchByTypeButton';
import { SearchByModal } from 'Molecules/modal/SearchByModal';
import { ExploreSelectWithFilter, SelectStyled } from 'Organisms/ExploreSelectWithFilter';
import React, { FC, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { OnChangeValue } from 'react-select';
import { useMobile } from 'services/useMobile';
import { useSelectSynonyms } from 'services/useSelectSynonyms.hook';
import { useDetailedVaccinesList, useVaccinesFiltered } from 'store/vaccinesStore/hooks';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';
import { mapResource, mapToSearchSelectOption } from 'utils/mapResource';

const MainLayoutStyled = styled(MapMainLayout)`
  min-height: 800px;
  position: relative;
`;

const StyledTitle = styled(H1)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 70px;
  z-index: 2;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    top: 20px;
  }
`;

const TrendingNowStyled = styled(TrendingNow)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 190px;
  z-index: 2;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    top: 120px;
  }
`;

const LabelStyled = styled(Label)`
  margin: 20px 0 0 0;
`;

const ContentContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 260px;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 0;
    top: 180px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  max-width: 600px;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    margin: 30px 50px;
  }
`;

const ButtonStyled = styled(SearchByTypeButton)`
  max-height: 44px;
  margin: 10px 10px 0;
  padding: 10px;
`;

const ExploreSelectWithFilterStyled = styled(ExploreSelectWithFilter)`
  width: 98%;

  ${SelectStyled} {
    max-width: 400px;
  }
`;

const filterSelectOptions: SelectOption[] = [
  {
    value: 'all',
    label: 'All',
    Icon: All,
  },
  {
    value: 'vaccine',
    label: 'Vaccines',
    Icon: Antigen,
  },
  {
    value: 'globulin',
    label: 'Immunoglobulins',
    Icon: Antibody,
  },
];

export const Vaccines: FC = () => {
  const isMobile = useMobile('s');
  const [vaccinesState, getVaccinesFiltered, total] = useVaccinesFiltered();
  const vaccinesList = useDetailedVaccinesList();
  const [isToxicityOpen, setToxicityOpen] = useState<boolean>(false);
  const [isContraindicationsOpen, setContraindicationsOpen] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState(filterSelectOptions[0]);

  const [toxicity, setToxicity] = useState<SelectOption | null>(null);
  const [contraindication, setContraindication] = useState<SelectOption | null>(null);
  const toxicityOptions = mapResource(vaccinesState.toxicities, mapToSearchSelectOption);
  const contraindicationOptions = mapResource(
    vaccinesState.contraindications,
    mapToSearchSelectOption
  );

  const onChangeContraindications = (value: OnChangeValue<SelectOption, false>): void => {
    setContraindication(value);
    if (value) {
      getVaccinesFiltered('contraindications', value.value);
    }
  };

  const onChangeToxicity = (value: OnChangeValue<SelectOption, false>): void => {
    setToxicity(value);
    if (value) {
      getVaccinesFiltered('toxicity', value.value);
    }
  };

  const selectOptions = useMemo(() => {
    const filteredVaccines =
      filterValue.value === 'all'
        ? vaccinesList
        : vaccinesList.filter(v => v.type === filterValue.value);

    return mapToSearchSelectOption(filteredVaccines);
  }, [filterValue.value, vaccinesList]);

  const loadSynonymOptions = async (inputValue: string): Promise<SelectOption[]> => {
    const synonyms = await getVaccineSynonyms(inputValue);

    return synonyms
      .filter(s => selectOptions.some(so => so.value === s.id.toString()))
      .map(x => ({
        label: x.name,
        value: x.id.toString(),
        synonymOf: vaccinesList.find(vaccine => vaccine.id === x.id)?.name,
      }));
  };

  const { onChange, loadOptions } = useSelectSynonyms(
    'vaccines',
    selectOptions,
    loadSynonymOptions
  );

  return (
    <MainLayoutStyled>
      <Helmet>
        <title>Explore vaccines - GIDEON</title>
        <meta
          name="description"
          content="GIDEON vaccines database contains data on vaccines and immunoglobulins. It also includes tools to find vaccines by toxicity and contraindications."
        />
      </Helmet>
      <StyledTitle color="exploreLandingTitle" font="Quicksand">
        Explore GIDEON data on vaccines and immunoglobulins
      </StyledTitle>
      <TrendingNowStyled section="vaccines" />
      <ContentContainer>
        <InnerContainer role="group" aria-labelledby="explore-vaccines-title">
          <ExploreLayoutSelectTitle id="explore-vaccines-title" />
          <LabelStyled size="small" color="main" htmlFor="explore-select-input">
            Filter and search across all vaccine records
          </LabelStyled>
          <ExploreSelectWithFilterStyled
            filterOptions={filterSelectOptions}
            filterValue={filterValue}
            onFilterChange={selected => selected && setFilterValue(selected)}
            defaultOptions={selectOptions}
            onChange={onChange}
            loadOptions={loadOptions}
            placeholder={
              isMobile ? 'Enter generic | trade name' : 'Start typing generic or trade name'
            }
            filterAriaLabel="Vaccine type"
            selectInputId="explore-select-input-vaccines" // Unique ID for cypress testing
          />
        </InnerContainer>
        <ExploreLayoutInnerContent>
          <ButtonStyled Icon={ToxicityIcon} onClick={() => setToxicityOpen(true)}>
            Toxicity
          </ButtonStyled>
          <ButtonStyled Icon={ContraindicationsIcon} onClick={() => setContraindicationsOpen(true)}>
            Contraindications
          </ButtonStyled>
        </ExploreLayoutInnerContent>
      </ContentContainer>
      <SearchByModal
        title="Searching for vaccines and immunoglobulins by Toxicity"
        subTitle="Toxicity is any untoward effect of a drug or vaccine."
        placeholder="Type a letter or select from the list"
        isOpen={isToxicityOpen}
        onClose={() => setToxicityOpen(false)}
        options={toxicityOptions}
        onChange={onChangeToxicity}
        type="vaccines"
        results={vaccinesState.filteredVaccines}
        total={total}
        value={toxicity}
        hideCompareButton
      />
      <SearchByModal
        title="Searching for vaccines and immunoglobulins by Contraindications"
        subTitle="A specific situation in which a vaccine should not be used because it may be harmful to the person."
        placeholder="Type a letter or select from the list"
        isOpen={isContraindicationsOpen}
        onClose={() => setContraindicationsOpen(false)}
        options={contraindicationOptions}
        onChange={onChangeContraindications}
        value={contraindication}
        type="vaccines"
        results={vaccinesState.filteredVaccines}
        total={total}
        hideCompareButton
      />
      <LandingMap section="vaccines" />
    </MainLayoutStyled>
  );
};
