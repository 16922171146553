import { Icon } from 'Atoms/Icon';
import styled from 'styled-components/macro';

export const InvisibleButton = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  text-align: left;
  transition-duration: 0.1s;
  transition-property: background, border, box-shadow, color;
  transition-timing-function: ease-in;

  padding: 0;
  color: ${props => props.theme.colors.button.default.textColor};

  &:hover {
    border: none;
    background: none;
    color: ${props => props.theme.colors.button.hover.textColor};
    ${Icon} {
      fill: ${props => props.theme.colors.button.hover.textColor};
    }
  }

  &:focus {
    outline: 2px solid ${props => props.theme.colors.button.focus.outline};
  }

  ${Icon} {
    fill: ${props => props.theme.colors.button.default.textColor};
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      border: none;
      background: none;
      color: ${props => props.theme.colors.button.default.textColor};
      ${Icon} {
        fill: ${props => props.theme.colors.button.default.textColor};
      }
    }
  }
`;
