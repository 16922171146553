import { AZList } from 'Molecules/az/AZList';
import React, { FC } from 'react';
import { match, Redirect, Route, Switch } from 'react-router-dom';
import { useCountriesGrouped } from 'store/countriesStore/hooks';
import { useDrugsGrouped } from 'store/drugsStore/hooks';
import { useVaccinesGrouped } from 'store/vaccinesStore/hooks';
import { CategoryType, diseases, DiseaseType } from 'types/az';

import { AZDiseasesDesktop } from './AZDiseasesDesktop';
import { AZDiseasesMobile } from './AZDiseasesMobile';
import { AZMicrobesDesktop } from './AZMicrobesDesktop';
import { AZMicrobesMobile } from './AZMicrobesMobile';

export interface Props {
  match: match;
  isMobile?: boolean;
  name: string;
  category?: CategoryType | DiseaseType;
  exact?: boolean;
}

export const AZDiseases: FC<Props> = ({ match, isMobile = false, name, category, exact }) => {
  if (isMobile) {
    return (
      <AZDiseasesMobile
        match={match}
        category={category || 'diseases'}
        title={name}
        exact={exact}
      />
    );
  } else {
    return <AZDiseasesDesktop match={match} category={category || 'diseases'} title={name} />;
  }
};

export const AZDiseasesWrapper: FC<Props> = ({ match, isMobile = false, name }) => {
  return (
    <Switch>
      <Route
        path={`${match.url}/all`}
        render={({ match: newMatch }) => (
          <AZDiseases
            match={newMatch}
            isMobile={isMobile}
            name={name}
            category={'diseases'}
            exact={false}
          />
        )}
      />
      {diseases.map((d, index) => (
        <Route
          key={index}
          path={`${match.url}/${d}`}
          render={({ match: newMatch }) => (
            <AZDiseases
              match={newMatch}
              isMobile={isMobile}
              name={name}
              category={d}
              exact={false}
            />
          )}
        />
      ))}
      {isMobile && (
        <Route
          exact
          render={({ match: newMatch }) => (
            <AZDiseases
              match={newMatch}
              isMobile={isMobile}
              name={name}
              category={'diseases'}
              exact={true}
            />
          )}
        />
      )}
      {!isMobile && <Redirect exact to={`${match.url}/all`} />}
    </Switch>
  );
};

export const AZDrugs: FC<Props> = ({ match, isMobile = false, name }) => {
  const drugs = useDrugsGrouped();

  return <AZList data={drugs} category="drugs" match={match} isMobile={isMobile} title={name} />;
};

export const AZVaccines: FC<Props> = ({ match, isMobile = false, name }) => {
  const vaccines = useVaccinesGrouped();

  return (
    <AZList data={vaccines} category="vaccines" match={match} isMobile={isMobile} title={name} />
  );
};

export const AZMicrobes: FC<Props> = ({ match, isMobile = false, name }) => {
  if (isMobile) {
    return <AZMicrobesMobile match={match} title={name} />;
  } else {
    return <AZMicrobesDesktop match={match} title={name} />;
  }
};

export const AZCountries: FC<Props> = ({ match, isMobile = false, name }) => {
  const countries = useCountriesGrouped();

  return (
    <AZList data={countries} category="countries" match={match} isMobile={isMobile} title={name} />
  );
};
