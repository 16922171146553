import diseasesLightBackground from 'assets/Backgrounds/diseases_light.jpeg';
import drugsLightBackground from 'assets/Backgrounds/drugs_light.jpeg';
import microbesLightBackground from 'assets/Backgrounds/microbes_light.jpeg';
import vaccinesLightBackground from 'assets/Backgrounds/vaccines_light.jpeg';
import { ReactComponent as BrokenLinkSvg } from 'assets/EmailConfirm/BrokenLink.svg';
import { ReactComponent as ClockSvg } from 'assets/Expiration/Clock.svg';
import { ReactComponent as AgentSvg } from 'assets/Fingerprint/Agent.svg';
import { ReactComponent as CountrySvg } from 'assets/Fingerprint/Country.svg';
import { ReactComponent as ReservoirSvg } from 'assets/Fingerprint/Reservoir.svg';
import { ReactComponent as VectorSvg } from 'assets/Fingerprint/Vector.svg';
import { ReactComponent as VehicleSvg } from 'assets/Fingerprint/Vehicle.svg';
import FullLogoPng from 'assets/full-logo.png';
import { ReactComponent as AccountSvg } from 'assets/Login/Account.svg';
import { ReactComponent as InstitutionSvg } from 'assets/Login/Institution.svg';
import { ReactComponent as LockSvg } from 'assets/Login/Lock.svg';
import { ReactComponent as PasswordSvg } from 'assets/Login/Password.svg';
import { ReactComponent as ReadingSvg } from 'assets/Login/Reading.svg';
import { ReactComponent as UserSvg } from 'assets/Login/User.svg';
import LogoPng from 'assets/logo.png';
import { ReactComponent as eBookSVG } from 'assets/UI/eBookLight.svg';
import { ReactComponent as Help } from 'assets/UI/HelpLight.svg';
import { ReactComponent as LightbulbSvg } from 'assets/UI/Lightbulb.svg';
import { ReactComponent as PasswordHide } from 'assets/UI/PasswordHideLight.svg';
import { ReactComponent as PasswordShow } from 'assets/UI/PasswordShowLight.svg';
import PlusBlack from 'assets/UI/PlusBlack.svg';
import { rgba } from 'polished';
import { DefaultTheme } from 'styled-components/macro';
import { updatesLightThemeIcons } from 'Updates/services/updatesLightThemeIcons';

import { commonTheme } from './commonTheme';

export const lightTheme: DefaultTheme = {
  ...commonTheme,
  colors: {
    background: {
      primary: '#FFFFFF',
      navbar: '#FFFFFF',
      secondaryNavbar: '#04384A',
      secondaryNavbarActiveLink: rgba('#FFFFFF', 0.1),
      footer: rgba('#FFFFFF', 0.9),
      searchBar: '#f9f9f9',
      modalCardHeader: '#F8F9FA',
      modalCardBody: '#FFFFFF',
      recordTitle: '#FFFFFF',
      lightPopup: '#009FD5',
      darkPopup: '#005B7A',
      differentialDiagnosisPreview: rgba('#000000', 0.01),
    },
    button: {
      default: {
        backgroundColor: '#FFFFFF',
        borderColor: '#4F4F4F',
        textColor: '#4F4F4F',
      },
      hover: {
        backgroundColor: '#FFFFFF',
        borderColor: '#055B7A',
        textColor: '#055B7A',
      },
      active: {
        backgroundColor: '#FFFFFF',
        borderColor: '#009FD5',
        textColor: '#009FD5',
      },
      disabled: {
        backgroundColor: 'transparent',
        borderColor: rgba(73, 80, 87, 0.6),
        textColor: rgba(73, 80, 87, 0.6),
      },
      focus: {
        backgroundColor: '#FFFFFF',
        borderColor: '#4F4F4F',
        textColor: '#4F4F4F',
        outline: '#007BFF',
      },
    },
    filledButton: {
      default: {
        default: {
          backgroundColor: '#FFFFFF',
          borderColor: '#4F4F4F',
          textColor: '#055B7A',
          boxShadow: '0 1px 4px rgba(0, 0, 0, 0.6)',
        },
        hover: {
          backgroundColor: '#14607A',
          borderColor: '#055B7A',
          textColor: '#FFFFFF',
          boxShadow:
            '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
        active: {
          backgroundColor: '#0ABFFC',
          borderColor: '#0ABFFC',
          textColor: '#000000',
        },
        focus: {
          backgroundColor: '#FFFFFF',
          borderColor: '#4F4F4F',
          textColor: '#055B7A',
          outline: '#007BFF',
        },
      },
      light: {
        default: {
          backgroundColor: '#FFFFFF',
          borderColor: '#4F4F4F',
          textColor: '#4F4F4F',
          boxShadow:
            '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.2)',
        },
        hover: {
          backgroundColor: '#FFFFFF',
          borderColor: '#055B7A',
          textColor: '#055B7A',
          boxShadow:
            '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.2)',
        },
        active: {
          backgroundColor: '#EFFAFF',
          borderColor: '#009FD5',
          textColor: '#009FD5',
          boxShadow:
            '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.2)',
        },
        focus: {
          backgroundColor: '#FFFFFF',
          borderColor: '#4F4F4F',
          textColor: '#4F4F4F',
          outline: '#007BFF',
        },
      },
      dark: {
        default: {
          backgroundColor: '#004F6B',
          borderColor: '#004F6B',
          textColor: rgba('#FFFFFF', 0.8),
          boxShadow: '0px 1px 4px 0px rgba(2,42,56,0.4)',
        },
        hover: {
          backgroundColor: '#202124',
          borderColor: '#00ACEA',
          textColor: '#7FCFEA',
          boxShadow:
            '0px 4px 4px rgba(2,42,56, 0.25), 0px 4px 4px rgba(2,42,56, 0.25), 0px 4px 4px rgba(2,42,56, 0.25)',
        },
        active: {
          backgroundColor: '#131617',
          borderColor: '#00ACEA',
          textColor: '#00ACEA',
        },
        focus: {
          backgroundColor: '#004F6B',
          borderColor: '#004F6B',
          textColor: rgba('#FFFFFF', 0.8),
          outline: '#007BFF',
        },
      },
      special: {
        default: {
          backgroundColor: '#F4C74F',
          borderColor: '#F4C74F',
          textColor: '#000000',
        },
        hover: {
          backgroundColor: '#14607A',
          borderColor: '#055B7A',
          textColor: '#FFFFFF',
        },
        active: {
          backgroundColor: '#0ABFFC',
          borderColor: '#0ABFFC',
          textColor: '#000000',
        },
        focus: {
          backgroundColor: '#FFFFFF',
          borderColor: '#FFFFFF',
          textColor: '#000000',
          outline: '#007BFF',
        },
      },
    },
    reportButton: {
      default: {
        backgroundColor: '#FFFFFF',
        textColor: '#055B7A',
        borderColor: '#4F4F4F',
      },
      hover: {
        backgroundColor: '#FFFFFF',
        textColor: '#055B7A',
        borderColor: '#055B7A',
      },
      icon: '#C70E0E',
    },
    circleButton: {
      default: {
        borderColor: '#595959',
        backgroundColor: '#FFFFFF',
        textColor: '#4F4F4F',
      },
      hover: {
        borderColor: '#055B7A',
        backgroundColor: '#FFFFFF',
        textColor: '#055B7A',
      },
    },
    nextButton: {
      default: {
        borderColor: '#AAAAAA',
        backgroundColor: '#FFFFFF',
        textColor: '#4F4F4F',
      },
      hover: {
        borderColor: '#51CF66',
        backgroundColor: '#51CF66',
        textColor: '#FFFFFF',
      },
      active: {
        borderColor: '#51CF66',
        backgroundColor: '#FFFFFF',
        textColor: '#000000',
      },
      focus: {
        borderColor: '#AAAAAA',
        backgroundColor: '#FFFFFF',
        textColor: '#4F4F4F',
      },
    },
    yesNoButton: {
      default: {
        backgroundColor: 'transparent',
        borderColor: '#AAAAAA',
        textColor: '#4F4F4F',
      },
      hover: {
        backgroundColor: 'transparent',
        borderColor: '#055B7A',
        textColor: '#4F4F4F',
      },
      active: {
        backgroundColor: '#0077A0',
        borderColor: '#0077A0',
        textColor: '#FFFFFF',
      },
      focus: {
        backgroundColor: 'transparent',
        borderColor: '#AAAAAA',
        textColor: '#4F4F4F',
      },
    },
    select: {
      default: {
        background: '#FFFFFF',
        border: '#4F4F4F',
        text: '#4F4F4F',
        placeholder: '#4F4F4F',
        menuBackground: '#FFFFFF',
        multiOption: {
          text: '#FFFFFF',
          background: '#0077A0',
          closeButtonBackground: rgba(127, 207, 234, 0.5),
          closeButton: '#000000',
        },
      },
      active: {
        border: '#0077A0',
        text: '#004F6B',
      },
      hover: {
        option: rgba(127, 207, 234, 0.2),
      },
      focus: {
        border: '#007BFF',
      },
    },
    modal: {
      base: {
        overlayBackground: rgba(0, 0, 0, 0),
      },
      afterOpen: {
        overlayBackground: rgba(0, 0, 0, 0.4),
      },
      beforeClose: {
        overlayBackground: rgba(0, 0, 0, 0),
      },
    },
    scrollbar: {
      track: '#DDDDDD',
      thumb: '#055B7A',
    },
    lightScrollbar: {
      track: '#FFFFFF',
      thumb: '#C4C4C4',
    },
    breadcrumb: {
      containerBorderColor: '#DEE2E6',
      menuBackgroundColor: '#FFFFFF',
      default: {
        borderColor: '#595959',
        backgroundColor: '#FFFFFF',
        textColor: '#0077A0',
        iconColor: '#495057',
        selectOptionColor: '#595959',
        selectOptionBackgroundColor: rgba(127, 207, 234, 0.2),
      },
      hover: {
        borderColor: '#0077A0',
        backgroundColor: '#FFFFFF',
        textColor: '#0077A0',
        iconColor: '#0077A0',
        selectOptionColor: '#595959',
        selectOptionBackgroundColor: rgba(127, 207, 234, 0.2),
      },
    },
    mobileSecondaryNavbar: {
      borderColor: '#DEE2E6',
    },
    azListSection: {
      borderColor: '#009FD5',
    },
    tab: {
      default: '#4F4F4F',
      hover: '#7FCFEA',
      active: '#055B7A',
      border: '#AAAAAA',
      borderActive: '#009FD5',
      focus: '#007BFF',
    },
    mobileNavigationRow: {
      default: {
        borderColor: '#4F4F4F',
        textColor: '#4F4F4F',
        backgroundColor: 'transparent',
      },
      active: {
        borderColor: '#009FD5',
        textColor: '#009FD5',
        backgroundColor: 'transparent',
      },
    },
    mobileNavbarLink: {
      default: {
        backgroundColor: 'transparent',
        borderColor: 'none',
        textColor: '#495057',
      },
      active: {
        backgroundColor: '#055B7A',
        borderColor: '#009FD5',
        textColor: '#FFFFFF',
      },
    },
    accountMenu: {
      default: {
        backgroundColor: '#FFFFFF',
        borderColor: '#AAAAAA',
        textColor: '#495057',
      },
      hover: {
        backgroundColor: '#FFFFFF',
        borderColor: '#0077A0',
        textColor: '#0077A0',
      },
    },
    startItem: {
      border: '#595959',
      background: '#FFFFFF',
      disabledBackground: rgba(255, 255, 255, 0.8),
      disabledIcon: '#AAAAAA',
    },
    checkbox: {
      defaultBorder: '#595959',
      hoverBorder: '#055B7A',
    },
    radio: {
      border: '#AAAAAA',
      borderChecked: '#0077A0',
      background: 'transparent',
      backgroundChecked: rgba(128, 207, 234, 0.2),
      checkmark: '#0077A0',
      checkmarkBorder: '#FFFFFF',
    },
    datepicker: {
      border: '#AAAAAA',
      focus: '#0077A0',
      placeholder: '#AAAAAA',
      text: '#000000',
      icon: '#595959',
      calendar: {
        background: '#FFFFFF',
        arrowButtonBackground: '#FFFFFF',
        hoverBackground: '#e4e7e7',
        weeksBackground: '#FFFFFF',
        border: '#4a4a4a',
        shortcuts: {
          background: '#FFFFFF',
          text: '#000000',
        },
      },
    },
    search: {
      default: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        boxShadowColor: 'transparent',
      },
      hover: {
        backgroundColor: 'transparent',
        borderColor: '#4F4F4F',
        boxShadowColor: 'transparent',
      },
    },
    text: {
      main: '#000000',
      mainBold: '#000000',
      timeStamp:'#4F4F4F',
      secondary: '#FFFFFF',
      inactive: '#4F4F4F',
      navbar: '#495057',
      navbarActive: '#055B7A',
      secondaryNavbar: '#FFFFFF',
      secondaryNavbarActive: '#FFFFFF',
      darkLink: '#055B7A',
      lightLink: '#0077A0',
      input: '#595959',
      selectInput: '#004F6B',
      errorTitle: '#595959',
      modalCardTitle: '#495057',
      exploreLandingTitle: '#000000',
      disabledNavLink: rgba(73, 80, 87, 0.6),
      error: '#C70E0E',
    },
    toast: {
      success: {
        text: '#000000',
        background: '#0ACD10',
        outline: '#007BFF',
      },
      error: {
        background: '#A82315',
        text: '#FFFFFF',
        outline: '#007BFF',
      },
      info: {
        background: '#0054AD',
        text: '#FFFFFF',
        outline: '#007BFF',
      },
      warning: {
        background: '#F4C74F',
        text: '#000000',
        outline: '#F4C74F',
      },
    },
    switch: {
      on: '#00BCD4',
      off: '#4f4f4f',
    },
    recordBorder: {
      first: '#7FCFEA',
      second: '#009FD5',
      third: '#128DB8',
      fourth: '#0077A0',
      fifth: '#004F6B',
    },
    recordSection: {
      shadow: 'transparent',
      border: rgba(128, 207, 234, 0.2),
    },
    imageGallery: {
      default: '#055B7A',
      hover: '#009FD5',
      indexBackground: '#4F4F4F',
      progressBar: '#055B7A',
      progressBarActive: '#009FD5',
    },
    table: {
      border: '#0077A0',
      heading: {
        backgroundColor: '#0077A0',
        text: '#FFFFFF',
      },
      row: {
        backgroundColor: '#FFFFFF',
        backgroundHoverColor: '#E8F7FC',
        text: '#000000',
      },
      scrollShadow: rgba(0, 0, 20, 0.5),
      sortButton: '#FFFFFF',
      compareTable: {
        border: '#AAAAAA',
        sortButton: '#4F4F4F',
        headingText: '#4F4F4F',
        shadowRight: 'linear-gradient(to left, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%)',
        shadowLeft: 'linear-gradient(to right, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%)',
        shadowArrow: '#0077A0',
        icon: '#0077A0',
        probabilityKeyBackground: rgba(0, 0, 0, 0.04),
        probabilityKeyBorder: rgba('#80CFEA', 0.2),
        sectionBorder: rgba(170, 170, 170, 0.8),
      },
    },
    separator: {
      light: '#AAAAAA',
      dark: '#595959',
      colored: '#009fd5',
    },
    listItem: {
      border: '#DEE2E6',
    },
    scrollToTopButton: {
      background: '#FFFFFF',
      border: '#009FD5',
    },
    comparisonColors: {
      Missing: '#7D7D7D',
      Never: '#495057',
      Rarely: '#C70E0E',
      Often: '#a0630f',
      Usually: '#057618',
      Always: '#055b7a',
    },
    stepIndicator: {
      default: '#AAAAAA',
      active: '#0077A0',
      completed: '#51CF66',
      completedNegative: '#C70E0E',
    },
    ageSlider: {
      sliderSelected: '#51CF66',
      slider: '#AAAAAA',
      buttonIcon: '#1971C2',
    },
    progressBar: {
      background: '#E9ECEF',
      progress: '#51CF66',
    },
    stepTreeProgressBar: {
      background: '#E9ECEF',
      fillColors: ['#C70E0E', '#D67D00', '#089E20'],
    },
    chip: {
      background: '#0077A0',
      text: '#FFFFFF',
    },
    tooltip: {
      background: '#000000',
      text: '#FFFFFF',
    },
    method: {
      default: {
        background: '#FFFFFF',
        border: '#4F4F4F',
      },
      hover: {
        background: '#FFFFFF',
        border: '#055B7A',
      },
      labels: {
        classic: {
          background: '#F4C74F',
          border: '#F4C74F',
          text: '#000000',
        },
        interactive: {
          background: '#7FCFEA',
          border: '#7FCFEA',
          text: '#000000',
        },
      },
    },
    graphsTable: {
      head: {
        desktop: {
          background: 'transparent',
          text: '#000000',
        },
        mobile: {
          background: '#0077A0',
          text: '#FFFFFF',
        },
      },
      row: {
        highlight: '#F1F3F5',
        hover: rgba('#7FCFEA', 0.15),
        active: rgba('#7FCFEA', 0.35),
        activeBorder: '#AAAAAA',
      },
    },
    buttonHoverBackground: '#e8f8fc',
    symptomGroupButton: {
      background: '#FFFFFF',
      border: '#009FD5',
      hoverBackground: '#F5FCFF',
    },
    point: {
      default: {
        background: '#FFFFFF',
        border: '#009FD5',
      },
      hover: {
        background: '#FF2B2B',
        border: '#009FD5',
      },
      active: {
        background: '#FF2B2B',
        border: '#000000',
      },
    },
    labTree: {
      treeElementButton: {
        default: {
          background: '#FFFFFF',
          border: '#4F4F4F',
          text: '#4F4F4F',
          containerBackground: '#F9F9F9',
          icon: '#4F4F4F',
        },
        active: {
          background: '#FFFFFF',
          border: '#0049B8',
          text: '#0049B8',
          icon: '#4F4F4F',
        },
        hover: {
          background: '#FFFFFF',
          border: '#0049B8',
          text: '#0049B8',
        },
      },
      buttonBorder: '#DEE2E6',
      border: '#4F4F4F',
      background: '#F9F9F9',
      overlay: {
        background: '#F4C74F',
      },
      previewList: {
        closedActiveBackground: '#0077A0',
      },
    },
    treeIndicator: {
      active: {
        text: '#0049B8',
        tick: '#0049B8',
      },
      completed: {
        text: '#000000',
        tick: '#089E20',
      },
      inactive: {
        text: '#4F4F4F',
        tick: '#4F4F4F',
      },
    },
    finding: {
      border: {
        default: '#AAAAAA',
        active: '#0077A0',
        inactive: '#AAAAAA',
      },
      icon: {
        default: '#4F4F4F',
        active: '#0077A0',
        inactive: '#AAAAAA',
      },
      background: {
        default: 'transparent',
        active: '#EFFAFF',
        inactive: 'transparent',
      },
      text: {
        default: '#4F4F4F',
        active: '#055B7A',
        inactive: '#666666',
      },
    },
    settings: {
      sectionBorder: rgba('#80CFEA', 0.2),
      sectionSeparator: '#7FCFEA',
      inputBorder: '#009FD5',
      inputText: '#055B7A',
    },
    accent: {
      1: '#C70E0E',
      2: '#D67D00',
      3: '#089E20',
      4: '#008EAD',
      5: '#495057',
      6: '#F8F9FA',
      7: '#7FCFEA',
    },
    loader: '#009FD5',
    lazyLoadPlaceholder: {
      background: '#EEEEEE',
      shine: `linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(125, 185, 232, 0) 100%);`,
    },
    outbreaksMap: {
      noData: '#ccc',
      endemicColors: {
        threeLevelEndemic: ['#DDF9F6', '#0CD3CE', '#0E6D90'],
        fiveLevelEndemic: ['#DDF9F6', '#0CD3CE', '#0B9F9B', '#0E6D90', '#074156'],
        sixLevelEndemic: ['#DDF9F6', '#71FAF6', '#0CD3CE', '#0B9F9B', '#0E6D90', '#074156'],
      },
      accessibilityEndemicColors: {
        threeLevelEndemic: ['#deebf7', '#9ecae1', '#3182bd'],
        fiveLevelEndemic: ['#eff3ff', '#9ecae1', '#6baed6', '#3182bd', '#08519c'],
        sixLevelEndemic: ['#eff3ff', '#c6dbef', '#9ecae1', '#6baed6', '#3182bd', '#08519c'],
      },
      border: '#dddddd',
      label: '#555',
    },
    expirationButtons: {
      order: '#055B7A',
      quote: '#0077A0',
      talk: '#009FD5',
      text: '#FFFFFF',
    },
    sectionHead: {
      background: '#0077A0',
      border: '#009FD5',
    },
    updatesActiveSection: {
      background: '#F7FAFC',
      border: '#0ABFFC',
    },
    paywall: {
      overlay: `linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255))`,
    },
    referencesPage: {
      message: { background: '#FFFCF2', border: '#F4C74F' },
    },
    videoButton: {
      background: '#FFFFFF',
      border: '#222222',
      color: '#4F4F4F',
    },
    trial: {
      socialProof: {
        border: '#F0F0F0',
        background: '#FFFFFF',
      },
      success: '#089E20',
    },
    whyNotItem: {
      button: '#F4C74F',
      border: '#F4C74F',
      text: '#000000',
    },
    focus: '#007BFF',
    helpTooltip: '#007BFF',
    countriesTitle: '#D7D7D7',
    comparisonCount: '#055B7A',
  },
  images: {
    logo: FullLogoPng,
    mobileLogo: LogoPng,
    compareButtonPlus: PlusBlack,
    compareLightbulb: LightbulbSvg,
    user: UserSvg,
    password: PasswordSvg,
    lock: LockSvg,
    institution: InstitutionSvg,
    reading: ReadingSvg,
    account: AccountSvg,
    eBooks: eBookSVG,
    fingerprint: {
      agent: AgentSvg,
      vector: VectorSvg,
      vehicle: VehicleSvg,
      reservoir: ReservoirSvg,
      country: CountrySvg,
    },
    updates: updatesLightThemeIcons,
    brokenLink: BrokenLinkSvg,
    clock: ClockSvg,
    help: Help,
    passwordHide: PasswordHide,
    passwordShow: PasswordShow,
  },
  backgroundImages: {
    diseases: {
      desktop: `linear-gradient(rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.45)), url(${diseasesLightBackground}) white left bottom / contain no-repeat`,
      mobile: `linear-gradient(rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.45)), url(${diseasesLightBackground}) white left bottom / contain no-repeat`,
    },
    drugs: {
      desktop: `url(${drugsLightBackground}) white center center / cover no-repeat fixed`,
      mobile: `url(${drugsLightBackground}) white center center / cover no-repeat fixed`,
    },
    microbes: {
      desktop: `linear-gradient(rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35)), url(${microbesLightBackground}) white center center / cover no-repeat fixed`,
      mobile: `linear-gradient(rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35)), url(${microbesLightBackground}) white top 50% right -100px / cover no-repeat fixed`,
    },
    vaccines: {
      desktop: `linear-gradient(rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35)), url(${vaccinesLightBackground}) white top 100px right -100px / contain no-repeat fixed`,
      mobile: `url(${vaccinesLightBackground}) white top 50% right -100px / contain no-repeat fixed`,
    },
  },
  mapBackground: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))`
};
