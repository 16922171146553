import {
  getReferenceDetailsPublic,
  getReferencesDetails,
  getReferencesDetailsPublic,
} from 'apiServices/references/references';
import React, { useEffect } from 'react';
import { usePushState } from 'services/usePushState.hook';
import { useAccountController } from 'store/accountStore/hooks';
import { newStoreError } from 'store/storeError';
import { Dispatch, Resource } from 'store/types';
import { ReferenceDetails, ReferenceIdentifier } from 'types/reference';

import { Action, ReferencesDispatchContext, ReferencesStateContext, State } from './provider';

export const useState = (): State => {
  const state = React.useContext(ReferencesStateContext);
  if (state === undefined) {
    throw new Error('References store is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(ReferencesDispatchContext);
  if (dispatch === undefined) {
    throw new Error('References store is not initialized');
  }
  return dispatch;
};

export const useReferenceData = (ids: ReferenceIdentifier[]): Resource<ReferenceDetails>[] => {
  const state = useState();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAccountController();

  const idsToFetch = ids.filter(id => state[id] === undefined);

  const fetch = isAuthenticated ? getReferencesDetails : getReferencesDetailsPublic;

  useEffect(() => {
    if (idsToFetch.length > 0) {
      dispatch({ type: 'References/LoadInitiated', payload: { ids: idsToFetch } });
      fetch(idsToFetch)
        .then(data => {
          dispatch({ type: 'References/Loaded', payload: data });
        })
        .catch(err => {
          dispatch({
            type: 'References/LoadFailed',
            payload: idsToFetch.map(id => ({
              id,
              error: newStoreError(err.message, err.code, err),
            })),
          });
        });
    }
  }, [dispatch, fetch, idsToFetch]);

  return ids.filter(id => state[id] !== undefined).map(id => state[id]);
};

export const usePublicReferenceData = (
  id: string,
  sourceCode: string
): Resource<ReferenceDetails | undefined> => {
  const state = useState();
  const dispatch = useDispatch();
  const { push } = usePushState();

  const stateId = `${sourceCode} ${id}`;

  const reference = state[stateId];

  useEffect(() => {
    if (!reference) {
      dispatch({ type: 'References/LoadInitiated', payload: { ids: [stateId] } });
      getReferenceDetailsPublic(id, sourceCode)
        .then(data => {
          dispatch({ type: 'References/Loaded', payload: [data] });
        })
        .catch(err => {
          dispatch({
            type: 'References/LoadFailed',
            payload: [
              {
                id: stateId,
                error: newStoreError(err.message, err.code, err),
              },
            ],
          });
        });
    }
  }, [dispatch, id, push, reference, sourceCode, stateId]);

  return reference;
};
