import {
  getLoginRequestsReport,
  getLoginRequestsSummary,
  updateAccountSettings,
} from 'apiServices/account/accountSettings';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { P } from 'Atoms/text';
import { ExternalToastLink, Toast } from 'Atoms/toast/Toast';
import moment, { Moment } from 'moment';
import { Props as UsageLogsProps, UsageLogs } from 'Organisms/AccountForm/Subsections/UsageLogs';
import React, { FC, FormEvent, useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch as useAccountDispatch } from 'store/accountStore/hooks';
import styled from 'styled-components/macro';
import { AccountSettings, LoginRequestReportFilterKey } from 'types/accountSettings';

import { Access } from './Subsections/Access';
import { ContactDetails } from './Subsections/ContactDetails';
import { EmailChangeModal } from './Subsections/EmailChangeModal';
import { GDPR } from './Subsections/GDPR';
import { GDPRDeleteModal } from './Subsections/GDPRDeleteModal';
import { PasswordChangeModal } from './Subsections/PasswordChangeModal';
import { PersonalInformation } from './Subsections/PersonalInformation';
import { Preferences } from './Subsections/Preferences';
import { PubmedLinkout } from './Subsections/PubmedLinkout';
import { Subscription } from './Subsections/Subscription';

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Sections = styled.div`
  display: flex;
  width: 100%;
  padding: 0 20px;
  justify-content: center;
  flex-wrap: wrap;
`;

const Section = styled.div`
  border: 1px solid ${props => props.theme.colors.settings.sectionBorder};
  border-radius: 10px;
  width: 100%;
  max-width: 420px;
  height: 550px;
  margin-top: 30px;
  padding: 15px;
  &:not(:last-child) {
    margin-right: 10px;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    height: unset;
  }
`;

const SaveButton = styled(FilledButton)`
  margin-top: 50px;
  max-width: 90px;
  padding: 7px 14px;
`;

export interface Props {
  inputState: AccountSettings;
  onInputChange: (value: string, type: string) => void;
  setInputState: (newState: AccountSettings) => void;
}

export const AccountForm: FC<Props> = ({ ...props }) => {
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [gdprModalOpen, setGdprModalOpen] = useState(false);

  const accountDispatch = useAccountDispatch();
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [startDate, setStartDate] = useState<Moment | null>(moment().startOf('year'));
  const [endDate, setEndDate] = useState<Moment | null>(moment());
  const [custUserId, setCustUserId] = useState<string>();
  const [groupId, setGroupId] = useState<string>();
  const [subscriptionId, setSubscriptionId] = useState<string>();

  const [usageLogFormMessage, setUsageLogFormMessage] = useState<string | null>(null);

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (isSubmiting) {
      return;
    }

    setIsSubmiting(true);

    updateAccountSettings(props.inputState)
      .then(updatedSettings => {
        setErrorMessage(null);
        toast.success(<Toast type="success" content="Account information updated!" />);
        accountDispatch({ type: 'Account/Loaded', payload: updatedSettings });
      })
      .catch(err => {
        setErrorMessage(err.message || 'Sign in failed');
      })
      .finally(() => setIsSubmiting(false));
  };

  const setStartDateWrapper = (value: Moment | null): void => {
    setStartDate(value);
    setUsageLogFormMessage(null);
  };

  const setEndDateWrapper = (value: Moment | null): void => {
    setEndDate(value);
    setUsageLogFormMessage(null);
  };

  const validateUsageLogForm = useCallback((): string | null => {
    if (!startDate || !endDate) {
      return 'Start and end dates must be provided.';
    }

    return null;
  }, [endDate, startDate]);

  const getFilterKey = useCallback((): LoginRequestReportFilterKey | undefined => {
    if (custUserId) {
      return 'cust_id';
    } else if (groupId) {
      return 'group_id';
    } else if (subscriptionId) {
      return 'subscription_id';
    }
  }, [custUserId, groupId, subscriptionId]);

  const getFileName = useCallback(
    (startDate: Moment, endDate: Moment, suffix: string): string => {
      return `${
        props.inputState.institution && `${props.inputState.institution}_`
      }${startDate.format('YYYY-MM-DD')}_to_${endDate.format('YYYY-MM-DD')}_gideon_${suffix}.csv`;
    },
    [props.inputState.institution]
  );

  const onClickAccessLog = useCallback((): void => {
    const error = validateUsageLogForm();

    if (error) {
      setUsageLogFormMessage(error);
      return;
    }

    if (isSubmiting || !startDate || !endDate) {
      return;
    }

    setIsSubmiting(true);

    getLoginRequestsReport(
      startDate,
      endDate,
      getFilterKey(),
      custUserId || groupId || subscriptionId
    )
      .then(report => {
        toast.success(
          <Toast
            type="success"
            content={
              <>
                Your access log report is ready.{' '}
                <ExternalToastLink
                  type="success"
                  to={`data:text/csv;charset=utf-8,${escape(report)}`}
                  download={getFileName(startDate, endDate, 'access_log_report')}
                  openInNewTab
                >
                  Download here
                </ExternalToastLink>
              </>
            }
          />
        );
      })
      .finally(() => setIsSubmiting(false));
  }, [
    custUserId,
    endDate,
    getFileName,
    getFilterKey,
    groupId,
    isSubmiting,
    startDate,
    subscriptionId,
    validateUsageLogForm,
  ]);

  const onClickSummary = useCallback((): void => {
    const error = validateUsageLogForm();

    if (error) {
      setUsageLogFormMessage(error);
      return;
    }

    if (isSubmiting || !startDate || !endDate) {
      return;
    }

    setIsSubmiting(true);

    getLoginRequestsSummary(
      startDate,
      endDate,
      getFilterKey(),
      custUserId || groupId || subscriptionId
    )
      .then(report => {
        toast.success(
          <Toast
            type="success"
            content={
              <>
                Your summary report is ready.{' '}
                <ExternalToastLink
                  type="success"
                  to={`data:text/csv;charset=utf-8,${escape(report)}`}
                  download={getFileName(startDate, endDate, 'summary_report')}
                  openInNewTab
                >
                  Download here
                </ExternalToastLink>
              </>
            }
          />
        );
      })
      .finally(() => setIsSubmiting(false));
  }, [
    custUserId,
    endDate,
    getFileName,
    getFilterKey,
    groupId,
    isSubmiting,
    startDate,
    subscriptionId,
    validateUsageLogForm,
  ]);

  const onClickCalendarButton = (type: 'year' | '6 months' | 'month'): void => {
    switch (type) {
      case 'year': {
        setStartDate(moment().subtract(1, 'year').startOf('year'));
        setEndDate(moment().subtract(1, 'year').endOf('year'));
        return;
      }
      case '6 months': {
        setStartDate(moment().subtract(6, 'months'));
        setEndDate(moment());
        return;
      }
      case 'month': {
        setStartDate(moment().subtract(1, 'month').startOf('month'));
        setEndDate(moment().subtract(1, 'month').endOf('month'));
        return;
      }
    }
  };

  const usageLogSectionProps = useMemo((): UsageLogsProps | null => {
    if (props.inputState.accountType !== 'institution_admin') {
      return null;
    }

    const isOutsideStartRange = (value: Moment): boolean => value.isAfter(endDate);
    const isOutsideEndRange = (value: Moment): boolean => value.isBefore(startDate);

    const temporaryProps: UsageLogsProps = {
      isSubmiting,
      endDate,
      startDate,
      onClickAccessLog,
      onClickSummary,
      setStartDate: setStartDateWrapper,
      setEndDate: setEndDateWrapper,
      error: usageLogFormMessage,
      isOutsideStartRange,
      isOutsideEndRange,
      onClickCalendarButton,
      email: props.inputState.email,
    };

    if (props.inputState.email === 'MBucco@epnet.com') {
      return {
        ...temporaryProps,
        custUserId: { value: custUserId, onChange: setCustUserId },
      };
    } else if (props.inputState.email === 'HealthLRP-ProductStrategy@wolterskluwer.com') {
      return {
        ...temporaryProps,
        custUserId: { value: custUserId, onChange: setCustUserId },
        groupId: { value: groupId, onChange: setGroupId },
        subscriptionId: { value: subscriptionId, onChange: setSubscriptionId },
      };
    } else {
      return temporaryProps;
    }
  }, [
    custUserId,
    endDate,
    groupId,
    isSubmiting,
    onClickAccessLog,
    onClickSummary,
    props.inputState.accountType,
    props.inputState.email,
    startDate,
    subscriptionId,
    usageLogFormMessage,
  ]);

  return (
    <>
      <Form onSubmit={onSubmit} aria-label="Account information">
        <Sections>
          <Section>
            <PersonalInformation {...props} />
            <ContactDetails {...props} onEmailChangeClick={() => setEmailModalOpen(true)} />
            {props.inputState.accountType === 'institution_admin' && <PubmedLinkout />}
          </Section>
          <Section>
            <Preferences {...props} />
            <Access onChangePasswordClick={() => setPasswordModalOpen(true)} />
            <Subscription {...props} />
          </Section>
          {props.inputState.accountType === 'individual' && (
            <Section>
              <GDPR onGDPRDeleteClick={() => setGdprModalOpen(true)} />
            </Section>
          )}
          {usageLogSectionProps && (
            <Section>
              <UsageLogs {...usageLogSectionProps} />
            </Section>
          )}
        </Sections>
        {errorMessage && <P>{errorMessage}</P>}
        <SaveButton type="submit">Save</SaveButton>
      </Form>
      <PasswordChangeModal
        modalOpen={passwordModalOpen}
        onModalClose={() => setPasswordModalOpen(false)}
      />
      <EmailChangeModal modalOpen={emailModalOpen} onModalClose={() => setEmailModalOpen(false)} />
      {props.inputState.accountType === 'individual' && (
        <GDPRDeleteModal modalOpen={gdprModalOpen} onModalClose={() => setGdprModalOpen(false)} />
      )}
    </>
  );
};
