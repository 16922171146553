import { Location } from 'history';
import { Props as BreadcrumbProps } from 'Molecules/breadcrumb/Breadcrumb';
import { OnChangeValue } from 'react-select';
import { SelectOption } from 'types/select';

import { CountrySelectOption } from './country';
import { DiseaseCountries } from './disease';

export type BreadcrumbSelectOption = SelectOption | CountrySelectOption;

export interface BreadcrumbOptionsMicrobes {
  bacteria: BreadcrumbSelectOption[];
  mycobacteria: BreadcrumbSelectOption[];
  yeasts: BreadcrumbSelectOption[];
  virus: BreadcrumbSelectOption[];
}

export interface BreadcrumbOptions {
  diseases: () => BreadcrumbSelectOption[];
  diseaseCountries: (id: string) => BreadcrumbSelectOption[];
  vaccines: () => BreadcrumbSelectOption[];
  drugs: () => BreadcrumbSelectOption[];
  countries: () => BreadcrumbSelectOption[];
  microbes: () => BreadcrumbOptionsMicrobes;
}

export interface RouteContext {
  getDiseaseCountries: (diseaseId: number) => Promise<DiseaseCountries>;
  isMobile: boolean;
}

export interface BreadcrumbRoute {
  handle(
    options: BreadcrumbOptions,
    location: Location,
    context: RouteContext
  ): BreadcrumbProps | null;
}

// Breadcrumb level(s)
export type BreadcrumbLevel =
  | BreadcrumbBackLevel
  | BreadcrumbTextLevel
  | BreadcrumbSelectLevel
  | BreadcrumbLinkLevel;

export interface BreadcrumbBackLevel {
  type: 'back';
}

export const breadcrumbBackFactory = (): BreadcrumbBackLevel => {
  return {
    type: 'back',
  };
};

export interface BreadcrumbTextLevel {
  label: string;
  type: 'text';
}

export const breadcrumbTextFactory = (
  params: Omit<BreadcrumbTextLevel, 'type'>
): BreadcrumbTextLevel => {
  return {
    ...params,
    type: 'text',
  };
};

export interface BreadcrumbSelectLevel {
  options: BreadcrumbSelectOption[];
  isSearchable: boolean;
  value: (values: string[]) => string;
  onChange: (
    option: OnChangeValue<BreadcrumbSelectOption, false>,
    push: (path: string) => void,
    setLoading: (state: boolean) => void
  ) => void;
  key: string;
  type: 'select';
  ariaLabel: string;
}

export const breadcrumbSelectFactory = (
  params: Omit<BreadcrumbSelectLevel, 'type'>
): BreadcrumbSelectLevel => {
  return {
    ...params,
    type: 'select',
  };
};

export interface BreadcrumbLinkLevel {
  path: string;
  label: string;
  type: 'link';
}

export const breadcrumbLinkFactory = (
  params: Omit<BreadcrumbLinkLevel, 'type'>
): BreadcrumbLinkLevel => {
  return {
    ...params,
    type: 'link',
  };
};

// Breadcrumb level mobile
export type BreadcrumbLevelMobile = BreadcrumbBackLevel | BreadcrumbMobileLinkLevel;

export interface BreadcrumbMobileLinkLevel {
  path: string;
  label: string;
  type: 'link';
}

export const breadcrumbMobileLinkFactory = (
  params: Omit<BreadcrumbMobileLinkLevel, 'type'>
): BreadcrumbMobileLinkLevel => {
  return {
    ...params,
    type: 'link',
  };
};

// Breadcrumb select loading state
export interface BreadcrumbSelectLoading {
  isLoading: boolean;
  index: number;
}
