import { AuthRoute } from 'Atoms/routes/AuthRoute';
import { RedirectWithState } from 'Atoms/routes/RedirectWithState';
import { VariableAuthRoute } from 'Atoms/routes/VariableAuthRoute';
import { NotFound } from 'pages/404/';
import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useCleanSlug } from 'services/slug/slug.service';
import { useSlugURLUpdate } from 'services/useSlugURLUpdate.hook';

import { BioterrorismNotes } from './Bioterrorism';
import { Disease } from './Disease';
import { DiseaseInnerRoutes, Params as DiseaseInnerRoutesParams } from './DiseaseInCountry';
import { DiseasePublic } from './DiseasePublic';
import { Outbreaks } from './Outbreaks';
import { WorldwideNotes } from './WorldwideNotes';
import { WorldwideNotesPublic } from './WorldwideNotesPublic';

interface Params {
  slug: string;
}

type Props = RouteComponentProps<Params>;

export const DiseaseRoutes: FC<Props> = ({ match, history }) => {
  const { slug, id } = useCleanSlug('diseases', match.params.slug);
  useSlugURLUpdate(history, match.params.slug, slug);

  const diseaseId = parseInt(id);

  return (
    <Switch>
      <VariableAuthRoute
        exact
        path={match.url}
        render={props => <Disease {...props} diseaseId={diseaseId} />}
        renderPublic={props => <DiseasePublic {...props} diseaseId={diseaseId} />}
      />
      <RedirectWithState exact path={`${match.url}/G90`} to={`${match.url}/bioterrorism`} />
      <RedirectWithState exact path={`${match.url}/G100`} to={`${match.url}/worldwide`} />
      <VariableAuthRoute
        exact
        path={`${match.url}/worldwide`}
        render={props => <WorldwideNotes {...props} diseaseId={diseaseId} />}
        renderPublic={props => <WorldwideNotesPublic {...props} diseaseId={diseaseId} />}
      />
      <AuthRoute
        exact
        path={`${match.url}/outbreaks`}
        render={props => <Outbreaks {...props} diseaseId={diseaseId} />}
      />
      <AuthRoute
        exact
        path={`${match.url}/bioterrorism`}
        render={props => <BioterrorismNotes {...props} diseaseId={diseaseId} />}
      />
      <AuthRoute<DiseaseInnerRoutesParams>
        exact
        path={`${match.url}/:countrySlug`}
        render={props => <DiseaseInnerRoutes {...props} diseaseId={diseaseId} />}
      />
      <Route component={NotFound} />
    </Switch>
  );
};
