import { VariableAuthRoute } from 'Atoms/routes/VariableAuthRoute';
import { NotFound } from 'pages/404/';
import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { VaccineRoute } from './Vaccine';
import { Vaccines } from './Vaccines';
import { VaccinesPublic } from './VaccinesPublic';

export const VaccinesRoutes: FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <VariableAuthRoute
      exact
      path={match.url}
      component={Vaccines}
      componentPublic={VaccinesPublic}
    />
    <Route path={`${match.url}/:slug`} component={VaccineRoute} />
    <Route component={NotFound} />
  </Switch>
);
