import { Dictionary } from 'types/common';
import { escapeRegExp } from 'utils/escapeRegExp';

/**
 * Object where key is string to replace and value is new value to put in xml
 */
type Replacer = Dictionary<string>;

export const replace = (data: string, replacer: Replacer): string => {
  const escapedKeys = Object.keys(replacer).map(key => `(?:${escapeRegExp(key)})`);

  if (escapedKeys.length === 0) {
    return data;
  }

  const replaceRegex = new RegExp(`${escapedKeys.join('|')}`, 'g');

  return data.replace(replaceRegex, match => replacer[match]);
};

export const replaceCaseInsensitive = (data: string, replacer: Replacer): string => {
  // Convert replacer keys to lower case so it would fix case inconsistent issues from api
  const replacerWithLowerKeys = Object.fromEntries(
    Object.entries(replacer).map(([key, value]) => [key.toLocaleLowerCase(), value])
  );

  const escapedKeys = Object.keys(replacerWithLowerKeys).map(key => `(?:${escapeRegExp(key)})`);

  if (escapedKeys.length === 0) {
    return data;
  }

  const replaceRegex = new RegExp(`${escapedKeys.join('|')}`, 'gi');

  return data.replace(replaceRegex, match => replacerWithLowerKeys[match.toLocaleLowerCase()]);
};
