import { isStoreError } from 'store/storeError';
import { isLoading, Resource } from 'store/types';
import { SelectOption } from 'types/select';

export const mapResource = <T, R>(
  p: Resource<T> | null,
  mapperFn: (p: T) => R
): Resource<R> | null => {
  if (p === null) {
    return null;
  }

  if (isLoading(p) || isStoreError(p)) {
    return p;
  }

  return mapperFn(p);
};

type DataEntry = { id: string | number; name: string ; type?:string};

export const mapToSearchSelectOption = (arr: DataEntry[]): SelectOption[] =>
  arr.map(x => ({
    label: x.name,
    value: x.id.toString(),
    type:x.type
  }));
