import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { Action as GenericAction, ActionWithPayload, Resource } from 'store/types';
import { Search as SearchResults, SearchQueryParameters } from 'types/search';

import { reducer } from './reducer';

export type Action =
  | GenericAction<'Search/Suggestions/LoadInitiated'>
  | ActionWithPayload<'Search/Suggestions/Loaded', string[]>
  | ActionWithPayload<'Search/Suggestions/LoadFailed', StoreError>
  | GenericAction<'Search/Results/LoadInitiated'>
  | ActionWithPayload<'Search/Results/Loaded', SearchResults | null>
  | ActionWithPayload<'Search/Results/LoadFailed', StoreError>
  | GenericAction<'Search/ModuleResults/LoadInitiated'>
  | ActionWithPayload<'Search/ModuleResults/Loaded', SearchResults | null>
  | ActionWithPayload<'Search/ModuleResults/LoadFailed', StoreError>
  | GenericAction<'Search/LoadMoreInitiated'>
  | ActionWithPayload<'Search/Results/LoadedMore', SearchResults>
  | ActionWithPayload<'Search/ModuleResults/LoadedMore', SearchResults>
  | ActionWithPayload<'Search/LoadMoreFailed', StoreError>
  | ActionWithPayload<'Search/SearchValue', string>
  | GenericAction<'Search/ModuleResults/Reset'>;

export interface Search {
  suggestions: Resource<string[]>;
  results: Resource<SearchResults> | null;
  moduleResults: Resource<SearchResults> | null;
  isLoadingAdditionalResults: boolean;
  currentSearch: Omit<SearchQueryParameters, 'module' | 'recordType'>;
}

export type State = Search;

const initialState: State = {
  suggestions: [],
  results: null,
  moduleResults: null,
  isLoadingAdditionalResults: false,
  currentSearch: {
    searchValue: '',
  },
};

export const {
  dispatchContext: SearchDispatchContext,
  stateContext: SearchStateContext,
  provider: SearchStoreProvider,
} = storeFactory(reducer, initialState);
