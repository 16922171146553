import { DataDTO } from 'apiServices/common/DTO';
import axios from 'axios.cached';
import { Content, Contents } from 'Updates/types/content';

export const getContents = async (): Promise<Contents> => {
  const contents = await axios.get<DataDTO<Content[]>>('/api/public/contents/statistics');

  return contents.data.data.reduce(
    (data, value) => ({
      ...data,
      [value.content]: value,
    }),
    {}
  );
};
