export const searchSelectOptionSortCompare = (
  a: { label: string },
  b: { label: string }
): number => {
  return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
};

export const filterSelectOptionByInput = <T extends { label: string }>(
  options: T[],
  inputValue: string,
  comparator: (left: T, right: T) => number = searchSelectOptionSortCompare
): T[] => {
  const matchingStart: T[] = [];
  const matchingInnerPart: T[] = [];
  const input = inputValue.toLowerCase();

  options.sort(comparator).forEach(option => {
    const label = option.label.toLowerCase();
    if (label.startsWith(input)) {
      matchingStart.push(option);
    } else if (label.includes(input)) {
      matchingInnerPart.push(option);
    }
  });

  return matchingStart.concat(matchingInnerPart);
};

export const filterMultiSelectOptionByInput = <T extends {
  value:string; name: string; label: string 
}>(
  options: T[],
  inputValue: string,
  comparator: (left: T, right: T) => number = searchSelectOptionSortCompare
): T[] => {
  const filtered_options: T[] = [];
  const includeNotItems = ['B01', 'B02', 'B03', 'B04', 'B05', 'B06', 'B09', 'B10', 'B22', 'B23', 'B24', 'B25', 'B26', 'B27', 'B32', 'B33', 'G03', 'G07', 'G38', 'G39', 'G40', 'G41', 'G42', 'G43', 'C01', 'C04', 'C05'];
  const excludeItems = ['B34', 'C23']; // 'Not identifiable by standard tests'

  options.sort(comparator).map(x => {
    filtered_options.push({...x, value: x.value + "-1"});
    if (!excludeItems.includes(x.value)) {
      if (includeNotItems.includes(x.value))
        filtered_options.push({...x, value: x.value + "-2", label: "Not " + x.label});
      else
        filtered_options.push({...x, value: x.value + "-2", label: "No " + x.label});
    }
  })
  const matchingStart: T[] = [];
  const matchingInnerPart: T[] = [];
  const input = inputValue.toLowerCase();

  filtered_options.forEach(option => {
    const label = option.label.toLowerCase();
    if (label.startsWith(input)) {
      matchingStart.push(option);
    } else if (label.includes(input)) {
      matchingInnerPart.push(option);
    }
  });

  return matchingStart.concat(matchingInnerPart);
};