import {
  getDrugsClass,
  getDrugsInteraction,
  getDrugsSimple,
  getDrugsSpectra,
  getDrugsToxicity,
} from 'apiServices/Drugs/drugs';
import { getDrugsBySpectrumAndClass, getDrugsFiltered } from 'apiServices/Drugs/drugs';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useAccountController } from 'store/accountStore/hooks';
import { assertIsNotStoreError, newStoreError } from 'store/storeError';
import { Dispatch, isLoading } from 'store/types';
import { GroupedData } from 'types/az';
import { FilterType } from 'types/drug';
import { SelectOption } from 'types/select';
import { groupDataAlphabetically } from 'utils/getGroupedData';

import {
  Action,
  Drugs,
  DrugsDispatchContext,
  DrugsStateContext,
  DrugWithSlug,
  State,
} from './provider';

export const useState = (): State => {
  const state = useContext(DrugsStateContext);
  if (state === undefined) {
    throw new Error('drug state is not initialized');
  }

  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = useContext(DrugsDispatchContext);
  if (dispatch === undefined) {
    throw new Error('drug state is not initialized');
  }

  return dispatch;
};

export const useDrugsResource = (): Drugs => {
  const state = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!state.drugs) {
      dispatch({ type: 'Drugs/LoadInitiated' });
      getDrugsSimple()
        .then(data => dispatch({ type: 'Drugs/Loaded', payload: data }))
        .catch(err =>
          dispatch({ type: 'Drugs/LoadFailed', payload: newStoreError(err.message, err.code, err) })
        );
    }
  }, [state.drugs, dispatch]);

  useEffect(() => {
    if (!state.toxicities) {
      dispatch({ type: 'Drugs/Toxicity/LoadInitiated' });
      getDrugsToxicity()
        .then(data => dispatch({ type: 'Drugs/Toxicity/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Drugs/Toxicity/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    }
  }, [state.toxicities, dispatch]);

  useEffect(() => {
    if (!state.interactions) {
      dispatch({ type: 'Drugs/Interaction/LoadInitiated' });
      getDrugsInteraction()
        .then(data => dispatch({ type: 'Drugs/Interaction/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Drugs/Interaction/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    }
  }, [state.interactions, dispatch]);

  useEffect(() => {
    if (!state.classes) {
      dispatch({ type: 'Drugs/Classes/LoadInitiated' });
      getDrugsClass()
        .then(data => dispatch({ type: 'Drugs/Classes/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Drugs/Classes/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    }
  }, [state.classes, dispatch]);

  useEffect(() => {
    if (!state.spectra) {
      dispatch({ type: 'Drugs/Spectra/LoadInitiated' });
      getDrugsSpectra()
        .then(data => dispatch({ type: 'Drugs/Spectra/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Drugs/Spectra/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    }
  }, [state.spectra, dispatch]);

  return state;
};

export const useDrugsGrouped = (): GroupedData => {
  const list = useDrugsList();

  return { data: groupDataAlphabetically(list), total: list.length };
};

type DrugsFilterProps = {
  drugsState: State;
  filterDrugs: (type: FilterType, value: string) => void;
  filterDrugsByClassAndSpectra: (classId: string, spectrumIds: SelectOption[]) => void;
  total: number;
};

export const useDrugsFiltered = (): DrugsFilterProps => {
  const { drugs } = useDrugsResource();
  const state = useState();
  const dispatch = useDispatch();

  const filterDrugs = (type: FilterType, value: string): void => {
    dispatch({ type: 'Drugs/Filter/LoadInitiated' });
    getDrugsFiltered(type, parseInt(value))
      .then(data => dispatch({ type: 'Drugs/Filter/Loaded', payload: data }))
      .catch(err =>
        dispatch({
          type: 'Drugs/Filter/LoadFailed',
          payload: newStoreError(err.message, err.code, err),
        })
      );
  };

  const filterDrugsByClassAndSpectra = useCallback(
    (classId: string, spectrum: SelectOption[]): void => {
      dispatch({ type: 'Drugs/Filter/LoadInitiated' });
      getDrugsBySpectrumAndClass(classId, spectrum)
        .then(data => dispatch({ type: 'Drugs/Filter/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Drugs/Filter/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    },
    [dispatch]
  );

  assertIsNotStoreError(drugs);

  const total = useMemo(() => (isLoading(drugs) ? 0 : drugs ? drugs.length : 0), [drugs]);

  return { drugsState: state, filterDrugs, filterDrugsByClassAndSpectra, total };
};

export const useDrugsList = (): DrugWithSlug[] => {
  const state = useState();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAccountController();

  const drugs = state.drugs;

  useEffect(() => {
    if (!drugs) {
      dispatch({ type: 'Drugs/LoadInitiated' });
      getDrugsSimple(!isAuthenticated)
        .then(data => dispatch({ type: 'Drugs/Loaded', payload: data }))
        .catch(err =>
          dispatch({ type: 'Drugs/LoadFailed', payload: newStoreError(err.message, err.code, err) })
        );
    }
  }, [drugs, dispatch, isAuthenticated]);

  assertIsNotStoreError(drugs);

  return isLoading(drugs) || !drugs ? [] : drugs;
};

export const useGetDrugList = (): (() => DrugWithSlug[]) => {
  const state = useState();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAccountController();

  const getDrugList = useCallback(() => {
    if (!state.drugs) {
      dispatch({ type: 'Drugs/LoadInitiated' });
      getDrugsSimple(!isAuthenticated)
        .then(data => dispatch({ type: 'Drugs/Loaded', payload: data }))
        .catch(err =>
          dispatch({ type: 'Drugs/LoadFailed', payload: newStoreError(err.message, err.code, err) })
        );
    }

    assertIsNotStoreError(state.drugs);

    return isLoading(state.drugs) || !state.drugs ? [] : state.drugs;
  }, [state.drugs, dispatch, isAuthenticated]);

  return getDrugList;
};
