import { CompareOptionsWrapper } from 'Atoms/CompareOptionsWrapper';
import { CompareButtonsSection } from 'Molecules/compare/CompareButtonsSection';
import { CompareDiseasesInformation } from 'Molecules/compare/CompareDiseasesInformation';
import { CompareDiseasesTabs, DiseasesTabType } from 'Molecules/compare/CompareDiseasesTabs';
import { CompareProbabilityKeys } from 'Molecules/compare/CompareProbabilityKeys';
import { CompareTopCaption } from 'Molecules/compare/CompareTopCaption';
import { CompareFingerprintTable } from 'Organisms/compare/CompareFingerprintTable';
import { CompareTable } from 'Organisms/compare/CompareTable';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { OnChangeValue } from 'react-select';
import {
  getCompareRows,
  getProbability,
  probabilities,
} from 'services/compare/getCompareDiseaseData';
import { useCompareList, useState as useCompareListState } from 'store/ComparisonListsStore/hooks';
import { useCompareDiseases } from 'store/ComparisonStore/hooks';
import { useDiseasesResource } from 'store/diseasesStore/hooks';
import { assertIsNotStoreError, isStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { Column, CompareColumnDefinition, ProbabilityType } from 'types/compare';
import { SelectOption } from 'types/select';

const StyledTabs = styled(CompareDiseasesTabs)`
  max-width: 375px;
  margin-bottom: 25px;
  padding-right: 5px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 300px;
  }
`;

export const CompareDiseases: FC = () => {
  const [probabilityType, setProbabilityType] = useState<ProbabilityType>('words');
  const diseasesState = useDiseasesResource();
  const compareListState = useCompareListState();
  const comparedDiseases = useCompareDiseases();
  const { toggleComparison, isFull, clearList, isItemInList } = useCompareList('diseases');
  const [activeSection, setActiveSection] = useState<DiseasesTabType>('Clinical findings');

  assertIsNotStoreError(diseasesState);

  if (isLoading(diseasesState)) {
    return (
      <CompareTopCaption
        countText={`${compareListState.diseases.length} diseases`}
        options={[]}
        isListFull={isFull}
        isLoading
        compareCategory="diseases"
      />
    );
  }

  const tableEmpty: boolean = comparedDiseases.length === 0;

  const diseasesOptions = diseasesState.list.map(id => ({
    value: id.toString(),
    label: diseasesState.data[id].name,
    disabled: isItemInList(id),
  }));

  const addToCompare = (selected: OnChangeValue<SelectOption, false>): void => {
    if (selected) {
      toggleComparison(parseInt(selected.value), false);
    }
  };

  if (tableEmpty) {
    return (
      <CompareTopCaption
        countText={`${compareListState.diseases.length} diseases`}
        options={diseasesOptions}
        onChange={addToCompare}
        compareCategory="diseases"
      />
    );
  }

  const onProbabilityTypeChange = (selected: OnChangeValue<SelectOption, false>): void => {
    if (selected) {
      setProbabilityType(selected.value as ProbabilityType);
    }
  };

  const colsData: Column[] = comparedDiseases.map(x => {
    if (isStoreError(x)) {
      throw new Error('Failed to retrieve disease');
    }
    if (isLoading(x) || x === undefined) {
      return 'Loading';
    }

    return {
      id: x.id,
      category: 'diseases',
      name: diseasesState.data[x.id].name,
    };
  });

  const mappedColumns: CompareColumnDefinition[] = colsData.map(x => {
    return {
      column: x,
      enableWrap: true,
    };
  });

  const filterSameGroup = probabilityType === 'words' && comparedDiseases.length > 1;
  const rows = getCompareRows(comparedDiseases, comparedDiseases.length === 1, filterSameGroup);

  return (
    <>
      <Helmet>
        <title>Compare diseases - GIDEON</title>
        <meta
          name="description"
          content="Generate a side-by-side comparison of 361 infectious diseases: compare clinical findings or fingerprint properties and share your insights with peers."
        />
      </Helmet>
      <CompareTopCaption
        countText={`${compareListState.diseases.length} diseases`}
        options={diseasesOptions}
        onChange={addToCompare}
        setProbabilityType={setProbabilityType}
        isListFull={isFull}
        onClear={clearList}
        compareCategory="diseases"
      />
      <StyledTabs onClick={section => setActiveSection(section)} activeSection={activeSection} />

      {activeSection === 'Clinical findings' && (
        <CompareOptionsWrapper>
          <CompareProbabilityKeys
            onChange={onProbabilityTypeChange}
            probabilities={probabilities}
          />
        </CompareOptionsWrapper>
      )}
      {activeSection === 'Clinical findings' ? (
        <CompareTable
          firstColumnTitle="Clinical findings"
          columns={tableEmpty ? [] : mappedColumns}
          rows={rows}
          probabilityType={probabilityType}
          onRemove={toggleComparison}
          getProbability={getProbability}
        />
      ) : (
        <CompareFingerprintTable
          diseasesIds={compareListState.diseases}
          onRemove={toggleComparison}
        />
      )}
      <CompareDiseasesInformation selectedIds={compareListState.diseases} />
      {activeSection === 'Clinical findings' ? (
        <CompareButtonsSection category="diseases" ids={compareListState.diseases} />
      ) : (
        <CompareButtonsSection category="fingerprint" ids={compareListState.diseases} />
      )}
    </>
  );
};
