import { P } from 'Atoms/text';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { MicrobeType } from 'types/microbeDetails';

import { CompareMicrobeNotes } from './CompareMicrobeNotes';

const Wrapper = styled.div`
  margin-top: 50px;
`;

const StyledP = styled(P)`
  margin-bottom: 10px;
`;

interface Props {
  className?: string;
  selectedIds: number[];
  type: MicrobeType;
}

export const CompareMicrobeInformation: FC<Props> = ({ className, selectedIds, type }) => {
  return (
    <Wrapper className={className}>
      <StyledP size="veryBig">More about your selected {type}</StyledP>
      {selectedIds.map(id => (
        <CompareMicrobeNotes key={id} id={id} type={type} />
      ))}
    </Wrapper>
  );
};
