import { Link } from 'Atoms/links/Link';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  margin-right: 10px;
`;

interface Props {
  className?: string;
  to: string;
  linkText: string;
  children?: ReactNode;
}

export const CompareTableLink: FC<Props> = ({ className, to, linkText, children }) => {
  return (
    <Wrapper className={className}>
      <StyledLink weight="600" to={to}>
        {linkText}
      </StyledLink>
      {children}
    </Wrapper>
  );
};
