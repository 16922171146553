export const getOriginalCountryCode = (code: string): string => {
  return code === 'G291.1' || code === 'G291.2' ? 'G291' : code;
};

export const getCountryOptionSynonymImage = (synonymName: string, countryName?: string): string => {
  if (synonymName === 'Wales' || synonymName === 'England') {
    return `/Flags/${synonymName}.svg`;
  }

  return `/Flags/${countryName || synonymName}.svg`;
};
