import React, { FC } from 'react';
import { ReferenceListStoreProvider } from 'store/ReferenceListStore/provider';

import { ReferenceModalProvider } from '../../Organisms/ReferenceModalProvider';

export const ReferenceProvider: FC = ({ children }) => {
  return (
    <ReferenceListStoreProvider>
      <ReferenceModalProvider>{children}</ReferenceModalProvider>
    </ReferenceListStoreProvider>
  );
};
