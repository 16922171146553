import { Table, TD, TH, TR } from 'Atoms/table/Table';
import { P } from 'Atoms/text/P';
import { Span } from 'Atoms/text/Span';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { DynamicContent } from 'types/dynamicContent';
import { Column, Row } from 'types/table';

const StyledP = styled(P)`
  margin: 15px 0 10px;
`;

interface CustomDrugDetails {
  code: number;
  drug: string;
  mechanismOfAction: DynamicContent;
  adultDosage: DynamicContent;
  pediatricDosage: DynamicContent;
  csfPenetration: DynamicContent;
  renal80?: string;
  renal50?: string;
  renal10?: string;
  renal?: string;
  hemodialysisDosage?: string;
  peritonealDialysisDosage?: string;
  referencesLinks?: DynamicContent;
}

interface Props {
  record: CustomDrugDetails;
}

const tableColumns: Column[] = [
  { name: 'Dosage interval for creatinine clearance' },
  { name: '> 80%' },
  { name: '50 - 80%' },
  { name: '10 - 50%' },
  { name: '< 10%' },
];

export const DrugSummarySection: FC<Props> = ({ record }) => {
  const tableRow: Row =
    record.renal && record.renal10 && record.renal50 && record.renal80
      ? [
          { value: 'Hours between doses', label: 'Hours between doses' },
          { value: record.renal80, label: record.renal80 },
          { value: record.renal50, label: record.renal50 },
          { value: record.renal10, label: record.renal10 },
          { value: record.renal, label: record.renal },
        ]
      : [];

  return (
    <>
      <StyledP weight="700">Mechanism of Action</StyledP>
      <DynamicHtml content={record.mechanismOfAction} />
      <StyledP weight="700">Typical Adult Dosage</StyledP>
      <DynamicHtml content={record.adultDosage} />
      <StyledP weight="700">Typical Pediatric Dosage</StyledP>
      <DynamicHtml content={record.pediatricDosage} />
      <StyledP weight="700">CSF Penetration (inflamed meninges):</StyledP>
      <DynamicHtml content={record.csfPenetration} />
      {tableRow.length > 1 && (
        <>
          <StyledP weight="700">Typical dosage for renal dysfunction</StyledP>
          <Table
            headings={
              <TR>
                {tableColumns.map(({ name }) => (
                  <TH key={name}>{name}</TH>
                ))}
              </TR>
            }
            rows={
              <TR>
                {tableRow.map((rowdata, j) => (
                  <TD key={j}>{rowdata.label ? rowdata.label : '-'}</TD>
                ))}
              </TR>
            }
          />
        </>
      )}
      {record.hemodialysisDosage && (
        <StyledP>
          <Span weight="700">Additional dose given following hemodialysis:&nbsp;</Span>
          <Span>{record.hemodialysisDosage}</Span>
        </StyledP>
      )}
      {record.peritonealDialysisDosage && (
        <StyledP>
          <Span weight="700">Dosage given during continuous peritoneal dialysis:&nbsp;</Span>
          <Span>{record.peritonealDialysisDosage}</Span>
        </StyledP>
      )}
      {record.referencesLinks && (
        <>
          <StyledP weight="700">Reference links</StyledP>
          <DynamicHtml content={record.referencesLinks} />
        </>
      )}
    </>
  );
};
