import { DumbCompareButton } from 'Molecules/buttons/CompareButton';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const StyledCompareButton = styled(DumbCompareButton)`
  width: 15px;
  height: 15px;
  margin: 0px;
  display: inline-flex;
  transform: translateY(-3px);
  cursor: unset;
`;

interface Props {
  className?: string;
  compareOnly?: boolean;
}

export const CompareExplanation: FC<Props> = ({ className, compareOnly }) => {
  if (compareOnly) {
    return (
      <div className={className}>
        Click <StyledCompareButton clicked={false} /> to add to the comparison table.
      </div>
    );
  }
  return (
    <div className={className}>
      Click on the disease name to open its record. Click <StyledCompareButton clicked={false} /> to
      add to the comparison table.
    </div>
  );
};
