import { H1, H2 } from 'Atoms/text/H';
import { MainLayout } from 'layouts/MainLayout';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

const StyledLayout = styled(MainLayout)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TopTitle = styled(H1)`
  margin: 103px 0 55px 0;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 73px 0 40px 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 23px 0 20px 0;
  }
`;

const BottomTitle = styled(H2)`
  margin: 0;
`;

interface Props {
  className?: string;
  children?: ReactNode;
  topTitle: string;
  bottomTitle?: string;
}

export const HomeLayout: FC<Props> = ({ className, topTitle, bottomTitle, children }) => {
  return (
    <StyledLayout className={className}>
      <TopTitle font="Quicksand" color="exploreLandingTitle" weight="500">
        {topTitle}
      </TopTitle>
      {bottomTitle && (
        <BottomTitle font="Quicksand" color="exploreLandingTitle">
          {bottomTitle}
        </BottomTitle>
      )}
      {children}
    </StyledLayout>
  );
};
