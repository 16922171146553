import { Toast } from 'Atoms/toast/Toast';
import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { ComparisonListsLocalStorageContext } from 'services/localStorage/comparisonLocalStorage.provider';
import { useContextAssert } from 'services/useContextAssert.hook';
import { Dispatch } from 'store/types';
import { ComparisonListsSection } from 'types/comparisonLists';

import { reducer } from './reducer';
import { Action, State } from './types';

// TODO move to configuration file
const maxComparisions = 10;

export const useState = (): State => {
  const [state] = useContextAssert(ComparisonListsLocalStorageContext, 'ComparisonListsStore');
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const [, setState] = useContextAssert(ComparisonListsLocalStorageContext, 'ComparisonListsStore');

  const newDispatch = useCallback(
    (action: Action): void => {
      setState(state => reducer(state, action));
    },
    [setState]
  );

  return newDispatch;
};

const toggleComparisonBody = (
  section: ComparisonListsSection,
  availableSlots: number,
  dispatch: Dispatch<Action>,
  stateSection: number[],
  id: number,
  showLink?: boolean
): void => {
  const toastId = 'toastId';
  const isToastActive = toast.isActive(toastId);

  if (stateSection.indexOf(id) >= 0) {
    dispatch({ type: 'ComparisonLists/RemoveFromCompare', payload: { section, id } });

    const content = (
      <Toast
        type="info"
        content={`Removed! You can add up to ${availableSlots + 1} ${section} to compare.`}
      />
    );

    isToastActive
      ? toast.update(toastId, { render: content, type: toast.TYPE.INFO })
      : toast.info(content, { toastId });
  } else if (availableSlots === 0) {
    const content = (
      <Toast
        type="error"
        content={`${section.charAt(0).toUpperCase() + section.slice(1)} comparison table is full.`}
      />
    );

    isToastActive
      ? toast.update(toastId, { render: content, type: toast.TYPE.ERROR })
      : toast.error(content, { toastId });
  } else {
    dispatch({ type: 'ComparisonLists/AddToCompare', payload: { section, id } });
    if (availableSlots === 1) {
      const content = (
        <Toast
          type="success"
          content={`Added! Your ${section} comparison table is full.`}
          to={showLink ? `/compare/${section}` : undefined}
          linkContent="Go to comparison table"
        />
      );

      isToastActive
        ? toast.update(toastId, { render: content, type: toast.TYPE.SUCCESS })
        : toast.success(content, { toastId });
    } else {
      const content = (
        <Toast
          type="success"
          content={`Added! You can add up to ${availableSlots - 1} ${section} to compare.`}
          to={showLink ? `/compare/${section}` : undefined}
          linkContent="Go to comparison table"
        />
      );

      isToastActive
        ? toast.update(toastId, { render: content, type: toast.TYPE.SUCCESS })
        : toast.success(content, { toastId });
    }
  }
};

export const useComparison = (
  section: ComparisonListsSection,
  id: number
): { isAdded: boolean; toggleComparison: () => void; isFull: boolean } => {
  const state = useState();
  const dispatch = useDispatch();
  const stateSection = state[section];
  const availableSlots = Math.max(maxComparisions - stateSection.length, 0);
  const isFull = availableSlots === 0;

  const isAdded = useMemo(() => stateSection.indexOf(id) >= 0, [stateSection, id]);
  const toggleComparison = useCallback(() => {
    toggleComparisonBody(section, availableSlots, dispatch, stateSection, id);
  }, [dispatch, section, id, stateSection, availableSlots]);

  return {
    isAdded,
    toggleComparison,
    isFull,
  };
};

export const useComparisonNotEmpty = (): boolean => {
  const state = useState();

  return (
    state.bacteria.length > 0 ||
    state.diseases.length > 0 ||
    state.drugs.length > 0 ||
    state.mycobacteria.length > 0 ||
    state.yeasts.length > 0
  );
};

interface CompareReturnTypes {
  toggleComparison: (id: number, showLink?: boolean) => void;
  isFull: boolean;
  clearList: () => void;
  isItemInList: (id: number) => boolean;
}

export const useCompareList = (section: ComparisonListsSection): CompareReturnTypes => {
  const state = useState();
  const dispatch = useDispatch();
  const stateSection = state[section];
  const availableSlots = Math.max(maxComparisions - stateSection.length, 0);
  const isFull = availableSlots === 0;

  const toggleComparison = useCallback(
    (id: number, showLink?: boolean) => {
      toggleComparisonBody(section, availableSlots, dispatch, stateSection, id, showLink);
    },
    [section, availableSlots, stateSection, dispatch]
  );

  const clearList = useCallback(() => {
    dispatch({ type: 'ComparisonLists/ClearList', payload: section });
    toast.success(
      <Toast type="success" content={`Cleared! Your ${section} comparison list is now empty.`} />
    );
  }, [section, dispatch]);

  const isItemInList = useCallback((id: number) => stateSection.indexOf(id) >= 0, [stateSection]);

  return {
    toggleComparison,
    isFull,
    clearList,
    isItemInList,
  };
};
