import { ReactComponent as DropdownIcon } from 'assets/Select/SearchDropdown.svg';
import { Icon } from 'Atoms/Icon';
import React, { ReactElement } from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import { SelectOption } from 'types/select';

export const SearchSelectDropdownIndicator = <T extends SelectOption, isMulti extends boolean>(
  props: DropdownIndicatorProps<T, isMulti>
): ReactElement => (
  <components.DropdownIndicator {...props}>
    <Icon
      svgComponent={DropdownIcon}
      className={`${props.selectProps.classNamePrefix}__dropdown-icon`}
    />
  </components.DropdownIndicator>
);
