import React, { forwardRef, ReactNode } from 'react';
import styled from 'styled-components/macro';

interface BaseProps {
  className?: string;
  children?: ReactNode;
}

const THBase = forwardRef<HTMLTableCellElement, BaseProps>(({ className, children }, ref) => (
  <th className={className} ref={ref}>
    {children}
  </th>
));

interface Props extends BaseProps {
  wrap?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  minWidth?: number;
  width?: string;
}

export const TH = styled(THBase)<Props>`
  min-width: ${props => (props.minWidth ? `${props.minWidth}px` : 'unset')};
  width: ${props => (props.width ? props.width : 'unset')};
  white-space: ${props => (props.wrap ? 'normal' : 'nowrap')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  border: 1px solid ${props => props.theme.colors.table.border};
  padding: 12px 8px;
  background: ${props => props.theme.colors.table.heading.backgroundColor};
  color: ${props => props.theme.colors.table.heading.text};
`;
