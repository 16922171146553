import { dynamicContentToString, DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React from 'react';
import { DynamicContent } from 'types/dynamicContent';
import { CaseSeries, Crossborders, Outbreaks, Survey } from 'types/surveyTables';
import { CellData, Column, Row } from 'types/table';
import { filterEmptyTableColumns } from 'utils/filterEmptyTableColumns';
import { formatNumber } from 'utils/formatNumber';
import { sortTableRows } from 'utils/sortTableRows';

const surveyColumns: Column[] = [
  { name: 'Survey Type', isSortable: true },
  { name: 'Years', isSortable: true, textAlign: 'right' },
  { name: 'Region', isSortable: true },
  { name: 'Focus', isSortable: true },
  { name: '%', isSortable: true, textAlign: 'right' },
  { name: 'Notes', enableWrap: true, minWidth: '300px' },
];

const associatedInfectionsSurveyColumns: Column[] = [
  { name: 'Disease', isSortable: true, maxWidth: '270px', enableWrap: true },
  { name: 'Survey Type', isSortable: true },
  { name: 'Years', isSortable: true, textAlign: 'right' },
  { name: 'Region', isSortable: true },
  { name: '%', isSortable: true, textAlign: 'right' },
  { name: 'Notes', enableWrap: true, minWidth: '300px' },
];

const outbreaksColumns: Column[] = [
  { name: 'Years', isSortable: true, textAlign: 'right' },
  { name: 'Region', isSortable: true },
  { name: 'Setting', isSortable: true },
  { name: 'Population', isSortable: true },
  { name: 'Cases', isSortable: true, textAlign: 'right' },
  { name: 'Deaths', isSortable: true, textAlign: 'right' },
  { name: 'Animal Population', isSortable: true },
  { name: 'Animal Cases', isSortable: true, textAlign: 'right' },
  { name: 'Animal Deaths', isSortable: true, textAlign: 'right' },
  { name: 'Source', isSortable: true },
  { name: 'Pathogen', isSortable: true },
  { name: 'Notes', enableWrap: true, minWidth: '300px' },
];

const crossBordersColumns: Column[] = [
  { name: 'Years', isSortable: true, textAlign: 'right' },
  { name: 'Acquired by **', isSortable: true },
  { name: 'Originated in **', isSortable: true },
  { name: 'Setting', isSortable: true },
  { name: 'Cases', isSortable: true, textAlign: 'right' },
  { name: 'Deaths', isSortable: true, textAlign: 'right' },
  { name: 'Notes', enableWrap: true, minWidth: '300px' },
];

const caseSeriesColumns: Column[] = [
  { name: 'Years', isSortable: true, textAlign: 'right' },
  { name: 'Region', isSortable: true },
  { name: 'Population', isSortable: true, textAlign: 'left' },
  { name: 'Cases', isSortable: true, textAlign: 'right' },
  { name: 'Deaths', isSortable: true, textAlign: 'right' },
  { name: 'Variable of interest', isSortable: true, textAlign: 'left' },
  { name: 'Notes', enableWrap: true, minWidth: '300px' },
];

type SurveyNumber = number | null;

const getYearsCell = (
  publication: SurveyNumber,
  start: SurveyNumber,
  end: SurveyNumber
): CellData => {
  if (publication !== null) {
    return { value: publication.toString(), label: `${publication} *` };
  }
  if (start !== null && end !== null) {
    return { value: start.toString(), label: `${start} - ${end}` };
  }
  if (start !== null) {
    return { value: start.toString(), label: `${start}` };
  }
  if (end !== null) {
    return { value: end.toString(), label: `${end}` };
  }

  return { value: '', label: '-' };
};

const getPercentCell = (percent1: SurveyNumber, percent2: SurveyNumber): CellData => {
  if (percent1 !== null && percent2 !== null) {
    return { value: percent1, label: `${percent1} - ${percent2}` };
  }
  if (percent1 !== null) return { value: percent1, label: percent1.toString() };
  if (percent2 !== null) return { value: percent2, label: percent2.toString() };
  return { value: '', label: '-' };
};

const getTextCell = (data: string | null): CellData => {
  if (data !== null) return { value: data.toLowerCase(), label: data.toString() };
  return { value: '', label: '-' };
};

const getNumberCell = (data: number | null): CellData => {
  if (data !== null) return { value: data, label: formatNumber(data) };
  return { value: '', label: '-' };
};

const getNotesCell = (notes: DynamicContent | null): CellData => {
  if (notes && notes.length > 0)
    return {
      value: dynamicContentToString(notes),
      label: <DynamicHtml key="TableNotes" content={notes} />,
    };
  return { value: '', label: '-' };
};

const getPathogenCell = (pathogen: DynamicContent | null): CellData => {
  if (pathogen && pathogen.length > 0) {
    return {
      value: dynamicContentToString(pathogen),
      label: <DynamicHtml key="TablePathogen" content={pathogen} />,
    };
  }
  return { value: '', label: '-' };
};

const showPublicationYearFootnote = (data: { publicationYear: number | null }[]): boolean => {
  return data.some(d => d.publicationYear !== null);
};

export const getSurveyTableData = (data: Survey[]): [Column[], Row[], boolean] => {
  const initialRows = data.map(row => [
    getTextCell(row.surveyType),
    getYearsCell(row.publicationYear, row.surveyStart, row.surveyEnd),
    getTextCell(row.region),
    getTextCell(row.population),
    getPercentCell(row.percent1, row.percent2),
    getNotesCell(row.notes),
  ]);
  const [columns, rows] = filterEmptyTableColumns(surveyColumns, initialRows);
  const sortedRows = sortTableRows(rows, true, 0);
  const showFootnote = showPublicationYearFootnote(data);

  return [columns, sortedRows, showFootnote];
};

export const getAssociatedInfectionsSurveyData = (data: Survey[]): [Column[], Row[], boolean] => {
  const initialRows = data.map(row => [
    getTextCell(row.disease),
    getTextCell(row.surveyType),
    getYearsCell(row.publicationYear, row.surveyStart, row.surveyEnd),
    getTextCell(row.region),
    getPercentCell(row.percent1, row.percent2),
    getNotesCell(row.notes),
  ]);
  const [columns, rows] = filterEmptyTableColumns(associatedInfectionsSurveyColumns, initialRows);
  const sortedRows = sortTableRows(rows, true, 0);
  const showFootnote = showPublicationYearFootnote(data);

  return [columns, sortedRows, showFootnote];
};

export const getOutbreaksTableData = (data: Outbreaks[]): [Column[], Row[], boolean] => {
  const initialRows = data.map(row => [
    getYearsCell(row.publicationYear, row.outbreakStart, row.outbreakEnd),
    getTextCell(row.region),
    getTextCell(row.setting),
    getTextCell(row.population),
    getNumberCell(row.cases),
    getNumberCell(row.deaths),
    getTextCell(row.animalpopulation),
    getNumberCell(row.animalcases),
    getNumberCell(row.animaldeaths),
    getTextCell(row.vehicle),
    getPathogenCell(row.pathogen),
    getNotesCell(row.notes),
  ]);
  const [columns, rows] = filterEmptyTableColumns(outbreaksColumns, initialRows);
  const sortedRows = sortTableRows(rows, true, 0);
  const showFootnote = showPublicationYearFootnote(data);

  return [columns, sortedRows, showFootnote];
};

export const getCrossBordersTableData = (data: Crossborders[]): [Column[], Row[], boolean] => {
  const initialRows = data.map(row => [
    getYearsCell(row.publicationYear, row.startYear, row.endYear),
    getTextCell(row.acquisitionCountry),
    getTextCell(row.originationCountry),
    getTextCell(row.setting),
    getNumberCell(row.cases),
    getNumberCell(row.deaths),
    getNotesCell(row.notes),
  ]);
  const [columns, rows] = filterEmptyTableColumns(crossBordersColumns, initialRows);
  const showFootnote = showPublicationYearFootnote(data);

  return [columns, rows, showFootnote];
};

export const getCaseSeriesTableData = (data: CaseSeries[]): [Column[], Row[], boolean] => {
  const initialRows = data.map(row => [
    getYearsCell(row.publicationYear, row.startYear, row.endYear),
    getTextCell(row.region),
    getTextCell(row.population),
    getNumberCell(row.cases),
    getNumberCell(row.deaths),
    getTextCell(row.parameter),
    getNotesCell(row.notes),
  ]);
  const [columns, rows] = filterEmptyTableColumns(caseSeriesColumns, initialRows);
  const showFootnote = showPublicationYearFootnote(data);
  return [columns, rows, showFootnote];
};
