import React, { FC } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

interface Props {
  className?: string;
  imageName?: keyof DefaultTheme['backgroundImages'];
}

const BackgroundComp: FC<Props> = ({ className }) => {
  return <div className={className}></div>;
};

export const ExploreBackground = styled(BackgroundComp)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: ${props =>
    props.imageName ? props.theme.backgroundImages[props.imageName].desktop : 'none'};
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    background: ${props =>
      props.imageName ? props.theme.backgroundImages[props.imageName].mobile : 'none'};
  }
`;
