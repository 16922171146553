import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { Chart } from 'types/chart';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'Chart/SingleLoadInitiated', { url: string }>
  | ActionWithPayload<'Chart/SingleLoaded', { url: string; data: Chart }>
  | ActionWithPayload<'Chart/SingleLoadFailed', { url: string; error: StoreError }>;

export type State = {
  [url: string]: Resource<Chart>;
};

const initialState: State = {};

export const {
  dispatchContext: ChartDispatchContext,
  stateContext: ChartStateContext,
  provider: ChartStoreProvider,
} = storeFactory(reducer, initialState);
