import { ReactComponent as BackArrowIcon } from 'assets/Button/BackArrow.svg';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon } from 'Atoms/Icon';
import { Span } from 'Atoms/text';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

const IconStyled = styled(Icon)`
  margin-right: 10px;
  width: 15px;
  height: 15px;
`;

const SpanStyled = styled(Span)`
  color: ${props => props.theme.colors.breadcrumb.default.textColor};

  margin: 0 auto;
`;

const LinkStyled = styled(InvisibleButton)`
  height: 30px;
  width: auto;
  display: flex;
  align-items: center;
  box-shadow: none;
  padding: 4px 12px 4px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.breadcrumb.default.borderColor};
  background-color: ${props => props.theme.colors.breadcrumb.default.backgroundColor};

  white-space: nowrap;
  position: relative;

  ${IconStyled} {
    fill: ${props => props.theme.colors.breadcrumb.default.iconColor};
  }

  &:hover {
    border: 1px solid ${props => props.theme.colors.breadcrumb.hover.borderColor};
    background-color: ${props => props.theme.colors.breadcrumb.hover.backgroundColor};

    ${SpanStyled} {
      color: ${props => props.theme.colors.breadcrumb.hover.textColor};
    }

    ${IconStyled} {
      fill: ${props => props.theme.colors.breadcrumb.hover.textColor};
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    background-color: unset;
    padding: 4px 6px 4px;
  }
`;

export interface Props {
  className?: string;
  children?: ReactNode;
  showIcon?: boolean;
  fontWeight?: '400' | '500';
  onClick?: () => void;
}

export const BreadcrumbLink: FC<Props> = ({
  className,
  children,
  showIcon,
  fontWeight,
  onClick,
}) => (
  <LinkStyled className={className} onClick={onClick}>
    {showIcon && <IconStyled svgComponent={BackArrowIcon} />}
    <SpanStyled size="normal" weight={fontWeight}>
      {children}
    </SpanStyled>
  </LinkStyled>
);
