import { ReactComponent as MailIcon } from 'assets/Button/Mail.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { Loader } from 'Atoms/Loader';
import { P } from 'Atoms/text';
import { ErrorBoundary } from 'errorBoundary';
import React, { FC, useEffect, useState } from 'react';
import { EmailPDFprops } from 'services/useEmailPDF';
import { useTooltip } from 'services/useTooltip';
import { useAccountController } from 'store/accountStore/hooks';
import styled, { css } from 'styled-components/macro';

import { EmailForm, Input, SendButton } from './EmailForm';

const EmailButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.4s;
  min-width: 230px;
`;

const StyledIcon = styled(Icon)`
  margin: 0 15px 0 0;
`;

const InputContainer = styled.div`
  min-width: 230px;
  position: absolute;
  width: 100%;
  max-width: 230px;
  height: 50px;
  padding: 10px 8px;
  border: 3px solid transparent;
  border-radius: 5px;
  overflow: hidden;
  transition: all 1s ease;

  border: 1px solid ${props => props.theme.colors.filledButton.default.default.borderColor};
  box-shadow: ${props => props.theme.colors.filledButton.default.default.boxShadow};
`;

const StyledP = styled(P)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0;
  transition: all 1s ease;
  font: ${props => props.theme.fonts.normalTextSemiBold};
  white-space: nowrap;
`;

export const EmailSentTitle = styled(StyledP)`
  color: ${props => props.theme.colors.accent[3]};
`;

export const EmailErrorTitle = styled(StyledP)`
  color: ${props => props.theme.colors.accent[1]};
`;

interface ContainerProps {
  emailButtonClicked: boolean;
  emailSent: boolean;
  isLoading: boolean;
  hasError: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  margin-right: 15px;
  margin: 10px 20px 10px 0;
  width: 230px;
  height: 50px;
  transition: all 1s ease;

  z-index: 250;

  ${props =>
    props.emailButtonClicked
      ? css`
          width: 300px;

          ${InputContainer} {
            visibility: visible;
            opacity: 1;
            z-index: 2;
            min-width: 300px;
          }
          ${EmailButtonWrapper} {
            opacity: 0;
            z-index: 1;
          }
        `
      : css`
          ${InputContainer} {
            visibility: hidden;
            opacity: 0;
            z-index: 1;
          }
          ${EmailButtonWrapper} {
            opacity: 1;
            z-index: 2;
          }
        `}

  ${props =>
    props.isLoading &&
    css`
      border: 1px solid ${props => props.theme.colors.filledButton.default.default.borderColor};
      box-shadow: ${props => props.theme.colors.filledButton.default.default.boxShadow};
      border-radius: 5px;
    `}

    ${props =>
    props.emailSent &&
    css`
      ${InputContainer} {
        border-color: ${props => props.theme.colors.stepIndicator.completed};
        ${Input} {
          opacity: 0;
        }
        ${SendButton} {
          opacity: 0;
          z-index: -1;
        }
        ${EmailErrorTitle} {
          opacity: 0;
          z-index: 0;
        }
        ${EmailSentTitle} {
          opacity: 1;
          z-index: 1;
        }
      }
    `}
    ${props =>
    props.hasError &&
    css`
      ${InputContainer} {
        border-color: ${props => props.theme.colors.stepIndicator.completedNegative};
        ${Input} {
          opacity: 0;
        }
        ${SendButton} {
          opacity: 0;
          z-index: -1;
        }
        ${EmailErrorTitle} {
          opacity: 1;
          z-index: 1;
        }
      }
    `};
`;

interface Props extends EmailPDFprops {
  className?: string;
  label?: string;
}

export const MailPDFButtonForm: FC<Props> = ({
  className,
  onEmailChange,
  email,
  sendPDFemail,
  isLoading,
  error,
  emailSent,
  resetState,
  label = 'Email PDF',
}) => {
  const [emailButtonClicked, setEmailButtonClicked] = useState(false);
  const [props, popper, setElement] = useTooltip('Send up to 10 emails in any 24h period');

  const { tokenPayload } = useAccountController();
  const isInsitutional = !!tokenPayload?.user.institutionId;

  useEffect(() => {
    if (emailSent) {
      setTimeout(() => {
        setEmailButtonClicked(false);
        resetState();
      }, 3000);
    }
  }, [emailSent, resetState]);

  return (
    <ErrorBoundary error={error}>
      <Container
        emailButtonClicked={emailButtonClicked}
        emailSent={emailSent}
        className={className}
        isLoading={isLoading}
        hasError={!!error}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <EmailButtonWrapper {...props.events}>
              <FilledButton
                onClick={() => setEmailButtonClicked(true)}
                ref={setElement}
                {...props.aria}
                aria-expanded={emailButtonClicked}
              >
                <StyledIcon svgComponent={MailIcon} size="small" />
                {label}
              </FilledButton>
              {!isInsitutional && popper}
            </EmailButtonWrapper>

            <InputContainer>
              <EmailSentTitle role="alert" aria-live="polite">
                Email sent successfully!
              </EmailSentTitle>
              <EmailErrorTitle>{error}</EmailErrorTitle>
              <EmailForm email={email} onEmailChange={onEmailChange} sendPDFemail={sendPDFemail} />
            </InputContainer>
          </>
        )}
      </Container>
    </ErrorBoundary>
  );
};
