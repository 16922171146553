import { isRegExp, isString } from 'lodash';
import { RefObject, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useHashAnchor = <Element extends HTMLElement>(
  ref: RefObject<Element>,
  id?: string | RegExp
): void => {
  const location = useLocation();

  useEffect(() => {
    if (ref.current) {
      const expectedHash = id || ref.current.getAttribute('id')?.toLowerCase();
      const currentHash = location.hash.slice(1).toLowerCase();

      if (expectedHash === undefined) {
        return;
      }

      if (
        (isRegExp(expectedHash) && expectedHash.test(currentHash)) ||
        (isString(expectedHash) && currentHash.startsWith(expectedHash.toLowerCase()))
      ) {
        ref.current.scrollIntoView();
      }
    }
  }, [location, id, ref]);
};
