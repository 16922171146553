import axios from 'axios.cached';

import {
  EmailLoginRequest,
  EmailLoginResponse,
  InstitutionalLoginRequest,
  InstitutionalLoginResponse,
} from './auth.dto';

const emailLogin = async (
  email: string,
  password: string,
  recaptchaToken: string
): Promise<string> => {
  const request: EmailLoginRequest = {
    email,
    password,
    recaptcha_token: recaptchaToken,
  };
  const res = await axios.post<EmailLoginResponse>('/api/auth/login', request);

  return res.data.token;
};

const institutionalLogin = async (
  username: string,
  password: string,
  recaptchaToken: string
): Promise<string> => {
  const request: InstitutionalLoginRequest = {
    username,
    password,
    recaptcha_token: recaptchaToken,
  };
  const res = await axios.post<InstitutionalLoginResponse>('/api/auth/institution/login', request);

  return res.data.token;
};

export const credentialsLogin = async (
  username: string,
  password: string,
  recaptchaToken: string
): Promise<string> => {
  const emailRegex = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
  const usernameIsEmail = !!username.match(emailRegex);
  return usernameIsEmail
    ? await emailLogin(username, password, recaptchaToken)
    : await institutionalLogin(username, password, recaptchaToken);
};

export const ipLogin = async (): Promise<string> => {
  const res = await axios.post<InstitutionalLoginResponse>('/api/auth/institution/login/ip');

  return res.data.token;
};
