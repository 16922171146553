import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'ReferenceList/AddReference': {
      const exists =
        state.list.findIndex(refNumber => refNumber === action.payload.referenceIdentifier) >= 0;

      if (exists) {
        return state;
      }

      return {
        ...state,
        index: {
          ...state.index,
          [action.payload.referenceIdentifier]: state.list.length + 1,
        },
        list: [...state.list, action.payload.referenceIdentifier],
      };
    }
    case 'ReferenceList/AddMultiReference': {
      let tempIndex = Object.assign({}, state.index);
      let tempList: string[] = [];
      tempList = tempList.concat(state.list);
      for (const identifier of action.payload.referenceIdentifiers) {
        const exists =
        tempList.findIndex(refNumber => refNumber === identifier) >= 0;
        if (!exists) {
          tempIndex = {
            ...tempIndex,
            [identifier]: tempList.length + 1,
          }
          tempList.push(identifier);
        }
      }

      return {
        ...state,
        index: tempIndex,
        list: tempList,
      };
    }
    case 'ReferenceList/OpenModal': {
      return {
        ...state,
        referenceModalIds: action.payload.reference,
        showReferenceModal: true,
      };
    }
    case 'ReferenceList/CloseModal': {
      return {
        ...state,
        showReferenceModal: false,
      };
    }
    case 'Reset/ReferenceList':{
      return {
        index: {},
        list: [],
        referenceModalIds: 'all',
        showReferenceModal: false,
      }
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
