import { ReactComponent as Star } from 'assets/UI/StarSocialProof.svg';
import { Icon } from 'Atoms/Icon';
import { Span } from 'Atoms/text';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  border: 1px solid ${props => props.theme.colors.trial.socialProof.border};
  border-radius: 5px;
  padding: 10px;

  background: ${props => props.theme.colors.trial.socialProof.background};

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  max-width: 650px;
  width: 100%;

  margin: 50px 0 0 0;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-width: unset;
  }
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BottomContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 10px 0 0 0;
`;

const ProofText = styled(Span)`
  margin: 0 0 0 10px;
`;

const IconStyled = styled(Icon)`
  min-height: 25px;
  min-width: 25px;
`;

const texts = [
  {
    proof:
      'GIDEON helped me a lot getting data for my thesis! It is so good that the papers with additional information are always linked.',
    author: 'Christian Voll, Ph.D. candidate, Universität Würzburg',
  },
  {
    proof: `It’s irreplaceable. I haven't seen anything else that compares to GIDEON.`,
    author: 'Dr. Jeffrey P. Gumprecht, MD, Mount Sinai Medical Center',
  },
  {
    proof:
      'We are limited by data that is both spatially resolved to country-level as well as temporally resolved. That is one of the biggest strengths of GIDEON, as a lot of the time we only have one or the other.',
    author: 'Tad A. Dallas, Assistant Professor, Louisiana State University',
  },
  {
    proof:
      'GIDEON is the best teaching tool for microbiology students. I love it because it’s all real-life data, always current and so versatile. So much information!',
    author: 'Dr. Monika Oli, Senior Lecturer, University of Florida',
  },
  {
    proof: 'I don’t think we could have modeled the data set we did without GIDEON.',
    author: 'Dr. Colin J. Carlson, Assistant Research Professor, Georgetown University',
  },
  {
    proof:
      'GIDEON really opens up a lot of new and practical ways of teaching. If you don’t know about GIDEON you should definitely look into it!',
    author:
      'Dr. Johnny El-Rady, Instructor (Microbiology and Genetics), University of South Florida',
  },
  {
    proof:
      'The web platform is a valuable tool to quickly gather high-quality information on diseases and the epidemiological situation of a disease in a country of interest.',
    author: 'Katrin Zitzelsberger, Epidemiologist, Munich Re',
  },
  {
    proof: 'Our team was super impressed with the amount of data you have.',
    author: 'Dr. Prabha Xaxa, International SOS',
  },
  {
    proof:
      'Infectious diseases have now become a hot topic in Economics. I hope that my use of the GIDEON database will alert researchers to this incredible information source and encourage more epidemiological research.',
    author: 'Prof. Rodolphe Desbordes, SKEMA Business School',
  },
  {
    proof:
      'I rely on this program because it has everything I need in a pinch. We are a sentinel lab for biosecurity and special pathogens, our hospital serves people from all over the world. This is the one application that I can find information on all these topics. It is a Godsend I would not want to be without',
    author: 'Sheryl Stuckey, Manager of Microbiology Lab, Holy Cross Hospital',
  },
];

export const SocialProof: FC = () => {
  const text = useMemo(() => texts[Math.floor(Math.random() * texts.length)], []);

  return (
    <Container>
      <TopContainer>
        <IconStyled size="smallMedium" svgComponent={Star} />
        <ProofText size="normal" weight="500">
          &ldquo;{text.proof}&rdquo;
        </ProofText>
      </TopContainer>
      <BottomContainer>
        <Span size="normal" weight="400">
          {text.author}
        </Span>
      </BottomContainer>
    </Container>
  );
};
