import { ExternalLink } from 'Atoms/links/ExternalLink';
import { Link } from 'Atoms/links/Link';
import { P } from 'Atoms/text';
import React, { FC, ReactElement } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

interface StyleProps {
  type: keyof DefaultTheme['colors']['toast'];
}

export const ToastP = styled(P)<StyleProps>`
  color: ${props => props.theme.colors.toast[props.type].text};
`;

export const ToastLink = styled(Link)<StyleProps>`
  color: ${props => props.theme.colors.toast[props.type].text};
`;

export const ExternalToastLink = styled(ExternalLink)<StyleProps>`
  color: ${props => props.theme.colors.toast[props.type].text};
`;

interface Props {
  content: string | ReactElement;
  type: keyof DefaultTheme['colors']['toast'];
  to?: string;
  linkContent?: string;
  externalLink?: boolean;
  openInNewTab?: boolean;
}

export const Toast: FC<Props> = ({
  content,
  type,
  to,
  linkContent,
  externalLink,
  openInNewTab,
}) => (
  <>
    <ToastP type={type}>{content}</ToastP>
    {to &&
      linkContent &&
      (externalLink ? (
        <ExternalToastLink type={type} to={to} openInNewTab={openInNewTab}>
          {linkContent} (opens in new tab)
        </ExternalToastLink>
      ) : (
        <ToastLink type={type} to={to} target={openInNewTab ? '_blank' : undefined}>
          {linkContent}
        </ToastLink>
      ))}
  </>
);
