import { Loader } from 'Atoms/Loader';
import { P } from 'Atoms/text';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { ActiveSection } from 'Updates/Molecules/ActiveSection';
import { ContentSection } from 'Updates/Molecules/ContentSection';
import { useContentsSections } from 'Updates/services/useContentsSections';
import { useContents } from 'Updates/store/contentsStore/hooks';
import { Content, Section } from 'Updates/types/content';

const Container = styled.div`
  margin-top: 20px;
  position: relative;
`;

const Sections = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-auto-flow: dense;

  column-gap: 10px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }
`;

const StyledContentSection = styled(ContentSection)<{ double?: boolean }>`
  ${props => props.double && `grid-column: auto / span 2;`}

  margin-top: 10px;
`;

const getCount = (section: Section, contentsData: Record<string, Content>): number => {
  return section.dataFields.reduce((count, field) => {
    const data = contentsData[field];
    return data ? count + parseFloat(data.count.replace(/,/g, '')) : count;
  }, 0);
};

export const Contents: FC = () => {
  const contentsData = useContents();
  const sections = useContentsSections();
  const [activeSectionName, setActiveSectionName] = useState<string>();

  if (isLoading(contentsData) || !contentsData) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  const onClick = (section: Section): void => {
    setActiveSectionName(name => (name !== section.name ? section.name : undefined));
  };

  return (
    <Container>
      <P color="inactive" size="big">
        As of {moment().format('DD MMM YYYY')}, the GIDEON database contains...
      </P>
      <Sections>
        {sections.map(section => [
          <StyledContentSection
            key={section.name}
            name={section.name}
            count={getCount(section, contentsData)}
            icon={section.icon}
            double={section.double}
            onClick={() => onClick(section)}
            active={section.name === activeSectionName}
          />,
          <ActiveSection
            key={`${section.name}-active-section`}
            open={section.name === activeSectionName}
            section={section}
            contentsData={contentsData}
          />,
        ])}
      </Sections>
    </Container>
  );
};
