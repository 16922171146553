import {
  CompareButtonPositions,
  CompareButtonSizes,
  DumbCompareButton,
} from 'Molecules/buttons/CompareButton';
import React, { FC } from 'react';
import { useComparison } from 'store/ComparisonListsStore/hooks';
import { ComparisonLists } from 'types/comparisonLists';

interface Props {
  className?: string;
  section: keyof ComparisonLists;
  id: number;
  showTooltip?: boolean;
  size?: CompareButtonSizes;
  position?: CompareButtonPositions;
}

export const SmartCompareButton: FC<Props> = ({
  className = '',
  section,
  id,
  showTooltip,
  size,
  position,
}) => {
  const { isAdded, toggleComparison } = useComparison(section, id);

  return (
    <DumbCompareButton
      className={className}
      showTooltip={showTooltip}
      clicked={isAdded}
      onClick={toggleComparison}
      size={size}
      position={position}
    />
  );
};
