import React, { FC } from 'react';
import { useAccountController } from 'store/accountStore/hooks';
import styled from 'styled-components/macro';

import { PaywallContent } from './PaywallContent';

const Overlay = styled.div`
  width: calc(100% + 40px);
  height: 100%;

  margin: -100px 0 0 -20px;

  display: flex;

  position: relative;
  z-index: 100;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: -100px 0 0 -5px;
    width: calc(100% + 10px);
  }

  &:before {
    background: ${props => props.theme.colors.paywall.overlay};
    position: absolute;
    content: '';
    width: 100%;

    height: 300px;
    top: -300px;
  }
`;

export const Paywall: FC = () => {
  const { signIn, isLoading, ipLoginStatus } = useAccountController();

  return (
    <Overlay>
      <PaywallContent onSubmit={signIn} isLoading={isLoading} ipLoginStatus={ipLoginStatus} />
    </Overlay>
  );
};
