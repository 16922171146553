import { Icon } from 'Atoms/Icon';
import { Link } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { RedirectWithState } from 'Atoms/routes/RedirectWithState';
import { P } from 'Atoms/text';
import { MapMainLayout } from 'layouts/MapMainLayout';
import { Disclaimer } from 'Molecules/Disclaimer';
import { UpdatesOutbreaksMap } from 'Organisms/outbreaksMap';
import { ProvidersInjector } from 'ProvidersInjector';
import React, { FC, Suspense, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useMobile } from 'services/useMobile';
import { useAccountController } from 'store/accountStore/hooks';
import styled, { ThemeContext } from 'styled-components/macro';
import { Section } from 'Updates/Molecules/Section';
import { Contents } from 'Updates/Organisms/Contents';
import { LatestDataSection } from 'Updates/Organisms/LatestDataSection';
import { UpdatesDesktop } from 'Updates/Organisms/UpdatesDesktop';
import { UpdatesMobile } from 'Updates/Organisms/UpdatesMobile';
import { UpdatesStoreProvider } from 'Updates/store/updatesStore/provider';
import { UpdatesTabSection } from 'Updates/types/tab';

const DataSection = styled(Section)`
  margin-top: 20px;
`;

const OutbreaksSection = styled(DataSection)`
  min-height: 500px;
`;

const OutbreaksSubtitle = styled.div`
  display: flex;
`;

const StyledLink = styled(Link)`
  margin-left: 10px;
`;

const MapLayoutStyled = styled(MapMainLayout)`
  height: 800px;
  border: 1px solid ${props => props.theme.colors.separator.light};
  border-radius: 10px;
  padding: 2px;
`;

const StyledDisclaimer = styled(Disclaimer)`
  ${Icon} {
    width: 30px;
    height: 30px;

    @media (max-width: ${props => props.theme.breakpoints.m}) {
      width: 25px;
      height: 25px;
    }
  }
`;

export const Updates: FC<RouteComponentProps> = ({ match }) => {
  const theme = useContext(ThemeContext);
  const { isAuthenticated, isLoading } = useAccountController();
  const isMobile = useMobile('m');

  const sections: UpdatesTabSection[] = [
    {
      name: 'Latest updates',
      slug: 'data',
      icon: theme.images.updates.folder,
      Component: ({ path }) => (
        <>
          <Helmet>
            <title>Latest updates - GIDEON</title>
            <meta
              name="description"
              content="Our medical scientists work around the clock! Explore the latest updates made to the GIDEON database in the last 48 hours or 7 days"
            />
          </Helmet>
          <DataSection
            title={
              <StyledDisclaimer>
                This list reflects updates made to the GIDEON database, not the most recent
                outbreaks.
              </StyledDisclaimer>
            }
          >
            <Switch>
              <RedirectWithState exact path={path} to={`${path}/48-hours`} />
              <Route exact path={`${path}/:value`}>
                <LatestDataSection path={path} />
              </Route>
            </Switch>
          </DataSection>
        </>
      ),
    },
    {
      name: 'Outbreaks map',
      slug: 'outbreaks',
      icon: theme.images.updates.world,
      Component: () => (
        <>
          <Helmet>
            <title>Outbreaks map - GIDEON</title>
            <meta
              name="description"
              content="Discover our global outbreaks map for the current year, available free of charge to everyone. Updated real-time as new data is added to the database."
            />
          </Helmet>
          <OutbreaksSection
            title={
              <OutbreaksSubtitle>
                <P color="inactive">Our global outbreaks maps go back to 1900 AD!</P>
                {!isAuthenticated && <StyledLink to="/login">Sign in to see more.</StyledLink>}
              </OutbreaksSubtitle>
            }
          >
            <MapLayoutStyled>
              <Suspense fallback={<Loader />}>
                <UpdatesOutbreaksMap />
              </Suspense>
            </MapLayoutStyled>
          </OutbreaksSection>
        </>
      ),
    },
    {
      name: 'Content by numbers',
      slug: 'content',
      icon: theme.images.updates.content.content,
      Component: () => (
        <>
          <Helmet>
            <title>Content by numbers - GIDEON</title>
            <meta
              name="description"
              content="Discover GIDEON evidence-based medicine database - explore content by numbers, updated real-time as new data is added to the database."
            />
          </Helmet>
          <Contents />
        </>
      ),
    },
  ];

  const Updates = isMobile ? UpdatesMobile : UpdatesDesktop;

  return (
    <ProvidersInjector providers={[UpdatesStoreProvider]}>
      <Helmet>
        <title>Updates - GIDEON</title>
      </Helmet>
      {isLoading ? <Loader /> : <Updates match={match} sections={sections} />}
    </ProvidersInjector>
  );
};
