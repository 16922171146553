import { ReactComponent as DiagnoseIcon } from 'assets/MobileNavbar/Diagnose.svg';
import { ReactComponent as ExploreIcon } from 'assets/MobileNavbar/Explore.svg';
import { ReactComponent as LabIcon } from 'assets/MobileNavbar/Lab.svg';
import { ReactComponent as VisualizeIcon } from 'assets/MobileNavbar/Visualize.svg';
import { MobileNavbarLink } from 'Atoms/links/MobileNavbarLink';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { LoginModalContext } from 'services/loginModal.service';
import { useContextAssert } from 'services/useContextAssert.hook';
import { usePushState } from 'services/usePushState.hook';
import { useAccountController } from 'store/accountStore/hooks';

export const MobileNavbarSections: FC = () => {
  const location = useLocation();
  const currentSection = location.pathname.split('/')[1];
  const { isAuthenticated } = useAccountController();
  const { setOpen } = useContextAssert(LoginModalContext);
  const { push } = usePushState();

  const onClick = (link: string): void => {
    if (isAuthenticated) {
      push(link);
    } else {
      setOpen(true, link);
    }
  };

  return (
    <>
      <MobileNavbarLink
        caption="Explore"
        isActive={currentSection === 'explore'}
        icon={ExploreIcon}
        onClick={() => push('/explore')}
      />
      <MobileNavbarLink
        caption="Lab"
        isActive={currentSection === 'lab'}
        icon={LabIcon}
        disabled={!isAuthenticated}
        onClick={() => onClick('/lab')}
      />
      <MobileNavbarLink
        caption="Diagnose"
        isActive={currentSection === 'diagnose'}
        icon={DiagnoseIcon}
        disabled={!isAuthenticated}
        onClick={() => onClick('/diagnose')}
      />
      <MobileNavbarLink
        caption="Visualize"
        isActive={currentSection === 'visualize'}
        icon={VisualizeIcon}
        disabled={!isAuthenticated}
        onClick={() => onClick('/visualize')}
      />
    </>
  );
};
