import { H1 } from 'Atoms/text';
import { useSections } from 'pages/Compare/ComparePageCategories';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';

const Title = styled(H1)`
  margin: 40px 0 60px 0;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 20px 0 20px 0;
  }
`;

export const ComparePageTitle: FC = () => {
  const sections = useSections();

  return (
    <Title weight="500">
      <Switch>
        <Route exact path="/compare" render={() => `Comparison tables`} />
        {sections.map(section => (
          <Route
            path={`/compare/${section.slug}`}
            key={section.slug}
            Redirect
            render={() => `Comparing ${section.name.toLocaleLowerCase()}`}
          />
        ))}
      </Switch>
    </Title>
  );
};
