import { getDiseasesSimple } from 'apiServices/Diseases/diseases';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useAccountController } from 'store/accountStore/hooks';
import { assertIsNotStoreError, newStoreError } from 'store/storeError';
import { Dispatch, isLoading, Loading, Resource } from 'store/types';
import { GroupedData } from 'types/az';
import { CustomError, ErrorCodes } from 'types/errorTypes';
import { groupDataAlphabetically } from 'utils/getGroupedData';

import {
  Action,
  Disease,
  Diseases,
  DiseasesDispatchContext,
  DiseasesStateContext,
  State,
} from './provider';

export const useState = (): State => {
  const state = React.useContext(DiseasesStateContext);
  if (state === undefined) {
    throw new CustomError('DiseaseStore', ErrorCodes.StoreNotInitialized);
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(DiseasesDispatchContext);
  if (dispatch === undefined) {
    throw new CustomError('DiseaseStore', ErrorCodes.StoreNotInitialized);
  }
  return dispatch;
};

export const useDiseasesResource = (): Resource<Diseases> => {
  const state = useState();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAccountController();

  useEffect(() => {
    if (!state) {
      dispatch({ type: 'Diseases/LoadInitiated' });
      getDiseasesSimple(!isAuthenticated)
        .then(data => dispatch({ type: 'Diseases/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Diseases/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    }
  }, [state, dispatch, isAuthenticated]);

  return state || Loading;
};

export const useDiseasesList = (): Disease[] => {
  const diseases = useDiseasesResource();

  assertIsNotStoreError(diseases);

  return useMemo(() => (isLoading(diseases) ? [] : diseases.list.map(id => diseases.data[id])), [
    diseases,
  ]);
};

export const useDiseasesGrouped = (): GroupedData => {
  const list = useDiseasesList();

  return { data: groupDataAlphabetically(list), total: list.length };
};

export const useGetDiseaseList = (): (() => Disease[]) => {
  const state = useState();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAccountController();

  const getDiseaseList = useCallback(() => {
    if (!state) {
      dispatch({ type: 'Diseases/LoadInitiated' });
      getDiseasesSimple(!isAuthenticated)
        .then(data => dispatch({ type: 'Diseases/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Diseases/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    }

    assertIsNotStoreError(state);

    return !isLoading(state) && state ? state.list.map(id => state.data[id]) : [];
  }, [dispatch, isAuthenticated, state]);

  return getDiseaseList;
};
