import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { MicrobeType, VirusType } from 'types/microbeDetails';
import { SimpleMicrobeWithSlug } from 'types/simpleMicrobe';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'Microbes/LoadInitiated', { type: MicrobeType | VirusType }>
  | ActionWithPayload<
      'Microbes/Loaded',
      { type: MicrobeType | VirusType; data: SimpleMicrobeWithSlug[] }
    >
  | ActionWithPayload<'Microbes/LoadFailed', { type: MicrobeType | VirusType; data: StoreError }>;

export type State = Record<MicrobeType | VirusType, Resource<SimpleMicrobeWithSlug[]> | null>;

const initialState: State = {
  bacteria: null,
  mycobacteria: null,
  yeasts: null,
  viruses: null,
};

export const {
  dispatchContext: MicrobesDispatchContext,
  stateContext: MicrobesStateContext,
  provider: MicrobesStoreProvider,
} = storeFactory(reducer, initialState);
