import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { CountrySelectOption, isCountrySelectOption } from 'types/country';
import { SelectOption } from 'types/select';

export const onCountryInputChange = (
  value: string,
  options: CountrySelectOption[]
): CountrySelectOption[] => {
  const input = value.toLowerCase();

  const sorted = options.sort((a, b) => {
    if (a.value === 'null') return -1;
    if (b.value === 'null') return 1;
    if (a.value === 'G100' || a.value === 'G90') return -1;
    if (b.value === 'G100' || b.value === 'G90') return 1;
    if (a.iso2Code?.toLowerCase() === input || a.iso3Code?.toLowerCase() === input) return -1;
    if (b.iso2Code?.toLowerCase() === input || b.iso3Code?.toLowerCase() === input) return 1;
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });

  return sorted;
};

export const countriesSelectFilter = <T extends SelectOption>(
  option: FilterOptionOption<T>,
  inputValue: string
): boolean => {
  const data = option.data;
  const lowerInput = inputValue.toLowerCase();

  if (isCountrySelectOption(data)) {
    if (data.iso2Code && data.iso2Code.toLowerCase().includes(lowerInput)) {
      return true;
    }

    if (data.iso3Code && data.iso3Code.toLowerCase().includes(lowerInput)) {
      return true;
    }
  }

  return (
    data.value.toLowerCase().includes(lowerInput) || data.label.toLowerCase().includes(lowerInput)
  );
};
