import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { CompareDisease, CompareDrug, CompareMicrobe } from 'types/compare';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'Compare/Diseases/LoadInitiated', number[]>
  | ActionWithPayload<'Compare/Diseases/Loaded', CompareDisease[]>
  | ActionWithPayload<'Compare/Diseases/LoadFailed', { error: StoreError; ids: number[] }>
  | ActionWithPayload<'Compare/Drugs/LoadInitiated', number[]>
  | ActionWithPayload<'Compare/Drugs/Loaded', CompareDrug[]>
  | ActionWithPayload<'Compare/Drugs/LoadFailed', { error: StoreError; ids: number[] }>
  | ActionWithPayload<'Compare/Bacteria/LoadInitiated', number[]>
  | ActionWithPayload<'Compare/Bacteria/Loaded', CompareMicrobe[]>
  | ActionWithPayload<'Compare/Bacteria/LoadFailed', { error: StoreError; ids: number[] }>
  | ActionWithPayload<'Compare/Mycobacteria/LoadInitiated', number[]>
  | ActionWithPayload<'Compare/Mycobacteria/Loaded', CompareMicrobe[]>
  | ActionWithPayload<'Compare/Mycobacteria/LoadFailed', { error: StoreError; ids: number[] }>
  | ActionWithPayload<'Compare/Yeasts/LoadInitiated', number[]>
  | ActionWithPayload<'Compare/Yeasts/Loaded', CompareMicrobe[]>
  | ActionWithPayload<'Compare/Yeasts/LoadFailed', { error: StoreError; ids: number[] }>;

export interface Compare {
  diseases: {
    [id: number]: Resource<CompareDisease>;
  };
  drugs: {
    [id: number]: Resource<CompareDrug>;
  };
  bacteria: {
    [id: number]: Resource<CompareMicrobe>;
  };
  mycobacteria: {
    [id: number]: Resource<CompareMicrobe>;
  };
  yeasts: {
    [id: number]: Resource<CompareMicrobe>;
  };
}

export type State = Compare;

const initialState: State = {
  diseases: {},
  drugs: {},
  bacteria: {},
  mycobacteria: {},
  yeasts: {},
};

export const {
  dispatchContext: CompareDispatchContext,
  stateContext: CompareStateContext,
  provider: CompareStoreProvider,
} = storeFactory(reducer, initialState);
