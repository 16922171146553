export const inappropriateSynonyms = [
  'Soviet Union',
  'USSR',
  'U.S.S.R.',
  'Prussia',
  'Yugoslavia',
  'Yugoslav Republic',
  'Former Yug. Rep. Maced.',
  'Fed. Rep. Yugoslavia',
  'Jugoslavia',
  'Trucial States',
  'Togoland',
  'Terrorism',
  'Tanganyika',
  'Spanish Guinea',
  'Spanish Equator. Guinea',
  'Southern Rhodesia',
  'South Yemen',
  'Spanish Sahara',
  'French Sudan',
  'French Guinea',
  'Portuguese Guinea',
  'Portuguese East Africa',
  'Portuguese West Africa',
  'German Southwest Africa',
  'South West Africa',
  "People's Republic of the Congo",
  'Central African Empire',
  'Zaire',
  'Northern Cameroons',
  'Cameroons',
  'Italian Somaliland',
  'French Somaliland',
  'Fr. Ter. Afars & Issas',
  'Persia',
  'East Pakistan',
  'East Bengal',
  'Nyasaland',
  'Northern Rhodesia',
  'Netherlands New Guinea',
  'Netherlands Guiana',
  'Dutch Guiana',
  'Netherlands Antilles',
  'Malaya',
  'Malagasy Republic',
  'Kongo',
  'Khmer Republic',
  'Kampuchea',
  'Democratic Kampuchea',
  'Formosa',
  'Dutch East Indies',
  'Danish West Indies',
  'Dahomey',
  'Czechoslovakia',
  'Ceylon',
  'British Togoland',
  'British Somaliland',
  'British New Guinea',
  'British Honduras',
  'British Guiana',
  'Bioterrorism',
  'Bioterror',
  'Biological terror',
  'Belgian Congo',
  'Bechuanaland',
  'Basutoland',
  'Abyssinia',
  'World',
  'Worldwide',
];
