import React, { FC } from 'react';
import { Route, Switch } from 'react-router';

import { PasswordHome } from './Home';
import { PasswordReset } from './Reset';

export const Password: FC = () => (
  <Switch>
    <Route exact path="/password" component={PasswordHome} />
    <Route exact path="/password/reset" component={PasswordReset} />
  </Switch>
);
