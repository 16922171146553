import { AccountMenuImage } from 'Atoms/accountMenu/AccountMenuImage';
import { ButtonLabel } from 'Atoms/buttons/ButtonLabel';
import { Icon } from 'Atoms/Icon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const AccountMenuInfoStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: solid;
  padding: 7px 16px 7px 9px;
  border-width: 1px;
  border-radius: 4px;

  width: 100%;
  height: 50px;

  color: ${props => props.theme.colors.accountMenu.default.textColor};
  background-color: ${props => props.theme.colors.accountMenu.default.backgroundColor};
  border-color: ${props => props.theme.colors.accountMenu.default.borderColor};

  ${Icon} {
    fill: ${props => props.theme.colors.accountMenu.default.textColor};
  }

  cursor: pointer;
  user-select: none;

  &[data-is-open='true'] {
    border-width: 2px 2px 0px 2px;
    border-radius: 4px 4px 0px 0px;
    padding: 10px 16px 10px 14px;
  }

  &:hover,
  &[data-is-open='true'] {
    color: ${props => props.theme.colors.accountMenu.hover.textColor};
    border-color: ${props => props.theme.colors.accountMenu.hover.borderColor};
    background-color: ${props => props.theme.colors.accountMenu.hover.backgroundColor};

    ${Icon} {
      fill: ${props => props.theme.colors.accountMenu.hover.textColor};
    }
  }

  &:focus {
    outline: 2px solid ${props => props.theme.colors.focus};
  }
`;

const Name = styled(ButtonLabel)`
  margin: 0 12px;
  font-family: ${props => props.theme.fontFamily.Inter};
  font-weight: 600;
  font-size: 16px;
`;

interface Props {
  className?: string;
  isOpen: boolean;
}

export const AccountMenuInfo: FC<Props> = ({ className, isOpen }) => (
  <AccountMenuInfoStyled data-is-open={isOpen} className={className}>
    <AccountMenuImage />
    <Name>More</Name>
  </AccountMenuInfoStyled>
);
