import { H1 } from 'Atoms/text';
import { ErrorBoundary } from 'errorBoundary';
import { ContentError } from 'Molecules/ContentError';
import { SearchBar } from 'Molecules/search/SearchBar';
import { SearchContent } from 'Organisms/search/SearchContent';
import { SearchResultText } from 'Organisms/search/SearchResultText';
import React, { FC, FormEvent, KeyboardEvent, useRef } from 'react';
import Autosuggest, {
  ChangeEvent,
  OnSuggestionsClearRequested,
  OnSuggestionSelected,
  SuggestionsFetchRequested,
} from 'react-autosuggest';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'services/useSearchParams';
import {
  useDispatch as useSearchDispatch,
  useSearchResults,
  useSearchSuggestions,
  useState as useSearchState,
} from 'store/searchStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { SearchFilterType, SearchModuleName } from 'types/search';

const SearchStyled = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
`;

interface StyleProps {
  searchResultsText?: boolean;
}

const SearchBarStyled = styled(SearchBar)<StyleProps>`
  margin-top: 20px;

  margin-bottom: ${props => (props.searchResultsText ? '15' : '35')}px;
`;

const SearchContentStyled = styled(SearchContent)`
  margin-top: 20px;

  max-width: 100%;
`;

const Title = styled(H1)`
  margin: 40px 0 60px 0;
`;

export const Search: FC = () => {
  const getSearchSuggestions = useSearchSuggestions();
  const loadMore = useSearchResults();
  const [{ type: recordType, module: moduleName }, pushSearchParams] = useSearchParams();

  const ref = useRef<Autosuggest>(null);

  const {
    suggestions,
    results,
    moduleResults,
    isLoadingAdditionalResults,
    currentSearch,
  } = useSearchState();
  const dispatch = useSearchDispatch();

  assertIsNotStoreError(suggestions);
  assertIsNotStoreError(results);
  assertIsNotStoreError(moduleResults);

  const searchResults = moduleName ? moduleResults : results;

  const onChange = (_e: FormEvent<HTMLElement>, params: ChangeEvent): void => {
    if (params.newValue.length === 0) {
      dispatch({ type: 'Search/Results/Loaded', payload: null });
      pushSearchParams(params.newValue, recordType, moduleName);
    }

    dispatch({ type: 'Search/SearchValue', payload: params.newValue });
  };

  const onSuggestionsFetchRequested: SuggestionsFetchRequested = (params): void => {
    if (params.reason !== 'input-focused') {
      getSearchSuggestions(params.value);
    }
  };

  const onSuggestionsClearRequested: OnSuggestionsClearRequested = () => {
    dispatch({ type: 'Search/Suggestions/Loaded', payload: [] });
  };

  const onSuggestionSelected: OnSuggestionSelected<string> = (event, data) => {
    pushSearchParams(data.suggestion, recordType, moduleName);
  };

  const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      ref.current?.input?.blur();
      pushSearchParams(currentSearch.searchValue, recordType, moduleName);
    }
  };

  const onChangeFilter = (value: SearchFilterType): void =>
    pushSearchParams(currentSearch.searchValue, value, moduleName);

  const onClickSubmit = (): void =>
    pushSearchParams(currentSearch.searchValue, recordType, moduleName);

  const onClickModule = (value: SearchModuleName): void =>
    pushSearchParams(currentSearch.searchValue, recordType, value);

  return (
    <SearchStyled>
      <Helmet>
        <title>Search - GIDEON</title>
        <meta
          name="description"
          content="Search through millions of data points of epidemiological data. Narrow down your results by category: diseases, drugs, vaccines, or pathogens."
        />
      </Helmet>
      <Title weight="500" font="Quicksand">
        Search
      </Title>
      <SearchBarStyled
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        suggestions={isLoading(suggestions) ? [] : suggestions}
        value={currentSearch.searchValue}
        onChange={onChange}
        onKeyDown={onInputKeyDown}
        innerRef={ref}
        onChangeFilter={onChangeFilter}
        filterValue={recordType}
        onClickSubmit={onClickSubmit}
        isLoading={isLoading(suggestions)}
        searchResultsText={!isLoading(searchResults) && !!searchResults}
      />
      {!isLoading(searchResults) && searchResults && (
        <SearchResultText resultCount={searchResults.total} />
      )}
      <ErrorBoundary error={props => <ContentError title="SearchContent" {...props} />}>
        <SearchContentStyled
          loadMore={loadMore}
          isLoadingMore={isLoadingAdditionalResults}
          onClick={onClickModule}
          activeModule={moduleName}
          searchModule={results}
          searchResults={searchResults}
        />
      </ErrorBoundary>
    </SearchStyled>
  );
};
