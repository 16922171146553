import { FilledButton, StyledSpan } from 'Atoms/buttons/FilledButton';
import { Icon, SvgComponent } from 'Atoms/Icon';
import { Span } from 'Atoms/text';
import React, { FC, MouseEventHandler } from 'react';
import { animated, useSpring } from 'react-spring';
import { useMobile } from 'services/useMobile';
import { useTooltip } from 'services/useTooltip';
import styled from 'styled-components/macro';
import { formatNumber } from 'utils/formatNumber';

const StyledIcon = styled(Icon)`
  width: 30px;
  height: 30px;
`;

const StyledButton = styled(FilledButton)`
  padding: 15px;

  ${StyledSpan} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  ${StyledSpan} {
    ${Icon} {
      fill: ${props => props.theme.colors.text.inactive};
    }
  }

  &:focus:enabled {
    ${Span} {
      color: ${props => props.theme.colors.filledButton.default.focus.textColor};
    }
  }

  &:hover:enabled {
    ${Span} {
      color: ${props => props.theme.colors.filledButton.default.hover.textColor};
    }
  }

  &:active:enabled {
    ${StyledSpan} {
      ${Icon} {
        fill: ${props => props.theme.colors.filledButton.default.active.textColor};
      }
    }
  }

  box-shadow: unset;
`;

const Count = styled(Span)`
  margin-top: 20px;
`;

const Name = styled(Span)`
  white-space: nowrap;
`;

interface Props {
  className?: string;
  count: number;
  icon: SvgComponent;
  name: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  active?: boolean;
}

export const ContentSection: FC<Props> = ({ className, count, icon, name, onClick, active }) => {
  const { number } = useSpring({ number: count, from: { number: 0 } });
  const [props, tooltip, setElement] = useTooltip('Click to expand', {
    eventElementSelector: true,
  });
  const isMobile = useMobile('m');

  return (
    <div {...props.events} className={className}>
      <StyledButton
        onClick={onClick}
        active={active}
        ref={ref => setElement(ref)}
        {...props.aria}
        aria-expanded={active}
      >
        <StyledIcon svgComponent={icon} fill="inactive" />
        <Count size="veryBig" color="inactive" weight="600">
          <animated.span>{number.to(number => formatNumber(Math.floor(number)))}</animated.span>
        </Count>
        <Name size={isMobile ? 'normal' : 'big'} color="inactive" weight="600">
          {name}
        </Name>
      </StyledButton>
      {!isMobile && tooltip}
    </div>
  );
};
