import { debounce } from 'lodash';
import { OnChangeValue } from 'react-select';
import { filterSelectOptionByInput } from 'services/select.service';
import { usePushState } from 'services/usePushState.hook';
import { RecordLocationState } from 'types/record';
import { SelectOption } from 'types/select';

const debouncedLoadOptions = debounce(
  (loadOption: () => Promise<SelectOption[]>, callback: (options: SelectOption[]) => void) =>
    loadOption().then(callback),
  300
);

interface ReturnType {
  onChange: (selected: OnChangeValue<SelectOption, false>) => void;
  loadOptions: (inputValue: string) => Promise<SelectOption[]>;
}

export const useSelectSynonyms = (
  section: string,
  defaultOptions: SelectOption[],
  loadSynonymOptions?: (inputValue: string) => Promise<SelectOption[]>,
  applyInputFilter = true,
  setInputLimitation = true,
): ReturnType => {
  const { push } = usePushState();

  const onChange = (selected: OnChangeValue<SelectOption, false>): void => {
    if (!selected) {
      return;
    }

    const pushState: RecordLocationState = {
      synonymName: selected.label,
    };

    push(`/explore/${section}/${selected.value}`, selected.synonymOf ? pushState : undefined);
  };

  const loadOptions = (inputValue: string): Promise<SelectOption[]> =>
    new Promise<SelectOption[]>(resolve =>
      debouncedLoadOptions(async () => {
        const filteredOptions = applyInputFilter
          ? filterSelectOptionByInput(defaultOptions, inputValue)
          : defaultOptions;

        if (setInputLimitation && inputValue.length < 3) {
          return filteredOptions;
        }

        const synonyms = loadSynonymOptions ? await loadSynonymOptions(inputValue) : [];

        return filteredOptions.concat(synonyms);
      }, resolve)
    );

  return { onChange, loadOptions };
};
