import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components/macro';

const DefaultDebounceDelay = 500;

const PlaceholderWrapper = styled.div`
  display: inline;
`;

interface Props {
  children: ReactNode;
  placeholder: ReactNode;
  threshold?: number;
  debounceMs?: number;
  disabled?: boolean;
}

export const LazyLoad: FC<Props> = ({
  children,
  placeholder,
  threshold = 0,
  debounceMs = DefaultDebounceDelay,
  disabled,
}) => {
  const [isLoaded, setIsLoaded] = useState(!!disabled);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold,
  });

  useEffect(() => {
    // This effect debounces component loading if user just scrolls through
    if (inView) {
      const handler = setTimeout(() => {
        setIsLoaded(inView);
      }, debounceMs);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [setIsLoaded, inView, debounceMs]);

  return (
    <>{isLoaded ? children : <PlaceholderWrapper ref={ref}>{placeholder}</PlaceholderWrapper>}</>
  );
};
