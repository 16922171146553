import { DeepLinkRedirectState } from 'Atoms/routes/AuthRoute';
import React, { FC, useLayoutEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useAccountController } from 'store/accountStore/hooks';

export const MagicLinkLogin: FC = () => {
  const { setToken, magicSignIn, setMagicToken } = useAccountController();

  const location = useLocation<DeepLinkRedirectState | undefined>();

  const hash = location.pathname.split('/').at(-1) || '';
  const params = new URLSearchParams(location.search);

  const target = params.get('target') || '/';

  useLayoutEffect(() => {
    const handleMagicSignIn = async () => {
      try {
        const token = await magicSignIn(hash);
        if (token) {
          setToken(token);
          setMagicToken(token);
        }
      } catch (error: any) {
        setMagicToken('Expired');
      }
    };
    handleMagicSignIn();
  }, [setToken, hash, magicSignIn, setMagicToken]);

  return <Redirect to={target} />;
};
