import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { contentClassNames, Modal } from '../../Atoms/Modal';
import { ModalCard } from '../modalCard/ModalCard';

const ModalStyled = styled(Modal)`
  .${contentClassNames.base} {
    max-height: 80%;
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      max-height: 100%;
    }
  }
`;

const Container = styled.div`
  padding: 0px 0px 20px 0px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-height: 100%;
  }
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const PrivacyModal: FC<Props> = ({ isOpen, onClose }) => (
  <ModalStyled isOpen={isOpen} onClose={onClose} showCloseButton>
    <ModalCard title="Privacy Policy">
      <Container>
        <p>
          Thank you for choosing to be part of our community at GIDEON Informatics, Inc (“
          <strong>Company</strong>”, “<strong>we</strong>”, “<strong>us</strong>”, or “
          <strong>our</strong>”). We are committed to protecting your personal information and your
          right to privacy. If you have any questions or concerns about our policy or our practices
          with regards to your personal information, please contact us at info@gideononline.com.
        </p>
        <p>
          When you visit our website{' '}
          <a href="https://gideononline.com/">https://gideononline.com</a> and use our services, you
          trust us with your personal information. We take your privacy very seriously. In this
          privacy policy, we seek to explain to you in the clearest way possible what information we
          collect, how we use it, and what rights you have in relation to it. We hope you take some
          time to read through it carefully, as it is important. If there are any terms in this
          privacy policy that you do not agree with, please discontinue the use of our Sites and our
          services.
        </p>
        <p>
          This privacy policy applies to all information collected through our website (such as{' '}
          <a href="https://gideononline.com/">https://gideononline.com</a>), and/or any related
          services, sales, marketing, or events (we refer to them collectively in this privacy
          policy as the &#8220;<strong>Services</strong>&#8220;).
        </p>
        <p>
          <strong>
            Please read this privacy policy carefully as it will help you make informed decisions
            about sharing your personal information with us.
          </strong>
        </p>
        <ol>
          <li>
            <strong> WHAT INFORMATION DO WE COLLECT?</strong>
          </li>
        </ol>
        <p>
          <strong>Personal information you disclose to us</strong>
        </p>
        <p>
          <strong>
            <em>In Short:</em>
          </strong>
          <strong> </strong>
          <em>We collect personal information that you provide to us.</em>
        </p>
        <p>
          We collect personal information that you voluntarily provide to us when registering at the
          Services expressing an interest in obtaining information about us or our products and
          services when participating in activities on the Services or otherwise contacting us.
        </p>
        <p>
          The personal information that we collect depends on the context of your interactions with
          us and the Services, the choices you make and the products and features you use. The
          personal information we collect can include the following:
        </p>
        <p>
          <strong>Publicly Available Personal Information.</strong> We collect first name, maiden
          name, last name, and nickname; email addresses; business phone number; phone numbers;
          business email; and other similar data.
        </p>
        <p>
          <strong>Personal Information Provided by You.</strong> We collect app usage; purchase
          history; passwords; financial information (credit card number, purchase history,
          invoices); and other similar data.
        </p>
        <p>
          <strong>Payment Data.</strong> We collect data necessary to process your payment if you
          make purchases, such as your payment instrument number (such as a credit card number), and
          the security code associated with your payment instrument. All payment data is stored by
          FoxyCart.com LLC, PayPal, and Stripe. You may find their privacy policy on their websites.
        </p>
        <p>
          All personal information that you provide to us must be true, complete, and accurate, and
          you must notify us of any changes to such personal information.
        </p>
        <p>
          <strong>Information automatically collected</strong>
        </p>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <strong> </strong>
          <em>
            Some information — such as IP address and/or browser and device characteristics — is
            collected automatically when you visit our Services.
          </em>
        </p>
        <p>
          We automatically collect certain information when you visit, use, or navigate the
          Services. This information does not reveal your specific identity (like your name or
          contact information) but may include device and usage information, such as your IP
          address, browser and device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how and when you use our
          Services and other technical information. This information is primarily needed to maintain
          the security and operation of our Services, and for our internal analytics and reporting
          purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and similar
          technologies. You can find out more about this in our Cookies Policy:{' '}
          <a href="https://www.gideononline.com/cookies">https://www.gideononline.com/cookies</a>.
        </p>
        <p>
          <strong>Online Identifiers.</strong> We collect tools and protocols, such as IP (Internet
          Protocol) addresses; device&#8217;s geolocation; cookie identifiers, or others such as the
          ones used for analytics and marketing; devices; and other similar data.
        </p>
        <ol start={2}>
          <li>
            <strong> HOW DO WE USE YOUR INFORMATION?</strong>
          </li>
        </ol>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <strong> </strong>
          <em>
            We process your information for purposes based on legitimate business interests, the
            fulfillment of our contract with you, compliance with our legal obligations, and/or your
            consent.
          </em>
        </p>
        <p>
          We use personal information collected via our Services for a variety of business purposes
          described below. We process your personal information for these purposes in reliance on
          our legitimate business interests, in order to enter into or perform a contract with you,
          with your consent, and/or for compliance with our legal obligations. We indicate the
          specific processing grounds we rely on next to each purpose listed below.
        </p>
        <p>We use the information we collect or receive:</p>
        <ul>
          <li>
            <strong>To send you marketing and promotional communications.</strong> We and/or our
            third-party marketing partners may use the personal information you send to us for our
            marketing purposes if this is in accordance with your marketing preferences. You can
            opt-out of our marketing emails at any time (see the &#8220;WHAT ARE YOUR PRIVACY
            RIGHTS&#8221; below).
          </li>
          <li>
            <strong>To send administrative information to you. </strong>We may use your personal
            information to send you a product, service, and new feature information and/or
            information about changes to our terms, conditions, and policies.
          </li>
          <li>
            <strong>Fulfill and manage your orders.</strong> We may use your information to fulfill
            and manage your orders, payments, returns, and exchanges made through the Services.
          </li>
          <li>
            <strong>Deliver targeted advertising to you. </strong>We may use your information to
            develop and display content and advertising (and work with third parties who do so)
            tailored to your interests and/or location and to measure its effectiveness. For more
            information, see our Cookie Policy:{' '}
            <a href="https://www.gideononline.com/cookies">https://www.gideononline.com/cookies</a>.
          </li>
          <li>
            <strong>Request Feedback.</strong> We may use your information to request feedback and
            to contact you about your use of our Services.
          </li>
          <li>
            <strong>To protect our Services.</strong> We may use your information as part of our
            efforts to keep our Services safe and secure (for example, for fraud monitoring and
            prevention).
          </li>
          <li>
            <strong>
              To enforce our terms, conditions and policies for Business Purposes, Legal Reasons,
              and Contractual.
            </strong>
          </li>
          <li>
            <strong>To respond to legal requests and prevent harm. </strong>If we receive a subpoena
            or other legal request, we may need to inspect the data we hold to determine how to
            respond.
          </li>
          <li>
            <strong>To manage user accounts</strong>. We may use your information for the purposes
            of managing our account and keeping it in working order.
          </li>
          <li>
            <strong>To deliver services to the user.</strong> We may use your information to provide
            you with the requested service.
          </li>
          <li>
            <strong>To respond to user inquiries/offer support to users.</strong> We may use your
            information to respond to your inquiries and solve any potential issues you might have
            with the use of our Services.
          </li>
          <li>
            <strong>For other Business Purposes.</strong> We may use your information for other
            Business Purposes, such as data analysis, identifying usage trends, determining the
            effectiveness of our promotional campaigns and to evaluate and improve our Services,
            products, marketing, and your experience. We may use and store this information in
            aggregated and anonymized form so that it is not associated with individual end-users
            and does not include personal information. We will not use identifiable personal
            information without your consent.
          </li>
        </ul>
        <ol start={3}>
          <li>
            <strong> WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong>
          </li>
        </ol>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <strong> </strong>
          <em>
            We only share information with your consent, to comply with laws, to provide you with
            services, to protect your rights, or to fulfill business obligations.
          </em>
        </p>
        <p>We may process or share data based on the following legal basis:</p>
        <ul>
          <li>
            <strong>Consent:</strong> We may process your data if you have given us specific consent
            to use your personal information for a specific purpose.
          </li>
          <li>
            <strong>Legitimate Interests:</strong> We may process your data when it is reasonably
            necessary to achieve our legitimate business interests.
          </li>
          <li>
            <strong>Performance of a Contract: </strong>Where we have entered into a contract with
            you, we may process your personal information to fulfill the terms of our contract.
          </li>
          <li>
            <strong>Legal Obligations:</strong> We may disclose your information where we are
            legally required to do so in order to comply with applicable law, governmental requests,
            a judicial proceeding, court order, or legal processes, such as in response to a court
            order or a subpoena (including in response to public authorities to meet national
            security or law enforcement requirements).
          </li>
          <li>
            <strong>Vital Interests:</strong> We may disclose your information where we believe it
            is necessary to investigate, prevent, or take action regarding potential violations of
            our policies, suspected fraud, situations involving potential threats to the safety of
            any person and illegal activities, or as evidence in litigation in which we are
            involved.
          </li>
        </ul>
        <p>
          More specifically, we may need to process your data or share your personal information in
          the following situations:
        </p>
        <ul>
          <li>
            <strong>Vendors, Consultants, and Other Third-Party Service Providers.</strong> We may
            share your data with third-party vendors, service providers, contractors, or agents who
            perform services for us or on our behalf and require access to such information to do
            that work. Examples include payment processing, data analysis, email delivery, hosting
            services, customer service, and marketing efforts. We may allow selected third parties
            to use tracking technology on the Services, which will enable them to collect data about
            how you interact with the Services over time. This information may be used to, among
            other things, analyze and track data, determine the popularity of certain content, and
            better understand online activity. Unless described in this Policy, we do not share,
            sell, rent, or trade any of your information with third parties for their promotional
            purposes. We have contracts in place with our data processors. This means that they
            cannot do anything with your personal information unless we have instructed them to do
            it. They will not share your personal information with any organization apart from us.
            They will hold it securely and retain it for the period we instruct.
          </li>
          <li>
            <strong>Business Transfers.</strong> We may share or transfer your information in
            connection with, or during negotiations of, any merger, sale of company assets,
            financing, or acquisition of all or a portion of our business to another company.
          </li>
          <li>
            <strong>Business Partners.</strong> We may share your information with our business
            partners to offer you certain products, services, or promotions.
          </li>
        </ul>
        <ol start={4}>
          <li>
            <strong> DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong>
          </li>
        </ol>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <strong> </strong>
          <em>
            We may use cookies and other tracking technologies to collect and store your
            information.
          </em>
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons and pixels) to
          access or store information. Specific information about how we use such technologies and
          how you can refuse certain cookies is set out in our Cookie Policy:{' '}
          <a href="https://www.gideononline.com/cookies">https://www.gideononline.com/cookies</a>.
        </p>
        <ol start={5}>
          <li>
            <strong> HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
          </li>
        </ol>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <strong> </strong>
          <em>
            We keep your information for as long as necessary to fulfill the purposes outlined in
            this privacy policy unless otherwise required by law.
          </em>
        </p>
        <p>
          We will only keep your personal information for as long as it is necessary for the
          purposes set out in this privacy policy unless a longer retention period is required or
          permitted by law (such as tax, accounting, or other legal requirements). No purpose in
          this policy will require us to keep your personal information for longer than 2 years past
          the start of the idle period of the user&#8217;s account.
        </p>
        <p>
          When we have no ongoing legitimate business need to process your personal information, we
          will either delete or anonymize it, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will securely store your
          personal information and isolate it from any further processing until deletion is
          possible.
        </p>
        <ol start={6}>
          <li>
            <strong> HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
          </li>
        </ol>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <strong> </strong>
          <em>
            We aim to protect your personal information through a system of organizational and
            technical security measures.
          </em>
        </p>
        <p>
          We have implemented appropriate technical and organizational security measures designed to
          protect the security of any personal information we process. However, please also remember
          that we cannot guarantee that the internet itself is 100% secure. Although we will do our
          best to protect your personal information, the transmission of personal information to and
          from our Services is at your own risk. You should only access the services within a secure
          environment.
        </p>
        <ol start={7}>
          <li>
            <strong> DO WE COLLECT INFORMATION FROM MINORS?</strong>
          </li>
        </ol>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <strong> </strong>
          <em>
            We do not knowingly collect data from or market to children under 18 years of age.
          </em>
        </p>
        <p>
          We do not knowingly solicit data from or market to children under 18 years of age. By
          using the Services, you represent that you are at least 18 or that you are the parent or
          guardian of such a minor and consent to such minor dependent’s use of the Services. If we
          learn that personal information from users less than 18 years of age has been collected,
          we will deactivate the account and take reasonable measures to promptly delete such data
          from our records. If you become aware of any data we have collected from children under
          age 18, please contact us at info@gideononline.com.
        </p>
        <ol start={8}>
          <li>
            <strong> WHAT ARE YOUR PRIVACY RIGHTS?</strong>
          </li>
        </ol>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <strong> </strong>
          <em>You may review, change, or terminate your account at any time.</em>
        </p>
        <p>
          If you are resident in the European Economic Area and you believe we are unlawfully
          processing your personal information, you also have the right to complain to your local
          data protection supervisory authority. You can find their contact details here:{' '}
          <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </a>
          .
        </p>
        <p>
          If you have questions or comments about your privacy rights, you may email us at
          info@gideononline.com.
        </p>
        <p>
          <strong>Account Information</strong>
        </p>
        <p>
          If you would at any time like to review or change the information in your account or
          terminate your account, you can:
        </p>
        <p>■ Log into your account settings and update your user account.</p>
        <p>■ Contact us using the contact information provided.</p>
        <p>
          Upon your request to terminate your account, we will deactivate or delete your account and
          information from our active databases. However, some information may be retained in our
          files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our
          Terms of Use, and/or comply with legal requirements.
        </p>
        <p>
          <strong>
            <u>Cookies and similar technologies:</u>
          </strong>
          <strong> </strong>Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to reject cookies. If you
          choose to remove cookies or reject cookies, this could affect certain features or services
          of our Services. To opt-out of interest-based advertising by advertisers on our Services
          visit <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>.
          For further information, please see our Cookie Policy:{' '}
          <a href="https://www.gideononline.com/cookies">https://www.gideononline.com/cookies</a>.
        </p>
        <p>
          <strong>
            <u>Opting out of email marketing:</u>
          </strong>
          <strong> </strong>You can unsubscribe from our marketing email list at any time by
          clicking on the unsubscribe link in the emails that we send or by contacting us using the
          details provided below. You will then be removed from the marketing email list – however,
          we will still need to send you service-related emails that are necessary for the
          administration and use of your account. To otherwise opt-out, you may:
        </p>
        <p>■ Contact us using the contact information provided.</p>
        <p>■ Unsubscribe using the link in the email</p>
        <ol start={9}>
          <li>
            <strong> DATA BREACH</strong>
          </li>
        </ol>
        <p>
          A privacy breach occurs when there is unauthorized access to or collection, use,
          disclosure or disposal of personal information. You will be notified about data breaches
          when GIDEON Informatics, Inc believes you are likely to be at risk or serious harm. For
          example, a data breach may be likely to result in serious financial harm or harm to your
          mental or physical well-being. In the event that GIDEON Informatics, Inc becomes aware of
          a security breach which has resulted or may result in unauthorized access, use or
          disclosure of personal information GIDEON Informatics, Inc will promptly investigate the
          matter and notify the applicable Supervisory Authority not later than 72 hours after
          having become aware of it, unless the personal data breach is unlikely to result in a risk
          to the rights and freedoms of natural persons.
        </p>
        <ol start={10}>
          <li>
            <strong> CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
          </li>
        </ol>
        <p>
          Most web browsers and some mobile operating systems and mobile applications include a
          Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference
          not to have data about your online browsing activities monitored and collected. No uniform
          technology standard for recognizing and implementing DNT signals has been finalized. As
          such, we do not currently respond to DNT browser signals or any other mechanism that
          automatically communicates your choice not to be tracked online. If a standard for online
          tracking is adopted that we must follow in the future, we will inform you about that
          practice in a revised version of this privacy policy.
        </p>
        <ol start={11}>
          <li>
            <strong> DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong>
          </li>
        </ol>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <strong> </strong>
          <em>
            Yes, if you are a resident of California, you are granted specific rights regarding
            access to your personal information.
          </em>
        </p>
        <p>
          California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits
          our users who are California residents to request and obtain from us, once a year and free
          of charge, information about categories of personal information (if any) we disclosed to
          third parties for direct marketing purposes and the names and addresses of all third
          parties with which we shared personal information in the immediately preceding calendar
          year. If you are a California resident and would like to make such a request, please
          submit your request in writing to us using the contact information provided below.
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a registered account with
          the Services, you have the right to request the removal of unwanted data that you publicly
          post on the Services. To request the removal of such data, please contact us using the
          contact information provided below, and include the email address associated with your
          account and a statement that you reside in California. We will make sure the data is not
          publicly displayed on the Services, but please be aware that the data may not be
          completely or comprehensively removed from our systems.
        </p>
        <ol start={12}>
          <li>
            <strong> DO WE MAKE UPDATES TO THIS POLICY?</strong>
          </li>
        </ol>
        <p>
          <strong>
            <em>In Short: </em>
          </strong>
          <strong> </strong>
          <em>
            Yes, we will update this policy as necessary to stay compliant with relevant laws.
          </em>
        </p>
        <p>
          We may update this privacy policy from time to time. The updated version will be indicated
          by an updated “Revised” date and the updated version will be effective as soon as it is
          accessible. If we make material changes to this privacy policy, we may notify you either
          by prominently posting a notice of such changes or by directly sending you a notification.
          We encourage you to review this privacy policy frequently to be informed of how we are
          protecting your information.
        </p>
        <ol start={13}>
          <li>
            <strong> HOW CAN YOU CONTACT US ABOUT THIS POLICY?</strong>
          </li>
        </ol>
        <p>
          If you have questions or comments about this policy, you may email us at
          info@gideononline.com or by post to:
        </p>
        <p>
          GIDEON Informatics, Inc
          <br />
          8721 Santa Monica Blvd, Suite 234
          <br />
          Los Angeles, CA 90069
          <br />
          USA
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</strong>
        </p>
        <p>
          Based on the laws of some countries, you may have the right to request access to the
          personal information we collect from you, change that information, or delete it in some
          circumstances. To request to review, update, or delete your personal information, please
          submit a request by emailing info@gideononline.com We will respond to your request within
          30 days.
        </p>
        <p>&nbsp;</p>
      </Container>
    </ModalCard>
  </ModalStyled>
);
