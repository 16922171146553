import { getChart } from 'apiServices/Charts/charts';
import React, { useEffect } from 'react';
import { newStoreError } from 'store/storeError';
import { Dispatch, Loading, Resource } from 'store/types';
import { Chart } from 'types/chart';

import { Action, ChartDispatchContext, ChartStateContext, State } from './provider';

export const useState = (): State => {
  const state = React.useContext(ChartStateContext);
  if (state === undefined) {
    throw new Error('Chart store is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(ChartDispatchContext);
  if (dispatch === undefined) {
    throw new Error('Chart store is not initialized');
  }
  return dispatch;
};

export const useDiseaseChart = (
  diseaseId: number,
  countryId: string,
  graphId: string,
  getNotes = false
): Resource<Chart> => {
  const url = `/api/diseases/${diseaseId}/distribution/${countryId}/charts/${graphId}`;
  const state = useState();
  const dispatch = useDispatch();
  const chartData = state[url];

  useEffect(() => {
    if (!chartData) {
      dispatch({ type: 'Chart/SingleLoadInitiated', payload: { url } });
      getChart(url, getNotes)
        .then(data => dispatch({ type: 'Chart/SingleLoaded', payload: { url, data } }))
        .catch(err => {
          dispatch({
            type: 'Chart/SingleLoadFailed',
            payload: { url, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [state, dispatch, chartData, url, getNotes]);

  return chartData || Loading;
};
