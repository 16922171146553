import { LazyLoadPlaceholder } from 'Atoms/LazyLoadPlaceholder';
import { GraphAndTableFrame } from 'Molecules/graph/GraphAndTableFrame';
import { CaptureElementRef, GraphCapture } from 'Molecules/graph/GraphCapture';
import { LazyLoad } from 'Molecules/LazyLoad';
import React, { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHashAnchor } from 'services/useHashAnchor.hook';
import { useMobile } from 'services/useMobile';
import { useDiseaseChart } from 'store/chartsStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { CustomError, ErrorCodes } from 'types/errorTypes';

const Container = styled.div`
  background-color: ${props => props.theme.colors.background.modalCardBody};
  padding: 25px 43px;
  border: 1px solid ${props => props.theme.colors.recordSection.border};

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 25px 15px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 15px 5px;
  }
`;

const CaptureContainer = styled(Container)``;

interface Props {
  graphId: string;
  diseaseId: string;
  countryId: string;
  placeholder: ReactNode;
}

export const GraphAndTable: FC<Props> = ({ diseaseId, countryId, graphId, placeholder }) => {
  const diseaseIdParsed = parseInt(diseaseId, 10);
  if (Number.isNaN(diseaseIdParsed) || diseaseIdParsed < 0) {
    throw new CustomError('Bad disease ID', ErrorCodes.DynamicContentBadAttribute, 'Error');
  }

  const chart = useDiseaseChart(diseaseIdParsed, countryId, graphId, true);
  const chartRates = useDiseaseChart(diseaseIdParsed, countryId, graphId + 'X');
  const isMobile = useMobile('m');
  const location = useLocation();

  const [isGraphSelected, setIsGraphSelected] = useState(true);

  const captureElementRef = useRef<CaptureElementRef>(null);

  const capture = useCallback(() => {
    if (captureElementRef.current) {
      captureElementRef.current.capture();
    }
  }, []);

  assertIsNotStoreError(chart);
  assertIsNotStoreError(chartRates);

  if (isLoading(chart) || isLoading(chartRates)) {
    return <p>{placeholder}</p>;
  }

  return (
    <>
      <GraphCapture ref={captureElementRef} imageName={`GIDEON ${chart.title}`}>
        <CaptureContainer>
          <GraphAndTableFrame
            chart={chart}
            chartRates={chartRates}
            isGraphSelected={isGraphSelected}
            isMobile={false}
            showCitation={true}
            location={location.pathname}
          />
        </CaptureContainer>
      </GraphCapture>
      <Container>
        <GraphAndTableFrame
          chart={chart}
          chartRates={chartRates}
          onDownload={capture}
          isGraphSelected={isGraphSelected}
          setIsGraphSelected={setIsGraphSelected}
          isMobile={isMobile}
          location={location.pathname}
        />
      </Container>
    </>
  );
};

export const GraphAndTableLazy: FC<Omit<Props, 'placeholder'>> = props => {
  const ref = useRef<HTMLDivElement>(null);
  const hashRegex = useMemo(() => new RegExp(`${props.graphId}X?`), [props.graphId]);
  useHashAnchor(ref, hashRegex);
  const placeholder = <LazyLoadPlaceholder height={730} />;
  return (
    <>
      <div ref={ref} />
      <LazyLoad threshold={0.2} placeholder={placeholder}>
        <GraphAndTable {...props} placeholder={placeholder} />
      </LazyLoad>
    </>
  );
};
