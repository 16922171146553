import { InlineInputWrapper, Label, StyledButton } from 'Molecules/account/AccountFormInput';
import { Subsection } from 'Molecules/account/Subsection';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const StyledSubsection = styled(Subsection)`
  margin-bottom: 30px;
`;

interface Props {
  onChangePasswordClick: () => void;
}

export const Access: FC<Props> = ({ onChangePasswordClick }) => (
  <StyledSubsection title="Access">
    <InlineInputWrapper>
      <Label>Password</Label>
      <StyledButton type="button" onClick={onChangePasswordClick}>
        Click here to change
      </StyledButton>
    </InlineInputWrapper>
  </StyledSubsection>
);
