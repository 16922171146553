import { InlineInputWrapper, Label, StyledButton } from 'Molecules/account/AccountFormInput';
import { Subsection } from 'Molecules/account/Subsection';
import React, { FC } from 'react';

interface Props {
  onGDPRDeleteClick: () => void;
}

export const GDPR: FC<Props> = ({ onGDPRDeleteClick }) => (
  <Subsection title="Data Privacy">
    <InlineInputWrapper>
      <Label>GIDEON respects your right to erasure</Label>
    </InlineInputWrapper>
    <StyledButton type="button" onClick={onGDPRDeleteClick}>
      Click here to request deletion of your data
    </StyledButton>
  </Subsection>
);
