import React, { FC, ReactNode } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { FontSizeCollection } from 'types/fontSizeCollection';

export type Size = 'small' | 'medium';
const sizes: FontSizeCollection<Size> = {
  small: {
    desktop: '16px',
    mobile: '15px',
  },
  medium: {
    desktop: '20px',
    mobile: '16px',
  },
};

interface Props {
  className?: string;
  id?: string;
  htmlFor?: string;
  children?: ReactNode;
  size?: Size;
  color?: keyof DefaultTheme['colors']['text'];
  weight?: '400' | '500' | '600' | '700';
  font?: keyof DefaultTheme['fontFamily'];
}

const LabelBase: FC<Props> = ({ id, className, children, htmlFor }) => (
  <label className={className} id={id} htmlFor={htmlFor}>
    {children}
  </label>
);

export const Label = styled(LabelBase)`
  color: ${props => props.theme.colors.text[props.color || 'input']};
  font-family: ${props => props.theme.fontFamily[props.font || 'Inter']};
  font-weight: ${props => props.weight || '400'};
  font-size: ${props => sizes[props.size || 'medium'].desktop};

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: ${props => sizes[props.size || 'medium'].mobile};
  }
`;
