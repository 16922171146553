import { Placement as PopperPlacement } from '@popperjs/core';
import styled from 'styled-components/macro';

const TooltipBase = styled.span`
  font: ${props => props.theme.fonts.normalText};
  min-width: 190px;
  max-width: 250px;
  white-space: normal;
  padding: 8px 5px;
  color: ${props => props.theme.colors.tooltip.text};
  background-color: ${props => props.theme.colors.tooltip.background};
  font-weight: normal;
  font-size: 14px;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  user-select: none;

  display: block;
`;

export const Tooltip = styled(TooltipBase)`
  top: -24px;
  left: 22px;
  position: absolute;
  z-index: 10;
  display: none;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none !important;
  }
`;

interface PopperTooltipProps {
  position: PopperPlacement;
}

export const PopperTooltip = styled(TooltipBase)<PopperTooltipProps>`
  margin-top: ${props => (props.position === 'bottom' ? '8px' : '0')};
  margin-right: ${props => (props.position === 'left' ? '5px' : '0')};
  margin-bottom: ${props => (props.position === 'top' ? '5px' : '0')};
  margin-left: ${props => (props.position === 'right' ? '5px' : '0')};

  margin-bottom: ${props => (props.position === 'top-end' ? '-5px' : '0')};
`;
