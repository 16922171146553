import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'CountryDetails/Details/SingleLoadInitiated': {
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], details: 'Loading' },
      };
    }
    case 'CountryDetails/Details/SingleLoaded': {
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], details: action.payload.data },
      };
    }
    case 'CountryDetails/Details/SingleLoadFailed': {
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], details: action.payload.error },
      };
    }
    case 'CountryDetails/Diseases/SingleLoadInitiated': {
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], diseases: 'Loading' },
      };
    }
    case 'CountryDetails/Diseases/SingleLoaded': {
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], diseases: action.payload.data },
      };
    }
    case 'CountryDetails/Diseases/SingleLoadFailed': {
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], diseases: action.payload.error },
      };
    }
    case 'CountryDetails/Public/SingleLoadInitiated': {
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], detailsPublic: 'Loading' },
      };
    }
    case 'CountryDetails/Public/SingleLoaded': {
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], detailsPublic: action.payload.data },
      };
    }
    case 'CountryDetails/Public/SingleLoadFailed': {
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], detailsPublic: action.payload.error },
      };
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
