import {
  DiagnosisRow,
  isDisaseCell,
  isProbabilityCell as isDiagnosisProbabilityCell,
} from 'Diagnose/types/diagnosisTable';
import {
  isPathogenCell,
  isProbabilityCell as isPathogenProbabilityCell,
  PathogenRow,
} from 'Lab/types/identifyTable';
import { CompareDrugRow } from 'types/compare';
import { Row } from 'types/table';

const alphabetRegex = /^[a-zA-Z]/;
const digitRegex = /^\d/;

const getGroupedRows = (rows: Row[], columnIndex: number): [Row[], Row[], Row[]] => {
  const alphabetGroup: Row[] = [];
  const digitGroup: Row[] = [];
  const symbolGroup: Row[] = [];

  rows.forEach(row => {
    if (alphabetRegex.test(row[columnIndex].value.toString())) {
      alphabetGroup.push(row);
    } else if (digitRegex.test(row[columnIndex].value.toString())) {
      digitGroup.push(row);
    } else {
      symbolGroup.push(row);
    }
  });
  return [alphabetGroup, digitGroup, symbolGroup];
};

const ascCompare = (value1: string | number, value2: string | number): number => {
  if (value1 < value2) return -1;
  if (value1 > value2) return 1;
  return 0;
};

const getRowComparator = (asc: boolean, columnIndex: number, secondColumnIndex: number) => (
  row1: Row,
  row2: Row
) => {
  const order = asc ? 1 : -1;
  const result = ascCompare(row1[columnIndex].value, row2[columnIndex].value);

  if (result === 0 && secondColumnIndex >= 0) {
    return ascCompare(row1[secondColumnIndex].value, row2[secondColumnIndex].value);
  }

  return result * order;
};

export const sortTableRows = (
  rows: Row[],
  asc: boolean,
  columnIndex: number,
  secondColumnIndex = -1
): Row[] => {
  if (columnIndex < 0) return rows;

  const [alphabetGroup, digitGroup, symbolGroup] = getGroupedRows(rows, columnIndex);
  return [
    ...alphabetGroup.sort(getRowComparator(asc, columnIndex, secondColumnIndex)),
    ...digitGroup.sort(getRowComparator(asc, columnIndex, secondColumnIndex)),
    ...symbolGroup.sort(getRowComparator(asc, columnIndex, secondColumnIndex)),
  ];
};

const getGroupedDrugRows = (
  rows: CompareDrugRow[]
): [CompareDrugRow[], CompareDrugRow[], CompareDrugRow[]] => {
  const alphabetGroup: CompareDrugRow[] = [];
  const digitGroup: CompareDrugRow[] = [];
  const symbolGroup: CompareDrugRow[] = [];

  rows.forEach(row => {
    if (alphabetRegex.test(row.value.toString())) {
      alphabetGroup.push(row);
    } else if (digitRegex.test(row.value.toString())) {
      digitGroup.push(row);
    } else {
      symbolGroup.push(row);
    }
  });
  return [alphabetGroup, digitGroup, symbolGroup];
};

const getDrugRowComparator = (asc: boolean) => (row1: CompareDrugRow, row2: CompareDrugRow) => {
  const order = asc ? 1 : -1;
  const result = ascCompare(row1.value, row2.value);

  if (result === 0) {
    return ascCompare(row1.value, row2.value);
  }

  return result * order;
};

export const sortDrugsRows = (
  rows: CompareDrugRow[],
  asc: boolean,
  columnIndex: number
): CompareDrugRow[] => {
  if (columnIndex < 0) return rows;

  const [alphabetGroup, digitGroup, symbolGroup] = getGroupedDrugRows(rows);
  return [
    ...alphabetGroup.sort(getDrugRowComparator(asc)),
    ...digitGroup.sort(getDrugRowComparator(asc)),
    ...symbolGroup.sort(getDrugRowComparator(asc)),
  ];
};

const getDiagnosisRowComparator = (asc: boolean, columnIndex: number) => (
  row1: DiagnosisRow,
  row2: DiagnosisRow
): number => {
  const cellOne = row1[columnIndex];
  const cellTwo = row2[columnIndex];

  const order = asc ? 1 : -1;

  if (isDisaseCell(cellOne) && isDisaseCell(cellTwo)) {
    return ascCompare(cellOne.label, cellTwo.label) * order;
  } else if (isDiagnosisProbabilityCell(cellOne) && isDiagnosisProbabilityCell(cellTwo)) {
    return ascCompare(cellOne.value, cellTwo.value) * order;
  } else {
    return 0;
  }
};

export const sortDiagnosisTableRows = (
  rows: DiagnosisRow[],
  asc: boolean,
  columnIndex: number
): DiagnosisRow[] => rows.sort(getDiagnosisRowComparator(asc, columnIndex));

const getPathogenRowComparator = (asc: boolean, columnIndex: number) => (
  row1: PathogenRow,
  row2: PathogenRow
): number => {
  const cellOne = row1[columnIndex];
  const cellTwo = row2[columnIndex];

  const order = asc ? 1 : -1;

  if (isPathogenCell(cellOne) && isPathogenCell(cellTwo)) {
    return ascCompare(cellOne.label, cellTwo.label) * order;
  } else if (isPathogenProbabilityCell(cellOne) && isPathogenProbabilityCell(cellTwo)) {
    return ascCompare(cellOne.value, cellTwo.value) * order;
  } else {
    return 0;
  }
};

export const sortIdentifyTableRows = (
  rows: PathogenRow[],
  asc: boolean,
  columnIndex: number
): PathogenRow[] => rows.sort(getPathogenRowComparator(asc, columnIndex));
