import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { DiseaseType } from 'types/az';
import { SimpleDiseaseWithSlug } from 'types/simpleDisease';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'DiseasesGroup/LoadInitiated', { type: DiseaseType }>
  | ActionWithPayload<'DiseasesGroup/Loaded', { type: DiseaseType; data: SimpleDiseaseWithSlug[] }>
  | ActionWithPayload<'DiseasesGroup/LoadFailed', { type: DiseaseType; data: StoreError }>;

export type State = Record<DiseaseType, Resource<SimpleDiseaseWithSlug[]> | null>;

const initialState: State = {
  algal: null,
  bacterial: null,
  fungal: null,
  parasitic: null,
  prion: null,
  protoctistal: null,
  viral: null,
  'unknown-agent': null,
};

export const {
  dispatchContext: DiseasesGroupDispatchContext,
  stateContext: DiseasesGroupStateContext,
  provider: DiseasesGroupStoreProvider,
} = storeFactory(reducer, initialState);
