import { Loading } from 'types/common';

import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'DiseaseDetails/SingleLoadInitiated': {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          [action.payload.type]: Loading,
        },
      };
    }
    case 'DiseaseDetails/SingleLoaded': {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          [action.payload.type]: action.payload.data,
        },
      };
    }
    case 'DiseaseDetails/SingleLoadFailed': {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          [action.payload.type]: action.payload.error,
        },
      };
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
