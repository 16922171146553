import { Label } from 'Atoms/Label';
import { H2 } from 'Atoms/text';
import { CompleteTooltip } from 'Atoms/tooltip/CompleteTooltip';
import { AsyncSelect } from 'Molecules/select/AsyncSelect';
import React, { FC, ReactNode } from 'react';
import { useSelectSynonyms } from 'services/useSelectSynonyms.hook';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  width: 100%;
  min-height: 210px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 30px 50px;
  position: relative;
`;

const SearchContainer = styled(InnerContainer)`
  width: 400px;
  min-width: 280px;
`;

const SelectStyled = styled(AsyncSelect<SelectOption, false>())`
  max-height: 44px;

  justify-self: center;
  align-self: end;

  margin-top: 20px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .${props => props.classNamePrefix}__control {
      padding: 4px 5px 4px 9px;
    }
    .${props => props.classNamePrefix}__search-icon {
      margin-right: 8px;
    }
  }
`;

const ContentContainer = styled.div`
  justify-self: center;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  margin-top: 20px;
`;

const HeadingStyled = styled(H2)`
  margin: 0 0 auto 0;
`;

const SmallHeadingStyled = styled(H2)`
  font-size: 14px;
  border-radius: 4px;
  padding: 0 5px;
  background: ${props => props.theme.colors.countriesTitle};
`;

const TooltipContainer = styled.div`
  margin-bottom: auto;
  position: relative;
`;

const StyledTooltip = styled(CompleteTooltip)`
  top: -10px;
  right: -25px;
`;

const LabelStyled = styled(Label)`
  margin: 20px 0 0 0;
`;

interface InnerContentProps {
  optionTitle?: string;
  tooltipContent?: string;
  children: ReactNode;
  className?: string;
  smallHeading?: boolean;
}

export const ExploreLayoutInnerContent: FC<InnerContentProps> = ({
  optionTitle = 'Search by...',
  tooltipContent,
  children,
  className,
  smallHeading,
}) => (
  <InnerContainer role="group" className={className} aria-labelledby="explore-option-title">
    {smallHeading ? (
      <SmallHeadingStyled weight="500" font="Quicksand" id="explore-option-title">
        {optionTitle}
      </SmallHeadingStyled>
    ) : (
      <HeadingStyled weight="500" font="Quicksand" id="explore-option-title">
        {optionTitle}
      </HeadingStyled>
    )}
    {tooltipContent && <StyledTooltip position="top" content={tooltipContent} />}
    <ContentContainer>{children}</ContentContainer>
  </InnerContainer>
);

interface TitleProps {
  id?: string;
}

export const ExploreLayoutSelectTitle: FC<TitleProps> = ({ id }) => (
  <TooltipContainer>
    <HeadingStyled weight="500" font="Quicksand" id={id}>
      I know what I’m looking for
    </HeadingStyled>
    <StyledTooltip position="top" content="Synonyms are automatically included in this search" />
  </TooltipContainer>
);

interface Props {
  children: ReactNode;
  className?: string;
  optionTitle?: string;
  tooltipContent?: string;
  placeholder: string;
  section: string;
  loadSynonymOptions?: (inputValue: string) => Promise<SelectOption[]>;
  defaultOptions: SelectOption[];
  selectLabel: string;
}

export const ExploreLayoutContent: FC<Props> = ({
  className,
  children,
  optionTitle,
  placeholder,
  section,
  loadSynonymOptions,
  tooltipContent,
  defaultOptions,
  selectLabel,
}) => {
  const { onChange, loadOptions } = useSelectSynonyms(section, defaultOptions, loadSynonymOptions);

  return (
    <Container className={className}>
      <SearchContainer role="group" aria-labelledby="explore-title">
        <ExploreLayoutSelectTitle id="explore-title" />
        <LabelStyled size="small" color="main" htmlFor="explore-select-input">
          {selectLabel}
        </LabelStyled>
        <SelectStyled
          classNamePrefix="explore-layout-select"
          placeholder={placeholder}
          loadOptions={loadOptions}
          onChange={onChange}
          showIcon
          cacheOptions
          defaultOptions={defaultOptions}
          showDropdown
          openMenuOnClick={false}
          id="explore-select"
          inputId={`explore-select-input-${section}`} // Unique ID for Cypress Testing.
        />
      </SearchContainer>
      {optionTitle && (
        <ExploreLayoutInnerContent optionTitle={optionTitle} tooltipContent={tooltipContent}>
          {children}
        </ExploreLayoutInnerContent>
      )}
    </Container>
  );
};
