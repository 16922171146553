import { Context, useContext } from 'react';
import { CustomError, ErrorCodes } from 'types/errorTypes';

export const useContextAssert = <T>(context: Context<T | undefined>, message?: string): T => {
  const value = useContext(context);

  if (value === undefined) {
    throw new CustomError(
      message || 'Context is used outside of the provider',
      ErrorCodes.StoreNotInitialized
    );
  }

  return value;
};
