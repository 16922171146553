import MenuDark from 'assets/UI/MenuDark.png';
import { ReactComponent as MenuLight } from 'assets/UI/MenuLight.svg';
import { Icon } from 'Atoms/Icon';
import React, { FC, useContext } from 'react';
import { ThemeModeContext } from 'services/theme';
import styled from 'styled-components/macro';

const MenuImage = styled.img`
  width: 20px;
  height: 20px;
`;

interface Props {
  className?: string;
}

export const AccountMenuImage: FC<Props> = ({ className }) => {
  const { isDarkMode } = useContext(ThemeModeContext);

  return isDarkMode ? (
    <MenuImage className={className} src={MenuDark} />
  ) : (
    <Icon className={className} svgComponent={MenuLight} size="small" />
  );
};
