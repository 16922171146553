import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Modal } from 'Atoms/Modal';
import { SecondaryModalCard } from 'Molecules/secondaryModalCard/SecondaryModalCard';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonBase = styled(FilledButton)`
  max-width: 200px;

  padding: 10px;

  margin-left: 10px;
`;

const ButtonConfirm = styled(FilledButton)`
  width: 100%;
  height: unset;
  max-width: 200px;
  height: 40px;

  padding: 10px;
`;

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onClose: () => void;
  onConfirm: () => void;
  confirmLabel: string;
  cancelLabel: string;
  description: string;
  className?: string;
}

export const ConfirmationModal: FC<Props> = ({
  isOpen,
  onCancel,
  onClose,
  onConfirm,
  confirmLabel,
  cancelLabel,
  description,
  className,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <SecondaryModalCard description={description} className={className}>
      <ButtonContainer>
        <ButtonConfirm onClick={onConfirm}>{confirmLabel}</ButtonConfirm>
        <ButtonBase onClick={onCancel}>{cancelLabel}</ButtonBase>
      </ButtonContainer>
    </SecondaryModalCard>
  </Modal>
);
