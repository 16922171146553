import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import { VirusSummarySection } from 'Molecules/section/VirusSummarySection';
import { getStringFromDynamicHtml } from 'Organisms/dynamicContent/DynamicHtmlToString';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import { Paywall } from 'Organisms/paywall/Paywall';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { Element } from 'react-scroll';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import { useVirusDetailsPublic } from 'store/virusDetailStore/hooks';
import styled from 'styled-components/macro';
import { isRecordLocationState } from 'types/record';

type Props = RouteComponentProps & {
  virusId: number;
};

export const VirusPublic: FC<Props> = ({ location, virusId }) => {
  const [showSummary, setShowSummary] = useState(true);

  const virus = useVirusDetailsPublic(virusId);
  const synonymName = isRecordLocationState(location.state)
    ? location.state.synonymName
    : undefined;

  if (isLoading(virus)) {
    return <Loader />;
  }

  assertIsNotStoreError(virus);

  const sections: RecordLink[] = [
    'Summary',
    'Structure',
    'Structure gallery',
    'Mechanism of infection',
    'Mechanism of infection gallery',
    'Downloads and references',
  ].map(section => ({
    label: section,
    'aria-label': `${virus.name} ${section.toLowerCase()}`,
    disabled:
      (section === 'Structure gallery' && virus.structure_images.length === 0) ||
      (section === 'Mechanism of infection gallery' && virus.mechanism_images.length === 0),
  }));

  const description = `GIDEON - ${virus.name}. Structure: ${getStringFromDynamicHtml(
    virus.structure
  )}`;

  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>{virus.name} - GIDEON</title>
          <meta name="description" content={description} />
        </Helmet>
        <ReferenceLabelContext.Provider value={virus.name}>
          <RecordLayout>
            <RecordTitle title={virus.name} synonymName={synonymName} />
            <RecordLinks links={sections} />

            <RecordSection
              link={sections[0]}
              show={showSummary}
              onCollapse={() => setShowSummary(!showSummary)}
              accent="first"
              id="virus-summary"
            >
              <VirusSummarySection type={virus.genome_type} diseases={virus.diseases} />
            </RecordSection>

            <Element name="Structure">
              <Element name="Structure gallery">
                <Element name="Mechanism of infection">
                  <Element name="Mechanism of infection gallery">
                    <Element name="Downloads and references">
                      <Paywall />
                    </Element>
                  </Element>
                </Element>
              </Element>
            </Element>
          </RecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};
