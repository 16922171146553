import React, { FC } from 'react';
import { Redirect, RedirectProps, useLocation } from 'react-router-dom';

export const RedirectWithState: FC<RedirectProps> = ({ to, ...rest }) => {
  const { state } = useLocation();

  const newTo = typeof to === 'string' ? { pathname: to, state } : { ...to, state };

  return <Redirect {...rest} to={newTo} />;
};
