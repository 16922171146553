import { Loader } from 'Atoms/Loader';
import { P } from 'Atoms/text';
import { AZDiseasesButtonsMobile } from 'Molecules/az/AZDiseasesButtonsMobile';
import { AZDownloadButtons } from 'Molecules/az/AZDownloadButtons';
import { AZListSection } from 'Molecules/az/AZListSection';
import { AZMobileResultsList } from 'Molecules/az/AZMobileResultsList';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { match, Redirect, Route, Switch } from 'react-router-dom';
import { useAccountController } from 'store/accountStore/hooks';
import { useDiseasesCategoryGrouped } from 'store/diseasesGroupStore/hooks';
import { useDiseasesGrouped } from 'store/diseasesStore/hooks';
import styled from 'styled-components/macro';
import { CategoryType, diseaseNames, DiseaseType } from 'types/az';
import { getComparisonListsSection } from 'types/comparisonLists';
import { alphabet } from 'utils/alphabet';
import { formatNullableNumber } from 'utils/formatNumber';

const ResultsCountParagraph = styled(P)`
  margin-bottom: 40px;
  text-align: center;
`;

interface Props {
  match: match;
  category: CategoryType | DiseaseType;
  title: string;
  exact?: boolean;
}

export const AZDiseasesMobile: FC<Props> = ({ match, category, title, exact }) => {
  const data = useDiseasesGrouped();
  const groupedData = useDiseasesCategoryGrouped();
  const { isAuthenticated } = useAccountController();

  if (category !== 'diseases' && !isAuthenticated) {
    return <Redirect to={'/az/diseases/all'} />;
  }

  if (!data || !groupedData) {
    return <div>Error...</div>;
  }

  if (
    (category === 'diseases' && data && data.total === 0) ||
    (category !== 'diseases' &&
      groupedData[category as DiseaseType] &&
      groupedData[category as DiseaseType].total === 0)
  ) {
    return <Loader />;
  }

  const exploreUrlPrefix = match.url.replace('az', 'explore').split('/').slice(0, -1).join('/');
  const compareSection = getComparisonListsSection(match.url.split('/')[2] || '');

  return (
    <>
      <Helmet>
        <title>A-Z {title} - GIDEON</title>
      </Helmet>
      {exact ? (
        <>
          <AZDiseasesButtonsMobile
            basePath={match.url}
            allTotal={data.total}
            algalTotal={groupedData.algal.total}
            bacterialTotal={groupedData.bacterial.total}
            fungalTotal={groupedData.fungal.total}
            parasiticTotal={groupedData.parasitic.total}
            prionTotal={groupedData.prion.total}
            protoctistalTotal={groupedData.protoctistal.total}
            viralTotal={groupedData.viral.total}
            unknownTotal={groupedData['unknown-agent'].total}
          />
          <AZDownloadButtons category={'diseases'} />
        </>
      ) : (
        <Switch>
          <Route
            exact
            path={match.url}
            render={() => (
              <>
                <ResultsCountParagraph size="big" weight="500">
                  GIDEON database contains{' '}
                  <strong>
                    {category === 'diseases'
                      ? formatNullableNumber(data.total)
                      : formatNullableNumber(groupedData[category as DiseaseType].total)}{' '}
                    {category !== 'diseases' && diseaseNames[category as DiseaseType]}
                  </strong>{' '}
                  diseases.
                </ResultsCountParagraph>
                <AZMobileResultsList
                  data={
                    category === 'diseases' ? data.data : groupedData[category as DiseaseType].data
                  }
                  match={match}
                />
                <AZDownloadButtons category={'diseases'} />
              </>
            )}
          />
          {alphabet.map(letter => (
            <Route
              key={letter}
              path={`${match.url}/${letter}`}
              render={() => (
                <>
                  <AZListSection
                    letter={letter}
                    data={
                      category === 'diseases'
                        ? data && data.data[letter]
                        : groupedData[category as DiseaseType].data[letter] &&
                          groupedData[category as DiseaseType].data[letter]
                    }
                    mobile
                    exploreUrlPrefix={exploreUrlPrefix}
                    compareSection={compareSection}
                  />
                  <AZDownloadButtons category={'diseases'} />
                </>
              )}
            />
          ))}
        </Switch>
      )}
    </>
  );
};
