import { ReactComponent as ArrowRight } from 'assets/UI/ArrowRight.svg';
import { Icon } from 'Atoms/Icon';
import React, { FC } from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { keyframes } from 'styled-components/macro';

const moveFrame = keyframes`
  0% {
    transform: translateX(8px);
    opacity: 0.6;
  }

  100% {
    transform: translateX(0px);
    opacity: 0.9;
  }
`;

const ArrowLeft = styled(ArrowRight)`
  transform: rotate(-180deg);
`;

const ArrowWrapper = styled.div`
  position: sticky;
  top: 50%;
  visibility: hidden;
  width: 30px;
  height: 30px;
  animation: ${moveFrame} 0.6s infinite alternate ease-in-out;
`;

const Arrow = styled(Icon)`
  fill: ${props => props.theme.colors.table.compareTable.shadowArrow};
`;

interface Props {
  className?: string;
  right?: boolean;
  onClick?: () => void;
  leftOffset?: number | 'unset';
  rightOffset?: number | 'unset';
  bottomOffset?: number | 'unset';
  opacity?: number;
}

const ShadowButtonBase: FC<Props> = ({
  className,
  onClick,
  right,
  rightOffset = 'unset',
  bottomOffset = 'unset',
  opacity = 1,
}) => {
  const { animatedOpacity } = useSpring({
    animatedOpacity: opacity,
    config: {
      tension: 280,
      friction: 30,
    },
  });

  return (
    <animated.div
      className={className}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onClick}
      style={{
        display: opacity === 0 && animatedOpacity.get() === 0 ? 'none' : 'block',
        right: rightOffset,
        bottom: bottomOffset,
        opacity: animatedOpacity,
      }}
    >
      <ArrowWrapper>
        <Arrow svgComponent={right ? ArrowRight : ArrowLeft} />
      </ArrowWrapper>
    </animated.div>
  );
};

export const ShadowButton = styled(ShadowButtonBase)`
  position: absolute;
  z-index: 4;
  border-radius: 5px;
  height: auto;
  top: 0;
  bottom: 0;
  ${props => (props.right ? 'right: 0' : 'left: 0')};
  width: 40px;
  cursor: pointer;
  background: ${props =>
    props.right
      ? props.theme.colors.table.compareTable.shadowRight
      : props.theme.colors.table.compareTable.shadowLeft};
  &:hover {
    ${Arrow} {
      visibility: visible;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 33px;
    ${ArrowWrapper} {
      width: 25px;
      height: 25px;
    }
  }
`;
