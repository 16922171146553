import { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useState } from 'store/ReferenceListStore/hooks';
import { ReferenceIdentifier } from 'types/reference';

export const useReference = (
  referenceIdentifier: ReferenceIdentifier
): { index: number; openReference: () => void } => {
  const state = useState();
  const dispatch = useDispatch();
  const index = state.index[referenceIdentifier];
  const location = useLocation();
  
  useLayoutEffect(()=>{
    dispatch({type:'Reset/ReferenceList'})
  },[location.pathname])

  const openReference = (): void => {
    dispatch({ type: 'ReferenceList/OpenModal', payload: { reference: referenceIdentifier } });
  };

  useEffect(() => {
    if (!index) {
      dispatch({ type: 'ReferenceList/AddReference', payload: { referenceIdentifier } });
    }
  }, [dispatch, index, referenceIdentifier]);

  return {
    index,
    openReference,
  };
};

const getAddString = (storeArr: number[]): string => {
  if (storeArr.length === 0) return '';
  let addStr = '';
  if (storeArr.length === 1) {
    addStr = `${storeArr[0]}`;
  } else if (storeArr.length === 2) {
    addStr = `${storeArr[0]}, ${storeArr[1]}`;
  } else {
    addStr = `${storeArr[0]} - ${storeArr[storeArr.length - 1]}`;
  }
  return addStr;
}

const generateGroupRefLabel = (indexes: number[]): string => {
  if (indexes.length === 0) return '';
  if (indexes.length === 1) return `${indexes[0]}`;

  let res = '';
  let diff = indexes[0];
  let storeArr: number[] = [];
  for (let i = 0; i < indexes.length; i++) {
    if (diff === indexes[i] - i) {
      storeArr.push(indexes[i]);
    } else {
      let addStr = getAddString(storeArr);
      if (res.length !== 0) addStr = ', ' + addStr;
      res += addStr;
      storeArr = [indexes[i]];
    }
    diff = indexes[i] - i;
  }
  if (res.length === 0) {
    res = `${storeArr[0]} - ${storeArr[storeArr.length - 1]}`;
  } else {
    res += ", " + getAddString(storeArr);
  }
  return res;
};

interface GroupReferenceRes {
  refLabel: string;
  indexes: number[];
  openGroupReference: () => void;
}

export const useGroupReference = (
  groupIdentifiers: string
): { data: GroupReferenceRes[] } => {
  const state = useState();
  const dispatch = useDispatch();
  const indexes: number[] = [];

  const splitted = groupIdentifiers.split("|");
  let noExist = false;
  for (const referenceIdentifier of splitted) {
    const index = state.index[referenceIdentifier];
    if (index) {
      indexes.push(index);
    } else {
      noExist = true;
    }
  }

  useEffect(() => {
    if (noExist) {
      dispatch({ type: 'ReferenceList/AddMultiReference', payload: { referenceIdentifiers: splitted } });
    }
  }, [dispatch, splitted, noExist]);

  indexes.sort((a, b) => a - b);

  const data: GroupReferenceRes[] = [];
  const chunkSize = 10;
  for (let i = 0; i < indexes.length; i += chunkSize) {
    const chunk = indexes.slice(i, i + chunkSize);
    let identifiers = "";
    for (const item of chunk) {
      if (identifiers) identifiers += "|";
      identifiers += Object.keys(state.index).find(key => state.index[key] === item);
    }
    const openGroupReference = (): void => {
      dispatch({ type: 'ReferenceList/OpenModal', payload: { reference: identifiers } });
    };
    data.push({
      refLabel: generateGroupRefLabel(chunk),
      indexes: chunk,
      openGroupReference
    })
  }
  return { data };
};

export const useOpenAllReferences = (): {
  openAllReferences: () => void;
  referenceCount: number;
} => {
  const state = useState();
  const dispatch = useDispatch();
  const openAllReferences = (): void => {
    dispatch({ type: 'ReferenceList/OpenModal', payload: { reference: 'all' } });
  };
  return {
    openAllReferences,
    referenceCount: state.list.length,
  };
};

export const useReferenceClose = (): { closeReferences: () => void } => {
  const dispatch = useDispatch();
  const closeReferences = (): void => {
    dispatch({ type: 'ReferenceList/CloseModal' });
  };
  return {
    closeReferences,
  };
};
