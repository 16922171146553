import { H2, P } from 'Atoms/text';
import { CompleteTooltip } from 'Atoms/tooltip/CompleteTooltip';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { ScrollbarMixin } from 'utils/styleMixins';

const Container = styled.div`
  background-color: ${props => props.theme.colors.background.modalCardBody};
  padding: 20px;

  border: 2px solid ${props => props.theme.colors.background.lightPopup};
  border-radius: 4px;

  /* Make scroll inside card */
  height: inherit;
  max-height: inherit;
  overflow: auto;
  ${ScrollbarMixin};
`;

const Title = styled(H2)`
  margin: 0 0 20px 0;
  text-align: center;
`;

const Description = styled(P)`
  margin: 0 0 20px 0;
  text-align: center;
`;

const StyledTooltip = styled(CompleteTooltip)`
  top: 6px;
`;

interface Props {
  className?: string;
  title?: string;
  description?: string;
  children?: ReactNode;
  tooltip?: string;
}

export const SecondaryModalCard: FC<Props> = ({
  className,
  children,
  title,
  description,
  tooltip,
}) => (
  <Container className={className}>
    {title && (
      <Title weight="500" font="Inter">
        {title}
        {tooltip && <StyledTooltip content={tooltip} />}
      </Title>
    )}
    {description && <Description size="big">{description}</Description>}
    {children}
  </Container>
);
