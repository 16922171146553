import { BreadcrumbContainerContentContext } from 'layouts/AppLayout';
import { FC, ReactElement, useCallback, useEffect } from 'react';
import { useContextAssert } from 'services/useContextAssert.hook';
import { useMobile } from 'services/useMobile';

interface Props {
  className?: string;
  children: ReactElement;
  passthrough?: boolean;
}

/**
 * Renders `children` inside `BreadcrumbContainer` only on desktop and when `Breadcrumb` needs to be shown.
 * On mobile or when the `passthrough` is provided always shows content in place.
 * @param className class to be passed to BreadcrumbContainer
 * @param children content to render
 */
export const BreadcrumbContainerContent: FC<Props> = ({ className, children, passthrough }) => {
  const isMobile = useMobile('m');

  const [, setContent] = useContextAssert(BreadcrumbContainerContentContext);

  const clearContent = useCallback((): void => {
    setContent({ content: null });
  }, [setContent]);

  useEffect(() => {
    if (isMobile || passthrough) {
      clearContent();
    } else {
      setContent({ content: children, className });
    }

    return () => clearContent();
  }, [children, className, clearContent, isMobile, passthrough, setContent]);

  return isMobile || passthrough ? children : null;
};
