import { match as Match, matchPath } from 'react-router-dom';

export interface BaseParams {
  [T: string]: string;
}

export interface SlugParams extends BaseParams {
  slug: string;
}

export interface DiseaseInnerParams extends BaseParams {
  diseaseSlug: string;
  countrySlug: string;
}

export function matchPathExtended<T extends BaseParams = BaseParams>(
  routes: string | string[],
  pathname: string
): Match<T> | null {
  return matchPath<T>(pathname, {
    exact: true,
    path: routes,
  });
}
