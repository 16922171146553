import { ErrorPageImage } from 'Atoms/ErrorPageImage';
import { H1, H2 } from 'Atoms/text';
import { ReportButton } from 'Molecules/buttons/ReportButton';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import { Errors } from 'types/errorTypes';

// This is copy from src/pages/Error/index.tsx

const ErrorPageStyled = styled.div`
  height: 100%;
  display: grid;
  flex: 1 0 auto;

  grid-template-columns: 70% 15% 15%;
  grid-template-rows: auto auto 50px;

  padding: 10% 0 0 4%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 10% 0 0 6%;
  }
`;

const GeneralContainer = styled.section`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
`;

const ErrorPageImageStyled = styled(ErrorPageImage)`
  grid-column: 2 / 4;
  grid-row: 1 / 4;
  justify-self: end;
  align-self: end;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    grid-row: 2 / 3;
  }
`;

const TitleStyled = styled(H1)`
  text-align: unset;
  margin: 0 0 40px 0;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 0 0 20px 0;
  }
  @media (min-width: ${props => props.theme.breakpoints.s}) and (max-width: ${props =>
      props.theme.breakpoints.m}) {
    margin: 0 0 30px 0;
  }
`;

const SubTitleStyled = styled(H2)`
  text-align: left;
  margin: 0;
`;

const ReportContainer = styled.div`
  margin-top: 40px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 27px;
  }
`;

interface Props {
  error?: Errors;
}

export const NetworkError: FC<Props> = () => {
  return (
    <ErrorPageStyled>
      <Helmet>
        <title>Network Error - GIDEON</title>
      </Helmet>
      <GeneralContainer>
        <TitleStyled weight="600" font="Inter" size="veryBig" color="errorTitle" lineHeight="unset">
          Please check you are connected to the Internet and try again
        </TitleStyled>
        <SubTitleStyled weight="600" size="medium" color="navbar" font="Inter">
          Try again later.
        </SubTitleStyled>
        <ReportContainer>
          <ReportButton onClick={() => location.reload()}>Try again</ReportButton>
        </ReportContainer>
      </GeneralContainer>
      <ErrorPageImageStyled />
    </ErrorPageStyled>
  );
};
