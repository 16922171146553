import { ReactNode } from 'react';
import { ActionWithPayload } from 'store/types';

type StringField =
  | 'firstName'
  | 'lastName'
  | 'position'
  | 'institution'
  | 'email'
  | 'howHear';

type FieldName = StringField;

type ErrorFieldName = FieldName | 'form';

export type SetValueAction<T extends FieldName> = { field: T; value: State[T] };

export type Action =
  | ActionWithPayload<
      'SignUp/Set/Value',
      SetValueAction<StringField>
    >
  | ActionWithPayload<'SignUp/Set/Errors', State['errors']>
  | ActionWithPayload<'SignUp/Set/Error', { field: ErrorFieldName; value: ReactNode | null }>;

export type State = Record<StringField, string> &
  {
    errors: Record<ErrorFieldName, ReactNode | null>;
  };

export const initialState: State = {
  firstName: '',
  lastName: '',
  position: '',
  institution: '',
  email: '',
  howHear: '',
  errors: {
    firstName: null,
    lastName: null,
    position: null,
    email: null,
    institution: null,
    howHear: null,
    form: null
  },
};
