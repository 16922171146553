import { Icon, SvgComponent } from 'Atoms/Icon';
import { SecondaryNavbarLink } from 'Atoms/links/NavLinks';
import React, { forwardRef, KeyboardEventHandler } from 'react';
import styled from 'styled-components/macro';

const PositionedIcon = styled(Icon)`
  margin-right: 15px;
  width: 23px;
  height: 23px;
`;

interface Props {
  className?: string;
  icon: SvgComponent;
  title: string;
  to: string;
  onClick?: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLAnchorElement>;
}

export const SecondaryNavLink = forwardRef<HTMLAnchorElement, Props>(
  ({ className, icon, title, to, onClick, onKeyDown }, ref) => (
    <SecondaryNavbarLink
      className={className}
      to={to}
      onClick={onClick}
      ref={ref}
      onKeyDown={onKeyDown}
    >
      <PositionedIcon svgComponent={icon} />
      {title}
    </SecondaryNavbarLink>
  )
);
