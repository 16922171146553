import { storeFactory } from 'store/storeFactory';
import { Action as GenericAction, ActionWithPayload } from 'store/types';
import { ReferenceIdentifier } from 'types/reference';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'ReferenceList/AddReference', { referenceIdentifier: ReferenceIdentifier }>
  | ActionWithPayload<
      'ReferenceList/AddMultiReference',
      { referenceIdentifiers: ReferenceIdentifier[] }
    >
  | ActionWithPayload<'ReferenceList/OpenModal', { reference: ReferenceIdentifier | 'all' }>
  | GenericAction<'Reset/ReferenceList'>
  | GenericAction<'ReferenceList/CloseModal'>;

export interface References {
  index: {
    [id: string]: number;
  };
  list: ReferenceIdentifier[];
  referenceModalIds: ReferenceIdentifier | 'all';
  showReferenceModal: boolean;
}

export type State = References;

const initialState: State = {
  index: {},
  list: [],
  referenceModalIds: 'all',
  showReferenceModal: false,
};

export const {
  dispatchContext: ReferenceListDispatchContext,
  stateContext: ReferenceListStateContext,
  provider: ReferenceListStoreProvider,
} = storeFactory(reducer, initialState);
