import { ReactComponent as GraphSVG } from 'assets/UI/Graph.svg';
import { ReactComponent as TableSVG } from 'assets/UI/Table.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import React, { Dispatch, FC, SetStateAction } from 'react';
import styled from 'styled-components/macro';

const StyledButton = styled(FilledButton)`
  max-width: 130px;
  padding: 10px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: unset;
    min-width: 120px;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: 10px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-right: 5px;
  }
`;

interface Props {
  className?: string;
  isGraphSelected: boolean;
  setIsGraphSelected?: Dispatch<SetStateAction<boolean>>;
}

export const GraphAndTableButton: FC<Props> = ({
  className,
  isGraphSelected,
  setIsGraphSelected,
}) => {
  if (!setIsGraphSelected) {
    return null;
  }

  return (
    <StyledButton size="small" onClick={() => setIsGraphSelected(o => !o)} className={className}>
      {isGraphSelected ? (
        <>
          <StyledIcon svgComponent={TableSVG} size="small" />
          Data table
        </>
      ) : (
        <>
          <StyledIcon svgComponent={GraphSVG} size="small" />
          Graph
        </>
      )}
    </StyledButton>
  );
};
