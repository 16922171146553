import { getDrugsSynonymsPublic } from 'apiServices/Drugs/drugs';
import { ReactComponent as PillIcon } from 'assets/Button/Pill.svg';
import { ReactComponent as ToxicityIcon } from 'assets/Button/ToxicityIcon.svg';
import { ExploreLayout } from 'layouts/ExploreLayout';
import { SearchByTypeButton } from 'Molecules/buttons/SearchByTypeButton';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { LoginModalContext } from 'services/loginModal.service';
import { useContextAssert } from 'services/useContextAssert.hook';
import { useMobile } from 'services/useMobile';
import { useDrugsList } from 'store/drugsStore/hooks';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';
import { mapToSearchSelectOption } from 'utils/mapResource';

const ButtonStyled = styled(SearchByTypeButton)`
  max-height: 44px;
  margin: 10px 10px 0;
  padding: 10px;

  opacity: 0.5;
`;

export const DrugsPublic: FC = () => {
  const isMobile = useMobile('s');
  const drugsList = useDrugsList();

  const drugOptions = mapToSearchSelectOption(drugsList);

  const { setOpen } = useContextAssert(LoginModalContext);

  const loadSynonymOptions = async (inputValue: string): Promise<SelectOption[]> => {
    const synonyms = await getDrugsSynonymsPublic(inputValue);

    return synonyms.map(x => ({
      label: x.name,
      value: x.id.toString(),
      synonymOf: drugsList.find(drug => drug.id === x.id)?.name,
    }));
  };

  return (
    <>
      <Helmet>
        <title>Explore drugs - GIDEON</title>
        <meta
          name="description"
          content="A wealth of information on 29,000+ trade names of anti-infective drugs. Includes tools to find drugs by toxicity, interaction, drug class, or spectrum."
        />
      </Helmet>
      <ExploreLayout
        title="Explore GIDEON data on drugs"
        imageName="drugs"
        placeholder={isMobile ? 'Enter generic | trade name' : 'Start typing generic or trade name'}
        section="drugs"
        defaultOptions={drugOptions}
        loadSynonymOptions={loadSynonymOptions}
        optionTitle="Search by..."
        selectLabel="Filter and search across all drug records"
      >
        <ButtonStyled Icon={ToxicityIcon} onClick={() => setOpen(true)}>
          Toxicity
        </ButtonStyled>
        <ButtonStyled Icon={PillIcon} onClick={() => setOpen(true)}>
          {isMobile ? 'Drug class | Spectrum' : 'Drug class or Spectrum'}
        </ButtonStyled>
        <ButtonStyled Icon={PillIcon} onClick={() => setOpen(true)}>
          Interaction
        </ButtonStyled>
      </ExploreLayout>
    </>
  );
};
