import { RedirectWithState } from 'Atoms/routes/RedirectWithState';
import { MainLayout } from 'layouts/MainLayout';
import { AZPageTitle } from 'Molecules/az/AZPageTitle';
import { AZTab } from 'Molecules/az/AZTab';
import { Tabs } from 'Molecules/Tabs';
import React, { FC } from 'react';
import { match, Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useSections } from './AZPageCategories';

const Wrapper = styled.div`
  width: 92%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    width: 96%;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

interface Props {
  match: match;
}

export const DesktopAZ: FC<Props> = ({ match }) => {
  const sections = useSections();

  return (
    <MainLayout>
      <Wrapper>
        <AZPageTitle />
        <Wrapper>
          <Tabs sections={sections} path={match.url} Tab={AZTab} />
          <Switch>
            <RedirectWithState exact from={match.url} to={`${match.url}/diseases`} />
            {sections.map(section => (
              <Route
                path={`${match.url}/${section.slug}`}
                key={section.slug}
                render={({ match }) => <section.Component match={match} name={section.name} />}
              />
            ))}
            <Redirect to="/404" />
          </Switch>
        </Wrapper>
      </Wrapper>
    </MainLayout>
  );
};
