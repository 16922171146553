import { ButtonWithBackground, FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { LoginInput } from 'Atoms/input/LoginInput';
import { ExternalLink } from 'Atoms/links/ExternalLink';
import { Link } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { getInstitutionList } from 'Auth/apiServices/account';
import { FormMessage } from 'Auth/Molecules/FormMessage';
import { ReCaptchaNoticeText } from 'Auth/Molecules/ReCaptchaNoticeText';
import { AccountModal } from 'Molecules/modal/AccountModal';
import { AsyncSelect } from 'Molecules/select/AsyncSelect';
import React, { FC, FormEvent, RefObject, useContext, useState } from 'react';
import { ErrorFields } from 'store/accountStore/hooks';
import styled, { ThemeContext } from 'styled-components/macro';
import { InstitutionSelectOption } from 'types/institution';

const Container = styled.div`
  width: 100%;
  max-width: 1500px;
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100%;

  @supports not (-ms-ime-align: auto) {
    justify-content: space-evenly;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const LeftColumn = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-right: 0;
  }
`;

const LeftLabel = styled.label`
  display: flex;
  font: ${props => props.theme.fonts.bigTextSemiBold};
  align-self: flex-start;
`;
const RightLabel = styled.label`
  display: flex;
  font: ${props => props.theme.fonts.bigTextSemiBold};
  align-self: flex-start;
  margin-left: 12px;
`;

const HorizontalText = styled.p`
  font: ${props => props.theme.fonts.bigTextSemiBold};
`;

const LabelPassword = styled.label`
  display: flex;
  align-items: center;
  font: ${props => props.theme.fonts.bigTextSemiBold};
  align-self: flex-start;

  margin: 10px 0 0 0;
`;

const StyledIcon = styled(Icon)`
  width: 25px;
  height: 25px;
  margin-right: 15px;
`;

const IndividualForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 300px;
  &:nth-child(2) {
    margin-top: 30px;
  }
`;

const StyledInput = styled(LoginInput)`
  margin: 10px 0 0 0;
`;

const FormButton = styled(FilledButton)`
  width: 40%;
  max-width: 200px;
  padding: 10px 15px;
  margin-top: 18px;
`;
interface MagicButtonProps {
  isEmailSent: boolean;
}
const FormMagicButton = styled(ButtonWithBackground)<MagicButtonProps>`
  width: ${props => !props.isEmailSent && '40%'};
  background-color: ${props => (props.isEmailSent ? '#089E20' : 'default')};
  max-width: 254px;
  padding: 10px 15px;
  margin-top: 10px;

  & > span {
    font-size: ${props => props.isEmailSent && '18px'} !important;
    color: ${props => props.isEmailSent && '#FFFFFF'} !important;
  }
`;

const Links = styled.nav`
  margin-top: 40px;
  width: 100%;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const LinkStyled = styled(Link)`
  color: ${props => props.theme.colors.text.main};
  font: ${props => props.theme.fonts.bigText};
`;

const RightColumn = styled.div`
  width: 100%;
  max-width: 400px;
  margin-left: 15px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 100px 0 0 0;
  }
`;

const InstitutionForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  &:nth-child(2) {
    margin-top: 40px;
  }
`;
const InstitutionFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const StyledSelect = styled(AsyncSelect<InstitutionSelectOption, false>())`
  margin-top: 10px;

  .${props => props.classNamePrefix}__control {
    &--is-focused,
    &--menu-is-open {
      .${props => props.classNamePrefix}__placeholder {
        display: block;
      }
    }
  }

  .${props => props.classNamePrefix}__dropdown-indicator {
    display: ${props => !props.showDropdown && 'none'};
  }
`;

const FormErrorMessage = styled(FormMessage)`
  margin-bottom: 10px;
`;

const StyleHorizontal = styled.div`
  width: 95%;
  display: flex;
  margin: auto;
  align-items: center;
  text-align: center;
  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 2px solid;
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }
`;

interface Props {
  className?: string;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onMagicSubmit?: (e: FormEvent<HTMLFormElement>) => void;
  errorMessage: ErrorFields;
  onUsernameChange: (value: string) => void;
  onMagicUsernameChange: (value: string) => void;
  onPasswordChange: (value: string) => void;
  username: string;
  magicUsername: string;
  password: string;
  isFormLoading?: boolean;
  isPageLoading?: boolean;
  usernameInput?: RefObject<HTMLInputElement>;
  magicUsernameInput?: RefObject<HTMLInputElement>;
  passwordInput?: RefObject<HTMLInputElement>;
  setErrorMessage: (value: React.SetStateAction<ErrorFields>) => void;
}

export const SignInForm: FC<Props> = ({
  className,
  onSubmit,
  onMagicSubmit,
  errorMessage,
  onUsernameChange,
  magicUsernameInput,
  magicUsername,
  onPasswordChange,
  onMagicUsernameChange,
  username,
  password,
  isFormLoading,
  isPageLoading,
  usernameInput,
  passwordInput,
  setErrorMessage,
}) => {
  const [institution, setInstitution] = useState<InstitutionSelectOption | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const theme = useContext(ThemeContext);
  const onInstitutionChange = (value: InstitutionSelectOption | null): void => {
    setInstitution(value);
  };

  const loadInstitutions = (
    value: string,
    callback: (values: InstitutionSelectOption[]) => void
  ): void => {
    getInstitutionList(value).then(list => {
      const selectOptions: InstitutionSelectOption[] = list.map(l => ({
        label: l.name,
        url: l.url,
        value: l.name,
        userGuide: l.userGuide,
        email: l.contactEmail,
      }));

      callback(selectOptions);
    });
  };

  const onClickInstitutionSignIn = (): void => {
    if (!institution) {
      return;
    }

    if (institution.url) {
      window.location.href = institution.url;
    } else if (institution.userGuide) {
      setIsOpen(true);
    }
  };

  return (
    <Container className={className}>
      {isPageLoading ? (
        <Loader />
      ) : (
        <>
          <LeftColumn>
            <IndividualForm onSubmit={onSubmit} aria-label="Individual user form">
              <LeftLabel htmlFor="username">
                <StyledIcon svgComponent={theme.images.user} />
                Username
              </LeftLabel>
              <StyledInput
                id="username"
                type="text"
                name="email"
                placeholder="Username"
                value={username}
                onChange={e => onUsernameChange(e.target.value.trim())}
                dataCy="email"
                error={!!errorMessage.username || !!errorMessage.form}
                autoComplete="email"
                inputRef={usernameInput}
                aria-describedby="sign-in-username-error sign-in-form-error"
                aria-invalid={!!errorMessage.username || !!errorMessage.form}
              />
              <FormMessage
                id="sign-in-username-error"
                type="error"
                message={errorMessage.username}
              />
              <LabelPassword htmlFor="password">
                <StyledIcon svgComponent={theme.images.password} />
                Password
              </LabelPassword>
              <StyledInput
                id="password"
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={e => onPasswordChange(e.target.value.trim())}
                dataCy="password"
                error={!!errorMessage.password || !!errorMessage.form}
                autoComplete="current-password"
                inputRef={passwordInput}
                aria-describedby="sign-in-password-error sign-in-form-error"
                aria-invalid={!!errorMessage.password || !!errorMessage.form}
              />
              <FormMessage
                id="sign-in-password-error"
                type="error"
                message={errorMessage.password}
              />
              <ReCaptchaNoticeText color="main" />
              <FormErrorMessage id="sign-in-form-error" type="error" message={errorMessage.form} />
              <FormButton
                type="submit"
                data-cy={'submit-crediential-login'}
                size="big"
                weight="500"
                disabled={isFormLoading}
              >
                Sign In
              </FormButton>
            </IndividualForm>
            <Links aria-label="Account managment links">
              <LinkWrapper>
                <StyledIcon svgComponent={theme.images.lock} />
                <LinkStyled to="/password" color="main" size="big">
                  Forgot password?
                </LinkStyled>
              </LinkWrapper>
              <LinkWrapper>
                <StyledIcon svgComponent={theme.images.account} />
                <LinkStyled to="/signup">No account? No problem!</LinkStyled>
              </LinkWrapper>
              <LinkWrapper>
                <StyledIcon svgComponent={theme.images.reading} />
                <ExternalLink
                  to="https://www.gideononline.com/quoteform/"
                  openInNewTab
                  color="main"
                  size="big"
                >
                  Need GIDEON for your library?
                </ExternalLink>
              </LinkWrapper>
            </Links>
          </LeftColumn>
          <RightColumn>
            <RightLabel htmlFor="institution-select">
              <StyledIcon svgComponent={theme.images.institution} />
              Institutional access options
            </RightLabel>
            <InstitutionFormContainer>
              <InstitutionForm aria-label="Institutional user form">
                <RightLabel htmlFor="institution-select">Find your institution by name</RightLabel>
                <StyledSelect
                  classNamePrefix="institution-select"
                  placeholder="Start typing your institution name"
                  showIcon
                  openMenuOnClick={false}
                  isClearable
                  inputId="institution-select"
                  loadOptions={loadInstitutions}
                  onChange={onInstitutionChange}
                />
                <FormButton
                  type="button"
                  size="big"
                  weight="500"
                  onClick={onClickInstitutionSignIn}
                >
                  Sign In
                </FormButton>
              </InstitutionForm>
              <StyleHorizontal>
                <HorizontalText>OR</HorizontalText>
              </StyleHorizontal>
              <IndividualForm onSubmit={onMagicSubmit} aria-label="Individual user form">
                <RightLabel htmlFor="magic-link">Receive a magic link to your email</RightLabel>
                <StyledInput
                  id="magicUsername"
                  type="text"
                  name="email"
                  placeholder="Type your email address"
                  value={magicUsername}
                  onChange={e => onMagicUsernameChange(e.target.value.trim())}
                  dataCy="magic-link"
                  error={!!errorMessage.username || !!errorMessage.form}
                  autoComplete="email"
                  inputRef={magicUsernameInput}
                  aria-describedby="sign-in-username-error sign-in-form-error"
                  aria-invalid={!!errorMessage.magicUsername || !!errorMessage.magicForm}
                />
                <FormErrorMessage
                  id="magic-link-form-error"
                  type="error"
                  message={errorMessage.magicForm}
                />
                <FormMagicButton
                  type="submit"
                  isEmailSent={errorMessage.magicUsername ? true : false}
                  size="big"
                  weight={errorMessage.magicUsername ? '700' : '500'}
                  disabled={errorMessage.magicUsername ? true : false}
                >
                  {errorMessage.magicUsername ? errorMessage.magicUsername : 'Send link'}
                </FormMagicButton>
              </IndividualForm>
            </InstitutionFormContainer>
          </RightColumn>
        </>
      )}
      <AccountModal isOpen={isOpen} onClose={() => setIsOpen(false)} title="Action required">
        {institution?.userGuide}. {institution?.email}
      </AccountModal>
    </Container>
  );
};
