import { DynamicContent, Node } from 'types/dynamicContent';

const renderChildren = (children: Node[]): string => {
  return children.map(child => getString(child)).join(' ');
};

const getString = (node: Node): string | null => {
  switch (node.type) {
    case 'text': {
      return node.value;
    }
    case 'Link':
    case 'i':
    case 'b':
    case 'u':
    case 'li':
    case 'ul': {
      return renderChildren(node.children);
    }
    default: {
      return '';
    }
  }
};

export const getStringFromDynamicHtml = (content: DynamicContent): string =>
  renderChildren(content);
