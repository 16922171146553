import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import { ClinicalFindingsSection } from 'Molecules/section/ClinicalFindingsSection';
import { DiseaseSummarySection } from 'Molecules/section/DiseaseSummarySection';
import { DistributionSection } from 'Molecules/section/DistributionSection';
import { getStringFromDynamicHtml } from 'Organisms/dynamicContent/DynamicHtmlToString';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import { Paywall } from 'Organisms/paywall/Paywall';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { Element } from 'react-scroll';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { useDiseaseCountriesPublic, useDiseaseDetailsPublic } from 'store/diseaseDetailStore/hooks';
import { isLoading } from 'store/types';
import { isRecordLocationState } from 'types/record';

type Props = RouteComponentProps & {
  diseaseId: number;
};

export const DiseasePublic: FC<Props> = ({ diseaseId, location }) => {
  const [showSummary, setShowSummary] = useState(true);
  const [showFindings, setShowFindings] = useState(true);

  const disease = useDiseaseDetailsPublic(diseaseId);
  const countries = useDiseaseCountriesPublic(diseaseId);

  const synonymName = isRecordLocationState(location.state)
    ? location.state.synonymName
    : undefined;

  if (isLoading(disease) || isLoading(countries)) {
    return <Loader />;
  }

  const getDescription = (): string =>
    `GIDEON - ${disease.disease}. Agent: ${getStringFromDynamicHtml(disease.agentText)}`;

  const sections: RecordLink[] = [
    'Summary',
    'Images',
    'Clinical findings',
    'Downloads and references',
  ].map(section => ({
    label: section,
    'aria-label': `${disease.disease} ${section.toLowerCase()}`,
  }));

  return (
    <ReferenceProvider>
      <Helmet>
        <title>{disease.disease} - GIDEON</title>
        <meta name="description" content={getDescription()} />
      </Helmet>
      <ReferenceLabelContext.Provider value={disease.disease}>
        <MainLayout>
          <RecordLayout>
            <RecordTitle title={disease.disease} synonymName={synonymName} />
            <RecordLinks links={sections} />
            <DistributionSection
              code={disease.code}
              countries={countries}
              isBioterrorismButtonDisabled
              isOutbreakButtonDisabled
              isSelectDisabled
            />
            <RecordSection
              link={sections[0]}
              show={showSummary}
              onCollapse={() => setShowSummary(!showSummary)}
              accent="first"
              id="disease-public-summary"
            >
              <DiseaseSummarySection details={disease} />
            </RecordSection>
            <RecordSection
              link={sections[1]}
              show={showFindings}
              onCollapse={() => setShowFindings(!showFindings)}
              accent="second"
              id="disease-public-clinical"
            >
              <ClinicalFindingsSection details={disease} />
            </RecordSection>
            <Element name="Clinical findings">
              <Element name="Downloads and references">
                <Paywall />
              </Element>
            </Element>
          </RecordLayout>
        </MainLayout>
      </ReferenceLabelContext.Provider>
    </ReferenceProvider>
  );
};
