import { useCallback, useEffect } from 'react';

export const useClickOutside = (ref: HTMLElement | null | undefined, cb: () => void): void => {
  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent): void => {
      if (!ref || ref.contains(event.target as Node)) {
        return;
      }

      cb();
    },
    [cb, ref]
  );

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);
};
