import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

const Layout = styled.div`
  width: 87%;
  padding-right: 60px;
  margin: 0 auto 100px auto;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-bottom: 100px;
    width: 95%;
    padding-right: 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    padding: 0 5px;
  }
`;

interface Props {
  children: ReactNode;
  className?: string;
}

export const RecordLayout: FC<Props> = ({ children, className }) => (
  <Layout className={className}>{children}</Layout>
);
