import { Placement as PopperPlacement } from '@popperjs/core';
import { ReactComponent as QuestionSVG } from 'assets/UI/QuestionMark.svg';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon } from 'Atoms/Icon';
import React, { FC, ReactNode } from 'react';
import { useTooltip } from 'services/useTooltip';
import styled from 'styled-components/macro';

const TooltipContainer = styled.span`
  position: absolute;
  width: 20px;
  height: 20px;
`;

const ExplanationButton = styled(InvisibleButton)`
  cursor: pointer;
  outline: none;

  &:focus {
    border: none;
    background: none;
    color: ${props => props.theme.colors.button.hover.textColor};
    ${Icon} {
      fill: ${props => props.theme.colors.button.hover.textColor};
    }
  }
`;

type Props = {
  className?: string;
  content: ReactNode;
  position?: PopperPlacement;
  onClickOnly?: boolean;
};

export const CompleteTooltip: FC<Props> = ({ className, content, position }) => {
  const [props, popper, setElementReference] = useTooltip(content, {
    position,
  });

  return (
    <TooltipContainer {...props.events} className={className}>
      <ExplanationButton {...props.aria} ref={setElementReference} aria-label="More information">
        <Icon svgComponent={QuestionSVG} fill="mainBold" />
      </ExplanationButton>
      {popper}
    </TooltipContainer>
  );
};
