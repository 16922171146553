import { isNotNull } from 'apiServices/common/guards';
import {
  SearchDTO,
  SearchFacetBucketDTO,
  SearchFacetsDTO,
  SearchHitDTO,
  SearchHitFieldsDTO,
} from 'apiServices/Search/DTO';
import { parseSearchResultDynamicContent } from 'services/parser/dynamicContentParser';
import {
  isSearchModuleName,
  ModuleSearchFacet,
  Search,
  SearchFacets,
  SearchHit,
  SearchResultFields,
} from 'types/search';

const mapFieldsDTOtoFields = (x: SearchHitFieldsDTO): SearchResultFields => ({
  recordType: x.record_type[0],
  description: x.description && x.description[0],
  title: parseSearchResultDynamicContent(x.title[0]),
});

const mapHitDTOtoHit = (x: SearchHitDTO): SearchHit => ({
  highlight: {
    title: parseSearchResultDynamicContent(x.highlights.title),
    description: parseSearchResultDynamicContent(x.highlights.description),
  },
  fields: mapFieldsDTOtoFields(x.fields),
  id: x.id,
});

const mapModuleFacetToModule = (x: SearchFacetBucketDTO[]): ModuleSearchFacet[] =>
  x
    .map(bucket =>
      isSearchModuleName(bucket.value)
        ? {
            value: bucket.value,
            count: bucket.count,
          }
        : null
    )
    .filter(isNotNull);

const mapFacetsDTOtoFacets = (x: SearchFacetsDTO): SearchFacets => ({
  disease: x.disease.buckets,
  bacteria: x.bacteria.buckets,
  country: x.country.buckets,
  drug: x.drug.buckets,
  module: mapModuleFacetToModule(x.module.buckets),
  mycobacteria: x.mycobacteria.buckets,
  vaccine: x.vaccine.buckets,
  yeasts: x.yeasts.buckets,
  recordType: x.record_type.buckets,
});

export const mapSearchDTOtoSearchResults = (x: SearchDTO): Search => ({
  hits: x.data.hits.map(mapHitDTOtoHit),
  facets: mapFacetsDTOtoFacets(x.data.facets),
  total: x.total,
  offset: x.offset,
  limit: x.limit,
});
