import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useGetCountryList } from 'store/countriesStore/hooks';
import { useGetDiseaseList } from 'store/diseasesStore/hooks';
import { useGetDrugList } from 'store/drugsStore/hooks';
import { useGetMicrobeList } from 'store/microbesStore/hooks';
import { useGetVaccineList } from 'store/vaccinesStore/hooks';
import { VirusType } from 'types/microbeDetails';
import { getOriginalCountryCode } from 'utils/customCountryCodes';

export const getSlug = (label: string): string => {
  const cleanLabel = label
    .replace(/[',.():+]/g, '')
    .replace(/[/-\s]+/g, '-')
    .toLowerCase();

  return cleanLabel.split(' ').join('-');
};

export type EntityType =
  | 'diseases'
  | 'drugs'
  | 'vaccines'
  | 'bacteria'
  | 'mycobacteria'
  | 'yeasts'
  | 'countries';

export const slugIdExtractor = (path: string): string => {
  const splitedPath = path.split('-');
  const lastSection = splitedPath[splitedPath.length - 1];

  return getOriginalCountryCode(lastSection);
};

interface CleanSlug {
  slug: string;
  id: string;
}

export const useCleanSlug = (type: EntityType | VirusType, oldSlug: string): CleanSlug => {
  const getDiseaseList = useGetDiseaseList();
  const getDrugList = useGetDrugList();
  const getVaccineList = useGetVaccineList();
  const getCountryList = useGetCountryList();
  const getMicrobeList = useGetMicrobeList();

  const [slug, setSlug] = useState<string | null>(null);

  const value = slugIdExtractor(oldSlug);

  const getSlugFromEntity = useCallback(
    (value: string): string | null => {
      switch (type) {
        case 'diseases': {
          const diseases = getDiseaseList();
          const disease = diseases.find(d => d.id.toString() === value);

          return disease ? disease.slug : null;
        }
        case 'drugs': {
          const drugs = getDrugList();
          const drug = drugs.find(d => d.id.toString() === value);

          return drug ? drug.slug : null;
        }
        case 'vaccines': {
          const vaccines = getVaccineList();
          const vaccine = vaccines.find(d => d.id.toString() === value);

          return vaccine ? vaccine.slug : null;
        }
        case 'countries': {
          const countries = getCountryList();
          const country = countries.find(d => d.id.toString() === value);

          return country ? country.slug : null;
        }
        case 'bacteria': {
          const microbes = getMicrobeList();
          const bacteria = microbes.bacteria.find(d => d.id.toString() === value);

          return bacteria ? bacteria.slug : null;
        }
        case 'mycobacteria': {
          const microbes = getMicrobeList();
          const mycobacteria = microbes.mycobacteria.find(d => d.id.toString() === value);

          return mycobacteria ? mycobacteria.slug : null;
        }
        case 'yeasts': {
          const microbes = getMicrobeList();
          const yeast = microbes.yeasts.find(d => d.id.toString() === value);

          return yeast ? yeast.slug : null;
        }
        case 'viruses': {
          const microbes = getMicrobeList();
          const virus = microbes.viruses.find(d => d.id.toString() === value);

          return virus ? virus.slug : null;
        }
      }
    },
    [getCountryList, getDiseaseList, getDrugList, getMicrobeList, getVaccineList, type]
  );

  useEffect(() => {
    setSlug(getSlugFromEntity(value));
  }, [getSlugFromEntity, value]);

  return {
    slug: slug ? `${slug}-${value}` : oldSlug,
    id: value,
  };
};
