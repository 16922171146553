import { Loader } from 'Atoms/Loader';
import { MapMainLayout } from 'layouts/MapMainLayout';
import { DiseaseOutbreaksMap } from 'Organisms/outbreaksMap/index';
import { NotFound } from 'pages/404';
import React, { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useDiseaseDetails } from 'store/diseaseDetailStore/hooks';
import { useDiseaseDistribution } from 'store/diseaseDistributionStore/hooks';
import { useDiseaseOutbreaks } from 'store/diseaseOutbreaksStore/hooks';
import { assertIsNotStoreError, isStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import { Dictionary } from 'types/common';

interface Props {
  diseaseId: number;
}

export const Outbreaks: FC<Props> = ({ diseaseId }) => {
  if (!diseaseId || Number.isNaN(diseaseId)) {
    return <NotFound />;
  }

  return (
    <MapMainLayout>
      <OutbreaksMap id={diseaseId} />
    </MapMainLayout>
  );
};

export const OutbreaksMap: FC<{ id: number }> = ({ id }) => {
  const disease = useDiseaseDetails(id);
  const distribution = useDiseaseDistribution(id);
  const outbreaks = useDiseaseOutbreaks(id);

  const { search } = useLocation();

  const year = useMemo(() => {
    const query = new URLSearchParams(search);

    return query.get('year');
  }, [search]);

  assertIsNotStoreError(distribution);

  const [endemicLabels, countryEndemics] = useMemo(() => {
    if (!isStoreError(distribution) && !isLoading(distribution)) {
      const countryEndemics = distribution.values.reduce(
        (acc, c) =>
          c.labelName
            ? {
                ...acc,
                [c.countryId]: c.labelName,
              }
            : acc,
        {} as Dictionary<string>
      );

      const endemicLabels = distribution.legend.map(l => l.legend);

      return [endemicLabels, countryEndemics] as const;
    } else {
      return [[], {}] as [string[], Dictionary<string>];
    }
  }, [distribution]);

  const diseaseName =
    (!isStoreError(disease) && !isLoading(disease) && disease.details.disease) || '';

  if (isLoading(distribution)) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{diseaseName} outbreaks map - GIDEON</title>
      </Helmet>
      <DiseaseOutbreaksMap
        endemicLabels={endemicLabels}
        countryEndemics={countryEndemics}
        outbreaks={outbreaks}
        diseaseName={diseaseName}
        diseaseId={id}
        year={year}
      />
    </>
  );
};
