import { P } from 'Atoms/text';
import { InlineInputWrapper, InlineLabel, StyledButton } from 'Molecules/account/AccountFormInput';
import { Subsection } from 'Molecules/account/Subsection';
import { Props as AccountFormProps } from 'Organisms/AccountForm';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const EmailChangeButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: -10px 0 10px 0;
`;

interface Props extends AccountFormProps {
  onEmailChangeClick: () => void;
}

export const ContactDetails: FC<Props> = ({ inputState, onEmailChangeClick }) => (
  <Subsection title="Contact details">
    <InlineInputWrapper>
      <InlineLabel>Email</InlineLabel>
      <P>{inputState.email}</P>
    </InlineInputWrapper>
    <EmailChangeButtonWrapper>
      <StyledButton type="button" onClick={onEmailChangeClick}>
        Click here to change
      </StyledButton>
    </EmailChangeButtonWrapper>
  </Subsection>
);
