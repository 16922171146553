import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SignUp/Set/Value': {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
        errors: {
          ...state.errors,
          [action.payload.field]: null,
        },
      };
    }
    case 'SignUp/Set/Errors': {
      return {
        ...state,
        errors: action.payload,
      };
    }
    case 'SignUp/Set/Error': {
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.value,
        },
      };
    }
    default: {
      const _ignore: never = action;
      break;
    }
  }
  return state;
};
