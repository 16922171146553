import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'VirusDetails/SingleLoadInitiated': {
      return { ...state, details: { ...state.details, [action.payload.id]: 'Loading' } };
    }
    case 'VirusDetails/SingleLoaded': {
      return { ...state, details: { ...state.details, [action.payload.id]: action.payload.data } };
    }
    case 'VirusDetails/SingleLoadFailed': {
      return { ...state, details: { ...state.details, [action.payload.id]: action.payload.error } };
    }
    case 'VirusDetails/Public/SingleLoadInitiated': {
      return {
        ...state,
        detailsPublic: { ...state.detailsPublic, [action.payload.id]: 'Loading' },
      };
    }
    case 'VirusDetails/Public/SingleLoaded': {
      return {
        ...state,
        detailsPublic: { ...state.detailsPublic, [action.payload.id]: action.payload.data },
      };
    }
    case 'VirusDetails/Public/SingleLoadFailed': {
      return {
        ...state,
        detailsPublic: { ...state.detailsPublic, [action.payload.id]: action.payload.error },
      };
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
