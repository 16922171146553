import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { TrendingData } from 'types/trending';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'Trending/LoadInitiated', { section: string }>
  | ActionWithPayload<'Trending/Loaded', { section: string; data: TrendingData }>
  | ActionWithPayload<'Trending/LoadFailed', { section: string; error: StoreError }>;

export type State = {
  [type: string]: Resource<TrendingData>;
};

const initialState: State = {};

export const {
  dispatchContext: TrendingDispatchContext,
  stateContext: TrendingStateContext,
  provider: TrendingStoreProvider,
} = storeFactory(reducer, initialState);
