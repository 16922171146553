import { CircleButton } from 'Atoms/buttons/CircleButton';
import { Icon, SvgComponent } from 'Atoms/Icon';
import { H4, Span } from 'Atoms/text';
import { CompleteTooltip } from 'Atoms/tooltip/CompleteTooltip';
import { nanoid } from 'nanoid';
import React, { FC, useMemo } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components/macro';

const IconStyled = styled(Icon)`
  height: auto;
  fill: ${props => props.theme.colors.button.default.borderColor};
`;

const Wrapper = styled(animated.div)`
  width: 100%;
`;

const Caption = styled.span`
  margin: 10% 0 0 0;
  transition: all 0.1s ease-in;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 16px;
  }
`;

const TopText = styled(H4)`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 0 20px 0;
  text-align: center;
`;

const Content = styled.div`
  position: relative;
`;

export const Tooltip = styled(CompleteTooltip)`
  position: absolute;
  top: 0;
  right: -4px;
  cursor: pointer;
  z-index: 10;
  width: 30px;
  height: 30px;
`;

const HiddenSpan = styled(Span)`
  display: none;
`;

export type CircleButtonSize = 'medium' | 'big';

interface Props {
  icon: SvgComponent;
  onClick?: () => void;
  className?: string;
  caption: string;
  disabled?: boolean;
  tooltipInfo?: string;
  size?: CircleButtonSize;
  active?: boolean;
  disabledText?: string;
  topText?: string;
  description?: string;
}

export const CircleButtonWithIcon: FC<Props> = ({
  icon,
  className,
  caption,
  onClick,
  disabled,
  tooltipInfo,
  size,
  active,
  disabledText,
  topText,
  description,
}) => {
  const props = useSpring({
    wrapperMaxWidth: size === 'medium' ? '200px' : '270px',
    iconWidth: size === 'medium' ? '40%' : '45%',
  });

  const id = useMemo(() => nanoid(), []);

  return (
    <Wrapper style={{ maxWidth: props.wrapperMaxWidth }} className={className}>
      {topText && (
        <TopText size="smallRegular" color="inactive" font="Inter">
          {topText}
        </TopText>
      )}
      <Content>
        {tooltipInfo && <Tooltip content={tooltipInfo} />}
        <CircleButton
          onClick={onClick}
          size="normal"
          weight="500"
          font="Quicksand"
          disabled={disabled}
          active={active}
          ariaLabel={caption}
          disabledText={disabledText}
          aria-describedby={id}
        >
          <animated.div style={{ width: props.iconWidth }}>
            <IconStyled svgComponent={icon} />
          </animated.div>
          <Caption id={caption}>{caption}</Caption>
          {description && <HiddenSpan id={id}>{description}</HiddenSpan>}
        </CircleButton>
      </Content>
    </Wrapper>
  );
};
