import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { VaccineCoverage } from 'types/vaccine';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'Vaccines/Coverage/SingleLoadInitiated', { id: string }>
  | ActionWithPayload<'Vaccines/Coverage/SingleLoaded', { id: string; data: VaccineCoverage[] }>
  | ActionWithPayload<'Vaccines/Coverage/SingleLoadFailed', { id: string; error: StoreError }>;

export type State = {
  vaccineCoverages: { [year: string]: Resource<VaccineCoverage[]> | null };
};

export const initialState: State = {
  vaccineCoverages: {},
};

export const {
  dispatchContext: VaccinesCoverageDispatchContext,
  stateContext: VaccinesCoverageStateContext,
  provider: VaccinesCoverageStoreProvider,
} = storeFactory(reducer, initialState);
