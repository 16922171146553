import moment from 'moment';
import React, { useEffect } from 'react';
import { assertIsNotStoreError, newStoreError } from 'store/storeError';
import { Dispatch } from 'store/types';
import { CustomError, ErrorCodes } from 'types/errorTypes';
import { getUpdates } from 'Updates/apiServices/updates/updates';
import { RangeValue } from 'Updates/types/rangeType';
import { UpdatesResponse } from 'Updates/types/updatedField';

import { Action, State, UpdatesDispatchContext, UpdatesStateContext } from './provider';

export const useState = (): State => {
  const state = React.useContext(UpdatesStateContext);
  if (state === undefined) {
    throw new CustomError('UpdatesStore', ErrorCodes.StoreNotInitialized);
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(UpdatesDispatchContext);
  if (dispatch === undefined) {
    throw new CustomError('UpdatesStore', ErrorCodes.StoreNotInitialized);
  }
  return dispatch;
};

const getEpochSeconds = (range: RangeValue): number => {
  switch (range) {
    case '48-hours':
      return moment().subtract(2, 'days').unix();
    case '7-days':
      return moment().subtract(1, 'weeks').unix();
    default:
      return moment().unix();
  }
};

export const useUpdatedFields = (value: RangeValue): UpdatesResponse | 'Loading' | null => {
  const state = useState();
  const dispatch = useDispatch();

  const selection = state[value];

  useEffect(() => {
    if (!selection) {
      const from = getEpochSeconds(value);
      const to = moment().unix();

      dispatch({ type: 'Updates/SingleLoadInitiated', payload: { id: value } });
      getUpdates(from, to)
        .then(data => dispatch({ type: 'Updates/SingleLoaded', payload: { id: value, data } }))
        .catch(err =>
          dispatch({
            type: 'Updates/SingleLoadFailed',
            payload: { id: value, error: newStoreError(err.message, err.code, err) },
          })
        );
    }
  }, [dispatch, selection, value]);

  assertIsNotStoreError(selection);

  return selection;
};
