import styled from 'styled-components/macro';

import { MainLayout } from './MainLayout';

export const MapMainLayout = styled(MainLayout)`
  display: flex;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 0;
    min-height: unset;
  }
`;
