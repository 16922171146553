import { Icon } from 'Atoms/Icon';
import React, { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';
import { FontSizeCollection } from 'types/fontSizeCollection';

type Size = 'micro' | 'tiny' | 'verySmall' | 'small' | 'normal' | 'medium' | 'mediumBig' | 'big';
export const sizes: FontSizeCollection<Size> = {
  micro: {
    desktop: '10px',
    mobile: '10px',
  },
  tiny: {
    desktop: '12px',
    mobile: '12px',
  },
  verySmall: {
    desktop: '14px',
    mobile: '14px',
  },
  small: {
    desktop: '15px',
    mobile: '14px',
  },
  normal: {
    desktop: '16px',
    mobile: '16px',
  },
  medium: {
    desktop: '18px',
    mobile: '16px',
  },
  mediumBig: {
    desktop: '20px',
    mobile: '18px',
  },
  big: {
    desktop: '22px',
    mobile: '18px',
  },
};

const StyledButton = styled.button``;

export interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  className?: string;
  children?: ReactNode;
  size?: Size;
  weight?: '400' | '500' | '600' | '700';
  type?: 'submit' | 'button';
  disabled?: boolean;
}

const ButtonBase = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, type = 'button', ...rest }, ref) => (
    <StyledButton type={type} {...rest} ref={ref}>
      {children}
    </StyledButton>
  )
);

const DisabledButtonStyles = css`
  background: ${props => props.theme.colors.button.disabled?.backgroundColor};
  border-color: ${props => props.theme.colors.button.disabled?.borderColor};
  color: ${props => props.theme.colors.button.disabled?.textColor};
  ${Icon} {
    fill: ${props => props.theme.colors.button.disabled?.textColor};
  }
  cursor: default;
`;

export const Button = styled(ButtonBase)`
  font-family: ${props => props.theme.fontFamily.Inter};
  font-size: ${props => sizes[props.size || 'normal'].desktop};
  font-weight: ${props => props.weight || '400'};
  width: 100%;
  color: ${props => props.theme.colors.button.default.textColor};
  background: ${props => props.theme.colors.button.default.backgroundColor};
  border: 1px solid ${props => props.theme.colors.button.default.borderColor};
  border-radius: 4px;
  padding: 15px 16px 14px;
  line-height: 19px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;

  ${Icon} {
    fill: ${props => props.theme.colors.button.default.textColor};
  }

  &:hover {
    background: ${props => props.theme.colors.button.hover.backgroundColor};
    border-color: ${props => props.theme.colors.button.hover.borderColor};
    color: ${props => props.theme.colors.button.hover.textColor};
    ${Icon} {
      fill: ${props => props.theme.colors.button.hover.textColor};
    }
  }

  &:active {
    background: ${props => props.theme.colors.button.active.backgroundColor};
    border-color: ${props => props.theme.colors.button.active.borderColor};
    color: ${props => props.theme.colors.button.active.textColor};
    ${Icon} {
      fill: ${props => props.theme.colors.button.active.textColor};
    }
  }

  &:disabled {
    ${DisabledButtonStyles};

    &:hover {
      ${DisabledButtonStyles};
    }
  }

  &:focus {
    outline: 2px solid ${props => props.theme.colors.focus};
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: ${props => sizes[props.size || 'normal'].mobile};
  }
`;
