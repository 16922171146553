import { Link } from 'react-scroll';
import styled from 'styled-components/macro';

export const RecordAnchorLink = styled(Link)`
  display: inline-block;
  font: ${props => props.theme.fonts.bigText};
  color: ${props => props.theme.colors.text.lightLink};
  cursor: pointer;
  text-decoration: underline;

  &:focus {
    outline: 2px solid ${props => props.theme.colors.focus};
  }
`;
