import { CheckboxWithLabel } from 'Molecules/CheckboxWithLabel';
import React, { ChangeEvent, FC, useState } from 'react';
import { DefaultStartPageContext } from 'services/localStorage/defaultStartPage.provider';
import { useContextAssert } from 'services/useContextAssert.hook';
import styled from 'styled-components/macro';
import { StartPage } from 'types/startPage';

const StartCheckboxStyled = styled(CheckboxWithLabel)`
  margin-top: 50px;
  justify-content: center;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-bottom: 50px;
  }
`;

interface Props {
  className?: string;
  page: StartPage;
}

export const DefaulPageCheckbox: FC<Props> = ({ className, page }) => {
  const [startPageValue, setStartPageValue] = useContextAssert(DefaultStartPageContext);
  const [checked, setChecked] = useState(startPageValue === page);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      setStartPageValue(page);
    } else {
      setStartPageValue('start');
    }
  };

  return (
    <StartCheckboxStyled
      className={className}
      checked={checked}
      onChange={onChange}
      size="medium"
      checkboxSize="30px"
    >
      Make this my default page after sign in
    </StartCheckboxStyled>
  );
};
