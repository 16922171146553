import { Toast } from 'Atoms/toast/Toast';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { isLoading } from 'store/types';

type Status = 'Loading' | 'Ready' | null;

const getLabel = (url: string | null): string => {
  if (url === 'Loading') {
    return 'Generating...';
  } else if (url) {
    return 'PDF is ready';
  } else {
    return 'Download PDF';
  }
};

const getStatus = (url: string | null): Status => {
  if (url === 'Loading') {
    return 'Loading';
  } else if (url) {
    return 'Ready';
  } else {
    return null;
  }
};

interface DownloadPDF {
  onClick: () => void;
  status: Status;
  label: string;
}

export const useDownloadPDF = (getPDFUrl: () => Promise<string>): DownloadPDF => {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    setUrl(null);
  }, [getPDFUrl]);

  const onClick = useCallback((): void => {
    if (isLoading(url)) {
      return;
    }

    if (url) {
      window.open(url, '_blank');
    } else {
      setUrl('Loading');
      getPDFUrl()
        .then(url => {
          setUrl(url);
          toast.success(
            <Toast
              type="success"
              content="Your PDF is ready"
              to={url}
              linkContent="Your link is ready"
              externalLink
              openInNewTab
            />
          );
        })
        .catch(err => {
          setUrl(null);
          toast.error(
            <Toast
              type="error"
              content={
                err.response?.status === 429
                  ? 'PDF download limit exceeded'
                  : 'Failed to download PDF'
              }
            />
          );
        });
    }
  }, [getPDFUrl, url]);

  return {
    onClick,
    status: getStatus(url),
    label: getLabel(url),
  };
};
