import React, { FC } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

interface Props {
  className?: string;
  comparisonAccent?: keyof DefaultTheme['colors']['comparisonColors'];
  accent?: keyof DefaultTheme['colors']['accent'];
  round?: boolean;
}

const ColoredBoxBase: FC<Props> = ({ className }) => {
  return <div className={className}></div>;
};

export const ColoredBox = styled(ColoredBoxBase)`
  width: 20px;
  height: 20px;
  border-radius: ${props => (props.round ? '10px' : '0')};
  background: ${props =>
    props.accent
      ? props.theme.colors.accent[props.accent]
      : props.comparisonAccent
      ? props.theme.colors.comparisonColors[props.comparisonAccent]
      : 'none'};
`;
