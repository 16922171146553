import { VariableAuthRoute } from 'Atoms/routes/VariableAuthRoute';
import { NotFound } from 'pages/404/';
import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useCleanSlug } from 'services/slug/slug.service';
import { useSlugURLUpdate } from 'services/useSlugURLUpdate.hook';

import { Drug } from './Drug';
import { DrugPublic } from './DrugPublic';

interface Params {
  slug: string;
}

type Props = RouteComponentProps<Params>;

export const DrugRoutes: FC<Props> = ({ match, history }) => {
  const { slug, id } = useCleanSlug('drugs', match.params.slug);
  useSlugURLUpdate(history, match.params.slug, slug);

  const drugId = parseInt(id);

  return (
    <Switch>
      <VariableAuthRoute
        exact
        path={match.url}
        render={props => <Drug {...props} drugId={drugId} />}
        renderPublic={props => <DrugPublic {...props} drugId={drugId} />}
      />
      <Route component={NotFound} />
    </Switch>
  );
};
