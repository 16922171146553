import { ReactComponent as AlgalSVG } from 'assets/Diseases/Algal.svg';
import { ReactComponent as ParasiticSVG } from 'assets/Diseases/Parasitic.svg';
import { ReactComponent as PrionSVG } from 'assets/Diseases/Prion.svg';
import { ReactComponent as ProtoctistalSVG } from 'assets/Diseases/Protoctistal.svg';
import { ReactComponent as UnknownSVG } from 'assets/Diseases/Unknown.svg';
import { ReactComponent as ViralSVG } from 'assets/Diseases/Viral.svg';
import { ReactComponent as DiseasesSvg } from 'assets/ExploreFocus/Diseases.svg';
import { ReactComponent as BacterialSVG } from 'assets/Microbes/Bacteria.svg';
import { ReactComponent as FungalSVG } from 'assets/Microbes/Yeasts.svg';
import { P } from 'Atoms/text';
import { Props as PProps } from 'Atoms/text/P';
import { ExploreFocusSection } from 'Molecules/exploreFocus/ExploreFocusSectionMobile';
import React, { FC } from 'react';
import { LoginModalContext } from 'services/loginModal.service';
import { useContextAssert } from 'services/useContextAssert.hook';
import { usePushState } from 'services/usePushState.hook';
import { useAccountController } from 'store/accountStore/hooks';
import styled from 'styled-components/macro';

const SubTitle = styled(P).attrs(
  (): Partial<PProps> => ({
    size: 'big',
    weight: '500',
  })
)`
  text-align: center;
`;

const Categories = styled.div`
  display: none;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin: 33px 0 20px 0;
  }
`;

interface Props {
  basePath: string;
  allTotal: number;
  algalTotal: number;
  bacterialTotal: number;
  fungalTotal: number;
  parasiticTotal: number;
  prionTotal: number;
  protoctistalTotal: number;
  viralTotal: number;
  unknownTotal: number;
}

export const AZDiseasesButtonsMobile: FC<Props> = ({
  basePath,
  allTotal,
  algalTotal,
  bacterialTotal,
  fungalTotal,
  parasiticTotal,
  prionTotal,
  protoctistalTotal,
  viralTotal,
  unknownTotal,
}) => {
  const { isAuthenticated } = useAccountController();
  const { setOpen } = useContextAssert(LoginModalContext);
  const { push } = usePushState();

  const navigateToURL = (url: string): void => {
    if (!isAuthenticated) {
      setOpen(true);
      return;
    }
    push(url);
  };

  return (
    <>
      <SubTitle>Choose etiology</SubTitle>
      <Categories>
        <ExploreFocusSection
          caption={`All (${allTotal})`}
          to={`${basePath}/all`}
          Icon={DiseasesSvg}
        />
        <ExploreFocusSection
          caption={!isAuthenticated ? 'Algal' : `Algal (${algalTotal})`}
          onClick={() => navigateToURL(`${basePath}/algal`)}
          Icon={AlgalSVG}
        />
        <ExploreFocusSection
          caption={!isAuthenticated ? 'Bacterial' : `Bacterial (${bacterialTotal})`}
          onClick={() => navigateToURL(`${basePath}/bacterial`)}
          Icon={BacterialSVG}
        />
        <ExploreFocusSection
          caption={!isAuthenticated ? 'Fungal' : `Fungal (${fungalTotal})`}
          onClick={() => navigateToURL(`${basePath}/fungal`)}
          Icon={FungalSVG}
        />
        <ExploreFocusSection
          caption={!isAuthenticated ? 'Parasitic' : `Parasitic (${parasiticTotal})`}
          onClick={() => navigateToURL(`${basePath}/parasitic`)}
          Icon={ParasiticSVG}
        />
        <ExploreFocusSection
          caption={!isAuthenticated ? 'Prion' : `Prion (${prionTotal})`}
          onClick={() => navigateToURL(`${basePath}/prion`)}
          Icon={PrionSVG}
        />
        <ExploreFocusSection
          caption={!isAuthenticated ? 'Protoctistal' : `Protoctistal (${protoctistalTotal})`}
          onClick={() => navigateToURL(`${basePath}/protoctistal`)}
          Icon={ProtoctistalSVG}
        />
        <ExploreFocusSection
          caption={!isAuthenticated ? 'Viral' : `Viral (${viralTotal})`}
          onClick={() => navigateToURL(`${basePath}/viral`)}
          Icon={ViralSVG}
        />
        <ExploreFocusSection
          caption={!isAuthenticated ? 'Unknown agent' : `Unknown agent (${unknownTotal})`}
          onClick={() => navigateToURL(`${basePath}/unknown-agent`)}
          Icon={UnknownSVG}
        />
      </Categories>
    </>
  );
};
