import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { P } from 'Atoms/text';
import { DrugSpectrumModal } from 'Molecules/drug/DrugSpectrumModal';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC, useState } from 'react';
import { useMicrobeDetails } from 'store/microbeDetailsStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { MicrobeType } from 'types/microbeDetails';

const Wrapper = styled.div`
  margin-top: 10px;
`;

const StyledP = styled(P)`
  margin: 20px 0 10px;
`;

const StyledButton = styled(InvisibleButton)`
  display: block;
  margin: 10px 0;
  color: ${props => props.theme.colors.text.darkLink};
  font: ${props => props.theme.fonts.normalText};
  cursor: pointer;
`;

interface Props {
  className?: string;
  id: number;
  type: MicrobeType;
}

export const CompareMicrobeNotes: FC<Props> = ({ className, id, type }) => {
  const [isSpectrumOpen, setSpectrumOpen] = useState<boolean>(false);
  const microbe = useMicrobeDetails(id, type);

  assertIsNotStoreError(microbe);

  if (isLoading(microbe)) {
    return null;
  }

  const hasSusceptibility = microbe.drugSusceptibility.length > 0;
  const hasNotes = microbe.notes.length > 0;

  if (!hasSusceptibility && !hasNotes)
    return (
      <Wrapper className={className}>
        <StyledP weight="700">{microbe.name}</StyledP>
        <P>No data</P>
      </Wrapper>
    );

  return (
    <Wrapper className={className}>
      <StyledP weight="700">{microbe.name}</StyledP>
      {hasNotes && <DynamicHtml content={microbe.notes} />}
      {hasSusceptibility && (
        <StyledButton
          onClick={() => setSpectrumOpen(true)}
        >{`Typical susceptibility of ${microbe.name}`}</StyledButton>
      )}
      {isSpectrumOpen && (
        <DrugSpectrumModal
          isOpen={isSpectrumOpen}
          onClose={() => setSpectrumOpen(false)}
          initialSpectrumValues={microbe.drugSusceptibility.map(x => ({
            value: x.code.toString(),
            label: x.pathogen,
          }))}
        />
      )}
    </Wrapper>
  );
};
