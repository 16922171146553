import { Span } from 'Atoms/text';
import { CompleteTooltip } from 'Atoms/tooltip/CompleteTooltip';
import { ProbabilityKey } from 'Molecules/compare/ProbabilityKey';
import { BreadcrumbSelect } from 'Molecules/select/BreadcrumbSelect';
import React, { FC, useMemo } from 'react';
import { OnChangeValue } from 'react-select';
import styled from 'styled-components/macro';
import { Probability, ProbabilityType } from 'types/compare';
import { SelectOption } from 'types/select';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  flex-wrap: wrap;
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ProbabilityKeys = styled.div`
  display: flex;
  margin-top: 7px;
  flex-wrap: wrap;
`;

const StyledSelect = styled(BreadcrumbSelect)`
  margin: 0 20px;
`;

const StyledProbability = styled(ProbabilityKey)``;

const ProbabilityKeyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 25px 40px 0 0;

  position: relative;
`;

const CompleteTooltipStyled = styled(CompleteTooltip)`
  top: -15px;
  right: -15px;
`;

const probabilityTypes: ProbabilityType[] = ['words', 'percentage'];

interface Props {
  className?: string;
  onChange?: (selection: OnChangeValue<SelectOption, false>) => void;
  probabilities: Probability[];
}

export const CompareProbabilityKeys: FC<Props> = ({ className, onChange, probabilities }) => {
  const probabilityTypeOptions = probabilityTypes.map(x => ({ value: x, label: x }));

  const probabilityKeys = useMemo(
    () =>
      probabilities.map(x => (
        <ProbabilityKeyWrapper key={x.name}>
          <StyledProbability
            probability={{ type: 'words', text: x.name }}
            accent={x.accent}
            weight="700"
          />
          {x.explanationText && (
            <CompleteTooltipStyled position="top-start" content={x.explanationText} />
          )}
        </ProbabilityKeyWrapper>
      )),
    [probabilities]
  );

  return (
    <Wrapper className={className}>
      <SelectWrapper>
        <Span weight="700">Probability key</Span>
        <StyledSelect
          classNamePrefix="breadcrumb-select"
          options={probabilityTypeOptions}
          onChange={onChange}
          isSearchable={false}
          ariaLabel="Probability key"
        />
      </SelectWrapper>
      <ProbabilityKeys>{probabilityKeys}</ProbabilityKeys>
    </Wrapper>
  );
};
