import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { Action as GenericAction, ActionWithPayload, Resource } from 'store/types';
import { CountryWithSlug } from 'types/country';

import { reducer } from './reducer';

export type Action =
  | GenericAction<'Countries/LoadInitiated'>
  | ActionWithPayload<'Countries/Loaded', CountryWithSlug[]>
  | ActionWithPayload<'Countries/LoadFailed', StoreError>;

export interface Countries {
  data: CountryWithSlug[];
}

export type State = Resource<Countries> | null;

const initialState: State = null;

export const {
  dispatchContext: CountriesDispatchContext,
  stateContext: CountriesStateContext,
  provider: CountriesStoreProvider,
} = storeFactory(reducer, initialState);
