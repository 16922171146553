import { RefObject, useEffect, useRef, useState } from 'react';

interface useHideOnScrollReturns<T> {
  show: boolean;
  ref: RefObject<T>;
}

/**
 * `useHideOnScroll` returns `ref` to assign to an element you want to hide on scroll,
 * `show` indicates whether element needs to be shown or hidden.
 */
export const useHideOnScroll = <T extends HTMLElement>(): useHideOnScrollReturns<T> => {
  const containerRef = useRef<T>(null);
  const [previousScrollPosition, setPreviousScrollPosition] = useState<number>(0);
  const [show, setIsShow] = useState<boolean>(true);

  useEffect(() => {
    const onScroll = (): void => {
      if (!containerRef.current) {
        return;
      }

      const currentPosition = window.pageYOffset;
      const isScrolledToBottom = window.scrollY + window.innerHeight >= document.body.scrollHeight;

      if (previousScrollPosition > currentPosition || isScrolledToBottom) {
        setIsShow(true);
      } else {
        setIsShow(false);
      }

      setPreviousScrollPosition(currentPosition);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [previousScrollPosition]);

  return { show, ref: containerRef };
};
