import { P } from 'Atoms/text';
import { rgba } from 'polished';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { CustomError } from 'types/errorTypes';

interface Props {
  title: string;
  error?: Error;
}

const PStyled = styled(P)`
  border: solid 1px ${props => props.theme.colors.text.error};
  border-radius: 4px;
  padding: 10px;
  background-color: ${props => rgba(props.theme.colors.text.error, 0.07)};
`;

export const ContentError: FC<Props> = ({ title, error }) => {
  return (
    <PStyled color="error">
      {title}
      {error instanceof CustomError
        ? ` ${error?.type}: ${error?.message} ${error?.code}`
        : ` ${error?.name} ${error?.message}`}
    </PStyled>
  );
};
