import axios from 'axios.cached';
import { VaccineAbbreviation } from 'types/vaccineAbbreviation';

import { DataDTO } from '../common/DTO';
import { VaccineAbbreviationDTO } from './abbreviations.dto';
import { mapAbbreviationDTO } from './abbreviations.mapper';

export const getAbbreviationDetails = async (id: string): Promise<VaccineAbbreviation> => {
  const abbreviationDetails = await axios.get<DataDTO<VaccineAbbreviationDTO>>(
    `/api/vaccines/abbreviations/${id}`
  );

  return mapAbbreviationDTO(id, abbreviationDetails.data.data);
};
