import { SelectOption } from 'types/select';

interface ListEntry {
  id: number | string;
  name: string;
}

export const mapListsToSelectOptions = (...lists: ListEntry[][]): SelectOption[][] => {
  const filtered = lists.map(list =>
    list.filter(a => !!a.id && a.id !== '0').map(a => ({ value: a.id.toString(), label: a.name }))
  );
  const extended = filtered.map(list => [{ value: 'null', label: 'Any' }, ...list]);
  return extended;
};
