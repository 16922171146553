import { LocationDescriptorObject, LocationState } from 'history';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { usePageName } from 'services/pageName.service';

export type PageNameState = {
  pageName: string;
};

export type PageNameStateOptional = PageNameState | null | undefined;

export type PushType<T> = {
  push(path: string, state?: T): void;
  push(location: LocationDescriptorObject<T>): void;
};

const isLocation = (value: unknown): value is LocationDescriptorObject =>
  typeof value == 'object' && !!value;

const isLocationWithState = (
  value: unknown
): value is LocationDescriptorObject<Record<string, unknown>> =>
  isLocation(value) && 'state' in value && typeof value.state === 'object' && value.state !== null;

/**
 * usePushState pushes specified path while also adding last pathname to state.
 *
 * This should be used to maintain a chain of state objects and in process used to evaluate breadcrumb back button label.
 * Use this everywhere where on push the breadcrumb would be rendered and useHistory() otherwise.
 */
export const usePushState = <T extends LocationState>(): PushType<T> => {
  const { push } = useHistory<PageNameStateOptional>();
  const pageName = usePageName();

  const newPush = useCallback(
    (...args: unknown[]): void => {
      const newState: PageNameState = {
        pageName: pageName,
      };

      const pathOrLocation = args[0];
      const state = args[1];

      if (typeof pathOrLocation === 'string' && typeof state === 'object') {
        push(pathOrLocation, { ...state, ...newState });
      } else if (typeof pathOrLocation === 'string' && !state) {
        push(pathOrLocation, newState);
      } else if (isLocation(pathOrLocation)) {
        const newLocation: LocationDescriptorObject<PageNameStateOptional> = {
          ...pathOrLocation,
          state: {
            ...newState,
          },
        };

        push(newLocation);
      } else if (isLocationWithState(pathOrLocation)) {
        const newLocation: LocationDescriptorObject<PageNameStateOptional> = {
          ...pathOrLocation,
          state: {
            ...pathOrLocation.state,
            ...newState,
          },
        };

        push(newLocation);
      }
    },
    [pageName, push]
  );

  return { push: newPush };
};
