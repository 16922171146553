import { ReactComponent as SearchIcon } from 'assets/Navbar/Search.svg';
import { Icon, SvgComponent } from 'Atoms/Icon';
import React, { ReactElement, useMemo } from 'react';
import { components, ControlProps } from 'react-select';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';

const { Control } = components;

const StyledIcon = styled(Icon)`
  height: 18px;
  width: 18px;
  margin-right: 23px;
`;

export const SearchSelectControl = <T extends SelectOption, isMulti extends boolean>({
  children,
  ...props
}: ControlProps<T, isMulti>): ReactElement => {
  const option = props.getValue();

  const Icon = useMemo((): SvgComponent => {
    const optionValue = option[0];

    if (optionValue && optionValue.Icon) {
      return optionValue.Icon;
    }

    return SearchIcon;
  }, [option]);

  return (
    <Control
      {...props}
      className={props.hasValue ? `${props.selectProps.classNamePrefix}__control--has-value` : ''}
    >
      <StyledIcon
        svgComponent={Icon}
        className={`${props.selectProps.classNamePrefix}__search-icon`}
      />
      {children}
    </Control>
  );
};
