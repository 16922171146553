import { DiseaseInCountry } from 'Explore/pages/Diseases/Disease/DiseaseInCountry/DiseaseInCountry';
import { NotFound } from 'pages/404/';
import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useCleanSlug } from 'services/slug/slug.service';
import { useSlugURLUpdate } from 'services/useSlugURLUpdate.hook';

export interface Params {
  countrySlug: string;
}

type Props = RouteComponentProps<Params> & {
  diseaseId: number;
};

export const DiseaseInnerRoutes: FC<Props> = ({ match, diseaseId, history }) => {
  const { slug, id } = useCleanSlug('countries', match.params.countrySlug);
  useSlugURLUpdate(history, match.params.countrySlug, slug);

  return (
    <Switch>
      <Route
        exact
        path={match.url}
        render={props => <DiseaseInCountry {...props} diseaseId={diseaseId} countryId={id} />}
      />
      <Route component={NotFound} />
    </Switch>
  );
};
