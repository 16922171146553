import {
  getBacteriaSimple,
  getMycobacteriaSimple,
  getVirusSimple,
  getYeastsSimple
} from 'apiServices/Microbes/microbes';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useAccountController } from 'store/accountStore/hooks';
import { assertIsNotStoreError, newStoreError } from 'store/storeError';
import { Dispatch, isLoading, Loading, Resource } from 'store/types';
import { GroupedData } from 'types/az';
import { MicrobeType } from 'types/microbeDetails';
import { MicrobeLists, SimpleMicrobe, SimpleMicrobes } from 'types/simpleMicrobe';
import { groupDataAlphabetically } from 'utils/getGroupedData';

import { Action, MicrobesDispatchContext, MicrobesStateContext, State } from './provider';

export const useState = (): State => {
  const state = React.useContext(MicrobesStateContext);
  if (state === undefined) {
    throw new Error('microbe state is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(MicrobesDispatchContext);
  if (dispatch === undefined) {
    throw new Error('microbe state is not initialized');
  }
  return dispatch;
};

const fetchAllMicrobes = (
  dispatch: Dispatch<Action>,
  state: State,
  isAuthenticated: boolean
): void => {
  if (!state.bacteria) {
    dispatch({ type: 'Microbes/LoadInitiated', payload: { type: 'bacteria' } });
    getBacteriaSimple(!isAuthenticated)
      .then(data =>
        dispatch({ type: 'Microbes/Loaded', payload: { type: 'bacteria', data: data } })
      )
      .catch(err =>
        dispatch({
          type: 'Microbes/LoadFailed',
          payload: { type: 'bacteria', data: newStoreError(err.message, err.code, err) },
        })
      );
  }

  if (!state.mycobacteria) {
    dispatch({ type: 'Microbes/LoadInitiated', payload: { type: 'mycobacteria' } });
    getMycobacteriaSimple(!isAuthenticated)
      .then(data =>
        dispatch({ type: 'Microbes/Loaded', payload: { type: 'mycobacteria', data: data } })
      )
      .catch(err =>
        dispatch({
          type: 'Microbes/LoadFailed',
          payload: { type: 'mycobacteria', data: newStoreError(err.message, err.code, err) },
        })
      );
  }

  if (!state.yeasts) {
    dispatch({ type: 'Microbes/LoadInitiated', payload: { type: 'yeasts' } });
    getYeastsSimple(!isAuthenticated)
      .then(data => dispatch({ type: 'Microbes/Loaded', payload: { type: 'yeasts', data: data } }))
      .catch(err =>
        dispatch({
          type: 'Microbes/LoadFailed',
          payload: { type: 'yeasts', data: newStoreError(err.message, err.code, err) },
        })
      );
  }

  if (!state.viruses) {
    dispatch({ type: 'Microbes/LoadInitiated', payload: { type: 'viruses' } });
    getVirusSimple(!isAuthenticated)
      .then(data => dispatch({ type: 'Microbes/Loaded', payload: { type: 'viruses', data: data } }))
      .catch(err =>
        dispatch({
          type: 'Microbes/LoadFailed',
          payload: { type: 'viruses', data: newStoreError(err.message, err.code, err) },
        })
      );
  }
};

export const useMicrobesResource = (): Resource<MicrobeLists> => {
  const state = useState();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAccountController();

  useEffect(() => {
    fetchAllMicrobes(dispatch, state, isAuthenticated);
  }, [state, dispatch, isAuthenticated]);

  if (
    isLoading(state.bacteria) ||
    isLoading(state.mycobacteria) ||
    isLoading(state.yeasts) ||
    isLoading(state.viruses) ||
    !state.bacteria ||
    !state.mycobacteria ||
    !state.yeasts ||
    !state.viruses
  ) {
    return Loading;
  }

  assertIsNotStoreError(state.bacteria);
  assertIsNotStoreError(state.mycobacteria);
  assertIsNotStoreError(state.yeasts);
  assertIsNotStoreError(state.viruses);

  return {
    bacteria: state.bacteria.slice().sort((lhs, rhs) => lhs.name.localeCompare(rhs.name)),
    mycobacteria: state.mycobacteria.slice().sort((lhs, rhs) => lhs.name.localeCompare(rhs.name)),
    yeasts: state.yeasts.slice().sort((lhs, rhs) => lhs.name.localeCompare(rhs.name)),
    viruses: state.viruses.slice().sort((lhs, rhs) => lhs.name.localeCompare(rhs.name)),
    allMicrobes: state.bacteria
      .concat(state.mycobacteria, state.yeasts, state.viruses)
      .sort((lhs, rhs) => lhs.name.localeCompare(rhs.name)),
  };
};

const emptyObject = { data: groupDataAlphabetically([]), total: 0 };

interface GroupedMicrobes {
  bacteria: GroupedData;
  mycobacteria: GroupedData;
  yeasts: GroupedData;
  viruses: GroupedData;
}

export const useMicrobesGrouped = (): GroupedMicrobes => {
  const microbes = useMicrobesResource();

  return useMemo(() => {
    assertIsNotStoreError(microbes);
    if (isLoading(microbes)) {
      return {
        bacteria: emptyObject,
        mycobacteria: emptyObject,
        yeasts: emptyObject,
        viruses: emptyObject
      };
    } else {
      return {
        bacteria: {
          data: groupDataAlphabetically(microbes.bacteria),
          total: microbes.bacteria.length,
        },
        mycobacteria: {
          data: groupDataAlphabetically(microbes.mycobacteria, undefined, 'Mycobacterium'),
          total: microbes.mycobacteria.length,
        },
        yeasts: {
          data: groupDataAlphabetically(microbes.yeasts),
          total: microbes.yeasts.length,
        },
        viruses: {
          data: groupDataAlphabetically(microbes.viruses),
          total: microbes.viruses.length
        }
      };
    }
  }, [microbes]);
};

export const useMicrobeYeastsList = (): SimpleMicrobe[] => {
  const microbesResource = useMicrobesResource();

  assertIsNotStoreError(microbesResource);

  return useMemo(() => (isLoading(microbesResource) ? [] : microbesResource.yeasts), [
    microbesResource,
  ]);
};

export const useMicrobeMycobacteriaList = (): SimpleMicrobe[] => {
  const microbesResource = useMicrobesResource();

  assertIsNotStoreError(microbesResource);

  return useMemo(() => (isLoading(microbesResource) ? [] : microbesResource.mycobacteria), [
    microbesResource,
  ]);
};

export const useMicrobeBacteriaList = (): SimpleMicrobe[] => {
  const microbesResource = useMicrobesResource();

  assertIsNotStoreError(microbesResource);

  return useMemo(() => (isLoading(microbesResource) ? [] : microbesResource.bacteria), [
    microbesResource,
  ]);
};

export const useGetMicrobeList = (): (() => SimpleMicrobes) => {
  const state = useState();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAccountController();

  const getMicrobes = useCallback(() => {
    fetchAllMicrobes(dispatch, state, isAuthenticated);

    assertIsNotStoreError(state.bacteria);
    assertIsNotStoreError(state.mycobacteria);
    assertIsNotStoreError(state.yeasts);
    assertIsNotStoreError(state.viruses);

    if (
      isLoading(state.bacteria) ||
      isLoading(state.mycobacteria) ||
      isLoading(state.yeasts) ||
      isLoading(state.viruses) ||
      !state.bacteria ||
      !state.mycobacteria ||
      !state.yeasts ||
      !state.viruses
    ) {
      return { bacteria: [], mycobacteria: [], yeasts: [], viruses: [] };
    }

    return { bacteria: state.bacteria, mycobacteria: state.mycobacteria, yeasts: state.yeasts, viruses: state.viruses };
  }, [state, dispatch, isAuthenticated]);

  return getMicrobes;
};

export const useMicrobeList = (type: MicrobeType): SimpleMicrobe[] => {
  const state = useState();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAccountController();

  assertIsNotStoreError(state);

  const microbeState = state[type];

  const fetchFunction = useMemo(() => {
    switch (type) {
      case 'bacteria': {
        return getBacteriaSimple;
      }
      case 'mycobacteria': {
        return getMycobacteriaSimple;
      }
      case 'yeasts': {
        return getYeastsSimple;
      }
    }
  }, [type]);

  useEffect(() => {
    if (!microbeState) {
      dispatch({ type: 'Microbes/LoadInitiated', payload: { type: type } });
      fetchFunction(!isAuthenticated)
        .then(data => dispatch({ type: 'Microbes/Loaded', payload: { type: type, data: data } }))
        .catch(err =>
          dispatch({
            type: 'Microbes/LoadFailed',
            payload: { type: type, data: newStoreError(err.message, err.code, err) },
          })
        );
    }
  }, [dispatch, fetchFunction, isAuthenticated, microbeState, type]);

  assertIsNotStoreError(microbeState);

  return useMemo(() => (isLoading(microbeState) || !microbeState ? [] : microbeState), [
    microbeState,
  ]);
};
