import React, { ChangeEvent, FC } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { FontSizeCollection } from 'types/fontSizeCollection';

type Size = 'big' | 'normal';
const sizes: FontSizeCollection<Size> = {
  normal: {
    desktop: '18px',
    mobile: '16px',
  },
  big: {
    desktop: '30px',
    mobile: '20px',
  },
};

interface Props {
  className?: string;
  type: 'text' | 'email' | 'password';
  name?: string;
  placeholder?: string;
  size?: Size;
  color?: keyof DefaultTheme['colors']['text'];
  placeholderColor?: keyof DefaultTheme['colors']['text'];
  background?: keyof DefaultTheme['colors']['background'];
  weight?: '400' | '500' | '600' | '700';
  value?: string;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
  ariaLabel?: string;
}

const InputBase: FC<Props> = ({
  className,
  type,
  name,
  placeholder,
  onChange,
  value,
  ariaLabel,
}) => (
  <input
    className={className}
    type={type}
    name={name}
    placeholder={placeholder}
    onChange={onChange}
    value={value}
    aria-label={ariaLabel}
  />
);

export const Input = styled(InputBase)`
  font-size: ${props => sizes[props.size || 'big'].desktop};
  font-weight: ${props => props.weight || '400'};
  color: ${props => props.theme.colors.text[props.color || 'input']};
  background: ${props => props.theme.colors.background[props.background || 'primary']};

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: ${props => sizes[props.size || 'big'].mobile};
  }

  &::placeholder {
    color: ${props => props.theme.colors.text[props.placeholderColor || 'input']};
  }
`;
