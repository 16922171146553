import { getCountryLatestOutbreaks } from 'apiServices/countryLatestOutbreaks/countryLatestOutbreaks';
import React, { useEffect } from 'react';
import { assertIsNotStoreError, newStoreError } from 'store/storeError';
import { Dispatch, Loading } from 'store/types';
import { LatestOutbreak } from 'types/countryLatestOutbreaks';

import {
  Action,
  CountryLatestOutbreaksDispatchContext,
  CountryLatestOutbreaksStateContext,
  State,
} from './provider';

export const useState = (): State => {
  const state = React.useContext(CountryLatestOutbreaksStateContext);
  if (state === undefined) {
    throw new Error('Country latest outbreaks store is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(CountryLatestOutbreaksDispatchContext);
  if (dispatch === undefined) {
    throw new Error('Country latest outbreaks store is not initialized');
  }
  return dispatch;
};

export const useCountryLatestOutbreaks = (id: string): LatestOutbreak[] | 'Loading' => {
  const state = useState();
  const dispatch = useDispatch();
  const countryOutbreaks = state[id];

  useEffect(() => {
    if (!countryOutbreaks) {
      dispatch({
        type: 'CountryLatestOutbreaks/SingleLoadInitiated',
        payload: { id },
      });
      getCountryLatestOutbreaks(id)
        .then(data =>
          dispatch({ type: 'CountryLatestOutbreaks/SingleLoaded', payload: { id, data } })
        )
        .catch(err =>
          dispatch({
            type: 'CountryLatestOutbreaks/SingleLoadFailed',
            payload: { id, error: newStoreError(err.message, err.code, err) },
          })
        );
    }
  }, [dispatch, state, countryOutbreaks, id]);

  assertIsNotStoreError(countryOutbreaks);

  return countryOutbreaks || Loading;
};
