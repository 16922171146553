import { useContext } from 'react';
import { ThemeContext } from 'styled-components/macro';
import { Section } from 'Updates/types/content';

export const useContentsSections = (): Section[] => {
  const theme = useContext(ThemeContext);

  return [
    {
      name: 'references',
      dataFields: ['total_reference_count'],
      icon: theme.images.updates.content.references,
    },
    {
      name: 'surveys',
      dataFields: ['survey_count'],
      icon: theme.images.updates.content.survey,
    },
    {
      name: 'outbreaks',
      dataFields: ['outbreak_count'],
      icon: theme.images.updates.content.outbreak,
    },
    {
      name: 'graphs',
      dataFields: ['graph_count'],
      icon: theme.images.updates.content.graphs,
    },
    {
      name: 'country notes',
      dataFields: ['countrynote_count'],
      icon: theme.images.updates.content.countryNotes,
    },
    {
      name: 'case series',
      dataFields: ['case_series_count'],
      icon: theme.images.updates.content.caseSeries,
    },
    {
      name: 'images',
      dataFields: [
        'image_count',
        'bacteria_image_count',
        'mycobacteria_image_count',
        'viruses_image_count',
        'yeasts_image_count',
      ],
      icon: theme.images.updates.content.images,
    },
    {
      name: 'diseases',
      dataFields: ['disease_count'],
      icon: theme.images.updates.content.diseases,
    },
    {
      name: 'countries',
      dataFields: ['country_count'],
      icon: theme.images.updates.content.countries,
    },
    {
      name: 'drugs',
      dataFields: ['drug_count'],
      icon: theme.images.updates.content.drugs,
    },
    {
      name: 'vaccines',
      dataFields: ['vaccine_count'],
      icon: theme.images.updates.content.vaccines,
    },
    {
      name: 'trade names',
      dataFields: ['syndrug_count', 'synvaccine_count'],
      icon: theme.images.updates.content.tradeNames,
    },
    {
      name: 'bacteria',
      dataFields: ['bacteria_count'],
      icon: theme.images.updates.content.bacteria,
    },
    {
      name: 'mycobacteria',
      dataFields: ['mycobacteria_count'],
      icon: theme.images.updates.content.mycobacteria,
    },
    {
      name: 'viruses',
      dataFields: ['virus_count'],
      icon: theme.images.updates.content.viruses,
    },
    {
      name: 'yeasts + algae',
      dataFields: ['yeast_count'],
      icon: theme.images.updates.content.yeasts,
    },
    {
      name: 'lab tests',
      dataFields: ['phenobacteria_count', 'phenomycobacteria_count', 'phenoyeast_count'],
      icon: theme.images.updates.content.tests,
    },
    {
      name: 'eBooks',
      dataFields: [
        'country_ebook_count',
        'disease_ebook_count',
        'drug_ebook_count',
        'outbreak_ebook_count',
        'survey_ebook_count',
        'travel_ebook_count',
        'vaccine_ebook_count',
        'bacteria_ebook_count',
        'yeast_ebook_count',
      ],
      icon: theme.images.updates.content.ebooks,
    }
  ];
};
