import { Icon as IconAtom } from 'Atoms/Icon';
import { Span } from 'Atoms/text';
import React, { ReactElement, SyntheticEvent, useContext } from 'react';
import { components, OptionProps } from 'react-select';
import { ThemeModeContext } from 'services/theme';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';

const Image = styled.img`
  width: 40px;
  height: 32px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled(Span)`
  margin-left: 15px;

  color: ${props => props.theme.colors.select.default.text};
`;

const IconStyled = styled(IconAtom)`
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;

  fill: ${props => props.theme.colors.select.default.text};
`;

const { Option } = components;

const getSynonymLabel = (synonymOf?: string): ReactElement | null =>
  synonymOf ? <Span color="inactive">({synonymOf})</Span> : null;

export const SearchSelectOptionWithIcon = <T extends SelectOption, isMulti extends boolean>({
  innerProps,
  ...props
}: OptionProps<T, isMulti>): ReactElement => {
  const { onMouseMove: _ignoreMove, onMouseOver: _ignoreOver, ...restInnerProps } = innerProps;
  const { isDarkMode } = useContext(ThemeModeContext);

  const newInnerProps: typeof innerProps = {
    ...restInnerProps,
    onMouseMove: () => undefined,
    onMouseOver: () => undefined,
  };

  const onError = (e: SyntheticEvent<HTMLImageElement, Event>): void => {
    e.currentTarget.src = '/Flags/Missing.svg';
  };

  const option = props.data;

  const getInnerContent = (): ReactElement[] | null => {
    if (option.imageUrl) {
      return [
        <Image
          key="image"
          src={isDarkMode && option.darkImageUrl ? option.darkImageUrl : option.imageUrl}
          alt={option.label}
          onError={onError}
        />,
        <Label key="label">
          {option.label} {getSynonymLabel(option.synonymOf)}
        </Label>,
      ];
    } else if (option.Icon) {
      return [
        <IconStyled key="icon" svgComponent={option.Icon} />,
        <Label key="label">
          {option.label} {getSynonymLabel(option.synonymOf)}
        </Label>,
      ];
    } else {
      return [
        <Span key="label">
          {option.label} {getSynonymLabel(option.synonymOf)}
        </Span>,
      ];
    }
  };

  return (
    <Option {...props} innerProps={newInnerProps}>
      <Wrapper>{getInnerContent()}</Wrapper>
    </Option>
  );
};
