import { Loader } from 'Atoms/Loader';
import { CompareTableBase } from 'Organisms/compare/CompareTableBase';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC, useMemo, useState } from 'react';
import { useMultipleDiseaseDetails } from 'store/diseaseDetailStore/hooks';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { isNotLoading } from 'types/common';
import { FingerprintRow } from 'types/compare';
import { DiseaseDetails } from 'types/disease';
import { DynamicContent } from 'types/dynamicContent';
import { CellData } from 'types/table';
import { SortState } from 'types/table';
import { renderFingerprintBody, renderFingerprintHead } from 'utils/renderCompareTable';

const CompareTableBaseStyled = styled(CompareTableBase)`
  margin-top: unset;
`;

interface Props {
  diseasesIds: number[];
  onRemove: (id: number) => void;
}

const getFingerprintDiseaseRow = (data: DynamicContent): CellData => ({
  label: <DynamicHtml content={data} />,
  value: data.map(x => (x.type === 'text' ? x.value : '')).join(),
});

const getFingerprintDiseaseRows = (diseases: (DiseaseDetails | 'Loading')[]): FingerprintRow[] =>
  diseases
    .filter(isNotLoading)
    .map(disease => [
      { label: disease.disease, value: disease.disease, id: disease.code },
      getFingerprintDiseaseRow(disease.agentText),
      getFingerprintDiseaseRow(disease.reservoirText),
      getFingerprintDiseaseRow(disease.vectorText),
      getFingerprintDiseaseRow(disease.vehicleText),
    ]);

const fingerprintColumns = ['Agent', 'Reservoir', 'Vector', 'Vehicle'];

export const CompareFingerprintTable: FC<Props> = ({ diseasesIds, onRemove }) => {
  const diseases = useMultipleDiseaseDetails(diseasesIds);
  const [sort, setSort] = useState<SortState>({ asc: false, columnIndex: -1 });

  const renderTHead = useMemo(() => {
    const onSortClick = (index: number): void => {
      if (index === sort.columnIndex) {
        setSort({ asc: !sort.asc, columnIndex: index });
      } else {
        setSort({ asc: true, columnIndex: index });
      }
    };
    return renderFingerprintHead(fingerprintColumns, 'Disease', sort, onSortClick);
  }, [sort]);

  const rows = useMemo(() => {
    return isLoading(diseases) ? [] : getFingerprintDiseaseRows(diseases);
  }, [diseases]);

  const renderTBody = useMemo(() => renderFingerprintBody(rows, sort, onRemove), [
    rows,
    sort,
    onRemove,
  ]);

  if (isLoading(diseases)) {
    return <Loader />;
  }

  return <CompareTableBaseStyled headings={renderTHead} rows={renderTBody} fullHeight />;
};
