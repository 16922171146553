import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'DrugDetails/SingleLoadInitiated': {
      return { ...state, drugDetails: { ...state.drugDetails, [action.payload.id]: 'Loading' } };
    }
    case 'DrugDetails/SingleLoaded': {
      return {
        ...state,
        drugDetails: { ...state.drugDetails, [action.payload.id]: action.payload.data },
      };
    }
    case 'DrugDetails/SingleLoadFailed': {
      return {
        ...state,
        drugDetails: { ...state.drugDetails, [action.payload.id]: action.payload.error },
      };
    }
    case 'DrugDetails/Public/SingleLoadInitiated': {
      return {
        ...state,
        drugDetailsPublic: { ...state.drugDetailsPublic, [action.payload.id]: 'Loading' },
      };
    }
    case 'DrugDetails/Public/SingleLoaded': {
      return {
        ...state,
        drugDetailsPublic: { ...state.drugDetailsPublic, [action.payload.id]: action.payload.data },
      };
    }
    case 'DrugDetails/Public/SingleLoadFailed': {
      return {
        ...state,
        drugDetailsPublic: {
          ...state.drugDetailsPublic,
          [action.payload.id]: action.payload.error,
        },
      };
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
