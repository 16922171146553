import { ReactComponent as CheckIcon } from 'assets/UI/Check.svg';
import { ReactComponent as CombineIcon } from 'assets/UI/Combine.svg';
import { ReactComponent as StarIcon } from 'assets/UI/Star.svg';
import { ReactComponent as SurgeryIcon } from 'assets/UI/Surgery.svg';
import { Icon, SvgComponent } from 'Atoms/Icon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { CompareDrugKeyType } from 'types/compare';

interface Props {
  className?: string;
  type: CompareDrugKeyType | null;
}

const getDrugKeyIcon = (type: CompareDrugKeyType | null): SvgComponent => {
  if (type === 'Check') return CheckIcon;
  if (type === 'Combine') return CombineIcon;
  if (type === 'Star') return StarIcon;
  return SurgeryIcon;
};

const CompareDrugKeyBase: FC<Props> = ({ className, type }) => {
  return <Icon svgComponent={getDrugKeyIcon(type)} className={className} />;
};

export const CompareDrugKey = styled(CompareDrugKeyBase)`
  width: 20px;
  height: 20px;
  fill: ${props => props.theme.colors.table.compareTable.icon};
  visibility: ${props => (props.type === null ? 'hidden' : 'visible')};
`;
