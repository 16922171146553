import { ReactComponent as Close } from 'assets/Overlay/CloseX.svg';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon } from 'Atoms/Icon';
import React, { KeyboardEvent, ReactElement } from 'react';
import { ClearIndicatorProps, components } from 'react-select';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';

const IconStyled = styled(Icon)`
  width: 15px;
  height: 15px;
`;

const Button = styled(InvisibleButton)`
  cursor: pointer;
  display: flex;
`;

export const SearchSelectClearIndicator = <T extends SelectOption, isMulti extends boolean>(
  props: ClearIndicatorProps<T, isMulti>
): ReactElement => {
  const onKeyDown = (e: KeyboardEvent<HTMLButtonElement>): void => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      props.clearValue();
    }
  };

  return (
    <components.ClearIndicator {...props}>
      <Button onKeyDown={onKeyDown} onClick={props.clearValue}>
        <IconStyled svgComponent={Close} fill="inactive" />
      </Button>
    </components.ClearIndicator>
  );
};
