import { SearchSelectDropdownIndicator } from 'Atoms/select/SearchSelectDropdownIndicator';
import { SearchSelectMenuList } from 'Atoms/select/SearchSelectMenuList';
import { SearchSelectOption as OptionComponent } from 'Atoms/select/SearchSelectOption';
import React, { FC } from 'react';
import ReactSelect, { ActionMeta, OnChangeValue } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';

interface Props {
  classNamePrefix: string;
  placeholder: string;
  className?: string;
  options: SelectOption[];
  showDropdown?: boolean;
  isSearchable?: boolean;
  name?: string;
  isLoading?: boolean;
  isClearable?: boolean;
  value?: SelectOption;
  onChange?: (
    selected: OnChangeValue<SelectOption, false>,
    options: ActionMeta<SelectOption>
  ) => void;
  filterOption?: (option: FilterOptionOption<SelectOption>, inputValue: string) => boolean;
  onInputChange?: (value: string) => void;
  openMenuOnClick?: boolean;
}

const PlainSelectBase: FC<Props> = ({ classNamePrefix = 'search-select', ...rest }) => (
  <ReactSelect<SelectOption, false>
    components={{
      MenuList: SearchSelectMenuList,
      DropdownIndicator: SearchSelectDropdownIndicator,
      Option: OptionComponent,
    }}
    classNamePrefix={classNamePrefix}
    isOptionDisabled={option => !!option.disabled}
    {...rest}
  />
);

export const PlainSelect = styled(PlainSelectBase)`
  font: ${props => props.theme.fonts.smallText};

  .${props => props.classNamePrefix}__control {
    padding: 0;
    background: ${props => props.theme.colors.breadcrumb.default.backgroundColor};
    border-radius: 5px;
    cursor: pointer;
    box-shadow: none;
    transition: none;
    min-height: 30px;
    border: none;

    &:hover,
    &--is-focused,
    &--menu-is-open {
      border-color: ${props => props.theme.colors.breadcrumb.hover.borderColor};
      background: ${props => props.theme.colors.breadcrumb.hover.backgroundColor};
    }

    &--menu-is-open {
      border-radius: 5px 5px 0 0;
      border: 1px solid ${props => props.theme.colors.breadcrumb.default.borderColor};
      border-width: 1px 1px 0px 1px;

      .${props => props.classNamePrefix}__dropdown-indicator {
        transform: rotate(-180deg);
      }
    }
  }

  .${props => props.classNamePrefix}__value-container {
    padding: 0 0 0 4px;
  }

  .${props => props.classNamePrefix}__dropdown-indicator {
    transition: transform 200ms ease;
    padding: 0;

    height: 25px;
    width: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .${props => props.classNamePrefix}__dropdown-icon {
    fill: ${props => props.theme.colors.breadcrumb.default.iconColor};
    height: 6px;
    width: 10px;
  }

  .${props => props.classNamePrefix}__indicator-separator {
    display: none;
  }

  .${props => props.classNamePrefix}__indicators {
    margin-left: 3px;
  }

  .${props => props.classNamePrefix}__single-value {
    color: ${props => props.theme.colors.text.main};
    position: relative;
    top: 0;
    transform: none;
    text-overflow: initial;
    overflow: initial;
    width: 100%;
  }

  .${props => props.classNamePrefix}__input {
    color: ${props => props.theme.colors.breadcrumb.default.textColor};
    margin: 0;
    input {
      font: ${props => props.theme.fonts.smallText};
    }
  }

  .${props => props.classNamePrefix}__menu-list {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    overflow-x: hidden;
  }

  .${props => props.classNamePrefix}__menu {
    margin-top: 0;
    margin-bottom: 0;
    z-index: 10;
    border-radius: 0 0 5px 5px;
    border: solid ${props => props.theme.colors.breadcrumb.hover.borderColor};
    border-width: 0px 1px 1px 1px;
    box-shadow: none;
    background-color: ${props => props.theme.colors.background.primary};
  }

  .${props => props.classNamePrefix}__option {
    color: ${props => props.theme.colors.breadcrumb.default.selectOptionColor};
    cursor: pointer;
    padding: 5px 12px;

    &--is-focused,
    &--is-selected {
      background: rgba(127, 207, 234, 0.2);
    }

    &--is-disabled {
      opacity: 0.5;
    }
  }

  .${props => props.classNamePrefix}__option:hover {
    background: ${props => props.theme.colors.select.hover.option};
  }
`;
