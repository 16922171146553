import { CSSProperties } from 'react';
import * as DynamicContent from 'types/dynamicContent';

type ParsingContext = {
  rawData: string;
  preparedXML: string;
};

const nodeTypes = {
  ELEMENT_NODE: 1,
  ATTRIBUTE_NODE: 2,
  TEXT_NODE: 3,
  CDATA_SECTION_NODE: 4,
  ENTITY_REFERENCE_NODE: 5,
  ENTITY_NODE: 6,
  PROCESSING_INSTRUCTION_NODE: 7,
  COMMENT_NODE: 8,
  DOCUMENT_NODE: 9,
  DOCUMENT_TYPE_NODE: 10,
  DOCUMENT_FRAGMENT_NODE: 11,
  NOTATION_NODE: 12,
};

const buildDeepLink = (type: string, id: string): string => {
  switch (type) {
    case 'diseases':
      return `/explore/diseases/${id}`;
    case 'drugs':
      return `/explore/drugs/${id}`;
    case 'vaccines':
      return `/explore/vaccines/${id}`;
    case 'bacteria':
      return `/explore/microbes/bacteria/${id}`;
    case 'mycobacteria':
      return `/explore/microbes/mycobacteria/${id}`;
    case 'yeasts':
      return `/explore/microbes/yeasts/${id}`;
    case 'virus':
      return `/explore/microbes/viruses/${id}`;
    default:
      return '';
  }
};

const getTableType = (
  tagName: string,
  type: string
): DynamicContent.TableNode['tableType'] | null => {
  switch (tagName) {
    case 'gdn-outbreaks':
      return 'notableOutbreaksTable';
    case 'gdn-sporadic':
      return 'sporadicEventsTable';
    case 'gdn-crossborders':
      return 'crossbordersTable';
    case 'gdn-case-series':
      return 'caseSeriesTable';
    case 'gdn-surveys':
      switch (type) {
        case 'prevalence':
          return 'prevalenceSurveyTable';
        case 'seroprevalence':
          return 'seroprevalenceSurveyTable';
        case 'associatedInfections':
          return 'associatedInfectionsSurveyTable';
        default:
          return null;
      }
    default:
      return null;
  }
};

const getTableTitle = (
  tagName: string,
  type: string,
  diseaseId: string
): DynamicContent.TableNode['title'] => {
  switch (tagName) {
    case 'gdn-outbreaks':
      return 'Notable outbreaks';
    case 'gdn-crossborders':
      return 'Cross-border events';
    case 'gdn-case-series':
      return 'Case series';
    case 'gdn-surveys': {
      switch (type) {
        case 'seroprevalence':
          return diseaseId === '11040' ? 'HBsAg-positivity surveys' : 'Seroprevalence surveys';
        case 'prevalence':
          return 'Prevalence surveys';
        case 'associatedInfections':
          return 'Associated Infections';
        default:
          return '';
      }
    }
    default:
      return '';
  }
};

const getTableSubTitle = (tagName: string, type: string, diseaseId?: string): DynamicContent.TableNode['subTitle'] => {
  switch (tagName) {
    case 'gdn-surveys': {
      switch (type) {
        case 'seroprevalence':
          return diseaseId !== '11040' ? 'IgG unless otherwise specified' : undefined;
        default:
          return undefined;
      }
    }
    case 'gdn-case-series': {
      return 'Experimental diagnostic or treatment protocols are not included';
    }
    default:
      return undefined;
  }
};

function getChildren(children: NodeList, context: ParsingContext): DynamicContent.Node[] {
  const result: DynamicContent.Node[] = [];
  
  
  for (let i = 0; i < children.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const node = convertFromDomNode(children[i], context);
    if (node) {
      result.push(node);
    }
  }
  return result;
}

const parseStyles = (styles: string): CSSProperties =>
  styles
    .split(';')
    .map((style: string): [string, string] => {
      const [key, ...rest] = style.split(':');
      return [key, rest.join(':')];
    })
    .filter(([key, style]) => key && style)
    .map(([key, style]) => [
      key.trim().replace(/-./g, c => c.substr(1).toUpperCase()),
      style.trim(),
    ])
    .reduce(
      (styleObj, [key, style]) => ({
        ...styleObj,
        [key]: style,
      }),
      {}
    );

const mapElementAttributes = (attributes: NamedNodeMap): DynamicContent.ElementAttributes => {
  const result: DynamicContent.ElementAttributes = {};
  for (let i = 0; i < attributes.length; ++i) {
    if (attributes[i].name === 'style') {
      result['style'] = parseStyles(attributes[i].value);
    } else {
      result[attributes[i].name] = attributes[i].value;
    }
  }
  return result;
};

const parseUnsuportedTag = (
  element: Element,
  context: ParsingContext
): DynamicContent.UnsupportedNode => ({
  type: 'unsupported',
  tagName: element.tagName,
  attributes: mapElementAttributes(element.attributes),
  children: getChildren(element.childNodes, context),
});

function convertFromDomNode(node: Node, context: ParsingContext): DynamicContent.Node | null {
  switch (node.nodeType) {
    case nodeTypes.ELEMENT_NODE: {
      const elementNode = node as Element;
      switch (elementNode.tagName) {
        case 'i':
          return { type: 'i', children: getChildren(elementNode.childNodes, context) };
        case 'b':
          return { type: 'b', children: getChildren(elementNode.childNodes, context) };
        case 'u':
          return { type: 'u', children: getChildren(elementNode.childNodes, context) };
        case 'ul':
          return { type: 'ul', children: getChildren(elementNode.childNodes, context) };
        case 'li':
          return { type: 'li', children: getChildren(elementNode.childNodes, context) };
        case 'br':
          return { type: 'newLine' };
        case 'gdn-pref': {
          const value = elementNode.getAttribute('id') || '';
          return { type: 'reference p', value };
        }
        case 'gdn-mref': {
          const value = elementNode.getAttribute('id') || '';
          return { type: 'reference m', value };
        }
        case 'gdn-ref': {
          const value = elementNode.getAttribute('id') || '';
          const code = elementNode.getAttribute('code') || '';
          return { type: 'reference', value, code };
        }
        case 'gdn-charts': {
          const disease = elementNode.getAttribute('disease') || '';
          const country = elementNode.getAttribute('country') || '';
          const id = elementNode.getAttribute('id') || '';
          return {
            type: 'chart',
            id,
            diseaseId: disease,
            countryId: country,
          };
        }
        case 'gdn-link': {
          
          const type = elementNode.getAttribute('type') || '';
          const id = elementNode.getAttribute('id') || '';
          const link = buildDeepLink(type, id);
          const children = getChildren(elementNode.childNodes, context);

          if (link) {
            return {
              type: 'Link',
              to: link,
              children,
              id,
            };
          } else {
            return parseUnsuportedTag(elementNode, context);
          }
        }
        case 'gdn-outbreaks':
        case 'gdn-sporadic':
        case 'gdn-crossborders':
        case 'gdn-case-series':
        case 'gdn-surveys': {
          const diseaseId = elementNode.getAttribute('disease') || '';
          const countryId = elementNode.getAttribute('country') || '';
          const typeAttribute = elementNode.getAttribute('type') || '';
          const tableType = getTableType(elementNode.tagName, typeAttribute);
          const title = getTableTitle(elementNode.tagName, typeAttribute, diseaseId);
          const subTitle = getTableSubTitle(elementNode.tagName, typeAttribute, diseaseId);

          return tableType
            ? {
                type: 'table',
                tableType: tableType,
                diseaseId,
                countryId,
                title,
                subTitle,
              }
            : parseUnsuportedTag(elementNode, context);
        }
        case 'gdn-vaccine-schedule': {
          const diseaseId = elementNode.getAttribute('disease') || '';
          const countryId = elementNode.getAttribute('country') || '';

          return {
            type: 'vaccineSchedule',
            diseaseId,
            countryId,
          };
        }
        case 'parsererror': {
          return {
            type: 'parsererror',
            content: parseUnsuportedTag(elementNode, context),
            rawData: context.rawData,
            preparedXML: context.preparedXML,
          };
        }
        default:
          return parseUnsuportedTag(elementNode, context);
      }
    }
    case nodeTypes.TEXT_NODE: {
      const textNode = node as Text;
      return { type: 'text', value: textNode.data };
    }
    default:
      return null;
  }
}

const domParser = new DOMParser();
const parseDynamicContentFromXML = (
  xml: string,
  originalData: string
): DynamicContent.DynamicContent => {
  const document = domParser.parseFromString(xml, 'text/xml');
  const parsingContext: ParsingContext = {
    rawData: originalData,
    preparedXML: xml,
  };

  // This check is necessary for Firefox, Edge <= 18 and JSDom (used in Jest)
  if (document.documentElement.tagName === 'parsererror') {
    const errorNode = convertFromDomNode(document.documentElement, parsingContext);
    // since it's tag name is checked it is guaranteed to be not null but for type safty just in case added default
    return [errorNode || { type: 'parsererror', rawData: originalData, preparedXML: xml }];
  }

  const result = getChildren(document.documentElement.childNodes, parsingContext);
  return result;
};

//TODO - this is not ideal solution to escape less than and ampersand character. Should be fixed more properly in the future.
const escapeAmp = /&(?!(?:(?:gt)|(?:lt)|(?:amp)|(?:apos)|(?:qout));)/g;
const escapeLessThan = /(<(?!(?:gdn-.+)|\/|b|i|u|br|!|li|ul|p))/g;

const graphRegex = /{Graph\s+(\d*)(X?)(?: .*?)?}/g;
const referenceRegex = /{([a-z]+)\s(.*?)}/g;
const listRegex = /(((^|\r?\n) - .+)+(\r?\n|$))/g;
const listItemRegex = /(?:^|\r?\n) - (.+)/g;
const newLineRegex = /\r?\n/g;

const referenceReplaceFunction = (_: string, type: string, value: string): string => {
  if (type === 'p') {
    return `<gdn-pref id="${value}"></gdn-pref>`;
  } else if (type === 'm') {
    return `<gdn-mref id="${value}"></gdn-mref>`;
  } else {
    return `<gdn-ref id="${value}" code="${type}"></gdn-ref>`
  }
};

function listReplaceFunction(match: string): string {
  const result = '<ul>' + match.replace(listItemRegex, '<li>$1</li>').trimRight() + '</ul>';
  return result;
}

const replaceRelatedDiseases = (
  data: string,
  relatedDiseases: NonNullable<DynamicContent.DynamicContentContext['relatedDiseases']>
): string => {
  let newData = data;

  relatedDiseases.forEach(d => {
    newData = newData.replaceAll(
      d.disease,
      `<gdn-link id="${d.disease_code}" type="diseases">${d.disease}</gdn-link>`
    );
  });

  return newData;
};

export function parseDynamicContent(
  data: string,
  context: DynamicContent.DynamicContentContext = {}
): DynamicContent.DynamicContent {
  
  if (!data) {
    data = 'undefined';
  }
  const preparedData = `<root>${data
    .replace(escapeAmp, '&amp;')
    .replace(escapeLessThan, '&lt;')
    .replace(
      graphRegex,
      `<gdn-charts id="$1" disease="${context?.diseaseId ?? ''}" country="${
        context?.countryId ?? ''
      }"></gdn-charts>`
    )
    .replace(referenceRegex, referenceReplaceFunction)
    .replace(listRegex, listReplaceFunction)
    .replace(newLineRegex, '<br />')
    .replace(
      '{vaccine_schedule}',
      `<gdn-vaccine-schedule disease='${context?.diseaseId ?? ''}' country='${
        context?.countryId ?? ''
      }'></gdn-vaccine-schedule>`
    )}</root>`;

  const actualPreparedData = context?.relatedDiseases?.length
    ? replaceRelatedDiseases(preparedData, context.relatedDiseases)
    : preparedData;

    
    
  return parseDynamicContentFromXML(actualPreparedData, data);
}

const searchBoldSyntax = /\*\*(.+?)\*\*/g;

export function parseSearchResultDynamicContent(data: string): DynamicContent.DynamicContent {
  const preparedData = `<root>${data
    .replace(escapeAmp, '&amp;')
    .replace(escapeLessThan, '&lt;')
    .replace(searchBoldSyntax, '<b>$1</b>') // TODO might be not necessary
    .replace(graphRegex, '') // Remove graphs from search result view
    .replace(referenceRegex, referenceReplaceFunction)
    .replace(listRegex, listReplaceFunction)
    .replace(newLineRegex, '<br />')}</root>`;

  return parseDynamicContentFromXML(preparedData, data);
}
