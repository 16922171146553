import { ReactComponent as OutOfTimeSVG } from 'assets/UI/OutOfTime.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { contentClassNames, Modal } from 'Atoms/Modal';
import { ModalCloseButton } from 'Molecules/buttons/ModalCloseButton';
import React, { createContext, FC, useState } from 'react';
import { IdleTimerProvider } from 'react-idle-timer';
import { useHistory } from 'react-router';
import { localStorageFactory } from 'services/localStorage/localStorage.factory';
import { useContextAssert } from 'services/useContextAssert.hook';
import styled from 'styled-components/macro';

import { AuthTokenContext } from './AuthToken.provider';

type IdleTimer = number | null;

export const IDLESECONDS = 30 * 60 * 1000; // 30 mins

export const [IdleTimerStorageContext, IdleTimerStorageProvider] = localStorageFactory<IdleTimer>(
  'idle-timer',
  null
);

type IdleModalContext = {
  isOpen: boolean;
  setOpen: (value: boolean, link?: string) => void;
};

export const IdleModalContext = createContext<IdleModalContext | undefined>(undefined);
export const IdleModalContextProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [token, setToken] = useContextAssert(AuthTokenContext);
  const [idleTime, setIdleTime] = useContextAssert(IdleTimerStorageContext);
  const { push } = useHistory();

  const setOpen = (value: boolean): void => {
    setIsOpen(value);
  };

  return (
    <IdleModalContext.Provider value={{ isOpen, setOpen }}>
      <IdleTimerProvider
        timeout={IDLESECONDS}
        startOnMount={false}
        startManually={true}
        stopOnIdle={true}
        onIdle={() => {
          if (token) {
            setOpen(true);
            setIdleTime(null);
            setToken(null);
            push('/login');
          }
        }}
        onAction={() => {
          if (idleTime) {
            const currentTime = new Date().getTime();
            if (currentTime - idleTime >= IDLESECONDS) {
              setIdleTime(null);
              setToken(null);
              push('/login');
            }
          }
        }}
        events={[
          'keydown',
          'wheel',
          'DOMMouseScroll',
          'mousewheel',
          'mousedown',
          'touchstart',
          'touchmove',
          'MSPointerDown',
          'MSPointerMove',
          'visibilitychange',
        ]}
      >
        {children}
      </IdleTimerProvider>
    </IdleModalContext.Provider>
  );
};

export const StyledModal = styled(Modal)`
  .${contentClassNames.base} {
    max-width: 480px;
  }
`;

export const StyledCloseButton = styled(ModalCloseButton)`
  top: 23px;
  right: 20px;
  width: 20px;
  height: 20px;
`;

export const Container = styled.div`
  background-color: ${props => props.theme.colors.background.modalCardBody};
  padding: 43px 0 80px;
  border: 3px solid ${props => props.theme.colors.background.lightPopup};
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const OutOfTimeSVGBox = styled(OutOfTimeSVG)`
  width: 50px;
  height: 50px;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 30px;
  line-height: 16px;
  color: #d80027;
  margin: 40px 0;
`;

export const P = styled.p`
  margin: 0 0 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
`;

const SignInButton = styled(FilledButton)`
  width: 150px;
`;

export const IdleModal: FC = () => {
  const { isOpen, setOpen } = useContextAssert(IdleModalContext);

  return (
    // Modal should not open if the user is IP authenticated
    <StyledModal isOpen={isOpen} onClose={() => setOpen(false)}>
      <StyledCloseButton onClick={() => setOpen(false)} />
      <Container>
        <OutOfTimeSVGBox />
        <Title>Session expired</Title>
        <P>You have been signed out due to inactivity.</P>
        <SignInButton onClick={() => setOpen(false)}>Sign Back In</SignInButton>
      </Container>
    </StyledModal>
  );
};
