import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'References/LoadInitiated': {
      return action.payload.ids.reduce(
        (state, id) => {
          state[id] = 'Loading';
          return state;
        },
        { ...state }
      );
    }
    case 'References/Loaded': {
      return action.payload.reduce(
        (state, x) => {
          const id = `${x.sourceCode} ${x.referenceNumber}`;
          state[id] = x;
          return state;
        },
        { ...state }
      );
    }
    case 'References/LoadFailed': {
      return action.payload.reduce(
        (state, x) => {
          state[x.id] = x.error;
          return state;
        },
        { ...state }
      );
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
