import { StoreError } from 'store/storeError';
import { Loading, LoadingType, Resource } from 'store/types';
import { Dictionary } from 'types/common';

import { Action, State } from './provider';

const updateFields = <K extends keyof State, T extends State[K]>(
  state: State,
  fieldName: K,
  newFileds: T
): State => ({
  ...state,
  [fieldName]: {
    ...state[fieldName],
    ...newFileds,
  },
});

const mapNewStaticFields = (
  ids: number[],
  newState: LoadingType | StoreError
): Dictionary<LoadingType | StoreError> =>
  ids.reduce(
    (acc, id) => ({
      ...acc,
      [id]: newState,
    }),
    {}
  );

const mapNewFields = <T extends { id: number }>(newFields: T[]): Dictionary<Resource<T>> =>
  newFields.reduce((acc, value) => ({ ...acc, [value.id]: value }), {});

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'Compare/Diseases/LoadInitiated': {
      return updateFields(state, 'diseases', mapNewStaticFields(action.payload, Loading));
    }
    case 'Compare/Drugs/LoadInitiated': {
      return updateFields(state, 'drugs', mapNewStaticFields(action.payload, Loading));
    }
    case 'Compare/Bacteria/LoadInitiated': {
      return updateFields(state, 'bacteria', mapNewStaticFields(action.payload, Loading));
    }
    case 'Compare/Mycobacteria/LoadInitiated': {
      return updateFields(state, 'mycobacteria', mapNewStaticFields(action.payload, Loading));
    }
    case 'Compare/Yeasts/LoadInitiated': {
      return updateFields(state, 'yeasts', mapNewStaticFields(action.payload, Loading));
    }
    case 'Compare/Diseases/Loaded': {
      return updateFields(state, 'diseases', mapNewFields(action.payload));
    }
    case 'Compare/Drugs/Loaded': {
      return updateFields(state, 'drugs', mapNewFields(action.payload));
    }
    case 'Compare/Bacteria/Loaded': {
      return updateFields(state, 'bacteria', mapNewFields(action.payload));
    }
    case 'Compare/Mycobacteria/Loaded': {
      return updateFields(state, 'mycobacteria', mapNewFields(action.payload));
    }
    case 'Compare/Yeasts/Loaded': {
      return updateFields(state, 'yeasts', mapNewFields(action.payload));
    }
    case 'Compare/Diseases/LoadFailed': {
      return updateFields(
        state,
        'diseases',
        mapNewStaticFields(action.payload.ids, action.payload.error)
      );
    }
    case 'Compare/Drugs/LoadFailed': {
      return updateFields(
        state,
        'drugs',
        mapNewStaticFields(action.payload.ids, action.payload.error)
      );
    }
    case 'Compare/Bacteria/LoadFailed': {
      return updateFields(
        state,
        'bacteria',
        mapNewStaticFields(action.payload.ids, action.payload.error)
      );
    }
    case 'Compare/Mycobacteria/LoadFailed': {
      return updateFields(
        state,
        'mycobacteria',
        mapNewStaticFields(action.payload.ids, action.payload.error)
      );
    }
    case 'Compare/Yeasts/LoadFailed': {
      return updateFields(
        state,
        'yeasts',
        mapNewStaticFields(action.payload.ids, action.payload.error)
      );
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
