import { RedirectWithState } from 'Atoms/routes/RedirectWithState';
import { VariableAuthRoute } from 'Atoms/routes/VariableAuthRoute';
import { NotFound } from 'pages/404/';
import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { MicrobeRoutes } from './Microbe';
import { Microbes } from './Microbes';
import { MicrobesPublic } from './MicrobesPublic';

export const MicrobesRoutes: FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <VariableAuthRoute
      exact
      path={match.url}
      component={Microbes}
      componentPublic={MicrobesPublic}
    />
    <RedirectWithState exact path={`${match.url}/:type`} to={match.url} />
    <Route path={`${match.url}/:type/:slug`} component={MicrobeRoutes} />
    <Route component={NotFound} />
  </Switch>
);
