import { HomeLayout } from 'layouts/HomeLayout';
import { DefaulPageCheckbox } from 'Molecules/DefaulPageCheckbox';
import { ExploreFocus } from 'Molecules/exploreFocus/ExploreFocus';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';

const StyledExploreFocus = styled(ExploreFocus)`
  margin-top: 100px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-top: 33px;
  }
`;

interface Props {
  hideDefaultPageOption?: boolean;
}

export const Home: FC<Props> = ({ hideDefaultPageOption }) => (
  <HomeLayout topTitle="Explore GIDEON data" bottomTitle="What would you like to research first?">
    <Helmet>
      <title>Explore - GIDEON</title>
      <meta
        name="description"
        content="The Explore module offers a wealth of information on 361 infectious diseases, 235 countries, and 30,000+ trade names of drugs and vaccines."
      />
    </Helmet>
    <StyledExploreFocus />
    {!hideDefaultPageOption && <DefaulPageCheckbox page="explore" />}
  </HomeLayout>
);
