import { ReactComponent as AlgalSVG } from 'assets/Diseases/Algal.svg';
import { ReactComponent as ParasiticSVG } from 'assets/Diseases/Parasitic.svg';
import { ReactComponent as PrionSVG } from 'assets/Diseases/Prion.svg';
import { ReactComponent as ProtoctistalSVG } from 'assets/Diseases/Protoctistal.svg';
import { ReactComponent as UnknownSVG } from 'assets/Diseases/Unknown.svg';
import { ReactComponent as ViralSVG } from 'assets/Diseases/Viral.svg';
import { ReactComponent as BacterialSVG } from 'assets/Microbes/Bacteria.svg';
import { ReactComponent as FungalSVG } from 'assets/Microbes/Yeasts.svg';
import { DiseaseButton } from 'Molecules/buttons/MicrobeButton';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { LoginModalContext } from 'services/loginModal.service';
import { useContextAssert } from 'services/useContextAssert.hook';
import { usePushState } from 'services/usePushState.hook';
import { useAccountController } from 'store/accountStore/hooks';
import styled from 'styled-components/macro';
import { diseaseNames } from 'types/az';

const DiseasesCategories = styled.div`
  display: flex;
  margin: 30px 0;

  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    justify-content: space-between;
  }
`;

const DiseaseButtonWrapper = styled.div`
  position: relative;
  width: calc((100% - 105px) / 8);
  aspect-ratio: 1/1;
  margin-bottom: 15px;
  margin-right: 15px;
  max-width: 150px;

  &:last-of-type {
    margin-right: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    margin-right: 0;
  }
`;

interface Props {
  basePath: string;
  dataCount: number;
}

export const AZDiseasesButtons: FC<Props> = ({ basePath, dataCount }) => {
  const { push } = usePushState();
  const location = useLocation();
  const { isAuthenticated } = useAccountController();
  const { setOpen } = useContextAssert(LoginModalContext);

  const checkIfActive = (url: string): boolean => {
    return location.pathname.startsWith(url);
  };

  const diseasePath = basePath.split('/').slice(0, -1).join('/');

  const navigateToURL = (url: string): void => {
    if (!isAuthenticated) {
      setOpen(true);
      return;
    }
    checkIfActive(url) ? push(`${diseasePath}/all`) : push(url);
  };

  return (
    <DiseasesCategories>
      <DiseaseButtonWrapper>
        <DiseaseButton
          Icon={AlgalSVG}
          caption={`${diseaseNames.algal} ${
            checkIfActive(`${diseasePath}/algal`) ? '(' + dataCount + ')' : ''
          }`}
          onClick={() => navigateToURL(`${diseasePath}/algal`)}
          active={checkIfActive(`${diseasePath}/algal`)}
        />
      </DiseaseButtonWrapper>
      <DiseaseButtonWrapper>
        <DiseaseButton
          Icon={BacterialSVG}
          caption={`${diseaseNames.bacterial} ${
            checkIfActive(`${diseasePath}/bacterial`) ? '(' + dataCount + ')' : ''
          }`}
          onClick={() => navigateToURL(`${diseasePath}/bacterial`)}
          active={checkIfActive(`${diseasePath}/bacterial`)}
        />
      </DiseaseButtonWrapper>
      <DiseaseButtonWrapper>
        <DiseaseButton
          Icon={FungalSVG}
          caption={`${diseaseNames.fungal} ${
            checkIfActive(`${diseasePath}/fungal`) ? '(' + dataCount + ')' : ''
          }`}
          onClick={() => navigateToURL(`${diseasePath}/fungal`)}
          active={checkIfActive(`${diseasePath}/fungal`)}
        />
      </DiseaseButtonWrapper>
      <DiseaseButtonWrapper>
        <DiseaseButton
          Icon={ParasiticSVG}
          caption={`${diseaseNames.parasitic} ${
            checkIfActive(`${diseasePath}/parasitic`) ? '(' + dataCount + ')' : ''
          }`}
          onClick={() => navigateToURL(`${diseasePath}/parasitic`)}
          active={checkIfActive(`${diseasePath}/parasitic`)}
        />
      </DiseaseButtonWrapper>
      <DiseaseButtonWrapper>
        <DiseaseButton
          Icon={PrionSVG}
          caption={`${diseaseNames.prion} ${
            checkIfActive(`${diseasePath}/prion`) ? '(' + dataCount + ')' : ''
          }`}
          onClick={() => navigateToURL(`${diseasePath}/prion`)}
          active={checkIfActive(`${diseasePath}/prion`)}
        />
      </DiseaseButtonWrapper>
      <DiseaseButtonWrapper>
        <DiseaseButton
          Icon={ProtoctistalSVG}
          caption={`${diseaseNames.protoctistal} ${
            checkIfActive(`${diseasePath}/protoctistal`) ? '(' + dataCount + ')' : ''
          }`}
          onClick={() => navigateToURL(`${diseasePath}/protoctistal`)}
          active={checkIfActive(`${diseasePath}/protoctistal`)}
        />
      </DiseaseButtonWrapper>
      <DiseaseButtonWrapper>
        <DiseaseButton
          Icon={ViralSVG}
          caption={`${diseaseNames.viral} ${
            checkIfActive(`${diseasePath}/viral`) ? '(' + dataCount + ')' : ''
          }`}
          onClick={() => navigateToURL(`${diseasePath}/viral`)}
          active={checkIfActive(`${diseasePath}/viral`)}
        />
      </DiseaseButtonWrapper>
      <DiseaseButtonWrapper>
        <DiseaseButton
          Icon={UnknownSVG}
          caption={`${diseaseNames['unknown-agent']} ${
            checkIfActive(`${diseasePath}/unknown-agent`) ? '(' + dataCount + ')' : ''
          }`}
          onClick={() => navigateToURL(`${diseasePath}/unknown-agent`)}
          active={checkIfActive(`${diseasePath}/unknown-agent`)}
        />
      </DiseaseButtonWrapper>
    </DiseasesCategories>
  );
};
