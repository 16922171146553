import React from 'react';
import styled from 'styled-components/macro';

const StyledArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;

  border-bottom: 12px solid ${props => props.theme.colors.background.secondaryNavbar};
`;

interface Props {
  className?: string;
}
export const Arrow: React.FC<Props> = ({ className }) => {
  return <StyledArrow className={className}></StyledArrow>;
};
