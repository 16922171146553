import { createGlobalStyle } from 'styled-components/macro';
import { ScrollbarMixin } from 'utils/styleMixins';

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: 'Inter', sans-serif;
    scroll-behavior: smooth;
  }

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: background-color 0.3s linear;
    background: ${props => props.theme.colors.background.primary};
    color: ${props => props.theme.colors.text.main};
    ${ScrollbarMixin};
  }

  html, body, #root {
    height: 100%;
  }

  /* This class is applied to body when modal is open */
  .ReactModal__Body--open {
    overflow-y: hidden;
  }

  .mapboxgl-ctrl-logo,
  .mapbox-improve-map {
    visibility: hidden;
    display: none;
  }

  div[id^="groove-container"] {
    bottom: 40px !important;
    right: 10px !important;

    @media (max-width: ${props => props.theme.breakpoints.m}) {
      display: none;
    }
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  .mapboxgl-popup-content {
    background: ${props => props.theme.colors.background['lightPopup']};
    padding: 10px !important;
    border-radius: 5px !important;
    font-size: 16px;

    .mapboxgl-popup-close-button {
      display: none;
    }
  }

  .mapboxgl-popup-tip {
    border-top-color: ${props => props.theme.colors.background['lightPopup']} !important;
  }
`;

export const DisableAnimationsGlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    transition: none !important;
  }
`;
