import { getAbbreviationDetails } from 'apiServices/abbreviations/abbreviations';
import React from 'react';
import { assertIsNotStoreError, newStoreError } from 'store/storeError';
import { Dispatch, Loading } from 'store/types';
import { CustomError, ErrorCodes } from 'types/errorTypes';
import { VaccineAbbreviation } from 'types/vaccineAbbreviation';

import { AbbreviationDispatchContext, AbbreviationStateContext, Action, State } from './provider';

export const useState = (): State => {
  const state = React.useContext(AbbreviationStateContext);
  if (state === undefined) {
    throw new CustomError('AbbreviationStore', ErrorCodes.StoreNotInitialized);
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(AbbreviationDispatchContext);
  if (dispatch === undefined) {
    throw new CustomError('AbbreviationStore', ErrorCodes.StoreNotInitialized);
  }
  return dispatch;
};

export const useAbbreviationDetails = (): {
  getAbbreviation: (id: string) => VaccineAbbreviation | 'Loading';
} => {
  const state = useState();
  const dispatch = useDispatch();

  const getAbbreviation = (id: string): VaccineAbbreviation | 'Loading' => {
    const abbreviationInStore = state[id];

    if (!abbreviationInStore) {
      dispatch({ type: 'Abbreviation/SingleLoadInitiated', payload: { id } });
      getAbbreviationDetails(id)
        .then(data => dispatch({ type: 'Abbreviation/SingleLoaded', payload: { id, data } }))
        .catch(err => {
          dispatch({
            type: 'Abbreviation/SingleLoadFailed',
            payload: { id, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
    assertIsNotStoreError(abbreviationInStore);
    return abbreviationInStore || Loading;
  };

  return { getAbbreviation };
};
