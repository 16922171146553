import { ReactComponent as ArrowRightSvg } from 'assets/UI/ArrowRight.svg';
import { Icon as IconAtom, SvgComponent } from 'Atoms/Icon';
import { P } from 'Atoms/text/P';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

const Caption = styled(P)`
  flex-grow: 1;
  text-align: left;
`;

const SubCaption = styled(P)`
  flex-grow: 1;
  text-align: left;
`;

const ArrowRightIcon = styled(IconAtom)`
  width: 20px;
  height: 30px;
`;

const StyledIcon = styled(IconAtom)`
  width: 35px;
  height: 35px;
  margin-right: 16px;
`;

const Button = styled.button`
  border: none;
  position: relative;
  background: ${props => props.theme.colors.background.primary};
  padding: 22px 25px 22px 43px;
  cursor: pointer;
  border-top: 1px solid ${props => props.theme.colors.button.default.borderColor};
  border-bottom: 1px solid ${props => props.theme.colors.button.default.borderColor};
  box-sizing: border-box;

  & + button {
    border-top: 0;
  }

  ${IconAtom} {
    fill: ${props => props.theme.colors.button.default.textColor};
  }
`;

const ActiveButton = styled(Button)`
  &:active {
    padding: 20px 25px 20px 43px;
    border-top: 0px solid;
    border-color: ${props => props.theme.colors.button.active.borderColor};
    border-width: 3px;
    margin-top: -1px;

    &:first-child {
      margin-top: 0px;
    }

    ${IconAtom} {
      fill: ${props => props.theme.colors.button.active.borderColor};
    }

    ${Caption} {
      color: ${props => props.theme.colors.button.active.textColor};
    }
  }

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

interface ContentProps {
  disabled?: boolean;
}

const Content = styled.div<ContentProps>`
  display: flex;
  align-items: center;
  opacity: ${props => (props.disabled ? 0.2 : 1)};
`;

const DisabledText = styled(P)`
  width: 100%;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  && {
    font-size: 30px;
  }
`;

const TextContainer = styled.div`
  width: 100%;
`;

interface Props {
  to?: string;
  className?: string;
  caption: string;
  Icon?: SvgComponent;
  onClick?: () => void;
  disabled?: boolean;
  disabledText?: string;
  subCaption?: string;
}

export const ExploreFocusSection: FC<Props> = ({
  to,
  className,
  caption,
  Icon,
  onClick,
  disabled,
  disabledText,
  subCaption,
}) => {
  const { push } = useHistory();
  const handleClick = (): void => {
    if (to) {
      push(to);
    } else if (onClick) {
      onClick();
    }
  };

  const content = (
    <Content disabled={disabled}>
      {Icon && <StyledIcon svgComponent={Icon} />}
      <TextContainer>
        <Caption weight="500" size="medium" color="inactive">
          {caption}
        </Caption>
        {subCaption && (
          <SubCaption weight="500" size="small" color="inactive">
            {subCaption}
          </SubCaption>
        )}
      </TextContainer>
      <ArrowRightIcon svgComponent={ArrowRightSvg} />
    </Content>
  );

  return disabled ? (
    <Button onClick={handleClick}>
      {content}
      <DisabledText weight="900" color="disabledNavLink" size="normal">
        {disabledText}
      </DisabledText>
    </Button>
  ) : (
    <ActiveButton className={className} onClick={handleClick}>
      {content}
    </ActiveButton>
  );
};
