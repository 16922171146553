import React, { FC, ReactElement } from 'react';
import {
  CellMeasurer,
  CellMeasurerCache,
  List,
  ListRowProps,
  WindowScroller,
} from 'react-virtualized';
import styled from 'styled-components/macro';
import { Row } from 'types/table';

export const TableBodyCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  padding: 10px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 5px;
  }

  &:not(:first-child) {
    border-left: 1px solid ${props => props.theme.colors.table.compareTable.border};
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font: ${props => props.theme.fonts.smallText};
  }
`;

const TableBodyRow = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  position: absolute;

  background: ${props => props.theme.colors.table.row.backgroundColor};

  background: ${props => props.theme.colors.table.row.backgroundColor};

  &:nth-child(2n) {
    background: ${props => props.theme.colors.graphsTable.row.highlight};
  }

  &:hover {
    background: ${props => props.theme.colors.graphsTable.row.hover};
  }
`;

const DateCell = styled(TableBodyCell)`
  width: 15%;
  padding-left: 3px;
  min-width: 65px;
`;

const CategoryCell = styled(TableBodyCell)`
  width: 20%;
  min-width: 105px;
`;

const StyledList = styled(List)`
  &:focus {
    outline: none;
  }
`;

interface Props {
  rows: Row[];
  cache: CellMeasurerCache;
}

export const LatestDataTableBody: FC<Props> = ({ rows, cache }) => {
  const renderRow = ({ key, index, style, parent }: ListRowProps): ReactElement => {
    const row = rows[index];

    const dateCell = row[0];
    const categoryCell = row[1];
    const itemCell = row[2];

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        <TableBodyRow style={style} role="row">
          <DateCell role="cell">{dateCell.label}</DateCell>
          <CategoryCell role="cell">{categoryCell.label}</CategoryCell>
          <TableBodyCell role="cell">{itemCell.label}</TableBodyCell>
        </TableBodyRow>
      </CellMeasurer>
    );
  };

  return (
    <WindowScroller>
      {({ height, isScrolling, onChildScroll, scrollTop, width }) => (
        <StyledList
          autoHeight
          autoWidth
          height={height}
          isScrolling={isScrolling}
          rowRenderer={renderRow}
          scrollTop={scrollTop}
          onChildScroll={onChildScroll}
          rowCount={rows.length}
          rowHeight={cache.rowHeight}
          // Force width to be at least 360px so all table columns would fit
          width={Math.max(360, width)}
        />
      )}
    </WindowScroller>
  );
};
