import { DataDTO } from 'apiServices/common/DTO';
import { SimpleDisease } from 'apiServices/Diseases/DTO';
import { ToxicEffect } from 'types/toxicEffect';

export interface SimpleVaccine {
  vaccine_code: number;
  vaccine: string;
}

export interface DetailedVaccineConcise extends SimpleVaccine {
  vaccine_type: string;
}

export interface VaccineToxicEffectDTO {
  id: string;
  name: string;
}

export interface VaccineToxicity {
  id: string;
  name: string;
}

export interface VaccineContraindications {
  code: string;
  name: string;
}

export interface VaccineSynonym {
  auto_id: number;
  vaccine: string;
  code: number;
}

export const isVaccineContraindications = (value: unknown): value is VaccineContraindications => {
  const valueWithAssumedType = value as VaccineContraindications;
  return (
    typeof value === 'object' &&
    value !== null &&
    typeof valueWithAssumedType.code === 'string' &&
    typeof valueWithAssumedType.name === 'string'
  );
};

export type SimpleVaccinesDTO = DataDTO<SimpleVaccine[]>;

export type VaccineToxicityDTO = DataDTO<VaccineToxicity[]>;

export type VaccineContraindicationsDTO = DataDTO<VaccineContraindications[]>;

export interface ReferenceDTO {
  reference_number: string;
  citation: string;
  short_citation: string;
}

export interface VaccineDetailsDTO {
  vaccine_code: number;
  vaccine: string;
  mechanism_of_action: string;
  adult_dosage: string;
  child_dosage: string;
  booster: string;
  toxic_effects: (ToxicEffect | null)[];
  contraindications: (VaccineContraindications | null)[]; // TODO API probably shoudn't return null's here
  references: (ReferenceDTO | null)[]; // TODO API probably shoudn't return null's here
  synonyms: string[];
  diseases: SimpleDisease[];
}

export interface VaccineDetailsPublicDTO {
  vaccine_code: number;
  vaccine: string;
  mechanism_of_action: string;
  adult_dosage: string;
}

export type VaccineSynonymsDTO = DataDTO<VaccineSynonym[]>;

export interface VaccineCoverageYear {
  year: number;
  count: number;
}
