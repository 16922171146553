import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { DiseaseOutbreak } from 'types/disease';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'DiseaseOutbreaks/SingleLoadInitiated', { id: number }>
  | ActionWithPayload<'DiseaseOutbreaks/SingleLoaded', { id: number; data: DiseaseOutbreak[] }>
  | ActionWithPayload<'DiseaseOutbreaks/SingleLoadFailed', { id: number; error: StoreError }>;

export type State = {
  [id: number]: Resource<DiseaseOutbreak[]> | undefined;
};

const initialState: State = {};

export const {
  dispatchContext: DiseaseOutbreaksDispatchContext,
  stateContext: DiseaseOutbreaksStateContext,
  provider: DiseaseOutbreaksStoreProvider,
} = storeFactory(reducer, initialState);
