import { ReactComponent as ExploreIcon } from 'assets/Start/ExploreIcon.svg';
import { ReactComponent as LabIcon } from 'assets/Start/LabIcon.svg';
import { ReactComponent as DiagnoseDarkIcon } from 'assets/Start/StethoscopeIconDark.svg';
import { ReactComponent as DiagnoseLightIcon } from 'assets/Start/StethoscopeIconLight.svg';
import { ReactComponent as VisualizeDarkIcon } from 'assets/Start/VisualizeDark.svg';
import { ReactComponent as VisualizeLightIcon } from 'assets/Start/VisualizeLight.svg';
import { CircleButtonWithIcon, Tooltip } from 'Molecules/buttons/CircleButtonWithIcon';
import { ExploreFocusSection } from 'Molecules/exploreFocus/ExploreFocusSectionMobile';
import React, { FC } from 'react';
import { LoginModalContext } from 'services/loginModal.service';
import { useContextAssert } from 'services/useContextAssert.hook';
import { useMobile } from 'services/useMobile';
import { useAccountController } from 'store/accountStore/hooks';
import styled from 'styled-components/macro';
import { startLinks, StartPage } from 'types/startPage';

const Desktop = styled.div`
  display: flex;
  justify-content: center;
  width: 95%;
  margin: 0 auto;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 99%;
  }
`;

const StyledCircleButton = styled(CircleButtonWithIcon)`
  ${Tooltip} {
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      right: -15px;
      width: 25px;
      height: 25px;
    }
  }

  margin: 0 20px;
`;

const Mobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
`;

interface Props {
  isDarkMode: boolean;
  onClick: (to: StartPage) => void;
}

export const StartPageButtons: FC<Props> = ({ isDarkMode, onClick }) => {
  const isMobile = useMobile('s');
  const { isAuthenticated } = useAccountController();
  const { setOpen } = useContextAssert(LoginModalContext);

  const onClickWrapper = (link: StartPage): void => {
    if (!isAuthenticated) {
      setOpen(true, startLinks[link]);
    } else {
      onClick(link);
    }
  };

  return isMobile ? (
    <Mobile>
      <ExploreFocusSection
        caption="Explore"
        Icon={ExploreIcon}
        onClick={() => onClick('explore')}
        subCaption="Find information"
      />
      <ExploreFocusSection
        caption="Lab"
        Icon={LabIcon}
        onClick={() => onClickWrapper('lab')}
        disabled={!isAuthenticated}
        disabledText="SIGN IN TO ACCESS"
        subCaption="Identify pathogens"
      />
      <ExploreFocusSection
        caption="Diagnose"
        Icon={isDarkMode ? DiagnoseDarkIcon : DiagnoseLightIcon}
        onClick={() => onClickWrapper('diagnose')}
        disabled={!isAuthenticated}
        disabledText="SIGN IN TO ACCESS"
        subCaption="Diagnose diseases"
      />
      <ExploreFocusSection
        caption="Visualize"
        Icon={isDarkMode ? VisualizeDarkIcon : VisualizeLightIcon}
        onClick={() => onClick('visualize')}
        subCaption="Visualize data"
      />
    </Mobile>
  ) : (
    <Desktop>
      <StyledCircleButton
        caption="Explore"
        onClick={() => onClick('explore')}
        icon={ExploreIcon}
        tooltipInfo="Find information on diseases, historical outbreaks, and geographical distribution, drugs, vaccines, microbes, or the burden of infectious diseases in certain countries."
        topText="Find information"
        description="Explore GIDEON data on diseases, vaccines, drugs, microbes, and countries"
      />
      <StyledCircleButton
        caption="Lab"
        onClick={() => onClickWrapper('lab')}
        icon={LabIcon}
        tooltipInfo="Identify bacteria, mycobacteria, or yeasts."
        disabled={!isAuthenticated}
        disabledText="SIGN IN TO ACCESS"
        topText="Identify pathogens"
        description="Identify pathogens using Bayesian analysis tools"
      />
      <StyledCircleButton
        caption="Diagnose"
        onClick={() => onClickWrapper('diagnose')}
        icon={isDarkMode ? DiagnoseDarkIcon : DiagnoseLightIcon}
        tooltipInfo="Diagnose a disease or run a differential diagnosis simulation for a given scenario."
        disabled={!isAuthenticated}
        disabledText="SIGN IN TO ACCESS"
        topText="Diagnose diseases"
        description="Diagnose diseases using Bayesian analysis tools"
      />
      <StyledCircleButton
        caption="Visualize"
        onClick={() => onClick('visualize')}
        icon={isDarkMode ? VisualizeDarkIcon : VisualizeLightIcon}
        tooltipInfo="Create bespoke epidemiological charts, explore various outbreak and vaccine coverage maps."
        topText="Visualize data"
        description="Create bespoke epidemiological charts, explore various outbreak and vaccine coverage maps."
      />
    </Desktop>
  );
};
