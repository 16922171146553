import { useEffect, useMemo, useState } from 'react';

const noop = (): void => {
  /* noop */
};
const mockedMediaQueryList = { matches: false, addListener: noop, removeListener: noop };

export const useMedia = (mediaQuery: string): boolean => {
  const mediaQueryInstance = useMemo(
    () => (window ? window.matchMedia(mediaQuery) : mockedMediaQueryList),
    [mediaQuery]
  );

  const [match, setMatch] = useState(mediaQueryInstance.matches);

  useEffect(() => {
    const onMediaChange = (event: MediaQueryListEvent): void => {
      setMatch(event.matches);
    };
    mediaQueryInstance.addListener(onMediaChange);
    return () => {
      mediaQueryInstance.removeListener(onMediaChange);
    };
  }, [mediaQueryInstance]);

  return match;
};
