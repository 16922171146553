import { getVirusDetails, getVirusDetailsPublic } from 'apiServices/Microbes/microbes';
import React, { useEffect } from 'react';
import { newStoreError } from 'store/storeError';
import { Dispatch, Loading, Resource } from 'store/types';
import { VirusDetails, VirusDetailsPublic } from 'types/microbeDetails';

import {
  Action,
  State,
  VirusDetailsDispatchContext,
  VirusDetailsStateContext,
} from './provider';

export const useState = (): State => {
  const state = React.useContext(VirusDetailsStateContext);
  if (state === undefined) {
    throw new Error('Virus details store is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(VirusDetailsDispatchContext);
  if (dispatch === undefined) {
    throw new Error('Virus details store is not initialized');
  }
  return dispatch;
};

export const useVirusDetails = (id: number): Resource<VirusDetails> => {
  const state = useState();
  const dispatch = useDispatch();
  const virusDetails = state.details[id];

  useEffect(() => {
    if (!virusDetails) {
      dispatch({ type: 'VirusDetails/SingleLoadInitiated', payload: { id } });
      getVirusDetails(id)
        .then(data => dispatch({ type: 'VirusDetails/SingleLoaded', payload: { id, data } }))
        .catch(err => {
          dispatch({
            type: 'VirusDetails/SingleLoadFailed',
            payload: { id, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, id, virusDetails]);

  return virusDetails || Loading;
};

export const useVirusDetailsPublic = (
  id: number
): Resource<VirusDetailsPublic> => {
  const state = useState();
  const dispatch = useDispatch();
  const virusDetails = state.detailsPublic[id];

  useEffect(() => {
    if (!virusDetails) {
      dispatch({ type: 'VirusDetails/Public/SingleLoadInitiated', payload: { id } });
      getVirusDetailsPublic(id)
        .then(data =>
          dispatch({ type: 'VirusDetails/Public/SingleLoaded', payload: { id, data } })
        )
        .catch(err => {
          dispatch({
            type: 'VirusDetails/Public/SingleLoadFailed',
            payload: { id, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, id, virusDetails]);

  return virusDetails || Loading;
};
