export type StartPage = 'start' | 'explore' | 'diagnose' | 'lab' | 'visualize' | 'login';

export const startLinks: Record<StartPage, string> = {
  start: '/start',
  explore: '/explore',
  diagnose: '/diagnose',
  lab: '/lab',
  visualize: '/visualize',
  login: '/login'
};

export const isStartPage = (value: string): value is StartPage => {
  return (
    value === 'start' ||
    value === 'explore' ||
    value === 'diagnose' ||
    value === 'lab' ||
    value === 'visualize' ||
    value === 'login'
  );
};
