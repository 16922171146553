import { VariableAuthRoute } from 'Atoms/routes/VariableAuthRoute';
import { NotFound } from 'pages/404/';
import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { DiseaseRoutes } from './Disease';
import { Diseases } from './Diseases';
import { DiseasesPublic } from './DiseasesPublic';

export const DiseasesRoutes: FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <VariableAuthRoute
      exact
      path={match.url}
      component={Diseases}
      componentPublic={DiseasesPublic}
    />
    <Route path={`${match.url}/:slug`} component={DiseaseRoutes} />
    <Route component={NotFound} />
  </Switch>
);
