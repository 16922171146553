import { changeAccountPassword } from 'apiServices/account/accountSettings';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Loader } from 'Atoms/Loader';
import { P } from 'Atoms/text';
import { Toast } from 'Atoms/toast/Toast';
import { Label } from 'Molecules/account/AccountFormInput';
import { AccountModalInput } from 'Molecules/account/AccountModalInput';
import { AccountModal } from 'Molecules/modal/AccountModal';
import React, { FC, FormEvent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { InputError, useInputController } from 'services/useInputController';
import styled from 'styled-components/macro';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormButton = styled(FilledButton)`
  max-width: 140px;
  max-height: 36px;
  min-height: 36px;
`;

const StyledInput = styled(AccountModalInput)`
  margin: 10px 0 20px 0;
`;

const ErrorMessage = styled(P)`
  margin: -10px 0 10px;
`;

const LabelContainer = styled.div`
  width: 100%;
`;

interface Props {
  modalOpen: boolean;
  onModalClose: () => void;
}

const currentPasswordValidator = (inputValue: string): InputError =>
  inputValue.length > 0 ? null : 'empty';

const newPasswordValidator = (inputValue: string): InputError =>
  inputValue.length < 8 ? 'min length 8 characters' : null;

const newPassword2Validator = (
  inputValue: string,
  newPasswordValue: string | undefined
): InputError =>
  inputValue.length < 8
    ? 'min length 8 characters'
    : !(newPasswordValue === inputValue)
    ? "passwords don't match"
    : null;

export const PasswordChangeModal: FC<Props> = ({ modalOpen, onModalClose }) => {
  const { inputProps: currentPassword, error: currentPasswordError } = useInputController(
    currentPasswordValidator
  );

  const { inputProps: newPassword, error: newPasswordError } = useInputController(
    newPasswordValidator
  );

  const validateNewPassword2 = useCallback(
    (inputValue: string): InputError => newPassword2Validator(inputValue, newPassword.value),
    [newPassword.value]
  );

  const { inputProps: newPassword2, error: newPassword2Error } = useInputController(
    validateNewPassword2
  );

  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const formComplete = !currentPasswordError && !newPasswordError && !newPassword2Error;

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (isSubmiting) {
      return;
    }

    setIsSubmiting(true);

    if (formComplete && currentPassword.value && newPassword.value) {
      changeAccountPassword(currentPassword.value, newPassword.value)
        .then(() => {
          toast.success(<Toast type="success" content="Account information has been updated!" />);

          setErrorMessage(null);
          currentPassword.clear();
          newPassword.clear();
          newPassword2.clear();
          onModalClose();
        })
        .catch(err => {
          setErrorMessage(
            err.response?.status === 400
              ? 'current password is incorrect'
              : 'Password change failed'
          );
        })
        .finally(() => setIsSubmiting(false));
    }
  };

  return (
    <AccountModal isOpen={modalOpen} onClose={onModalClose} title="Change password">
      <Form onSubmit={onSubmit}>
        <LabelContainer>
          <Label htmlFor="old-password">Old password</Label>
        </LabelContainer>
        <StyledInput
          type="password"
          placeholder="Type your current password"
          {...currentPassword}
          inputCorrect={currentPassword.inputCorrect && !errorMessage}
          onChange={e => {
            currentPassword.onChange(e);
            setErrorMessage(null);
          }}
          id="old-password"
          autoComplete="current-password"
        />
        <LabelContainer>
          <Label htmlFor="new-password">New password</Label>
        </LabelContainer>
        <StyledInput
          id="new-password"
          type="password"
          placeholder="Your new password goes here"
          {...newPassword}
          autoComplete="new-password"
        />
        {newPasswordError && <ErrorMessage color="inactive">{newPasswordError}</ErrorMessage>}
        <LabelContainer>
          <Label htmlFor="repeat-new-password">Repeat new password</Label>
        </LabelContainer>
        <StyledInput
          id="repeat-new-password"
          type="password"
          placeholder="Practice run of your new password"
          {...newPassword2}
          autoComplete="new-password"
        />
        {newPassword2Error && <ErrorMessage color="inactive">{newPassword2Error}</ErrorMessage>}
        {errorMessage && <ErrorMessage color="error">{errorMessage}</ErrorMessage>}
        <FormButton type="submit" disabled={!formComplete || isSubmiting}>
          {isSubmiting ? <Loader /> : formComplete ? 'All set!' : 'Not done yet'}
        </FormButton>
      </Form>
    </AccountModal>
  );
};
