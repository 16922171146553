import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import { VaccineSummarySection } from 'Molecules/section/VaccineSummarySection';
import { getStringFromDynamicHtml } from 'Organisms/dynamicContent/DynamicHtmlToString';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import { Paywall } from 'Organisms/paywall/Paywall';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { Element } from 'react-scroll';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import { useVaccineDetailsPublic } from 'store/vaccineDetailsStore/hooks';
import { isRecordLocationState } from 'types/record';

type Props = RouteComponentProps & {
  vaccineId: number;
};

export const VaccinePublic: FC<Props> = ({ vaccineId, location }) => {
  const [showSummary, setShowSummary] = useState(true);

  const vaccine = useVaccineDetailsPublic(vaccineId);

  const synonymName = isRecordLocationState(location.state)
    ? location.state.synonymName
    : undefined;

  if (isLoading(vaccine)) {
    return <Loader />;
  }

  assertIsNotStoreError(vaccine);

  const sections: RecordLink[] = [
    'Summary',
    'Warnings',
    'Trade names',
    'Downloads and references',
  ].map(section => ({
    label: section,
    'aria-label': `${vaccine.vaccine} ${section.toLowerCase()}`,
  }));

  const description = `GIDEON - ${vaccine.vaccine}. Mechanism of Action: ${getStringFromDynamicHtml(
    vaccine.mechanismOfAction
  )}`;

  return (
    <ReferenceProvider>
      <Helmet>
        <title>{vaccine.vaccine} - GIDEON</title>
        <meta name="description" content={description} />
      </Helmet>
      <ReferenceLabelContext.Provider value={vaccine.vaccine}>
        <MainLayout>
          <RecordLayout>
            <RecordTitle title={vaccine.vaccine} synonymName={synonymName} />
            <RecordLinks links={sections} />
            <RecordSection
              link={sections[0]}
              show={showSummary}
              onCollapse={() => setShowSummary(!showSummary)}
              accent="first"
              id="vaccine-public-summary"
            >
              <VaccineSummarySection record={vaccine} />
            </RecordSection>
            <Element name="Warnings">
              <Element name="Trade names">
                <Element name="Downloads and references">
                  <Paywall />
                </Element>
              </Element>
            </Element>
          </RecordLayout>
        </MainLayout>
      </ReferenceLabelContext.Provider>
    </ReferenceProvider>
  );
};
