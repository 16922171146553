import { ReactComponent as Bacteria } from 'assets/Search/Bacteria.svg';
import { ReactComponent as Diseases } from 'assets/Search/Diseases.svg';
import { ReactComponent as Drugs } from 'assets/Search/Drugs.svg';
import { ReactComponent as Mycobacteria } from 'assets/Search/Mycobacteria.svg';
import { ReactComponent as Vaccines } from 'assets/Search/Vaccines.svg';
import { ReactComponent as Virus } from 'assets/Search/Virus.svg';
import { ReactComponent as Yeasts } from 'assets/Search/Yeasts.svg';
import { SvgComponent } from 'Atoms/Icon';
import { H2 } from 'Atoms/text';
import { SearchModule } from 'Molecules/search/SearchModule';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { ModuleSearchFacet, SearchModuleName } from 'types/search';

const SearchModulesStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 18px;
  align-items: center;
`;

const SearchModuleStyled = styled(SearchModule)`
  &:not(:first-child) {
    margin-left: 45px;
    @media (max-width: ${props => props.theme.breakpoints.l}) {
      margin-left: 15px;
    }
  }
`;

const TitleStyled = styled(H2)`
  margin: 0;
  white-space: nowrap;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    display: none;
  }
`;

const SearchFacetContainerStyled = styled.div`
  display: flex;
  flex-direction: row;

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    margin-left: 60px;
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    width: 100%;

    justify-content: center;
  }
`;

interface SearchModuleFacetDisplay {
  type: SearchModuleName;
  label: string;
  Icon: SvgComponent;
}

const searchModules: SearchModuleFacetDisplay[] = [
  {
    type: 'diseases',
    label: 'Diseases',
    Icon: Diseases,
  },
  {
    type: 'drugs',
    label: 'Drugs',
    Icon: Drugs,
  },
  {
    type: 'vaccines',
    label: 'Vaccines',
    Icon: Vaccines,
  },
  {
    type: 'bacteria',
    label: 'Bacteria',
    Icon: Bacteria,
  },
  {
    type: 'mycobacteria',
    label: 'Mycobacteria',
    Icon: Mycobacteria,
  },
  {
    type: 'viruses',
    label: 'Viruses',
    Icon: Virus,
  },
  {
    type: 'yeasts',
    label: 'Yeasts and Algae',
    Icon: Yeasts,
  },
];

interface Props {
  className?: string;
  modules: ModuleSearchFacet[];
  onClick: (type: SearchModuleName) => void;
  activeModule: SearchModuleName | null;
}

export const SearchModules: FC<Props> = ({ className, modules, onClick, activeModule }) => (
  <SearchModulesStyled className={className}>
    <TitleStyled font="Inter" size="big" weight="700" color="input">
      Found in
    </TitleStyled>
    <SearchFacetContainerStyled>
      {searchModules.map(facet => (
        <SearchModuleStyled
          key={facet.type}
          Icon={facet.Icon}
          count={modules.find(module => module.value === facet.type)?.count || 0}
          onClick={() => onClick(facet.type)}
          isActive={facet.type === activeModule}
          label={facet.label}
        />
      ))}
    </SearchFacetContainerStyled>
  </SearchModulesStyled>
);
