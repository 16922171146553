import axios from 'axios.cached';
import { CompareDisease, CompareDrug, CompareMicrobe } from 'types/compare';

import { DataDTO } from '../common/DTO';
import { mapCompareDiseasesDTO, mapCompareDrugsDTO, mapCompareMicrobeDTO } from './compare.mapper';
import { CompareDiseasesDTO, CompareDrugsDTO, CompareMicrobeDTO } from './DTO';

export const getComparedDiseases = async (
  diseaseIds: (number | string)[]
): Promise<CompareDisease[]> => {
  const diseases = await axios.get<DataDTO<CompareDiseasesDTO[]>>(
    `/api/diseases/compare?diseases=${diseaseIds.join(',')}`
  );

  return mapCompareDiseasesDTO(diseases.data.data);
};

export const getComparedDrugs = async (drugIds: (number | string)[]): Promise<CompareDrug[]> => {
  const drugs = await axios.get<DataDTO<CompareDrugsDTO[][]>>(
    `/api/drugs/compare?drugs=${drugIds.join(',')}`
  );

  return mapCompareDrugsDTO(drugs.data.data);
};

export const getComparedBacteria = async (
  bacteriaIds: (number | string)[]
): Promise<CompareMicrobe[]> => {
  const bacteria = await axios.get<DataDTO<CompareMicrobeDTO[]>>(
    `/api/microbiology/bacteria/compare?codes=${bacteriaIds.join(',')}`
  );

  return mapCompareMicrobeDTO(bacteria.data.data);
};

export const getComparedMycobacteria = async (
  mycobacteriaIds: (number | string)[]
): Promise<CompareMicrobe[]> => {
  const mycobacteria = await axios.get<DataDTO<CompareMicrobeDTO[]>>(
    `/api/microbiology/mycobacteria/compare?codes=${mycobacteriaIds.join(',')}`
  );

  return mapCompareMicrobeDTO(mycobacteria.data.data);
};

export const getComparedYeasts = async (
  yeastsIds: (number | string)[]
): Promise<CompareMicrobe[]> => {
  const yeasts = await axios.get<DataDTO<CompareMicrobeDTO[]>>(
    `/api/microbiology/yeasts/compare?codes=${yeastsIds.join(',')}`
  );

  return mapCompareMicrobeDTO(yeasts.data.data);
};
