import { ReferenceDTO } from 'apiServices/common/DTO';
import { GraphCell, GraphData } from 'Atoms/Graph';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC, Fragment, ReactNode, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { useEventListener } from 'services/useEventListener.hook';
import styled from 'styled-components/macro';
import { Chart } from 'types/chart';
import { GeneratedChart } from 'Visualize/store/chartStore/hooks';

import { TD } from './TD';
import { TH } from './TH';
import { TR } from './TR';

const Container = styled.div`
  width: 100%;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  max-width: 100%;
`;

const StyledTH = styled(TH)`
  text-align: right;
`;

interface Props {
  className?: string;
  data: GraphData;
  isTransposed?: boolean;
  renderCellValue?: (value: GraphCell) => ReactNode;
  cellWidth?: number;
  charts?: GeneratedChart[];
}

export const MultiGraphTable: FC<Props> = ({
  className,
  data,
  isTransposed,
  charts,
  renderCellValue,
  cellWidth = 70,
}) => {
  const actualData = useMemo(() => {
    if (!data[0]) {
      return [];
    }

    return isTransposed ? data[0].map((_, column) => data.map(row => row[column])) : data;
  }, [data, isTransposed]);

  const columnHeaders = useMemo(
    () => (actualData[0] ? actualData[0].filter((_, i) => i > 0) : []),
    [actualData]
  );

  const rowHeaders = useMemo(
    () => (actualData ? actualData.map(row => row.filter((_, i) => i === 0)).slice(1) : []),
    [actualData]
  );

  const body = useMemo(
    () =>
      actualData[0] ? actualData.filter((_, i) => i > 0).map(row => row.slice(1, row.length)) : [],
    [actualData]
  );

  const cellWithReference = useMemo(
    () =>
      body.map(row =>
        row.map(value => {
          const references: ReferenceDTO[] = (charts || []).reduce(
            (acc: ReferenceDTO[], chart: Chart) => {
              const chartReferences =
                chart.coordinates.find(coord => coord.y === String(value))?.references || [];
              return acc.concat(chartReferences);
            },
            []
          );
          return { label: value, reference: references };
        })
      ),
    [body, charts]
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const rowHeaderRef = useRef<HTMLTableCellElement>(null);
  const [width, setWidth] = useState<number>();

  const handleResize = (): void => {
    containerRef.current &&
      rowHeaderRef.current &&
      setWidth(containerRef.current.offsetWidth - rowHeaderRef.current.offsetWidth);
  };

  useEventListener('resize', handleResize);
  useLayoutEffect(handleResize);

  const rowLength = columnHeaders.length;
  const splitBy = width ? Math.floor(width / cellWidth) : rowLength;

  const bodyChunks = useMemo(() => {
    const tables = [];
    const chunksNeeded = Math.ceil(rowLength / splitBy);

    for (let i = 0; i < chunksNeeded; i++) {
      tables.push({
        columns: columnHeaders.slice(i * splitBy, (i + 1) * splitBy),
        rows: cellWithReference.map(row => row.slice(i * splitBy, (i + 1) * splitBy)),
      });
    }

    return tables;
  }, [body, columnHeaders, rowLength, splitBy]);

  return (
    <Container className={className} ref={containerRef}>
      <StyledTable>
        {bodyChunks.map((chunk, chunkIndex) => (
          <Fragment key={chunkIndex}>
            <thead>
              <TR>
                <StyledTH ref={chunkIndex === 0 ? rowHeaderRef : null} />
                {chunk.columns.map((column, columnIndex) => (
                  <StyledTH key={columnIndex} minWidth={cellWidth}>
                    {column}
                  </StyledTH>
                ))}
              </TR>
            </thead>
            <tbody>
              {chunk.rows.map((cell, rowIndex) => (
                <TR key={rowIndex}>
                  <StyledTH>{rowHeaders[rowIndex]}</StyledTH>
                  {cell.map((cell, cellIndex) => (
                    <TD key={cellIndex}>
                      {renderCellValue ? renderCellValue(cell.label) : cell.label}
                      <DynamicHtml
                        content={
                          cell.reference.length > 0
                            ? parseDynamicContent(
                                cell.reference.map(ref => `{${ref.reference_number}}`).join(' ')
                              )
                            : []
                        }
                      />
                    </TD>
                  ))}
                </TR>
              ))}
            </tbody>
          </Fragment>
        ))}
      </StyledTable>
    </Container>
  );
};
