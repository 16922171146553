import { Loading } from 'store/types';

import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'Vaccines/LoadInitiated': {
      return {
        ...state,
        vaccines: Loading,
      };
    }
    case 'Vaccines/Loaded':
    case 'Vaccines/LoadFailed': {
      return {
        ...state,
        vaccines: action.payload,
      };
    }
    case 'Vaccines/Toxicity/LoadInitiated': {
      return {
        ...state,
        toxicities: Loading,
      };
    }
    case 'Vaccines/Toxicity/Loaded':
    case 'Vaccines/Toxicity/LoadFailed': {
      return {
        ...state,
        toxicities: action.payload,
      };
    }
    case 'Vaccines/Contraindications/LoadInitiated': {
      return {
        ...state,
        contraindications: Loading,
      };
    }
    case 'Vaccines/Contraindications/Loaded':
    case 'Vaccines/Contraindications/LoadFailed': {
      return {
        ...state,
        contraindications: action.payload,
      };
    }
    case 'Vaccines/Filter/LoadInitiated': {
      return { ...state, filteredVaccines: Loading };
    }
    case 'Vaccines/Filter/Loaded':
    case 'Vaccines/Filter/LoadFailed': {
      return {
        ...state,
        filteredVaccines: action.payload,
      };
    }
    case 'VaccinesDetailed/LoadInitiated': {
      return {
        ...state,
        detailedVaccines: Loading,
      };
    }
    case 'VaccinesDetailed/Loaded':
    case 'VaccinesDetailed/LoadFailed': {
      return {
        ...state,
        detailedVaccines: action.payload,
      };
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
