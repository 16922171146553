import { ReactComponent as BackArrowSvg } from 'Updates/assets/BackArrow.svg';
import { ReactComponent as bacteria } from 'Updates/assets/content/Bacteria.svg';
import { ReactComponent as caseSeries } from 'Updates/assets/content/CaseSeries.svg';
import { ReactComponent as content } from 'Updates/assets/content/Content.svg';
import { ReactComponent as countries } from 'Updates/assets/content/Countries.svg';
import { ReactComponent as countryNotes } from 'Updates/assets/content/CountryNotes.svg';
import { ReactComponent as diseases } from 'Updates/assets/content/Diseases.svg';
import { ReactComponent as drugs } from 'Updates/assets/content/Drugs.svg';
import { ReactComponent as ebooks } from 'Updates/assets/content/Ebooks.svg';
import { ReactComponent as graphs } from 'Updates/assets/content/Graphs.svg';
import { ReactComponent as images } from 'Updates/assets/content/Images.svg';
import { ReactComponent as mycobacteria } from 'Updates/assets/content/Mycobacteria.svg';
import { ReactComponent as outbreak } from 'Updates/assets/content/Outbreak.svg';
import { ReactComponent as references } from 'Updates/assets/content/References.svg';
import { ReactComponent as survey } from 'Updates/assets/content/Survey.svg';
import { ReactComponent as symptoms } from 'Updates/assets/content/Symptoms.svg';
import { ReactComponent as tests } from 'Updates/assets/content/Tests.svg';
import { ReactComponent as tradeNames } from 'Updates/assets/content/TradeNames.svg';
import { ReactComponent as vaccines } from 'Updates/assets/content/Vaccines.svg';
import { ReactComponent as viruses } from 'Updates/assets/content/Viruses.svg';
import { ReactComponent as yeasts } from 'Updates/assets/content/Yeasts.svg';
import { ReactComponent as FolderSvg } from 'Updates/assets/Folder.svg';
import { ReactComponent as WorldSvg } from 'Updates/assets/World.svg';

export const updatesLightThemeIcons = {
  backArrow: BackArrowSvg,
  folder: FolderSvg,
  world: WorldSvg,
  content: {
    bacteria,
    content,
    countries,
    countryNotes,
    diseases,
    drugs,
    graphs,
    images,
    mycobacteria,
    outbreak,
    references,
    survey,
    symptoms,
    tests,
    tradeNames,
    vaccines,
    yeasts,
    ebooks,
    caseSeries,
    viruses
  },
};
