import { Icon, SvgComponent } from 'Atoms/Icon';
import { Link } from 'Atoms/links/Link';
import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';

interface Props {
  className?: string;
  caption: string;
  icon: SvgComponent;
  isActive?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const Caption = styled.div`
  font: ${props => props.theme.fonts.tinyText};
  color: ${props => props.theme.colors.button.default.textColor};
`;

const MobileNavbarLinkBase: FC<Props> = ({ className, caption, isActive, icon, onClick }) => (
  <Link noUnderline to="" className={className} isActive={isActive} onClick={onClick}>
    <Icon svgComponent={icon} />
    <Caption>{caption}</Caption>
  </Link>
);

export const MobileNavbarLink = styled(MobileNavbarLinkBase)`
  width: 86px;
  height: 76px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${props => props.theme.colors.mobileNavbarLink.default.backgroundColor};

  ${Icon} {
    width: 34px;
    height: 34px;
    margin-bottom: 10px;
    fill: ${props => props.theme.colors.mobileNavbarLink.default.textColor};
  }

  ${props =>
    props.disabled
      ? css`
          opacity: 0.2;
          cursor: pointer;
        `
      : props.isActive
      ? css`
          background: ${props => props.theme.colors.mobileNavbarLink.active.backgroundColor};
          border: 3px solid ${props => props.theme.colors.mobileNavbarLink.active.borderColor};
          border-radius: 5px;

          ${Icon} {
            fill: ${props => props.theme.colors.mobileNavbarLink.active.textColor};
          }
          ${Caption} {
            color: ${props => props.theme.colors.mobileNavbarLink.active.textColor};
          }
        `
      : ''}
`;
