import { VariableAuthRoute } from 'Atoms/routes/VariableAuthRoute';
import { NotFound } from 'pages/404/';
import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { Countries } from '../Countries/Countries';
import { CountriesPublic } from './CountriesPublic';
import { CountryRoutes } from './Country';
import { CountryBioterrorism } from './Country/CountryBioterrorism';

export const CountriesRoutes: FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <VariableAuthRoute
      exact
      path={match.url}
      component={Countries}
      componentPublic={CountriesPublic}
    />
    <Route exact path={`${match.url}/bioterrorism`} component={CountryBioterrorism} />
    <Route path={`${match.url}/:slug`} component={CountryRoutes} />
    <Route component={NotFound} />
  </Switch>
);
