import { ReactComponent as CountriesIcon } from 'assets/ExploreFocus/Countries.svg';
import { ReactComponent as DiseasesIcon } from 'assets/ExploreFocus/Diseases.svg';
import { ReactComponent as DrugsIcon } from 'assets/ExploreFocus/Drugs.svg';
import { ReactComponent as MicrobesIcon } from 'assets/ExploreFocus/Microbes.svg';
import { ReactComponent as VaccinesIcon } from 'assets/ExploreFocus/Vaccines.svg';
import { ExploreFocusSection } from 'Molecules/exploreFocus/ExploreFocusSectionMobile';
import React, { FC } from 'react';
import { useCountriesGrouped } from 'store/countriesStore/hooks';
import { useDiseasesGrouped } from 'store/diseasesStore/hooks';
import { useDrugsGrouped } from 'store/drugsStore/hooks';
import { useMicrobesGrouped } from 'store/microbesStore/hooks';
import { useVaccinesGrouped } from 'store/vaccinesStore/hooks';
import styled from 'styled-components/macro';
import { formatNullableNumber } from 'utils/formatNumber';

const Categories = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin: 33px 0 20px 0;
`;

export const AZCategoriesSelectorMobile: FC = () => {
  const { bacteria, mycobacteria, yeasts } = useMicrobesGrouped();
  const diseases = useDiseasesGrouped();
  const drugs = useDrugsGrouped();
  const vaccines = useVaccinesGrouped();
  const countries = useCountriesGrouped();

  const totalMicrobes = bacteria.total + mycobacteria.total + yeasts.total;

  return (
    <Categories>
      <ExploreFocusSection
        caption={`Diseases (${diseases.total})`}
        to="/az/diseases"
        Icon={DiseasesIcon}
      />
      <ExploreFocusSection caption={`Drugs (${drugs.total})`} to="/az/drugs" Icon={DrugsIcon} />
      <ExploreFocusSection
        caption={`Vaccines (${vaccines.total})`}
        to="/az/vaccines"
        Icon={VaccinesIcon}
      />
      <ExploreFocusSection
        caption={`Microbes (${formatNullableNumber(totalMicrobes)})`}
        to="/az/microbes"
        Icon={MicrobesIcon}
      />
      <ExploreFocusSection
        caption={`Countries (${countries.total})`}
        to="/az/countries"
        Icon={CountriesIcon}
      />
    </Categories>
  );
};
