import { ExploreFocusSection } from 'Molecules/exploreFocus/ExploreFocusSectionMobile';
import React, { FC, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';

const Categories = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin: 33px 0 20px 0;
`;

export const UpdatesSelectorMobile: FC = () => {
  const theme = useContext(ThemeContext);

  return (
    <Categories>
      <ExploreFocusSection
        caption="Latest updates"
        to="/updates/data"
        Icon={theme.images.updates.folder}
      />
      <ExploreFocusSection
        caption="Outbreaks map"
        to="/updates/outbreaks"
        Icon={theme.images.updates.world}
      />
      <ExploreFocusSection
        caption="Content by numbers"
        to="/updates/content"
        Icon={theme.images.updates.content.content}
      />
    </Categories>
  );
};
