import { InlineInputWrapper, InlineLabel, StyledInput } from 'Molecules/account/AccountFormInput';
import { Subsection } from 'Molecules/account/Subsection';
import { Props } from 'Organisms/AccountForm';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const StyledSubsection = styled(Subsection)`
  margin-bottom: 30px;
`;

const WrapperStyled = styled(InlineInputWrapper)`
  display: flex;
  flex-direction: column;

  align-items: start;
`;

const Input = styled(StyledInput)`
  width: 100%;

  margin: 5px 0 0 0;
`;

export const PersonalInformation: FC<Props> = ({ inputState, onInputChange }) => (
  <StyledSubsection title="Admin contact">
    <WrapperStyled>
      <InlineLabel htmlFor="first-name">First Name</InlineLabel>
      <Input
        type="text"
        value={inputState.firstName}
        onChange={e => onInputChange(e.target.value, 'firstName')}
        id="first-name"
        autoComplete="given-name"
      />
    </WrapperStyled>
    <WrapperStyled>
      <InlineLabel htmlFor="last-name">Last Name</InlineLabel>
      <Input
        type="text"
        value={inputState.lastName}
        onChange={e => onInputChange(e.target.value, 'lastName')}
        id="last-name"
        autoComplete="family-name"
      />
    </WrapperStyled>
  </StyledSubsection>
);
