import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'Outbreaks/SingleLoadInitiated': {
      return { ...state, outbreaks: { ...state.outbreaks, [action.payload.id]: 'Loading' } };
    }
    case 'Outbreaks/SingleLoaded': {
      return {
        ...state,
        outbreaks: { ...state.outbreaks, [action.payload.id]: action.payload.data },
      };
    }
    case 'Outbreaks/SingleLoadFailed': {
      return {
        ...state,
        outbreaks: { ...state.outbreaks, [action.payload.id]: action.payload.error },
      };
    }
    case 'Outbreaks/Public/SingleLoadInitiated': {
      return {
        ...state,
        outbreaksPublic: { ...state.outbreaksPublic, [action.payload.id]: 'Loading' },
      };
    }
    case 'Outbreaks/Public/SingleLoaded': {
      return {
        ...state,
        outbreaksPublic: { ...state.outbreaksPublic, [action.payload.id]: action.payload.data },
      };
    }
    case 'Outbreaks/Public/SingleLoadFailed': {
      return {
        ...state,
        outbreaksPublic: { ...state.outbreaksPublic, [action.payload.id]: action.payload.error },
      };
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
