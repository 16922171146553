import { getComparePDFUrl } from 'apiServices/Pdf/pdf';
import { sendComparePDF } from 'apiServices/Pdf/pdfMail';
import { DownloadButton } from 'Molecules/buttons/DownloadButton';
import { SquareDownloadButton, SquareMailButton } from 'Molecules/PdfButtons';
import { MailPDFButtonForm } from 'Organisms/mailPdf/MailPDFButtonForm';
import React, { FC, useCallback } from 'react';
import { useDownloadPDF } from 'services/useDownloadPDF';
import { useEmailPDF } from 'services/useEmailPDF';
import { useMobile } from 'services/useMobile';
import styled from 'styled-components/macro';
import { CompareCategory } from 'types/pdf';

const Wrapper = styled.div`
  margin: 25px 0;
  display: flex;
  flex-wrap: wrap;
`;

const StyledDownloadButton = styled(DownloadButton)`
  margin: 15px 15px 0 0;
  max-width: 230px;
  height: 50px;
`;

const StyledMailButton = styled(MailPDFButtonForm)`
  margin: 15px 15px 0 0;
`;
interface Props {
  className?: string;
  category: CompareCategory;
  ids: number[];
}

export const CompareButtonsSection: FC<Props> = ({ className, category, ids }) => {
  const { email, ...restEmailProps } = useEmailPDF(() => sendComparePDF(category, ids, email));
  const isMobile = useMobile('s');

  const getPDFUrl = useCallback(() => getComparePDFUrl(category, ids), [category, ids]);
  const { onClick } = useDownloadPDF(getPDFUrl);

  if (isMobile) {
    return (
      <Wrapper className={className}>
        <SquareDownloadButton disabled={!category || !ids} onClick={onClick} label="Download" />
        <SquareMailButton email={email} {...restEmailProps} />
      </Wrapper>
    );
  }

  return (
    <Wrapper className={className}>
      <StyledDownloadButton disabled={!category || !ids} onClick={onClick} />
      <StyledMailButton email={email} {...restEmailProps} />
    </Wrapper>
  );
};
