import { P } from 'Atoms/text';
import { Gallery } from 'Molecules/ImageGallery';
import React, { useEffect, useRef } from 'react';
import ImageGallery from 'react-image-gallery';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Image } from 'types/image';

const Container = styled.div`
  margin-top: 16px;
  display: flex;
`;

interface Props {
  images?: Image[];
}

export const ImagesSection: React.FC<Props> = ({ images = [] }) => {
  const imagesArr = images.map(({ url, provider, title, description }) => ({
    original: url,
    thumbnail: url,
    thumbnailTitle: provider,
    originalTitle: title,
    description,
  }));

  const galleryRef = useRef<ImageGallery>(null);

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash.startsWith('#image-') && galleryRef.current) {
      const imageId = hash.split('-')[1];
      const index = images.findIndex(image => image.imageFileName.startsWith(imageId));
      galleryRef.current.slideToIndex(index);
    }
  }, [images, location.hash]);

  if (images.length <= 0) {
    return <P>No images are available for this disease</P>;
  }

  return (
    <Container>
      <Gallery images={imagesArr} ref={galleryRef} />
    </Container>
  );
};
