import { Loader } from 'Atoms/Loader';
import { SurveyTable } from 'Organisms/SurveyTable';
import React, { FC } from 'react';
import { useCountrySeroprevalenceSurveys } from 'store/countryNotesStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';

interface Props {
  diseaseId: number;
  countryId: string;
  title?: string;
  subTitle?: string;
}

const footnote1 = '* indicates publication year (not necessarily year of survey)';

export const SeroprevalenceSurveys: FC<Props> = ({ diseaseId, countryId, title, subTitle }) => {
  const outbreaksData = useCountrySeroprevalenceSurveys(diseaseId, countryId);
  assertIsNotStoreError(outbreaksData);
  if (isLoading(outbreaksData)) {
    return <Loader />;
  }

  if (!outbreaksData || outbreaksData.length === 0) {
    return <></>;
  }

  return (
    <SurveyTable
      surveyData={outbreaksData}
      footnote1={footnote1}
      title={title}
      subTitle={subTitle}
    />
  );
};
