import axios from 'axios.cached';
import { LatestOutbreak } from 'types/countryLatestOutbreaks';

import { DataDTO } from '../common/DTO';
import { LatestOutbreakDTO } from './DTO';

export const getCountryLatestOutbreaks = async (code: string): Promise<LatestOutbreak[]> => {
  const relevantDiseases = await axios.get<DataDTO<LatestOutbreakDTO[]>>(
    code === 'G90'
      ? `/api/diseases/bioterrorism/latest-outbreaks?return=simple`
      : `/api/diseases/countries/${code}/latest-outbreaks?return=simple`
  );

  const maxTimeStamp = relevantDiseases.data.timestamp??0;
  const data =  relevantDiseases.data.data.map(x => ({
    countryCode: x.country_code,
    cases: x.cases,
    city: x.city,
    country: x.country_code === 'G100' ? 'Worldwide' : x.country,
    deaths: x.deaths,
    disease: x.disease,
    diseaseCode: x.disease_code,
    end: x.outbreak_end,
    publicationYear: x.publication_year,
    region: x.region,
    start: x.outbreak_start,
    state: x.state,
    timestamp:maxTimeStamp,
  }))

  return data;
};
