import { Link } from 'Atoms/links/Link';
import React, { FC } from 'react';
import { useGroupReference } from 'services/referenceProvider/hooks';
import { ReferenceNode } from 'types/dynamicContent';

interface Props {
  className?: string;
  data: ReferenceNode[];
  href?: string;
}

export const ReferenceGroupMarker: FC<Props> = ({ className, data }) => {
  let Identifiers = '';
  for (let i = 0; i < data.length; i++) {
    const ref = data[i];
    if (i !== 0) {
      Identifiers += '|';
    }
    Identifiers += `${ref.code} ${ref.value}`;
  }
  const { data: RefData } = useGroupReference(Identifiers);

  return (
    <>
      {RefData.map((item, index) => (
        <sup className={className} key={'MultiGroup' + index}>
          <Link to="#" onClick={item.openGroupReference} size="reference" noUnderline>
            [{item.refLabel}]
          </Link>
          &nbsp;
        </sup>
      ))}
    </>
  );
};
