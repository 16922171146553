import { P } from 'Atoms/text';
import React, { ReactElement } from 'react';
import { components, MultiValueGenericProps } from 'react-select';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';

const { MultiValueLabel } = components;

const StyledP = styled(P)`
  white-space: normal;
  word-break: break-word;
`;

export const SearchSelectMultiValueLabel = <T extends SelectOption, isMulti extends boolean>({
  children,
  ...props
}: MultiValueGenericProps<T, isMulti>): ReactElement => (
  <MultiValueLabel {...props}>
    <StyledP color="secondary">{children}</StyledP>
  </MultiValueLabel>
);
