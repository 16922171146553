import { ExternalLink } from 'Atoms/links/ExternalLink';
import { P, Props as PProps } from 'Atoms/text/P';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const PStyled = styled(P)`
  margin: 10px 0;
`;

interface Props {
  className?: string;
  color?: PProps['color'];
}

export const ReCaptchaNoticeText: FC<Props> = ({ className, color }) => (
  <PStyled className={className} size="small" color={color}>
    This site is protected by reCAPTCHA and the Google{' '}
    <ExternalLink color={color} size="small" to="https://policies.google.com/privacy">
      Privacy Policy
    </ExternalLink>{' '}
    and{' '}
    <ExternalLink color={color} size="small" to="https://policies.google.com/terms">
      Terms of Service
    </ExternalLink>{' '}
    apply
  </PStyled>
);
