import { DiseaseDetailsDTO, RelatedNotesDTO } from 'apiServices/Diseases/DTO';
import axios from 'axios.cached';
import { CountryNotes, CountryNotesPublic } from 'types/countryNotes';

import { CaseSeriesDTO, CrossbordersDTO, DataDTO, OutbreaksDTO, SurveyDTO } from '../common/DTO';
import { mapCountryNotesDTO, mapCountryNotesPublicDTO } from './countryNotes.mapper';
import { AllCountryNoteSectionsDTO, CountryNotesDTO, CountryNotesPublicDTO, VaccineScheduleDTO } from './DTO';
import { mapWorldwideNotesDTO } from './worldwideNotes.mapper';

export const getCountryNotes = async (
  diseaseId: number,
  countryId: string
): Promise<CountryNotes> => {
  const [
    allNotes,
    countryNotes,
    prevalence,
    seroprevalence,
    crossborders,
    outbreaks,
    vaccineSchedule,
    diseaseDetails,
    relatedNotes,
    caseSeries,
  ] = await Promise.all([
    axios.get<DataDTO<AllCountryNoteSectionsDTO>>(`/api/diseases/${diseaseId}/distribution/${countryId}/sections`),
    axios.get<DataDTO<CountryNotesDTO>>(`/api/diseases/${diseaseId}/distribution/${countryId}`),
    axios.get<DataDTO<SurveyDTO[]>>(
      `/api/diseases/${diseaseId}/distribution/${countryId}/surveys/prevalence`
    ),
    axios.get<DataDTO<SurveyDTO[]>>(
      `/api/diseases/${diseaseId}/distribution/${countryId}/surveys/seroprevalence`
    ),
    axios.get<DataDTO<CrossbordersDTO>>(
      `/api/diseases/${diseaseId}/distribution/${countryId}/crossborders`
    ),
    axios.get<DataDTO<OutbreaksDTO[]>>(
      `/api/diseases/${diseaseId}/distribution/${countryId}/outbreaks`
    ),
    axios.get<DataDTO<VaccineScheduleDTO[]>>(
      `/api/travel/countries/${countryId}/diseases/${diseaseId}/vaccine-schedule`
    ),
    axios.get<DataDTO<DiseaseDetailsDTO>>(`/api/diseases/${diseaseId}/general`),
    axios.get<DataDTO<RelatedNotesDTO>>(
      `/api/diseases/${diseaseId}/distribution/${countryId}/related_notes`
    ),
    axios.get<DataDTO<CaseSeriesDTO[]>>(
      `/api/diseases/${diseaseId}/distribution/${countryId}/case-series`
    ),
  ]);

  // typescript only allows 10 item in tupple 
  const sporadic = await axios.get<DataDTO<OutbreaksDTO[]>>(
    `/api/diseases/${diseaseId}/distribution/${countryId}/sporadic-events`
  )

  const maxTimeStamp = Math.max(
    allNotes.data.timestamp??0,
    countryNotes.data.timestamp ?? 0,
    prevalence.data.timestamp ?? 0,
    seroprevalence.data.timestamp ?? 0,
    crossborders.data.timestamp ?? 0,
    outbreaks.data.timestamp ?? 0,
    vaccineSchedule.data.timestamp?? 0,
    diseaseDetails.data.timestamp?? 0,
    relatedNotes.data.timestamp??0,
    caseSeries.data.timestamp?? 0,
    sporadic.data.timestamp?? 0
  );

  let associatedInfections = null;
  if (diseaseId === 10040) {
    associatedInfections = await axios.get<DataDTO<SurveyDTO[]>>(
      `/api/diseases/${diseaseId}/distribution/${countryId}/associated-infections`
    );
  }

  if (associatedInfections && associatedInfections.data.data.length > 0) {
    if (countryNotes.data.data.note) {
      countryNotes.data.data.note = countryNotes.data.data.note.replace('<b>Associated Infections</b>',
        `<gdn-surveys type='associatedInfections' disease='${diseaseId}' country='${countryId}'></gdn-surveys>`);
      countryNotes.data.data.note = countryNotes.data.data.note.replace('<b>Associated infections</b>',
        `<gdn-surveys type='associatedInfections' disease='${diseaseId}' country='${countryId}'></gdn-surveys>`);
      countryNotes.data.data.note = countryNotes.data.data.note.replace('<b>associated infections</b>',
        `<gdn-surveys type='associatedInfections' disease='${diseaseId}' country='${countryId}'></gdn-surveys>`);
    }
  }

  // if (caseSeries && caseSeries.data.data.length > 0) {
  //   if (countryNotes.data.data.note) {
  //     countryNotes.data.data.note = countryNotes.data.data.note.replace('<b>Case Series</b>',
  //       `<gdn-case-series disease='${diseaseId}' country='${countryId}'></gdn-case-series>`);
  //     countryNotes.data.data.note = countryNotes.data.data.note.replace('<b>Case series</b>',
  //       `<gdn-case-series disease='${diseaseId}' country='${countryId}'></gdn-case-series>`);
  //     countryNotes.data.data.note = countryNotes.data.data.note.replace('<b>case series</b>',
  //       `<gdn-case-series disease='${diseaseId}' country='${countryId}'></gdn-case-series>`);
  //   }
  // }
  
  return countryId === 'G100'
    ? mapWorldwideNotesDTO(
        allNotes.data.data,
        countryNotes.data.data,
        prevalence.data.data,
        seroprevalence.data.data,
        crossborders.data.data,
        outbreaks.data.data,
        sporadic.data.data,
        maxTimeStamp,
        diseaseDetails.data.data,
        relatedNotes.data.data,
        associatedInfections ? associatedInfections.data.data : [],
        caseSeries.data.data
      )
    : mapCountryNotesDTO(
      allNotes.data.data,
        countryNotes.data.data,
        prevalence.data.data,
        seroprevalence.data.data,
        crossborders.data.data,
        outbreaks.data.data,
        sporadic.data.data,
        maxTimeStamp,
        vaccineSchedule.data.data,
        relatedNotes.data.data,
        associatedInfections ? associatedInfections.data.data : [],
        caseSeries.data.data
      );
};

export const getCountryNotesPublic = async (
  diseaseId: number,
  countryId: string
): Promise<CountryNotesPublic> => {
  const countryNotes = await axios.get<DataDTO<CountryNotesPublicDTO>>(
    `/api/public/diseases/${diseaseId}/distribution/${countryId}`
  );

  return mapCountryNotesPublicDTO(countryNotes.data.data);
};
