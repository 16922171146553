import { P, Props as PProps } from 'Atoms/text/P';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { groupDataAlphabeticallyFromStrings } from 'utils/getGroupedData';

const Wrapper = styled.div`
  column-count: 5;
  column-width: 200px;
  column-gap: 20px;
`;

const Group = styled.div`
  break-inside: avoid-column;
  display: table;
  margin: 20px 0 0 0;
  &:first-child {
    margin-top: 0;
  }
`;

const GroupTitle = styled(P).attrs(
  (): Partial<PProps> => ({
    weight: '700',
    size: 'normal',
  })
)``;

const GroupItem = styled(P).attrs(
  (): Partial<PProps> => ({
    size: 'normal',
  })
)``;

interface Props {
  data: string[];
}

export const TradeNamesSection: FC<Props> = ({ data }) => {
  const groupedList = groupDataAlphabeticallyFromStrings(data, true);
  return (
    <Wrapper>
      {Object.keys(groupedList)
        .sort()
        .map((key: string) => (
          <Group key={key}>
            <GroupTitle>{key}</GroupTitle>
            {groupedList[key].map(item => (
              <GroupItem key={item.toString()}>{item}</GroupItem>
            ))}
          </Group>
        ))}
    </Wrapper>
  );
};
