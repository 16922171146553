import { ReactComponent as MailIcon } from 'assets/Button/Mail.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { Loader } from 'Atoms/Loader';
import { contentClassNames, Modal } from 'Atoms/Modal';
import { ModalCloseButton } from 'Molecules/buttons/ModalCloseButton';
import React, { FC, useEffect, useState } from 'react';
import { EmailPDFprops } from 'services/useEmailPDF';
import { useTooltip } from 'services/useTooltip';
import { useAccountController } from 'store/accountStore/hooks';
import styled, { css } from 'styled-components/macro';

import { EmailForm, Input, SendButton } from './EmailForm';
import { EmailErrorTitle, EmailSentTitle } from './MailPDFButtonForm';

const StyledIcon = styled(Icon)`
  margin: 0 15px 0 0;
`;

const StyledModal = styled(Modal)`
  .${contentClassNames.base} {
    max-width: 550px;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
    }
  }
`;

interface ModalContainerProps {
  emailSent: boolean;
  isLoading: boolean;
  hasError: boolean;
}

const ModalContainer = styled.div<ModalContainerProps>`
  background-color: ${props => props.theme.colors.background.modalCardBody};
  padding: 25px 50px;

  border: 2px solid ${props => props.theme.colors.background.lightPopup};
  border-radius: 4px;
  min-height: 116px;

  ${props =>
    props.emailSent &&
    css`
      ${Input} {
        opacity: 0;
      }
      ${SendButton} {
        opacity: 0;
        z-index: -1;
      }
      ${EmailErrorTitle} {
        opacity: 0;
        z-index: 0;
      }
      ${EmailSentTitle} {
        opacity: 1;
        z-index: 1;
      }
    `}

  ${props =>
    props.hasError &&
    css`
      ${Input} {
        opacity: 0;
      }
      ${SendButton} {
        opacity: 0;
        z-index: -1;
      }
      ${EmailErrorTitle} {
        opacity: 1;
        z-index: 1;
      }
    `}
`;

const StyledCloseButton = styled(ModalCloseButton)`
  top: 15px;
  right: 15px;
`;

const StyledEmailForm = styled(EmailForm)`
  height: 60px;
  ${Input} {
    font: ${props => props.theme.fonts.mediumTextSemiBold};
  }
  ${SendButton} {
    padding: 9px 0;
  }
`;

interface Props extends EmailPDFprops {
  className?: string;
  label?: string;
}

export const MailPDFModalForm: FC<Props> = ({
  className,
  label = 'Email PDF',
  sendPDFemail,
  email,
  onEmailChange,
  emailSent,
  isLoading,
  error,
  resetState,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [props, popper, setElement] = useTooltip('Send up to 10 emails in any 24h period');

  const { tokenPayload } = useAccountController();
  const isInsitutional = !!tokenPayload?.user.institutionId;

  const ariaProps = isInsitutional ? {} : props.aria;

  useEffect(() => {
    if (emailSent) {
      setTimeout(() => {
        setModalOpen(false);
        resetState();
      }, 3000);
    }
  }, [emailSent, resetState]);

  return (
    <>
      <div {...props.events}>
        <FilledButton
          onClick={() => setModalOpen(true)}
          className={className}
          {...ariaProps}
          ref={setElement}
        >
          <StyledIcon svgComponent={MailIcon} size="small" />
          {label}
        </FilledButton>
        {!isInsitutional && popper}
      </div>

      <StyledModal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalContainer emailSent={emailSent} isLoading={isLoading} hasError={!!error}>
          <StyledCloseButton onClick={() => setModalOpen(false)} />
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <EmailSentTitle size="medium" role="alert" aria-live="polite">
                Email sent successfully!
              </EmailSentTitle>
              <EmailErrorTitle size="medium">{error}</EmailErrorTitle>
              <StyledEmailForm
                sendPDFemail={sendPDFemail}
                email={email}
                onEmailChange={onEmailChange}
              />
            </>
          )}
        </ModalContainer>
      </StyledModal>
    </>
  );
};
