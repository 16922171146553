import { storeFactory } from 'store/storeFactory';
import { Action as GenericAction } from 'store/types';

import { reducer } from './reducer';

export type Action = GenericAction<'MapControl/ToggleAccessibility'>;

export type State = {
  accessibility: boolean;
};

const initialState: State = {
  accessibility: false,
};

export const {
  dispatchContext: MapControlStoreDispatchContext,
  stateContext: MapControlStoreStateContext,
  provider: MapControlStoreProvider,
} = storeFactory(reducer, initialState);
