import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';

const Top = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

interface Props {
  className?: string;
  title: ReactElement;
}

export const Section: FC<Props> = ({ className, title, children }) => {
  return (
    <div className={className}>
      <Top>{title}</Top>
      {children}
    </div>
  );
};
