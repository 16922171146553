import { ColoredBox } from 'Atoms/ColoredBox';
import { P, Span } from 'Atoms/text';
import { CompleteTooltip } from 'Atoms/tooltip/CompleteTooltip';
import React, { FC, ReactNode } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { Phenotype } from 'types/microbeDetails';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex-direction: column;
  }
`;

const SectionTop = styled.div`
  display: flex;
`;

const StyledColoredBox = styled(ColoredBox)`
  margin-right: 10px;
`;

const SectionBottom = styled.div`
  margin-top: 20px;
`;

const StyledP = styled(P)`
  margin-bottom: 10px;
`;

const Section = styled.div`
  min-width: 200px;

  margin: 0 20px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 20px 0 0 0;
  }
`;

const NoPhenotypesText = styled(Span)`
  opacity: 0.8;
`;

const Tooltip = styled(CompleteTooltip)`
  position: unset;
  margin: 0 0 0 auto;
`;

interface PhenotypeProps {
  className?: string;
  phenotypes: Phenotype[];
  title: string;
  accent: keyof DefaultTheme['colors']['comparisonColors'];
  tooltipContent?: ReactNode;
}

const PhenotypesRowSection: FC<PhenotypeProps> = ({
  className,
  phenotypes,
  title,
  accent,
  tooltipContent,
}) => {
  return (
    <Section className={className}>
      <SectionTop>
        <StyledColoredBox comparisonAccent={accent} />
        <Span weight="600"> {title}</Span>
        {tooltipContent && <Tooltip content={tooltipContent} />}
      </SectionTop>
      <SectionBottom>
        {phenotypes.length > 0 ? (
          phenotypes.map(x => <StyledP key={x.code}>{x.phenotype}</StyledP>)
        ) : (
          <NoPhenotypesText color="inactive" fontStyle="italic">
            No phenotypic tests found
          </NoPhenotypesText>
        )}
      </SectionBottom>
    </Section>
  );
};

interface Props {
  phenotypes: Phenotype[];
}

export const MicrobePhenotypesSection: FC<Props> = ({ phenotypes }) => {
  const always = phenotypes.filter(x => x.percent && x.percent === 100);
  const moreThan90 = phenotypes.filter(x => x.percent && x.percent > 90 && x.percent < 100);
  const from10To90 = phenotypes.filter(x => x.percent && x.percent >= 10 && x.percent <= 90);
  const lessThan10 = phenotypes.filter(x => x.percent && x.percent > 0 && x.percent < 10);
  const never = phenotypes.filter(x => x.percent !== null && x.percent === 0);

  return (
    <Wrapper>
      <PhenotypesRowSection
        title="Always"
        phenotypes={always}
        accent="Always"
        tooltipContent="Reported in all isolates"
      />
      <PhenotypesRowSection
        title="&gt; 90%"
        phenotypes={moreThan90}
        accent="Usually"
        tooltipContent="Reported in 90-99% of all isolates"
      />
      <PhenotypesRowSection
        title="10 - 90%"
        phenotypes={from10To90}
        accent="Often"
        tooltipContent="Reported in 10-90% of all isolates"
      />
      <PhenotypesRowSection
        title="&lt; 10%"
        phenotypes={lessThan10}
        accent="Rarely"
        tooltipContent="Reported in 0.01-10% of all isolates"
      />
      <PhenotypesRowSection
        title="Never"
        phenotypes={never}
        accent="Never"
        tooltipContent="Not reported"
      />
    </Wrapper>
  );
};
