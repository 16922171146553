import { ReactComponent as CloseSvg } from 'assets/Overlay/CloseX.svg';
import { Icon } from 'Atoms/Icon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const IconStyled = styled(Icon)`
  width: 9px;
  height: 9px;
`;

interface Props {
  className?: string;
  onClick?: () => void;
}

const Button: FC<Props> = ({ className, onClick }) => (
  <button className={className} onClick={onClick} aria-label="Close">
    <IconStyled svgComponent={CloseSvg} />
  </button>
);

export const CloseButton = styled(Button)`
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;

  border: 1px solid ${props => props.theme.colors.button.active.borderColor};
  ${IconStyled} {
    fill: ${props => props.theme.colors.button.active.borderColor};
  }

  &:hover {
    border-color: ${props => props.theme.colors.button.hover.borderColor};
    ${IconStyled} {
      fill: ${props => props.theme.colors.button.hover.borderColor};
    }
  }
`;
