import { ReactComponent as ArrowIcon } from 'assets/UI/ArrowDown.svg';
import { Icon } from 'Atoms/Icon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const ArrowDown = styled(Icon)`
  width: 13px;
  height: 11px;
  fill: ${props => props.theme.colors.table.compareTable.sortButton};
`;

const ArrowUp = styled(ArrowDown)`
  transform: rotate(180deg);
`;

export interface Props {
  className?: string;
  onClick?: () => void;
  clicked?: boolean;
  asc?: boolean;
  color?: 'dark' | 'light';
  label?: string;
}

const SortButtonBase: FC<Props> = ({ className, onClick, clicked, asc, label }) => {
  return (
    <button
      className={className}
      onClick={onClick}
      aria-label={`Sort by ${label && `${label} in`} ${getAriaSort(false, !asc)} order`}
    >
      {(!clicked || asc) && <ArrowUp svgComponent={ArrowIcon} />}
      {(!clicked || !asc) && <ArrowDown svgComponent={ArrowIcon} />}
    </button>
  );
};

export const getAriaSort = (
  isNone: boolean,
  isAscending: boolean
): 'none' | 'ascending' | 'descending' => {
  if (isNone) {
    return 'none';
  } else if (isAscending) {
    return 'ascending';
  } else {
    return 'descending';
  }
};

export const SortButton = styled(SortButtonBase)`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 15px;
  height: 25px;
  ${ArrowDown} {
    fill: ${props =>
      props.color === 'light'
        ? props.theme.colors.table.sortButton
        : props.theme.colors.table.compareTable.sortButton};
  }

  &:focus {
    outline: 2px solid ${props => props.theme.colors.focus};
  }
`;
