import { ReactComponent as CheckSVG } from 'assets/UI/Check.svg';
import { ReactComponent as CrossSVG } from 'assets/UI/Cross.svg';
import { Icon } from 'Atoms/Icon';
import { AccountInput, Props as AccountInputProps } from 'Atoms/input/AccountInput';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

interface InputProps {
  inputCorrect?: boolean;
}

const StyledInput = styled(AccountInput)<InputProps>`
  width: 100%;

  ${Icon} {
    fill: ${props =>
      props.inputCorrect
        ? props.theme.colors.datepicker.focus
        : props.theme.colors.datepicker.border};
  }
`;

interface Props extends AccountInputProps {
  inputCorrect: boolean;
}

export const AccountModalInput: FC<Props> = ({ inputCorrect, ...props }) => {
  return (
    <StyledInput icon={inputCorrect ? CheckSVG : CrossSVG} inputCorrect={inputCorrect} {...props} />
  );
};
