import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload } from 'store/types';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'LegalModal/TermsAndConditions', boolean>
  | ActionWithPayload<'LegalModal/LicenseAgreement', boolean>
  | ActionWithPayload<'LegalModal/Privacy', boolean>;

export type State = {
  termsAndConditionsModal: boolean;
  licenseAgreementModal: boolean;
  privacyModal: boolean;
};

const initialState: State = {
  termsAndConditionsModal: false,
  licenseAgreementModal: false,
  privacyModal: false,
};

export const {
  dispatchContext: LegalModalDispatchContext,
  stateContext: LegalModalStateContext,
  provider: LegalModalProvider,
} = storeFactory(reducer, initialState);
