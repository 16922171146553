import { Span } from 'Atoms/text';
import React, { FC } from 'react';
import { formatNumber } from 'utils/formatNumber';

interface Props {
  className?: string;
  resultCount: number;
}

const getSearchResultText = (count: number): string => {
  if (count <= 0) {
    return 'No search results.';
  } else if (count === 1) {
    return '1 search result.';
  } else return `${formatNumber(count)} search results.`;
};

export const SearchResultText: FC<Props> = ({ className, resultCount }) => (
  <Span role="alert" aria-live="polite" className={className}>
    {getSearchResultText(resultCount)}
  </Span>
);
