import { confirmAccountEmail } from 'apiServices/account/accountSettings';
import { ReactComponent as SuperheroSvg } from 'assets/EmailConfirm/Superhero.svg';
import { Icon } from 'Atoms/Icon';
import { Link } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { H1, P } from 'Atoms/text';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const Logo = styled.img`
  display: block;
  width: auto;
  height: 25px;
`;

const LogoLink = styled(Link)`
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);

  display: block;
  &:hover {
    opacity: 0.9;
  }
`;

const Content = styled.div`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(H1)`
  margin: 0;
`;

const StyledIcon = styled(Icon)`
  width: 65px;
  height: 65px;
  margin: 70px 0;
`;

const StyledLink = styled(Link)`
  margin-top: 50px;
`;

const ConfirmEmailSuccess: FC = () => {
  return (
    <>
      <Title color="inactive" size="big" weight="700">
        ALL DONE!
      </Title>
      <StyledIcon svgComponent={SuperheroSvg} />
      <P>You have successfully changed your email address.</P>
      <StyledLink to="/" weight="700">
        Go back to GIDEON
      </StyledLink>
    </>
  );
};

const ConfirmEmailFail: FC = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <Title color="inactive" size="big" weight="700">
        OH NO!
      </Title>
      <StyledIcon svgComponent={theme.images.brokenLink} />
      <P>It appears your email confirmation link has expired.</P>
      <StyledLink to="/account" weight="700">
        Generate a new one from your account menu
      </StyledLink>
    </>
  );
};

export const ConfirmEmail: FC = () => {
  const theme = useContext(ThemeContext);
  const location = useLocation();
  const hash = location.hash.split('#token=');
  const token = hash.length > 1 ? hash[1] : null;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      confirmAccountEmail(token)
        .then(() => {
          setIsSuccess(true);
        })
        .catch(() => {
          setIsSuccess(false);
        })
        .finally(() => setIsLoading(false));
    }
  }, [token]);

  if (!token) {
    return <Redirect to="/404" />;
  }

  return (
    <Container>
      <LogoLink to="/">
        <Logo src={theme.images.logo} alt="Gideon logo" />
      </LogoLink>
      {isLoading ? (
        <Loader />
      ) : (
        <Content>{isSuccess ? <ConfirmEmailSuccess /> : <ConfirmEmailFail />}</Content>
      )}
    </Container>
  );
};
