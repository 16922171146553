import { ModalCloseButton } from 'Molecules/buttons/ModalCloseButton';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  position: relative;
  background: ${props => props.theme.colors.background.primary};
  margin: 4px 5px;
  padding: 18px;
  width: 300px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  border: 2px solid ${props => props.theme.colors.recordBorder.second};
`;

const StyledCloseButton = styled(ModalCloseButton)`
  width: 15px;
  height: 15px;
  top: 15px;
  right: 15px;
`;

interface Props {
  className?: string;
  children: ReactNode;
  onClose?: () => void;
}

export const LightTooltip: FC<Props> = ({ className, children, onClose }) => {
  return (
    <Wrapper className={className}>
      <StyledCloseButton onClick={onClose} />
      {children}
    </Wrapper>
  );
};
