import { DiseaseDetailsDTO, RelatedNotesDTO } from 'apiServices/Diseases/DTO';
import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { AllSectionReturnType, CountryNotes } from 'types/countryNotes';

import { CaseSeriesDTO, CrossbordersDTO, OutbreaksDTO, SurveyDTO } from '../common/DTO';
import { mapCaseSeriesDTO, mapReferenceDTO } from '../common/mappers';
import { mapCrossbordersDTO, mapOutbreaksDTO, mapSurveyDTO } from '../common/mappers';
import { mapVaccineScheduleDTO } from './countryNotes.mapper';
import { AllCountryNoteSectionsDTO, CountryNotesDTO } from './DTO';

export function AllSectionDTO(x:AllCountryNoteSectionsDTO):AllSectionReturnType{
  return {
    background:x.background?parseDynamicContent(x.background || ''):undefined,
    graphs:x.graphs?.length?x.graphs.map(g => parseDynamicContent(g)):[],
    vaccinations:x.vaccinations?.length?  mapVaccineScheduleDTO(x.vaccinations):undefined,
    vaccinations_intro:x.vaccinations_intro? parseDynamicContent(x.vaccinations_intro || ''):undefined,
    vaccination_graphs:x.vaccination_graphs?.length?x.vaccination_graphs.map(vg=>parseDynamicContent(vg)):undefined,
    case_series: x.case_series? parseDynamicContent(x.case_series || ''):undefined,
    background_intro: x.background_intro ?parseDynamicContent(x.background_intro||''):undefined,
    crossborder:x.crossborder ?parseDynamicContent(x.crossborder || ''):undefined,
    crossborder_intro:x.crossborder_intro ? parseDynamicContent(x.crossborder_intro || ''):undefined,
    outbreaks:x.outbreaks ? parseDynamicContent(x.outbreaks || ''):undefined,
    sporadic:x.sporadic_events? parseDynamicContent(x.sporadic_events || ''):undefined,
    outbreaks_intro:x.outbreaks_intro ?parseDynamicContent(x.outbreaks_intro || ''):undefined,
    sporadic_intro:x.sporadic_intro ?parseDynamicContent(x.sporadic_intro || ''):undefined,
    reference:x.references && parseDynamicContent(x.references.map(ref => `{${ref.reference_number}}`).join(' ')) || [],
    related_countries:x.related_countries ? x.related_countries.map(c => ({
      id: c.country_code,
      name: c.country,
    })):undefined,
    reviews_and_updates:x.reviews_and_updates?parseDynamicContent(x.reviews_and_updates || ''):undefined,
    reviews_and_updates_intro:x.reviews_and_updates_intro ?parseDynamicContent(x.reviews_and_updates_intro || ''):undefined,
    // surveys: x.surveys ? x.surveys.map(x => parseDynamicContent(x)):undefined,
    prevalence: x.surveys ? x.surveys.filter(p=> p.includes(`type='prevalence'`)).map(x => parseDynamicContent(x)):undefined,
    seroprevalence: x.surveys ? x.surveys.filter(p=> p.includes(`type='seroprevalence'`)).map(x => parseDynamicContent(x)):undefined,


  }
}

export const mapWorldwideNotesDTO = (
  all:AllCountryNoteSectionsDTO,
  x: CountryNotesDTO,
  prevalence: SurveyDTO[],
  seroprevalence: SurveyDTO[],
  crossborders: CrossbordersDTO,
  outbreaks: OutbreaksDTO[],
  sporadic: OutbreaksDTO[],
  timestamp:number|undefined,
  diseaseDetails: DiseaseDetailsDTO,
  relatedNotes: RelatedNotesDTO,
  associatedInfections: SurveyDTO[],
  caseSeries: CaseSeriesDTO[],
): CountryNotes => ({
  allSection:AllSectionDTO(all),
  note: parseDynamicContent(x.note || '', {
    relatedDiseases: diseaseDetails.related_diseases,
  }),
  references: x.references.map(mapReferenceDTO),
  prevalence: prevalence.map(x => mapSurveyDTO(x)),
  seroprevalence: seroprevalence.map(x => mapSurveyDTO(x)),
  crossborders: crossborders.events.map(x => mapCrossbordersDTO(x)),
  outbreaks: outbreaks.map(x => mapOutbreaksDTO(x)),
  sporadic: sporadic.map(x => mapOutbreaksDTO(x)),
  relatedCountries: relatedNotes.related_countries.map(x => ({
    id: x.country_code,
    name: x.country,
  })),
  relatedDiseases: relatedNotes.related_diseases.map(x => ({
    id: x.disease_code,
    name: x.disease,
  })),
  timestamp:timestamp,
  associatedInfections: associatedInfections.map(x => mapSurveyDTO(x)),
  caseSeries: caseSeries.map(x => mapCaseSeriesDTO(x)),
});
