import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { CountryDetails, CountryDetailsPublic, CountryDisease } from 'types/countryDetails';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'CountryDetails/Details/SingleLoadInitiated', { id: string }>
  | ActionWithPayload<'CountryDetails/Details/SingleLoaded', { id: string; data: CountryDetails }>
  | ActionWithPayload<'CountryDetails/Details/SingleLoadFailed', { id: string; error: StoreError }>
  | ActionWithPayload<'CountryDetails/Diseases/SingleLoadInitiated', { id: string }>
  | ActionWithPayload<
      'CountryDetails/Diseases/SingleLoaded',
      { id: string; data: CountryDisease[] }
    >
  | ActionWithPayload<'CountryDetails/Diseases/SingleLoadFailed', { id: string; error: StoreError }>
  | ActionWithPayload<'CountryDetails/Public/SingleLoadInitiated', { id: string }>
  | ActionWithPayload<
      'CountryDetails/Public/SingleLoaded',
      { id: string; data: CountryDetailsPublic }
    >
  | ActionWithPayload<'CountryDetails/Public/SingleLoadFailed', { id: string; error: StoreError }>;

export type CountryDetailsState = {
  details: Resource<CountryDetails> | undefined;
  diseases: Resource<CountryDisease[]> | undefined;
  detailsPublic: Resource<CountryDetailsPublic> | undefined;
};

export type State = {
  [id: string]: CountryDetailsState;
};

const initialState: State = {};

export const {
  dispatchContext: CountryDetailsDispatchContext,
  stateContext: CountryDetailsStateContext,
  provider: CountryDetailsStoreProvider,
} = storeFactory(reducer, initialState);
