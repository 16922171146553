import { css } from 'styled-components/macro';

export const ScrollbarMixin = css`
  &::-webkit-scrollbar {
    width: 10px; /* for vertical scrollbar */
    height: 10px; /* for horizontal scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.scrollbar.track};
  }
  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollbar.thumb};
  }

  /* For Firefox browsers */
  scrollbar-color: ${props =>
    props.theme.colors.scrollbar.thumb + ' ' + props.theme.colors.scrollbar.track};
  scrollbar-width: 10px;
`;
