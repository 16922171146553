import { getFingerprintPDFUrl } from 'apiServices/Pdf/pdf';
import { sendFingerprintPDF } from 'apiServices/Pdf/pdfMail';
import { Loader } from 'Atoms/Loader';
import { contentClassNames } from 'Atoms/Modal';
import { ErrorBoundary } from 'errorBoundary';
import { ContentError } from 'Molecules/ContentError';
import {
  Content,
  ModalHeader,
  StyledClosedButton,
  StyledModal,
  Title,
} from 'Molecules/exploreLanding/ExploreLandingModal';
import { FingerprintSearchResults } from 'Molecules/FingerprintSearchResults';
import { SquareDownloadButton, SquareMailButton } from 'Molecules/PdfButtons';
import { AsyncSelectWithTooltip, SelectWithTooltip } from 'Molecules/SelectWithTooltip';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { ActionMeta, OnChangeValue } from 'react-select';
import { useCountrySelectOptions } from 'services/useCountrySelectOptions';
import { useDownloadPDF } from 'services/useDownloadPDF';
import { useEmailPDF } from 'services/useEmailPDF';
import { useTouchScreen } from 'services/useMobile';
import { useDiseasesList } from 'store/diseasesStore/hooks';
import { useFingerprintDiseasesResource } from 'store/fingerprintDiseasesStore/hooks';
import { useFingerprintResource } from 'store/fingerprintPropertiesStore/hooks';
import { assertIsNotStoreError, isStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled, { ThemeContext } from 'styled-components/macro';
import { SelectedFingerprintState } from 'types/fingerprint';
import { SelectOption } from 'types/select';
import { countriesSelectFilter, onCountryInputChange } from 'utils/countriesSelectFilter';
import { getOriginalCountryCode } from 'utils/customCountryCodes';
import { mapListsToSelectOptions } from 'utils/mapListsToSelectOptions';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ParametersWrapper = styled.div`
  width: 100%;
`;

const Parameters = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: -20px;
`;

const Dropdown = styled(SelectWithTooltip)`
  margin: 20px 5px 0 5px;
  flex-grow: 1;
`;

const AsyncDropdown = styled(AsyncSelectWithTooltip)`
  margin: 20px 5px 0 5px;
  flex-grow: 1;

  .${props => props.classNamePrefix}__control--menu-is-open {
    .${props => props.classNamePrefix}__single-value {
      display: none;
    }
  }
`;

const ExploreLandingModal = styled(StyledModal)`
  .${contentClassNames.base} {
    max-width: 90%;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      max-width: 100%;
    }
  }
`;

const StickyButtons = styled.div`
  position: absolute;
  top: 80vh;
  right: 0;
  display: flex;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`;

const DownloadButton = styled(SquareDownloadButton)`
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 115px;
    min-width: 115px;
  }
`;

const MailButton = styled(SquareMailButton)`
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 115px;
    min-width: 115px;
  }
`;

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: none;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
    justify-content: flex-start;
  }
`;

const additionalCountries = [
  { id: 'null', name: 'Any' },
  { id: 'G90', name: 'Bioterrorism' },
];

interface FingerprintModalBaseProps {
  selectedState: SelectedFingerprintState;
  onSelectedStateChange: (newState: SelectedFingerprintState) => void;
  onClickPDFDownload: () => void;
  labelPDFDownload: string;
}

const FingerprintModalBase: FC<FingerprintModalBaseProps> = ({
  selectedState,
  onSelectedStateChange,
  onClickPDFDownload,
  labelPDFDownload,
}) => {
  const theme = useContext(ThemeContext);
  const isTouchScreen = useTouchScreen();
  const fingerprintResource = useFingerprintResource();
  const [filteredDiseasesState, applyFilter, updateState] = useFingerprintDiseasesResource();
  const { email, ...restEmailProps } = useEmailPDF(() => sendFingerprintPDF(selectedState, email));

  const diseases = useDiseasesList();

  const { countriesOptions, loadCountriesOptions } = useCountrySelectOptions({
    countries: additionalCountries,
  });

  const onSelectChange = useCallback(
    (selected: OnChangeValue<SelectOption, false>, options: ActionMeta<SelectOption>): void => {
      const name = options ? options.name : undefined;
      if (selected && name) {
        const updatedState = {
          ...selectedState,
          [name]: selected.value === 'null' ? null : selected.value,
        };

        onSelectedStateChange(updatedState);
        if (
          !updatedState.agent &&
          !updatedState.vector &&
          !updatedState.vehicle &&
          !updatedState.reservoir &&
          !updatedState.country
        ) {
          updateState(diseases);
        } else {
          applyFilter(updatedState);
        }
      }
    },
    [selectedState, onSelectedStateChange, applyFilter, updateState, diseases]
  );

  const handleCountryChange = (selected: OnChangeValue<SelectOption, false>): void => {
    if (selected) {
      const updatedState = {
        ...selectedState,
        country: selected.value === 'null' ? null : getOriginalCountryCode(selected.value),
      };

      onSelectedStateChange(updatedState);
      if (
        !updatedState.agent &&
        !updatedState.vector &&
        !updatedState.vehicle &&
        !updatedState.reservoir &&
        !updatedState.country
      ) {
        updateState(diseases);
      } else {
        applyFilter(updatedState);
      }
    }
  };

  useEffect(() => {
    updateState(diseases);
  }, [updateState, diseases]);

  assertIsNotStoreError(fingerprintResource);

  if (isLoading(fingerprintResource)) {
    return <Loader />;
  }

  const [agents, vectors, vehicles, reservoirs] = mapListsToSelectOptions(
    fingerprintResource.agents,
    fingerprintResource.vectors,
    fingerprintResource.vehicles,
    fingerprintResource.reservoirs
  );

  const results =
    !isLoading(filteredDiseasesState) &&
    !isStoreError(filteredDiseasesState) &&
    filteredDiseasesState
      ? filteredDiseasesState
      : [];

  return (
    <>
      <ParametersWrapper>
        <Parameters>
          <Dropdown
            caption="Agent"
            placeholder="Choose agent"
            selectOptions={agents}
            tooltipInfo="Classification (e.g., virus, parasite) and taxonomic designation."
            onChange={onSelectChange}
            value={selectedState.agent}
            name="agent"
            isSearchable={!isTouchScreen}
            icon={theme.images.fingerprint.agent}
            id="agent-dropdown"
          />
          <Dropdown
            caption="Vector"
            placeholder="Choose vector"
            selectOptions={vectors}
            tooltipInfo="An arthropod or other living carrier which transports an infectious agent from an infected organism or reservoir to a susceptible individual or immediate surroundings."
            onChange={onSelectChange}
            value={selectedState.vector}
            name="vector"
            isSearchable={!isTouchScreen}
            icon={theme.images.fingerprint.vector}
            id="vector-dropdown"
          />
          <Dropdown
            caption="Vehicle"
            placeholder="Choose vehicle"
            selectOptions={vehicles}
            tooltipInfo="The mode of transmission for an infectious agent. This generally implies a passive and inanimate (i.e., non-vector) mode."
            onChange={onSelectChange}
            value={selectedState.vehicle}
            name="vehicle"
            isSearchable={!isTouchScreen}
            icon={theme.images.fingerprint.vehicle}
            id="vehicle-dropdown"
          />
          <Dropdown
            caption="Reservoir"
            placeholder="Choose reservoir"
            selectOptions={reservoirs}
            tooltipInfo="Any animal, arthropod, plant, soil or substance in which an infectious agent normally lives and multiplies, on which it depends primarily for survival, and where it reproduces itself in such a manner that it can be transmitted to a susceptible host."
            onChange={onSelectChange}
            value={selectedState.reservoir}
            name="reservoir"
            isSearchable={!isTouchScreen}
            icon={theme.images.fingerprint.reservoir}
            id="reservoir-dropdown"
          />
          <AsyncDropdown
            caption="Country"
            classNamePrefix="explore-countries-select"
            placeholder="Search country records"
            loadOptions={loadCountriesOptions}
            onChange={handleCountryChange}
            onInputChange={value => onCountryInputChange(value, countriesOptions)}
            cacheOptions
            defaultOptions={countriesOptions}
            showDropdown
            filterOption={countriesSelectFilter}
            value={countriesOptions.find(x =>
              selectedState.country === null
                ? x.value === 'null'
                : x.value === selectedState.country
            )}
            isSearchable={!isTouchScreen}
            icon={theme.images.fingerprint.country}
            inputId="country-dropdown"
          />
        </Parameters>
      </ParametersWrapper>
      <FingerprintSearchResults
        total={diseases.length}
        results={results}
        isLoading={isLoading(filteredDiseasesState)}
      />
      <ButtonsWrapper>
        <DownloadButton onClick={onClickPDFDownload} label={labelPDFDownload} />
        <MailButton email={email} {...restEmailProps} />
      </ButtonsWrapper>
    </>
  );
};

export const FingerprintModal: FC<Props> = ({ isOpen, onClose }) => {
  const [selectedState, setSelectedState] = useState<SelectedFingerprintState>({
    agent: null,
    vector: null,
    vehicle: null,
    reservoir: null,
    country: null,
  });
  const { email, ...restEmailProps } = useEmailPDF(() => sendFingerprintPDF(selectedState, email));

  const getPDFUrl = useCallback(() => getFingerprintPDFUrl(selectedState), [selectedState]);
  const { onClick, label } = useDownloadPDF(getPDFUrl);

  return (
    <ExploreLandingModal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>
        <StyledClosedButton onClick={onClose} aria-label="Modal close" />
        <Title font="Quicksand" weight="500" color="mainBold">
          Running a fingerprint search for diseases
        </Title>
        <StickyButtons>
          <SquareDownloadButton onClick={onClick} label={label} />
          <SquareMailButton email={email} {...restEmailProps} />
        </StickyButtons>
      </ModalHeader>

      <Content>
        <ErrorBoundary error={error => <ContentError title="FingerPrintModal" {...error} />}>
          <FingerprintModalBase
            selectedState={selectedState}
            onSelectedStateChange={newState => setSelectedState(newState)}
            onClickPDFDownload={onClick}
            labelPDFDownload={label}
          />
        </ErrorBoundary>
      </Content>
    </ExploreLandingModal>
  );
};
