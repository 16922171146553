import { useState } from 'react';

export interface EmailPDFprops {
  email: string;
  onEmailChange: (email: string) => void;
  sendPDFemail: () => void;
  isLoading: boolean;
  error: string;
  emailSent: boolean;
  resetState: () => void;
}

export const useEmailPDF = (sendPDF: (email: string) => Promise<void>): EmailPDFprops => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const resetState = (): void => {
    setEmail('');
    setError('');
    setEmailSent(false);
  };

  const sendPDFemail = (): void => {
    setIsLoading(true);
    sendPDF(email)
      .then(() => {
        setEmailSent(true);
      })
      .catch(err => {
        if (err.response?.status === 429) {
          setError('Email limit exceeded');
        } else {
          setError('Failed to send email');
        }
        setEmailSent(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    email,
    onEmailChange: email => setEmail(email),
    sendPDFemail,
    isLoading,
    error,
    emailSent,
    resetState,
  };
};
