import React, { ReactElement } from 'react';
import { parseDynamicContent } from 'services/parser/dynamicContentParser';

import { DynamicHtml } from './DynamicHtml';

export interface Props {
  contentString: string;
}

export const DynamicHtmlFromString = React.memo(
  ({ contentString }: Props): ReactElement => {
    const dynamicContent = parseDynamicContent(contentString);

    return <DynamicHtml content={dynamicContent} />;
  }
);
