import Axios from 'axios';
import React, { FC, SetStateAction, useMemo } from 'react';
import { ContextValue, useStorage } from 'services/localStorage/localStorage.factory';

type MagicTokenContextType = ContextValue<string | null>;

const setAxiosAuthorizationHeader = (value: string | null): void => {
  Axios.defaults.headers.common['Authorization'] = value ? `Bearer ${value}` : null;
};

export const MagicTokenContext = React.createContext<MagicTokenContextType>(undefined);
export const MagicTokenProvider: FC = ({ children }) => {
  const [magictoken, setMagicToken] = useStorage<string | null>(
    window.localStorage,
    'magictoken',
    null,
    setAxiosAuthorizationHeader
  );

  const contextValue: MagicTokenContextType = useMemo(() => {
    const set = (value: SetStateAction<string | null>): void => {
      setAxiosAuthorizationHeader(typeof value === 'function' ? value(magictoken) : value);

      setMagicToken(value);
    };

    return [magictoken, set];
  }, [setMagicToken, magictoken]);

  return <MagicTokenContext.Provider value={contextValue}>{children}</MagicTokenContext.Provider>;
};
