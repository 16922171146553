import { getAriaSort, SortButton } from 'Atoms/buttons/SortButton';
import { Icon } from 'Atoms/Icon';
import { Span } from 'Atoms/text';
import React, { FC } from 'react';
import { useMobile } from 'services/useMobile';
import styled from 'styled-components/macro';
import { ColumnHeader } from 'Visualize/types/graphTable';

const TableHeader = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  position: sticky;
  top: 0;

  background: ${props => props.theme.colors.graphsTable.head.desktop.background};
  z-index: 400;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    background: ${props => props.theme.colors.graphsTable.head.mobile.background};
  }
`;

export const TableHeaderRow = styled.div`
  display: flex;
  flex-direction: row;

  border: 1px solid ${props => props.theme.colors.graphsTable.row.highlight};
  border-bottom: 2px solid ${props => props.theme.colors.table.compareTable.border};
  border-radius: 2px 2px 0 0;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    border-bottom: none;
  }
`;

export const TableHeaderColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  white-space: nowrap;

  width: 100%;
  padding: 20px 10px;
  background: ${props => props.theme.colors.background.primary};

  &:not(:first-child) {
    border-left: 1px solid ${props => props.theme.colors.table.compareTable.border};
    padding: 10px;
  }
`;

const StyledSpan = styled(Span)`
  color: ${props => props.theme.colors.graphsTable.head.desktop.text};
  ${Icon} {
    fill: ${props => props.theme.colors.graphsTable.head.desktop.text};
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font: ${props => props.theme.fonts.smallTextBold};
    color: ${props => props.theme.colors.graphsTable.head.mobile.text};

    ${Icon} {
      fill: ${props => props.theme.colors.graphsTable.head.mobile.text};
    }
  }
`;

const SortButtonStyled = styled(SortButton)`
  margin: 0 0 0 10px;
  ${Icon} {
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      fill: ${props => props.theme.colors.graphsTable.head.mobile.text};
      opacity: 0.9;
    }
  }
`;

interface Props {
  className?: string;
  columns: ColumnHeader[];
  onClickSort: (index: number) => void;
  asc: boolean;
  columnIndex: number;
}

export const GraphsTableHead: FC<Props> = ({
  className,
  columns,
  onClickSort,
  asc,
  columnIndex,
}) => {
  const isMobile = useMobile('s');

  return (
    <TableHeader role="rowgroup" className={className}>
      <TableHeaderRow role="row">
        {columns.map((column, i) => (
          <TableHeaderColumn
            role="columnheader"
            key={i}
            aria-sort={getAriaSort(i !== columnIndex, asc)}
          >
            <StyledSpan color="mainBold" weight="600">
              {isMobile && column.labelMobile ? column.labelMobile : column.label}
            </StyledSpan>
            {column.isSortable && (
              <SortButtonStyled
                asc={asc}
                clicked={i === columnIndex}
                onClick={() => onClickSort(i)}
                label={typeof column.label === 'string' ? column.label : undefined}
              />
            )}
          </TableHeaderColumn>
        ))}
      </TableHeaderRow>
    </TableHeader>
  );
};
