import 'react-toastify/dist/ReactToastify.min.css';

import React, { FC } from 'react';
import { ToastContainer as BaseToastContainer } from 'react-toastify';
import styled from 'styled-components/macro';

const StyledToastContainer = styled(BaseToastContainer)`
  top: 66px;

  .Toastify__toast--info {
    background: ${props => props.theme.colors.toast.info.background};
  }

  .Toastify__toast--success {
    background: ${props => props.theme.colors.toast.success.background};
  }

  .Toastify__toast--error {
    background: ${props => props.theme.colors.toast.error.background};
  }

  .Toastify__toast--warning {
    background: ${props => props.theme.colors.toast.warning.background};
  }

  .Toastify__close-button--info {
    opacity: 1;

    &:focus {
      outline: 2px solid ${props => props.theme.colors.toast.info.outline};
    }

    svg {
      fill: ${props => props.theme.colors.toast.info.text};
    }
  }

  .Toastify__close-button--success {
    opacity: 1;

    &:focus {
      outline: 2px solid ${props => props.theme.colors.toast.success.outline};
    }

    svg {
      fill: ${props => props.theme.colors.toast.success.text};
    }
  }

  .Toastify__close-button--error {
    opacity: 1;

    &:focus {
      outline: 2px solid ${props => props.theme.colors.toast.error.outline};
    }

    svg {
      fill: ${props => props.theme.colors.toast.error.text};
    }
  }

  .Toastify__close-button--warning {
    opacity: 1;

    &:focus {
      outline: 2px solid ${props => props.theme.colors.toast.warning.outline};
    }

    svg {
      fill: ${props => props.theme.colors.toast.warning.text};
    }
  }
`;

export const ToastContainer: FC = () => <StyledToastContainer autoClose={10000} />;
