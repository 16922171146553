import { contentClassNames, Modal } from 'Atoms/Modal';
import { P } from 'Atoms/text';
import { ModalCloseButton } from 'Molecules/buttons/ModalCloseButton';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

const StyledModal = styled(Modal)`
  .${contentClassNames.base} {
    max-width: 550px;
  }
`;

const Container = styled.div`
  background-color: ${props => props.theme.colors.background.modalCardBody};
  padding: 25px 50px;

  border: 2px solid ${props => props.theme.colors.background.lightPopup};
  border-radius: 4px;
`;

const StyledCloseButton = styled(ModalCloseButton)`
  top: 15px;
  right: 15px;
`;

const Title = styled(P)`
  border-bottom: 1px solid ${props => props.theme.colors.accent[7]};
  padding-bottom: 5px;
  margin-bottom: 20px;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
}

export const AccountModal: FC<Props> = ({ isOpen, onClose, children, title }) => {
  return (
    <StyledModal isOpen={isOpen} onClose={onClose}>
      <Container>
        <StyledCloseButton onClick={onClose} />
        <Title weight="600">{title}</Title>
        {children}
      </Container>
    </StyledModal>
  );
};
