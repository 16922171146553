import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { Action as GenericAction, ActionWithPayload, Resource } from 'store/types';
import { DrugClass, DrugInteraction, DrugSpectrum, DrugToxicity } from 'types/drug';

import { reducer } from './reducer';

export type Action =
  | GenericAction<'Drugs/LoadInitiated'>
  | ActionWithPayload<'Drugs/Loaded', DrugWithSlug[]>
  | ActionWithPayload<'Drugs/LoadFailed', StoreError>
  | GenericAction<'Drugs/Toxicity/LoadInitiated'>
  | ActionWithPayload<'Drugs/Toxicity/Loaded', DrugToxicity[]>
  | ActionWithPayload<'Drugs/Toxicity/LoadFailed', StoreError>
  | GenericAction<'Drugs/Interaction/LoadInitiated'>
  | ActionWithPayload<'Drugs/Interaction/Loaded', DrugInteraction[]>
  | ActionWithPayload<'Drugs/Interaction/LoadFailed', StoreError>
  | GenericAction<'Drugs/Classes/LoadInitiated'>
  | ActionWithPayload<'Drugs/Classes/Loaded', DrugClass[]>
  | ActionWithPayload<'Drugs/Classes/LoadFailed', StoreError>
  | GenericAction<'Drugs/Spectra/LoadInitiated'>
  | ActionWithPayload<'Drugs/Spectra/Loaded', DrugSpectrum[]>
  | ActionWithPayload<'Drugs/Spectra/LoadFailed', StoreError>
  | GenericAction<'Drugs/Filter/LoadInitiated'>
  | ActionWithPayload<'Drugs/Filter/Loaded', Drug[]>
  | ActionWithPayload<'Drugs/Filter/LoadFailed', StoreError>;

export interface Drug {
  id: number;
  name: string;
}

export interface DrugWithSlug extends Drug {
  slug: string;
}

export interface Drugs {
  drugs: Resource<DrugWithSlug[]> | null;
  toxicities: Resource<DrugToxicity[]> | null;
  interactions: Resource<DrugInteraction[]> | null;
  classes: Resource<DrugClass[]> | null;
  spectra: Resource<DrugSpectrum[]> | null;
  filteredDrugs: Resource<Drug[]> | [];
}

export type State = Drugs;

const initialState: State = {
  drugs: null,
  toxicities: null,
  interactions: null,
  classes: null,
  spectra: null,
  filteredDrugs: [],
};

export const {
  dispatchContext: DrugsDispatchContext,
  stateContext: DrugsStateContext,
  provider: DrugsStoreProvider,
} = storeFactory(reducer, initialState);
