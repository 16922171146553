import { MobileNavbarSections } from 'Molecules/mobileSecondaryNavbar/MobileNavbarSections';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const Navbar = styled.div`
  display: none;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 5px 29px 4px;
    box-sizing: border-box;
    width: 100%;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: ${props => props.theme.colors.background.primary};
    border: solid ${props => props.theme.colors.mobileSecondaryNavbar.borderColor};
    border-width: 1px 0;
    box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.12), 0px -1px 1px rgba(0, 0, 0, 0.14);

    z-index: 50;
  }
`;

export const MobileSecondaryNavbar: FC = () => (
  <Navbar>
    <MobileNavbarSections />
  </Navbar>
);
