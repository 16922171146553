import { SvgComponent } from 'Atoms/Icon';
import { SearchSelectClearIndicator } from 'Atoms/select/SearchSelectClearIndicator';
import { SearchSelectControl } from 'Atoms/select/SearchSelectControl';
import { SearchSelectDropdownIndicator } from 'Atoms/select/SearchSelectDropdownIndicator';
import { SearchSelectMenuList } from 'Atoms/select/SearchSelectMenuList';
import { SearchSelectOptionWithIcon } from 'Atoms/select/SearchSelectOptionWithIcon';
import { StyledSelectBase } from 'Atoms/select/SelectStyles';
import React from 'react';
import { ActionMeta, OnChangeValue } from 'react-select';
import Async from 'react-select/async';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';

/**
 * When defaultOptions are set to true, loadOptions functions is being called initialy
 * on component mount, with inputValue as an empty string.
 */
export interface Props<T extends SelectOption, isMulti extends boolean> {
  className?: string;
  classNamePrefix: string;
  placeholder?: string;
  showIcon?: boolean;
  showDropdown?: boolean;
  loadOptions: (inputValue: string, callback: (options: T[]) => void) => Promise<T[]> | void;
  filterOption?: (option: FilterOptionOption<T>, inputValue: string) => boolean;
  defaultOptions?: boolean | T[];
  onChange?: (option: OnChangeValue<T, isMulti>, action: ActionMeta<T>) => void;
  cacheOptions?: boolean;
  value?: OnChangeValue<T, isMulti>;
  openMenuOnClick?: boolean;
  onInputChange?: (value: string) => void;
  isSearchable?: boolean;
  isClearable?: boolean;
  Icon?: SvgComponent;
  controlShouldRenderValue?: boolean;
  backspaceRemovesValue?: boolean;
  isMulti?: isMulti;
  'aria-label'?: string;
  id?: string;
  isDisabled?: boolean;
  inputId?: string;
  tabIndex?: number;
  'aria-describedby'?: string;
}

const isOptionDisabled = <T extends SelectOption>(option: T): boolean => {
  const disabledValue = option.disabled;
  if (disabledValue !== undefined) {
    return disabledValue;
  }

  return false;
};

/**
 * For __option:hover check SearchSelectOption component.
 */
//TODO: Find a better solution.

// We don't normally specify a return type for styled function.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const AsyncSelect = <T extends SelectOption, IsMulti extends boolean>() =>
  styled(({ ...rest }: Props<T, IsMulti>) => (
    <Async<T, IsMulti>
      components={{
        Control: SearchSelectControl,
        MenuList: SearchSelectMenuList,
        DropdownIndicator: SearchSelectDropdownIndicator,
        Option: SearchSelectOptionWithIcon,
        ClearIndicator: SearchSelectClearIndicator,
      }}
      isOptionDisabled={isOptionDisabled}
      {...rest}
    />
  ))`
    ${StyledSelectBase};

    .${props => props.classNamePrefix}__control--is-disabled {
      opacity: 0.5;
    }

    .${props => props.classNamePrefix}__option:hover {
      background: ${props => props.theme.colors.select.hover.option};
    }
  `;
