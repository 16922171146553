import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAccountController } from 'store/accountStore/hooks';

export const EBooksRedirects: FC = () => {
  const EBooksUrl = process.env.REACT_APP_EBOOKS_URL;

  const { token } = useAccountController();

  if (!EBooksUrl) {
    return <Redirect to="/" />;
  }

  const redirectToEBooks = (target: string): void => {
    if (token) {
      window.location.href = `${EBooksUrl}/login/token?token=${token}&target=${target}`;
    } else {
      window.location.href = `${EBooksUrl}${target}`;
    }
  };

  return (
    <Switch>
      <Route
        exact
        path="/ebooks"
        render={() => {
          redirectToEBooks('/');
          return null;
        }}
      />
      <Route
        exact
        path="/ebooks/countries"
        render={() => {
          redirectToEBooks('/countries');
          return null;
        }}
      />
      <Route
        exact
        path="/ebooks/diseases"
        render={() => {
          redirectToEBooks('/diseases');
          return null;
        }}
      />
      <Route
        exact
        path="/ebooks/other"
        render={() => {
          redirectToEBooks('/other');
          return null;
        }}
      />
    </Switch>
  );
};
