import { P } from 'Atoms/text';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC } from 'react';
import { useMultipleDiseaseDetails } from 'store/diseaseDetailStore/hooks';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { DiseaseDetails } from 'types/disease';

const Wrapper = styled.div`
  margin-top: 50px;
`;

const Title = styled(P)`
  margin-bottom: 25px;
`;

const StyledP = styled(P)`
  margin-bottom: 10px;
`;

interface DiseaseHintProps {
  diseaseDetails: DiseaseDetails | 'Loading';
}

const DiseaseHint: FC<DiseaseHintProps> = ({ diseaseDetails }) => {
  if (isLoading(diseaseDetails)) {
    return null;
  }

  if (diseaseDetails.clinicalHints.length === 0) {
    return (
      <Wrapper>
        <StyledP weight="700">{diseaseDetails.disease}</StyledP>
        <P>No data</P>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <StyledP weight="700">{diseaseDetails.disease}</StyledP>
      <DynamicHtml content={diseaseDetails.clinicalHints} />
    </Wrapper>
  );
};

interface Props {
  className?: string;
  selectedIds: number[];
}

export const CompareDiseasesInformation: FC<Props> = ({ className, selectedIds }) => {
  const diseasesDetails = useMultipleDiseaseDetails(selectedIds);

  return (
    <Wrapper className={className}>
      <Title size="veryBig">More about your selected diseases</Title>
      {diseasesDetails.map((disease, i) => (
        <DiseaseHint key={i} diseaseDetails={disease} />
      ))}
    </Wrapper>
  );
};
