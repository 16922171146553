import { Loader } from 'Atoms/Loader';
import { H2, P } from 'Atoms/text';
import { ExploreLandingModal, ModalHeader } from 'Molecules/exploreLanding/ExploreLandingModal';
import { SearchByModalResults } from 'Molecules/SearchByModalResults';
import { MultiSelect } from 'Molecules/select/MultiSelect';
import { SearchSelect } from 'Molecules/select/SearchSelect';
import React, { FC, useEffect, useState } from 'react';
import { OnChangeValue } from 'react-select';
import { useDrugsFiltered } from 'store/drugsStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';
import { mapResource, mapToSearchSelectOption } from 'utils/mapResource';

const StyledModal = styled(ExploreLandingModal)`
  ${ModalHeader} {
    padding: 1% 11%;
  }
`;

const SelectsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
`;

const Wrapper = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledH2 = styled(H2)`
  margin-bottom: 8px;
`;

const StyledP = styled(P)`
  margin-bottom: 20px;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  initialSpectrumValues?: SelectOption[];
}

export const DrugSpectrumModal: FC<Props> = ({ isOpen, onClose, initialSpectrumValues }) => {
  const { drugsState, filterDrugsByClassAndSpectra, total } = useDrugsFiltered();

  const [drugClass, setDrugClass] = useState<SelectOption | null>(null);
  const [spectrum, setSpectrum] = useState<SelectOption[]>(initialSpectrumValues || []);

  const classesOptions = mapResource(drugsState.classes, mapToSearchSelectOption);
  const spectraOptions = mapResource(drugsState.spectra, mapToSearchSelectOption);

  useEffect(() => {
    filterDrugsByClassAndSpectra(drugClass?.value || '', spectrum ? spectrum : []);
  }, [drugClass, spectrum, filterDrugsByClassAndSpectra]);

  assertIsNotStoreError(classesOptions);
  assertIsNotStoreError(spectraOptions);

  const modalTitle =
    spectrum && spectrum.length > 0
      ? `Drugs effective against ${spectrum.map(x => x.label).join(', ')}`
      : `Search for drugs by Class, Spectrum or both`;

  if (isLoading(classesOptions) || isLoading(spectraOptions)) {
    return (
      <StyledModal title={modalTitle} isOpen={isOpen} onClose={onClose}>
        <Loader />
      </StyledModal>
    );
  }

  const onClassesChange = (selected: OnChangeValue<SelectOption, false>): void => {
    setDrugClass(selected);
  };

  const onSpectraChange = (selected: OnChangeValue<SelectOption, true>): void => {
    setSpectrum([...selected]);
  };

  return (
    <StyledModal title={modalTitle} isOpen={isOpen} onClose={onClose}>
      <SelectsWrapper>
        <Wrapper>
          <StyledH2>Drug class</StyledH2>
          <StyledP color="inactive">(select one or none)</StyledP>
          <SearchSelect
            classNamePrefix="search-select"
            placeholder="Change drug class"
            showDropdown
            showIcon
            options={classesOptions ? classesOptions : []}
            onChange={onClassesChange}
            value={drugClass}
            isClearable
          />
        </Wrapper>
        <Wrapper>
          <StyledH2>Spectrum</StyledH2>
          <StyledP color="inactive">(select one or more)</StyledP>
          <MultiSelect
            classNamePrefix="search-select"
            options={spectraOptions ? spectraOptions : []}
            placeholder="Add more pathogens"
            onChange={onSpectraChange}
            value={spectrum}
          />
        </Wrapper>
      </SelectsWrapper>
      <SearchByModalResults
        isInitial={!drugClass && !spectrum}
        total={total}
        type="drugs"
        results={drugsState.filteredDrugs}
      />
    </StyledModal>
  );
};
