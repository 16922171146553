import { Modal } from 'Atoms/Modal';
import { PaywallContent } from 'Organisms/paywall/PaywallContent';
import React, { createContext, FC, FormEvent, useState } from 'react';
import { Fields, useAccountController } from 'store/accountStore/hooks';

import { usePushState } from './usePushState.hook';

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  link: string | null;
}

const LoginModal: FC<Props> = ({ isOpen, setIsOpen, link }) => {
  const { push } = usePushState();
  const { signIn, isLoading, ipLoginStatus } = useAccountController();

  const onSubmit = (e: FormEvent<HTMLFormElement>, fields: Fields): void => {
    signIn(e, fields).then(() => {
      if (link) {
        push(link);
      }

      setIsOpen(false);
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} showCloseButton>
      <PaywallContent onSubmit={onSubmit} isLoading={isLoading} ipLoginStatus={ipLoginStatus} />
    </Modal>
  );
};

type LoginModalContext = {
  isOpen: boolean;
  setOpen: (value: boolean, link?: string) => void;
};

export const LoginModalContext = createContext<LoginModalContext | undefined>(undefined);
export const LoginModalContextProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [link, setLink] = useState<string | null>(null);

  const setOpen = (value: boolean, link?: string): void => {
    setIsOpen(value);

    if (link) {
      setLink(link);
    }
  };

  return (
    <LoginModalContext.Provider value={{ isOpen, setOpen }}>
      {children}
      <LoginModal isOpen={isOpen} setIsOpen={setIsOpen} link={link} />
    </LoginModalContext.Provider>
  );
};
