import { Row } from 'types/table';

type TestDictionary = { [key: string]: string };

const basicTests: TestDictionary = {
  'Gram negative': 'Gram negative',
  'Gram positive': 'Gram positive',
  Coccus: 'Coccus',
  'Coccus - pairs or chains predominate': 'Coccus - pairs or chains predominate',
  'Coccus - clusters or groups predominate': 'Coccus - clusters or groups predominate',
  'Bacillus or coccobacillus': 'Bacillus or coccobacillus',
  'Branching filaments present': 'Branching filaments present',
  'Spore formation': 'Spore formation',
  'Acid fast': 'Acid fast',
  Spirochete: 'Spirochete',
  'Curved bacilli': 'Curved bacilli',
  'Cell wall-deficient': 'Cell wall-deficient',
  Aerobe: 'Aerobe',
  Facultative: 'Facultative',
  Anaerobe: 'Anaerobe',
  Microaerophilic: 'Microaerophilic',
  'Growth on ordinary blood agar': 'Growth on ordinary blood agar',
  'Growth on MacConkey agar': 'Growth on MacConkey agar',
  Oxidase: 'Oxidase',
  Catalase: 'Catalase',
  'Glucose fermenter': 'Glucose fermenter',
  'Glucose oxidizer': 'Glucose oxidizer',
  'Not identifiable by standard tests': 'Not identifiable by standard tests',
};

const generalTests: TestDictionary = {
  'Beta hemolysis': 'Beta hemolysis',
  'Coagulase production': 'Coagulase production',
  Motile: 'Motile',
  'Cholesterol needed for growth': 'Cholesterol needed for growth',
  'X factor required': 'X factor required',
  'V factor required': 'V factor required',
  Capnophilic: 'Capnophilic',
  Indole: 'Indole',
  'ONPG (beta galactosidase)': 'ONPG (beta galactosidase)',
  'Methyl red': 'Methyl red',
  'Voges Proskauer': 'Voges Proskauer',
  Citrate: 'Citrate',
  'Hydrogen sulfide': 'Hydrogen sulfide',
  'Nitrate to nitrite': 'Nitrate to nitrite',
  'Nitrite to gas': 'Nitrite to gas',
  Malonate: 'Malonate',
  'Casein hydrolysis': 'Casein hydrolysis',
  DNase: 'DNase',
  'Esculin hydrolysis': 'Esculin hydrolysis',
  'Gelatin hydrolysis': 'Gelatin hydrolysis',
  'Hippurate hydrolysis': 'Hippurate hydrolysis',
  Lecithinase: 'Lecithinase',
  Lipase: 'Lipase',
  'Starch hydrolysis': 'Starch hydrolysis',
  'Tyrosine hydrolysis': 'Tyrosine hydrolysis',
  'Urea hydrolysis': 'Urea hydrolysis',
  'Yellow pigment': 'Yellow pigment',
  'Arginine dihydrolase': 'Arginine dihydrolase',
  'Lysine decarboxylase': 'Lysine decarboxylase',
  'Ornithine decarboxylase': 'Ornithine decarboxylase',
  'Phenylalanine deaminase': 'Phenylalanine deaminase',
  'Acetate utilization': 'Acetate utilization',
  'Mucate utilization': 'Mucate utilization',
  'Tartrate utilization': 'Tartrate utilization',
  'Growth in KCN': 'Growth in KCN',
  'Growth at 42 degrees C': 'Growth at 42 degrees C',
  'Growth in 6.5% NaCl': 'Growth in 6.5% NaCl',
  'Bile-susceptible': 'Bile-susceptible',
  'Cephalothin-susceptible': 'Cephalothin-susceptible',
  'Colistin-Polymyxin susceptible': 'Colistin-Polymyxin susceptible',
  'Nalidixic acid-susceptible': 'Nalidixic acid-susceptible',
  'Novobiocin-susceptible': 'Novobiocin-susceptible',
  'Vancomycin-susceptible': 'Vancomycin-susceptible',
  'Alkaline phosphatase': 'Alkaline phosphatase',
  'Xanthine hydrolysis': 'Xanthine hydrolysis',
  'Hypoxanthine hydrolysis': 'Hypoxanthine hydrolysis',
  'Gas from glucose': 'Gas from glucose',
  'Pyrrolidonyl-beta-naphthylamide': 'Pyrrolidonyl-beta-naphthylamide',
  'Leucine aminopeptidase [LAP]': 'Leucine aminopeptidase [LAP]',
};

const foaTests: TestDictionary = {
  Adonitol: 'Adonitol',
  'Alpha-methyl-D-glucoside': 'Alpha-methyl-D-glucoside',
  'L-Arabinose': 'L-Arabinose',
  'D-Arabitol': 'D-Arabitol',
  Cellobiose: 'Cellobiose',
  Dulcitol: 'Dulcitol',
  Erthyritol: 'Erthyritol',
  Glycerol: 'Glycerol',
  'myo-Inositol': 'myo-Inositol',
  Lactose: 'Lactose',
  Maltose: 'Maltose',
  'D-Mannitol': 'D-Mannitol',
  'D-Mannose': 'D-Mannose',
  Melibiose: 'Melibiose',
  Raffinose: 'Raffinose',
  'L-Rhamnose': 'L-Rhamnose',
  Salicin: 'Salicin',
  'D-Sorbitol': 'D-Sorbitol',
  Sucrose: 'Sucrose',
  Trehalose: 'Trehalose',
  'D-Xylose': 'D-Xylose',
};

export const getGroupedBacteriaRows = (rows: Row[]): [Row[], Row[], Row[]] => {
  const basic: Row[] = [];
  const general: Row[] = [];
  const foa: Row[] = [];

  rows.forEach(row => {
    if (basicTests[row[0].value]) {
      basic.push(row);
    }
    if (generalTests[row[0].value]) {
      general.push(row);
    }
    if (foaTests[row[0].value]) {
      foa.push(row);
    }
  });

  return [basic, general, foa];
};
