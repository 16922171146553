import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { Action as GenericAction, ActionWithPayload, Resource } from 'store/types';
import { Contents } from 'Updates/types/content';

import { reducer } from './reducer';

export type Action =
  | GenericAction<'Contents/LoadInitiated'>
  | ActionWithPayload<'Contents/Loaded', Contents>
  | ActionWithPayload<'Contents/LoadFailed', StoreError>;

export type State = Resource<Contents> | null;

const initialState: State = null;

export const {
  dispatchContext: ContentsDispatchContext,
  stateContext: ContentsStateContext,
  provider: ContentsStoreProvider,
} = storeFactory(reducer, initialState);
