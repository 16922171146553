import { ReactComponent as AZSvg } from 'assets/Navbar/AZ.svg';
import { ReactComponent as CompareSvg } from 'assets/Navbar/Compare.svg';
import { ReactComponent as CompareNotEmptySvg } from 'assets/Navbar/CompareNotEmpty.svg';
import { ReactComponent as SearchSvg } from 'assets/Navbar/Search.svg';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon } from 'Atoms/Icon';
import { DisabledNavbarLink, StaticNavbarLink } from 'Atoms/links/NavLinks';
import { Span } from 'Atoms/text';
import { CompareNavButton } from 'Molecules/compare/CompareNavButton';
import { rgba } from 'polished';
import React, { FC, forwardRef, KeyboardEventHandler } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginModalContext } from 'services/loginModal.service';
import { useContextAssert } from 'services/useContextAssert.hook';
import { usePushState } from 'services/usePushState.hook';
import { useTooltip } from 'services/useTooltip';
import {
  useComparisonNotEmpty,
  useState as useCompareState,
} from 'store/ComparisonListsStore/hooks';
import styled from 'styled-components/macro';

const DisabledStyledIcon = styled(Icon)`
  width: auto;
  height: 22px;

  fill: ${props => rgba(props.theme.colors.text.inactive, 0.4)};
`;

const Button = styled(InvisibleButton)`
  margin-right: 20px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-right: 10px;
  }
`;

const StyledIcon = styled(Icon)`
  width: auto;
  height: 22px;
`;

const StyledLink = styled(StaticNavbarLink)`
  margin-right: 50px;
  white-space: nowrap;
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    margin-right: 40px;
  }
`;

const SearchMobileLink = styled(StaticNavbarLink)`
  margin: 0 10px 0 auto;
`;

export const DisabledNavbarLinkStyled = styled(DisabledNavbarLink)`
  height: 100%;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;

const HiddenDescription = styled(Span)`
  display: none;
`;

interface BaseProps {
  onKeyDown?: KeyboardEventHandler<HTMLElement>;
}

export const SearchButton = forwardRef<HTMLAnchorElement, BaseProps>(({ onKeyDown }, ref) => (
  <StyledLink to="/search" aria-label="Open search" ref={ref} onKeyDown={onKeyDown}>
    Search
  </StyledLink>
));

export const SearchButtonMobile: FC = () => (
  <SearchMobileLink to="/search" aria-label="Open search">
    <StyledIcon fill="inactive" svgComponent={SearchSvg} />
  </SearchMobileLink>
);

export const SearchButtonPublic: FC = () => {
  const [props, tooltip, setElement] = useTooltip('Sign in to access this module');
  const { setOpen } = useContextAssert(LoginModalContext);

  return (
    <DisabledNavbarLinkStyled
      {...props.aria}
      {...props.events}
      onClick={() => setOpen(true, '/search')}
      ref={setElement}
    >
      Search
      {tooltip}
    </DisabledNavbarLinkStyled>
  );
};

export const SearchButtonPublicMobile: FC = () => {
  const { setOpen } = useContextAssert(LoginModalContext);

  return (
    <SearchMobileLink
      aria-label="Open search"
      to="/search"
      onClick={() => setOpen(true, '/search')}
    >
      <DisabledStyledIcon fill="inactive" svgComponent={SearchSvg} />
    </SearchMobileLink>
  );
};

const getComparePath = (pathname: string): string => {
  switch (pathname) {
    case '/explore/diseases': {
      return '/compare/diseases';
    }
    case '/explore/drugs': {
      return '/compare/drugs';
    }
    case '/explore/microbes/bacteria': {
      return '/compare/bacteria';
    }
    case '/explore/microbes/mycobacteria': {
      return '/compare/mycobacteria';
    }
    case '/explore/microbes/yeasts': {
      return '/compare/yeasts';
    }
    case '/explore/microbes': {
      return '/compare/bacteria';
    }
    default: {
      return '/compare';
    }
  }
};

export const CompareButton = forwardRef<HTMLAnchorElement, BaseProps>(({ onKeyDown }, ref) => {
  const { activeSection } = useCompareState();
  const { location } = useHistory();

  return (
    <CompareNavButton
      path={activeSection ? `/compare/${activeSection}` : getComparePath(location.pathname)}
      ref={ref}
      onKeyDown={onKeyDown}
    />
  );
});

const MobileLink = styled(StaticNavbarLink)`
  margin-right: 10px;
`;

export const CompareButtonMobile: FC = () => {
  const comparisonNotEmpty = useComparisonNotEmpty();
  const { location } = useHistory();

  return (
    <MobileLink
      to={getComparePath(location.pathname)}
      aria-label="Open comparison"
      aria-describedby="compare-button"
    >
      <StyledIcon
        fill="inactive"
        svgComponent={comparisonNotEmpty ? CompareNotEmptySvg : CompareSvg}
      />
      <HiddenDescription id="compare-button">
        Create comparison tables for diseases, drugs, and pathogens
      </HiddenDescription>
    </MobileLink>
  );
};

export const CompareButtonPublic: FC = () => {
  const { setOpen } = useContextAssert(LoginModalContext);
  const [props, tooltip, setElement] = useTooltip('Sign in to access this module');

  return (
    <DisabledNavbarLinkStyled
      {...props.aria}
      {...props.events}
      onClick={() => setOpen(true, '/compare')}
      ref={setElement}
    >
      Compare
      {tooltip}
    </DisabledNavbarLinkStyled>
  );
};

const CompareButtonPublicStyled = styled(Button)`
  margin-right: 10px;
`;

export const CompareButtonPublicMobile: FC = () => {
  const { setOpen } = useContextAssert(LoginModalContext);

  return (
    <CompareButtonPublicStyled aria-label="Open compare" onClick={() => setOpen(true, '/compare')}>
      <DisabledStyledIcon svgComponent={CompareSvg} />
    </CompareButtonPublicStyled>
  );
};

const getAZPath = (pathname: string): string => {
  switch (pathname) {
    case '/explore/diseases': {
      return '/az/diseases';
    }
    case '/explore/drugs': {
      return '/az/drugs';
    }
    case '/explore/vaccines': {
      return '/az/vaccines';
    }
    case '/explore/microbes/bacteria': {
      return '/az/microbes/bacteria';
    }
    case '/explore/microbes/mycobacteria': {
      return '/az/microbes/mycobacteria';
    }
    case '/explore/microbes/yeasts': {
      return '/az/microbes/yeasts';
    }
    case '/explore/microbes': {
      return '/az/microbes';
    }
    case '/explore/countries': {
      return '/az/countries';
    }
    default: {
      return '/az';
    }
  }
};

export const AZButton = forwardRef<HTMLAnchorElement, BaseProps>(({ onKeyDown }, ref) => {
  const { location } = useHistory();

  return (
    <StyledLink
      to={getAZPath(location.pathname)}
      aria-label="Open alphabetical list"
      ref={ref}
      onKeyDown={onKeyDown}
      aria-describedby="az-link"
    >
      A-Z
      <HiddenDescription id="az-link">
        Browse GIDEON content alphabetically, A to Z
      </HiddenDescription>
    </StyledLink>
  );
});

export const AZButtonMobile: FC = () => {
  const { push } = usePushState();
  const { location } = useHistory();

  return (
    <Button
      onClick={() => push(getAZPath(location.pathname))}
      aria-label="Open alphabetical list"
      aria-describedby="az-link"
    >
      <StyledIcon svgComponent={AZSvg} />
      <HiddenDescription id="az-link">
        Browse GIDEON content alphabetically, A to Z
      </HiddenDescription>
    </Button>
  );
};
