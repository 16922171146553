import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'Account/Load': {
      return {
        ...state,
        accountSettings: 'Loading',
      };
    }
    case 'Account/Loaded': {
      return {
        ...state,
        accountSettings: action.payload,
      };
    }
    case 'Account/LoadFailed': {
      return {
        ...state,
        accountSettings: action.payload,
      };
    }
    case 'Account/UpdateIPLoginStatus': {
      return {
        ...state,
        ipLoginStatus: action.payload,
      };
    }
    case 'Account/SkipIPLogin': {
      return state.ipLoginStatus === 'initial'
        ? {
            ...state,
            ipLoginStatus: 'skipped',
          }
        : state;
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
