import { ReactComponent as CountriesSvg } from 'assets/Navbar/Countries.svg';
import { ReactComponent as DiseasesSvg } from 'assets/Navbar/Diseases.svg';
import { ReactComponent as DrugsSvg } from 'assets/Navbar/Drugs.svg';
import { ReactComponent as MicrobesSvg } from 'assets/Navbar/Microbes.svg';
import { ReactComponent as VaccinesSvg } from 'assets/Navbar/Vaccine.svg';
import { ReactComponent as Bacteria } from 'assets/Search/Bacteria.svg';
import { ReactComponent as Mycobacteria } from 'assets/Search/Mycobacteria.svg';
import { ReactComponent as Yeasts } from 'assets/Search/Yeasts.svg';
import { SvgComponent } from 'Atoms/Icon';
import { ReactComponent as ProbabilityEngineSvg } from 'Diagnose/assets/ProbabilityEngine.svg';
import { ReactComponent as ProcessSvg } from 'Diagnose/assets/Process.svg';
import { ReactComponent as DiseaseSVG } from 'Visualize/assets/DiseaseLight.svg';
import { ReactComponent as EpidemiologicalSVG } from 'Visualize/assets/EpidemiologicalLight.svg';
import { ReactComponent as OutbreaksSVG } from 'Visualize/assets/OutbreaksLight.svg';
import { ReactComponent as VaccinesSVG } from 'Visualize/assets/VaccinesLight.svg';

export interface SecondaryNavbarLink {
  to: string;
  title: string;
  icon: SvgComponent;
  shortTitle?: string;
}

export const exploreLinks: SecondaryNavbarLink[] = [
  {
    to: '/explore/diseases',
    title: 'Diseases',
    icon: DiseasesSvg,
  },
  {
    to: '/explore/drugs',
    title: 'Drugs',
    icon: DrugsSvg,
  },
  {
    to: '/explore/vaccines',
    title: 'Vaccines',
    icon: VaccinesSvg,
  },
  {
    to: '/explore/microbes',
    title: 'Microbes',
    icon: MicrobesSvg,
  },
  {
    to: '/explore/countries',
    title: 'Countries',
    icon: CountriesSvg,
  },
];

export const labLinks: SecondaryNavbarLink[] = [
  {
    to: '/lab/bacteria',
    title: 'Bacteria',
    icon: Bacteria,
  },
  {
    to: '/lab/mycobacteria',
    title: 'Mycobacteria',
    icon: Mycobacteria,
  },
  {
    to: '/lab/yeasts',
    title: 'Yeasts',
    icon: Yeasts,
  },
];

export const diagnoseLinks: SecondaryNavbarLink[] = [
  {
    to: '/diagnose/probability-engine',
    title: 'Probability engine',
    icon: ProbabilityEngineSvg,
  },
  {
    to: '/diagnose/steps',
    title: 'Step by Step',
    icon: ProcessSvg,
  },
];

export const visualizeLinks: SecondaryNavbarLink[] = [
  {
    to: '/visualize/graphs',
    title: 'Epidemiological graphs',
    shortTitle: 'Epi. Graphs',
    icon: EpidemiologicalSVG,
  },
  {
    to: '/visualize/disease-outbreaks/start',
    title: 'Disease outbreak maps',
    shortTitle: 'D.O. Maps',
    icon: DiseaseSVG,
  },
  {
    to: `/visualize/global-outbreaks-map/${new Date().getFullYear()}`,
    title: 'Global outbreaks map',
    shortTitle: 'G.O. Maps',
    icon: OutbreaksSVG,
  },
  {
    to: '/visualize/vaccine-coverage-maps/start',
    title: 'Vaccine coverage maps',
    shortTitle: 'Vacc. Cov. Maps',
    icon: VaccinesSVG,
  }
];

export const publicVisualizeLinks: SecondaryNavbarLink[] = [
  {
    to: '/visualize/graphs',
    title: 'Epidemiological graphs',
    shortTitle: 'Epi. Graphs',
    icon: EpidemiologicalSVG,
  },
  {
    to: `/visualize/global-outbreaks-map/${new Date().getFullYear()}`,
    title: 'Global outbreaks map',
    shortTitle: 'G.O. Maps',
    icon: OutbreaksSVG,
  }
];
