export interface EmailLoginRequest {
  email: string;
  password: string;
  recaptcha_token: string;
}

export interface InstitutionalLoginRequest {
  username: string;
  password: string;
  recaptcha_token: string;
}

interface UserData {
  user_id: number;
  email: string;
  first_name: string;
  last_name: string;
}

export interface EmailLoginResponse {
  token: string;
  user: UserData;
  subscription: {
    part_number: number;
    expire_date: string;
  };
}

export interface InstitutionalLoginResponse {
  token: string;
  user: UserData;
  institution: unknown;
}

//TODO: Move elsewhere before reusing, to some general type file
export interface ErrorResponse {
  error: {
    message: string;
  };
}

export const isErrorResponse = (value: unknown): value is ErrorResponse => {
  return (
    !!value &&
    (value as ErrorResponse).error &&
    typeof (value as ErrorResponse).error === 'object' &&
    !!(value as ErrorResponse).error.message
  );
};
