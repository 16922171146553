import { H1, H2 } from 'Atoms/text/H';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const TitleWrapper = styled.div`
  margin: 0px 0 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 50px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 16px 10px 30px 10px;
  }
`;

const StyledTitle = styled(H1)`
  margin: 0;
`;

interface Props {
  title: string;
  subtitle?: string;
}

export const ExploreLandingTitles: FC<Props> = ({ title, subtitle }) => {
  return (
    <TitleWrapper>
      <StyledTitle color="exploreLandingTitle" font="Quicksand">
        {title}
      </StyledTitle>
      {subtitle && (
        <H2 size="smallRegular" font="Inter">
          {subtitle}
        </H2>
      )}
    </TitleWrapper>
  );
};
