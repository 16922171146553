import { RelatedNotesDTO } from 'apiServices/Diseases/DTO';
import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { RegionVaccineSchedule } from 'types/countryDetails';
import { CountryNotes, CountryNotesPublic } from 'types/countryNotes';

import { CaseSeriesDTO, CrossbordersDTO, OutbreaksDTO, SurveyDTO } from '../common/DTO';
import {
  mapCaseSeriesDTO,
  mapCrossbordersDTO,
  mapOutbreaksDTO,
  mapReferenceDTO,
  mapSurveyDTO,
} from '../common/mappers';
import { AllCountryNoteSectionsDTO, CountryNotesDTO, CountryNotesPublicDTO, VaccineScheduleDTO } from './DTO';
import { AllSectionDTO } from './worldwideNotes.mapper';

export const mapVaccineScheduleDTO = (
  v: VaccineScheduleDTO[]
): RegionVaccineSchedule | undefined => {
  if (v.length === 0) {
    return undefined;
  }

  return {
    countryCode: v.length > 0 ? v[0].country_code : '',
    vaccines: v.map(x => ({ vaccine: x.vaccine, schedule: x.schedule, references: x.references })),
  };
};

export const mapCountryNotesDTO = (
  all:AllCountryNoteSectionsDTO,
  x: CountryNotesDTO,
  prevalence: SurveyDTO[],
  seroprevalence: SurveyDTO[],
  crossborders: CrossbordersDTO,
  outbreaks: OutbreaksDTO[],
  sporadic: OutbreaksDTO[],
  timestamp:number|undefined,
  vaccineSchedule: VaccineScheduleDTO[],
  relatedNotes: RelatedNotesDTO,
  associatedInfections: SurveyDTO[],
  caseSeries: CaseSeriesDTO[],
): CountryNotes => ({
  allSection:AllSectionDTO(all),
  note: parseDynamicContent(x.note || ''),
  references: x.references.map(mapReferenceDTO),
  prevalence: prevalence.map(x => mapSurveyDTO(x)),
  seroprevalence: seroprevalence.map(x => mapSurveyDTO(x)),
  crossborders: crossborders.events.map(x => mapCrossbordersDTO(x)),
  outbreaks: outbreaks.map(x => mapOutbreaksDTO(x)),
  sporadic: sporadic.map(x => mapOutbreaksDTO(x)),
  vaccineSchedule: mapVaccineScheduleDTO(vaccineSchedule),
  relatedCountries: relatedNotes.related_countries.map(x => ({
    id: x.country_code,
    name: x.country,
  })),
  relatedDiseases: relatedNotes.related_diseases.map(x => ({
    id: x.disease_code,
    name: x.disease,
  })),
  timestamp:timestamp,
  associatedInfections: associatedInfections.map(x => mapSurveyDTO(x)),
  caseSeries: caseSeries.map(x => mapCaseSeriesDTO(x)),
});

export const mapCountryNotesPublicDTO = (x: CountryNotesPublicDTO): CountryNotesPublic => ({
  note: parseDynamicContent(x.note),
});
