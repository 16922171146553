import { ReactComponent as JumpBottomSVG } from 'assets/Button/JumpBottom.svg';
import { ReactComponent as JumpTopSVG } from 'assets/Button/JumpTop.svg';
import { Tooltip } from 'Atoms/tooltip/Tooltip';
import { HorizontalScrollbarShadows } from 'Organisms/HorizontalScrollbarShadows';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

export { TD } from './TD';
export { TH } from './TH';
export { TR } from './TR';

const TableWrapper = styled.div`
  position: relative;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  max-width: 100%;
  margin-bottom: 1px;
`;

const StyledTooltip = styled(Tooltip)`
  width: fit-content;
  height: fit-content;
  min-width: 120px;
  left: 20px;
  transform: translateX(-50%);
  padding: 3px 5px 5px;
`;

const JumpButton = styled.button`
  position: absolute;
  right: 0;
  transform: translateX(-50%);
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 4;
  opacity: 0.5;

  &.to-bottom {
    top: -43px;

    ${StyledTooltip} {
      top: -27px;
      bottom: initial;
    }
  }

  &.to-top {
    bottom: -43px;

    ${StyledTooltip} {
      bottom: -27px;
      top: initial;
    }
  }

  &:hover {
    opacity: 1;

    ${StyledTooltip} {
      display: block;
    }
  }

  svg {
    rect {
      fill: ${props => props.theme.colors.background.modalCardBody};
      stroke: ${props => props.theme.colors.button.default.borderColor};
    }
    path {
      fill: ${props => props.theme.colors.button.default.borderColor};
    }
  }
`;

interface Props {
  className?: string;
  headings: ReactNode;
  rows: ReactNode;
}

export const Table: FC<Props> = ({ headings, rows, className }) => {
  const [renderWithRef, setRenderWithRef] = useState(false);
  const tableRef = useRef<HTMLTableElement>(null);
  const topRef = useRef<HTMLButtonElement>(null);
  const downRef = useRef<HTMLButtonElement>(null);

  const jumpToTableEnd = (direction: string): void => {
    if (direction === 'bottom' && downRef.current) {
      downRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    if (direction === 'top' && topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    if (tableRef.current) {
      setRenderWithRef(true);
    }
  }, []);

  return (
    <TableWrapper>
      {renderWithRef && tableRef.current && tableRef.current.clientHeight >= window.innerHeight && (
        <JumpButton className="to-bottom" ref={topRef} onClick={() => jumpToTableEnd('bottom')}>
          <JumpBottomSVG />
          <StyledTooltip>Jump to bottom</StyledTooltip>
        </JumpButton>
      )}
      <HorizontalScrollbarShadows className={className}>
        <StyledTable ref={tableRef}>
          <thead>{headings}</thead>
          <tbody>{rows}</tbody>
        </StyledTable>
      </HorizontalScrollbarShadows>
      {renderWithRef && tableRef.current && tableRef.current.clientHeight >= window.innerHeight && (
        <JumpButton className="to-top" ref={downRef} onClick={() => jumpToTableEnd('top')}>
          <JumpTopSVG />
          <StyledTooltip>Jump to top</StyledTooltip>
        </JumpButton>
      )}
    </TableWrapper>
  );
};
