import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { Action as GenericAction, ActionWithPayload, Resource } from 'store/types';
import { Fingerprints } from 'types/fingerprint';

import { reducer } from './reducer';

export type Action =
  | GenericAction<'Fingerprint/LoadInitiated'>
  | ActionWithPayload<'Fingerprint/Loaded', Fingerprints>
  | ActionWithPayload<'Fingerprint/LoadFailed', StoreError>;

export type State = Resource<Fingerprints> | null;

const initialState: State = null;

export const {
  dispatchContext: FingerprintPropertiesDispatchContext,
  stateContext: FingerprintPropertiesStateContext,
  provider: FingerprintPropertiesStoreProvider,
} = storeFactory(reducer, initialState);
