interface FlagPath {
  light: string;
  dark?: string;
}

const hasDarkImage = (label: string): boolean => {
  return label === 'G90' || label === 'bioterrorism' || label === 'G100' || label === 'null';
};

export const getFlagPath = (label: string, value: string): FlagPath => {
  const result = {
    light: `/Flags/${label}.svg`,
    dark: hasDarkImage(value) ? `/Flags/${label}Dark.svg` : `/Flags/${label}.svg`,
  };

  return result;
};
