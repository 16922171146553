import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { RangeValue } from 'Updates/types/rangeType';
import { UpdatesResponse } from 'Updates/types/updatedField';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'Updates/SingleLoadInitiated', { id: RangeValue }>
  | ActionWithPayload<'Updates/SingleLoaded', { id: RangeValue; data: UpdatesResponse }>
  | ActionWithPayload<'Updates/SingleLoadFailed', { id: RangeValue; error: StoreError }>;

export type State = Record<RangeValue, Resource<UpdatesResponse> | null>;

const initialState: State = {
  '48-hours': null,
  '7-days': null,
};

export const {
  dispatchContext: UpdatesDispatchContext,
  stateContext: UpdatesStateContext,
  provider: UpdatesStoreProvider,
} = storeFactory(reducer, initialState);
