import {
  CompareDisease,
  CompareDrug,
  CompareDrugPathogen,
  CompareMicrobe,
  Symptom,
} from 'types/compare';

import { CompareDiseasesDTO, CompareDrugsDTO, CompareMicrobeDTO, SymptomDTO } from './DTO';

const mapSymptomsDTO = (symptom: SymptomDTO): Symptom => ({
  id: symptom.symptom_code,
  name: symptom.symptom_name,
  probability: symptom.probability,
});

export const mapCompareDiseasesDTO = (diseases: CompareDiseasesDTO[]): CompareDisease[] => {
  return diseases.map(d => ({
    id: d.disease_code,
    symptoms: d.symptoms.map(mapSymptomsDTO),
  }));
};

export const mapCompareDrugsDTO = (drugs: CompareDrugsDTO[][]): CompareDrug[] => {
  return drugs.map<CompareDrug>(drug => ({
    id: drug[0].code,
    name: drug[0].drug,
    pathogens: drug.map<CompareDrugPathogen>(d => ({
      pathogenCode: d.pathogen_code,
      pathogen: d.pathogen,
      isDrugClass: d.is_drug_class,
      drugOfChoice: !!d.drug_of_choice,
      usedInCombination: !!d.used_in_combination,
      excisionRequired: !!d.excision_required,
      microbiologyAgents: d.microbiology_agents,
      surgeryRequired: d.surgery_required,
    })),
  }));
};

export const mapCompareMicrobeDTO = (microbe: CompareMicrobeDTO[]): CompareMicrobe[] => {
  return microbe.map(x => ({
    id: x.code,
    phenotypes: x.phenotypes.map(y => ({
      id: y.phenotype_code,
      name: y.phenotype,
      probability: typeof y.probability === 'string' ? parseFloat(y.probability) : y.probability,
    })),
  }));
};
