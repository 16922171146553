import { ReactComponent as DrugClassIcon } from 'assets/UI/DrugClass.svg';
import { Icon } from 'Atoms/Icon';
import { OrderedListItem } from 'Atoms/OrderedList';
import { Span } from 'Atoms/text';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const DrugClassItemStyled = styled(OrderedListItem)`
  display: flex;
  align-items: center;

  width: 100%;
`;

const IconStyled = styled(Icon)`
  max-width: 30px;
  max-height: 30px;

  margin-right: 10px;
`;

type Props = {
  className?: string;
  label: string;
};

export const DrugClassItem: FC<Props> = ({ className, label }) => (
  <DrugClassItemStyled className={className}>
    <IconStyled fill="lightLink" svgComponent={DrugClassIcon} />
    <Span>
      <b>Drug class:</b> {label}
    </Span>
  </DrugClassItemStyled>
);
