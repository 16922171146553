import { Link } from 'Atoms/links/Link';
import moment from 'moment';
import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import { Row } from 'types/table';
import { UpdatedField } from 'Updates/types/updatedField';

const LinkStyled = styled(Link)`
  display: inline-block;
  padding: 5px 0;
`;

export const getLatestDataRows = (data: UpdatedField[]): Row[] =>
  data.map(x => [
    { value: x.timestamp, label: moment.unix(x.timestamp).format('MMM DD, YYYY') },
    { value: x.category, label: x.category },
    x.countries
      ? {
          value: x.name,
          label: (
            <div>
              <LinkStyled to={x.url}>{x.name}</LinkStyled>
              {x.countries.length > 0 && ' in '}
              {x.countries.map((country, index) => (
                <Fragment key={country.url}>
                  <LinkStyled to={country.url}>{country.name}</LinkStyled>
                  {x.countries && index < x.countries.length - 1 && `, `}
                </Fragment>
              ))}
            </div>
          ),
          itemCount: x.countries.length,
        }
      : { value: x.id, label: <Link to={x.url}>{x.name}</Link> },
  ]);
