import { Toast } from 'Atoms/toast/Toast';
import React, { FC, ReactText, useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

export const ServiceWorkerWrapper: FC = ({ children }) => {
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
  const toastId = useRef<ReactText | null>(null);

  const onForceUpdate = useCallback((waitingWorker: ServiceWorker): void => {
    waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    window.location.reload();
  }, []);

  useEffect(() => {
    if (!waitingWorker) {
      return;
    }

    if (toastId.current) {
      toast.update(toastId.current, {
        onClick: () => onForceUpdate(waitingWorker),
      });
    } else {
      toastId.current = toast.warning(
        <Toast
          type="warning"
          content="We have just updated GIDEON! Click here to refresh (you won't lose your progress)."
        />,
        {
          onClick: () => onForceUpdate(waitingWorker),
          autoClose: false,
          hideProgressBar: true,
        }
      );
    }
  }, [waitingWorker, onForceUpdate]);

  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({
      onUpdate: registration => setWaitingWorker(registration.waiting),
      onReady: registration => setWaitingWorker(registration.waiting),
    });
  }, []);

  return <>{children}</>;
};
