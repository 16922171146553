import {
  getSearchResults as getSearchResultsApi,
  getSearchSuggestions as getSearchSuggestionsApi,
} from 'apiServices/Search/search';
import { debounce } from 'lodash';
import { Dispatch, useCallback, useContext, useEffect, useRef } from 'react';
import { useSearchParams } from 'services/useSearchParams';
import { isStoreError, newStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import { CustomError, ErrorCodes } from 'types/errorTypes';
import { SearchQueryParameters } from 'types/search';

import { Action, SearchDispatchContext, SearchStateContext, State } from './provider';

export const useState = (): State => {
  const state = useContext(SearchStateContext);
  if (state === undefined) {
    throw new CustomError('SearchStore', ErrorCodes.StoreNotInitialized);
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = useContext(SearchDispatchContext);
  if (dispatch === undefined) {
    throw new CustomError('SearchStore', ErrorCodes.StoreNotInitialized);
  }
  return dispatch;
};

export const useSearchSuggestions = (): ((searchValue: string) => void) => {
  const dispatch = useDispatch();

  const getSearchSuggestions = useRef(
    debounce((searchValue: string): void => {
      dispatch({ type: 'Search/Suggestions/LoadInitiated' });
      getSearchSuggestionsApi(searchValue)
        .then(data => dispatch({ type: 'Search/Suggestions/Loaded', payload: data }))
        .catch(err => {
          dispatch({
            type: 'Search/Suggestions/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          });
        });
    }, 300)
  ).current;

  return getSearchSuggestions;
};

export const useSearchResults = (): (() => void) => {
  const state = useState();
  const dispatch = useDispatch();
  const [{ q, type, module }] = useSearchParams();

  const getSearchResults = useCallback(
    (queryParameters: SearchQueryParameters): void => {
      if (queryParameters.searchValue.length !== 0) {
        dispatch({ type: 'Search/Results/LoadInitiated' });
        getSearchResultsApi(queryParameters)
          .then(data =>
            dispatch({
              type: 'Search/Results/Loaded',
              payload: data,
            })
          )
          .catch(err =>
            dispatch({
              type: 'Search/Results/LoadFailed',
              payload: newStoreError(err.message, err.code, err),
            })
          );
      }
    },
    [dispatch]
  );

  const getModuleSearchResults = useCallback(
    (queryParameters: SearchQueryParameters): void => {
      if (queryParameters.searchValue.length !== 0) {
        dispatch({
          type: 'Search/ModuleResults/LoadInitiated',
        });
        getSearchResultsApi(queryParameters)
          .then(data =>
            dispatch({
              type: 'Search/ModuleResults/Loaded',
              payload: data,
            })
          )
          .catch(err =>
            dispatch({
              type: 'Search/ModuleResults/LoadFailed',
              payload: newStoreError(err.message, err.code, err),
            })
          );
      }
    },
    [dispatch]
  );

  const getMoreSearchResults = useCallback((): void => {
    const currentSearch = state.currentSearch;
    const currentResults = module ? state.moduleResults : state.results;

    if (
      !currentSearch ||
      !currentResults ||
      isLoading(currentResults) ||
      isStoreError(currentResults)
    ) {
      return;
    }

    dispatch({ type: 'Search/LoadMoreInitiated' });
    getSearchResultsApi({
      searchValue: currentSearch.searchValue,
      recordType: type,
      offset: currentResults.hits.length,
      limit: 10,
      module: module || undefined,
    })
      .then(data => {
        if (module) {
          dispatch({
            type: 'Search/ModuleResults/LoadedMore',
            payload: data,
          });
        } else {
          dispatch({
            type: 'Search/Results/LoadedMore',
            payload: data,
          });
        }
      })
      .catch(err => {
        dispatch({
          type: 'Search/LoadMoreFailed',
          payload: newStoreError(err.message, err.code, err),
        });
      });
  }, [state.currentSearch, state.moduleResults, state.results, module, dispatch, type]);

  useEffect(() => {
    if (q) {
      dispatch({ type: 'Search/SearchValue', payload: q });
      getSearchResults({ searchValue: q, recordType: type });
    }
  }, [dispatch, getSearchResults, q, type]);

  useEffect(() => {
    if (q && module) {
      getModuleSearchResults({ searchValue: q, recordType: type, module: module });
    } else if (!module) {
      dispatch({ type: 'Search/ModuleResults/Reset' });
    }
  }, [getModuleSearchResults, q, type, module, dispatch]);

  return getMoreSearchResults;
};
