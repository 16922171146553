import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { AccountInput } from 'Atoms/input/AccountInput';
import { P } from 'Atoms/text';
import { BreadcrumbSelect } from 'Molecules/select/BreadcrumbSelect';
import { SearchSelect } from 'Molecules/select/SearchSelect';
import styled, { css } from 'styled-components/macro';

export const Label = styled.label`
  color: ${props => props.theme.colors.text.inactive};
  font: ${props => props.theme.fonts.normalTextBold};
  margin-right: 10px;
`;

export const InlineInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const InlineLabel = styled(Label)`
  flex-grow: 1;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const inputStyles = css`
  width: 72%;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 15px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const StyledInput = styled(AccountInput)`
  ${inputStyles}
`;

const selectStyles = css`
  min-width: 175px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 15px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const StyledSelect = styled(BreadcrumbSelect)`
  ${selectStyles};
  .${props => props.classNamePrefix}__control {
    border: 1px solid ${props => props.theme.colors.settings.inputBorder};

    &--is-focused {
      border: 2px solid ${props => props.theme.colors.focus};
    }

    &--menu-is-open {
      border-width: 1px 1px 0px 1px;
    }
  }
`;

export const StyledButton = styled(InvisibleButton)`
  text-decoration: underline;
  color: ${props => props.theme.colors.settings.inputText};
  font: ${props => props.theme.fonts.normalText};
  cursor: pointer;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 15px;
  }
`;

export const StyledP = styled(P)`
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 15px;
  }
`;

export const StyledAsyncSelect = styled(SearchSelect)`
  max-width: 72%;

  .${props => props.classNamePrefix}__control {
    border: 1px solid ${props => props.theme.colors.settings.inputBorder};
    border-radius: 5px;
    padding: 1px 12px 1px 7px;
    font: ${props => props.theme.fonts.normalTextSemiBold};
    min-height: unset;

    .${props => props.classNamePrefix}__single-value {
      color: ${props => props.theme.colors.settings.inputText};
      font-weight: 500;
    }

    &--menu-is-open {
      border-radius: 5px 5px 0 0;
      border-width: 1px 1px 0px 1px;
      border-color: ${props => props.theme.colors.settings.inputBorder};
    }
  }

  .${props => props.classNamePrefix}__menu {
    border: solid ${props => props.theme.colors.settings.inputBorder};
    border-width: 0 1px 1px 1px;
    border-radius: 0 0 5px 5px;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 15px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 100%;
  }
`;
