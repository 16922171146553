import { MainLayout } from 'layouts/MainLayout';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { match } from 'react-router-dom';
import { useMobile } from 'services/useMobile';

import { DesktopAZ } from './DesktopAZ';
import { MobileAZ } from './MobileAZ';

interface Props {
  match: match;
}

export const AZ: FC<Props> = ({ match }) => {
  const smallerThanMedium = useMobile('m');

  return (
    <MainLayout>
      <Helmet>
        <title>A-Z - GIDEON</title>
      </Helmet>
      {smallerThanMedium ? <MobileAZ match={match} /> : <DesktopAZ match={match} />}
    </MainLayout>
  );
};
