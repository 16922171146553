import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'CountryNotes/SingleLoadInitiated': {
      return {
        ...state,
        countryNotes: { ...state.countryNotes, [action.payload.indentifier]: 'Loading' },
      };
    }
    case 'CountryNotes/SingleLoaded': {
      return {
        ...state,
        countryNotes: { ...state.countryNotes, [action.payload.indentifier]: action.payload.data },
      };
    }
    case 'CountryNotes/SingleLoadFailed': {
      return {
        ...state,
        countryNotes: { ...state.countryNotes, [action.payload.indentifier]: action.payload.error },
      };
    }
    case 'CountryNotes/Public/SingleLoadInitiated': {
      return {
        ...state,
        countryNotesPublic: {
          ...state.countryNotesPublic,
          [action.payload.indentifier]: 'Loading',
        },
      };
    }
    case 'CountryNotes/Public/SingleLoaded': {
      return {
        ...state,
        countryNotesPublic: {
          ...state.countryNotesPublic,
          [action.payload.indentifier]: action.payload.data,
        },
      };
    }
    case 'CountryNotes/Public/SingleLoadFailed': {
      return {
        ...state,
        countryNotesPublic: {
          ...state.countryNotesPublic,
          [action.payload.indentifier]: action.payload.error,
        },
      };
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
