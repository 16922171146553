import { Loader } from 'Atoms/Loader';
import { ErrorBoundary } from 'errorBoundary';
import { DumbCompareButton } from 'Molecules/buttons/CompareButton';
import { ContentError } from 'Molecules/ContentError';
import { ListItem } from 'Molecules/ListItem';
import React, { FC } from 'react';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading, Resource } from 'store/types';
import styled from 'styled-components/macro';
import { getComparisonListsSection } from 'types/comparisonLists';
import { SearchResult } from 'types/searchByModal';

const Results = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 37px;
`;

const ResultsTitle = styled.p`
  font: ${props => props.theme.fonts.bigText};
  color: ${props => props.theme.colors.text.main};
  margin: 0 0 10px 0;
`;

const ResultsCaption = styled.p`
  font: ${props => props.theme.fonts.normalText};
  color: ${props => props.theme.colors.text.main};
`;

const ResultsList = styled.div`
  margin-top: 20px;
  overflow: auto;
`;

const StyledCompareButton = styled(DumbCompareButton)`
  width: 15px;
  height: 15px;
  margin: 0px;
  display: inline-flex;
  transform: translateY(-3px);
  cursor: unset;
`;

const StyledListItem = styled(ListItem)`
  max-width: 450px;
`;

interface Props {
  total: number;
  results: Resource<SearchResult[]>;
  isInitial: boolean;
  type: string;
  hideCompareButton?: boolean;
}

const SearchByModalResultsBase: FC<Props> = ({
  total,
  results,
  type,
  isInitial,
  hideCompareButton,
}) => {
  if (isLoading(results)) {
    return <Loader />;
  }

  assertIsNotStoreError(results);

  if (isInitial) {
    return (
      <Results>
        <ResultsTitle>Select for results</ResultsTitle>
      </Results>
    );
  }

  if (results.length === 0) {
    return (
      <Results>
        <ResultsTitle>No results found</ResultsTitle>
      </Results>
    );
  }

  return (
    <Results>
      <ResultsTitle
        role="alert"
        aria-live="polite"
      >{`${results.length} of ${total} ${type} match your search!`}</ResultsTitle>
      <ResultsCaption>
        Click on the title to open {type} record.
        {!hideCompareButton && (
          <>
            Click <StyledCompareButton clicked={false} /> to add to the comparison table.
          </>
        )}
      </ResultsCaption>
      <ResultsList>
        {results.map(result => (
          <StyledListItem
            key={result.id}
            name={result.name}
            to={`/explore/${type}/${result.id}`}
            compareButtonSection={getComparisonListsSection(type)}
            compareButtonId={result.id}
          />
        ))}
      </ResultsList>
    </Results>
  );
};

export const SearchByModalResults: FC<Props> = props => (
  <ErrorBoundary error={props => <ContentError title="SearchByModalResults" {...props} />}>
    <SearchByModalResultsBase {...props} />
  </ErrorBoundary>
);
