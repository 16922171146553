import { AccountSettings } from 'types/accountSettings';

import { AccountSettingsDTO } from './DTO';

export const mapAccountSettingsDTO = (data: AccountSettingsDTO): AccountSettings => ({
  accountType: data?.account_type,
  institution: data?.institution,
  title: data?.title ?? '',
  firstName: data?.first_name ?? '',
  lastName: data?.last_name ?? '',
  email: data?.email ?? '',
  defaultPage: data?.default_page ?? null,
  defaultColorTheme: data?.default_color_theme ?? null,
  subscriptionExpireDate: data?.subscription_expire_date ?? '',
  paperSize: data.paper_size ?? '',
});

export const mapAccountSettings = (data: AccountSettings): AccountSettingsDTO => ({
  account_type: data.accountType,
  title: data.title,
  first_name: data.firstName,
  last_name: data.lastName,
  email: data.email,
  default_page: data.defaultPage,
  default_color_theme: data.defaultColorTheme,
  subscription_expire_date: data.subscriptionExpireDate,
  paper_size: data.paperSize,
});
