import { ReactComponent as DiseasesIcon } from 'assets/ExploreFocus/Diseases.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import { DownloadsSection } from 'Molecules/section/DownloadsSection';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import { NotFound } from 'pages/404/';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { usePushState } from 'services/usePushState.hook';
import { useCountryNotes } from 'store/countryNotesStore/hooks';
import { useDiseaseDetails } from 'store/diseaseDetailStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';

const bioterrorismCountryCode = 'G90';

type Props = RouteComponentProps & { diseaseId: number };

const StyledButton = styled(FilledButton)`
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
  & > span {
    display: flex;
    flex-direction: column;
  }
`;

const StyledIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  margin-bottom: 4px;
`;

export const BioterrorismNotes: FC<Props> = ({ match, diseaseId }) => {
  const { push } = usePushState();
  const [showNotes, setShowNotes] = useState(true);
  const [showDownloads, setShowDownloads] = useState(true);

  const disease = useDiseaseDetails(diseaseId);

  const notes = useCountryNotes(diseaseId, bioterrorismCountryCode);

  if (isLoading(disease) || isLoading(notes)) {
    return <Loader />;
  }

  assertIsNotStoreError(disease);
  assertIsNotStoreError(notes);

  if (!disease.countries.isBioterrorismAvailable) {
    return <NotFound />;
  }

  const sections: RecordLink[] = [
    { label: 'Bioterror note', 'aria-label': `${disease.details.disease} bioterror note` },
    {
      label: 'Downloads and references',
      'aria-label': `${disease.details.disease} bioterror downloads and references`,
    },
  ];

  const title = `${disease.details.disease} as an agent of bioterrorism`;

  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>{title} - GIDEON</title>
        </Helmet>
        <ReferenceLabelContext.Provider value={`${disease.details.disease} bioterror`}>
          <RecordLayout>
            <RecordTitle
              title={title}
              buttons={
                <StyledButton
                  onClick={() => {
                    const path = match.url.split('/');
                    push(path.filter((_, i) => i < path.length - 1).join('/'));
                  }}
                  size="micro"
                >
                  <StyledIcon svgComponent={DiseasesIcon} />
                  Disease
                </StyledButton>
              }
            />
            <RecordLinks links={sections} />
            <RecordSection
              link={sections[0]}
              show={showNotes}
              onCollapse={() => setShowNotes(!showNotes)}
              accent="first"
              id="bioterrorism-note"
            >
              <DynamicHtml content={notes.note} />
            </RecordSection>
            <RecordSection
              link={sections[1]}
              show={showDownloads}
              onCollapse={() => setShowDownloads(!showDownloads)}
              accent="second"
              id="bioterrorism-download"
            >
              <DownloadsSection id={`${diseaseId}/G90`} category="diseases" labelTitle={title} />
            </RecordSection>
          </RecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};
