import { BreadcrumbLink } from 'Atoms/breadcrumb/BreadcrumbLink';
import { Span } from 'Atoms/text';
import { BreadcrumbSelect } from 'Molecules/select/BreadcrumbSelect';
import React, { FC, ReactElement, useState } from 'react';
import { useHistory } from 'react-router';
import { useMobile } from 'services/useMobile';
import { PageNameStateOptional, usePushState } from 'services/usePushState.hook';
import styled from 'styled-components/macro';
import { BreadcrumbLevel, BreadcrumbLevelMobile, BreadcrumbSelectLoading } from 'types/breadcrumb';
import { countriesSelectFilter } from 'utils/countriesSelectFilter';

const BreadcrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BreadcrumbLinkStyled = styled(BreadcrumbLink)`
  margin-left: 10px;
`;

const BreadcrumbLinkMobileStyled = styled(BreadcrumbLink)`
  &:not(:first-child) {
    margin-left: 10px;
  }
`;

const BreadcrumbBackButton = styled(BreadcrumbLink)`
  margin-right: 20px;
`;

const BreadcrumbSelectStyled = styled(BreadcrumbSelect)`
  &:not(:first-child) {
    margin-left: 10px;
  }

  &.breadcrumb-select-container-3,
  &.breadcrumb-select-container-4 {
    .${props => props.classNamePrefix}__control {
      min-width: 310px;
    }
  }
`;

const DesktopContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const MobileContainer = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: center;
`;

export interface Props {
  className?: string;
  desktopLevels: BreadcrumbLevel[];
  mobileLevels: BreadcrumbLevelMobile[];
}

const BreadcrumbBase: FC<Props> = ({ className, desktopLevels, mobileLevels }) => {
  const { push } = usePushState();
  const { location, goBack } = useHistory<PageNameStateOptional>();
  const values = location.pathname.split('/').slice(1);
  const [selectLoading, setIsSelectLoading] = useState<BreadcrumbSelectLoading>({
    isLoading: false,
    index: -1,
  });

  const isDesktop = useMobile('m', 'min');
  const isTablet = useMobile('l', 'max');

  const getSingleLevelDesktop = (level: BreadcrumbLevel, index: number): ReactElement | null => {
    switch (level.type) {
      case 'back': {
        return location.state?.pageName ? (
          <BreadcrumbBackButton onClick={goBack} showIcon key={level.type}>
            {isTablet ? 'Back' : location.state.pageName}
          </BreadcrumbBackButton>
        ) : null;
      }
      case 'text':
        return (
          <Span key={level.label} color="navbar">
            {level.label}
          </Span>
        );
      case 'select': {
        return (
          <BreadcrumbSelectStyled
            classNamePrefix="breadcrumb-select"
            className={`breadcrumb-select-container-${index}`}
            key={level.key}
            options={level.options}
            onChange={option =>
              level.onChange(option, push, state => setIsSelectLoading({ isLoading: state, index }))
            }
            value={level.value(values)}
            isSearchable={level.isSearchable}
            filterOption={countriesSelectFilter}
            isLoading={index === selectLoading.index && selectLoading.isLoading}
            ariaLabel={level.ariaLabel}
          />
        );
      }
      case 'link':
        return (
          <BreadcrumbLinkStyled showIcon={false} key={level.label} onClick={() => push(level.path)}>
            {level.label}
          </BreadcrumbLinkStyled>
        );
    }
  };

  const getSingleLevelMobile = (level: BreadcrumbLevelMobile): ReactElement => {
    switch (level.type) {
      case 'back': {
        return (
          <BreadcrumbLinkMobileStyled showIcon key={level.type} fontWeight="400" onClick={goBack}>
            Back
          </BreadcrumbLinkMobileStyled>
        );
      }
      case 'link': {
        return (
          <BreadcrumbLinkMobileStyled
            key={level.label}
            fontWeight="400"
            onClick={() => push(level.path)}
          >
            {level.label}
          </BreadcrumbLinkMobileStyled>
        );
      }
    }
  };

  return (
    <BreadcrumbContainer className={className}>
      {isDesktop ? (
        <DesktopContainer>{desktopLevels.map(getSingleLevelDesktop)}</DesktopContainer>
      ) : (
        <MobileContainer>{mobileLevels.map(getSingleLevelMobile)}</MobileContainer>
      )}
    </BreadcrumbContainer>
  );
};

export const Breadcrumb = styled(BreadcrumbBase)``;
