import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * `useRouteChange` executes the callback when route changes.
 * @param `callback` callback to execute on route change.
 */
export const useRouteChange = (callback: () => void): void => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      callback();
    });

    return () => {
      unlisten();
    };
  }, [callback, history]);
};
