import { getCountries } from 'apiServices/Diseases/fingerprint';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useAccountController } from 'store/accountStore/hooks';
import { assertIsNotStoreError, newStoreError } from 'store/storeError';
import { Dispatch, isLoading, Loading, Resource } from 'store/types';
import { GroupedData } from 'types/az';
import { Country, CountryWithSlug } from 'types/country';
import { groupDataAlphabetically } from 'utils/getGroupedData';

import {
  Action,
  Countries,
  CountriesDispatchContext,
  CountriesStateContext,
  State,
} from './provider';

export const useState = (): State => {
  const state = React.useContext(CountriesStateContext);
  if (state === undefined) {
    throw new Error('country state is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(CountriesDispatchContext);
  if (dispatch === undefined) {
    throw new Error('country state is not initialized');
  }
  return dispatch;
};

export const useCountriesResource = (): Resource<Countries> => {
  const state = useState();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAccountController();

  useEffect(() => {
    if (!state) {
      dispatch({ type: 'Countries/LoadInitiated' });
      getCountries(!isAuthenticated)
        .then(data => dispatch({ type: 'Countries/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Countries/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    }
  }, [state, dispatch, isAuthenticated]);

  return state || Loading;
};

export const useCountriesGrouped = (): GroupedData => {
  const countries = useCountriesResource();

  assertIsNotStoreError(countries);

  return isLoading(countries)
    ? { data: groupDataAlphabetically([]), total: 0 }
    : { data: groupDataAlphabetically(countries.data), total: countries.data.length };
};

export const useCountriesList = (): Country[] => {
  const countries = useCountriesResource();

  assertIsNotStoreError(countries);

  return useMemo(() => (isLoading(countries) ? [] : countries.data), [countries]);
};

export const useGetCountryList = (): (() => CountryWithSlug[]) => {
  const state = useState();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAccountController();

  const getCountryList = useCallback(() => {
    if (!state) {
      dispatch({ type: 'Countries/LoadInitiated' });
      getCountries(!isAuthenticated)
        .then(data => dispatch({ type: 'Countries/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Countries/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    }

    assertIsNotStoreError(state);

    return isLoading(state) || !state ? [] : state.data;
  }, [state, dispatch, isAuthenticated]);

  return getCountryList;
};
