import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Loader } from 'Atoms/Loader';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

interface Props {
  loadMore: () => void;
  isLoading: boolean;
}
const LoadMoreButton = styled(FilledButton)`
  max-width: 200px;
  margin: 0 auto 10px;
`;

const LoadMoreDetectorWrapper = styled.div`
  position: relative;
  margin-top: 10px;
`;

export const LoadMoreDetector: FC<Props> = ({ loadMore, isLoading }) => (
  <LoadMoreDetectorWrapper>
    {isLoading ? (
      <Loader />
    ) : (
      <LoadMoreButton onClick={loadMore} disabled={isLoading}>
        Load more
      </LoadMoreButton>
    )}
  </LoadMoreDetectorWrapper>
);
