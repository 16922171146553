import {
  getComparedBacteria,
  getComparedDiseases,
  getComparedDrugs,
  getComparedMycobacteria,
  getComparedYeasts,
} from 'apiServices/Compare/compare';
import React, { useEffect } from 'react';
import { useState as useCompareListState } from 'store/ComparisonListsStore/hooks';
import { newStoreError } from 'store/storeError';
import { Dispatch, Resource } from 'store/types';
import { CompareDisease, CompareDrug, CompareMicrobe } from 'types/compare';

import { Action, CompareDispatchContext, CompareStateContext, State } from './provider';

export const useState = (): State => {
  const state = React.useContext(CompareStateContext);
  if (state === undefined) {
    throw new Error('compare state is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(CompareDispatchContext);
  if (dispatch === undefined) {
    throw new Error('compare state is not initialized');
  }
  return dispatch;
};

export const useCompareDiseases = (): Resource<CompareDisease>[] => {
  const dispatch = useDispatch();
  const { diseases } = useState();
  const { diseases: ids } = useCompareListState();

  useEffect(() => {
    if (ids.some(id => !diseases[id])) {
      const idsToRequest = ids.filter(id => !diseases[id]);
      dispatch({ type: 'Compare/Diseases/LoadInitiated', payload: idsToRequest });
      getComparedDiseases(idsToRequest)
        .then(data => dispatch({ type: 'Compare/Diseases/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Compare/Diseases/LoadFailed',
            payload: { error: newStoreError(err.message, err.code, err), ids: idsToRequest },
          })
        );
    }
  }, [diseases, dispatch, ids]);

  return ids.map(id => diseases[id]);
};

export const useCompareDrugs = (): Resource<CompareDrug>[] => {
  const dispatch = useDispatch();
  const { drugs } = useState();
  const { drugs: ids } = useCompareListState();

  useEffect(() => {
    if (ids.some(id => !drugs[id])) {
      const idsToRequest = ids.filter(id => !drugs[id]);
      getComparedDrugs(idsToRequest)
        .then(data => {
          dispatch({ type: 'Compare/Drugs/Loaded', payload: data });
        })
        .catch(err =>
          dispatch({
            type: 'Compare/Drugs/LoadFailed',
            payload: { error: newStoreError(err.message, err.code, err), ids: idsToRequest },
          })
        );
    }
  }, [drugs, dispatch, ids]);

  return ids.map(id => drugs[id]);
};

export const useCompareBacteria = (): Resource<CompareMicrobe>[] => {
  const dispatch = useDispatch();
  const { bacteria } = useState();
  const { bacteria: ids } = useCompareListState();

  useEffect(() => {
    if (ids.some(id => !bacteria[id])) {
      const idsToRequest = ids.filter(id => !bacteria[id]);
      dispatch({ type: 'Compare/Bacteria/LoadInitiated', payload: idsToRequest });
      getComparedBacteria(idsToRequest)
        .then(data => dispatch({ type: 'Compare/Bacteria/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Compare/Bacteria/LoadFailed',
            payload: { error: newStoreError(err.message, err.code, err), ids: idsToRequest },
          })
        );
    }
  }, [bacteria, dispatch, ids]);

  return ids.map(id => bacteria[id]);
};

export const useCompareMycobacteria = (): Resource<CompareMicrobe>[] => {
  const dispatch = useDispatch();
  const { mycobacteria } = useState();
  const { mycobacteria: ids } = useCompareListState();

  useEffect(() => {
    if (ids.some(id => !mycobacteria[id])) {
      const idsToRequest = ids.filter(id => !mycobacteria[id]);
      dispatch({ type: 'Compare/Mycobacteria/LoadInitiated', payload: idsToRequest });
      getComparedMycobacteria(idsToRequest)
        .then(data => dispatch({ type: 'Compare/Mycobacteria/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Compare/Mycobacteria/LoadFailed',
            payload: { error: newStoreError(err.message, err.code, err), ids: idsToRequest },
          })
        );
    }
  }, [mycobacteria, dispatch, ids]);

  return ids.map(id => mycobacteria[id]);
};

export const useCompareYeasts = (): Resource<CompareMicrobe>[] => {
  const dispatch = useDispatch();
  const { yeasts } = useState();
  const { yeasts: ids } = useCompareListState();

  useEffect(() => {
    if (ids.some(id => !yeasts[id])) {
      const idsToRequest = ids.filter(id => !yeasts[id]);
      dispatch({ type: 'Compare/Yeasts/LoadInitiated', payload: idsToRequest });
      getComparedYeasts(idsToRequest)
        .then(data => dispatch({ type: 'Compare/Yeasts/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Compare/Yeasts/LoadFailed',
            payload: { error: newStoreError(err.message, err.code, err), ids: idsToRequest },
          })
        );
    }
  }, [yeasts, dispatch, ids]);

  return ids.map(id => yeasts[id]);
};
