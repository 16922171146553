import { InlineInputWrapper, Label, StyledP } from 'Molecules/account/AccountFormInput';
import { Subsection } from 'Molecules/account/Subsection';
import moment from 'moment';
import { Props } from 'Organisms/AccountForm';
import React, { FC } from 'react';

export const Subscription: FC<Props> = ({ inputState }) => (
  <Subsection title="Subscription">
    <InlineInputWrapper>
      <Label>Ends</Label>
      <StyledP>{moment(inputState.subscriptionExpireDate).format('MMMM DD, YYYY')}</StyledP>
    </InlineInputWrapper>
  </Subsection>
);
