import 'react-image-gallery/styles/css/image-gallery.css';
import 'core-js/features/object/from-entries';
import 'core-js/features/array/flat';
import 'core-js/features/array/flat-map';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { Login } from 'Auth/pages/SignIn';
import { MagicLinkLogin } from 'Auth/pages/SignIn/MagicLinkLogin';
import { TokenLogin } from 'Auth/pages/SignIn/tokenLogin';
import { TrendingStoreProvider } from 'Explore/store/trendingStore/provider';
import { PhenotypicClassStoreProvider } from 'Lab/store/phenotypicClassStore/provider';
import { ConfirmEmail } from 'pages/ConfirmEmail';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactPixel from 'react-facebook-pixel';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import TagManager from 'react-gtm-module';
import Modal from 'react-modal';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { AxiosProvider } from 'services/axios.service';
import { AuthTokenProvider } from 'services/localStorage/AuthToken.provider';
import { ComparisonListsLocalStorageProvider } from 'services/localStorage/comparisonLocalStorage.provider';
import { DefaultStartPageProvider } from 'services/localStorage/defaultStartPage.provider';
import { LinkExpiredModal, ModalContextProvider } from 'services/localStorage/ExpiredLink.provider';
import {
  IdleModal,
  IdleModalContextProvider,
  IdleTimerStorageProvider,
} from 'services/localStorage/idleTimer.provider';
import { MagicTokenProvider } from 'services/localStorage/MagicToken.provider';
import { MotionProvider } from 'services/motion.service';
import { ServiceWorkerWrapper } from 'services/serviceWorkerWrapper';
import { ThemeProvider } from 'services/theme';
import { AbbreviationStoreProvider } from 'store/abbreviationsStore/provider';
import { AccountStoreProvider } from 'store/accountStore/provider';
import { ChartStoreProvider } from 'store/chartsStore/provider';
import { CompareStoreProvider } from 'store/ComparisonStore/provider';
import { CountriesStoreProvider } from 'store/countriesStore/provider';
import { CountryDetailsStoreProvider } from 'store/countryDetailsStore/provider';
import { CountryLatestOutbreaksStoreProvider } from 'store/countryLatestOutbreaksStore/provider';
import { CountryNotesStoreProvider } from 'store/countryNotesStore/provider';
import { DiseaseDetailsStoreProvider } from 'store/diseaseDetailStore/provider';
import { DiseaseDistributionStoreProvider } from 'store/diseaseDistributionStore/provider';
import { DiseaseOutbreaksStoreProvider } from 'store/diseaseOutbreaksStore/provider';
import { DiseasesGroupStoreProvider } from 'store/diseasesGroupStore/provider';
import { DiseasesStoreProvider } from 'store/diseasesStore/provider';
import { DrugDetailsStoreProvider } from 'store/drugDetailsStore/provider';
import { DrugsStoreProvider } from 'store/drugsStore/provider';
import { FingerprintDiseasesStoreProvider } from 'store/fingerprintDiseasesStore/provider';
import { FingerprintPropertiesStoreProvider } from 'store/fingerprintPropertiesStore/provider';
import { LegalModalProvider } from 'store/legalModalStore/provider';
import { MapControlStoreProvider } from 'store/mapControlStore/provider';
import { MicrobeDetailsStoreProvider } from 'store/microbeDetailsStore/provider';
import { MicrobesStoreProvider } from 'store/microbesStore/provider';
import { OutbreaksStoreProvider } from 'store/outbreaksStore/provider';
import { ReferencesStoreProvider } from 'store/referencesStore/provider';
import { RegionsStoreProvider } from 'store/regionStore/provider';
import { SearchStoreProvider } from 'store/searchStore/provider';
import { VaccinesCoverageStoreProvider } from 'store/vaccineCoverageStore/provider';
import { VaccineDetailsStoreProvider } from 'store/vaccineDetailsStore/provider';
import { VaccinesStoreProvider } from 'store/vaccinesStore/provider';
import { VirusDetailsStoreProvider } from 'store/virusDetailStore/provider';
import { ToastContainer } from 'ToastContainer';
import { ContentsStoreProvider } from 'Updates/store/contentsStore/provider';

import { App } from './App';
import { ErrorBoundary } from './errorBoundary';
import { GlobalStyle } from './globalStyle';
import { ProvidersInjector } from './ProvidersInjector';
import { ScrollToTop } from './utils/scrollToTop';

Modal.setAppElement('#root');

ReactPixel.init(process.env.REACT_APP_PIXEL_ID || '');
ReactPixel.pageView();

if (process.env.REACT_APP_GTM_ID && process.env.REACT_APP_GTM_ID.length > 0) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

const storeProviders = [
  AuthTokenProvider,
  MagicTokenProvider,
  IdleTimerStorageProvider,
  IdleModalContextProvider,
  ModalContextProvider,
  AxiosProvider,
  AccountStoreProvider,
  ComparisonListsLocalStorageProvider,
  DefaultStartPageProvider,
  DiseasesStoreProvider,
  DiseasesGroupStoreProvider,
  DiseaseDetailsStoreProvider,
  DiseaseOutbreaksStoreProvider,
  MicrobesStoreProvider,
  FingerprintPropertiesStoreProvider,
  FingerprintDiseasesStoreProvider,
  CountriesStoreProvider,
  VaccinesStoreProvider,
  DrugsStoreProvider,
  DrugDetailsStoreProvider,
  ReferencesStoreProvider,
  VaccineDetailsStoreProvider,
  MicrobeDetailsStoreProvider,
  VirusDetailsStoreProvider,
  CountryNotesStoreProvider,
  ChartStoreProvider,
  CountryDetailsStoreProvider,
  CompareStoreProvider,
  SearchStoreProvider,
  DiseaseDistributionStoreProvider,
  AbbreviationStoreProvider,
  OutbreaksStoreProvider,
  VaccinesCoverageStoreProvider,
  CountryLatestOutbreaksStoreProvider,
  LegalModalProvider,
  MotionProvider,
  ContentsStoreProvider,
  PhenotypicClassStoreProvider,
  TrendingStoreProvider,
  MapControlStoreProvider,
  RegionsStoreProvider,
];

ReactDOM.render(
  <ServiceWorkerWrapper>
    <ErrorBoundary error={null}>
      <Router>
        <ScrollToTop />
        <ProvidersInjector providers={storeProviders}>
          <ThemeProvider>
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              useRecaptchaNet
            >
              <GlobalStyle />
              <Switch>
                <Route exact path="/confirm-email" component={ConfirmEmail} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/login/token" component={TokenLogin} />
                <Route exact path="/magiclink/:magichash" component={MagicLinkLogin} />
                <Route component={App} />
              </Switch>
            </GoogleReCaptchaProvider>
            <LinkExpiredModal />
            <ToastContainer />
            <IdleModal />
          </ThemeProvider>
        </ProvidersInjector>
      </Router>
    </ErrorBoundary>
  </ServiceWorkerWrapper>,
  document.getElementById('root')
);
