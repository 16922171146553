import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { DiseaseDistribution } from 'types/diseaseDistribution';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'DiseaseDistribution/SingleLoadInitiated', { id: number }>
  | ActionWithPayload<'DiseaseDistribution/SingleLoaded', { id: number; data: DiseaseDistribution }>
  | ActionWithPayload<'DiseaseDistribution/SingleLoadFailed', { id: number; error: StoreError }>;

export type State = {
  [id: number]: Resource<DiseaseDistribution> | undefined;
};

const initialState: State = {};

export const {
  dispatchContext: DiseaseDistributionDispatchContext,
  stateContext: DiseaseDistributionStateContext,
  provider: DiseaseDistributionStoreProvider,
} = storeFactory(reducer, initialState);
