import { AssociatedInfectionsSurveys } from 'Organisms/tables/AssociatedInfectionsSurveys';
import { CaseSeries } from 'Organisms/tables/CaseSeries';
import { Crossborders } from 'Organisms/tables/Crossborders';
import { NotableOutbreaks } from 'Organisms/tables/NotableOutbreaks';
import { PrevalenceSurveys } from 'Organisms/tables/PrevalenceSurveys';
import { SeroprevalenceSurveys } from 'Organisms/tables/SeroprevalenceSurveys';
import { SporadicEvents } from 'Organisms/tables/SporadicEvents';
import React, { FC } from 'react';
import { TableNode } from 'types/dynamicContent';
import { CustomError, ErrorCodes } from 'types/errorTypes';

interface Props {
  tableType: TableNode['tableType'];
  diseaseId: string;
  countryId: string;
  title: string;
  subTitle?: string;
}

export const DynamicTable: FC<Props> = ({
  tableType,
  diseaseId: diseaseIdString,
  countryId,
  title,
  subTitle,
}) => {
  const diseaseId = parseInt(diseaseIdString, 10);
  if (Number.isNaN(diseaseId) || diseaseId <= 0) {
    throw new CustomError('Bad disease ID', ErrorCodes.DynamicContentBadAttribute, 'Error');
  }

  switch (tableType) {
    case 'crossbordersTable': {
      return <Crossborders diseaseId={diseaseId} countryId={countryId} title={title} />;
    }
    case 'prevalenceSurveyTable': {
      return <PrevalenceSurveys diseaseId={diseaseId} countryId={countryId} title={title} />;
    }
    case 'seroprevalenceSurveyTable': {
      return (
        <SeroprevalenceSurveys
          diseaseId={diseaseId}
          countryId={countryId}
          title={title}
          subTitle={subTitle}
        />
      );
    }
    case 'associatedInfectionsSurveyTable': {
      return (
        <AssociatedInfectionsSurveys
          diseaseId={diseaseId}
          countryId={countryId}
          title={title}
          subTitle={subTitle}
        />
      );
    }
    case 'caseSeriesTable': {
      return (
        <CaseSeries diseaseId={diseaseId} countryId={countryId} title={title} subTitle={subTitle} />
      );
    }
    case 'notableOutbreaksTable': {
      return <NotableOutbreaks diseaseId={diseaseId} countryId={countryId} title={title} />;
    }
    case 'sporadicEventsTable': {
      return <SporadicEvents diseaseId={diseaseId} countryId={countryId} title={title} />;
    }
  }
};
