import { SearchResult } from 'Molecules/search/SearchResult';
import React, { FC } from 'react';
import { getSearchResultPath } from 'services/searchRouteParser.service';
import styled from 'styled-components/macro';
import { SearchHit } from 'types/search';

import { LoadMoreDetector } from './LoadMoreDetector';

const SearchResultsStyled = styled.div`
  width: 100%;
`;

const SearchResultStyled = styled(SearchResult)`
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

interface Props {
  className?: string;
  results: SearchHit[];
  totalResults: number;
  loadMore: () => void;
  isLoadingMore: boolean;
}

export const SearchResults: FC<Props> = ({
  className,
  results,
  loadMore,
  isLoadingMore,
  totalResults,
}) => (
  <SearchResultsStyled className={className}>
    {results.map(result => (
      <SearchResultStyled
        key={result.id}
        path={getSearchResultPath(result.id)}
        title={result.fields.title}
        description={result.highlight.description}
        recordType={result.fields.recordType}
      />
    ))}
    {results.length < totalResults && (
      <LoadMoreDetector loadMore={loadMore} isLoading={isLoadingMore} />
    )}
  </SearchResultsStyled>
);
