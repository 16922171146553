import { Loader } from 'Atoms/Loader';
import { ErrorBoundary } from 'errorBoundary';
import { ContentError } from 'Molecules/ContentError';
import { ExploreLandingModal } from 'Molecules/exploreLanding/ExploreLandingModal';
import { SearchByModalResults } from 'Molecules/SearchByModalResults';
import { SearchSelect } from 'Molecules/select/SearchSelect';
import React, { FC } from 'react';
import { ActionMeta, OnChangeValue } from 'react-select';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading, Resource } from 'store/types';
import styled from 'styled-components/macro';
import { SearchResult } from 'types/searchByModal';
import { SelectOption } from 'types/select';

interface ContentProps {
  options: Resource<SelectOption[]> | null;
  placeholder: string;
  onChange?: (
    value: OnChangeValue<SelectOption, false>,
    actionMeta: ActionMeta<SelectOption>
  ) => void;
  results: Resource<SearchResult[]>;
  type: string;
  total: number;
  value?: SelectOption | null;
  hideCompareButton?: boolean;
}

interface Props extends ContentProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  subTitle?: string;
}

const SelectStyled = styled(SearchSelect)`
  max-width: 400px;
`;

const SearchByModalContent: FC<ContentProps> = ({
  options,
  placeholder,
  onChange,
  results,
  type,
  total,
  value,
  hideCompareButton,
}) => {
  if (isLoading(options)) {
    return <Loader />;
  }

  assertIsNotStoreError(options);

  return (
    <>
      <SelectStyled
        classNamePrefix="search-select"
        placeholder={placeholder}
        showDropdown
        showIcon
        options={options || []}
        value={value}
        onChange={onChange}
        openMenuOnClick={false}
        isClearable
      />
      <SearchByModalResults
        isInitial={!value}
        total={total}
        type={type}
        results={results}
        hideCompareButton={hideCompareButton}
      />
    </>
  );
};

export const SearchByModal: FC<Props> = ({ isOpen, onClose, title, subTitle, ...rest }) => (
  <ExploreLandingModal title={title} subTitle={subTitle} isOpen={isOpen} onClose={onClose}>
    <ErrorBoundary error={props => <ContentError title="SearchByModal" {...props} />}>
      <SearchByModalContent {...rest} />
    </ErrorBoundary>
  </ExploreLandingModal>
);
