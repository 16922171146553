import { getAriaSort } from 'Atoms/buttons/SortButton';
import { Loader } from 'Atoms/Loader';
import { P } from 'Atoms/text';
import { Tooltip } from 'Atoms/tooltip/Tooltip';
import { CellWithSort } from 'Molecules/compare/CellWithSort';
import { CompareDrugKey } from 'Molecules/compare/CompareDrugKey';
import { CompareTableLink } from 'Molecules/compare/CompareTableLink';
import {
  CompareTableBase,
  StickyFirstTH,
  StickyTH,
  StyledCloseButton,
  StyledTD,
  StyledTH,
  TR,
} from 'Organisms/compare/CompareTableBase';
import React, { FC, useMemo, useState } from 'react';
import { getKeyInfo } from 'services/compare/getCompareDrugData';
import styled from 'styled-components/macro';
import { CompareColumnDefinition, CompareDrugKeyType, CompareDrugRows } from 'types/compare';
import { SortState } from 'types/table';
import { sortDrugsRows } from 'utils/sortTableRows';

const StyledLink = styled(CompareTableLink)`
  text-align: center;
`;

const HeadingIcons = styled.div`
  display: flex;
  margin-top: 14px;
  justify-content: space-evenly;
`;

const HeadingIcon = styled(CompareDrugKey)`
  width: 15px;
  height: 15px;
  margin: 0 3px;
  fill: ${props => props.theme.colors.table.compareTable.headingText};
`;

const StyledTooltip = styled(Tooltip)`
  top: 25px;
  left: -78px;
`;

const DrugKeyWrapper = styled.div`
  position: relative;
  &:hover {
    ${StyledTooltip} {
      display: block;
    }
  }
  &:last-child {
    ${StyledTooltip} {
      left: -115px;
    }
  }
`;

const StyledDrugKey = styled(CompareDrugKey)`
  margin: 0 3px;
`;

const TH = styled(StyledTH)`
  padding: 25px 18px 15px 18px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 25px 18px 15px 18px;
  }
`;

const CellKeys = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const StyledStickyTH = styled(StickyTH)`
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    min-width: 200px;
    max-width: 200px;
    padding: 8px 20px 8px 8px;
  }
`;

const StyledTR = styled(TR)`
  &:last-child {
    ${StyledTooltip} {
      top: -60px;
    }
  }
`;

interface Props {
  columns: CompareColumnDefinition[];
  rows: CompareDrugRows;
  onRemove(id: number): void;
}

export const CompareDrugsTable: FC<Props> = ({ columns, rows, onRemove }) => {
  const [sort, setSort] = useState<SortState>({ asc: false, columnIndex: -1 });

  const renderTHead = useMemo(() => {
    const onSortClick = (index: number): void => {
      if (index === sort.columnIndex) {
        setSort({ asc: !sort.asc, columnIndex: index });
      } else {
        setSort({ asc: true, columnIndex: index });
      }
    };
    return (
      <>
        <StickyFirstTH wrap aria-sort={getAriaSort(0 !== sort.columnIndex, sort.asc)}>
          <CellWithSort
            text="Spectra"
            onClick={() => onSortClick(0)}
            clicked={0 === sort.columnIndex}
            asc={sort.asc}
          />
        </StickyFirstTH>
        {columns.map((c, i) => {
          if (c.column === 'Loading') {
            return (
              <TH key={i} wrap={columns[i].enableWrap} width={`${100 / (columns.length + 1)}%`}>
                <Loader />
              </TH>
            );
          }

          const { category, id, name } = c.column;
          return (
            <TH key={i} wrap={columns[i].enableWrap} width={`${100 / (columns.length + 1)}%`}>
              <StyledCloseButton onClick={() => onRemove(id)} />
              <StyledLink to={`/explore/${category}/${id}`} linkText={name} />
              <HeadingIcons>
                <DrugKeyWrapper>
                  <HeadingIcon type="Star" />
                  <StyledTooltip>{getKeyInfo('Star')}</StyledTooltip>
                </DrugKeyWrapper>
                <DrugKeyWrapper>
                  <HeadingIcon type="Check" />
                  <StyledTooltip>{getKeyInfo('Check')}</StyledTooltip>
                </DrugKeyWrapper>
                <DrugKeyWrapper>
                  <HeadingIcon type="Combine" />
                  <StyledTooltip>{getKeyInfo('Combine')}</StyledTooltip>
                </DrugKeyWrapper>
                <DrugKeyWrapper>
                  <HeadingIcon type="Surgery" />
                  <StyledTooltip>{getKeyInfo('Surgery')}</StyledTooltip>
                </DrugKeyWrapper>
              </HeadingIcons>
            </TH>
          );
        })}
      </>
    );
  }, [columns, onRemove, sort]);

  const renderTBody = useMemo(() => {
    const drugsCount = columns.length;

    const rowsArray = Object.keys(rows).map(key => rows[key]);

    const sortedRows = sortDrugsRows(rowsArray, sort.asc, sort.columnIndex);

    return sortedRows.map((row, i) => {
      const agent = row.microbiologyAgent;

      return (
        <StyledTR key={i}>
          <StyledStickyTH key={i}>
            {agent ? (
              <StyledLink
                to={`/explore/microbes/${agent.type}/${agent.code}`}
                linkText={row.label.toString()}
              />
            ) : (
              row.label
            )}
          </StyledStickyTH>
          {row.drugColumns.slice(0, drugsCount).map((cell, j) => {
            return (
              <StyledTD key={j}>
                <CellKeys>
                  {cell &&
                    cell.map((x, k) => {
                      let type: CompareDrugKeyType | null = null;
                      if (x) {
                        type =
                          k === 0 ? 'Star' : k === 1 ? 'Check' : k === 2 ? 'Combine' : 'Surgery';
                      }
                      return (
                        <DrugKeyWrapper key={k}>
                          <StyledDrugKey type={type} />
                          {type && <StyledTooltip>{getKeyInfo(type)}</StyledTooltip>}
                        </DrugKeyWrapper>
                      );
                    })}
                </CellKeys>
              </StyledTD>
            );
          })}
        </StyledTR>
      );
    });
  }, [rows, columns, sort]);

  return (
    <>
      <P weight="500">Comparison of known spectrum for selected drugs</P>
      <CompareTableBase headings={renderTHead} rows={renderTBody} />
    </>
  );
};
