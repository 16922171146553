import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { Outbreak } from 'types/disease';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'Outbreaks/SingleLoadInitiated', { id: string }>
  | ActionWithPayload<'Outbreaks/SingleLoaded', { id: string; data: Outbreak[] }>
  | ActionWithPayload<'Outbreaks/SingleLoadFailed', { id: string; error: StoreError }>
  | ActionWithPayload<'Outbreaks/Public/SingleLoadInitiated', { id: string }>
  | ActionWithPayload<'Outbreaks/Public/SingleLoaded', { id: string; data: Outbreak[] }>
  | ActionWithPayload<'Outbreaks/Public/SingleLoadFailed', { id: string; error: StoreError }>;

export type State = {
  outbreaks: { [year: string]: Resource<Outbreak[]> | null };
  outbreaksPublic: { [year: string]: Resource<Outbreak[]> | null };
};

export const initialState: State = {
  outbreaks: {},
  outbreaksPublic: {},
};

export const {
  dispatchContext: OutbreaksDispatchContext,
  stateContext: OutbreaksStateContext,
  provider: OutbreaksStoreProvider,
} = storeFactory(reducer, initialState);
