import { PhenotypicClass } from 'Lab/types/phenotypicClass';
import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { Action as ActionWithoutPayload, ActionWithPayload, Resource } from 'store/types';

import { reducer } from './reducer';

export type Action =
  | ActionWithoutPayload<'Classes/LoadInitiated'>
  | ActionWithPayload<'Classes/Loaded', PhenotypicClass[]>
  | ActionWithPayload<'Classes/LoadFailed', StoreError>;

export interface State {
  classes: Resource<PhenotypicClass[]> | null;
}

export const initialState: State = {
  classes: null,
};

export const {
  dispatchContext: PhenotypicClassDispatchContext,
  stateContext: PhenotypicClassStateContext,
  provider: PhenotypicClassStoreProvider,
} = storeFactory(reducer, initialState);
