import { H2 } from 'Atoms/text';
import { SectionTitle } from 'Molecules/SectionTitle';
import React, { FC, ReactNode, useRef } from 'react';
import { useHashAnchor } from 'services/useHashAnchor.hook';
import styled, { DefaultTheme } from 'styled-components/macro';

import { RecordLink } from './RecordLinks';

const TopWrapper = styled.div`
  background-color: ${props => props.theme.colors.background.primary};
  z-index: 5;
  position: sticky;
  top: 0px;
`;

const Title = styled(H2)`
  margin: 0;
  flex-grow: 1;
  text-align: left;
`;

const SectionTitleStyled = styled(SectionTitle)`
  margin: 0 10px 0 35px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 0 10px 0 20px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin: 0 5px;
  }
`;

interface ContentType {
  'data-accent': keyof DefaultTheme['colors']['recordBorder'];
}

const SectionStyled = styled.section<ContentType>`
  &:not(:last-child) {
    padding-bottom: 50px;
  }

  border-left-width: 8px;
  border-left-style: solid;
  border-left-color: ${props => props.theme.colors.recordBorder[props['data-accent']]};
`;

interface ElementType {
  'data-show': boolean;
}

const Content = styled.div<ElementType>`
  display: ${props => (props['data-show'] ? 'block' : 'none')};

  border-radius: 5px;
  background-color: ${props => props.theme.colors.background.modalCardBody};
  padding: 10px;
  margin-top: 5px;
  box-shadow: 0px 2px 2px ${props => props.theme.colors.recordSection.shadow};

  margin-left: 25px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-left: 10px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-left: 5px;
    margin-right: 0px;
    padding: 10px 0;
  }
`;

interface Props {
  children: ReactNode;
  className?: string;
  link: RecordLink;
  show: boolean;
  onCollapse: () => void;
  accent: keyof DefaultTheme['colors']['recordBorder'];
  anchorRegex?: RegExp;
  id: string;
}

export const RecordSection: FC<Props> = ({
  children,
  className,
  link,
  show,
  onCollapse,
  accent,
  anchorRegex,
  id,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useHashAnchor(ref, anchorRegex || link.label);

  if (link.disabled) {
    return null;
  }

  return (
    <SectionStyled
      className={className}
      id={link.label}
      data-show={show}
      data-accent={accent}
      aria-labelledby={id}
    >
      <TopWrapper>
        <SectionTitleStyled show={show} onClick={onCollapse} ref={ref} label={link.label}>
          <Title weight="500" color="mainBold" font="Inter" lineHeight="unset" id={id}>
            {link.label}
          </Title>
        </SectionTitleStyled>
      </TopWrapper>
      <Content data-show={show}>{children}</Content>
    </SectionStyled>
  );
};
