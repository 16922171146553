import { P } from 'Atoms/text';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const SubsectionTitle = styled(P)`
  border-bottom: 1px solid ${props => props.theme.colors.settings.sectionSeparator};
  padding-bottom: 8px;
  margin-bottom: 20px;
`;

interface SubsectionProps {
  className?: string;
  title: string;
}

export const Subsection: FC<SubsectionProps> = ({ className, title, children }) => {
  return (
    <div className={className}>
      <SubsectionTitle weight="600">{title}</SubsectionTitle>
      {children}
    </div>
  );
};
