import { DefaultTheme } from 'styled-components/macro';

export const commonTheme: Pick<DefaultTheme, 'fontFamily' | 'fonts' | 'breakpoints' | 'commonColors'> = {
  commonColors: {
    lightBlue: '#00ACEA'
  },
  fontFamily: {
    Inter: '"Inter", Sans-serif',
    Exo2: '"Exo 2", Sans-serif',
    Quicksand: '"Quicksand", Sans-serif',
  },
  fonts: {
    RecordTitle: '400 40px Inter, Sans-serif',
    RecordSectionTitle: '400 25px Inter, Sans-serif',
    HugeText: '400 30px Inter, Sans-serif',
    veryBigTitleBold: '600 80px Inter, Sans-serif',
    veryBigTitle: '400 80px Inter, Sans-serif',
    HeaderBold: '700 25px Inter, Sans-serif',
    Header: '400 25px Inter, Sans-serif',
    bigTextBold: '700 20px Inter, Sans-serif',
    bigTextSemiBold: '500 20px Inter, Sans-serif',
    bigText: '400 20px Inter, Sans-serif',
    mediumTextBold: '700 18px Inter, Sans-serif',
    mediumTextSemiBold: '500 18px Inter, Sans-serif',
    mediumText: '400 18px Inter, Sans-serif',
    normalText: '400 16px Inter, Sans-serif',
    normalTextSemiBold: '500 16px Inter, Sans-serif',
    normalTextBold: '600 16px Inter, Sans-serif',
    smallText: '400 15px Inter, Sans-serif',
    smallTextSemiBold: '500 15px Inter, Sans-serif',
    smallTextBold: '600 15px Inter, Sans-serif',
    verySmallText: '400 14px Inter, Sans-serif',
    verySmallTextSemiBold: '500 14px Inter, Sans-serif',
    tinyText: '400 12px Inter, Sans-serif',
  },
  breakpoints: {
    sm: '400px',
    s: '700px',
    m: '1000px',
    l: '1300px',
    xl: '1600px',
  },
};
