import React from 'react';
import { Dispatch } from 'store/types';

import { Action, ReferenceListDispatchContext, ReferenceListStateContext, State } from './provider';

export const useState = (): State => {
  const state = React.useContext(ReferenceListStateContext);
  if (state === undefined) {
    throw new Error('Page reference used outside of provider scope');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(ReferenceListDispatchContext);
  if (dispatch === undefined) {
    throw new Error('Page reference used outside of provider scope');
  }
  return dispatch;
};
