import { ErrorParams, LogBody, LogParams } from 'apiServices/Logs/DTO';
import axios, { AxiosResponse } from 'axios.cached';

export const reportLog = async (log: LogParams): Promise<AxiosResponse> => {
  const requestBody: LogBody = {
    ...log,
    datetime: `${new Date().toISOString().split('.')[0]}Z`,
  };

  return await axios.post('/api/log', requestBody);
};

export const reportError = async (data: ErrorParams): Promise<AxiosResponse> => {
  return await axios.post('/api/public/report', { data: data });
};