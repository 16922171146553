import { Span } from 'Atoms/text';
import { Tooltip } from 'Atoms/tooltip/Tooltip';
import React, { FC } from 'react';
import { DefaultTheme } from 'styled-components/macro';
import styled from 'styled-components/macro';
import { ProbabilityType } from 'types/compare';

interface ProbabilityData {
  type: ProbabilityType;
  text?: string;
  percentage?: string;
  explanationText?: string;
}

interface Props {
  className?: string;
  probability: ProbabilityData;
  accent: keyof DefaultTheme['colors']['comparisonColors'];
  weight?: '500' | '700';
}

const SpanStyled = styled(Span)`
  position: relative;
  &:hover {
    ${Tooltip} {
      display: block;
    }
  }
`;

const TooltipStyled = styled(Tooltip)`
  top: -50px;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const ProbabilityKeyBase: FC<Props> = ({ className, probability }) => {
  if (probability.type === 'percentage' && probability.percentage) {
    return <SpanStyled className={className}>{probability.percentage}</SpanStyled>;
  } else if (probability.type === 'words' && probability.text) {
    return (
      <SpanStyled className={className}>
        {probability.text}
        {probability.explanationText && (
          <TooltipStyled>{probability.explanationText}</TooltipStyled>
        )}
      </SpanStyled>
    );
  }

  return null;
};

export const ProbabilityKey = styled(ProbabilityKeyBase)`
  color: ${props => props.theme.colors.comparisonColors[props.accent]};
  font-weight: ${props => (props.weight ? props.weight : '500')};
`;
