import axios from 'axios.cached';
import { Chart } from 'types/chart';

import { DataDTO } from '../common/DTO';
import { mapDiseaseChartDTO } from './charts.mapper';
import { ChartDTO, ChartNotesDTO } from './DTO';

export const getChart = async (url: string, getNotes: boolean): Promise<Chart> => {
  if (getNotes) {
    const [chart, chartNotes] = await Promise.all([
      axios.get<DataDTO<ChartDTO>>(url),
      axios.get<ChartNotesDTO>(`${url}/notes`),
    ]);

    return mapDiseaseChartDTO(chart.data.data, chartNotes.data);
  } else {
    const chart = await axios.get<DataDTO<ChartDTO>>(url);

    return mapDiseaseChartDTO(chart.data.data);
  }
};
