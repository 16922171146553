import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { VirusDetails, VirusDetailsPublic } from 'types/microbeDetails';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'VirusDetails/SingleLoadInitiated', { id: number }>
  | ActionWithPayload<'VirusDetails/SingleLoaded', { id: number; data: VirusDetails }>
  | ActionWithPayload<'VirusDetails/SingleLoadFailed', { id: number; error: StoreError }>
  | ActionWithPayload<'VirusDetails/Public/SingleLoadInitiated', { id: number }>
  | ActionWithPayload<'VirusDetails/Public/SingleLoaded', { id: number; data: VirusDetailsPublic }>
  | ActionWithPayload<'VirusDetails/Public/SingleLoadFailed', { id: number; error: StoreError }>;

export type State = {
  details: { [id: number]: Resource<VirusDetails> | undefined };
  detailsPublic: { [id: number]: Resource<VirusDetailsPublic> | undefined };
};

const initialState: State = {
  details: {},
  detailsPublic: {},
};

export const {
  dispatchContext: VirusDetailsDispatchContext,
  stateContext: VirusDetailsStateContext,
  provider: VirusDetailsStoreProvider,
} = storeFactory(reducer, initialState);
