import { getDiseaseDistribution } from 'apiServices/Diseases/diseases';
import React, { useEffect } from 'react';
import { newStoreError } from 'store/storeError';
import { Dispatch, Loading, Resource } from 'store/types';
import { DiseaseDistribution } from 'types/diseaseDistribution';

import {
  Action,
  DiseaseDistributionDispatchContext,
  DiseaseDistributionStateContext,
  State,
} from './provider';

export const useState = (): State => {
  const state = React.useContext(DiseaseDistributionStateContext);
  if (state === undefined) {
    throw new Error('Disease outbreaks store is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(DiseaseDistributionDispatchContext);
  if (dispatch === undefined) {
    throw new Error('Disease outbreaks store is not initialized');
  }
  return dispatch;
};

export const useDiseaseDistribution = (diseaseId: number): Resource<DiseaseDistribution> => {
  const state = useState();
  const dispatch = useDispatch();
  const diseaseDistribution = state[diseaseId];

  useEffect(() => {
    if (!diseaseDistribution) {
      dispatch({ type: 'DiseaseDistribution/SingleLoadInitiated', payload: { id: diseaseId } });
      getDiseaseDistribution(diseaseId)
        .then(data =>
          dispatch({ type: 'DiseaseDistribution/SingleLoaded', payload: { id: diseaseId, data } })
        )
        .catch(err => {
          dispatch({
            type: 'DiseaseDistribution/SingleLoadFailed',
            payload: { id: diseaseId, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, diseaseId, diseaseDistribution]);

  return diseaseDistribution || Loading;
};
