import { Breadcrumb, Props as BreadcrumbProps } from 'Molecules/breadcrumb/Breadcrumb';
import React, { FC, ReactNode } from 'react';
import { useHideOnScroll } from 'services/useHideOnScroll.hook';
import styled from 'styled-components/macro';

const BreadcrumbHeightMobile = '50';

interface StyleProps {
  show: boolean;
}

const BreadcrumbContainerStyled = styled.nav<StyleProps>`
  width: 100%;
  background: none;
  z-index: 700;
  padding: 20px 30px 0px;

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    height: ${BreadcrumbHeightMobile}px;
    padding: 10px;
    background: ${props => props.theme.colors.background.primary};
    position: fixed;
    bottom: 0;
    left: 0;
    border: solid ${props => props.theme.colors.breadcrumb.containerBorderColor};
    box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.12), 0px -1px 1px rgba(0, 0, 0, 0.14);
    border-width: 1px 0 0 0;

    transition: bottom 0.3s;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) and (orientation: landscape) {
    bottom: ${props => (props.show ? '0' : `-${BreadcrumbHeightMobile}`)}px;
  }
`;

export interface BreadcrumbContainerContent {
  className?: string;
  content: ReactNode;
}

export interface Props {
  breadcrumbProps: BreadcrumbProps;
  content: BreadcrumbContainerContent;
}

export const BreadcrumbContainer: FC<Props> = ({ breadcrumbProps, content }) => {
  const { ref, show } = useHideOnScroll<HTMLDivElement>();

  return (
    <BreadcrumbContainerStyled
      ref={ref}
      className={content.className}
      show={show}
      aria-label="Breadcrumb area"
    >
      <Breadcrumb
        desktopLevels={breadcrumbProps.desktopLevels}
        mobileLevels={breadcrumbProps.mobileLevels}
      />
      {content.content}
    </BreadcrumbContainerStyled>
  );
};
