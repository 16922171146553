import { OrderedList, OrderedListItem } from 'Atoms/OrderedList';
import { P, Props as PProps } from 'Atoms/text/P';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex-direction: column;
  }
`;

const EffectsSection = styled.div`
  flex-grow: 1;
  border-right: 1px solid ${props => props.theme.colors.separator.light};
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    border-right: none;
    width: 100%;
  }
`;

const InteractionsSection = styled.div`
  flex-grow: 1;
  margin-left: 30px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 0;
  }
`;

const StyledList = styled(OrderedList)`
  margin: -10px 15px 0 0;
`;

const SectionTitle = styled(P).attrs(
  (): Partial<PProps> => ({
    weight: '500',
    size: 'medium',
  })
)`
  margin: 22px 0 32px;
`;

interface Props {
  firstColumnTitle: string;
  firstColumnData: string[];
  secondColumnTitle: string;
  secondColumnData: string[];
}

export const WarningsSection: FC<Props> = ({
  firstColumnTitle,
  firstColumnData,
  secondColumnTitle,
  secondColumnData,
}) => {
  return (
    <Wrapper>
      <EffectsSection>
        <SectionTitle>
          {firstColumnTitle} ({firstColumnData.length})
        </SectionTitle>
        <StyledList>
          {firstColumnData.map((x, i) => (
            <OrderedListItem key={i}>{x}</OrderedListItem>
          ))}
        </StyledList>
      </EffectsSection>
      <InteractionsSection>
        <SectionTitle>
          {secondColumnTitle} ({secondColumnData.length})
        </SectionTitle>
        <StyledList>
          {secondColumnData.map((x, i) => (
            <OrderedListItem key={i}>{x}</OrderedListItem>
          ))}
        </StyledList>
      </InteractionsSection>
    </Wrapper>
  );
};
