import { DataDTO } from 'apiServices/common/DTO';
import axios from 'axios.cached';

import { TrendingDTO } from './DTO';

export const getTrendingData = async (section: string): Promise<TrendingDTO> => {
  const response = await axios.get<DataDTO<TrendingDTO[]>>(`/api/public/contents/statistics/toplist/${section}`);

  let data = response.data.data;
  if (section === 'countries') {
    data = response.data.data.filter(temp => temp.name.toLowerCase().indexOf('worldwide') === -1 && temp.name.toLowerCase().indexOf('bioterror') === -1);
  }

  if (data.length === 0) {
    return {
      id: '',
      name: '',
      rank: 0
    };
  }

  return data.sort((a,b) => {
    if (a.rank > b.rank) return 1;
    if (a.rank < b.rank) return -1;
    return 0
  })[0];
};