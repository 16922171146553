import { YearRangeOption } from 'Molecules/YearRangeSelector';

export const generateArray = <T>(length: number, fill: T): T[] => {
  const arr: T[] = [];
  arr.length = length;
  arr.fill(fill);
  return arr;
};

export const generateArrayRange = (from: number, to: number): YearRangeOption[] => {
  // safeguard if 'from' is bigger than 'to'
  const smaller = Math.min(from, to);
  const bigger = Math.max(from, to);

  return generateArray(bigger - smaller + 1, 0).map((_, i) => ({
    year: smaller + i,
  }));
};

export const generateArrayRangePublic = (from: number, to: number): YearRangeOption[] => {
  // safeguard if 'from' is bigger than 'to'
  const smaller = Math.min(from, to);
  const bigger = Math.max(from, to);

  return generateArray(bigger - smaller + 1, 0).map((_, i) => ({
    year: smaller + i,
    disabled: smaller + i !== new Date().getFullYear(),
  }));
};
