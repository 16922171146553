import { ReactComponent as ScientistSVG } from 'assets/UI/Scientist.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { Link } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { RedirectWithState } from 'Atoms/routes/RedirectWithState';
import { Span } from 'Atoms/text';
import { passwordReset, verifyPasswordResetToken } from 'Auth/apiServices/account';
import { FormInput } from 'Auth/Molecules/FormInput';
import { FormMessage } from 'Auth/Molecules/FormMessage';
import { ContentContainer, ErrorRow, Row, SpecialLink, Title } from 'Auth/Molecules/Styles';
import { MainLayout } from 'layouts/MainLayout';
import React, { FC, FormEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import styled from 'styled-components/macro';

const Layout = styled(MainLayout)`
  padding: 50px 0px;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 40px 0;
  }
`;

const FormContainer = styled.form`
  width: 450px;
  height: 100%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  margin: 40px 0;

  display: flex;
  flex-direction: column;
`;

const Text = styled(Span)`
  text-align: center;
`;

const RowStyled = styled(Row)`
  &:not(:first-child) {
    margin-top: 40px;
  }
`;

const SpecialLinkStyled = styled(SpecialLink)`
  width: 450px;
  height: 44px;

  margin: 25px 0 0 0;
`;

const SpecialButton = styled(FilledButton)`
  width: 450px;
  height: 44px;

  margin: 25px 0 0 0;
`;

const IconStyled = styled(Icon)`
  width: 65px;
  height: 65px;

  margin: 40px 0 0 0;
`;

interface Errors {
  password?: string;
  repeatPassword?: string;
  form?: string;
}

export const PasswordReset: FC = () => {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  const [isFormLoading, setIsFormLoading] = useState(false);
  const [linkError, setLinkError] = useState<string | null>(null);

  const [isSuccess, setIsSuccess] = useState(false);

  const { search } = useLocation();

  const token = useMemo(() => {
    const params = new URLSearchParams(search);

    return params.get('token');
  }, [search]);

  useEffect(() => {
    setIsFormLoading(true);

    if (!token) {
      return;
    }

    verifyPasswordResetToken(token)
      .catch(() => setLinkError('Link is expired'))
      .finally(() => setIsFormLoading(false));
  }, [token]);

  const validateForm = (): Errors => {
    const errors: Errors = {};

    if (!password) {
      errors.password = 'Password must be provided';
    } else if (password.length < 8) {
      errors.password = 'Password length must be at least 8 characters';
    }

    if (password != repeatPassword) {
      errors.repeatPassword = 'Passwords must match';
    }

    return errors;
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!token) {
      return;
    }

    const errors = validateForm();
    setErrors(errors);

    if (Object.values(errors).some(error => error)) {
      return;
    }

    setIsLoading(true);
    passwordReset(token, password)
      .then(() => setIsSuccess(true))
      .catch(() => setErrors({ form: 'Server error' }))
      .finally(() => setIsLoading(false));
  };

  const getForm = (): ReactElement => {
    if (isFormLoading) {
      return <Loader />;
    } else if (linkError) {
      return (
        <ErrorRow>
          <FormMessage type="error" message={linkError} />
        </ErrorRow>
      );
    } else {
      return (
        <>
          <TextContainer>
            <Text>Please enter your new password below.</Text>
          </TextContainer>
          <FormContainer onSubmit={onSubmit}>
            <Row>
              <FormInput
                id="password-reset-password"
                label="Create your new password:"
                onChange={value => {
                  setErrors(e => ({ ...e, password: undefined }));
                  setPassword(value);
                }}
                value={password}
                type="password"
                error={!!errors.password}
                autoComplete="new-password"
              />
            </Row>
            <ErrorRow>
              <FormMessage type="error" message={errors.password} />
            </ErrorRow>
            <RowStyled>
              <FormInput
                id="password-reset-repeat-password"
                label="Confirm your new password:"
                onChange={value => {
                  setErrors(e => ({ ...e, repeatPassword: undefined }));
                  setRepeatPassword(value);
                }}
                value={repeatPassword}
                type="password"
                error={!!errors.repeatPassword}
                autoComplete="new-password"
              />
            </RowStyled>
            <ErrorRow>
              <FormMessage type="error" message={errors.repeatPassword} />
            </ErrorRow>
            <ErrorRow>
              <FormMessage type="error" message={errors.form} />
            </ErrorRow>
            <SpecialButton disabled={isLoading} color="special">
              {isLoading ? <Loader /> : 'Reset password'}
            </SpecialButton>
          </FormContainer>
        </>
      );
    }
  };

  if (!token) {
    return <RedirectWithState to="/404" />;
  }

  return (
    <Layout>
      <Helmet>
        <title>Password reset - GIDEON</title>
      </Helmet>
      {isSuccess ? (
        <>
          <Title color="lightLink" weight="600" size="big" font="Inter">
            Success!
          </Title>
          <ContentContainer>
            <IconStyled svgComponent={ScientistSVG} />
            <TextContainer>
              <Text>Your GIDEON password has been updated.</Text>
            </TextContainer>
            <SpecialLinkStyled as={Link} to="/login" noUnderline color="special">
              <Span weight="500">Sign In</Span>
            </SpecialLinkStyled>
          </ContentContainer>
        </>
      ) : (
        <>
          <Title color="lightLink" weight="600" size="big" font="Inter">
            Create your new password
          </Title>
          <ContentContainer>{getForm()}</ContentContainer>
        </>
      )}
    </Layout>
  );
};
