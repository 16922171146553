import { CompleteTooltip } from 'Atoms/tooltip/CompleteTooltip';
import { InlineInputWrapper, InlineLabel, StyledInput } from 'Molecules/account/AccountFormInput';
import { Subsection } from 'Molecules/account/Subsection';
import React, { FC } from 'react';
import { useAccountController } from 'store/accountStore/hooks';
import styled from 'styled-components/macro';

const StyledSubsection = styled(Subsection)`
  margin-top: 30px;
`;

const WrapperStyled = styled(InlineInputWrapper)`
  display: flex;
  flex-direction: column;

  align-items: start;
`;

const Input = styled(StyledInput)`
  width: 100%;

  margin: 5px 0 0 0;
  opacity: 0.7;
`;

const StyledLabel = styled(InlineLabel)`
  position: relative;
`;

const StyledTooltip = styled(CompleteTooltip)`
  right: -25px;
`;

export const PubmedLinkout: FC = () => {
  const { tokenPayload } = useAccountController();

  return (
    <StyledSubsection title="PubMed linkout">
      <WrapperStyled>
        <StyledLabel htmlFor="link-out">
          Your library&#39;s otool parameter
          <StyledTooltip
            position="top"
            content="Contact your GIDEON representative to change this"
          />
        </StyledLabel>
        <Input
          type="text"
          placeholder="examplelib"
          value={tokenPayload?.user.ref_otool ? tokenPayload?.user.ref_otool : 'No value specified'}
          id="link-out"
          disabled
          hideIcon={true}
        />
      </WrapperStyled>
    </StyledSubsection>
  );
};
