import { Loader } from 'Atoms/Loader';
import { SearchModules } from 'Organisms/search/SearchModules';
import { SearchResults } from 'Organisms/search/SearchResults';
import React, { FC } from 'react';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { Search, SearchModuleName } from 'types/search';

const SearchContentStyled = styled.div`
  width: 100%;
  padding: 20px 40px;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 20px 20px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 20px 10px;
  }
`;

const SearchResultsContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 80px;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    margin-top: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex-direction: column;
  }
`;

const SearchResultsStyled = styled(SearchResults)`
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 20px 0 0 0;
  }
`;

interface Props {
  className?: string;
  loadMore: () => void;
  isLoadingMore: boolean;
  onClick: (type: SearchModuleName) => void;
  activeModule: SearchModuleName | null;
  searchModule: Search | null | 'Loading';
  searchResults: Search | null | 'Loading';
}

export const SearchContent: FC<Props> = ({
  className,
  searchModule,
  searchResults,
  loadMore,
  isLoadingMore,
  onClick,
  activeModule,
}) => (
  <SearchContentStyled className={className}>
    {!isLoading(searchModule) && searchModule && searchModule.total !== 0 && (
      <SearchModules
        modules={searchModule.facets.module}
        onClick={onClick}
        activeModule={activeModule}
      />
    )}
    {searchResults && (
      <SearchResultsContainer>
        {isLoading(searchResults) ? (
          <Loader />
        ) : (
          <SearchResultsStyled
            results={searchResults.hits}
            totalResults={searchResults.total}
            loadMore={loadMore}
            isLoadingMore={isLoadingMore}
          />
        )}
      </SearchResultsContainer>
    )}
  </SearchContentStyled>
);
