import { RecordAnchorLink } from 'Atoms/links/RecordAnchorLink';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { DynamicContent } from 'types/dynamicContent';

const Links = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 43px 90px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 0 28px 90px;
  }

  position: relative;
  z-index: 200;
`;

const StyledLink = styled(RecordAnchorLink)`
  margin-bottom: 20px;
  &:last-child {
    margin: 0;
  }
`;

export interface RecordLink {
  label: string;
  'aria-label'?: string;
  disabled?: boolean;
}
export interface DynamicRecordLink {
  label: string;
  accent: 'first' | 'second' | 'third' | 'fourth' | 'fifth';
  'aria-label'?: string;
  intro?: DynamicContent;
  disabled?: boolean;
}

interface Props {
  className?: string;
  links: RecordLink[];
}

export const RecordLinks: FC<Props> = ({ links }) => (
  <Links>
    {links
      .filter(x => !x.disabled)
      .map((link, i) => (
        <StyledLink
          key={link.label}
          to={link.label}
          tabIndex={0}
          aria-label={link['aria-label']}
        >{`${i + 1}. ${link.label}`}</StyledLink>
      ))}
  </Links>
);
