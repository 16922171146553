import { useEffect } from 'react';

export function useEventListener<K extends keyof WindowEventMap>(
  type: K,
  listener: (this: Window, ev: WindowEventMap[K]) => void,
  element?: Window,
  options?: boolean | AddEventListenerOptions
): void;
export function useEventListener<K extends keyof HTMLElementEventMap>(
  type: K,
  listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => void,
  element: HTMLElement | null,
  options?: boolean | AddEventListenerOptions
): void;
export function useEventListener(
  type: string,
  listener: EventListenerOrEventListenerObject,
  element: HTMLElement | Window | null = window,
  options?: boolean | AddEventListenerOptions
): void {
  useEffect(() => {
    // Make sure element supports addEventListener
    if (!element || !element.addEventListener) return;

    // Add event listener
    element.addEventListener(type, listener, options);

    // Remove event listener on cleanup
    return () => {
      element.removeEventListener(type, listener, options);
    };
  }, [listener, element, type, options]);
}
