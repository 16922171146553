import { SvgComponent } from 'Atoms/Icon';

import { PaginationData } from './common';
import { DynamicContent } from './dynamicContent';

export type SearchRecordType = 'image' | 'graph' | 'document';

export type SearchFilterType = SearchRecordType | 'all';

export const isSearchFilterType = (value: string): value is SearchFilterType => {
  return value === 'all' || value === 'document' || value === 'graph' || value === 'image';
};

export const isSearchModuleName = (value: string): value is SearchModuleName => {
  return (
    value === 'diseases' ||
    value === 'drugs' ||
    value === 'bacteria' ||
    value === 'country' ||
    value === 'vaccines' ||
    value === 'yeasts' ||
    value === 'mycobacteria'||
    value ==='viruses'
  );
};

export interface Search extends PaginationData {
  hits: SearchHit[];
  facets: SearchFacets;
  total: number;
}

export interface SearchHit {
  highlight: SearchResultHighlight;
  fields: SearchResultFields;
  id: string;
}

export interface SearchResultFields {
  recordType: SearchRecordType;
  description?: string;
  title: DynamicContent;
}

interface SearchResultHighlight {
  description: DynamicContent;
  title: DynamicContent;
}

export type SearchFacetName =
  | 'disease'
  | 'drug'
  | 'module'
  | 'country'
  | 'recordType'
  | 'vaccine'
  | 'bacteria'
  | 'mycobacteria'
  | 'yeasts';

export type SearchModuleName =
  | 'diseases'
  | 'drugs'
  | 'bacteria'
  | 'country'
  | 'vaccines'
  | 'yeasts'
  | 'mycobacteria'
  |'viruses';

export type SearchFacets = Omit<Record<SearchFacetName, SearchFacet[]>, 'module'> & {
  module: ModuleSearchFacet[];
};

export interface ModuleSearchFacet {
  value: SearchModuleName;
  count: number;
}

export interface SearchFacet {
  value: string;
  count: number;
}

export interface SearchQueryParameters {
  searchValue: string;
  recordType: SearchFilterType;
  module?: SearchModuleName;
  limit?: number;
  offset?: number;
}

export interface SearchFilterOption {
  value: SearchFilterType;
  label: string;
  Icon: SvgComponent;
}
