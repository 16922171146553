// axios.cached.ts
// This is hacky solution for avoiding multiple same requests.
// Because react dispach is asynchronous in many stores hook that loads the data
// is caled many times before loading state is set. To avoid this all get reuqests gets cached here
// and if multiple requests are made to the same endpoint while previous is not resolved this cache
// returns same promise. And if request is finished it is removed from this cache to allow renew
// the data if necessary

import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
export * from 'axios';

const maxRetries = 2;

const PromiseTimeout = (ms: number): Promise<undefined> =>
  new Promise(resolve => setTimeout(resolve, ms));

// axiosGetWithRetry is get request wrapper which retries the request
// after some delay if 429 status was received
const axiosGetWithRetry = async <T, R>(
  url: string,
  config?: AxiosRequestConfig | undefined
): Promise<R> => {
  // Try for (maxRetries - 1) times
  for (let i = 1; i < maxRetries; ++i) {
    try {
      // await is necessary here, otherwise try..catch would not work
      return await Axios.get<T, R>(url, config);
    } catch (err) {
      if (err?.response?.status !== 429) {
        // if error is not 'too many requests', stop trying and reject the promise
        throw err;
      }
    }

    // this executes only if request failed with 429
    // before next try wait some time
    // each time out is 5 seconds longer
    await PromiseTimeout(i * 5000);
  }

  // Last retry is outside of loop because we return it regardless of the result
  return Axios.get<T, R>(url, config);
};

const requestCache = new Map<string, Promise<unknown>>();

export const get: typeof Axios.get = <T = unknown, R = AxiosResponse<T>>(
  url: string,
  config?: AxiosRequestConfig | undefined
): Promise<R> => {
  if (requestCache.has(url)) {
    return requestCache.get(url) as Promise<R>;
  }

  const promise = axiosGetWithRetry<T, R>(url, config);
  requestCache.set(url, promise);

  // remove from cahce after request is resolved.
  // If new request arives, cahce will be managed by browser.
  promise.then(() => requestCache.delete(url));

  return promise;
};

export default {
  ...Axios,
  get,
};
