import { LoginInput } from 'Atoms/input/LoginInput';
import { Label } from 'Atoms/Label';
import React, { FC, FocusEvent } from 'react';
import styled from 'styled-components/macro';

const InputContainer = styled.div`
  width: 100%;
`;

const Input = styled(LoginInput)`
  width: 100%;
  max-width: unset;
  margin-top: 5px;
  text-align: unset;
`;

interface Props {
  className?: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  type?: 'text' | 'email' | 'password';
  id: string;
  error?: boolean;
  autoComplete?: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

export const FormInput: FC<Props> = ({
  className,
  label,
  value,
  onChange,
  type = 'text',
  id,
  error,
  autoComplete,
  onBlur,
}) => (
  <InputContainer className={className}>
    <Label size="small" weight="700" htmlFor={id}>
      {label}
    </Label>
    <Input
      id={id}
      type={type}
      value={value}
      onChange={e => onChange(e.target.value)}
      error={error}
      autoComplete={autoComplete}
      onBlur={onBlur}
    />
  </InputContainer>
);
