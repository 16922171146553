import { getVaccinesCoverage } from 'apiServices/Vaccines/vaccines';
import { Dispatch, useContext, useEffect } from 'react';
import { newStoreError } from 'store/storeError';
import { Loading, Resource } from 'store/types';
import { CustomError, ErrorCodes } from 'types/errorTypes';
import { VaccineCoverage } from 'types/vaccine';

import { Action, State,VaccinesCoverageDispatchContext, VaccinesCoverageStateContext } from './provider';

export const VaccineAbbreviations = [
  { value: 'DTP3', label: 'Diphtheria, Tetanus, Pertussis', url: 'diphtheria-tetanus-pertussis', mapTitle: 'Diphtheria - WHO-UNICEF est. vaccine (DTP3 %) coverage' },
  { value: 'DTP3-1', label: 'Diphtheria, Tetanus, Pertussis (1 yr old)', url: 'diphtheria-tetanus-pertussis-1-year-old', mapTitle: 'Diphtheria - WHO % DTP3 est. coverage among one-year-olds' },
  { value: 'HepB3', label: 'Hepatitis B', url: 'hepatitis-b', mapTitle: 'Hepatitis B - WHO-UNICEF est. % (HepB3) vaccine coverage' },
  { value: 'HepB3-1', label: 'Hepatitis B (1 yr old)', url: 'hepatitis-b-1-year-old', mapTitle: 'Hepatitis B - WHO % HepB3 est. coverage among one-year-olds' },
  { value: 'JE', label: 'Japanese encephalitis', url: 'japanese-encephalitis', mapTitle: 'Japanese encephalitis - WHO-UNICEF est. % vaccine coverage' },
  { value: 'Measles', label: 'Measles', url: 'measles', mapTitle: 'Measles - WHO-UNICEF est. % vaccine coverage' },
  { value: 'Measles-1', label: 'Measles (1 yr old)', url: 'measles-1-year-old', mapTitle: 'Measles - WHO % Measles vaccine est. coverage among one-year-olds' },
  { value: 'Hib3', label: 'Meningitis - bacterial', url: 'meningitis-bacterial', mapTitle: 'Meningitis - bacterial - WHO-UNICEF est. % (Hib3) vaccine coverage' },
  { value: 'Hib3-1', label: 'Meningitis - bacterial (1 yr old)', url: 'meningitis-bacterial-1-year-old', mapTitle: 'Meningitis - bacterial - WHO Hib3 % vaccine est. coverage among one-year-olds' },
  { value: 'IPV1', label: 'Poliomyelitis (IPV1)', url: 'poliomyelitis-ipv1', mapTitle: 'Poliomyelitis - WHO-UNICEF est. % vaccine (IPV1) coverage' },
  { value: 'POL3', label: 'Poliomyelitis (POL3)', url: 'poliomyelitis-pol3', mapTitle: 'Poliomyelitis - WHO-UNICEF est. % vaccine (POL3) coverage' },
  { value: 'POL3-1', label: 'Poliomyelitis (POL3 1 yr old)', url: 'poliomyelitis-pol3-1-year-old', mapTitle: 'Poliomyelitis - WHO % POL3 est. coverage among one-year-olds' },
  { value: 'Rota1', label: 'Rotavirus', url: 'rotavirus', mapTitle: 'Rotavirus - WHO-UNICEF est. % (Rota1) vaccine coverage' },
  { value: 'RotaC', label: 'Rotavirus (1 yr old)', url: 'rotavirus-1-year-old', mapTitle: 'Rotavirus - WHO % RotaC est. coverage among one-year-olds' },
  { value: 'Rubella1', label: 'Rubella', url: 'rubella', mapTitle: 'Rubella - WHO-UNICEF est. % (Rubella1) vaccine coverage' },
  // { value: 'TT2+', label: 'Tetanus', url: 'tetanus', mapTitle: 'WHO-UNICEF est. % vaccine coverage' },
  { value: 'BCG', label: 'Tuberculosis', url: 'tuberculosis', mapTitle: 'Tuberculosis - WHO-UNICEF est. % BCG coverage' },
  { value: 'BCG-1', label: 'Tuberculosis (1 yr old)', url: 'tuberculosis-1-year-old', mapTitle: 'Tuberculosis - WHO % BCG est. coverage among one-year-olds' },
  { value: 'YF', label: 'Yellow fever', url: 'yellow-fever', mapTitle: 'Yellow fever - WHO-UNICEF est. % vaccine coverage of target population' },
];

export const VaccineCoverageLabels = [
  'No data', '< 60%', '60% - 75%', '75% - 90%', '90% - 95%', '≥ 95%'
];

export const useState = (): State => {
  const state = useContext(VaccinesCoverageStateContext);
  if (state === undefined) {
    throw new CustomError('VaccinesCoverageStore', ErrorCodes.StoreNotInitialized);
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = useContext(VaccinesCoverageDispatchContext);
  if (dispatch === undefined) {
    throw new CustomError('VaccinesCoverageStore', ErrorCodes.StoreNotInitialized);
  }
  return dispatch;
};

export const useVaccineCoverages = (vaccine_abrev: string, year: string): Resource<VaccineCoverage[]> => {
  const state = useState();
  const dispatch = useDispatch();
  const id = `${vaccine_abrev}-${year}`;
  const coverages = state.vaccineCoverages[id];

  useEffect(() => {
    if (!coverages) {
      dispatch({ type: 'Vaccines/Coverage/SingleLoadInitiated', payload: { id: id } });
      getVaccinesCoverage(vaccine_abrev, year)
        .then(data => dispatch({ type: 'Vaccines/Coverage/SingleLoaded', payload: { id: id, data } }))
        .catch(err => {
          dispatch({
            type: 'Vaccines/Coverage/SingleLoadFailed',
            payload: { id: id, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, coverages, id, vaccine_abrev, year]);

  return coverages || Loading;
};