import { Loader } from 'Atoms/Loader';
import React, { FC, ReactElement } from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { useAccountController } from 'store/accountStore/hooks';

type RenderFunc<P> = (props: RouteComponentProps<P>) => React.ReactNode;

type Props<P> = Omit<RouteProps, 'render' | 'component' | 'children'> & {
  render?: RenderFunc<P>;
  renderPublic?: RenderFunc<P>;
  component?: FC;
  componentPublic?: FC;
};

export const VariableAuthRoute = <P extends { [K in keyof P]?: string }>({
  render,
  renderPublic,
  component,
  componentPublic,
  ...rest
}: Props<P>): ReactElement => {
  const { isAuthenticated, isLoading } = useAccountController();

  const getRender = (): RenderFunc<P> | undefined => {
    if (isLoading) {
      return () => <Loader />;
    } else if (isAuthenticated) {
      return render;
    } else {
      return renderPublic;
    }
  };

  const getComponent = (): FC | undefined => {
    if (isLoading) {
      return Loader;
    } else if (isAuthenticated) {
      return component;
    } else {
      return componentPublic;
    }
  };

  return <Route {...rest} render={getRender()} component={getComponent()} />;
};
