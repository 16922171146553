import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { Chart } from 'types/chart';

import { ChartDTO, ChartNotesDTO } from './DTO';

export const mapDiseaseChartDTO = (chart: ChartDTO, chartNotes?: ChartNotesDTO): Chart => ({
  coordinates: chart.coordinates.map(c => ({ x: c.x_axis, y: c.y_axis,references:c.references })),
  title: chart.title,
  xTitle: chart.x_title,
  yTitle: chart.y_title,
  notes: chartNotes?.data ? parseDynamicContent(chartNotes.data) : undefined,
  Yearly_Notes: chartNotes?.yearly_notes?chartNotes.yearly_notes.map(c=>({year:c.year,note:parseDynamicContent(c.note)})):[]
});
