import React, { FC } from 'react';
import styled, { css, DefaultTheme } from 'styled-components/macro';

const sizes = {
  small: {
    height: '20px',
    width: '20px',
  },
  smallMedium: {
    height: '25px',
    width: '25px',
  },
  medium: {
    height: '30px',
    width: '30px',
  },
  full: {
    height: '100%',
    width: '100%',
  },
};

export type SvgComponent = React.FC<React.SVGProps<SVGSVGElement>>;
interface Props {
  className?: string;
  fill?: keyof DefaultTheme['colors']['text'];
  size?: keyof typeof sizes;
  svgComponent: SvgComponent;
  ariaHidden?: boolean;
}

const IconComp: FC<Props> = ({ className, svgComponent: SvgComponent, ariaHidden = true }) => (
  <SvgComponent className={className} aria-hidden={ariaHidden} />
);

export const Icon = styled(IconComp)`
  ${props =>
    props.fill
      ? css`
          fill: ${props.theme.colors.text[props.fill]};
        `
      : ''}
  ${props => {
    const size = props.size || 'full';
    return css`
      height: ${sizes[size].height};
      width: ${sizes[size].width};
    `;
  }}
`;
