import styled from 'styled-components/macro';

export const OrderedList = styled.ol`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  counter-reset: item;
`;

export const OrderedListItem = styled.li`
  font: ${props => props.theme.fonts.normalText};
  display: block;
  margin-top: 10px;
  &:first-child {
    margin: 0;
  }
  &::before {
    content: counter(item) '. ';
    padding-right: 20px;
    counter-increment: item;
    width: 20px;
    display: inline-block;
  }
`;
