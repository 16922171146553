import { Loader } from 'Atoms/Loader';
import { P } from 'Atoms/text';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { ImagesSection } from 'Molecules/ImagesSection';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import { DownloadsSection } from 'Molecules/section/DownloadsSection';
import { VirusSummarySection } from 'Molecules/section/VirusSummarySection';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import { getStringFromDynamicHtml } from 'Organisms/dynamicContent/DynamicHtmlToString';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { getTimeStamp } from 'services/getLatestOutbreakLabel';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { assertIsNotStoreError, StoreError } from 'store/storeError';
import { isLoading, Loading, LoadingType } from 'store/types';
import { useVirusDetails } from 'store/virusDetailStore/hooks';
import styled from 'styled-components/macro';
import { VirusDetails } from 'types/microbeDetails';
import { isRecordLocationState } from 'types/record';

type Props = RouteComponentProps & {
  virusId: number;
};

const StyledP = styled(P)`
  margin-bottom: 10px;
  margin-top: 30px;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledRecordLayout = styled(RecordLayout)`
  position: relative;
`;

const ComingSoon = styled.div`
  position: absolute;
  z-index: 300;
  left: 0;
  right: 60px;
  bottom: 0;
  top: 339px;
  background: ${props => props.theme.colors.background.primary};
  opacity: 0.95;
  text-align: center;
  padding-top: 80px;
  color: #aaa;
  font-size: 70px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.4);

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    right: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    right: 5px;
  }
`;

export const Virus: FC<Props> = ({ location, virusId }) => {
  const [showSummary, setShowSummary] = useState(true);
  const [showStructure, setShowStructure] = useState(true);
  const [showStructureGallery, setShowStructureGallery] = useState(true);
  const [showInfection, setShowInfection] = useState(true);
  const [showInfectionGallery, setShowInfectionGallery] = useState(true);
  const [showDownloads, setShowDownloads] = useState(true);

  const virus = useVirusDetails(virusId);

  const synonymName = isRecordLocationState(location.state)
    ? location.state.synonymName
    : undefined;

  if (isLoading(virus)) {
    return <Loader />;
  }

  assertIsNotStoreError(virus);

  const sections: RecordLink[] = [
    'Summary',
    'Structure',
    'Structure gallery',
    'Mechanism of infection',
    'Mechanism of infection gallery',
    'Downloads and references',
  ].map(section => ({
    label: section,
    'aria-label': `${virus.name} ${section.toLowerCase()}`,
    disabled:
      (section === 'Structure gallery' && virus.structure_images.length === 0) ||
      (section === 'Mechanism of infection gallery' && virus.mechanism_images.length === 0),
  }));

  const description = `GIDEON - ${virus.name}. Structure: ${getStringFromDynamicHtml(
    virus.structure
  )}`;

  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>{virus.name} - GIDEON</title>
          <meta name="description" content={description} />
        </Helmet>
        <ReferenceLabelContext.Provider value={virus.name}>
          <StyledRecordLayout>
            <RecordTitle
              title={virus.name}
              synonymName={synonymName}
              showTimeStamp={getTimeStamp(virus.timestamp)}
            />
            <RecordLinks links={sections} />
            <RecordSection
              link={sections[0]}
              show={showSummary}
              onCollapse={() => setShowSummary(!showSummary)}
              accent="first"
              id="virus-summary"
            >
              <VirusSummarySection
                type={virus.genome_type}
                diseases={virus.diseases}
                reservoirs={virus.reservoirs}
                vehicles={virus.vehicles}
                vectors={virus.vectors}
                vaccines={virus.vaccines}
                drugs={virus.drugs}
                synonyms={virus.synonyms}
                etymology={virus.etymology}
              />
            </RecordSection>
            <RecordSection
              link={sections[1]}
              show={showStructure}
              onCollapse={() => setShowStructure(!showStructure)}
              accent="second"
              id="virus-structure"
            >
              <DynamicHtml content={virus.structure} />
            </RecordSection>
            <RecordSection
              link={sections[2]}
              show={showStructureGallery}
              onCollapse={() => setShowStructureGallery(!showStructureGallery)}
              accent="second"
              id="virus-structure-gallery"
            >
              <ImagesSection images={virus.structure_images} />
            </RecordSection>
            <RecordSection
              link={sections[3]}
              show={showInfection}
              onCollapse={() => setShowInfection(!showInfection)}
              accent="third"
              id="virus-infection"
            >
              <StyledP weight="700">Entry pathway</StyledP>
              <DynamicHtml content={virus.entry_mechanism} />
              <StyledP weight="700">Replication</StyledP>
              <DynamicHtml content={virus.replication_mechanism} />
            </RecordSection>
            <RecordSection
              link={sections[4]}
              show={showInfectionGallery}
              onCollapse={() => setShowInfectionGallery(!showInfectionGallery)}
              accent="third"
              id="virus-infection-gallery"
            >
              <ImagesSection images={virus.mechanism_images} />
            </RecordSection>
            <RecordSection
              link={sections[5]}
              show={showDownloads}
              onCollapse={() => setShowDownloads(!showDownloads)}
              accent="third"
              id="virus-downloads"
            >
              <DownloadsSection category="viruses" id={virus.code} labelTitle={virus.name} />
            </RecordSection>
          </StyledRecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};
