import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { Action as GenericAction, ActionWithPayload, Resource } from 'store/types';

import { reducer } from './reducer';

export type Action =
  | GenericAction<'FingerprintDiseases/LoadInitiated'>
  | ActionWithPayload<'FingerprintDiseases/Loaded', Disease[]>
  | ActionWithPayload<'FingerprintDiseases/LoadFailed', StoreError>;

export interface Disease {
  id: number;
  name: string;
}

export type State = Resource<Disease[]>;

const initialState: State = [];

export const {
  dispatchContext: FingerprintDiseasesDispatchContext,
  stateContext: FingerprintDiseasesStateContext,
  provider: FingerprintDiseasesStoreProvider,
} = storeFactory(reducer, initialState);
