import { Icon } from 'Atoms/Icon';
import { RedirectWithState } from 'Atoms/routes/RedirectWithState';
import { MainLayout } from 'layouts/MainLayout';
import { Tab } from 'Molecules/buttons/Tab';
import { Slider, Tabs } from 'Molecules/Tabs';
import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { UpdatesTitle } from 'Updates/Molecules/UpdatesTitle';
import { UpdatesTabSection } from 'Updates/types/tab';

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  margin-top: 50px;
  padding: 0 10%;
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 0 5%;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 0 15px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 5px;
  }
`;

const CustomTabs = styled(Tabs)`
  justify-content: unset;
`;

const CustomTab = styled(Tab)`
  ${Icon} {
    min-width: 25px;
  }

  &:nth-child(1) {
    width: 220px;
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: 220px;
          left: 0;
        }
      `}
  }

  &:nth-child(2) {
    width: 220px;
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: 220px;
          left: 220px;
        }
      `}
  }

  &:nth-child(3) {
    width: 250px;
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: 250px;
          left: 440px;
        }
      `}
  }
`;

interface Props {
  match: RouteComponentProps['match'];
  sections: UpdatesTabSection[];
}

export const UpdatesDesktop: FC<Props> = ({ match, sections }) => (
  <MainLayout>
    <UpdatesTitle />
    <Content>
      <CustomTabs sections={sections} Tab={CustomTab} path={match.url} />
      <Switch>
        <RedirectWithState exact from={match.url} to={`${match.url}/data`} />
        {sections.map(section => (
          <Route path={`${match.url}/${section.slug}`} key={section.slug}>
            <section.Component path={`${match.url}/${section.slug}`} />
          </Route>
        ))}
        <Redirect to="/404" />
      </Switch>
    </Content>
  </MainLayout>
);
