import type { LoadingType } from 'types/common';

import { StoreError } from './storeError';

export { Loading, isLoading } from 'types/common';
export type { LoadingType } from 'types/common';

export interface Action<T> {
  readonly type: T;
}

export interface ActionWithPayload<T, P> extends Action<T> {
  readonly payload: P;
}

export type Resource<T> = T | LoadingType | StoreError;

export type Reducer<S, A> = (state: S, action: A) => S;
export type Dispatch<A> = (action: A) => void;
