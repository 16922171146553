import { requestDeleteGDPR } from 'apiServices/account/accountSettings';
import { ReactComponent as DangerSVG } from 'assets/UI/Danger.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Loader } from 'Atoms/Loader';
import { P } from 'Atoms/text';
import { Toast } from 'Atoms/toast/Toast';
import { StyledP } from 'Molecules/account/AccountFormInput';
import { AccountModal } from 'Molecules/modal/AccountModal';
import React, { FC, FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
`;

const FormButton = styled(FilledButton)`
  max-height: 36px;
  min-height: 36px;
  margin: 0 5px;
  background: ${props => props.theme.colors.yesNoButton.default.backgroundColor};
`;

const CancelButton = styled(FormButton)`
  background: ${props => props.theme.colors.filledButton.dark.default.backgroundColor};

  span {
    color: ${props => props.theme.colors.filledButton.dark.default.textColor};
  }
`;

const ErrorMessage = styled(P)`
  margin: -10px 0 10px;
`;

const DangerSVGBox = styled(DangerSVG)`
  width: 35px;
  margin: 0 auto 30px;
  fill: #c70e0e;
`;

const GDPRText = styled(StyledP)`
  width: 100%;
  text-align: left;
  line-height: 25px;
`;

const GDPRLI = styled.li`
  line-height: 25px;
`;

interface Props {
  modalOpen: boolean;
  onModalClose: () => void;
}

export const GDPRDeleteModal: FC<Props> = ({ modalOpen, onModalClose }) => {
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (isSubmiting) {
      return;
    }

    setIsSubmiting(true);

    requestDeleteGDPR()
      .then(() => {
        toast.success(
          <Toast
            type="success"
            content="Thank you. We have received your request and will remove your data from our systems within the next 30 days."
          />
        );

        setErrorMessage(null);
        onModalClose();
      })
      .catch(() => {
        setErrorMessage('We faced an unexpected error. Please try again later');
      })
      .finally(() => setIsSubmiting(false));
  };

  return (
    <AccountModal isOpen={modalOpen} onClose={onModalClose} title="Right to erasure">
      <Form onSubmit={onSubmit}>
        <DangerSVGBox />
        <GDPRText>
          You are about to request deletion of all your personal data from GIDEON systems.
        </GDPRText>
        <GDPRText>
          <b>This will impact our ability to deliver the service to you.</b>
        </GDPRText>
        <ul>
          <GDPRLI>You will lose access to GIDEON</GDPRLI>
          <GDPRLI>
            You won&apos;t be able to re-register with the same email address while the data
            deletion is in process
          </GDPRLI>
          <GDPRLI>You will no longer receive our latest blogs via email</GDPRLI>
          <GDPRLI>You will no longer receive our monthly newsletter</GDPRLI>
          <GDPRLI>You will lose access to any customer discounts and promotions</GDPRLI>
        </ul>
        {errorMessage && <ErrorMessage color="error">{errorMessage}</ErrorMessage>}
        <ButtonContainer>
          <CancelButton type="button" disabled={isSubmiting} onClick={onModalClose}>
            I’ve changed my mind
          </CancelButton>
          <FormButton type="submit" disabled={isSubmiting}>
            {isSubmiting ? <Loader /> : 'Delete anyway'}
          </FormButton>
        </ButtonContainer>
      </Form>
    </AccountModal>
  );
};
