import { ExternalLink } from 'Atoms/links/ExternalLink';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { contentClassNames, Modal } from '../../Atoms/Modal';
import { ModalCard } from '../modalCard/ModalCard';

const ModalStyled = styled(Modal)`
  .${contentClassNames.base} {
    max-height: 80%;
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      max-height: 100%;
    }
  }
`;

const TermsContainer = styled.div`
  padding: 0px 0px 20px 0px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-height: 100%;
  }
`;

const ExternalLinkStyled = styled(ExternalLink)`
  max-width: 300px;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const TermsModal: FC<Props> = ({ isOpen, onClose }) => (
  <ModalStyled isOpen={isOpen} onClose={onClose} showCloseButton>
    <ModalCard title="Terms of Service">
      <TermsContainer>
        <p>
          <strong>Welcome to GIDEON online!</strong>
          <br />
          <br />
          When using the website and services (the “Services”) of GIDEON Informatics, Inc.
          (“GIDEON”), you agree to the terms and conditions contained in this document (“Terms of
          Service”). We may revise the Terms of Service from time to time, at our sole discretion
          and without notice to you, by updating this posting. Unless otherwise provided below, the
          revised terms will take effect when they are posted.
        </p>
        <p>
          PLEASE READ THESE TERMS CAREFULLY AS USE OF THIS WEBSITE CONSTITUTES ACCEPTANCE OF THESE
          TERMS AND CONDITIONS.
        </p>
        <p>
          <strong>User Obligations</strong>. You agree not to do any of the following while using
          the Services:
        </p>
        <ul>
          <li>intentionally or unintentionally violate any applicable law or regulation;</li>
          <li>
            post or transmit any content that you do not have the right to post or transmit under
            any law, contractual duty or fiduciary relationship;
          </li>
          <li>
            post or transmit any content that infringes a third party’s trademark, patent, trade
            secret, copyright, publicity, or privacy rights;
          </li>
          <li>
            access, tamper with or use non-public areas of the website, or GIDEON’s computer
            systems. Unauthorized individuals attempting to access or tamper with these areas may be
            subject to prosecution;
          </li>
          <li>
            frame or link to the website except as permitted in writing by GIDEON; impersonate or
            misrepresent your affiliation with any person or entity. For example, you agree not to
            falsely represent that you are a copyright holder of a work or the agent of a copyright
            holder.
          </li>
        </ul>
        <p>
          <strong> Links</strong>. The website may contain links to other websites or resources. You
          acknowledge and agree that GIDEON is not responsible or liable for (i) the availability or
          accuracy of such websites or resources; or (ii) the content, advertising, or products on
          or available from such websites or resources. The inclusion of any link on the website
          does not imply that we endorse the linked site.
        </p>
        <p>
          <strong>Computer Software</strong>.&nbsp; Except as expressly permitted in any specific
          license agreements with GIDEON, you may not reproduce, record, publish, publicly exhibit
          or distribute any software made available on the GIDEON website without GIDEON’s express
          written consent. GIDEON retains title to any such software made available as part of the
          Services and you may only be granted a non-transferable, non-exclusive right and revocable
          license to use such software in accordance with a license agreement with GIDEON. GIDEON
          and its suppliers and licensors reserve all other intellectual property rights in and
          relating to such software.
        </p>
        <p>
          <strong>Protection of Content Provided by GIDEON and Its Licensors</strong>. The website
          contains trademarks and other material furnished by GIDEON, its licensors, and others. You
          should assume that everything you see or read on the website or through the Services,
          which was not furnished by you (such as sound clips, photographs, illustrations, text and
          other materials) (“Site Content”) is copyrighted unless otherwise noted. You may not sell,
          reproduce, distribute, copy, duplicate, resell, modify, display, publicly perform, prepare
          derivative works based on, repost, exploit for any commercial purpose, or otherwise use
          any of the Site Content in any way for any public or commercial purpose without prior
          written consent of GIDEON or the rights holder. You may not use the Site Content on any
          other web site or in a networked computer environment for any purpose. If you violate any
          of these terms, your permission to use the Site Content will automatically terminate, you
          must immediately destroy any copies you have made of the Site Content, and we may end your
          authorization to use the website.
        </p>
        <p>
          <strong>Notices</strong>. GIDEON will give you any necessary notices by posting them on
          the website. You authorize GIDEON to send notices via electronic mail as well if GIDEON
          decides, in its sole discretion, to do so. You agree to check the website for notices, and
          that you will be considered to have received a notice when it is made available to you by
          posting on the website.
        </p>
        <h3>Disclaimer of Warranties and Liability</h3>
        <p>
          GIDEON selects what we believe to be the most outstanding and credible medical material
          for inclusion in its Services. The editors consult only sources they deem reliable and
          competent. However, there may be delays, omissions or inaccuracies in such information.
          This information is not intended to replace professional medical advice and should not be
          used as the sole basis or diagnosis or therapy.
        </p>
        <p>
          The Services may include facts, views, opinions and recommendations of individuals and
          organizations which we believe may be of interest to our subscribers. GIDEON does not
          guarantee the accuracy, completeness or timeliness of, or otherwise endorse, these view,
          opinions or recommendations.
        </p>
        <p>
          GIDEON assumes no liability to patients with respect to the actions of physicians, health
          care facilities and other users, and is not responsible for any injury, death or damage
          resulting from the use, misuse or interpretation of information obtained through this
          program.&nbsp; Therapeutic options listed by the program are limited to published studies
          and reviews.&nbsp; Therapy should not be undertaken&nbsp; without a thorough assessment of
          the indications, contraindications and side effects of any prospective drug or
          intervention.&nbsp; Furthermore, the database is largely derived from incidence and
          prevalence statistics whose accuracy will vary widely for individual diseases and
          countries.&nbsp; Changes in endemicity, incidence, and drugs of choice may occur.&nbsp;
          The list of drugs, infectious diseases and even country names will vary with time.&nbsp;
          Although we endeavor to include such new information on a timely basis, a delay cannot be
          avoided.
        </p>
        <p>
          NEITHER GIDEON NOR ANY OTHER PARTY OR ANY MEDICAL SOCIETY MAKES ANY WARRANTY OR
          REPRESENTATION, EXPRESS OR IMPLIED, WITH RESPECT TO THE SERVICES WHICH ARE LICENSED “AS
          IS”.&nbsp; GIDEON AND ITS AFFILIATES, AGENTS AND LICENSORS CANNOT AND DO NOT WARRANT THE
          ACCURACY, COMPLETENESS, CURRENTNESS, NONINFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A
          PARTICULAR PURPOSE OF INFORMATION AVAILABLE THROUGH GIDEON. YOU ASSUME ALL RISK AS TO THE
          QUALITY, FUNCTION AND PERFORMANCE OF THE SERVICES.&nbsp; NEITHER GIDEON NOR ANY OF ITS
          AFFILIATES, AGENTS OR LICENSORS SHALL BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS OR
          INJURY, CAUSED IN WHOLE OR PART BY ITS ACTIONS (INCLUDING BUT NOT LIMITED TO NEGLIGENCE OR
          CONTINGENCIES BEYOND ITS CONTROL) IN PROCURING, COMPILING, INTERPRETING, REPORTING OR
          DELIVERING INFORMATION THROUGH GIDEON. IN NO EVENT WILL GIDEON, ITS AFFILIATES, AGENTS OR
          LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN
          RELIANCE ON SUCH INFORMATION. GIDEON AND ITS AFFILIATES, AGENTS AND LICENSORS SHALL NOT BE
          LIABLE TO YOU OR ANYONE ELSE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION,
          CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT, OR SIMILAR DAMAGES) EVEN IF ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. YOU AGREE THAT THE LIABILITY OF GIDEON, ITS AFFILIATES,
          AGENTS AND LICENSORS, IF ANY, ARISING OUT OF ANY KIND OF LEGAL CLAIM (WHETHER IN CONTRACT,
          TORT OR OTHERWISE) IN ANY WAY CONNECTED WITH THE INFORMATION PROVIDED BY GIDEON SHALL NOT
          EXCEED THE AMOUNT YOU PAID TO GIDEON FOR THE INFORMATION.
        </p>
        <p>
          Some states do not allow limitations on how long an implied warranty lasts and some states
          do not allow the exclusion or limitation of incidental or consequential damages, so the
          above limitation and exclusion may not apply to you. This warranty gives you specific
          legal rights, and you may also have other rights which vary from state to state.
        </p>
        <p>
          <strong>Indemnification</strong>. You agree to defend, indemnify, and hold harmless
          GIDEON, its officers, directors, employees, consultants, agents, co-branders or other
          partners, from and against any claims, actions, liability, damages and/or demands,
          including without limitation reasonable legal and accounting fees, made by any third party
          due to or resulting from your use of the Services; your connection to the Services; your
          violation of the rights of others, these Terms of Service or any intellectual property or
          other right of any person or entity; and any intellectual property infringement by any
          other user of your account. These Terms of Service will inure to the benefit of GIDEON’s
          successors, assigns and licensors.
        </p>
        <p>
          <strong>General Provisions</strong>. You are responsible for compliance with applicable
          local laws, keeping in mind that access to the Services may not be legal by certain
          persons or in certain countries. If any part of these Terms of Service is held to be
          invalid or unenforceable under applicable law, it shall be deemed omitted and the
          remaining provisions shall continue in full force and effect. These Terms of Service are
          personal to you and you may not transfer, assign or delegate these Terms of Service to
          anyone without the express written permission of GIDEON. Any attempt by you to assign,
          transfer or delegate these Terms of Service without the express written permission of
          GIDEON shall be null and void. The paragraph headings in these Terms of Service, shown in
          boldface type, are included to help make the agreement easier to read and have no binding
          effect. These Terms of Service constitute the entire agreement between you and GIDEON with
          respect to the use of the Services and supersede and replace all prior or contemporaneous
          agreements, written or oral, between the parties regarding such subject matter. These
          Terms of Service, your rights and obligations, and all actions contemplated by these Terms
          of Service shall be governed by and construed in accordance with the laws of the United
          States of America and the State of California, as if it was a contract wholly entered into
          and wholly performed within California, without regard to the principles of conflict of
          laws. Any waiver of any provisions of these Terms of Service will be effective only if in
          writing signed by GIDEON.
        </p>
        <ExternalLinkStyled to="/terms_of_service.pdf" download openInNewTab>
          Download this document
        </ExternalLinkStyled>
      </TermsContainer>
    </ModalCard>
  </ModalStyled>
);
