import { CompareTableBase, StickyFirstTH, StyledTH, TR } from 'Organisms/compare/CompareTableBase';
import React, { FC, ReactNode, useMemo, useState } from 'react';
import { getProbability } from 'services/compare/getCompareMicrobeData';
import { getGroupedBacteriaRows } from 'services/compare/getGroupedBacteriaRows';
import styled, { css } from 'styled-components/macro';
import { CompareColumnDefinition, ProbabilityType } from 'types/compare';
import { Row } from 'types/table';
import { SortState } from 'types/table';
import { renderCompareBody, renderCompareHead } from 'utils/renderCompareTable';

const THBorders = css`
  border-top: 1px solid ${(props) => props.theme.colors.table.compareTable.sectionBorder};
  border-bottom: 1px solid ${(props) => props.theme.colors.table.compareTable.sectionBorder};
`;

const StaticTH = styled(StyledTH)`
  padding: 8px;
  position: initial;
  ${THBorders};
  &:last-child {
    border-right: none;
  }
`;

const StyledStickyTH = styled(StickyFirstTH)`
  ${THBorders};
  position: sticky;
  left: 0;
  top: auto;
  z-index: 1;
  && {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

interface Props {
  columns: CompareColumnDefinition[];
  rows: Row[];
  probabilityType: ProbabilityType;
  onRemove(id: number): void;
}

const getGroupHead = (text: string, columns: CompareColumnDefinition[]): ReactNode => {
  return (
    <TR>
      <StyledStickyTH wrap>{text}</StyledStickyTH>
      {columns.map((c, i) => {
        return (
          <StaticTH key={i} wrap={columns[i].enableWrap}>
            {' '}
          </StaticTH>
        );
      })}
    </TR>
  );
};

const renderTestGroup = (
  text: string,
  columns: CompareColumnDefinition[],
  rows: Row[],
  sort: SortState,
  probabilityType: ProbabilityType
): ReactNode => {
  if (rows.length > 0) {
    return (
      <>
        {getGroupHead(text, columns)}
        {renderCompareBody(rows, sort, getProbability, probabilityType)}
      </>
    );
  }
  return null;
};

export const CompareBacteriaTable: FC<Props> = ({ columns, rows, probabilityType, onRemove }) => {
  const [sort, setSort] = useState<SortState>({ asc: false, columnIndex: -1 });

  const renderTHead = useMemo(() => {
    const onSortClick = (index: number): void => {
      if (index === sort.columnIndex) {
        setSort({ asc: !sort.asc, columnIndex: index });
      } else {
        setSort({ asc: true, columnIndex: index });
      }
    };

    return renderCompareHead(columns, 'Phenotypic tests', sort, onSortClick, onRemove);
  }, [columns, sort, onRemove]);

  const [basic, general, foa] = getGroupedBacteriaRows(rows);
  const testGroups = (
    <>
      {renderTestGroup('Basic tests', columns, basic, sort, probabilityType)}
      {renderTestGroup('General tests', columns, general, sort, probabilityType)}
      {renderTestGroup('Fermentation or Acidification', columns, foa, sort, probabilityType)}
    </>
  );

  return <CompareTableBase headings={renderTHead} rows={testGroups} />;
};
