import { getDiseasesByFingerprint } from 'apiServices/Diseases/fingerprint';
import React, { useCallback } from 'react';
import { newStoreError } from 'store/storeError';
import { Dispatch } from 'store/types';
import { SimpleDisease } from 'types/simpleDisease';

import {
  Action,
  FingerprintDiseasesDispatchContext,
  FingerprintDiseasesStateContext,
  State,
} from './provider';

export const useState = (): State => {
  const state = React.useContext(FingerprintDiseasesStateContext);
  if (state === undefined) {
    throw new Error('fingerprint disease state is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(FingerprintDiseasesDispatchContext);
  if (dispatch === undefined) {
    throw new Error('fingerprint disease state is not initialized');
  }
  return dispatch;
};

export interface FilterParameters {
  agent: string | null;
  vector: string | null;
  vehicle: string | null;
  reservoir: string | null;
  country: string | null;
}

export const useFingerprintDiseasesResource = (): [
  State,
  (fp: FilterParameters) => void,
  (diseases: SimpleDisease[]) => void
] => {
  const state = useState();
  const dispatch = useDispatch();

  const applyNewFilter = useCallback(
    ({ agent, vector, vehicle, reservoir, country }: FilterParameters): void => {
      dispatch({ type: 'FingerprintDiseases/LoadInitiated' });
      getDiseasesByFingerprint(agent, vector, vehicle, reservoir, country)
        .then(data => dispatch({ type: 'FingerprintDiseases/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'FingerprintDiseases/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    },
    [dispatch]
  );

  const updateState = useCallback(
    (diseases: SimpleDisease[]): void => {
      dispatch({ type: 'FingerprintDiseases/Loaded', payload: diseases });
    },
    [dispatch]
  );

  return [state, applyNewFilter, updateState];
};
