import {
  getCountryDetails,
  getCountryDetailsPublic,
  getCountryRelevantDiseases,
} from 'apiServices/Country/country';
import React, { useEffect } from 'react';
import { assertIsNotStoreError, newStoreError } from 'store/storeError';
import { Dispatch, Loading, Resource } from 'store/types';
import { CountryDetailsPublic, CountryDisease } from 'types/countryDetails';

import {
  Action,
  CountryDetailsDispatchContext,
  CountryDetailsState,
  CountryDetailsStateContext,
  State,
} from './provider';

export const useState = (): State => {
  const state = React.useContext(CountryDetailsStateContext);
  if (state === undefined) {
    throw new Error('Country details store is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(CountryDetailsDispatchContext);
  if (dispatch === undefined) {
    throw new Error('Country details store is not initialized');
  }
  return dispatch;
};

const loadCountryDiseases = (
  id: string,
  country: CountryDetailsState | undefined,
  dispatch: Dispatch<Action>
): void => {
  if (!country || !country.diseases) {
    dispatch({
      type: 'CountryDetails/Diseases/SingleLoadInitiated',
      payload: { id },
    });
    getCountryRelevantDiseases(id)
      .then(data =>
        dispatch({ type: 'CountryDetails/Diseases/SingleLoaded', payload: { id, data } })
      )
      .catch(err =>
        dispatch({
          type: 'CountryDetails/Diseases/SingleLoadFailed',
          payload: { id, error: newStoreError(err.message, err.code, err) },
        })
      );
  }
};

export const useCountryDetails = (id: string): CountryDetailsState => {
  const state = useState();
  const dispatch = useDispatch();
  const country = state[id];

  useEffect(() => {
    if (!country || !country.details) {
      dispatch({
        type: 'CountryDetails/Details/SingleLoadInitiated',
        payload: { id },
      });
      getCountryDetails(id)
        .then(data =>
          dispatch({ type: 'CountryDetails/Details/SingleLoaded', payload: { id, data } })
        )
        .catch(err =>
          dispatch({
            type: 'CountryDetails/Details/SingleLoadFailed',
            payload: { id, error: newStoreError(err.message, err.code, err) },
          })
        );
    }

    loadCountryDiseases(id, country, dispatch);
  }, [dispatch, state, country, id]);

  return country || Loading;
};

export const useCountryDetailsPublic = (id: string): CountryDetailsPublic | 'Loading' => {
  const state = useState();
  const dispatch = useDispatch();
  const country = state[id];

  useEffect(() => {
    if (!country || !country.detailsPublic) {
      dispatch({
        type: 'CountryDetails/Public/SingleLoadInitiated',
        payload: { id },
      });
      getCountryDetailsPublic(id)
        .then(data =>
          dispatch({ type: 'CountryDetails/Public/SingleLoaded', payload: { id, data } })
        )
        .catch(err =>
          dispatch({
            type: 'CountryDetails/Public/SingleLoadFailed',
            payload: { id, error: newStoreError(err.message, err.code, err) },
          })
        );
    }
  }, [country, dispatch, id]);

  if (!country) {
    return Loading;
  }

  assertIsNotStoreError(country.detailsPublic);

  return country.detailsPublic || Loading;
};

export const useCountryRelatedDiseases = (id: string): Resource<CountryDisease[]> => {
  const state = useState();
  const dispatch = useDispatch();
  const country = state[id];

  useEffect(() => {
    loadCountryDiseases(id, country, dispatch);
  }, [dispatch, state, country, id]);

  return country && country.diseases ? country.diseases : Loading;
};

export const useCountriesRelatedDiseases = (ids: string[]): void => {
  const state = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    ids.forEach(id => {
      const country = state[id];

      loadCountryDiseases(id, country, dispatch);
    });
  }, [dispatch, ids, state]);
};
