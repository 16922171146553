import { MainLayout } from 'layouts/MainLayout';
import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { UpdatesTitle } from 'Updates/Molecules/UpdatesTitle';
import { UpdatesTabSection } from 'Updates/types/tab';

import { UpdatesSelectorMobile } from './UpdatesSelectorMobile';

const TitleStyled = styled(UpdatesTitle)`
  margin-top: 25px;
`;

const Container = styled.div`
  padding: 10px;
`;

interface Props {
  match: RouteComponentProps['match'];
  sections: UpdatesTabSection[];
}

export const UpdatesMobile: FC<Props> = ({ match, sections }) => (
  <MainLayout>
    <TitleStyled />
    <Switch>
      <Route exact from={match.url}>
        <UpdatesSelectorMobile />
      </Route>
      {sections.map(section => (
        <Route path={`${match.url}/${section.slug}`} key={section.slug}>
          <Container>
            <section.Component path={`${match.url}/${section.slug}`} />
          </Container>
        </Route>
      ))}
    </Switch>
  </MainLayout>
);
