import { SimpleDisease } from 'apiServices/Diseases/DTO';
import { Link } from 'Atoms/links/Link';
import { P } from 'Atoms/text/P';
import { Span } from 'Atoms/text/Span';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { DynamicContent } from 'types/dynamicContent';

const StyledP = styled(P)`
  margin: 15px 0 10px;
`;

interface CustomVaccineDetails {
  code: number;
  vaccine: string;
  mechanismOfAction: DynamicContent;
  adultDosage: DynamicContent;
  childDosage?: DynamicContent;
  booster?: string;
  referencesLinks?: DynamicContent;
  relevantDiseases?: SimpleDisease[];
}

interface Props {
  record: CustomVaccineDetails;
}

export const VaccineSummarySection: FC<Props> = ({ record }) => (
  <>
    {record.relevantDiseases && record.relevantDiseases.length > 0 && (
      <>
        <StyledP weight="700">
          {record.relevantDiseases.length === 1 ? 'Relevant Disease' : 'Relevant Diseases'}
        </StyledP>
        <StyledP weight="500">
          {record.relevantDiseases.map((item, index) => (
            <Span key={index}>
              {index !== 0 ? ', ' : ''}
              <Link to={`/explore/diseases/${item.disease_code}`}>{item.disease}</Link>
            </Span>
          ))}
        </StyledP>
      </>
    )}
    <StyledP weight="700">Mechanism of Action</StyledP>
    <DynamicHtml content={record.mechanismOfAction} />
    <StyledP weight="700">Typical Adult Dosage</StyledP>
    <DynamicHtml content={record.adultDosage} />
    {record.childDosage && (
      <>
        <StyledP weight="700">Typical Pediatric Dosage</StyledP>
        <DynamicHtml content={record.childDosage} />
      </>
    )}
    {record.booster && (
      <StyledP>
        <Span weight="700">Subsequent booster:&nbsp;</Span>
        <Span>{record.booster}</Span>
      </StyledP>
    )}
    {record.referencesLinks && (
      <>
        <StyledP weight="700">Reference links</StyledP>
        <DynamicHtml content={record.referencesLinks} />
      </>
    )}
  </>
);
