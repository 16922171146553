import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  background: ${props => props.theme.colors.table.compareTable.probabilityKeyBackground};

  border: 1px solid ${props => props.theme.colors.table.compareTable.probabilityKeyBorder};
  border-radius: 10px;

  padding: 10px 10px;
  margin: 0;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 10px 5px;
  }
`;

interface Props {
  className?: string;
  children: ReactNode;
}

export const CompareOptionsWrapper: FC<Props> = ({ className, children }) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};
