import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { ReferenceDetails, ReferenceIdentifier } from 'types/reference';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'References/LoadInitiated', { ids: ReferenceIdentifier[] }>
  | ActionWithPayload<'References/Loaded', ReferenceDetails[]>
  | ActionWithPayload<'References/LoadFailed', { id: ReferenceIdentifier; error: StoreError }[]>;

export type State = {
  [id: string]: Resource<ReferenceDetails>;
};

const initialState: State = {};

export const {
  dispatchContext: ReferencesDispatchContext,
  stateContext: ReferencesStateContext,
  provider: ReferencesStoreProvider,
} = storeFactory(reducer, initialState);
