import React, { ReactElement } from 'react';
import { components, OptionProps } from 'react-select';
import { SelectOption as SearchSelectOptionType } from 'types/select';

const { Option } = components;

/**
 * innerProps define onMouseMove and onMouseOver to manage hovered or focused state for option items.
 * The way it works is ineficient for high number of elements, so we can disable it.
 * And handle hovered or focused states solely with CSS pseudo classes.
 * https://github.com/JedWatson/react-select/issues/3128
 */
export const SearchSelectOption = <T extends SearchSelectOptionType, isMulti extends boolean>({
  innerProps,
  ...props
}: OptionProps<T, isMulti>): ReactElement => {
  const { onMouseMove: _ignoreMove, onMouseOver: _ignoreOver, ...restInnerProps } = innerProps;

  const newInnerProps: typeof innerProps = {
    ...restInnerProps,
    onMouseMove: () => undefined,
    onMouseOver: () => undefined,
  };

  return <Option {...props} innerProps={newInnerProps} />;
};
