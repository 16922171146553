import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { VaccineAbbreviation } from 'types/vaccineAbbreviation';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'Abbreviation/SingleLoadInitiated', { id: string }>
  | ActionWithPayload<'Abbreviation/SingleLoaded', { id: string; data: VaccineAbbreviation }>
  | ActionWithPayload<'Abbreviation/SingleLoadFailed', { id: string; error: StoreError }>;

export type State = {
  [id: string]: Resource<VaccineAbbreviation> | undefined;
};

const initialState: State = {};

export const {
  dispatchContext: AbbreviationDispatchContext,
  stateContext: AbbreviationStateContext,
  provider: AbbreviationStoreProvider,
} = storeFactory(reducer, initialState);
