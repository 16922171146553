import { ReactComponent as ArrowSvg } from 'assets/UI/ArrowRight.svg';
import { Icon } from 'Atoms/Icon';
import React, { forwardRef } from 'react';
import styled from 'styled-components/macro';

import { Button, ButtonProps } from './Button';

interface StyledProps {
  isCollapsed?: boolean;
}

interface Props extends ButtonProps {
  isCollapsed?: boolean;
}

const IconStyled = styled(Icon)<StyledProps>`
  width: 16px;
  height: 16px;
  transform: ${props => (props.isCollapsed ? 'rotate(90deg)' : 'rotate(-90deg)')};
  transition: transform 0.2s ease-in-out;
`;

const CollapseButtonBase = forwardRef<HTMLButtonElement, Props>(
  ({ isCollapsed, onClick, className, ...rest }, ref) => (
    <Button className={className} onClick={onClick} {...rest} ref={ref}>
      <IconStyled svgComponent={ArrowSvg} isCollapsed={isCollapsed} />
    </Button>
  )
);

export const CollapseButton = styled(CollapseButtonBase)`
  width: 35px;
  height: 35px;
  padding: 0;
`;
