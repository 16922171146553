import { Tab } from 'Molecules/buttons/Tab';
import { Slider } from 'Molecules/Tabs';
import styled, { css } from 'styled-components/macro';

export const AZTab = styled(Tab)`
  width: 20%;

  &:nth-child(1) {
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          left: 0;
        }
      `}
  }

  &:nth-child(2) {
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: 165px;
          left: 20%;
        }
      `}
  }

  &:nth-child(3) {
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: 175px;
          left: 40%;
        }
      `}
  }

  &:nth-child(4) {
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: 198px;
          left: 60%;
        }
      `}
  }

  &:nth-child(5) {
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: 192px;
          left: 80%;
        }
      `}
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    width: 19%;
    &:nth-child(1) {
      ${props =>
        props.active &&
        css`
          & ~ ${Slider} {
            width: 166px;
          }
        `}
    }

    &:nth-child(2) {
      ${props =>
        props.active &&
        css`
          & ~ ${Slider} {
            width: 142px;
          }
        `}
    }

    &:nth-child(3) {
      ${props =>
        props.active &&
        css`
          & ~ ${Slider} {
            width: 157px;
          }
        `}
    }

    &:nth-child(4) {
      width: 20%;
      ${props =>
        props.active &&
        css`
          & ~ ${Slider} {
            width: 183px;
          }
        `}
    }

    &:nth-child(5) {
      ${props =>
        props.active &&
        css`
          & ~ ${Slider} {
            left: 81%;
            width: 174px;
          }
        `}
    }
  }
`;
