import { Link } from 'Atoms/links/Link';
import { H1, H2 } from 'Atoms/text/H';
import { MainLayout } from 'layouts/MainLayout';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useAccountController } from 'store/accountStore/hooks';
import styled from 'styled-components/macro';

const Container = styled.div`
  margin: 50px 20%;
`;

const Section = styled.div`
  border: 1px solid ${props => props.theme.colors.separator.light};
  border-radius: 10px;
`;

const Title = styled(H1)`
  margin: 40px 0 0 0;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 20px 0 0 0;
  }
`;

const SectionTitle = styled(H2)`
  text-align: left;

  margin: 10px 0;
`;

const ListItem = styled.li`
  margin: 5px 0;
`;

export const Sitemap: FC = () => {
  const { isAuthenticated } = useAccountController();

  return (
    <MainLayout>
      <Helmet>
        <title>Site Map - GIDEON</title>
      </Helmet>
      <Title weight="500" font="Quicksand">
        GIDEON Site Map
      </Title>
      <Container>
        <SectionTitle size="regular">GIDEON Home</SectionTitle>
        <Section>
          <ul>
            {isAuthenticated && (
              <>
                <ListItem>
                  <Link to="/account">Acount information</Link>
                </ListItem>
              </>
            )}
            <ListItem>
              <Link to="/az">A-Z</Link>
              <ul>
                <ListItem>
                  <Link to="/az/diseases">A-Z Diseases</Link>
                </ListItem>
                <ListItem>
                  <Link to="/az/diseases">A-Z Drugs</Link>
                </ListItem>
                <ListItem>
                  <Link to="/az/diseases">A-Z Vaccines</Link>
                </ListItem>
                <ListItem>
                  <Link to="/az/microbes">A-Z Microbes</Link>
                  <ul>
                    <ListItem>
                      <Link to="/az/microbes/bacteria">A-Z Bacteria</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/az/microbes/mycobacteria">A-Z Mycobacteria</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/az/microbes/viruses">A-Z Viruses</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/az/microbes/yeasts">A-Z Yeasts and Algae</Link>
                    </ListItem>
                  </ul>
                </ListItem>
                <ListItem>
                  <Link to="/az/countries">A-Z Countries</Link>
                </ListItem>
              </ul>
            </ListItem>
            {isAuthenticated && (
              <>
                <ListItem>
                  <Link to="/compare">Compare</Link>
                  <ul>
                    <ListItem>
                      <Link to="/compare/diseases">Compare diseases</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/compare/drugs">Compare drugs</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/compare/bacteria">Compare bacteria</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/compare/mycobacteria">Compare mycobacteria</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/compare/yeasts">Compare yeasts</Link>
                    </ListItem>
                  </ul>
                </ListItem>
              </>
            )}
            {isAuthenticated && (
              <>
                <ListItem>
                  <Link to="/ebooks">eBooks</Link>
                  <ul>
                    <ListItem>
                      <Link to="/ebooks/countries">Country eBooks</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/ebooks/diseases">Diseases eBooks</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/ebooks/other">GIDEON Guides</Link>
                    </ListItem>
                  </ul>
                </ListItem>
              </>
            )}
            <ListItem>
              <Link to="/start">Start</Link>
            </ListItem>
            {isAuthenticated && (
              <ListItem>
                <Link to="/search">Search</Link>
              </ListItem>
            )}
            <ListItem>
              <Link to="/updates">Updates</Link>
              <ul>
                <ListItem>
                  <Link to="/updates/data">Updates latest data</Link>
                  <ul>
                    <ListItem>
                      <Link to="/updates/data/48-hours">Updates latest data 48 hours</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/updates/data/7-days">Updates latest data 7 days</Link>
                    </ListItem>
                  </ul>
                </ListItem>
                <ListItem>
                  <Link to="/updates/outbreaks">Updates outbreaks map</Link>
                </ListItem>
                <ListItem>
                  <Link to="/updates/content">Updates content</Link>
                </ListItem>
              </ul>
            </ListItem>
          </ul>
        </Section>
        <SectionTitle size="regular">GIDEON Explore</SectionTitle>
        <Section>
          <ul>
            <ListItem>
              <Link to="/explore">Explore home</Link>
              <ul>
                <ListItem>
                  <Link to="/explore/diseases">Explore diseases</Link>
                </ListItem>
                <ListItem>
                  <Link to="/explore/drugs">Explore drugs</Link>
                </ListItem>
                <ListItem>
                  <Link to="/explore/vaccines">Explore vaccines</Link>
                </ListItem>
                <ListItem>
                  <Link to="/explore/microbes">Explore microbes</Link>
                </ListItem>
                <ListItem>
                  <Link to="/explore/countries">Explore countries</Link>
                </ListItem>
              </ul>
            </ListItem>
          </ul>
        </Section>
        {isAuthenticated && (
          <>
            <SectionTitle size="regular">GIDEON Lab</SectionTitle>
            <Section>
              <ul>
                <ListItem>
                  <Link to="/lab">Lab home</Link>
                  <ul>
                    <ListItem>
                      <Link to="/lab/bacteria">Lab bacteria</Link>
                      <ul>
                        <ListItem>
                          <Link to="/lab/bacteria/probability-engine">Probability engine</Link>
                        </ListItem>
                        <ListItem>
                          <Link to="/lab/bacteria/tree">Decision tree</Link>
                        </ListItem>
                      </ul>
                    </ListItem>
                    <ListItem>
                      <Link to="/lab/mycobacteria">Lab mycobacteria</Link>
                      <ul>
                        <ListItem>
                          <Link to="/lab/mycobacteria/probability-engine">Probability engine</Link>
                        </ListItem>
                        <ListItem>
                          <Link to="/lab/mycobacteria/tree">Decision tree</Link>
                        </ListItem>
                      </ul>
                    </ListItem>
                    <ListItem>
                      <Link to="/lab/yeasts">Lab yeasts</Link>
                      <ul>
                        <ListItem>
                          <Link to="/lab/yeasts/probability-engine">Probability engine</Link>
                        </ListItem>
                        <ListItem>
                          <Link to="/lab/yeasts/tree">Decision tree</Link>
                        </ListItem>
                      </ul>
                    </ListItem>
                  </ul>
                </ListItem>
              </ul>
            </Section>
            <SectionTitle size="regular">GIDEON Diagnose</SectionTitle>
            <Section>
              <ul>
                <ListItem>
                  <Link to="/diagnose">Diagnose home</Link>
                  <ul>
                    <ListItem>
                      <Link to="/diagnose/probability-engine">Probability engine</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/diagnose/steps">Steps</Link>
                    </ListItem>
                  </ul>
                </ListItem>
              </ul>
            </Section>
          </>
        )}
        <SectionTitle size="regular">GIDEON Visualize</SectionTitle>
        <Section>
          <ul>
            <ListItem>
              <Link to="/visualize">Visualize home</Link>
              <ul>
                <ListItem>
                  <Link to="/visualize/disease-outbreaks/start">Disease outbreak maps</Link>
                </ListItem>
                <ListItem>
                  <Link to="/visualize/graphs">Epidemiological graphs</Link>
                </ListItem>
                <ListItem>
                  <Link to={`/visualize/global-outbreaks-map/${new Date().getFullYear()}`}>
                    Global outbreaks map
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/visualize/vaccine-coverage-maps/start">Vaccine coverage maps</Link>
                </ListItem>
              </ul>
            </ListItem>
          </ul>
        </Section>
      </Container>
    </MainLayout>
  );
};
