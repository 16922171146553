import { useContext } from 'react';
import { useMedia } from 'services/useMedia.hook';
import { DefaultTheme, ThemeContext } from 'styled-components/macro';

export const useMobile = (
  breakpoint: keyof DefaultTheme['breakpoints'],
  widthType?: 'min' | 'max'
): boolean => {
  const theme = useContext(ThemeContext);
  const breakpointQuery = breakpoint
    ? `(${widthType || 'max'}-width: ${theme.breakpoints[breakpoint]})`
    : `(max-width: ${theme.breakpoints.m})`;

  return useMedia(breakpointQuery);
};

export const useTouchScreen = (): boolean => useMedia('(hover: none) and (pointer: coarse)');
