import { CompareTableBase } from 'Organisms/compare/CompareTableBase';
import React, { FC, useMemo, useState } from 'react';
import { CompareColumnDefinition, Probability, ProbabilityType } from 'types/compare';
import { Row } from 'types/table';
import { SortState } from 'types/table';
import { renderCompareBody, renderCompareHead } from 'utils/renderCompareTable';

interface Props {
  columns: CompareColumnDefinition[];
  rows: Row[];
  probabilityType: ProbabilityType;
  firstColumnTitle: string;
  onRemove(id: number): void;
  getProbability: (probability: number) => Probability | undefined;
}

export const CompareTable: FC<Props> = ({
  columns,
  rows,
  probabilityType,
  firstColumnTitle,
  onRemove,
  getProbability,
}) => {
  const [sort, setSort] = useState<SortState>({ asc: false, columnIndex: -1 });

  const renderTHead = useMemo(() => {
    const onSortClick = (index: number): void => {
      if (index === sort.columnIndex) {
        setSort({ asc: !sort.asc, columnIndex: index });
      } else {
        setSort({ asc: true, columnIndex: index });
      }
    };
    return renderCompareHead(columns, firstColumnTitle, sort, onSortClick, onRemove);
  }, [columns, sort, onRemove, firstColumnTitle]);

  const renderTBody = useMemo(
    () => renderCompareBody(rows, sort, getProbability, probabilityType),
    [rows, sort, probabilityType, getProbability]
  );

  return <CompareTableBase headings={renderTHead} rows={renderTBody} />;
};
