import { P, Span } from 'Atoms/text';
import { CompareDrugKey } from 'Molecules/compare/CompareDrugKey';
import React, { FC } from 'react';
import { getKeyInfo } from 'services/compare/getCompareDrugData';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 40%;
`;

const Keys = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
`;

const KeyGroup = styled.div`
  display: flex;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-wrap: wrap;
  }
`;

const Key = styled.div`
  display: flex;
  width: 310px;
  margin: 15px 15px 0 0;
`;

const StyledDrugKey = styled(CompareDrugKey)`
  width: 20px;
  height: 20px;
  fill: ${props => props.theme.colors.table.compareTable.icon};
  margin-right: 15px;
`;

interface Props {
  className?: string;
}
export const CompareDrugsKeys: FC<Props> = ({ className }) => {
  return (
    <Wrapper className={className}>
      <P weight="600" size="medium">
        Key
      </P>
      <Keys>
        <KeyGroup>
          <Key>
            <StyledDrugKey type="Check" />
            <Span>{getKeyInfo('Check')}</Span>
          </Key>
          <Key>
            <StyledDrugKey type="Combine" />
            <Span>{getKeyInfo('Combine')}</Span>
          </Key>
        </KeyGroup>
        <KeyGroup>
          <Key>
            <StyledDrugKey type="Star" />
            <Span>{getKeyInfo('Star')}</Span>
          </Key>
          <Key>
            <StyledDrugKey type="Surgery" />
            <Span>{getKeyInfo('Surgery')}</Span>
          </Key>
        </KeyGroup>
      </Keys>
    </Wrapper>
  );
};
