import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'LegalModal/TermsAndConditions': {
      return {
        ...state,
        termsAndConditionsModal: action.payload,
      };
    }
    case 'LegalModal/LicenseAgreement': {
      return {
        ...state,
        licenseAgreementModal: action.payload,
      };
    }
    case 'LegalModal/Privacy': {
      return {
        ...state,
        privacyModal: action.payload,
      };
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
