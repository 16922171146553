import styled from 'styled-components/macro';

export const AccountMenuOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  border: solid;
  border-width: 0px 2px 2px 2px;
  border-color: ${props => props.theme.colors.accountMenu.hover.borderColor};
  border-radius: 0px 0px 4px 4px;
  background-color: ${props => props.theme.colors.accountMenu.hover.backgroundColor};
`;
