import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { VaccineDetails, VaccineDetailsPublic } from 'types/vaccine';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'VaccineDetails/SingleLoadInitiated', { id: number }>
  | ActionWithPayload<'VaccineDetails/SingleLoaded', { id: number; data: VaccineDetails }>
  | ActionWithPayload<'VaccineDetails/SingleLoadFailed', { id: number; error: StoreError }>
  | ActionWithPayload<'VaccineDetails/Public/SingleLoadInitiated', { id: number }>
  | ActionWithPayload<
      'VaccineDetails/Public/SingleLoaded',
      { id: number; data: VaccineDetailsPublic }
    >
  | ActionWithPayload<'VaccineDetails/Public/SingleLoadFailed', { id: number; error: StoreError }>;

export type State = {
  vaccineDetails: { [id: number]: Resource<VaccineDetails> | undefined };
  vaccineDetailsPublic: { [id: number]: Resource<VaccineDetailsPublic> | undefined };
};

const initialState: State = {
  vaccineDetails: {},
  vaccineDetailsPublic: {},
};

export const {
  dispatchContext: VaccineDetailsDispatchContext,
  stateContext: VaccineDetailsStateContext,
  provider: VaccineDetailsStoreProvider,
} = storeFactory(reducer, initialState);
