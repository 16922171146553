import { ReactComponent as DownloadIcon } from 'assets/UI/Download.svg';
import { FilledButton, Props as FilledButtonProps } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import React, { FC, ReactNode } from 'react';
import { useTooltip } from 'services/useTooltip';
import { useAccountController } from 'store/accountStore/hooks';
import styled from 'styled-components/macro';

const StyledIcon = styled(Icon)`
  margin: 0 15px 0 0;
`;

const Container = styled.div`
  margin: 10px 20px 10px 0;
  width: 230px;
`;

interface Props extends FilledButtonProps {
  label?: ReactNode;
}

export const DownloadButton: FC<Props> = ({ className, label = 'Download PDF', ...rest }) => {
  const { tokenPayload } = useAccountController();
  const [props, popper, setElement] = useTooltip('Download up to 10 items in any 24h period');

  const isInstitutional = !!tokenPayload?.user.institutionId;

  const ariaProps = isInstitutional ? {} : props.aria;

  return (
    <Container className={className} {...props.events}>
      <FilledButton {...rest} {...ariaProps} ref={setElement}>
        <StyledIcon svgComponent={DownloadIcon} size="small" />
        {label}
      </FilledButton>
      {!isInstitutional && popper}
    </Container>
  );
};
