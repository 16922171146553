import { SelectOption } from './select';

export interface Country {
  id: string;
  name: string;
}

export interface CountryWithSlug extends Country {
  slug: string;
}

export interface CountrySelectOption extends SelectOption {
  iso2Code: string | null;
  iso3Code: string | null;
}

export const isCountrySelectOption = (value: SelectOption): value is CountrySelectOption =>
  'iso2Code' in (value as CountrySelectOption) && 'iso3Code' in (value as CountrySelectOption);
