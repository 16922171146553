import { Loading } from 'store/types';

import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'Classes/LoadInitiated': {
      return {
        ...state,
        classes: Loading,
      };
    }
    case 'Classes/Loaded':
    case 'Classes/LoadFailed': {
      return {
        ...state,
        classes: action.payload,
      };
    }
    default: {
      // eslint-disable-next-line
      const never: never = action;
      return state;
    }
  }
};
