import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { CountryPageTitle } from 'Molecules/CountryPageTitle';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { CountryDiseasesSection } from 'Molecules/section/CountryDiseasesSection';
import { DownloadsSection } from 'Molecules/section/DownloadsSection';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { useCountryRelatedDiseases } from 'store/countryDetailsStore/hooks';
import { useCountryLatestOutbreaks } from 'store/countryLatestOutbreaksStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';

const countryId = 'G90';

export const CountryBioterrorism: FC = () => {
  const [showSection, setShowSection] = useState({ diseases: true, downloads: true });
  const diseases = useCountryRelatedDiseases(countryId);
  const latestOutbreaks = useCountryLatestOutbreaks(countryId);

  assertIsNotStoreError(diseases);
  assertIsNotStoreError(latestOutbreaks);

  if (isLoading(diseases) || isLoading(latestOutbreaks)) {
    return <Loader />;
  }

  const sections: RecordLink[] = [
    {
      label: `Bioterror diseases (${diseases.filter(d => d.is_endemic)?.length})`,
      'aria-label': `Diseases currently endemic to bioterror (${
        diseases.filter(d => d.is_endemic)?.length
      })`,
    },
    {
      label: `Non bioterror diseases (${diseases.filter(d => !d.is_endemic)?.length})`,
      'aria-label': `Diseases not currently endemic to bioterror (${
        diseases.filter(d => !d.is_endemic)?.length
      })`,
    },
    { label: 'Downloads', 'aria-label': `Bioterrorism downloads` },
  ];

  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>Bioterrorism - GIDEON</title>
        </Helmet>
        <ReferenceLabelContext.Provider value="Bioterrorism">
          <RecordLayout>
            <CountryPageTitle country="Bioterrorism" countryId="bioterrorism" />
            <RecordLinks links={sections} />
            <RecordSection
              link={sections[0]}
              show={showSection.diseases}
              onCollapse={() =>
                setShowSection(section => ({ ...section, diseases: !section.diseases }))
              }
              accent="first"
              id="bioterror-Endemic-diseases"
            >
              <CountryDiseasesSection
                diseases={diseases.filter(el => el.is_endemic)}
                countryId={countryId}
                latestOutbreaks={latestOutbreaks}
              />
            </RecordSection>
            <RecordSection
              link={sections[1]}
              show={showSection.diseases}
              onCollapse={() =>
                setShowSection(section => ({ ...section, diseases: !section.diseases }))
              }
              accent="second"
              id="bioterror-NotEndemic-diseases"
            >
              <CountryDiseasesSection
                diseases={diseases.filter(el => !el.is_endemic)}
                countryId={countryId}
                latestOutbreaks={latestOutbreaks}
              />
            </RecordSection>
            <RecordSection
              link={sections[2]}
              show={showSection.downloads}
              onCollapse={() =>
                setShowSection(section => ({ ...section, downloads: !section.downloads }))
              }
              accent="third"
              id="country-bioterrorism-downloads"
            >
              <DownloadsSection category="diseases" id="bioterror" labelTitle="Bioterrorism" />
            </RecordSection>
          </RecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};
