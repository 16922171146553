import { Loader } from 'Atoms/Loader';
import { P } from 'Atoms/text';
import { Props as PProps } from 'Atoms/text/P';
import { AlphabetSelector } from 'Molecules/AlphabetSelector';
import { AZDiseasesButtons } from 'Molecules/az/AZDiseasesButtons';
import { AZDownloadButtons } from 'Molecules/az/AZDownloadButtons';
import { AZResultsList } from 'Molecules/az/AZResultsList';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { match, Redirect } from 'react-router-dom';
import { useAccountController } from 'store/accountStore/hooks';
import { useDiseasesCategoryGrouped } from 'store/diseasesGroupStore/hooks';
import { useDiseasesGrouped } from 'store/diseasesStore/hooks';
import styled from 'styled-components/macro';
import { CategoryType, DiseaseType } from 'types/az';
import { getComparisonListsSection } from 'types/comparisonLists';
import { formatNullableNumber } from 'utils/formatNumber';

const ResultsCountParagraph = styled(P)`
  margin-top: 20px;
`;

const CaptionWithMargin = styled(P).attrs(
  (): Partial<PProps> => ({
    size: 'big',
  })
)`
  margin-top: 20px;
`;

interface Props {
  match: match;
  category: CategoryType | DiseaseType;
  isMobile?: boolean;
  title: string;
}

export const AZDiseasesDesktop: FC<Props> = ({ match, category, title }) => {
  const data = useDiseasesGrouped();
  const groupedData = useDiseasesCategoryGrouped();
  const { isAuthenticated } = useAccountController();

  if (category !== 'diseases' && !isAuthenticated) {
    return <Redirect to={'/az/diseases/all'} />;
  }

  if (!data || !groupedData) {
    return <div>Error...</div>;
  }

  if (
    (category === 'diseases' && data && data.total === 0) ||
    (category !== 'diseases' &&
      groupedData[category as DiseaseType] &&
      groupedData[category as DiseaseType].total === 0)
  ) {
    return <Loader />;
  }

  const exploreUrlPrefix = match.url.replace('az', 'explore').split('/').slice(0, -1).join('/');
  const compareSection = getComparisonListsSection(match.url.split('/')[2] || '');

  return (
    <>
      <Helmet>
        <title>A-Z {title} - GIDEON</title>
      </Helmet>
      <AlphabetSelector basePath={match.url} data={data.data} />
      <ResultsCountParagraph size="big">
        GIDEON database contains <strong>{formatNullableNumber(data.total)}</strong> diseases.
      </ResultsCountParagraph>
      <CaptionWithMargin>Click on the icons below to filter by etiology.</CaptionWithMargin>
      <AZDiseasesButtons
        basePath={match.url}
        dataCount={category === 'diseases' ? 0 : groupedData[category as DiseaseType].total}
      />
      <AZResultsList
        data={category === 'diseases' ? data.data : groupedData[category as DiseaseType].data}
        basePath={match.url}
        exploreUrlPrefix={exploreUrlPrefix}
        compareSection={compareSection}
        useDOMOptimization={false}
      />
      <AZDownloadButtons category={'diseases'} />
    </>
  );
};
