import { HiddenElementForCapture } from 'Atoms/HiddenElementForCapture';
import React, { forwardRef, ReactElement, useContext, useImperativeHandle, useState } from 'react';
import { useElementCapture } from 'services/useElementCapture.hook';
import styled, { ThemeContext } from 'styled-components/macro';

export interface CaptureElementRef {
  capture: () => void;
}

interface Props {
  children: ReactElement;
  imageName?: string;
  forModal?: boolean;
}

const FullLogo = styled.img`
  display: block;
  width: auto;
  height: 25px;
  position: absolute;
  left: 50px;
  top: 50px;
  z-index: 999;

  &.modal-logo {
    top: 20px;
  }
`;

export const GraphCapture = forwardRef<CaptureElementRef, Props>(
  ({ children, imageName, forModal }, ref) => {
    const { ref: captureRef, capture } = useElementCapture(imageName);
    const theme = useContext(ThemeContext);
    const [mount, setMount] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        capture: () => {
          setMount(true);
          // TODO: setTimeout switch to ready event listiner
          setTimeout(() => capture().then(() => setMount(false)), 1000);
        },
      }),
      [capture]
    );

    return mount ? (
      <HiddenElementForCapture ref={captureRef}>
        <FullLogo
          src={theme.images.logo}
          alt="Gideon logo"
          className={`${forModal ? 'modal-logo' : ''}`}
        />
        {children}
      </HiddenElementForCapture>
    ) : null;
  }
);
