import { FilledButton } from 'Atoms/buttons/FilledButton';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { SectionsDictionary } from 'types/az';
import { alphabet } from 'utils/alphabet';

const StyledButton = styled(FilledButton)`
  min-width: 30px;
  min-height: 30px;

  width: 40px;
  height: 40px;
  flex-grow: 1;
  margin: 10px 8px 0 0;

  padding: 0;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    margin-right: 4px;
    width: 30px;
    height: 30px;
  }
`;

interface AlphabetButtonProps {
  children: string;
  className?: string;
  to: string;
  isActive?: boolean;
  'aria-expanded'?: boolean;
  'aria-label'?: string;
}

const AlphabetButton: FC<AlphabetButtonProps> = ({
  children,
  className,
  to,
  isActive,
  ...rest
}) => {
  const { replace } = useHistory();
  return (
    <StyledButton
      className={className}
      size="mediumBig"
      onClick={() => replace(to)}
      active={isActive}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin: -8px 0 0 0;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  z-index: 2;
  background: ${props => props.theme.colors.background.primary};
`;

const Selectors = styled.div`
  display: flex;
  width: 100%;
`;

interface Props {
  basePath: string;
  data: SectionsDictionary;
}

export const AlphabetSelector: FC<Props> = ({ basePath, data }) => {
  const { pathname } = useLocation();

  return (
    <Wrapper>
      <Selectors>
        {alphabet.map(x => {
          const to = `${basePath}/${x}`;

          return (
            <AlphabetButton
              key={x}
              to={to}
              isActive={pathname === to}
              aria-expanded={pathname === to}
              aria-label={`${data[x].length} results found for letter ${x}`}
            >
              {x}
            </AlphabetButton>
          );
        })}
      </Selectors>
    </Wrapper>
  );
};
