import { Loader } from 'Atoms/Loader';
import { AuthRoute } from 'Atoms/routes/AuthRoute';
import { Password } from 'Auth/pages/Password';
import { SignUp } from 'Auth/pages/SignUp';
import { Explore } from 'Explore/pages';
import { AppLayout } from 'layouts/AppLayout';
import { NotFound } from 'pages/404';
import { Account } from 'pages/Account';
import { AZ } from 'pages/AZ';
import { Compare } from 'pages/Compare';
import { EBooksRedirects } from 'pages/EBooks';
import { References } from 'pages/References';
import { Search } from 'pages/Search';
import { Sitemap } from 'pages/Sitemap';
import { Start } from 'pages/Start';
import React, { FC, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { DefaultStartPageContext } from 'services/localStorage/defaultStartPage.provider';
import { LoginModalContextProvider } from 'services/loginModal.service';
import { useContextAssert } from 'services/useContextAssert.hook';
import { useAccountController } from 'store/accountStore/hooks';
import { startLinks } from 'types/startPage';
import { Updates } from 'Updates/pages/Updates';

const Diagnose = lazy(() => import('Diagnose/pages'));
const Lab = lazy(() => import('Lab/pages'));
const Visualize = lazy(() => import('Visualize/pages'));

const AuthApp: FC = () => (
  <Switch>
    <Route path="/compare" component={Compare} />
    <Route path="/diagnose" component={Diagnose} />
    <Route path="/lab" component={Lab} />
    {process.env.REACT_APP_FEATURE_ACCOUNT_SETTINGS === 'enabled' && (
      <Route exact path="/account" component={Account} />
    )}
    <Route exact path="/search" component={Search} />
    <Route component={NotFound} />
  </Switch>
);

export const App: FC = () => {
  const [defaultStartPageValue] = useContextAssert(DefaultStartPageContext);
  const { isAuthenticated } = useAccountController();

  const startPage = isAuthenticated ? defaultStartPageValue : 'login';

  return (
    <LoginModalContextProvider>
      <AppLayout>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Redirect exact from="/" to={startLinks[startPage]} />
            <Route exact path="/start" component={Start} />
            <Route path="/explore" component={Explore} />
            <Route path="/az" component={AZ} />
            <Route path="/visualize" component={Visualize} />
            {process.env.REACT_APP_FEATURE_UPDATES === 'enabled' && (
              <Route path="/updates" component={Updates} />
            )}
            <Route exact path="/references/:type/:id" component={References} />
            <Route path="/signup" component={SignUp} />
            <Route path="/password" component={Password} />
            <Route path="/sitemap" component={Sitemap} />
            <Route path="/ebooks" component={EBooksRedirects} />
            <AuthRoute
              path={['/compare', '/diagnose', '/lab', '/account', '/search']}
              component={AuthApp}
            />
            <Redirect from="/quantify" to="/visualize/graphs" />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </AppLayout>
    </LoginModalContextProvider>
  );
};
