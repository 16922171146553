import { Graph } from 'Atoms/Graph';
import { MultiGraphTable } from 'Atoms/table/MultiGraphTable';
import { H3 } from 'Atoms/text';
import { GraphAndTableButton } from 'Molecules/buttons/GraphAndTableButton';
import React, { FC, useState } from 'react';
import { useMultiGraphData } from 'services/useMultiLineGraphData.hook';
import styled from 'styled-components/macro';
import { VaccinationChartEntity } from 'types/countryDetails';

const GraphWrapper = styled.div`
  width: 100%;
  height: 500px;
`;

const Container = styled.div`
  display: flex;
  margin: 0 0 10px 0;
`;

const ButtonContainer = styled.div`
  margin: 0 0 0 auto;
`;

const columns = ['BCG', 'DTP3', 'Polio', 'Measles'];

interface Props {
  data: VaccinationChartEntity[];
  countryName: string;
}

export const VaccinationGraphSection: FC<Props> = ({ countryName, data }) => {
  const graphData = useMultiGraphData(data, columns);
  const [isGraphSelected, setIsGraphSelected] = useState(true);

  const getTitle = (): string => {
    const diseases = columns.filter(c => data.some(d => d.title.indexOf(c) > -1)).join(', ');

    return `${countryName}. ${diseases} vaccine. WHO estimated % coverage.`;
  };

  return (
    <>
      <Container>
        <ButtonContainer>
          <GraphAndTableButton
            isGraphSelected={isGraphSelected}
            setIsGraphSelected={setIsGraphSelected}
          />
        </ButtonContainer>
      </Container>
      <H3 weight="600">{getTitle()}</H3>
      {isGraphSelected ? (
        <GraphWrapper>
          <Graph
            data={graphData}
            xTitle="Year"
            yTitle1="WHO estimated % coverage"
            legendPosition="right"
            yMaxValue1="100"
            yMaxValue2="100"
          />
        </GraphWrapper>
      ) : (
        <MultiGraphTable data={graphData} isTransposed />
      )}
    </>
  );
};
