import { DataDTO } from 'apiServices/common/DTO';
import axios from 'axios.cached';
import { EmailExists } from 'types/account';
import { Institution } from 'types/institution';

import { EmailExistsDTO, InstitutionDTO } from './account.dto';

export const accountSignUp = async (
  firstName: string,
  lastName: string,
  position: string,
  email: string,
  institution: string,
  howHear: string
): Promise<void> =>
  await axios.post('/api/account/connect', {
    first_name: firstName,
    last_name: lastName,
    position,
    email,
    institution,
    how_hear: howHear
  });

export const accountSignUpVerify = async (id: string, hash: string): Promise<boolean> => {
  const response = await axios.post<boolean>('/api/account/verify-email', {
    id,
    hash,
  });

  return response.data;
};

export const accountSendVerificationEmail = async (email: string): Promise<boolean> => {
  const response = await axios.post<boolean>('/api/account/send-verify-email', {
    email,
    validation_url: `${process.env.REACT_APP_URL}/signup/verify`,
  });

  return response.data;
};

export const magicLinkSend = async(username:string):Promise<string>=>{
  const res = await axios.get<{message:string}>(`/api/auth/magiclink?email=${username}`);
  return res.data.message
}

export const magicHashLogin = async(hash:string):Promise<string>=>{
  const res = await axios.get(`/api/auth/magiclink/${hash}`);
  return res.data.token || res.data.message || 'Invalid response';
}

export const sendPasswordResetEmail = async (email: string): Promise<void> => {
  const response = await axios.post('/api/auth/send-password-reset-email', {
    email,
    link: `${process.env.REACT_APP_URL}/password/reset`,
  });

  return response.data;
};

export const verifyPasswordResetToken = async (token: string): Promise<void> => {
  const response = await axios.post('/api/auth/verify-password-reset-token', {
    token,
  });

  return response.data;
};

export const passwordReset = async (token: string, password: string): Promise<void> => {
  const response = await axios.post('/api/auth/password-reset', {
    token,
    password,
  });

  return response.data;
};

export const getInstitutionList = async (search?: string): Promise<Institution[]> => {
  const response = await axios.get<DataDTO<InstitutionDTO[]>>(
    `/api/auth/institution/list?search=${search}&return_url=${process.env.REACT_APP_URL}`
  );

  return response.data.data.map(i => ({
    name: i.institution_name,
    url: i.institution_url,
    userGuide: i.user_guide,
    contactEmail: i.contact_email,
    isSubscriber: i.is_subscriber !== 'N',
  }));
};

export const getAccountEmailExists = async (email: string): Promise<EmailExists> => {
  const response = await axios.get<EmailExistsDTO>(`/api/account/exists?email=${email}`);

  return {
    domainExists: response.data.domain_exists,
    exists: response.data.exists,
  };
};
