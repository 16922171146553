import { TD, TH, TR } from 'Atoms/table/Table';
import { CloseButton } from 'Molecules/buttons/CloseButton';
import { HorizontalScrollbarShadows } from 'Organisms/HorizontalScrollbarShadows';
import React, { FC, ReactNode, RefObject } from 'react';
import styled, { css } from 'styled-components/macro';
export { TR } from 'Atoms/table/TR';

const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  margin-bottom: 20px;
`;

const StyledHorizontalScrollbarShadows = styled(HorizontalScrollbarShadows)`
  margin-top: 40px;
`;

const DisplayCloseButton = css`
  visibility: visible;
  transform: translate(-50%, -50%);
`;

export const StyledCloseButton = styled(CloseButton)`
  z-index: 4;
  visibility: hidden;
  margin-left: 5px;
  position: absolute;
  top: 13px;
  left: 50%;
  transform: translate(-50%, -40px);
  transition: all 0.2s ease-in-out;
  @media (hover: none), (max-width: ${props => props.theme.breakpoints.sm}) {
    ${DisplayCloseButton}
  }
`;

export const StyledTH = styled(TH)`
  position: sticky;
  top: 0;
  z-index: 3;
  background: ${props => props.theme.colors.table.row.backgroundColor};
  color: ${props => props.theme.colors.table.compareTable.headingText};
  text-align: left;
  padding: 22px 25px 20px 8px;
  border-top: none;
  border-left: none;
  border-color: ${props => props.theme.colors.table.compareTable.border};
  border-bottom: 2px solid ${props => props.theme.colors.table.compareTable.border};
  &:last-child {
    border-right: none;
  }
  &:hover {
    ${StyledCloseButton} {
      ${DisplayCloseButton}
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    min-width: 140px;
    padding: 22px 21px 6px 8px;
  }

  @supports (-ms-ime-align: auto) {
    position: relative;
  }
`;

const StyledTR = styled(TR)`
  &:hover {
    background: ${props => props.theme.colors.table.row.backgroundColor};
  }
`;

export const StyledTD = styled(TD)`
  padding: 8px 20px 8px 8px;
  &:not(:first-child) {
    padding-left: 22px;
  }
  border: none;
  border-right: 1px solid ${props => props.theme.colors.table.compareTable.border};
  &:last-child {
    border-right: none;
  }
`;

export const StickyFirstTH = styled(StyledTH)`
  position: sticky;
  left: 0;
  font-weight: 600;
  top: 0;
  z-index: 4;
  padding-left: 8px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    min-width: 155px;
    max-width: 155px;
  }
`;

export const StickyTH = styled(TH)`
  position: sticky;
  left: 0;
  top: auto;
  z-index: 2;
  font-weight: 500;
  padding: 8px 20px 8px 8px;
  background: ${props => props.theme.colors.table.row.backgroundColor};
  color: ${props => props.theme.colors.table.compareTable.headingText};
  border: none;
  border-right: 1px solid ${props => props.theme.colors.table.compareTable.border};
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    white-space: normal;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    min-width: 110px;
    max-width: 110px;
    padding: 0 20px 8px 8px;
  }

  @supports (-ms-ime-align: auto) {
    position: unset;
  }
`;

const Container = styled.div`
  position: relative;
`;

type WrapperProps = Pick<Props, 'disableShadows' | 'fullHeight' | 'className'>;

const Wrapper: FC<WrapperProps> = ({ className, fullHeight, disableShadows, children }) =>
  disableShadows ? (
    <Container>{children}</Container>
  ) : (
    <StyledHorizontalScrollbarShadows className={className} fullHeight={fullHeight}>
      {children}
    </StyledHorizontalScrollbarShadows>
  );

interface Props {
  className?: string;
  headings: ReactNode;
  rows: ReactNode;
  fullHeight?: boolean;
  tableRef?: RefObject<HTMLTableElement>;
  disableShadows?: boolean;
}

export const CompareTableBase: FC<Props> = ({
  headings,
  rows,
  className,
  fullHeight = false,
  tableRef,
  disableShadows,
}) => (
  <Wrapper className={className} fullHeight={fullHeight} disableShadows={disableShadows}>
    <StyledTable ref={tableRef}>
      <thead>
        <StyledTR>{headings}</StyledTR>
      </thead>
      <tbody>{rows}</tbody>
    </StyledTable>
  </Wrapper>
);
