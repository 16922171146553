import { Icon } from 'Atoms/Icon';
import React, { forwardRef, MouseEvent, useCallback } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { usePageName } from 'services/pageName.service';
import { PageNameState } from 'services/usePushState.hook';
import styled from 'styled-components/macro';

const selectedClassName = 'activeNav';

interface Props extends NavLinkProps {
  className?: string;
  onClick?: () => void;
}

const BasicLink = forwardRef<HTMLAnchorElement, Props>(
  ({ className, children, onClick, to, ...rest }, ref) => {
    const pageName = usePageName();
    const onClickHandler = useCallback(
      (e: MouseEvent) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      },
      [onClick]
    );

    const state: PageNameState = {
      pageName: pageName,
    };

    const newTo =
      typeof to === 'string'
        ? {
            pathname: to,
            state,
          }
        : { ...to, state };

    return (
      <NavLink
        ref={ref}
        className={className}
        onClick={onClickHandler}
        {...rest}
        to={newTo}
        activeClassName={selectedClassName}
      >
        {children}
      </NavLink>
    );
  }
);

export const DisabledNavbarLink = styled.span`
  font-family: ${props => props.theme.fontFamily.Inter};
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: ${props => props.theme.colors.text.disabledNavLink};
  text-decoration: none;
  transition: color 0.3s;
`;

export const StaticNavbarLink = styled(BasicLink)`
  font-family: ${props => props.theme.fontFamily.Inter};
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: ${props => props.theme.colors.text.navbar};
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: ${props => props.theme.colors.text.navbarActive};
  }

  &:focus {
    outline: 2px solid ${props => props.theme.colors.focus};
  }
`;

export const NavbarLink = styled(StaticNavbarLink)`
  &::after {
    margin-top: 2px;
    content: '';
    display: block;
    transform: scaleX(0);
    transform-origin: left;
    height: 3px;
    background-color: ${props => props.theme.colors.text.navbar};
    transition: transform 0.3s, background-color 0.3s;
  }

  &.${selectedClassName} {
    &::after {
      transform: scaleX(1);
      background-color: ${props => props.theme.colors.text.navbarActive};
    }
  }
`;

export const SecondaryNavbarLink = styled(BasicLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 7px 16px;
  transition: background-color 0.1s ease-in;
  color: ${props => props.theme.colors.text.secondaryNavbar};
  ${Icon} {
    fill: ${props => props.theme.colors.text.secondaryNavbar};
  }
  font: ${props => props.theme.fonts.smallTextBold};
  line-height: 24px;
  border-radius: 100px;
  margin-right: 5px;

  &:hover,
  &.${selectedClassName} {
    background-color: ${props => props.theme.colors.background.secondaryNavbarActiveLink};
    color: ${props => props.theme.colors.text.secondaryNavbarActive};

    ${Icon} {
      fill: ${props => props.theme.colors.text.secondaryNavbarActive};
    }
  }

  &:focus {
    outline: 2px solid ${props => props.theme.colors.focus};
  }
`;
