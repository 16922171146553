import { MobileIndexNavigationButton } from 'Molecules/buttons/MobileIndexNavigationButton';
import React, { FC } from 'react';
import { match } from 'react-router-dom';
import { usePushState } from 'services/usePushState.hook';
import { SectionsDictionary } from 'types/az';

interface Props {
  data: SectionsDictionary | null;
  match: match;
}

export const AZMobileResultsList: FC<Props> = ({ data, match }) => {
  const { push } = usePushState();

  const onClick = (section: string): void => {
    push(`${match.url}/${section}`);
  };

  return (
    <div>
      {data &&
        Object.keys(data).map(key => (
          <MobileIndexNavigationButton
            key={key}
            caption={`${key} (${data[key].length})`}
            onClick={() => onClick(key)}
          />
        ))}
    </div>
  );
};
