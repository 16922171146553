import { Location } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import React, { FC, ReactElement, ReactNode } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, useLocation } from 'react-router-dom';
import { useAccountController } from 'store/accountStore/hooks';

type RenderFunc<P> = (props: RouteComponentProps<P>) => ReactNode;

type Props<P> = Omit<RouteProps, 'render' | 'component' | 'children'> & {
  render?: RenderFunc<P>;
  component?: FC;
};

export interface DeepLinkRedirectState {
  location: Location;
}

export const AuthRoute = <P extends { [K in keyof P]?: string }>({
  render,
  component,
  ...rest
}: Props<P>): ReactElement => {
  const { isAuthenticated, isLoading } = useAccountController();
  const location = useLocation();

  const redirectState: DeepLinkRedirectState = {
    location: location,
  };

  const getRender = (): RenderFunc<P> | undefined => {
    if (isLoading) {
      return () => <Loader />;
    } else if (isAuthenticated) {
      return render;
    } else {
      return () => <Redirect to={{ pathname: '/login', state: redirectState }} />;
    }
  };

  const getComponent = (): FC | undefined => {
    if (isLoading) {
      return Loader;
    } else if (isAuthenticated) {
      return component;
    } else {
      return () => <Redirect to={{ pathname: '/login', state: redirectState }} />;
    }
  };

  return <Route {...rest} render={getRender()} component={getComponent()} />;
};
