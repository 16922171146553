import { getDrugsSynonyms } from 'apiServices/Drugs/drugs';
import { ReactComponent as Clear } from 'assets/UI/Clear.svg';
import { ButtonLabel } from 'Atoms/buttons/ButtonLabel';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { P } from 'Atoms/text';
import { Span } from 'Atoms/text';
import { ConfirmationModal } from 'Molecules/modal/ConfirmationModal';
import { AsyncSelect } from 'Molecules/select/AsyncSelect';
import { SearchSelect } from 'Molecules/select/SearchSelect';
import React, { FC, useState } from 'react';
import { OnChangeValue } from 'react-select';
import { useSelectSynonyms } from 'services/useSelectSynonyms.hook';
import { useCompareList } from 'store/ComparisonListsStore/hooks';
import { useDrugsList } from 'store/drugsStore/hooks';
import styled from 'styled-components/macro';
import { CompareCategory, ProbabilityType } from 'types/compare';
import { SelectOption } from 'types/select';

const CaptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 10px 0 40px 0;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
  }
`;

const StyledP = styled(P)`
  margin: 10px 15px 0 0;

  color: ${props => props.theme.colors.comparisonCount};
`;

const StyledSelect = styled(SearchSelect)`
  margin: 10px 10px 0 0;

  width: 400px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
  }

  .${props => props.classNamePrefix}__control {
    &--is-focused,
    &--menu-is-open {
      .${props => props.classNamePrefix}__placeholder {
        display: unset;
      }
    }
  }

  .${props => props.classNamePrefix}__option {
    &--is-disabled {
      opacity: 0.5;
    }
  }
`;

const SelectStyled = styled(AsyncSelect<SelectOption, false>())`
  max-height: 44px;

  justify-self: center;
  align-self: end;

  margin-top: 20px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .${props => props.classNamePrefix}__control {
      padding: 4px 5px 4px 9px;
    }
    .${props => props.classNamePrefix}__search-icon {
      margin-right: 8px;
    }
  }

  .${props => props.classNamePrefix}__control {
    &--is-focused,
    &--menu-is-open {
      .${props => props.classNamePrefix}__placeholder {
        display: unset;
      }
    }
  }

  .${props => props.classNamePrefix}__option {
    &--is-disabled {
      opacity: 0.5;
    }
  }
`;

const SpanStyled = styled(Span)`
  margin: 0 auto;
`;

const StyledButton = styled(FilledButton)`
  width: 150px;
  border-radius: 5px;
  padding: 4px 15px;
  box-shadow: none;
  height: 44px;

  &:hover {
    ${SpanStyled} {
      color: ${props => props.theme.colors.button.hover.textColor};
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
    margin: 10px 0 0 0;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0 0 0;
`;

interface Props {
  className?: string;
  countText: string;
  options: SelectOption[];
  onChange?: (selected: OnChangeValue<SelectOption, false>) => void;
  isListFull?: boolean;
  isLoading?: boolean;
  onClear?: () => void;
  setProbabilityType?: React.Dispatch<React.SetStateAction<ProbabilityType>>;
  compareCategory: CompareCategory;
}

export const CompareTopCaption: FC<Props> = ({
  className,
  countText,
  options,
  setProbabilityType,
  onChange,
  isListFull,
  isLoading,
  onClear,
  compareCategory,
}) => {
  const drugsList = useDrugsList();
  const { isItemInList } = useCompareList('drugs');

  const loadSynonymOptions = async (inputValue: string): Promise<SelectOption[]> => {
    const synonyms = await getDrugsSynonyms(inputValue);
    return synonyms.map(x => ({
      label: x.name,
      value: x.id.toString(),
      synonymOf: drugsList.find(drug => drug.id === x.id)?.name,
      disabled: isItemInList(x.id),
    }));
  };

  const { loadOptions } = useSelectSynonyms('drugs', options, loadSynonymOptions);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const clearRecordType =
    compareCategory === 'diseases'
      ? 'diseases'
      : compareCategory === 'drugs'
      ? 'drugs'
      : 'organisms';

  return (
    <CaptionWrapper className={className}>
      <LeftContainer>
        <StyledP>
          You have <strong>{countText}</strong> in your comparison table.
        </StyledP>
        {!isListFull &&
          (compareCategory === 'drugs' ? (
            <SelectStyled
              classNamePrefix="compare-drug-select"
              placeholder="Would you like to add more?"
              loadOptions={loadOptions}
              onChange={onChange}
              showIcon
              cacheOptions
              defaultOptions={options}
              showDropdown
              controlShouldRenderValue={false}
              id="compare-drug-select"
              inputId="compare-drug-select-input"
            />
          ) : (
            <StyledSelect
              classNamePrefix="comparison-table-select"
              options={options}
              placeholder="Would you like to add more?"
              onChange={onChange}
              isLoading={isLoading}
              ariaLabel="Add more"
              showIcon
              controlShouldRenderValue={false}
            />
          ))}
      </LeftContainer>
      <RightContainer>
        {onClear && (
          <StyledButton onClick={() => setConfirmationModal(true)}>
            <Icon svgComponent={Clear} fill="inactive" size="small" />
            <ButtonLabel color="inactive">Clear table</ButtonLabel>
          </StyledButton>
        )}
      </RightContainer>
      <ConfirmationModal
        isOpen={confirmationModal}
        description={`Clear all ${clearRecordType} from ${compareCategory} comparison table?`}
        onClose={() => setConfirmationModal(false)}
        onCancel={() => setConfirmationModal(false)}
        onConfirm={() => {
          onClear && onClear();
          setConfirmationModal(false);
          setProbabilityType && setProbabilityType('words');
        }}
        cancelLabel="No"
        confirmLabel="Yes"
      />
    </CaptionWrapper>
  );
};
