import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC, Fragment, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useEventListener } from 'services/useEventListener.hook';
import styled from 'styled-components/macro';
import { Column, Row } from 'types/table';

import { TD } from './TD';
import { TH } from './TH';
import { TR } from './TR';

const Container = styled.div`
  width: 100%;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  max-width: 100%;
`;

const StyledTH = styled(TH)`
  text-align: right;
`;

interface Props {
  className?: string;
  columns: Column[];
  row: Row;
}

const calculateCellWidth = (row: Row): number => {
  if (
    row.some(x => x.value.toString().length >= 8) ||
    row.some(x => x.references && x.references.length > 0)
  ) {
    return 106;
  }

  if (row.some(x => x.value.toString().length >= 7)) {
    return 91;
  }

  if (row.some(x => x.value.toString().length === 6)) {
    return 81;
  }

  return 70;
};

export const GraphTable: FC<Props> = ({ columns, row, className }) => {
  const cellWidth = calculateCellWidth(row);

  const containerRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState<number>();

  const handleResize = (): void => {
    containerRef.current && setWidth(containerRef.current.offsetWidth);
  };

  useEventListener('resize', handleResize);
  useLayoutEffect(handleResize);

  const splitBy = width ? Math.floor(width / cellWidth) : columns.length;

  const chunks = useMemo(() => {
    const tables = [];
    const chunksNeeded = Math.ceil(columns.length / splitBy);
    for (let i = 0; i < chunksNeeded; i++) {
      tables.push({
        columns: columns.slice(i * splitBy, (i + 1) * splitBy),
        row: row.slice(i * splitBy, (i + 1) * splitBy),
      });
    }
    return tables;
  }, [columns, row, splitBy]);

  return (
    <Container className={className} ref={containerRef}>
      <StyledTable>
        {chunks.map((chunk, i) => (
          <Fragment key={i}>
            <thead>
              <TR>
                {chunk.columns.map(({ name }) => (
                  <StyledTH key={name} minWidth={cellWidth}>
                    {name}
                  </StyledTH>
                ))}
              </TR>
            </thead>
            <tbody>
              <TR>
                {chunk.row.map((rowdata, j) => (
                  <TD
                    key={j}
                    wrap={columns[j].enableWrap}
                    minWidth={columns[j].minWidth}
                    textAlign={columns[j].textAlign}
                  >
                    {rowdata.label ? rowdata.label : '-'}
                    {rowdata.references && <DynamicHtml content={rowdata.references} />}
                  </TD>
                ))}
              </TR>
            </tbody>
          </Fragment>
        ))}
      </StyledTable>
    </Container>
  );
};
