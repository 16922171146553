import 'moment/min/locales';

import { Icon } from 'Atoms/Icon';
import { ReactComponent as CalendarSvg } from 'Diagnose/assets/Calendar.svg';
import { PlainSelect } from 'Molecules/select/PlainSelect';
import moment, { Moment } from 'moment';
import React, { FC, MutableRefObject, ReactElement, useLayoutEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { OnChangeValue } from 'react-select';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';
import { generateArray } from 'utils/generateArray';

import { DatePickerProps, DatePickerStyles } from './datePickerStyles';

const locale = window.navigator.language;

const localLocale = moment().locale(locale);
//TODO - replace is bug-prone and should be removed in the future. Dictionary with known locales and formats would be a good solution.
export const DateFormat = localLocale.localeData().longDateFormat('L').replace('MM', 'MMM');

export interface Props {
  className?: string;
  value: Moment | null;
  onChange: (date: Moment | null) => void;
  id: string;
  placeholder?: string;
  isOutsideRange?: (day: Moment) => boolean;
  disabled?: boolean;
  hasError?: boolean;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
}

interface MonthElementProps {
  month: Moment;
  onMonthSelect: (currentMonth: Moment, newMonthVal: string) => void;
  onYearSelect: (currentMonth: Moment, newYearVal: string) => void;
  isVisible: boolean;
}

const Wrapper = styled.div<DatePickerProps>`
  ${DatePickerStyles}
  ${Icon} {
    fill: ${props =>
      props.focused ? props.theme.colors.datepicker.focus : props.theme.colors.datepicker.icon};
  }
`;

const SelectBase = styled(PlainSelect)`
  .${props => props.classNamePrefix}__menu-list {
    height: 200px;
  }
`;

const Selects = styled.div`
  display: flex;
  justify-content: center;
`;

const MonthSelect = styled(SelectBase)`
  margin-right: 5px;
  .${props => props.classNamePrefix}__control {
    width: 110px;
  }
`;

const YearSelect = styled(SelectBase)`
  .${props => props.classNamePrefix}__control {
    width: 75px;
  }
`;

const years: SelectOption[] = (() => {
  const yearsCount = 20;
  const year = new Date().getFullYear();
  return generateArray(yearsCount, 0).map((_, i) => {
    const value = year - i;
    return { value: value.toString(), label: value.toString() };
  });
})();

const months: SelectOption[] = moment
  .months()
  .map((x, i) => ({ value: i.toString(), label: x.toString() }));

const renderMonthElement = ({
  month,
  onMonthSelect,
  onYearSelect,
}: MonthElementProps): ReactElement => {
  const onMonthChange = (selected: OnChangeValue<SelectOption, false>): void => {
    if (selected) {
      onMonthSelect(month, selected.value);
    }
  };

  const onYearChange = (selected: OnChangeValue<SelectOption, false>): void => {
    if (selected) {
      onYearSelect(month, selected.value);
    }
  };

  const selectedMonth = moment(month).format('MMMM');
  const selectedYear = moment(month).format('YYYY');

  return (
    <Selects>
      <MonthSelect
        options={months}
        onChange={onMonthChange}
        placeholder="Month"
        classNamePrefix="date-select"
        value={{ value: selectedMonth, label: selectedMonth }}
        isSearchable={false}
      />
      <YearSelect
        options={years}
        onChange={onYearChange}
        placeholder="Year"
        classNamePrefix="date-select"
        value={{ value: selectedYear, label: selectedYear }}
        isSearchable={false}
      />
    </Selects>
  );
};

export const DatePicker: FC<Props> = ({
  className,
  value,
  onChange,
  id,
  placeholder,
  isOutsideRange = () => false,
  disabled,
  hasError,
  inputRef,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (inputRef) {
      inputRef.current = document.getElementById(id) as HTMLInputElement;
    }
  }, [id, inputRef]);

  const onClick = (): void => {
    document.getElementById(id)?.focus();
  };

  const onPickerChange = (date: Moment | null): void => {
    onChange(date);
  };

  return (
    <Wrapper
      className={className}
      focused={isFocused}
      onClick={onClick}
      disabled={disabled}
      hasError={hasError}
      role="presentation"
    >
      <SingleDatePicker
        date={value}
        onDateChange={onPickerChange}
        focused={isFocused}
        onFocusChange={({ focused }) => setIsFocused(focused)}
        id={id}
        placeholder={placeholder || moment('1900-12-31').format(DateFormat)}
        numberOfMonths={1}
        isOutsideRange={isOutsideRange}
        displayFormat={DateFormat}
        disabled={disabled}
        renderMonthElement={renderMonthElement}
      />
      <Icon size="small" svgComponent={CalendarSvg} />
    </Wrapper>
  );
};
