import { Loading } from 'store/types';

import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'Trending/LoadInitiated': {
      return {
        ...state,
        [action.payload.section]: Loading
      };
    }
    case 'Trending/Loaded': {
      return {
        ...state,
        [action.payload.section]: action.payload.data
      };
    }
    case 'Trending/LoadFailed': {
      return {
        ...state,
        [action.payload.section]: action.payload.error
      };
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
