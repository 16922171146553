import React, {
  Context,
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
} from 'react';
import { useState } from 'react';
import { CustomError, ErrorCodes } from 'types/errorTypes';

// Session storage clean up executed on imitial page load.
if (window.opener) {
  window.sessionStorage.clear();
  // window.opener is removed to protect of resetting the state after new tab is reloaded
  window.opener = null;
}

const eventBelongsToStorage = (event: StorageEvent, storage: Storage): boolean =>
  event.storageArea === storage;

type SetStateFunc<T> = Dispatch<SetStateAction<T>>;

/**
 * Returns a value stored in Storage API, and a function to update it.
 *
 * DISCLAIMER: This hook doesn't synchronize value changes for the same storage key between different instances.
 * @param storage to store values in (window.localStorage or window.sessionStorage)
 * @param key to store the value as
 * @param initialValue is not being stored in localStorage, if not provided, define value type manually.
 */
export function useStorage<T>(
  storage: Storage,
  key: string,
  initialValue?: T | (() => T),
  changeHandler?: (value: T) => void
): [T, SetStateFunc<T>] {
  const unwrappedState = useState<T>(() => {
    try {
      const item = storage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const [state, setState] = unwrappedState;

  useEffect(() => {
    storage.setItem(key, JSON.stringify(state));
  }, [state, key, storage]);

  useEffect(() => {
    const handler = (e: StorageEvent): void => {
      try {
        if (eventBelongsToStorage(e, storage) && e.key === key && e.newValue) {
          const val = JSON.parse(e.newValue);
          setState(val);
          changeHandler && changeHandler(val);
        }
      } catch (error) {
        throw new CustomError(
          `useLocalStorage setValue error for key ${key}`,
          ErrorCodes.LocalStorageProvider,
          'Error',
          error
        );
      }
    };
    window.addEventListener('storage', handler);

    return () => {
      window.removeEventListener('storage', handler);
    };
  }, [setState, key, storage, changeHandler]);

  return unwrappedState;
}

interface ProviderProps {
  children: ReactNode;
}

export type ContextValue<T> = [T, SetStateFunc<T>] | undefined;

function storageFactory<T extends unknown>(
  storage: Storage,
  key: string,
  defaultValue: T
): readonly [Context<ContextValue<T>>, FC<ProviderProps>] {
  const context = createContext<ContextValue<T>>(undefined);

  const Provider: FC<ProviderProps> = ({ children }) => {
    const localStorage = useStorage<T>(storage, key, defaultValue);

    return <context.Provider value={localStorage}>{children}</context.Provider>;
  };

  return [context, Provider] as const;
}

export function localStorageFactory<T extends unknown>(
  key: string,
  defaultValue: T
): readonly [Context<ContextValue<T>>, FC<ProviderProps>] {
  return storageFactory(window.localStorage, key, defaultValue);
}

export function sessionStorageFactory<T extends unknown>(
  key: string,
  defaultValue: T
): readonly [Context<ContextValue<T>>, FC<ProviderProps>] {
  return storageFactory(window.sessionStorage, key, defaultValue);
}
