import { isStoreError } from 'store/storeError';
import { isLoading, Loading } from 'store/types';

import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'Search/Suggestions/LoadInitiated': {
      return {
        ...state,
        suggestions: Loading,
      };
    }
    case 'Search/Suggestions/Loaded':
    case 'Search/Suggestions/LoadFailed': {
      return {
        ...state,
        suggestions: action.payload,
      };
    }
    case 'Search/Results/LoadInitiated': {
      return {
        ...state,
        results: Loading,
      };
    }
    case 'Search/Results/Loaded':
    case 'Search/Results/LoadFailed': {
      return {
        ...state,
        results: action.payload,
      };
    }
    case 'Search/LoadMoreInitiated': {
      return {
        ...state,
        isLoadingAdditionalResults: true,
      };
    }
    case 'Search/Results/LoadedMore': {
      const results = state.results;

      if (isStoreError(results) || isLoading(results) || results === null) {
        return state;
      }

      return {
        ...state,
        results: {
          ...results,
          hits: [...results.hits, ...action.payload.hits],
        },
        isLoadingAdditionalResults: false,
      };
    }
    case 'Search/ModuleResults/LoadedMore': {
      const results = state.moduleResults;

      if (isStoreError(results) || isLoading(results) || results === null) {
        return state;
      }

      return {
        ...state,
        moduleResults: {
          ...results,
          hits: [...results.hits, ...action.payload.hits],
        },
        isLoadingAdditionalResults: false,
      };
    }
    case 'Search/LoadMoreFailed': {
      return {
        ...state,
        isLoadingAdditionalResults: false,
      };
    }
    case 'Search/SearchValue': {
      return {
        ...state,
        currentSearch: {
          ...state.currentSearch,
          searchValue: action.payload,
        },
      };
    }
    case 'Search/ModuleResults/LoadInitiated': {
      return {
        ...state,
        moduleResults: Loading,
      };
    }
    case 'Search/ModuleResults/Loaded':
    case 'Search/ModuleResults/LoadFailed': {
      return {
        ...state,
        moduleResults: action.payload,
      };
    }
    case 'Search/ModuleResults/Reset': {
      return {
        ...state,
        moduleResults: null,
      };
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
