import { SearchSelectControl } from 'Atoms/select/SearchSelectControl';
import { SearchSelectDropdownIndicator } from 'Atoms/select/SearchSelectDropdownIndicator';
import { SearchSelectMenuList } from 'Atoms/select/SearchSelectMenuList';
import { SearchSelectMultiValueLabel } from 'Atoms/select/SearchSelectMultiValueLabel';
import { StyledMultiSelectBase } from 'Atoms/select/SelectStyles';
import React, { FC } from 'react';
import Select, { OnChangeValue } from 'react-select';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';

interface Props {
  className?: string;
  options: SelectOption[];
  placeholder: string;
  isClearable?: boolean;
  onChange?: (values: OnChangeValue<SelectOption, true>) => void;
  value?: SelectOption[];
}

const MultiSelectBase: FC<Props> = ({ ...rest }) => (
  <Select
    classNamePrefix="search-select"
    isMulti
    components={{
      Control: SearchSelectControl,
      MenuList: SearchSelectMenuList,
      DropdownIndicator: SearchSelectDropdownIndicator,
      MultiValueLabel: SearchSelectMultiValueLabel,
    }}
    {...rest}
  />
);

export const MultiSelect = styled(MultiSelectBase)`
  ${StyledMultiSelectBase};
  .${props => props.classNamePrefix}__clear-indicator {
    display: none;
  }
  .${props => props.classNamePrefix}__multi-value {
    /* 95% so that search cursor would stay in the same line */
    width: 95%;
  }
`;
