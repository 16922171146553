import { Checkbox, CheckboxWidth } from 'Atoms/Checkbox';
import { Label, Size } from 'Atoms/Label';
import React, { ChangeEvent, FC, ReactNode } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LabelStyled = styled(Label)`
  margin-left: 15px;
  user-select: none;

  display: flex;
  align-items: center;
`;

interface Props {
  className?: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
  size?: Size;
  checkboxSize?: CheckboxWidth;
  color?: keyof DefaultTheme['colors']['text'];
  id?: string;
}

export const CheckboxWithLabel: FC<Props> = ({
  className,
  checked,
  onChange,
  children,
  size,
  checkboxSize,
  color,
  id = 'default-option',
}) => (
  <CheckboxContainer className={className}>
    <Checkbox id={id} checked={checked} onChange={onChange} width={checkboxSize} />
    <LabelStyled color={color || 'input'} htmlFor={id} size={size}>
      {children}
    </LabelStyled>
  </CheckboxContainer>
);
