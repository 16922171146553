import { changeAccountEmail } from 'apiServices/account/accountSettings';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Loader } from 'Atoms/Loader';
import { P } from 'Atoms/text';
import { Label } from 'Molecules/account/AccountFormInput';
import { AccountModalInput } from 'Molecules/account/AccountModalInput';
import { AccountModal } from 'Molecules/modal/AccountModal';
import React, { FC, FormEvent, useCallback, useState } from 'react';
import { InputError, useInputController } from 'services/useInputController';
import styled from 'styled-components/macro';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormButton = styled(FilledButton)`
  max-width: 140px;
  max-height: 36px;
  min-height: 36px;
`;

const StyledInput = styled(AccountModalInput)`
  margin: 10px 0 20px 0;
`;

const ErrorMessage = styled(P)`
  margin: -10px 0 10px;
`;

const ConfirmationMessageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ConfirmButton = styled(FormButton)`
  margin-top: 20px;
`;

const LabelContainer = styled.div`
  width: 100%;
`;

interface Props {
  modalOpen: boolean;
  onModalClose: () => void;
}

const passwordValidator = (inputValue: string): InputError =>
  inputValue.length > 0 ? null : 'empty';

const emailValidator = (inputValue: string): InputError => (inputValue.length > 0 ? null : 'empty');

const email2Validator = (inputValue: string, emailValue: string | undefined): InputError =>
  inputValue.length === 0 ? 'empty' : !(emailValue === inputValue) ? "emails don't match" : null;

export const EmailChangeModal: FC<Props> = ({ modalOpen, onModalClose }) => {
  const { inputProps: password, error: passwordError } = useInputController(passwordValidator);

  const { inputProps: email, error: emailError } = useInputController(emailValidator);

  const validateEmail2 = useCallback(
    (inputValue: string): InputError => email2Validator(inputValue, email.value),
    [email.value]
  );

  const { inputProps: email2, error: email2Error } = useInputController(validateEmail2);

  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false);

  const formComplete = !passwordError && !emailError && !email2Error;

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (isSubmiting) {
      return;
    }

    setIsSubmiting(true);

    if (formComplete && password.value && email.value) {
      changeAccountEmail(password.value, email.value)
        .then(() => {
          setSubmissionSuccess(true);
          setErrorMessage(null);
          password.clear();
          email.clear();
          email2.clear();
        })
        .catch(err => {
          if (err.response.data.error.message) {
            setErrorMessage(err.response.data.error.message);
          } else {
            setErrorMessage('Email change failed');
          }
        })
        .finally(() => setIsSubmiting(false));
    }
  };

  if (submissionSuccess) {
    return (
      <AccountModal isOpen={modalOpen} onClose={onModalClose} title="Change email address">
        <ConfirmationMessageBox>
          <P size="big" textAlign="center">
            We have sent a confirmation link to your new email address. Click it within the next 10
            minutes to complete the change.
          </P>
          <ConfirmButton type="button" onClick={onModalClose}>
            OK
          </ConfirmButton>
        </ConfirmationMessageBox>
      </AccountModal>
    );
  }

  return (
    <AccountModal isOpen={modalOpen} onClose={onModalClose} title="Change email address">
      <Form onSubmit={onSubmit}>
        <LabelContainer>
          <Label htmlFor="password">Password</Label>
        </LabelContainer>
        <StyledInput
          id="password"
          type="password"
          placeholder="For security, enter your password"
          {...password}
          onChange={e => {
            password.onChange(e);
            setErrorMessage(null);
          }}
          autoComplete="current-password"
        />
        <LabelContainer>
          <Label htmlFor="email">Email</Label>
        </LabelContainer>
        <StyledInput
          id="email"
          type="email"
          placeholder="Type your new email address"
          {...email}
          autoComplete="email"
        />
        <LabelContainer>
          <Label htmlFor="repeat-email">Repeat email</Label>
        </LabelContainer>
        <StyledInput
          id="repeat-email"
          type="email"
          placeholder="Confirm your new email address"
          {...email2}
          autoComplete="email"
        />
        {email2Error && <ErrorMessage color="inactive">{email2Error}</ErrorMessage>}
        {errorMessage && <ErrorMessage color="error">{errorMessage}</ErrorMessage>}
        <FormButton type="submit" disabled={!formComplete || isSubmiting}>
          {isSubmiting ? <Loader /> : formComplete ? 'All set!' : 'Not done yet'}
        </FormButton>
      </Form>
    </AccountModal>
  );
};
