import { ExternalLink } from 'Atoms/links/ExternalLink';
import { contentClassNames, Modal } from 'Atoms/Modal';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { ModalCard } from '../modalCard/ModalCard';

const LicenseContainer = styled.div`
  padding: 0px 0px 20px 0px;
`;

const InstitutionalLicenseAgreement: FC = () => {
  return (
    <LicenseContainer>
      <p>
        The following provisions govern the use of GIDEON services by GIDEON institution subscribers
        (“Subscriber”). In order to access the GIDEON Service (the “GIDEON Service” or the
        “Service”), the subscriber agrees to the following terms and conditions:
      </p>
      <h3>BINDING TERMS AND CHANGES</h3>
      <p>
        GIDEON INFORMATICS, INC (“GIDEON”) is an electronic publisher providing medical information
        and decision support to Subscriber. By becoming a Subscriber, and using the Service,
        Subscriber is indicating agreement to be bound by all of the terms in this Subscriber
        Agreement (the “Agreement”).
      </p>
      <p>
        GIDEON may modify or assign additional terms and conditions as made available to Subscriber
        via the website, from time to time, including without limitation, changes in subscription
        rates, use restrictions or guidelines. Those terms and conditions will apply upon renewal of
        the subscription in question without the need for amendment. Those terms and conditions will
        prevail and control use of the relevant content, unless the Subscriber chooses not to renew
        the subscription. Subscriber hereby grants to GIDEON the right to enforce or assert on their
        own behalf the provisions of this Agreement to the extent they pertain to the content
        contained in the GIDEON service.
      </p>
      <h3>IP ADDRESS REGISTRATION</h3>
      <p>
        Subscriber will provide institution’s valid Internet Protocol (IP) address(es) to GIDEON in
        order to register with the Service and access the Service in accordance with this
        Agreement.&nbsp; For the terms of this Agreement an institution is defined as a contiguous
        campus community. Valid IP addresses may not include publicly accessible proxy or cache
        servers.&nbsp; Subscriber will notify GIDEON within thirty (30) days of any changes in
        registration data or IP addresses.
      </p>
      <h3>USE OF SUBSCRIBER INFORMATION</h3>
      <p>
        By entering into this Agreement, Subscriber is agreeing that GIDEON may store information
        provided as part of the registration process and use aggregate statistical information about
        subscribers in marketing the service, and to protect the security or integrity of the
        Service. No one other than GIDEON, EBSCO Publishing, Inc or their respective employees,
        consultants or advisors will receive Subscriber name, address, phone number, e-mail address,
        or other specific personal identifying information without Subscriber express prior consent,
        unless required by law.
      </p>
      <p>
        Subscriber also agrees and authorizes that GIDEON may use the information provided to
        contact Subscriber via email or online postings in order to: fulfill and to respond to
        Subscriber requests for information or services, and to inform Subscriber about new
        features, functionality, or options related to the Service. You may choose not to receive
        such information and/or to permit GIDEON to use Subscriber information in this manner by
        simply notifying GIDEON at www.GIDEONonline.com, or through other means provided within
        communications Subscriber receives.
      </p>
      <h3>COPYRIGHTS AND LIMITATIONS ON USE</h3>
      <p>
        The information available through the service is the sole and exclusive property of GIDEON
        (or its subsidiaries, affiliates or designees) and is protected by copyright and other
        intellectual property laws.
      </p>
      <p>
        GIDEON grants Subscriber and its authorized employees, students, academic staff, and persons
        physically present at the Subscriber’s site (collectively, “Authorized Users”) a
        non-transferable, non-exclusive right and revocable license to use the Service according to
        the terms and conditions set forth in this Agreement. Except as expressly granted by this
        Agreement, Subscriber acquires no right, title or license in the content or data
        incorporated in the Service.
      </p>
      <p>
        GIDEON information may not be reproduced, retransmitted, stored, distributed, disseminated,
        sold, published, broadcast or circulated in any medium to anyone, including but not limited
        to others in the same company or organization, without the express prior written permission
        of GIDEON, except as otherwise expressly permitted under fair use provisions of U.S.
        Copyright Law. Fair use provisions of U.S. Copyright Law permit reproduction of single
        copies of copyrighted material for personal, non-commercial and private use. In determining
        whether the use made of copyrighted material is “fair use”, one factor to be considered
        includes the amount and substantiality of the portion used in relation to the copyrighted
        material as a whole. In the event of any permitted use of copyrighted material, no
        modifications shall be made without written authorization from GIDEON. Any requests for
        permission may be sent to: GIDEON, 8721 Santa Monica Blvd Suite 234, Los Angeles, CA 90069,
        USA, (fax) +1 413-487-9264, (email){' '}
        <ExternalLink to="mailto:sales@gideononline.com">sales@gideononline.com</ExternalLink>.
      </p>
      <p>
        Subscriber may not use the facilities and/or services of GIDEON to publish or distribute any
        information (including software or other content) which is illegal, which violates or
        infringes upon the rights of any other person, which would be abusive, profane,
        pornographic, or sexually offensive to an average person, or which contains errors. GIDEON
        may at any time exercise editorial control over the content of any information which is
        distributed through its facilities and/or services. Subscriber may not, without the approval
        of GIDEON, use its facilities and/or services to publish or distribute any advertising,
        promotional material, or solicitation to other subscribers to use any goods or services. For
        example, Subscriber may not use the facilities and/or services of GIDEON to conduct any
        business, to solicit the performance of any activity which is prohibited by law, or to
        solicit other subscribers to become subscribers of other information services.
      </p>
      <p>
        Authorized users are not permitted to download excessive portions of GIDEON information. The
        use of robots and all automated downloading programs are prohibited. In the event of any
        unauthorized use of the Licensed Materials by an Authorized User, Subscriber shall cooperate
        with GIDEON in the investigation of any unauthorized use of the Licensed Materials of which
        it is made aware and shall use reasonable efforts to remedy such unauthorized use and
        prevent its recurrence. Subscriber may terminate such Authorized User’s access to the
        Licensed Materials after first providing reasonable notice to GIDEON (in no event less than
        two (2) weeks) and cooperating with GIDEON to avoid recurrence of any unauthorized use.
      </p>
      <h3>FEES AND PAYMENTS</h3>
      <p>
        Subscriber agrees to pay all charges incurred in connection with Subscriber user name and
        password for the GIDEON Services (including any applicable taxes) at the rates in effect
        when the charges were incurred. GIDEON may change the Subscriber Fees and charges then in
        effect, or add new charges, by posting such information on the GIDEON website.
      </p>
      <p>
        Subscriber is responsible for any charges incurred to access the GIDEON Service, such as
        telephone and other equipment charges, and fees charged by an Internet access provider or
        other third party service. Subscriber is responsible for any applicable taxes relating to
        use of the services.
      </p>
      <p>
        By entering into this agreement, Subscriber acknowledges that the Service is for Subscriber
        exclusive use only. Use or sharing of passwords by non-subscribers is prohibited. Failure to
        comply will result in immediate suspension of Subscriber account.
      </p>
      <h3>DISCLAIMER OF WARRANTIES AND LIABILITY</h3>
      <p>
        GIDEON selects only the most outstanding and credible medical material for inclusion in its
        Service. The editors consult only sources they deem reliable and competent. However, there
        may be delays, omissions or inaccuracies in such information. This information is not
        intended to replace professional medical advice and should not be used as the sole basis of
        diagnosis or therapy.
      </p>
      <p>
        The GIDEON Service may include facts, views, opinions and recommendations of individuals and
        organizations deemed of interest to Subscribers. GIDEON does not guarantee the accuracy,
        completeness or timeliness of, or otherwise endorse, these views, opinions or
        recommendations.
      </p>
      <p>
        GIDEON assumes no liability to patients with respect to the actions of physicians, health
        care facilities and other users, and is not responsible for any injury, death or damage
        resulting from the use, misuse or interpretation of information obtained through this
        program.&nbsp; Therapeutic options listed by the program are limited to published studies
        and reviews.&nbsp; Therapy should not be undertaken&nbsp;without a thorough assessment of
        the indications, contraindications and side effects of any prospective drug or
        intervention.&nbsp; Furthermore, the database is largely derived from incidence and
        prevalence statistics whose accuracy will vary widely for individual diseases and
        countries.&nbsp; Changes in endemicity, incidence, and drugs of choice may occur.&nbsp; The
        list of drugs, infectious diseases and even country names will vary with time.&nbsp;
        Although we endeavor to include such new information on a timely basis, a delay cannot be
        avoided.
      </p>
      <p>
        NEITHER GIDEON NOR ANY OTHER PARTY OR ANY MEDICAL SOCIETY MAKES ANY WARRANTY OR
        REPRESENTATION, EXPRESS OR IMPLIED, WITH RESPECT TO THE SERVICE WHICH IS LICENSED “AS
        IS”.&nbsp; GIDEON AND ITS AFFILIATES, AGENTS AND LICENSORS CANNOT AND DO NOT WARRANT THE
        ACCURACY, COMPLETENESS, CURRENTNESS, NONINFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A
        PARTICULAR PURPOSE OF INFORMATION AVAILABLE THROUGH GIDEON. SUBSCRIBER ASSUMES ALL RISK AS
        TO THE QUALITY, FUNCTION AND PERFORMANCE OF THE LICENSED MATERIALS.&nbsp; NEITHER GIDEON NOR
        ANY OF ITS AFFILIATES, AGENTS OR LICENSORS SHALL BE LIABLE TO SUBSCRIBER OR ANYONE ELSE FOR
        ANY LOSS OR INJURY, CAUSED IN WHOLE OR PART BY ITS ACTIONS (INCLUDING BUT NOT LIMITED TO
        NEGLIGENCE OR CONTINGENCIES BEYOND ITS CONTROL) IN PROCURING, COMPILING, INTERPRETING,
        REPORTING OR DELIVERING INFORMATION THROUGH THE SERVICE. IN NO EVENT WILL GIDEON, ITS
        AFFILIATES, AGENTS OR LICENSORS BE LIABLE TO SUBSCRIBER OR ANYONE ELSE FOR ANY DECISION MADE
        OR ACTION TAKEN BY SUBSCRIBER IN RELIANCE ON SUCH INFORMATION. GIDEON AND ITS AFFILIATES,
        AGENTS AND LICENSORS SHALL NOT BE LIABLE TO SUBSCRIBER OR ANYONE ELSE FOR ANY DAMAGES
        (INCLUDING, WITHOUT LIMITATION, CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT, OR SIMILAR
        DAMAGES) EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SUBSCRIBER AGREES THAT THE
        LIABILITY OF GIDEON, ITS AFFILIATES, AGENTS AND LICENSORS, IF ANY, ARISING OUT OF ANY KIND
        OF LEGAL CLAIM (WHETHER IN CONTRACT, TORT OR OTHERWISE) IN ANY WAY CONNECTED WITH THE
        INFORMATION PROVIDED BY GIDEON SHALL NOT EXCEED THE AMOUNT SUBSCRIBER PAID TO GIDEON FOR THE
        INFORMATION.
      </p>
      <p>
        GIDEON neither endorses nor takes responsibility for any products, goods or services offered
        by outside vendors through our services or advertised on the service.
      </p>
      <h3>SYSTEMS RELIABILITY AND FORCE MAJEURE</h3>
      <p>
        GIDEON will make every reasonable effort to keep the Service available 24 hours a day, seven
        (7) days a week and to maintain saved information. However, there may be times when
        availability may be limited and/or information may be lost. GIDEON shall not be liable for
        non-availability of the Service or lost information.
      </p>
      <p>
        Except as to payment obligations, neither party shall be liable or considered in default
        under this Agreement when the delay of performance is caused by circumstances beyond its
        reasonable control, including failure of suppliers, licensors, subcontractors, and carriers,
        acts of civil or military authorities, national emergencies, fire, flood, acts of God,
        insurrection, and war, provided the party involving this section immediately provides notice
        thereof to the other and does everything reasonably possible to resume its performance
        thereunder.
      </p>
      <h3>TERMINATION AND GENERAL PROVISIONS</h3>
      <p>
        Subscriber subscription will continue until terminated by GIDEON or until Subscriber
        notifies GIDEON by telephone, electronic mail, or via means provided within the Service of
        Subscriber decision to terminate subscription.
      </p>
      <h3>LINKS</h3>
      <p>
        The website may contain links to other websites or resources. Subscriber acknowledges and
        agrees that GIDEON is not responsible or liable for (i) the availability or accuracy of such
        websites or resources; or (ii) the content, advertising, or products on or available from
        such websites or resources. The inclusion of any link on the website does not imply that
        GIDEON endorses the linked site.
        <br />
        Subscribers who use foul language, communicate inappropriately, download material for any
        use other than Subscriber non-commercial use, fail to pay or attempt to subvert the GIDEON
        system and/or services shall have their accounts terminated without compensation.
      </p>
      <p>
        Neither failure nor delay on the part of any party to exercise any right, remedy, power or
        privilege hereunder nor course of dealing between the parties shall operate as a waiver
        thereof, or of the exercise of any other right, remedy, power or privilege. No term of this
        Agreement shall be deemed waived, and no breach consented to, unless such waiver or consent
        shall be in writing and signed by the party claimed to have waived or consented. No waiver
        of any rights or consent to any breaches shall constitute a waiver of any other rights or
        consent to any other breach.
      </p>
      <h3>INDEMNIFICATION</h3>
      <p>
        Subscriber agrees to defend, indemnify, and hold harmless GIDEON, its officers, directors,
        employees, consultants, agents, co-branders or other partners, from and against any claims,
        actions, liability, damages and/or demands, including without limitation reasonable legal
        and accounting fees, made by any third party due to or resulting from Subscriber use of the
        Service; Subscriber connection to the Service; Subscribers violation of the rights of
        others, this Agreement or any intellectual property or other right of any person or entity;
        and any intellectual property infringement by any other user of Subscriber account. This
        Agreement will inure to the benefit of GIDEON’s successors, assigns and licensors.
        <br />
        If any provision in this Agreement is held invalid or unenforceable under applicable law, it
        shall be deemed omitted and the remaining provisions shall continue in full force and
        effect. This Agreement, Subscriber rights and obligations, and all actions contemplated by
        this Agreement shall be governed by and construed in accordance with the laws of the United
        States of America and the State of California, as if the Agreement was a contract wholly
        entered into and wholly performed within California, without regard to the principles of
        conflict of laws. This Agreement will not be governed by the United Nations Convention on
        Contracts for the International Sale of Goods.
      </p>
      <p>Last Updated: January 10th, 2006</p>
      <ExternalLink to="/institutional_subscriber_license.pdf" download openInNewTab>
        Download this document
      </ExternalLink>
    </LicenseContainer>
  );
};

const IndividualLicenseAgreement: FC = () => {
  return (
    <LicenseContainer>
      <p>
        The following provisions govern the use of GIDEON services by GIDEON subscribers. In order
        to access the GIDEON Service (the “GIDEON Service” or the “Service”), the subscriber must
        agree to the following terms and conditions:
      </p>
      <h3>BINDING TERMS AND CHANGES</h3>
      <p>
        GIDEON INFORMATICS, INC (“GIDEON”) is an electronic publisher providing medical information
        and decision support to our subscribers. By becoming a subscriber, completing the online
        registration process and clicking the “Accept” button, and using our service, you are
        indicating that you agree to be bound by all of the terms in this Subscriber Agreement (the
        “Agreement”).
      </p>
      <p>
        GIDEON may change the terms in this Agreement at any time with or without notice.&nbsp; You
        are responsible to continue to review this agreement periodically for any changes or
        updates.&nbsp; Your continued use of the Service after the inclusion of any changes shall be
        construed as your acceptance of the updated agreement terms. &nbsp;If you do not agree to
        the changes, you may cancel your subscription to the GIDEON service.
      </p>
      <p>
        GIDEON may change the fees charged for the Service. When fees are changed, GIDEON will
        notify you by e-mail or online postings. The fee options available to you will be presented
        within the Service. In order to continue your access to the Service you will need to select
        one of the available fee options. If you do not select one of these options, your
        subscription to the GIDEON Service will be terminated at the end of the subscription term
        then in effect.
      </p>
      <h3>USE OF SUBSCRIBER INFORMATION</h3>
      <p>
        By entering into this Agreement, you also are agreeing that GIDEON may store the information
        you provided as part of the registration process and use aggregate statistical information
        about our subscribers in marketing our services, and to protect the security or integrity of
        the GIDEON Service. No one other than GIDEON or its employees, consultants or advisors will
        receive your name, address, phone number, e-mail address, or other specific personal
        identifying information without your express prior consent, unless required by law.
      </p>
      <p>
        You also agree and authorize that GIDEON may use the information you provide to contact you
        via email or online postings in order to: fulfill and to respond to your requests for
        information or services, and to inform you about new features, functionality, or options
        related to the GIDEON Service. You may choose not to receive such information and/or to
        permit us to use your information in this manner by simply notifying us at
        www.GIDEONonline.com, or through other means provided within communications you receive.
      </p>
      <h3>COPYRIGHTS AND LIMITATIONS ON USE</h3>
      <p>
        The information available through our services is the sole and exclusive property of GIDEON
        (or its subsidiaries, affiliates or designees) and is protected by copyright and other
        intellectual property laws.
      </p>
      <p>
        GIDEON grants you a non-transferable, non-exclusive right and revocable license to use the
        GIDEON Service according to the terms and conditions set forth in this Agreement. Except as
        expressly granted by this Agreement, you acquire no right, title or license in the content
        or data incorporated in the GIDEON Service.
      </p>
      <p>
        GIDEON information may not be reproduced, retransmitted, stored, distributed, disseminated,
        sold, published, broadcast or circulated in any medium to anyone, including but not limited
        to others in the same company or organization, without the express prior written permission
        of GIDEON, except as otherwise expressly permitted under fair use provisions of U.S.
        Copyright Law. Fair use provisions of U.S. Copyright Law permit reproduction of single
        copies of copyrighted material for your personal, non-commercial and private use. In
        determining whether the use made of copyrighted material is “fair use”, one factor to be
        considered includes the amount and substantiality of the portion used in relation to the
        copyrighted material as a whole. In the event of any permitted use of copyrighted material,
        no modifications shall be made without written authorization from GIDEON. Any requests for
        permission may be sent to: GIDEON, 8721 Santa Monica Blvd Suite 234, Los Angeles, CA 90069,
        USA, (fax) +1 413-487-9264, (email){' '}
        <ExternalLink to="mailto:sales@gideononline.com">sales@gideononline.com</ExternalLink>.
      </p>
      <p>
        You may not use the facilities and/or services of GIDEON to publish or distribute any
        information (including software or other content) which is illegal, which violates or
        infringes upon the rights of any other person, which would be abusive, profane,
        pornographic, or sexually offensive to an average person, or which contains errors. GIDEON
        may at any time exercise editorial control over the content of any information which is
        distributed through its facilities and/or services. You may not, without the approval of
        GIDEON, use its facilities and/or services to publish or distribute any advertising,
        promotional material, or solicitation to other subscribers to use any goods or services. For
        example, you may not use the facilities and/or services of GIDEON to conduct any business,
        to solicit the performance of any activity which is prohibited by law, or to solicit other
        subscribers to become subscribers of other information services. Similarly, you may not use
        the facilities and/or services of GIDEON to download and redistribute public information or
        shareware for personal gain or use the facilities and/or services to distribute multiple
        copies of public domain information or shareware.
      </p>
      <p>
        Subscribers are not permitted to download excessive portions of GIDEON information. The use
        of robots and all automated downloading programs are prohibited. GIDEON shall take all
        reasonable steps to cease such excessive and unauthorized activity and to prevent any
        recurrence thereof, including terminating your access to the GIDEON Service.
      </p>
      <h3>FEES AND PAYMENTS</h3>
      <p>
        You agree to pay all charges incurred in connection with your user name and password for the
        GIDEON Services (including any applicable taxes) at the rates in effect when the charges
        were incurred. GIDEON may change the Subscriber Fees and charges then in effect, or add new
        charges, by posting such information on the GIDEON website.
      </p>
      <p>
        All charges incurred in connection with your user name and password will be billed to the
        credit card you designate. GIDEON SHALL NOT BE LIABLE FOR ANY AMOUNTS BILLED TO YOUR CREDIT
        CARD BY A THIRD PARTY, WHICH WERE NOT AUTHORIZED BY YOU.
      </p>
      <p>
        You also are responsible for any charges incurred to access the GIDEON Service, such as
        telephone and other equipment charges, and fees charged by an Internet access provider or
        other third party service. You are also responsible for any applicable taxes relating to
        your use of the services.
      </p>
      <p>
        By entering into this agreement, you acknowledge that the GIDEON Service is for your
        exclusive use only. Use or sharing of passwords by non-subscribers is prohibited. Failure to
        comply will result in immediate suspension of your account.
      </p>
      <h3>DISCLAIMER OF WARRANTIES AND LIABILITY</h3>
      <p>
        GIDEON selects what we believe to be the most outstanding and credible medical material for
        inclusion in its Service. The editors consult only sources they deem reliable and competent.
        However, there may be delays, omissions or inaccuracies in such information. This
        information is not intended to replace professional medical advice and should not be used as
        the sole basis of diagnosis or therapy.
      </p>
      <p>
        The GIDEON Service may include facts, views, opinions and recommendations of individuals and
        organizations which we believe may be of interest to our subscribers. GIDEON does not
        guarantee the accuracy, completeness or timeliness of, or otherwise endorse, these views,
        opinions or recommendations.
      </p>
      <p>
        GIDEON assumes no liability to patients with respect to the actions of physicians, health
        care facilities and other users, and is not responsible for any injury, death or damage
        resulting from the use, misuse or interpretation of information obtained through this
        program.&nbsp; Therapeutic options listed by the program are limited to published studies
        and reviews.&nbsp; Therapy should not be undertaken&nbsp; without a thorough assessment of
        the indications, contraindications and side effects of any prospective drug or
        intervention.&nbsp; Furthermore, the database is largely derived from incidence and
        prevalence statistics whose accuracy will vary widely for individual diseases and
        countries.&nbsp; Changes in endemicity, incidence, and drugs of choice may occur.&nbsp; The
        list of drugs, infectious diseases and even country names will vary with time.&nbsp;
        Although we endeavor to include such new information on a timely basis, a delay cannot be
        avoided.
      </p>
      <p>
        NEITHER GIDEON NOR ANY OTHER PARTY OR ANY MEDICAL SOCIETY MAKES ANY WARRANTY OR
        REPRESENTATION, EXPRESS OR IMPLIED, WITH RESPECT TO THE SERVICE WHICH IS LICENSED “AS
        IS”.&nbsp; GIDEON AND ITS AFFILIATES, AGENTS AND LICENSORS CANNOT AND DO NOT WARRANT THE
        ACCURACY, COMPLETENESS, CURRENTNESS, NONINFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A
        PARTICULAR PURPOSE OF INFORMATION AVAILABLE THROUGH GIDEON. YOU ASSUME ALL RISK AS TO THE
        QUALITY, FUNCTION AND PERFORMANCE OF THE LICENSED MATERIALS.&nbsp; NEITHER GIDEON NOR ANY OF
        ITS AFFILIATES, AGENTS OR LICENSORS SHALL BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS OR
        INJURY, CAUSED IN WHOLE OR PART BY ITS ACTIONS (INCLUDING BUT NOT LIMITED TO NEGLIGENCE OR
        CONTINGENCIES BEYOND ITS CONTROL) IN PROCURING, COMPILING, INTERPRETING, REPORTING OR
        DELIVERING INFORMATION THROUGH GIDEON. IN NO EVENT WILL GIDEON, ITS AFFILIATES, AGENTS OR
        LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN
        RELIANCE ON SUCH INFORMATION. GIDEON AND ITS AFFILIATES, AGENTS AND LICENSORS SHALL NOT BE
        LIABLE TO YOU OR ANYONE ELSE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, CONSEQUENTIAL,
        SPECIAL, INCIDENTAL, INDIRECT, OR SIMILAR DAMAGES) EVEN IF ADVISED OF THE POSSIBILITY OF
        SUCH DAMAGES. YOU AGREE THAT THE LIABILITY OF GIDEON, ITS AFFILIATES, AGENTS AND LICENSORS,
        IF ANY, ARISING OUT OF ANY KIND OF LEGAL CLAIM (WHETHER IN CONTRACT, TORT OR OTHERWISE) IN
        ANY WAY CONNECTED WITH THE INFORMATION PROVIDED BY GIDEON SHALL NOT EXCEED THE AMOUNT YOU
        PAID TO GIDEON FOR THE INFORMATION.
      </p>
      <p>
        Some states do not allow limitations on how long an implied warranty lasts and some states
        do not allow the exclusion or limitation of incidental or consequential damages, so the
        above limitation and exclusion may not apply to you. This warranty gives you specific legal
        rights, and you may also have other rights which vary from state to state.
        <br />
        GIDEON neither endorses nor takes responsibility for any products, goods or services offered
        by outside vendors through our services or advertised on our system.
      </p>
      <h3>SYSTEMS RELIABILITY AND FORCE MAJEURE</h3>
      <p>
        GIDEON will make every reasonable effort to keep the Service available 24 hours a day, seven
        (7) days a week and to maintain saved information. However, there may be times when
        availability may be limited and/or information may be lost. GIDEON shall not be liable for
        non-availability of the Service or lost information.
      </p>
      <p>
        Except as to payment obligations, neither party shall be liable or considered in default
        under this Agreement when the delay of performance is caused by circumstances beyond its
        reasonable control, including failure of suppliers, licensors, subcontractors, and carriers,
        acts of civil or military authorities, national emergencies, fire, flood, acts of God,
        insurrection, and war, provided the party involving this section immediately provides notice
        thereof to the other and does everything reasonably possible to resume its performance
        thereunder.
      </p>
      <h3>TERMINATION AND GENERAL PROVISIONS</h3>
      <p>
        Your subscription will continue until terminated by GIDEON or until you notify GIDEON by
        telephone, electronic mail, or via means provided within the Service of your decision to
        terminate your subscription. GIDEON may discontinue or change the Service, or its
        availability to you, at any time, and you may always terminate your subscription at any
        time. This Agreement is personal to you, and you may not assign your rights or obligations
        to anyone.
      </p>
      <p>
        If you elect to cancel your subscription, the cancellation will be effective on your next
        monthly subscription anniversary date. The monthly subscription anniversary date is the
        day-of-the-month on which you subscribed to the Service. Annual subscribers will receive a
        pro-rata refund for any unused portion of the subscription, (from the effective date of
        cancellation to the normal end date of the subscription.) This pro-rata amount will be
        credited to the credit card to which your subscription is charged.
      </p>
      <h3>LINKS</h3>
      <p>
        The website may contain links to other websites or resources. You acknowledge and agree that
        GIDEON is not responsible or liable for (i) the availability or accuracy of such websites or
        resources; or (ii) the content, advertising, or products on or available from such websites
        or resources. The inclusion of any link on the website does not imply that we endorse the
        linked site.
        <br />
        Subscribers who use foul language, communicate inappropriately, download material for any
        use other than your own non-commercial use, fail to pay or attempt to subvert the GIDEON
        system and/or services shall have their accounts terminated without compensation.
      </p>
      <p>
        Neither failure nor delay on the part of any party to exercise any right, remedy, power or
        privilege hereunder nor course of dealing between the parties shall operate as a waiver
        thereof, or of the exercise of any other right, remedy, power or privilege. No term of this
        Agreement shall be deemed waived, and no breach consented to, unless such waiver or consent
        shall be in writing and signed by the party claimed to have waived or consented. No waiver
        of any rights or consent to any breaches shall constitute a waiver of any other rights or
        consent to any other breach.
      </p>
      <h3>INDEMNIFICATION</h3>
      <p>
        You agree to defend, indemnify, and hold harmless GIDEON, its officers, directors,
        employees, consultants, agents, co-branders or other partners, from and against any claims,
        actions, liability, damages and/or demands, including without limitation reasonable legal
        and accounting fees, made by any third party due to or resulting from your use of the
        Service; your connection to the Service; your violation of the rights of others, this
        Agreement or any intellectual property or other right of any person or entity; and any
        intellectual property infringement by any other user of your account. This Agreement will
        inure to the benefit of GIDEON’s successors, assigns and licensors.
        <br />
        If any provision in this Agreement is held invalid or unenforceable under applicable law, it
        shall be deemed omitted and the remaining provisions shall continue in full force and
        effect. This Agreement, your rights and obligations, and all actions contemplated by this
        Agreement shall be governed by and construed in accordance with the laws of the United
        States of America and the State of California, as if the Agreement was a contract wholly
        entered into and wholly performed within California, without regard to the principles of
        conflict of laws. This Agreement will not be governed by the United Nations Convention on
        Contracts for the International Sale of Goods.
      </p>
      <p>
        I HAVE READ AND UNDERSTAND THIS SUBSCRIBER AGREEMENT AND AGREE TO BE BOUND BY ALL OF THE
        TERMS.
      </p>
      <p>Last Updated: October 21st, 2004</p>
      <ExternalLink to="/individual_license_agreement.pdf" download openInNewTab>
        Download this document
      </ExternalLink>
    </LicenseContainer>
  );
};

const ModalStyled = styled(Modal)`
  .${contentClassNames.base} {
    max-height: 80%;
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      max-height: 100%;
    }
  }
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isInstitution: boolean;
}

export const LicenseAgreementModal: FC<Props> = ({ isOpen, isInstitution, onClose }) => {
  return (
    <ModalStyled isOpen={isOpen} onClose={onClose} showCloseButton>
      <ModalCard
        title={isInstitution ? 'Institutional Subscriber License' : 'Individual License Agreement'}
      >
        <div>
          {isInstitution ? <InstitutionalLicenseAgreement /> : <IndividualLicenseAgreement />}
        </div>
      </ModalCard>
    </ModalStyled>
  );
};
