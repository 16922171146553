import { ReactComponent as CloseIcon } from 'assets/Overlay/CloseX.svg';
import { Icon } from 'Atoms/Icon';
import { ToTopButton } from 'Molecules/buttons/ScrollToTopButton';
import React, { FC } from 'react';
import { useTooltip } from 'services/useTooltip';
import styled from 'styled-components/macro';

const ButtonContainer = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;

  top: 25px;
  right: 10px;
`;

const ToTopButtonStyled = styled(ToTopButton)`
  position: fixed;
`;

interface Props {
  className?: string;
  onClick: () => void;
}

export const ReferenceCloseButton: FC<Props> = ({ className, onClick }) => {
  const [props, popper, setElement] = useTooltip('Close', { position: 'left' });

  return (
    <ButtonContainer className={className} {...props.events}>
      <ToTopButtonStyled onClick={onClick} ref={setElement} {...props.aria}>
        <Icon svgComponent={CloseIcon} size="smallMedium" />
      </ToTopButtonStyled>
      {popper}
    </ButtonContainer>
  );
};
