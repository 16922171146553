import { ReactComponent as ReferencesDarkSVG } from 'assets/UI/ReferencesDark.svg';
import { ReactComponent as ReferencesLightSVG } from 'assets/UI/ReferencesLight.svg';
import { ReactComponent as ScientistSVG } from 'assets/UI/Scientist.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { Link } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { H2, P } from 'Atoms/text';
import { Span } from 'Atoms/text/Span';
import { MainLayout } from 'layouts/MainLayout';
import { ModalCardHeader } from 'Molecules/modalCard/ModalCardHeader';
import { useHandledReferenceData } from 'Organisms/ReferenceCard';
import React, { FC, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { ThemeModeContext } from 'services/theme';
import { useAccountController } from 'store/accountStore/hooks';
import { usePublicReferenceData } from 'store/referencesStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { ReferenceDetails } from 'types/reference';

const MainLayoutStyled = styled(MainLayout)`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  height: 100%;
  width: 70%;

  padding: 40px 0 0 0;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
    padding: 20px;
  }
`;

const ReferenceHeader = styled(ModalCardHeader)`
  box-shadow: unset;
  padding: unset;
  background: unset;

  ${H2} {
    color: ${props => props.theme.colors.text.darkLink};
  }
`;

const ReferenceContainer = styled.div`
  margin-top: 60px;
  background-color: ${props => props.theme.colors.background.modalCardBody};
  border-radius: 10px;
  padding: 20px;
`;

const Separator = styled.span`
  display: block;
  width: 100%;
  height: 1px;
  background: ${props => props.theme.colors.text.inactive};

  margin-top: 15px;
`;

const BodyContainer = styled.div`
  font: ${props => props.theme.fonts.normalText};
  color: ${props => props.theme.colors.text.main};

  margin-top: 15px;
  ${P} {
    line-height: 24px;

    @media (max-width: ${props => props.theme.breakpoints.m}) {
      padding: 16px;
    }
  }
`;

const SpanStyled = styled(Span)`
  padding: 20px;
`;

const NotificationContainer = styled.div`
  background: ${props => props.theme.colors.referencesPage.message.background};
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.referencesPage.message.border};

  display: flex;
  flex-direction: column;
`;

const ScientistIconStyled = styled(Icon)`
  height: 60px;
  width: 60px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 20px;
`;

const TopContainer = styled.div`
  display: flex;
`;

const BottomText = styled(Span)`
  margin-top: 15px;
`;

const BottomContainer = styled.div`
  display: flex;
  margin-top: 20px;

  justify-content: center;
`;

const FilledButtonStyled = styled(FilledButton)`
  width: 200px;
`;

const ReferencesIconStyled = styled(Icon)`
  position: absolute;

  height: 30px;
  width: 30px;
  left: -50px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`;

interface Params {
  type: string;
  id: string;
}

interface ReferenceContentProps {
  reference: ReferenceDetails;
}

export const ReferenceContent: FC<ReferenceContentProps> = ({ reference }) => {
  const { title, subTitle, content } = useHandledReferenceData(reference);
  const { isDarkMode } = useContext(ThemeModeContext);

  const { isAuthenticated } = useAccountController();

  return (
    <MainLayoutStyled>
      <ContentContainer>
        <SpanStyled size="big" color="inactive">
          Reference
        </SpanStyled>
        <ReferenceContainer>
          <ReferencesIconStyled
            svgComponent={isDarkMode ? ReferencesDarkSVG : ReferencesLightSVG}
          />
          <ReferenceHeader title={title} subTitle={subTitle} />
          <Separator />
          <BodyContainer>{content}</BodyContainer>
        </ReferenceContainer>
        {!isAuthenticated && (
          <NotificationContainer>
            <TopContainer>
              <ScientistIconStyled svgComponent={ScientistSVG} />
              <TextContainer>
                <Span size="medium">
                  <Span size="medium" weight="700">
                    Hi there!
                  </Span>{' '}
                  Enjoying this ebook?
                </Span>
                <BottomText size="medium">
                  Did you know? Over the past 3 years, GIDEON data was used in 200+ diverse
                  scientific publications.
                </BottomText>
                <BottomText size="medium">
                  Click the button below to get a 24h free access to the database.
                </BottomText>
              </TextContainer>
            </TopContainer>
            <BottomContainer>
              <FilledButtonStyled as={Link} to="/signup" noUnderline>
                TRY IT FREE
              </FilledButtonStyled>
            </BottomContainer>
          </NotificationContainer>
        )}
      </ContentContainer>
    </MainLayoutStyled>
  );
};

export const References: FC<RouteComponentProps<Params>> = ({ match }) => {
  const reference = usePublicReferenceData(match.params.id, match.params.type);

  assertIsNotStoreError(reference);

  return (
    <>
      <Helmet>
        <title>Reference - GIDEON</title>
      </Helmet>
      {isLoading(reference) || !reference ? <Loader /> : <ReferenceContent reference={reference} />}
    </>
  );
};
