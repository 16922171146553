import { css } from 'styled-components/macro';
import { ScrollbarMixin } from 'utils/styleMixins';

interface Props {
  classNamePrefix: string;
  showDropdown?: boolean;
  showIcon?: boolean;
}

export const StyledSelectBase = css<Props>`
  font: ${props => props.theme.fonts.normalText};
  width: 100%;

  .${props => props.classNamePrefix}__control {
    background: ${props => props.theme.colors.select.default.background};
    border: solid ${props => props.theme.colors.select.default.border};
    border-width: 2px;
    border-radius: 10px;
    padding: 4px 12px 4px 26px;
    box-shadow: none;
    transition: none;
    height: 100%;
    min-height: 44px;

    &:hover,
    &--has-value {
      border-color: ${props => props.theme.colors.select.active.border};

      .${props => props.classNamePrefix}__dropdown-icon {
        fill: ${props => props.theme.colors.select.active.border};
      }

      .${props => props.classNamePrefix}__search-icon {
        fill: ${props => props.theme.colors.select.active.border};
      }
    }

    &--is-focused {
      border: 2px solid ${props => props.theme.colors.focus};
    }

    &--menu-is-open {
      border-width: 2px 2px 0px 2px;
      border-radius: 10px 10px 0 0;
      padding: 4px 12px 6px 26px;

      .${props => props.classNamePrefix}__dropdown-indicator {
        transform: rotate(-180deg);
      }

      border-color: ${props => props.theme.colors.select.active.border};

      .${props => props.classNamePrefix}__dropdown-icon {
        fill: ${props => props.theme.colors.select.active.border};
      }

      .${props => props.classNamePrefix}__search-icon {
        fill: ${props => props.theme.colors.select.active.border};
      }
    }

    &--is-focused,
    &--menu-is-open {
      .${props => props.classNamePrefix}__placeholder {
        display: none;
      }
    }
  }

  .${props => props.classNamePrefix}__dropdown-indicator {
    transition: transform 200ms ease;
    display: ${props => !props.showDropdown && 'none'};
    padding: 0;

    height: 25px;
    width: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .${props => props.classNamePrefix}__dropdown-icon {
    fill: ${props => props.theme.colors.select.default.border};
    width: 10px;
    height: 6px;
  }

  .${props => props.classNamePrefix}__placeholder {
    color: ${props => props.theme.colors.select.default.placeholder};
  }

  .${props => props.classNamePrefix}__value-container {
    padding: 2px 0 2px 4px;
    height: 100%;
  }

  .${props => props.classNamePrefix}__input-container {
    color: ${props => props.theme.colors.select.active.text};
    min-height: 20px;
    font: ${props => props.theme.fonts.normalTextBold};
    caret-color: ${props => props.theme.colors.select.active.text};
  }

  .${props => props.classNamePrefix}__single-value {
    color: ${props => props.theme.colors.select.active.text};
    font: ${props => props.theme.fonts.normalTextBold};
  }

  .${props => props.classNamePrefix}__menu {
    margin-top: 0px;
    margin-bottom: 0px;

    background-color: ${props => props.theme.colors.select.default.menuBackground};
    border: solid ${props => props.theme.colors.select.active.border};
    border-width: 0 2px 2px 2px;
    border-radius: 0 0 10px 10px;
    box-shadow: none;
    overflow: hidden;

    z-index: 100;
  }

  .${props => props.classNamePrefix}__menu-list {
    padding: 0;
    overflow: auto;
  }

  .${props => props.classNamePrefix}__option {
    overflow: hidden;
    color: ${props => props.theme.colors.select.default.text};

    &--is-focused,
    &--is-selected {
      background: ${props => props.theme.colors.select.hover.option};
    }

    &--is-selected {
      font: ${props => props.theme.fonts.normalTextBold};
    }
  }

  .${props => props.classNamePrefix}__option:hover {
    background: ${props => props.theme.colors.select.hover.option};
  }

  .${props => props.classNamePrefix}__indicator-separator {
    display: none;
  }

  .${props => props.classNamePrefix}__search-icon {
    display: ${props => !props.showIcon && 'none'};
    fill: ${props => props.theme.colors.select.default.text};
  }

  .${props => props.classNamePrefix}__menu-list {
    ${ScrollbarMixin}
  }
`;

export const StyledMultiSelectBase = css<Props>`
  ${StyledSelectBase};

  .${props => props.classNamePrefix}__control--has-value {
    padding-left: 10px;
    .${props => props.classNamePrefix}__search-icon {
      margin-right: 4px;
    }
  }

  .${props => props.classNamePrefix}__multi-value__label {
    background: ${props => props.theme.colors.select.default.multiOption?.background};
    color: ${props => props.theme.colors.select.default.multiOption?.text};
  }

  .${props => props.classNamePrefix}__multi-value__remove {
    background: ${props => props.theme.colors.select.default.multiOption?.closeButtonBackground};
    & > svg {
      fill: ${props => props.theme.colors.select.default.multiOption?.closeButton};
    }
  }

  .${props => props.classNamePrefix}__multi-value__label {
    width: 100%;
  }
`;
