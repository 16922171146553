import { getAllFingerprints } from 'apiServices/Diseases/fingerprint';
import React, { useEffect } from 'react';
import { newStoreError } from 'store/storeError';
import { Dispatch, Loading, Resource } from 'store/types';
import { Fingerprints } from 'types/fingerprint';

import {
  Action,
  FingerprintPropertiesDispatchContext,
  FingerprintPropertiesStateContext,
  State,
} from './provider';

export const useState = (): State => {
  const state = React.useContext(FingerprintPropertiesStateContext);
  if (state === undefined) {
    throw new Error('fingerprint state is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(FingerprintPropertiesDispatchContext);
  if (dispatch === undefined) {
    throw new Error('fingerprint state is not initialized');
  }
  return dispatch;
};

export const useFingerprintResource = (): Resource<Fingerprints> => {
  const state = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!state) {
      dispatch({ type: 'Fingerprint/LoadInitiated' });
      getAllFingerprints()
        .then(data => dispatch({ type: 'Fingerprint/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Fingerprint/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    }
  }, [dispatch, state]);

  return state || Loading;
};
