import { ChangeEvent, useState } from 'react';

export type InputError = string | null;

type InputProps = {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  inputCorrect: boolean;
  clear: () => void;
};

interface ReturnType {
  inputProps: InputProps;
  error: InputError;
}

export const useInputController = (validator?: (inputValue: string) => InputError): ReturnType => {
  const [inputValue, setInputValue] = useState<string>('');
  const error = validator && inputValue ? validator(inputValue) : null;

  return {
    inputProps: {
      value: inputValue,
      onChange: e => setInputValue(e.target.value),
      inputCorrect: !!inputValue && !error,
      clear: () => setInputValue(''),
    },
    error,
  };
};
