import { ReactComponent as Check } from 'assets/UI/Check.svg';
import { Icon } from 'Atoms/Icon';
import React, { ChangeEvent, FC } from 'react';
import styled from 'styled-components/macro';

const HiddenCheckbox = styled.input`
  opacity: 0;
  position: absolute;
  padding: 0;

  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
`;

const StyledCheckbox = styled.div`
  background-color: transparent;
  border: 1px solid;
  border-color: ${props => props.theme.colors.checkbox.defaultBorder};

  width: 100%;
  height: 100%;

  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 200ms ease;

  ${HiddenCheckbox}:focus + & {
    outline: 2px solid ${props => props.theme.colors.focus};
  }
`;

const CheckboxContainer = styled.div<Props>`
  display: inline-block;
  position: relative;

  width: ${props => props.width || '40px'};
  height: ${props => props.width || '40px'};

  min-width: ${props => props.width || '40px'};
  min-height: ${props => props.width || '40px'};

  &:hover {
    ${StyledCheckbox} {
      border-color: ${props => props.theme.colors.checkbox.hoverBorder};
    }
  }
`;

const IconStyled = styled(Icon)`
  width: 80%;
  height: 80%;
  fill: ${props => props.theme.colors.text.lightLink};
`;

export type CheckboxWidth = '20px' | '30px' | '40px';

interface Props {
  className?: string;
  id?: string;
  name?: string;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: 'radio' | 'checkbox';
  value?: string;
  width?: CheckboxWidth;
}

export const Checkbox: FC<Props> = ({
  className,
  id,
  name,
  checked,
  onChange,
  type,
  value,
  width,
}) => (
  <CheckboxContainer className={className} width={width}>
    <HiddenCheckbox
      onChange={onChange}
      checked={checked}
      value={value}
      id={id}
      name={name}
      type={type || 'checkbox'}
    />
    <StyledCheckbox>{checked && <IconStyled svgComponent={Check} />}</StyledCheckbox>
  </CheckboxContainer>
);
