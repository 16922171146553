import { ReactComponent as FingerprintSvg } from 'assets/UI/FingerprintWithourBorders.svg';
import { ReactComponent as MedicalRecordSvg } from 'assets/UI/MedicalRecord.svg';
import { Tab } from 'Molecules/buttons/Tab';
import { Slider, TabsContainer } from 'Molecules/Tabs';
import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';

const CompareDiseasesTab = styled(Tab)`
  align-items: center;
  &:nth-child(1) {
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: 195px;
          left: 0;
        }
      `}
  }

  &:nth-child(2) {
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: 155px;
          left: 220px;
        }
      `}
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font: ${props => props.theme.fonts.normalTextSemiBold};

    &:nth-child(1) {
      ${props =>
        props.active &&
        css`
          & ~ ${Slider} {
            width: 160px;
            left: 0;
          }
        `}
    }

    &:nth-child(2) {
      ${props =>
        props.active &&
        css`
          & ~ ${Slider} {
            width: 124px;
            left: 176px;
          }
        `}
    }
  }
`;

export const StyledTabsContainer = styled(TabsContainer)`
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
`;

export type DiseasesTabType = 'Clinical findings' | 'Fingerprint';

interface Props {
  className?: string;
  activeSection: DiseasesTabType;
  onClick: (section: DiseasesTabType) => void;
}

export const CompareDiseasesTabs: FC<Props> = ({ className, activeSection, onClick }) => {
  return (
    <StyledTabsContainer className={className}>
      <CompareDiseasesTab
        name="Clinical findings"
        onClick={() => onClick('Clinical findings')}
        active={activeSection === 'Clinical findings'}
        icon={MedicalRecordSvg}
      >
        Clinical findings
      </CompareDiseasesTab>
      <CompareDiseasesTab
        name="Fingerprint"
        onClick={() => onClick('Fingerprint')}
        active={activeSection === 'Fingerprint'}
        icon={FingerprintSvg}
      >
        Fingerprint
      </CompareDiseasesTab>
      <Slider />
    </StyledTabsContainer>
  );
};
