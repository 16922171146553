import React, { FC } from 'react';
import {
  useCompareBacteria,
  useCompareMycobacteria,
  useCompareYeasts,
} from 'store/ComparisonStore/hooks';
import { useMicrobesResource } from 'store/microbesStore/hooks';

import { CompareDiseases as CompareDiseasesComponent } from './CompareDiseases';
import { CompareDrugs as CompareDrugsComponent } from './CompareDrugs';
import { CompareMicrobe as CompareMicrobeComponent } from './CompareMicrobe';

export const CompareDiseases: FC = () => {
  return <CompareDiseasesComponent />;
};

export const CompareDrugs: FC = () => {
  return <CompareDrugsComponent />;
};

export const CompareBacteria: FC = () => {
  const microbes = useMicrobesResource();
  const comparedBacteria = useCompareBacteria();

  return (
    <CompareMicrobeComponent
      microbeType="bacteria"
      microbes={microbes}
      comparedMicrobes={comparedBacteria}
    />
  );
};

export const CompareMycobacteria: FC = () => {
  const microbes = useMicrobesResource();
  const comparedMycobacteria = useCompareMycobacteria();

  return (
    <CompareMicrobeComponent
      microbeType="mycobacteria"
      microbes={microbes}
      comparedMicrobes={comparedMycobacteria}
    />
  );
};

export const CompareYeasts: FC = () => {
  const microbes = useMicrobesResource();
  const comparedYeasts = useCompareYeasts();

  return (
    <CompareMicrobeComponent
      microbeType="yeasts"
      microbes={microbes}
      comparedMicrobes={comparedYeasts}
    />
  );
};
