import { ReferenceDTO } from 'apiServices/common/DTO';
import { isNotNull } from 'apiServices/common/guards';
import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { getSlug } from 'services/slug/slug.service';
import { isMicrobe } from 'types/az';
import {
  DiseaseCountries,
  DiseaseDetails,
  DiseaseOutbreak,
  Outbreak,
  Pathogen,
  PublicDiseaseDetails,
  Vaccine,
} from 'types/disease';
import { DiseaseDistribution } from 'types/diseaseDistribution';
import { DynamicContent } from 'types/dynamicContent';
import { parseEtymologyData } from 'utils/parseEtymologyData';
import { replace, replaceCaseInsensitive } from 'utils/replacer';

import {
  DiseaseCountryDTO,
  DiseaseDetailsDTO,
  DiseaseDistributionLegendDTO,
  DiseaseDistributionValueDTO,
  DiseaseOutbreakDTO,
  DrugDTO,
  MicrobiologyAgentDTO,
  OutbreakSimpleDTO,
  PathogenDTO,
  PublicDiseaseDetailsDTO,
  VaccineDTO,
} from './DTO';

const mapPathogenDTO = (
  pathogen: PathogenDTO,
  microbiologyAgents: MicrobiologyAgentDTO[]
): Pathogen => {
  const microbe = microbiologyAgents.find(a => a.pathogen === pathogen.pathogen);
  const microbeType = microbe?.type || '';
  return microbe && isMicrobe(microbeType)
    ? {
        code: pathogen.pathogen_code,
        pathogen: pathogen.pathogen,
        microbe: {
          code: microbe.code,
          type: microbeType,
        },
      }
    : {
        code: pathogen.pathogen_code,
        pathogen: pathogen.pathogen,
      };
};

const mapVaccineDTO = (vaccine: VaccineDTO): Vaccine => ({
  code: vaccine.vaccine_code,
  vaccine: vaccine.vaccine,
});

const addDrugLinks = (therapyText: string, drugs: DrugDTO[]): string => {
  const replacer = drugs.reduce(
    (acc, drug) => ({
      ...acc,
      [drug.drug]: `<gdn-link type="drugs" id="${drug.drug_code}">${drug.drug}</gdn-link>`,
    }),
    {}
  );

  return replace(therapyText, replacer);
};

const parseDrugs = (
  therapyText: string,
  drugs: DrugDTO[],
  references: ReferenceDTO[] = []
): DynamicContent =>
  parseDynamicContent(
    addDrugLinks(therapyText, drugs) + references.map(ref => `{${ref.reference_number}}`).join(' ')
  );

const getCountryName = (countryCode: string, country: string): string => {
  if (countryCode === 'G100') return 'Worldwide';
  if (countryCode === 'G90') return 'Bioterrorism';
  return country;
};

const getCountryCode = (countryCode: string): string => {
  if (countryCode === 'G100') return 'worldwide';
  if (countryCode === 'G90') return 'bioterrorism';
  return countryCode;
};

export const mapDiseaseCountries = (countries: DiseaseCountryDTO[],timestamp?:number): DiseaseCountries => ({
  list: countries.map(c => ({
    code: getCountryCode(c.country_code),
    country: getCountryName(c.country_code, c.country),
    isEndemic: c.is_endemic,
    noteNumber: c.country_note_number,
    hasCountryNote: c.has_country_note,
    isChloroquineResistant: c.is_chloroquine_resistant,
    isChloroquineSensitive: c.is_chloroquine_sensitive,
    slug: getSlug(getCountryName(c.country_code, c.country)),
  })),
  isBioterrorismAvailable:
    countries.find(country => country.country_code === 'G90')?.has_country_note || false,
  isWorldwideAvailable:
    countries.find(country => country.country_code === 'G100')?.has_country_note || false,
  timestamp:timestamp||0
});

const addMicrobeLinks = (text: string, pathogens: MicrobiologyAgentDTO[]): string => {
  const replacer = pathogens.reduce((acc, pathogen) => {
    return {
      ...acc,
      [pathogen.pathogen]: `<gdn-link type="${pathogen.type}" id="${pathogen.code}">${pathogen.pathogen}</gdn-link>`,
    };
  }, {});

  return replaceCaseInsensitive(text, replacer);
};

export const mapDiseasesDetailsDTO = (d: DiseaseDetailsDTO,Timestamp:number): DiseaseDetails => ({
  code: d.disease_code,
  disease: d.disease,
  agentText: parseDynamicContent(addMicrobeLinks(d.agent_text, d.microbiology_agents)),
  reservoirText: parseDynamicContent(d.reservoir_text  || 'None'),
  vectorText: parseDynamicContent(d.vector_text  || 'None'),
  vehicleText: parseDynamicContent(d.vehicle_text  || 'None'),
  incubation: parseDynamicContent(d.incubation || 'Not known'),
  diagnosticTests: parseDynamicContent(d.diagnostic_tests  || 'None'),
  adultTherapy: parseDrugs(d.adult_therapy, d.adult_drugs, d.therapy_references),
  pediatricTherapy: parseDrugs(d.pediatric_therapy, d.pediatric_drugs),
  vaccines: d.vaccines.filter(isNotNull).map(mapVaccineDTO),
  clinicalHints: parseDynamicContent(d.clinical_hints  || 'None'),
  pathogens: d.pathogens
    .filter(isNotNull)
    .map(pathogen => mapPathogenDTO(pathogen, d.microbiology_agents)),
  clinicalNote: parseDynamicContent(addMicrobeLinks(d.clinical_note, d.microbiology_agents)),
  synonyms: d.synonyms
    .concat([`ICD9: ${d.icd9.join(', ')}`, `ICD10: ${d.icd10.join(', ')}`, `ICD11: ${d.icd11.join(', ')}`])
    .map(synonym => parseDynamicContent(synonym)),
  slug: getSlug(d.disease),
  relatedDiseases: d.related_diseases
    ? d.related_diseases.map(rd => ({
        id: parseInt(rd.disease_code),
        name: rd.disease,
      }))
    : [],
  etymology: (d.etymology && d.etymology.length > 0) ? parseEtymologyData(d.etymology) : undefined,
  associated_microbes:d.microbiology_agents.map(m=>({...m,references:m.references?.length> 0 ? parseDynamicContent(m.references.map(ref => `{${ref.reference_number}}`).join(' ')) : []})).sort((a,b)=>a.pathogen.localeCompare(b.pathogen)),
  timestamp:Timestamp
});

export const mapDiseaseOutbreakDTO = (outbreak: DiseaseOutbreakDTO): DiseaseOutbreak | null => {
  if (outbreak.outbreak_start === null) {
    return null;
  }

  return {
    countryId: outbreak.country_code,
    countryName: outbreak.country_name,
    cases: outbreak.cases,
    deaths: outbreak.deaths,
    startYear: outbreak.outbreak_start,
    endYear: outbreak.outbreak_end || outbreak.outbreak_start,
    PublicationYear: outbreak.publication_year,
    city: outbreak.city,
    state: outbreak.state,
    region: outbreak.region,
    lat: outbreak.latitude,
    lng: outbreak.longitude,
  };
};

const getLabel = (count: number, labels: DiseaseDistributionLegendDTO[]): string => {
  const label = labels.find(label => {
    if (label.exclusive_min && label.exclusive_max) {
      return count > label.min_value && count < label.max_value;
    } else if (label.exclusive_max) {
      return count >= label.min_value && count < label.max_value;
    } else if (label.exclusive_min) {
      return count > label.min_value && count <= label.max_value;
    } else {
      return count >= label.min_value && count <= label.max_value;
    }
  });

  return label?.legend || '';
};

export const mapDiseaseDistributionDTO = (
  labels: DiseaseDistributionLegendDTO[],
  values: DiseaseDistributionValueDTO[]
): DiseaseDistribution => ({
  legend: labels.map(l => ({
    legend: l.legend,
    maxValue: l.max_value,
    minValue: l.min_value,
    exclusiveMax: l.exclusive_max,
    exclusiveMin: l.exclusive_min,
  })),
  values: values.map(v => ({
    countryId: v.country_code,
    countryName: v.country,
    count: v.count,
    labelName: getLabel(v.count, labels),
  })),
});

export const mapOutbreakSimpleDTO = (outbreak: OutbreakSimpleDTO): Outbreak | null => {
  if (!outbreak.country_code || !outbreak.country) {
    return null;
  }

  return {
    cases: outbreak.cases,
    countryId: outbreak.country_code.toString(),
    countryName: outbreak.country_code === 'G100' ? 'Worldwide' : outbreak.country,
    deaths: outbreak.deaths,
    diseaseId: outbreak.disease_code.toString(),
    diseaseName: outbreak.disease,
    region: outbreak.region,
    state: outbreak.state,
    latitude: outbreak.latitude,
    longitude: outbreak.longitude,
    city: outbreak.city,
  };
};

export const mapPublicDiseasesDetailsDTO = (d: PublicDiseaseDetailsDTO): PublicDiseaseDetails => ({
  code: d.disease_code,
  disease: d.disease,
  agentText: parseDynamicContent(d.agent_text),
  reservoirText: parseDynamicContent(d.reservoir_text),
  vehicleText: parseDynamicContent(d.vehicle_text),
  vectorText: parseDynamicContent(d.vector_text),
  incubation: parseDynamicContent(d.incubation),
  clinicalNote: parseDynamicContent(d.clinical_note),
  diagnosticTests: parseDynamicContent(d.diagnostic_tests),
  slug: getSlug(d.disease),
});
