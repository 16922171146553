import { ReactComponent as GalleryArrowSVG } from 'assets/UI/galleryArrow.svg';
import { ReactComponent as MaximizeSVG } from 'assets/UI/Maximize.svg';
import { ReactComponent as MinimizeSVG } from 'assets/UI/Minimize.svg';
import { FilledButton, StyledSpan } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { LazyLoadPlaceholder } from 'Atoms/LazyLoadPlaceholder';
import { P } from 'Atoms/text/P';
import { isFunction } from 'lodash';
import { LazyLoad } from 'Molecules/LazyLoad';
import { DynamicHtmlFromString } from 'Organisms/dynamicContent/DynamicHtmlFromString';
import { linearGradient, rgba } from 'polished';
import React, { ReactElement, ReactNode, useCallback, useRef, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { useMobile } from 'services/useMobile';
import styled from 'styled-components/macro';
import { ScrollbarMixin } from 'utils/styleMixins';

const spacer = 5;
const buttonsHeight = 50;
const buttonsHeightMobile = 35;
const progressBarHeight = 20;

const Wrapper = styled.div`
  position: relative;
  height: calc(100vh - 270px);
  width: 100%;
  padding: 0 5px;
  margin: 0;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    height: calc(100vh - 150px);
  }
`;

const Image = styled.img`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-height: 100%;
  width: 100%;
  object-fit: contain;
`;

const DescriptionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Description = styled.div`
  position: absolute;
  bottom: 0;
  background: ${props =>
    linearGradient({
      colorStops: [
        rgba(props.theme.colors.background.modalCardBody, 0) + ' 0',
        rgba(props.theme.colors.background.modalCardBody, 1) + ' 100px',
      ],
    })};
  background-attachment: local;
  padding: 100px 10% 10px;
  line-height: normal;
  text-align: left;
  white-space: normal;
  width: 100%;
  min-height: 200px;
  max-height: 100%;
  overflow-y: auto;

  .fullscreen & {
    background: ${linearGradient({
      colorStops: [rgba('#000000', 0) + ' 0', rgba('#000000', 1) + ' 100px'],
    })};
  }
`;

const StyledP = styled(P)``;

const FullscreenButtonWrapper = styled.div`
  position: absolute;
  bottom: ${-spacer - buttonsHeight}px;
  width: 100%;
  height: ${buttonsHeight}px;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    align-items: center;
    height: ${buttonsHeightMobile}px;
  }
`;

const FullscreenButton = styled(FilledButton)`
  width: ${buttonsHeight}px;
  height: 100%;
  padding: 5px;
  border-radius: 5px;
  ${Icon} {
    width: 35px;
    height: 35px;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    height: ${buttonsHeightMobile}px;
    width: ${buttonsHeightMobile}px;
    ${Icon} {
      width: 24px;
      height: 25px;
    }
  }
`;

const ShowDescriptionButton = styled(FilledButton)`
  width: unset;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 125px;
    height: ${buttonsHeightMobile}px;
    padding: 2px;
    ${StyledSpan} {
      font: ${props => props.theme.fonts.verySmallText};
    }
  }
`;

const ArrowContainer = styled.span`
  display: inline-block;
  background-color: ${props => props.theme.colors.background.modalCardBody};
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;

const GalleryArrow = styled(Icon)`
  width: 100%;
  height: 100%;
  transform: scale(1.1);
  fill: ${props => props.theme.colors.imageGallery.default};
`;

const GalleryArrowReverse = styled(GalleryArrow)`
  transform: scaleX(-1.1) scaleY(1.1);
`;

const ArrowButton = styled.button`
  &:hover,
  &:active {
    ${GalleryArrow} {
      fill: ${props => props.theme.colors.imageGallery.hover};
    }
  }
`;

const renderLeftNav = (
  onClick: React.MouseEventHandler<HTMLElement>,
  disabled: boolean
): ReactElement => (
  <ArrowButton
    type="button"
    className="image-gallery-icon image-gallery-left-nav"
    disabled={disabled}
    onClick={onClick}
    aria-label="Previous Slide"
  >
    <ArrowContainer>
      <GalleryArrowReverse svgComponent={GalleryArrowSVG} />
    </ArrowContainer>
  </ArrowButton>
);

const renderRightNav = (
  onClick: React.MouseEventHandler<HTMLElement>,
  disabled: boolean
): ReactElement => (
  <ArrowButton
    type="button"
    className="image-gallery-icon image-gallery-right-nav"
    disabled={disabled}
    onClick={onClick}
    aria-label="Next Slide"
  >
    <ArrowContainer>
      <GalleryArrow svgComponent={GalleryArrowSVG} />
    </ArrowContainer>
  </ArrowButton>
);

const ThumbnailPlaceholder = styled(LazyLoadPlaceholder)`
  // img is inline by default, so placeholder need to match it,
  // but also it should support width and height
  display: inline-block;

  /* Should mach stylings for .image-gallery-thumbnail-image but to have a hardcoded width */
  vertical-align: middle;
  line-height: 0;

  height: 100px;
  width: 151px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    height: 50px;
    width: 76px;
  }
`;

const renderThumbInner = (item: ReactImageGalleryItem): ReactElement => {
  return (
    <div className="image-gallery-thumbnail-inner">
      <LazyLoad placeholder={<ThumbnailPlaceholder showLoader={false} />}>
        <img
          className="image-gallery-thumbnail-image"
          src={item.thumbnail}
          alt={item.thumbnailAlt}
          title={item.thumbnailTitle}
        />
      </LazyLoad>
      {item.thumbnailLabel && (
        <div className="image-gallery-thumbnail-label">{item.thumbnailLabel}</div>
      )}
    </div>
  );
};

interface Props {
  className?: string;
  images: ReactImageGalleryItem[];
}

const GalleryComponent = React.forwardRef<ImageGallery, Props>(({ className, images }, ref) => {
  const isMobile = useMobile('m');

  const [showDescription, setShowDescription] = useState(!isMobile);
  const galleryRef = useRef<ImageGallery | null>(null);

  const refBinder = useCallback(
    (reference: ImageGallery | null) => {
      galleryRef.current = reference;
      if (isFunction(ref)) {
        ref(reference);
      } else if (ref) {
        ref.current = reference;
      }
    },
    [ref]
  );

  const [fullScreenClass, setFullScreen] = useState<string>('');

  const renderFullscreenButton = useCallback(
    (onClick: React.MouseEventHandler<HTMLElement>, isFullscreen: boolean): ReactNode => {
      return (
        <FullscreenButtonWrapper>
          <ShowDescriptionButton
            onClick={() => setShowDescription(!showDescription)}
            color={fullScreenClass != '' ? 'dark' : undefined}
          >
            {showDescription ? 'Hide' : 'Show'} Description
          </ShowDescriptionButton>
          <FullscreenButton
            onClick={onClick}
            aria-label="Fullscreen button"
            color={fullScreenClass != '' ? 'dark' : undefined}
          >
            <Icon svgComponent={isFullscreen ? MinimizeSVG : MaximizeSVG} />
          </FullscreenButton>
        </FullscreenButtonWrapper>
      );
    },
    [fullScreenClass, showDescription]
  );

  const renderItem = useCallback(
    (item: ReactImageGalleryItem): ReactNode => {
      const { original, originalTitle, thumbnailTitle, description } = item;

      return (
        <Wrapper>
          <Image src={original} alt={originalTitle} title={originalTitle} />
          {showDescription && (
            <DescriptionWrapper>
              <Description>
                {thumbnailTitle && (
                  <StyledP>
                    <b>Source:</b> {item.thumbnailTitle}
                  </StyledP>
                )}
                {originalTitle && (
                  <StyledP>
                    <b>Title:</b> <DynamicHtmlFromString contentString={originalTitle} />
                  </StyledP>
                )}
                {description && (
                  <StyledP>
                    <b>Description:</b> <DynamicHtmlFromString contentString={description} />
                  </StyledP>
                )}
              </Description>
            </DescriptionWrapper>
          )}
        </Wrapper>
      );
    },
    [showDescription]
  );

  // This is hacky solution for fixing some fullscreen styling problems on mobile.
  // The issue was that styles sometimes applyed before fully entering full screen
  // and half of thumbnail was bellow screen. It was only reproduced on actual android phone Chrome 81
  const onScreenChange = useCallback(
    (state: boolean) => {
      const timeout = 500;
      if (state) {
        setTimeout(() => setFullScreen(' fullscreen-styles'), timeout);
      } else {
        setTimeout(() => setFullScreen(''), timeout);
      }
    },
    [setFullScreen]
  );

  const onSlide = useCallback((index: number) => {
    if (!galleryRef.current) {
      return;
    }

    // thumbnailsWrapper reference is missing in type definition, so it is cast manually
    const imageGallery = (galleryRef.current as unknown) as {
      thumbnailsWrapper: React.RefObject<HTMLDivElement>;
    };
    const wrapper = imageGallery.thumbnailsWrapper?.current;
    if (!wrapper) {
      return;
    }

    const tn = wrapper.firstChild?.childNodes[index] as HTMLElement | undefined;
    const wrapperRect = wrapper.getBoundingClientRect();
    const thumbnailRect = tn?.getBoundingClientRect();
    const thumbnailOffset = tn?.offsetLeft;
    if (!thumbnailRect || thumbnailOffset === undefined) {
      return;
    }

    wrapper?.scrollTo(thumbnailOffset + thumbnailRect.width / 2 - wrapperRect.width / 2, 0);
  }, []);

  return (
    <ImageGallery
      ref={refBinder}
      items={images}
      additionalClass={className + fullScreenClass}
      showPlayButton={false}
      renderItem={renderItem}
      showBullets
      showIndex
      showFullscreenButton={true}
      disableThumbnailScroll={true}
      // Casted to any cause type definition for onScreenChange prop is wrong
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onScreenChange={onScreenChange as any}
      // there is missing prop definition for onBeforeSlide
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onBeforeSlide={onSlide}
      renderFullscreenButton={renderFullscreenButton}
      renderLeftNav={renderLeftNav}
      renderRightNav={renderRightNav}
      renderThumbInner={renderThumbInner}
      lazyLoad={true}
    />
  );
});

export const Gallery = styled(GalleryComponent)`
  width: 100%;

  .image-gallery-slide-wrapper {
    margin-bottom: ${spacer + buttonsHeight + progressBarHeight}px;
    height: 100%;
  }

  .image-gallery-icon {
    color: ${props => props.theme.colors.imageGallery.default};

    &:hover {
      color: ${props => props.theme.colors.imageGallery.hover};
    }

    &.image-gallery-left-nav {
      margin-left: 10px;
    }

    &.image-gallery-right-nav {
      margin-right: 10px;
    }

    @media (max-width: ${props => props.theme.breakpoints.m}) {
      &.image-gallery-left-nav {
        margin-left: 0;
      }

      &.image-gallery-right-nav {
        margin-right: 0;
      }
    }

    @media (max-width: ${props => props.theme.breakpoints.m}) and (pointer: coarse) {
      display: none;
    }
  }

  .image-gallery-swipe {
    flex-grow: 1;
  }

  .image-gallery-index {
    position: absolute;
    top: unset;
    right: unset;
    bottom: ${-spacer - buttonsHeight}px;
    left: 50%;
    margin: 0;
    transform: translateX(-50%);
    background: ${props => props.theme.colors.imageGallery.indexBackground};

    @media (max-width: ${props => props.theme.breakpoints.s}) {
      bottom: ${-spacer - buttonsHeight - progressBarHeight}px;
      height: ${progressBarHeight - spacer}px;
      padding: 2px;
    }
  }

  .image-gallery-bullets {
    position: absolute;
    bottom: ${-spacer - buttonsHeight - progressBarHeight}px;
    height: ${progressBarHeight - spacer}px;
    width: 100%;
  }

  .image-gallery-bullets-container {
    height: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;

    &:hover {
      /* used 3 classes to overide .active styles and :focus styles */
      .image-gallery-bullet.image-gallery-bullet.image-gallery-bullet {
        background: ${props => props.theme.colors.imageGallery.progressBarActive};
      }
    }

    &:focus-within {
      /* used 2 classes to overide .active styles */
      .image-gallery-bullet.image-gallery-bullet {
        background: ${props => props.theme.colors.imageGallery.progressBarActive};
      }
    }
  }

  .image-gallery-bullet {
    flex: 1 1 auto;
    border: none;
    background: #ffffff;
    box-shadow: none;
    border-radius: initial;
    margin: 0;
    padding: 0;
    background: ${props => props.theme.colors.imageGallery.progressBarActive};
    transition: none;

    &.active ~ .image-gallery-bullet {
      background: ${props => props.theme.colors.imageGallery.progressBar};
    }

    &:hover {
      transform: unset;

      /* used 3 classes to overide .active styles and :focus styles */
      & ~ .image-gallery-bullet.image-gallery-bullet.image-gallery-bullet {
        background: ${props => props.theme.colors.imageGallery.progressBar};
      }
    }

    &:focus {
      transform: unset;

      /* used 2 classes to overide .active styles */
      & ~ .image-gallery-bullet.image-gallery-bullet {
        background: ${props => props.theme.colors.imageGallery.progressBar};
      }
    }

    &.active {
      background: ${props => props.theme.colors.imageGallery.progressBarActive};
    }
  }

  .image-gallery-thumbnails {
    overflow-x: auto;
    ${ScrollbarMixin}
    scroll-behavior: smooth;
  }

  .image-gallery-thumbnail {
    width: unset;
  }

  .image-gallery-thumbnail-image {
    height: 100px;
    width: auto;
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      height: 50px;
    }
  }

  .fullscreen {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin: 0 auto;
    ${StyledP} {
      color: #ffffff;
    }

    ${FullscreenButtonWrapper} {
      padding-left: 10px;
      padding-right: 10px;
    }

    ${Wrapper} {
      height: calc(100vh - ${138 + spacer + buttonsHeight + progressBarHeight}px);

      @media (max-width: ${props => props.theme.breakpoints.m}) {
        height: 100vh;
      }
    }

    .image-gallery-swipe,
    .image-gallery-slides,
    .image-gallery-slide {
      height: 100%;
    }
  }

  &.fullscreen-styles {
    .fullscreen {
      ${Wrapper} {
        @media (max-width: ${props => props.theme.breakpoints.m}) {
          height: calc(100vh - ${94 + spacer + buttonsHeightMobile + progressBarHeight}px);
          @media (orientation: landscape) {
            height: calc(100vh - ${20 + buttonsHeightMobile + progressBarHeight}px);
          }
        }
      }
    }

    .image-gallery-thumbnails {
      @media (max-width: ${props => props.theme.breakpoints.m}) and (orientation: landscape) {
        display: none;
      }
    }
  }

  &.fullscreen-modal {
    z-index: 100;
  }
`;
