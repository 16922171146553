import { getAZListPDFUrl } from 'apiServices/Pdf/pdf';
import { sendAZListPDF } from 'apiServices/Pdf/pdfMail';
import { StyledSpan } from 'Atoms/buttons/FilledButton';
import { DownloadButton } from 'Molecules/buttons/DownloadButton';
import { SquareDownloadButton, SquareMailButton } from 'Molecules/PdfButtons';
import { MailPDFButtonForm } from 'Organisms/mailPdf/MailPDFButtonForm';
import React, { FC, useCallback } from 'react';
import { useDownloadPDF } from 'services/useDownloadPDF';
import { useEmailPDF } from 'services/useEmailPDF';
import { useMobile } from 'services/useMobile';
import styled from 'styled-components/macro';
import { CategoryType, MicrobeType } from 'types/az';
import { VirusType } from 'types/microbeDetails';

const DownloadButtons = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 40px 0;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-left: 20px;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    justify-content: center;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-left: 3px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
`;

const StyledDownloadButton = styled(DownloadButton)`
  min-width: 250px;
  margin: 20px 20px 0 0;
`;

const DownloadAllButton = styled(DownloadButton)`
  min-width: 230px;
  margin: 20px 20px 0 0;
  ${StyledSpan} {
    font-size: 14px;
  }
`;

const StyledMailButton = styled(MailPDFButtonForm)`
  margin: 20px 20px 0 0;
`;

const StyledSquareMailButton = styled(SquareMailButton)`
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    min-width: 75px;
    width: 75px;
  }
`;

const StyledSquareDownloadButton = styled(SquareDownloadButton)`
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    min-width: 75px;
    width: 75px;
  }
`;

interface DownloadAllProps {
  className?: string;
}

export const DownloadEntireAZButton: FC<DownloadAllProps> = ({ className }) => {
  const isTablet = useMobile('l');
  const isMobile = useMobile('s');
  const { onClick, status } = useDownloadPDF(getAZListPDFUrl);

  const getDownloadLabel = (): string => {
    if (status === 'Loading') {
      return isMobile ? 'Generating' : 'Generating...';
    } else if (status === 'Ready') {
      return isMobile ? 'A-Z' : 'A-Z PDF is ready';
    } else {
      return isMobile ? 'A-Z' : 'Download A-Z PDF';
    }
  };

  return isTablet ? (
    <StyledSquareDownloadButton
      className={className}
      onClick={onClick}
      label={getDownloadLabel()}
    />
  ) : (
    <DownloadAllButton className={className} onClick={onClick} label={getDownloadLabel()} />
  );
};

interface Props {
  className?: string;
  category: CategoryType | MicrobeType | VirusType;
}

export const AZDownloadButtons: FC<Props> = ({ className, category }) => {
  const isTablet = useMobile('l');
  const isMobile = useMobile('s');

  const { email: categoryEmail, ...categoryEmailProps } = useEmailPDF(() =>
    sendAZListPDF(categoryEmail, category)
  );
  const { email: wholeListEmail, ...wholeListEmailProps } = useEmailPDF(() =>
    sendAZListPDF(wholeListEmail)
  );
  const getPDFUrl = useCallback(() => getAZListPDFUrl(category), [category]);
  const { onClick, status } = useDownloadPDF(getPDFUrl);

  const getDownloadLabel = (): string => {
    if (status === 'Loading') {
      return isMobile ? 'Generating' : 'Generating...';
    } else if (status === 'Ready') {
      return isMobile ? 'This list' : 'List is ready';
    } else {
      return isMobile
        ? 'This list'
        : category === 'diseases'
        ? 'Download Diseases A-Z'
        : 'Download this list';
    }
  };

  const emailThisLabel = isMobile ? 'This list' : 'Email this list';
  const emailAllLabel = isMobile ? 'A-Z' : 'Email A-Z';

  return (
    <DownloadButtons className={className}>
      <ButtonGroup>
        {isTablet ? (
          <StyledSquareDownloadButton onClick={onClick} label={getDownloadLabel()} />
        ) : (
          <StyledDownloadButton onClick={onClick} label={getDownloadLabel()} />
        )}
        <DownloadEntireAZButton />
      </ButtonGroup>
      <ButtonGroup>
        {isTablet ? (
          <>
            <StyledSquareMailButton
              email={categoryEmail}
              {...categoryEmailProps}
              label={emailThisLabel}
            />
            <StyledSquareMailButton
              email={wholeListEmail}
              {...wholeListEmailProps}
              label={emailAllLabel}
            />
          </>
        ) : (
          <>
            <StyledMailButton
              email={categoryEmail}
              {...categoryEmailProps}
              label={emailThisLabel}
            />
            <StyledMailButton
              email={wholeListEmail}
              {...wholeListEmailProps}
              label={emailAllLabel}
            />
          </>
        )}
      </ButtonGroup>
    </DownloadButtons>
  );
};
