import { LocationState } from 'history';

export interface RecordLocationState {
  synonymName: string;
}

export const isRecordLocationState = (state: LocationState): state is RecordLocationState => {
  return (
    state !== null &&
    state !== undefined &&
    (state as RecordLocationState).synonymName !== undefined
  );
};
