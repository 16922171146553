import { SearchDTO, SearchSuggestionsDTO } from 'apiServices/Search/DTO';
import { mapSearchDTOtoSearchResults } from 'apiServices/Search/mappers';
import Axios from 'axios.cached';
import { Search, SearchQueryParameters } from 'types/search';

export const getSearchSuggestions = async (query: string): Promise<string[]> => {
  const searchSuggestions = await Axios.get<SearchSuggestionsDTO>(`/api/search/suggestions`, {
    params: {
      q: query,
    },
  });

  return searchSuggestions.data.data;
};

export const getSearchResults = async (queryParameters: SearchQueryParameters): Promise<Search> => {
  const search = await Axios.get<SearchDTO>('/api/search', {
    params: {
      q: queryParameters.searchValue,
      record_type: queryParameters.recordType === 'all' ? undefined : queryParameters.recordType,
      module: queryParameters.module,
      offset: queryParameters.offset,
      limit: queryParameters.limit,
    },
  });

  return mapSearchDTOtoSearchResults(search.data);
};
