import { getAriaSort, SortButton } from 'Atoms/buttons/SortButton';
import { Link } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { Span } from 'Atoms/text';
import { P } from 'Atoms/text';
import { Tooltip } from 'Atoms/tooltip/Tooltip';
import { CloseButton } from 'Molecules/buttons/CloseButton';
import { CellWithSort } from 'Molecules/compare/CellWithSort';
import { CompareTableLink } from 'Molecules/compare/CompareTableLink';
import { ProbabilityKey } from 'Molecules/compare/ProbabilityKey';
import {
  StickyFirstTH,
  StickyTH,
  StyledCloseButton,
  StyledTD,
  StyledTH,
  TR,
} from 'Organisms/compare/CompareTableBase';
import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import {
  CompareColumnDefinition,
  FingerprintRow,
  Probability,
  ProbabilityType,
} from 'types/compare';
import { Row } from 'types/table';
import { SortState } from 'types/table';
import { sortTableRows } from 'utils/sortTableRows';

const StyledProbabilityKey = styled(ProbabilityKey)`
  margin: 0 auto;
`;

const TooltipStyled = styled(Tooltip)`
  top: -90px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const MissingTest = styled(Span)`
  color: ${props => props.theme.colors.comparisonColors.Missing};
  position: relative;
  &:hover {
    ${TooltipStyled} {
      display: block;
    }
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledP = styled(P)`
  margin-right: 10px;
`;

export const renderCompareHead = (
  columns: CompareColumnDefinition[],
  firstColumnTitle: string,
  sort: SortState,
  onSortClick: (index: number) => void,
  onRemove: (id: number) => void
): ReactNode => {
  return (
    <>
      <StickyFirstTH wrap>
        <CellWithSort
          text={firstColumnTitle}
          onClick={() => onSortClick(0)}
          clicked={0 === sort.columnIndex}
          asc={sort.asc}
        />
      </StickyFirstTH>
      {columns.map((c, i) => {
        if (c.column === 'Loading') {
          return (
            <StyledTH key={i} wrap={columns[i].enableWrap} width={`${100 / (columns.length + 1)}%`}>
              <Loader />
            </StyledTH>
          );
        }

        const { category, id, name } = c.column;
        return (
          <StyledTH
            key={i}
            wrap={columns[i].enableWrap}
            width={`${100 / (columns.length + 1)}%`}
            aria-sort={getAriaSort(i + 1 !== sort.columnIndex, sort.asc)}
          >
            <StyledCloseButton onClick={() => onRemove(id)} />
            <CompareTableLink to={`/explore/${category}/${id}`} linkText={name}>
              <SortButton
                onClick={() => onSortClick(i + 1)}
                clicked={i + 1 === sort.columnIndex}
                asc={sort.asc}
                label={name}
              />
            </CompareTableLink>
          </StyledTH>
        );
      })}
    </>
  );
};

export const renderCompareBody = (
  rows: Row[],
  sort: SortState,
  getProbability: (probability: number) => Probability | undefined,
  probabilityType: ProbabilityType
): ReactNode => {
  const sortedRows: Row[] = sortTableRows(rows, sort.asc, sort.columnIndex);

  return sortedRows.map((row, i) => (
    <TR key={i}>
      {row.map((cell, j) => {
        if (typeof cell.value === 'string') {
          return <StickyTH key={j}>{cell.label}</StickyTH>;
        }
        const probabilityData = getProbability(cell.value);
        if (probabilityData) {
          return (
            <StyledTD key={j}>
              {probabilityData.name === 'Missing test' ? (
                <MissingTest weight="500">
                  {probabilityData.name}
                  {probabilityData.explanationText && (
                    <TooltipStyled>{probabilityData.explanationText}</TooltipStyled>
                  )}
                </MissingTest>
              ) : (
                <StyledProbabilityKey
                  probability={{
                    type: probabilityType,
                    percentage: probabilityData.percentage,
                    text: probabilityData.name,
                    explanationText: probabilityData.explanationText,
                  }}
                  accent={probabilityData.accent}
                />
              )}
            </StyledTD>
          );
        }

        return <StyledTD key={j} />;
      })}
    </TR>
  ));
};

export const renderFingerprintHead = (
  columns: string[],
  firstColumnTitle: string,
  sort: SortState,
  onSortClick: (index: number) => void
): ReactNode => (
  <>
    <StickyFirstTH wrap>
      <CellWithSort
        text={firstColumnTitle}
        onClick={() => onSortClick(0)}
        clicked={0 === sort.columnIndex}
        asc={sort.asc}
      />
    </StickyFirstTH>
    {columns.map((column, i) => (
      <StyledTH key={i} width={`${100 / (columns.length + 1)}%`}>
        <ColumnWrapper>
          <StyledP>{column}</StyledP>
          <SortButton
            onClick={() => onSortClick(i + 1)}
            clicked={i + 1 === sort.columnIndex}
            asc={sort.asc}
            label={column}
          />
        </ColumnWrapper>
      </StyledTH>
    ))}
  </>
);

export const renderFingerprintBody = (
  rows: FingerprintRow[],
  sort: SortState,
  onRemove: (id: number) => void
): ReactNode => {
  const sortedRows: FingerprintRow[] = sortTableRows(rows, sort.asc, sort.columnIndex);

  return sortedRows.map((row, i) => {
    const id = row[0].id;

    return (
      <TR key={i}>
        {row.map((cell, j) =>
          cell.id ? (
            <StickyTH key={j}>
              <Link to={`/explore/diseases/${cell.id}`}>{cell.label}</Link>
            </StickyTH>
          ) : (
            <StyledTD key={j} wrap>
              {cell.label}
            </StyledTD>
          )
        )}
        {id && (
          <StyledTD>
            <CloseButton onClick={() => onRemove(id)} />
          </StyledTD>
        )}
      </TR>
    );
  });
};
