import { VariableAuthRoute } from 'Atoms/routes/VariableAuthRoute';
import { NotFound } from 'pages/404/';
import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { DrugRoutes } from './Drug';
import { Drugs } from './Drugs';
import { DrugsPublic } from './DrugsPublic';

export const DrugsRoutes: FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <VariableAuthRoute exact path={match.url} component={Drugs} componentPublic={DrugsPublic} />
    <Route path={`${match.url}/:slug`} component={DrugRoutes} />
    <Route component={NotFound} />
  </Switch>
);
