import { H1 } from 'Atoms/text/H';
import { P } from 'Atoms/text/P';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const TitleContainer = styled.div`
  margin-top: 50px;
`;

const Title = styled(H1)`
  margin: 0 0 10px 0;
`;

interface Props {
  className?: string;
}

export const UpdatesTitle: FC<Props> = ({ className }) => (
  <TitleContainer className={className}>
    <Title color="lightLink" weight="600" size="big" font="Inter">
      What&apos;s New?
    </Title>
    <P textAlign="center">real-time product updates</P>
  </TitleContainer>
);
