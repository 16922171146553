import { Icon } from 'Atoms/Icon';
import { StaticNavbarLink } from 'Atoms/links/NavLinks';
import { Span } from 'Atoms/text';
import React, { forwardRef, KeyboardEventHandler, useContext } from 'react';
import { useComparisonNotEmpty } from 'store/ComparisonListsStore/hooks';
import styled, { ThemeContext } from 'styled-components/macro';

const StyledIcon = styled(Icon)`
  width: auto;
  height: 22px;
  fill: ${props => props.theme.colors.button.default.textColor};
`;

const DesktopIcon = styled(StyledIcon)`
  margin-bottom: 10px;
`;

const NotEmptyLink = styled(StaticNavbarLink)`
  font-weight: 600;
  color: ${props => props.theme.colors.text.mainBold};

  margin-right: 38px;
  display: flex;
  align-items: center;
`;

const Link = styled(StaticNavbarLink)`
  margin-right: 50px;
`;

const HiddenDescription = styled(Span)`
  display: none;
`;

interface Props {
  path: string;
  onKeyDown?: KeyboardEventHandler<HTMLElement>;
}

export const CompareNavButton = forwardRef<HTMLAnchorElement, Props>(({ path, onKeyDown }, ref) => {
  const comparisonNotEmpty = useComparisonNotEmpty();
  const theme = useContext(ThemeContext);

  return comparisonNotEmpty ? (
    <NotEmptyLink
      to={path}
      aria-label="Open comparison"
      ref={ref}
      onKeyDown={onKeyDown}
      aria-describedby="compare-button"
    >
      Compare
      <DesktopIcon svgComponent={theme.images.compareLightbulb} />
      <HiddenDescription id="compare-button">
        Create comparison tables for diseases, drugs, and pathogens
      </HiddenDescription>
    </NotEmptyLink>
  ) : (
    <Link
      to={path}
      aria-label="Open comparison"
      ref={ref}
      onKeyDown={onKeyDown}
      aria-describedby="compare-button"
    >
      Compare
      <HiddenDescription id="compare-button">
        Create comparison tables for diseases, drugs, and pathogens
      </HiddenDescription>
    </Link>
  );
});
