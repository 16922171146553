import Axios from 'axios.cached';
import { UpdatesResponse } from 'Updates/types/updatedField';

import { UpdatedDTO, UpdatesDataDTOWithPagination } from './updates.dto';
import { mapUpdatedFields } from './updates.mapper';

async function getUpdatesData<T>(
  url: string
): Promise<UpdatesDataDTOWithPagination<T[]>> {
  const response = await Axios.get<UpdatesDataDTOWithPagination<T[]>>(url);

  const { data, total } = response.data;

  if (data.length >= total) {
    return response.data;
  }

  const limit = response.data.limit;
  const times = Math.ceil((total - limit) / limit);
  const array = Array.from(Array(times)).map((_, i) =>
    Axios.get<UpdatesDataDTOWithPagination<T>>(
      `${url}&limit=${limit}&offset=${(i + 1) * limit}`
    )
  );
  const responses = await Promise.all(array);

  return {
    ...response.data,
    data: [...response.data.data, ...responses.flatMap(r => r.data.data)],
  };
}

export const getUpdates = async (from: number, to: number): Promise<UpdatesResponse> => {
  const updatedFields = await getUpdatesData<UpdatedDTO>(
    `/api/public/whatsnew?from=${from}&to=${to}`
  );

  return {
    updates: mapUpdatedFields(updatedFields.data),
    total: updatedFields.total,
    totalUpdates: updatedFields.updates
  };
};
