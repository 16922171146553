import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import { MicrobeSummarySection } from 'Molecules/section/MicrobeSummarySection';
import { getStringFromDynamicHtml } from 'Organisms/dynamicContent/DynamicHtmlToString';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import { Paywall } from 'Organisms/paywall/Paywall';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { Element } from 'react-scroll';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { useMicrobeDetailsPublic } from 'store/microbeDetailsStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import { MicrobeType } from 'types/microbeDetails';
import { isRecordLocationState } from 'types/record';

type Props = RouteComponentProps & {
  type: MicrobeType;
  microbeId: number;
};

export const MicrobePublic: FC<Props> = ({ location, type, microbeId }) => {
  const [showSummary, setShowSummary] = useState(true);

  const microbe = useMicrobeDetailsPublic(microbeId, type);

  const synonymName = isRecordLocationState(location.state)
    ? location.state.synonymName
    : undefined;

  if (isLoading(microbe)) {
    return <Loader />;
  }

  assertIsNotStoreError(microbe);

  const description = `GIDEON - ${microbe.name}. Notes: ${getStringFromDynamicHtml(microbe.notes)}`;

  const sections: RecordLink[] = ['Summary', 'Images', 'Phenotype', 'Downloads and references'].map(
    section => ({
      label: section,
      'aria-label': `${microbe.name} ${section.toLowerCase()}`,
    })
  );

  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>{microbe.name} - GIDEON</title>
          <meta name="description" content={description} />
        </Helmet>
        <ReferenceLabelContext.Provider value={microbe.name}>
          <RecordLayout>
            <RecordTitle title={microbe.name} synonymName={synonymName} />
            <RecordLinks links={sections} />
            <RecordSection
              link={sections[0]}
              show={showSummary}
              onCollapse={() => setShowSummary(!showSummary)}
              accent="first"
              id="microbe-public-summary"
            >
              <MicrobeSummarySection notes={microbe.notes} ecology={microbe.ecology} />
            </RecordSection>
            <Element name="Images">
              <Element name="Phenotype">
                <Element name="Downloads and references">
                  <Paywall />
                </Element>
              </Element>
            </Element>
          </RecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};
