import Axios from 'axios';
import React, { FC, SetStateAction, useMemo } from 'react';
import { ContextValue, useStorage } from 'services/localStorage/localStorage.factory';

type AuthTokenContextType = ContextValue<string | null>;

const setAxiosAuthorizationHeader = (value: string | null): void => {
  Axios.defaults.headers.common['Authorization'] = value ? `Bearer ${value}` : null;
};

export const AuthTokenContext = React.createContext<AuthTokenContextType>(undefined);
export const AuthTokenProvider: FC = ({ children }) => {
  const [token, setToken] = useStorage<string | null>(
    window.localStorage,
    'token',
    null,
    setAxiosAuthorizationHeader
  );

  const contextValue: AuthTokenContextType = useMemo(() => {
    const set = (value: SetStateAction<string | null>): void => {
      setAxiosAuthorizationHeader(typeof value === 'function' ? value(token) : value);

      setToken(value);
    };

    return [token, set];
  }, [setToken, token]);

  return <AuthTokenContext.Provider value={contextValue}>{children}</AuthTokenContext.Provider>;
};
