import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'VaccineDetails/SingleLoadInitiated': {
      return {
        ...state,
        vaccineDetails: { ...state.vaccineDetails, [action.payload.id]: 'Loading' },
      };
    }
    case 'VaccineDetails/SingleLoaded': {
      return {
        ...state,
        vaccineDetails: { ...state.vaccineDetails, [action.payload.id]: action.payload.data },
      };
    }
    case 'VaccineDetails/SingleLoadFailed': {
      return {
        ...state,
        vaccineDetails: { ...state.vaccineDetails, [action.payload.id]: action.payload.error },
      };
    }
    case 'VaccineDetails/Public/SingleLoadInitiated': {
      return {
        ...state,
        vaccineDetailsPublic: { ...state.vaccineDetailsPublic, [action.payload.id]: 'Loading' },
      };
    }
    case 'VaccineDetails/Public/SingleLoaded': {
      return {
        ...state,
        vaccineDetailsPublic: {
          ...state.vaccineDetailsPublic,
          [action.payload.id]: action.payload.data,
        },
      };
    }
    case 'VaccineDetails/Public/SingleLoadFailed': {
      return {
        ...state,
        vaccineDetailsPublic: {
          ...state.vaccineDetailsPublic,
          [action.payload.id]: action.payload.error,
        },
      };
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
