import { ReactComponent as CountriesIcon } from 'assets/ExploreFocus/Countries.svg';
import { ReactComponent as DiseasesIcon } from 'assets/ExploreFocus/Diseases.svg';
import { ReactComponent as DrugsIcon } from 'assets/ExploreFocus/Drugs.svg';
import { ReactComponent as MicrobesIcon } from 'assets/ExploreFocus/Microbes.svg';
import { ReactComponent as VaccinesIcon } from 'assets/ExploreFocus/Vaccines.svg';
import { ExploreFocusSection } from 'Molecules/exploreFocus/ExploreFocusSectionMobile';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: none;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
`;

export const MobileExploreFocus: FC = () => (
  <Wrapper>
    <ExploreFocusSection caption="Diseases" to="/explore/diseases" Icon={DiseasesIcon} />
    <ExploreFocusSection caption="Drugs" to="/explore/drugs" Icon={DrugsIcon} />
    <ExploreFocusSection caption="Vaccines" to="/explore/vaccines" Icon={VaccinesIcon} />
    <ExploreFocusSection caption="Microbes" to="/explore/microbes" Icon={MicrobesIcon} />
    <ExploreFocusSection caption="Countries" to="/explore/countries" Icon={CountriesIcon} />
  </Wrapper>
);
