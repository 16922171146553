import { Placement as PopperPlacement } from '@popperjs/core';
import { PopperTooltip } from 'Atoms/tooltip/Tooltip';
import { nanoid } from 'nanoid';
import { Popper } from 'Organisms/Popper';
import React, {
  Dispatch,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { useClickOutside } from './useClickOutside.hook';

type Event<T> = MouseEvent<T> | FocusEvent<T>;

interface ElementProps<T> {
  events: {
    onMouseOver?: (event?: MouseEvent<T>) => void;
    onMouseOut?: () => void;
    onClick?: (event?: MouseEvent<T>) => void;
    onKeyDown?: (e: KeyboardEvent<T>) => void;
  };
  aria: { 'aria-describedby': string };
}

interface TooltipOptions {
  position?: PopperPlacement;
  id?: string;
  eventElementSelector?: boolean;
}

type UseTooltip<T> = [ElementProps<T>, ReactElement | null, Dispatch<SetStateAction<T | null>>];

/**
 * useTooltip is used to show a tooltip attached to an element
 * @param content - what to show
 * @param options - optional configuration for tooltip
 * @returns events to attach to the element and aria tags for accessibility
 */
export const useTooltip = <T extends HTMLElement>(
  content: ReactNode | ((onClose: () => void) => ReactNode),
  options: TooltipOptions = { position: 'bottom' }
): UseTooltip<T> => {
  const [elementReference, setElementReference] = useState<T | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const onOpenTooltip = useCallback(
    (e?: Event<T>) => {
      setIsOpen(true);

      if (options.eventElementSelector && e) {
        setElementReference(e.currentTarget);
      }
    },
    [options.eventElementSelector]
  );
  const onCloseTooltip = useCallback(() => setIsOpen(false), []);
  const onToggleTooltip = useCallback(
    (e?: Event<T>) => (isOpen ? onCloseTooltip() : onOpenTooltip(e)),
    [isOpen, onCloseTooltip, onOpenTooltip]
  );

  const id = useMemo(() => options.id || nanoid(), [options.id]);

  const props: ElementProps<T> = useMemo(
    () => ({
      events: {
        onMouseOver: onOpenTooltip,
        onMouseOut: onCloseTooltip,
        onClick: onToggleTooltip,
      },
      aria: {
        'aria-describedby': id,
      },
    }),
    [onOpenTooltip, onCloseTooltip, onToggleTooltip, id]
  );

  const renderContent = useCallback(
    (): ReactNode => (typeof content === 'function' ? content(onCloseTooltip) : content),
    [content, onCloseTooltip]
  );

  const popper = useMemo(
    () => (
      <Popper
        referenceElement={elementReference}
        position={options.position}
        id={id}
        isOpen={isOpen}
      >
        <PopperTooltip position={options.position || 'bottom'}>{renderContent()}</PopperTooltip>
      </Popper>
    ),
    [elementReference, options.position, id, isOpen, renderContent]
  );

  useClickOutside(elementReference, onCloseTooltip);

  return [props, popper, setElementReference];
};
