import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { usePushState } from 'services/usePushState.hook';
import {
  isSearchFilterType,
  isSearchModuleName,
  SearchFilterType,
  SearchModuleName,
} from 'types/search';

interface SearchParams {
  q: string | null;
  type: SearchFilterType;
  module: SearchModuleName | null;
}

export const useSearchParams = (): [
  SearchParams,
  (searchQuery: string, type: SearchFilterType, module: SearchModuleName | null) => void
] => {
  const location = useLocation();
  const { push } = usePushState();

  const searchParams = useMemo(() => {
    const query = new URLSearchParams(location.search);

    const q = query.get('q');
    const type = query.get('type');
    const module = query.get('module');

    return {
      q,
      type: type && isSearchFilterType(type) ? type : 'all',
      module: module && isSearchModuleName(module) ? module : null,
    };
  }, [location.search]);

  const setSearchParams = useCallback(
    (searchQuery: string, type: SearchFilterType, module: SearchModuleName | null): void => {
      const query = new URLSearchParams({
        q: searchQuery,
        type,
      });

      // Extra clause is here to deselect when an already selected module is clicked
      if (module && module !== searchParams.module) {
        query.append('module', module);
      }

      push({ search: query.toString() });
    },
    [searchParams.module, push]
  );

  return [searchParams, setSearchParams];
};
