import { isStoreError } from 'store/storeError';
import { isLoading, Resource } from 'store/types';
import { CompareMicrobe, Probability } from 'types/compare';
import { Row } from 'types/table';

export const probabilities: Probability[] = [
  {
    name: 'Never',
    percentage: '0%',
    accent: 'Never',
    explanationText: 'Not reported',
    moreOrEqual: 0,
    lessThan: Number.EPSILON,
  },
  {
    name: 'Rarely',
    percentage: '1-9%',
    accent: 'Rarely',
    explanationText: 'Reported in 1-9% of isolates',
    moreOrEqual: Number.EPSILON,
    lessThan: 10,
  },
  {
    name: 'Often',
    percentage: '10-89%',
    accent: 'Often',
    explanationText: 'Reported in 10 - 89% of isolates',
    moreOrEqual: 10,
    lessThan: 90,
  },
  {
    name: 'Usually',
    percentage: '90-99%',
    accent: 'Usually',
    explanationText: 'Reported in 90-99% of isolates',
    moreOrEqual: 90,
    lessThan: 100,
  },
  {
    name: 'Always',
    percentage: '100%',
    accent: 'Always',
    explanationText: 'Reported in all isolates',
    moreOrEqual: 100,
    lessThan: Number.POSITIVE_INFINITY,
  },
  {
    accent: 'Missing',
    name: 'Missing test',
    percentage: 'Missing test',
    explanationText:
      'Phenotypic test for which there is no published data concerning the selected organism.',
    moreOrEqual: -1,
    lessThan: -1,
  },
];

export const getProbability = (probability: number): Probability | undefined => {
  const percent = Math.round(probability * 100 * 10000) / 10000;

  if (percent < 0) {
    return probabilities[5];
  }
  const probabilityData = probabilities.find(x => percent >= x.moreOrEqual && percent < x.lessThan);
  if (probabilityData) {
    return {
      ...probabilityData,
      percentage: `${percent}%`,
    };
  }
};

const getRow = (
  code: string,
  name: string,
  data: (CompareMicrobe | undefined)[],
  showMatches?: boolean,
  filterSameGroup?: boolean
): Row => {
  const probs = data.map(x => {
    const found = x && x.phenotypes.find(x => x.id === code);
    if (found) {
      return found.probability === null
        ? { value: -1, label: 'Missing test' }
        : { value: found.probability, label: found.probability.toString() };
    }
    return { value: '', label: '' };
  });
  if (probs.length === 0) return probs;

  if (!showMatches) {
    const allEqual = probs.every(x => x.value === probs[0].value);
    if (allEqual) return [];
  }

  if (!showMatches && filterSameGroup) {
    const firstGroup = getProbability(+probs[0].value)?.name;
    const allGroupsEqual = probs.every(x => getProbability(+x.value)?.name === firstGroup);
    if (allGroupsEqual) return [];
  }

  return [{ value: name, label: name }, ...probs];
};

export const getCompareRows = (
  data: Resource<CompareMicrobe>[],
  showMatches?: boolean,
  filterSameGroup?: boolean
): Row[] => {
  const loadedData = data.map(x => {
    if (!isStoreError(x) && !isLoading(x)) {
      return x;
    } else {
      return undefined;
    }
  });

  if (loadedData.length === 0) return [];
  const rows =
    loadedData[0] &&
    loadedData[0].phenotypes.map(x =>
      getRow(x.id, x.name, loadedData, showMatches, filterSameGroup)
    );

  return rows ? rows.filter(x => x.length > 0) : [];
};
