import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { DrugDetails, DrugDetailsPublic } from 'types/drug';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'DrugDetails/SingleLoadInitiated', { id: number }>
  | ActionWithPayload<'DrugDetails/SingleLoaded', { id: number; data: DrugDetails }>
  | ActionWithPayload<'DrugDetails/SingleLoadFailed', { id: number; error: StoreError }>
  | ActionWithPayload<'DrugDetails/Public/SingleLoadInitiated', { id: number }>
  | ActionWithPayload<'DrugDetails/Public/SingleLoaded', { id: number; data: DrugDetailsPublic }>
  | ActionWithPayload<'DrugDetails/Public/SingleLoadFailed', { id: number; error: StoreError }>;

export type State = {
  drugDetails: { [id: number]: Resource<DrugDetails> | undefined };
  drugDetailsPublic: { [id: number]: Resource<DrugDetailsPublic> | undefined };
};

const initialState: State = { drugDetails: {}, drugDetailsPublic: {} };

export const {
  dispatchContext: DrugDetailsDispatchContext,
  stateContext: DrugDetailsStateContext,
  provider: DrugDetailsStoreProvider,
} = storeFactory(reducer, initialState);
