import { History } from 'history';
import { useEffect } from 'react';

export const useSlugURLUpdate = (history: History, oldSlug: string, newSlug: string): void => {
  useEffect(() => {
    if (newSlug !== oldSlug) {
      history.replace({
        pathname: history.location.pathname.replace(oldSlug, newSlug),
        search: history.location.search,
        hash: history.location.hash,
        state: history.location.state,
      });
    }
  }, [history, oldSlug, newSlug]);
};
