import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { MicrobeDetails, MicrobeDetailsPublic } from 'types/microbeDetails';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'MicrobeDetails/SingleLoadInitiated', { id: number }>
  | ActionWithPayload<'MicrobeDetails/SingleLoaded', { id: number; data: MicrobeDetails }>
  | ActionWithPayload<'MicrobeDetails/SingleLoadFailed', { id: number; error: StoreError }>
  | ActionWithPayload<'MicrobeDetails/Public/SingleLoadInitiated', { id: number }>
  | ActionWithPayload<
      'MicrobeDetails/Public/SingleLoaded',
      { id: number; data: MicrobeDetailsPublic }
    >
  | ActionWithPayload<'MicrobeDetails/Public/SingleLoadFailed', { id: number; error: StoreError }>;

export type State = {
  details: { [id: number]: Resource<MicrobeDetails> | undefined };
  detailsPublic: { [id: number]: Resource<MicrobeDetailsPublic> | undefined };
};

const initialState: State = {
  details: {},
  detailsPublic: {},
};

export const {
  dispatchContext: MicrobeDetailsDispatchContext,
  stateContext: MicrobeDetailsStateContext,
  provider: MicrobeDetailsStoreProvider,
} = storeFactory(reducer, initialState);
