import { CopyrightText } from 'Molecules/CopyrightText';
import { FooterLinks } from 'Molecules/footer/FooterLinks';
import { LicenseAgreementModal } from 'Molecules/modal/LicenseAgreementModal';
import { PrivacyModal } from 'Molecules/modal/PrivacyModal';
import { TermsModal } from 'Molecules/modal/TermsModal';
import React, { FC } from 'react';
import { useAccountController } from 'store/accountStore/hooks';
import { useDispatch, useState } from 'store/legalModalStore/hooks';
import styled from 'styled-components/macro';

const FooterStyled = styled.footer`
  box-sizing: border-box;
  width: 100%;
  padding: 15px 35px;
  display: flex;
  align-items: center;
  background: ${props => props.theme.colors.background.footer};
  z-index: 1;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    padding: 15px 10px;
  }
`;

const FooterLinksStyled = styled(FooterLinks)`
  @media (min-width: ${props => props.theme.breakpoints.m}) {
    margin: 0 0 0 auto;
  }
`;

const FooterText = styled(CopyrightText)`
  @media (min-width: ${props => props.theme.breakpoints.m}) {
    margin-right: 20px;
    white-space: nowrap;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 10px;
  }
`;

export const Footer: FC = () => {
  const state = useState();
  const dispatch = useDispatch();

  const { tokenPayload } = useAccountController();
  const isInstitution = !!tokenPayload?.user.institutionId;

  return (
    <FooterStyled>
      <FooterText textAlign="center" />
      <FooterLinksStyled
        onTermsClick={() => dispatch({ type: 'LegalModal/TermsAndConditions', payload: true })}
        onLicenseClick={() => dispatch({ type: 'LegalModal/LicenseAgreement', payload: true })}
      />
      <TermsModal
        isOpen={state.termsAndConditionsModal}
        onClose={() => dispatch({ type: 'LegalModal/TermsAndConditions', payload: false })}
      />
      <LicenseAgreementModal
        isOpen={state.licenseAgreementModal}
        onClose={() => dispatch({ type: 'LegalModal/LicenseAgreement', payload: false })}
        isInstitution={isInstitution}
      />
      <PrivacyModal
        isOpen={state.privacyModal}
        onClose={() => dispatch({ type: 'LegalModal/Privacy', payload: false })}
      />
    </FooterStyled>
  );
};
