export interface HotjarFunction {
  (type: 'trigger', trigger: string): void;
  (...args: unknown[]): void;
}

declare global {
  interface Window {
    hj: HotjarFunction;
  }
}

export const hotjarTrggerSurvey = (): void => {
  if (process.env.REACT_APP_HOTJAR_ENABLE_SURVEY === 'true') {
    window.hj('trigger', 'survey');
  }
};
