import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon, SvgComponent } from 'Atoms/Icon';
import { Span } from 'Atoms/text/Span';
import { nanoid } from 'nanoid';
import React, {
  EventHandler,
  forwardRef,
  KeyboardEventHandler,
  MouseEvent,
  TouchEvent,
  useMemo,
  useState,
} from 'react';
import { useMobile } from 'services/useMobile';
import styled from 'styled-components/macro';

const MenuToolTip = styled(Span)`
  display: none;
  position: absolute;
  right: 0;
  cursor: pointer;
  z-index: 10;
  background-color: ${props => props.theme.colors.background.lightPopup};
  white-space: nowrap;
  padding: 5px;
  border-radius: 5px;
  top: 100%;
`;

const AccountMenuOptionStyled = styled(InvisibleButton)`
  display: flex;
  padding: 10px 16px 10px 14px;
  cursor: pointer;
  align-items: center;
  position: relative;

  &:hover {
    ${MenuToolTip} {
      display: block;
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${Icon} {
    height: 21px;
    width: 21px;
  }
`;

const NameContainer = styled(Span)`
  margin-left: 12px;
`;

const HiddenDescription = styled(Span)`
  display: none;
`;

interface Props {
  className?: string;
  icon: SvgComponent;
  label: string;
  onClick: EventHandler<MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>>;
  onKeyDown?: KeyboardEventHandler<HTMLButtonElement>;
  description?: string;
  tooltip?: string;
  onToolTipClick?: () => void;
}

export const AccountMenuOption = forwardRef<HTMLButtonElement, Props>(
  ({ className, icon, label, onClick, onKeyDown, description, tooltip, onToolTipClick }, ref) => {
    const id = useMemo(() => (description ? nanoid() : undefined), [description]);
    const isMobile = useMobile('m');
    const [mobileItemClicked, setMobileItemClicked] = useState(false);

    const isIOS = /iPad|iPhone|iPod/.test(navigator.platform);

    const renderContent = (
      <>
        <IconContainer>
          <Icon svgComponent={icon} fill="inactive" />
        </IconContainer>
        <NameContainer color="inactive" weight="500" size="smallStatic">
          {label}
        </NameContainer>
        {description && <HiddenDescription id={id}>{description}</HiddenDescription>}
        {tooltip && (
          <MenuToolTip
            onClick={() => {
              if (isMobile && onToolTipClick) onToolTipClick();
            }}
          >
            {tooltip}
          </MenuToolTip>
        )}
      </>
    );

    return isIOS ? (
      <AccountMenuOptionStyled
        className={className}
        onTouchEnd={e => {
          if (tooltip && tooltip.length > 0 && isMobile) {
            if (!mobileItemClicked) {
              e.stopPropagation();
            } else {
              onClick(e);
            }
            setMobileItemClicked(!mobileItemClicked);
          } else {
            onClick(e);
          }
        }}
        ref={ref}
        onKeyDown={onKeyDown}
        aria-describedby={id}
      >
        {renderContent}
      </AccountMenuOptionStyled>
    ) : (
      <AccountMenuOptionStyled
        className={className}
        onClick={e => {
          if (tooltip && tooltip.length > 0 && isMobile) {
            if (!mobileItemClicked) {
              e.stopPropagation();
            } else {
              onClick(e);
            }
            setMobileItemClicked(!mobileItemClicked);
          } else {
            onClick(e);
          }
        }}
        ref={ref}
        onKeyDown={onKeyDown}
        aria-describedby={id}
      >
        {renderContent}
      </AccountMenuOptionStyled>
    );
  }
);
