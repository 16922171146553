import { ButtonLabel } from 'Atoms/buttons/ButtonLabel';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

interface Props {
  className?: string;
  onClick?(): void;
  Icon: FC;
}

const ButtonStyled = styled(FilledButton)`
  border-width: 2px;
  width: 230px;
`;

export const SearchByTypeButton: FC<Props> = ({
  className,
  onClick,
  children,
  Icon: IconComponent,
}) => {
  return (
    <ButtonStyled onClick={onClick} className={className}>
      <Icon svgComponent={IconComponent} size="small" />
      <ButtonLabel>{children}</ButtonLabel>
    </ButtonStyled>
  );
};
