import {
  getDiseasesSimpleFilter,
} from 'apiServices/Diseases/diseases';
import React, { useEffect, useMemo } from 'react';
import { useAccountController } from 'store/accountStore/hooks';
import { assertIsNotStoreError, newStoreError } from 'store/storeError';
import { Dispatch, isLoading, Loading, Resource } from 'store/types';
import { GroupedData } from 'types/az';
import { SimpleDiseasesList } from 'types/simpleDisease';
import { groupDataAlphabetically } from 'utils/getGroupedData';

import { Action, DiseasesGroupDispatchContext, DiseasesGroupStateContext, State } from './provider';

export const useState = (): State => {
  const state = React.useContext(DiseasesGroupStateContext);
  if (state === undefined) {
    throw new Error('diseases group state is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(DiseasesGroupDispatchContext);
  if (dispatch === undefined) {
    throw new Error('diseases group state is not initialized');
  }
  return dispatch;
};

const fetchAllDiseases = (
  dispatch: Dispatch<Action>,
  state: State,
  isAuthenticated: boolean
): void => {
  if (!state['algal']) {
    dispatch({ type: 'DiseasesGroup/LoadInitiated', payload: { type: 'algal' } });
    getDiseasesSimpleFilter('algal', !isAuthenticated)
      .then(data =>
        dispatch({ type: 'DiseasesGroup/Loaded', payload: { type: 'algal', data: data } })
      )
      .catch(err =>
        dispatch({
          type: 'DiseasesGroup/LoadFailed',
          payload: { type: 'algal', data: newStoreError(err.message, err.code, err) },
        })
      );
  }
  if (!state['bacterial']) {
    dispatch({ type: 'DiseasesGroup/LoadInitiated', payload: { type: 'bacterial' } });
    getDiseasesSimpleFilter('bacterial', !isAuthenticated)
      .then(data =>
        dispatch({ type: 'DiseasesGroup/Loaded', payload: { type: 'bacterial', data: data } })
      )
      .catch(err =>
        dispatch({
          type: 'DiseasesGroup/LoadFailed',
          payload: { type: 'bacterial', data: newStoreError(err.message, err.code, err) },
        })
      );
  }
  if (!state['fungal']) {
    dispatch({ type: 'DiseasesGroup/LoadInitiated', payload: { type: 'fungal' } });
    getDiseasesSimpleFilter('fungal', !isAuthenticated)
      .then(data =>
        dispatch({ type: 'DiseasesGroup/Loaded', payload: { type: 'fungal', data: data } })
      )
      .catch(err =>
        dispatch({
          type: 'DiseasesGroup/LoadFailed',
          payload: { type: 'fungal', data: newStoreError(err.message, err.code, err) },
        })
      );
  }
  if (!state['parasitic']) {
    dispatch({ type: 'DiseasesGroup/LoadInitiated', payload: { type: 'parasitic' } });
    getDiseasesSimpleFilter('parasitic', !isAuthenticated)
      .then(data =>
        dispatch({ type: 'DiseasesGroup/Loaded', payload: { type: 'parasitic', data: data } })
      )
      .catch(err =>
        dispatch({
          type: 'DiseasesGroup/LoadFailed',
          payload: { type: 'parasitic', data: newStoreError(err.message, err.code, err) },
        })
      );
  }
  if (!state['prion']) {
    dispatch({ type: 'DiseasesGroup/LoadInitiated', payload: { type: 'prion' } });
    getDiseasesSimpleFilter('prion', !isAuthenticated)
      .then(data =>
        dispatch({ type: 'DiseasesGroup/Loaded', payload: { type: 'prion', data: data } })
      )
      .catch(err =>
        dispatch({
          type: 'DiseasesGroup/LoadFailed',
          payload: { type: 'prion', data: newStoreError(err.message, err.code, err) },
        })
      );
  }
  if (!state['protoctistal']) {
    dispatch({ type: 'DiseasesGroup/LoadInitiated', payload: { type: 'protoctistal' } });
    getDiseasesSimpleFilter('protoctistal', !isAuthenticated)
      .then(data =>
        dispatch({ type: 'DiseasesGroup/Loaded', payload: { type: 'protoctistal', data: data } })
      )
      .catch(err =>
        dispatch({
          type: 'DiseasesGroup/LoadFailed',
          payload: { type: 'protoctistal', data: newStoreError(err.message, err.code, err) },
        })
      );
  }
  if (!state['viral']) {
    dispatch({ type: 'DiseasesGroup/LoadInitiated', payload: { type: 'viral' } });
    getDiseasesSimpleFilter('viral', !isAuthenticated)
      .then(data =>
        dispatch({ type: 'DiseasesGroup/Loaded', payload: { type: 'viral', data: data } })
      )
      .catch(err =>
        dispatch({
          type: 'DiseasesGroup/LoadFailed',
          payload: { type: 'viral', data: newStoreError(err.message, err.code, err) },
        })
      );
  }
  if (!state['unknown-agent']) {
    dispatch({ type: 'DiseasesGroup/LoadInitiated', payload: { type: 'unknown-agent' } });
    getDiseasesSimpleFilter('unknown-agent', !isAuthenticated)
      .then(data =>
        dispatch({ type: 'DiseasesGroup/Loaded', payload: { type: 'unknown-agent', data: data } })
      )
      .catch(err =>
        dispatch({
          type: 'DiseasesGroup/LoadFailed',
          payload: { type: 'unknown-agent', data: newStoreError(err.message, err.code, err) },
        })
      );
  }
};

export const useDiseasesGroupResource = (): Resource<SimpleDiseasesList> => {
  const state = useState();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAccountController();

  useEffect(() => {
    fetchAllDiseases(dispatch, state, isAuthenticated);
  }, [state, dispatch, isAuthenticated]);

  if (
    isLoading(state["algal"]) ||
    isLoading(state["bacterial"]) ||
    isLoading(state["fungal"]) ||
    isLoading(state["parasitic"]) ||
    isLoading(state["prion"]) ||
    isLoading(state["protoctistal"]) ||
    isLoading(state["viral"]) ||
    isLoading(state["unknown-agent"]) ||
    !state["algal"] ||
    !state["bacterial"] ||
    !state["fungal"] ||
    !state["parasitic"] ||
    !state["prion"] ||
    !state["protoctistal"] ||
    !state["viral"] ||
    !state["unknown-agent"]
  ) {
    return Loading;
  }

  assertIsNotStoreError(state["algal"]);
  assertIsNotStoreError(state["bacterial"]);
  assertIsNotStoreError(state["fungal"]);
  assertIsNotStoreError(state["parasitic"]);
  assertIsNotStoreError(state["prion"]);
  assertIsNotStoreError(state["protoctistal"]);
  assertIsNotStoreError(state["viral"]);
  assertIsNotStoreError(state["unknown-agent"]);

  return {
    algal: state["algal"].slice().sort((lhs, rhs) => lhs.name.localeCompare(rhs.name)),
    bacterial: state["bacterial"].slice().sort((lhs, rhs) => lhs.name.localeCompare(rhs.name)),
    fungal: state["fungal"].slice().sort((lhs, rhs) => lhs.name.localeCompare(rhs.name)),
    parasitic: state["parasitic"].slice().sort((lhs, rhs) => lhs.name.localeCompare(rhs.name)),
    prion: state["prion"].slice().sort((lhs, rhs) => lhs.name.localeCompare(rhs.name)),
    protoctistal: state["protoctistal"].slice().sort((lhs, rhs) => lhs.name.localeCompare(rhs.name)),
    viral: state["viral"].slice().sort((lhs, rhs) => lhs.name.localeCompare(rhs.name)),
    'unknown-agent': state["unknown-agent"].slice().sort((lhs, rhs) => lhs.name.localeCompare(rhs.name)),
  };
};

const emptyObject = { data: groupDataAlphabetically([]), total: 0 };

interface GroupedDiseases {
  algal: GroupedData;
  bacterial: GroupedData;
  fungal: GroupedData;
  parasitic: GroupedData;
  prion: GroupedData;
  protoctistal: GroupedData;
  viral: GroupedData;
  'unknown-agent': GroupedData;
}

export const useDiseasesCategoryGrouped = (): GroupedDiseases => {
  const diseaseGroup = useDiseasesGroupResource();

  return useMemo(() => {
    assertIsNotStoreError(diseaseGroup);
    if (isLoading(diseaseGroup)) {
      return {
        algal: emptyObject,
        bacterial: emptyObject,
        fungal: emptyObject,
        parasitic: emptyObject,
        prion: emptyObject,
        protoctistal: emptyObject,
        viral: emptyObject,
        'unknown-agent': emptyObject
      };
    } else {
      return {
        algal: {
          data: groupDataAlphabetically(diseaseGroup.algal),
          total: diseaseGroup.algal.length,
        },
        bacterial: {
          data: groupDataAlphabetically(diseaseGroup.bacterial),
          total: diseaseGroup.bacterial.length,
        },
        fungal: {
          data: groupDataAlphabetically(diseaseGroup.fungal),
          total: diseaseGroup.fungal.length,
        },
        parasitic: {
          data: groupDataAlphabetically(diseaseGroup.parasitic),
          total: diseaseGroup.parasitic.length,
        },
        prion: {
          data: groupDataAlphabetically(diseaseGroup.prion),
          total: diseaseGroup.prion.length,
        },
        protoctistal: {
          data: groupDataAlphabetically(diseaseGroup.protoctistal),
          total: diseaseGroup.protoctistal.length,
        },
        viral: {
          data: groupDataAlphabetically(diseaseGroup.viral),
          total: diseaseGroup.viral.length,
        },
        'unknown-agent': {
          data: groupDataAlphabetically(diseaseGroup['unknown-agent']),
          total: diseaseGroup['unknown-agent'].length,
        },
      };
    }
  }, [diseaseGroup]);
};