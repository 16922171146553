import { P } from 'Atoms/text';
import { Props as PProps } from 'Atoms/text/P';
import { AZList } from 'Molecules/az/AZList';
import { AzMicrobesButtons } from 'Molecules/az/AZMicrobesButtons';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { match, Route, Switch } from 'react-router-dom';
import { useMicrobesGrouped } from 'store/microbesStore/hooks';
import styled from 'styled-components/macro';
import { microbeItems, microbeNames } from 'types/az';
import { formatNullableNumber } from 'utils/formatNumber';

const Paragraph = styled(P).attrs(
  (): Partial<PProps> => ({
    size: 'big',
  })
)``;

const CaptionWithMargin = styled(P).attrs(
  (): Partial<PProps> => ({
    size: 'big',
  })
)`
  margin-top: 10px;
`;

const ResultWrapper = styled.div`
  position: relative;
`;

interface Props {
  match: match;
  title?: string;
}

export const AZMicrobesDesktop: FC<Props> = ({ match, title }) => {
  const groupedMicrobes = useMicrobesGrouped();
  const { bacteria, mycobacteria, yeasts, viruses } = groupedMicrobes;

  return (
    <>
      <Helmet>
        <title>A-Z {title} - GIDEON</title>
      </Helmet>
      <Paragraph>
        GIDEON database contains <strong>{formatNullableNumber(bacteria.total)}</strong> bacteria,{' '}
        <strong>{mycobacteria.total}</strong> mycobacteria, <strong>{viruses.total}</strong> viruses
        and <strong>{yeasts.total}</strong> yeasts and algae.
      </Paragraph>
      <CaptionWithMargin>Select category to explore.</CaptionWithMargin>
      <AzMicrobesButtons basePath={match.url} />
      <ResultWrapper>
        <Switch>
          {microbeItems.map(m => (
            <Route
              key={m}
              path={`${match.url}/${m}`}
              render={({ match: newMatch }) => (
                <AZList
                  data={groupedMicrobes[m]}
                  category={m}
                  match={newMatch}
                  title={microbeNames[m]}
                />
              )}
            />
          ))}
        </Switch>
      </ResultWrapper>
    </>
  );
};
