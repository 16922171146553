import { DisableAnimationsGlobalStyle } from 'globalStyle';
import React, { createContext, FC, useEffect, useMemo, useState } from 'react';
import { Globals } from 'react-spring';

const mockedMediaQueryList = {
  matches: false,
  addEventListener: () => void 0,
  removeEventListener: () => void 0,
};

const reduceMotionLocalStorageKey = 'reduce-motion';
const reduceMotionPreference = window
  ? window.localStorage.getItem(reduceMotionLocalStorageKey)
  : null;

const mediaQuery = window
  ? window.matchMedia('(prefers-reduced-motion: reduce)')
  : mockedMediaQueryList;

interface MotionContextValue {
  isMotionReduced: boolean;
  setIsMotionReduced: (value: boolean) => void;
}

const getInitialState = (): boolean => {
  if (reduceMotionPreference) {
    return reduceMotionPreference === 'true';
  }

  return mediaQuery.matches;
};

export const MotionContext = createContext<MotionContextValue | undefined>(undefined);

export const MotionProvider: FC = ({ children }) => {
  const [isMotionReduced, setIsMotionReduced] = useState(getInitialState());

  const onReduceMotion = (event: Event): void => {
    setIsMotionReduced((event.target as MediaQueryList).matches);
  };

  useEffect(() => {
    if (!reduceMotionPreference) {
      mediaQuery.addEventListener('change', onReduceMotion);
      return () => {
        mediaQuery.removeEventListener('change', onReduceMotion);
      };
    }
  });

  useEffect(() => {
    Globals.assign({ skipAnimation: isMotionReduced });
  }, [isMotionReduced]);

  const contextValue = useMemo(
    () => ({
      isMotionReduced,
      setIsMotionReduced: (value: boolean) => {
        window && window.localStorage.setItem(reduceMotionLocalStorageKey, value.toString());
        setIsMotionReduced(value);
      },
    }),
    [isMotionReduced]
  );

  return (
    <MotionContext.Provider value={contextValue}>
      {isMotionReduced && <DisableAnimationsGlobalStyle />}
      {children}
    </MotionContext.Provider>
  );
};
