import { isStoreError } from 'store/storeError';
import { isLoading, Resource } from 'store/types';
import { CompareDisease, Probability } from 'types/compare';
import { Row } from 'types/table';

export const probabilities: Probability[] = [
  {
    name: 'Never',
    percentage: '0%',
    accent: 'Never',
    explanationText: 'Not reported',
    moreOrEqual: 0,
    lessThan: Number.EPSILON,
  },
  {
    name: 'Rarely',
    percentage: '0.01-19%',
    accent: 'Rarely',
    explanationText: 'Reported in 0.01-19% of all cases',
    moreOrEqual: Number.EPSILON,
    lessThan: 20,
  },
  {
    name: 'Often',
    percentage: '20-79%',
    accent: 'Often',
    explanationText: 'Reported in 20 - 79% of all cases',
    moreOrEqual: 20,
    lessThan: 80,
  },
  {
    name: 'Usually',
    percentage: '80-99%',
    accent: 'Usually',
    explanationText: 'Reported in 80-99% of all cases',
    moreOrEqual: 80,
    lessThan: 100,
  },
  {
    name: 'Always',
    percentage: '100%',
    accent: 'Always',
    explanationText: 'Reported in all cases',
    moreOrEqual: 100,
    lessThan: Number.POSITIVE_INFINITY,
  },
];

export const getProbability = (probability: number): Probability | undefined => {
  const percent = Math.round(probability * 100 * 10000) / 10000;
  const probabilityData = probabilities.find(x => percent >= x.moreOrEqual && percent < x.lessThan);
  if (probabilityData) {
    return {
      ...probabilityData,
      percentage: `${percent}%`,
    };
  }
};

const getRow = (
  symptomCode: string,
  symptomName: string,
  data: (CompareDisease | undefined)[],
  showMatches?: boolean,
  filterSameGroup?: boolean
): Row => {
  const probs = data.map(x => {
    const found = x && x.symptoms.find(x => x.id === symptomCode);
    return found
      ? { value: found.probability, label: found.probability.toString() }
      : { value: '', label: '' };
  });

  if (probs.filter(x => x.value > 0).length === 0) return [];

  if (!showMatches) {
    const allEqual = probs.every(x => x.value === probs[0].value);
    if (allEqual) return [];
  }

  if (!showMatches && filterSameGroup) {
    const firstGroup = getProbability(+probs[0].value)?.name;
    const allGroupsEqual = probs.every(x => getProbability(+x.value)?.name === firstGroup);
    if (allGroupsEqual) return [];
  }

  return [{ value: symptomName, label: symptomName }, ...probs];
};

export const getCompareRows = (
  data: Resource<CompareDisease>[],
  showMatches?: boolean,
  filterSameGroup?: boolean
): Row[] => {
  const loadedData = data.map(x => {
    if (!isStoreError(x) && !isLoading(x)) {
      return x;
    } else return undefined;
  });

  if (loadedData.length === 0) return [];

  const rows =
    loadedData[0] &&
    loadedData[0].symptoms.map(x => getRow(x.id, x.name, loadedData, showMatches, filterSameGroup));
  return rows ? rows.filter(x => x.length > 0) : [];
};
