import { Loading } from 'store/types';

import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'DiseasesGroup/LoadInitiated': {
      return { ...state, [action.payload.type]: Loading };
    }
    case 'DiseasesGroup/Loaded': {
      return {
        ...state,
        [action.payload.type]: action.payload.data,
      };
    }
    case 'DiseasesGroup/LoadFailed': {
      return { ...state, [action.payload.type]: action.payload };
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
