import { VariableAuthRoute } from 'Atoms/routes/VariableAuthRoute';
import { NotFound } from 'pages/404/';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { CountriesRoutes } from './Countries';
import { DiseasesRoutes } from './Diseases';
import { DrugsRoutes } from './Drugs';
import { Home as ExploreHome } from './Home';
import { MicrobesRoutes } from './Microbes';
import { VaccinesRoutes } from './Vaccines';

export const Explore: FC = () => (
  <Switch>
    <VariableAuthRoute
      exact
      path="/explore"
      render={() => <ExploreHome />}
      renderPublic={() => <ExploreHome hideDefaultPageOption />}
    />
    <Route path="/explore/countries" component={CountriesRoutes} />
    <Route path="/explore/diseases" component={DiseasesRoutes} />
    <Route path="/explore/drugs" component={DrugsRoutes} />
    <Route path="/explore/vaccines" component={VaccinesRoutes} />
    <Route path="/explore/microbes" component={MicrobesRoutes} />
    <Route component={NotFound} />
  </Switch>
);
