import React from 'react';
import { Dispatch } from 'store/types';

import { Action, LegalModalDispatchContext, LegalModalStateContext, State } from './provider';

export const useState = (): State => {
  const state = React.useContext(LegalModalStateContext);
  if (state === undefined) {
    throw new Error('Legal modal store is not initialized');
  }

  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(LegalModalDispatchContext);
  if (dispatch === undefined) {
    throw new Error('Legal modal store is not initialized');
  }

  return dispatch;
};
