import { ReactComponent as ScientistSVG } from 'assets/UI/Scientist.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { Loader } from 'Atoms/Loader';
import { Span } from 'Atoms/text';
import { sendPasswordResetEmail } from 'Auth/apiServices/account';
import { FormInput } from 'Auth/Molecules/FormInput';
import { FormMessage } from 'Auth/Molecules/FormMessage';
import { ContentContainer, ErrorRow, Row, Title } from 'Auth/Molecules/Styles';
import { emailRegex } from 'Auth/services/validation';
import { MainLayout } from 'layouts/MainLayout';
import React, { FC, FormEvent, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';

const Layout = styled(MainLayout)`
  padding: 50px 0px;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 40px 0;
  }
`;

const FormContainer = styled.form`
  width: 450px;
  height: 100%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  margin: 40px 0;

  display: flex;
  flex-direction: column;
`;

const Text = styled(Span)`
  text-align: center;
`;

const BottomText = styled(Text)`
  margin: 20px 0;
`;

const SpecialButton = styled(FilledButton)`
  width: 450px;
  height: 44px;

  margin: 25px 0 0 0;
`;

const IconStyled = styled(Icon)`
  width: 65px;
  height: 65px;

  margin: 40px 0 0 0;
`;

export const PasswordHome: FC = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!email) {
      setEmailError('Email is required');
      return;
    } else {
      const emailIsValid = email.match(emailRegex);

      if (!emailIsValid) {
        setEmailError('Email is not valid');
        return;
      }
    }

    setIsLoading(true);

    sendPasswordResetEmail(email)
      .then(() => setIsSuccess(true))
      .catch(() => setEmailError('Server error'))
      .finally(() => setIsLoading(false));
  };

  return (
    <Layout>
      <Helmet>
        <title>Forgot password - GIDEON</title>
      </Helmet>
      {isSuccess && email ? (
        <>
          <Title color="lightLink" weight="600" size="big" font="Inter">
            Check your email
          </Title>
          <ContentContainer>
            <IconStyled svgComponent={ScientistSVG} />
            <TextContainer>
              <Text>
                Check your inbox for an email with instructions on how to reset your password.
              </Text>
              <BottomText>
                We’ve sent it to <Span weight="700">{email}</Span>
              </BottomText>
            </TextContainer>
          </ContentContainer>
        </>
      ) : (
        <>
          <Title color="lightLink" weight="600" size="big" font="Inter">
            Forgot your password?
          </Title>
          <ContentContainer>
            <TextContainer>
              <Text>Please enter your e-mail address and press &ldquo;Reset Password&rdquo;.</Text>
              <BottomText>
                We will send you an email with instruction to reset your password.
              </BottomText>
            </TextContainer>
            <FormContainer onSubmit={onSubmit}>
              <Row>
                <FormInput
                  id="password-email"
                  label="Enter your email address below:"
                  onChange={value => {
                    setEmailError(null);
                    setEmail(value);
                  }}
                  value={email}
                  type="email"
                  error={!!emailError}
                  autoComplete="email"
                />
              </Row>
              <ErrorRow>
                <FormMessage type="error" message={emailError} />
              </ErrorRow>
              <SpecialButton disabled={isLoading} color="special">
                {isLoading ? <Loader /> : 'Reset password'}
              </SpecialButton>
            </FormContainer>
          </ContentContainer>
        </>
      )}
    </Layout>
  );
};
