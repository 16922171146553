import { css } from 'styled-components/macro';

export interface DatePickerProps {
  focused: boolean | null;
  disabled?: boolean;
  hasError?: boolean;
}

export const DatePickerStyles = css<DatePickerProps>`
  border: 1px solid
    ${props =>
      props.focused
        ? props.theme.colors.datepicker.focus
        : props.hasError
        ? props.theme.colors.text.error
        : props.theme.colors.datepicker.border};
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 15px 0 10px;
  cursor: ${props => (props.disabled ? 'initial' : 'text')};
  opacity: ${props => (props.disabled ? 0.4 : 1)};

  transition: all 0.1s ease-in;

  /* Input */
  .SingleDatePicker {
    flex-grow: 1;
    padding: 5px 0;
  }
  .SingleDatePickerInput__withBorder {
    border: none;
    background: none;
  }
  .DateInput_input__focused {
    border-color: transparent;
  }
  .DateInput {
    background: none;
    width: 100%;
  }
  .DateInput_input {
    background: none;
    color: ${props => props.theme.colors.datepicker.text};

    &::placeholder {
      color: ${props => props.theme.colors.datepicker.placeholder};
    }
  }

  /* Calendar */
  .SingleDatePicker_picker {
    z-index: 110;
    margin-bottom: 20px;
    border: 1px solid ${props => props.theme.colors.datepicker.calendar.border};

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      left: -10px !important;
    }
  }

  .CalendarMonth_caption {
    padding-top: 19px;
  }

  /* Background */
  .DayPicker_focusRegion {
    background: ${props => props.theme.colors.datepicker.calendar.background};
  }

  .CalendarMonthGrid {
    z-index: 2;
    background: transparent;
  }

  .CalendarMonth {
    background: transparent;
  }

  .DayPickerNavigation {
    z-index: 3;
  }

  /* Calendar width bug fix */
  .DayPicker__withBorder {
    width: 318px !important;
  }

  /* Arrow */
  .DateInput_fangShape {
    fill: ${props => props.theme.colors.datepicker.text};
  }

  .DateInput_fangStroke {
    stroke: ${props => props.theme.colors.datepicker.text};
  }

  /* Top text */
  .CalendarMonth_caption {
    color: ${props => props.theme.colors.text.main};
  }

  /* Arrow buttons */
  .DayPickerNavigation_button__horizontalDefault {
    background: ${props => props.theme.colors.datepicker.calendar.arrowButtonBackground};
    &:hover {
      background: ${props => props.theme.colors.datepicker.calendar.hoverBackground};
    }
  }

  .DayPickerNavigation_svg__horizontal {
    fill: ${props => props.theme.colors.text.main};
  }

  /* Week days */
  .DayPicker_weekHeader_ul {
    border-radius: 4px;
  }

  .DayPicker_weekHeader_li {
    color: ${props => props.theme.colors.text.main};
    font-weight: 700;
  }

  /* Days */
  .CalendarMonth_table {
    margin-top: 4px;
  }

  .CalendarDay__default {
    background: ${props => props.theme.colors.datepicker.calendar.background};
    color: ${props => props.theme.colors.text.main};
    &:hover {
      background: ${props => props.theme.colors.datepicker.calendar.hoverBackground};
    }
  }

  .CalendarDay__blocked_out_of_range {
    opacity: 0.3;
    &:hover {
      background: ${props => props.theme.colors.datepicker.calendar.background};
    }
  }

  /* Shortcuts window */
  .DayPickerKeyboardShortcuts_panel {
    background: ${props => props.theme.colors.datepicker.calendar.shortcuts.background};
    color: ${props => props.theme.colors.datepicker.calendar.shortcuts.text};
  }
`;
