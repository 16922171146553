interface UpdatedFieldDTO {
  sub_module?: string;
  status: string;
  timestamp: number;
}

export interface UpdatedDiseaseDTO extends UpdatedFieldDTO {
  module: 'diseases';
  disease: string;
  disease_code: string;
  country?: string;
  country_code?: string;
}

export interface UpdatedDrugDTO extends UpdatedFieldDTO {
  module: 'drugs';
  drug: string;
  drug_code: string;
}

export interface UpdatedVaccineDTO extends UpdatedFieldDTO {
  module: 'vaccines';
  vaccine: string;
  vaccine_code: string;
}

export interface UpdatedBacteriaDTO extends UpdatedFieldDTO {
  module: 'bacteria';
  bacteria: string;
  bacteria_code: string;
}

export interface UpdatedMycobacteriaDTO extends UpdatedFieldDTO {
  module: 'mycobacteria';
  mycobacteria: string;
  mycobacteria_code: string;
}

export interface UpdatedYeastsDTO extends UpdatedFieldDTO {
  module: 'yeasts';
  yeast: string;
  yeast_code: string;
}
export interface UpdatedVirusDTO extends UpdatedFieldDTO {
  module: 'virus';
  virus: string;
  virus_code: string;
}

export interface UpdatesDataDTOWithPagination<T> {
  data: T;
  total: number;
  offset: number;
  limit: number;
  updates: number;
}

export type UpdatedDTO =
  | UpdatedDiseaseDTO
  | UpdatedDrugDTO
  | UpdatedVaccineDTO
  | UpdatedBacteriaDTO
  | UpdatedMycobacteriaDTO
  | UpdatedYeastsDTO
  | UpdatedVirusDTO;

export const isUpdatedDiseaseDTO = (value: UpdatedDTO): value is UpdatedDiseaseDTO =>
  'disease' in value &&
  typeof value.disease === 'string' &&
  'disease_code' in value &&
  typeof value.disease_code === 'number';

export const isUpdatedDrugDTO = (value: UpdatedDTO): value is UpdatedDrugDTO =>
  'drug' in value &&
  typeof value.drug === 'string' &&
  'drug_code' in value &&
  typeof value.drug_code === 'number';

export const isUpdatedVaccineDTO = (value: UpdatedDTO): value is UpdatedVaccineDTO =>
  'vaccine' in value &&
  typeof value.vaccine === 'string' &&
  'vaccine_code' in value &&
  typeof value.vaccine_code === 'number';

export const isUpdatedBacteriaDTO = (value: UpdatedDTO): value is UpdatedBacteriaDTO =>
  'bacteria' in value &&
  typeof value.bacteria === 'string' &&
  'bacteria_code' in value &&
  typeof value.bacteria_code === 'number';

export const isUpdatedMycobacteriaDTO = (value: UpdatedDTO): value is UpdatedMycobacteriaDTO =>
  'mycobacteria' in value &&
  typeof value.mycobacteria === 'string' &&
  'mycobacteria_code' in value &&
  typeof value.mycobacteria_code === 'number';

export const isUpdatedYeastsDTO = (value: UpdatedDTO): value is UpdatedYeastsDTO =>
  'yeast' in value &&
  typeof value.yeast === 'string' &&
  'yeast_code' in value &&
  typeof value.yeast_code === 'number';
  
export const isUpdatedVirusDTO = (value: UpdatedDTO): value is UpdatedVirusDTO =>
  'virus' in value &&
  typeof value.virus === 'string' &&
  'virus_code' in value &&
  typeof value.virus_code === 'number';
