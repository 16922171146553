import { Loading } from 'store/types';

import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'Drugs/LoadInitiated': {
      return {
        ...state,
        drugs: Loading,
      };
    }
    case 'Drugs/Loaded':
    case 'Drugs/LoadFailed': {
      return {
        ...state,
        drugs: action.payload,
      };
    }
    case 'Drugs/Toxicity/LoadInitiated': {
      return {
        ...state,
        toxicities: Loading,
      };
    }
    case 'Drugs/Toxicity/Loaded':
    case 'Drugs/Toxicity/LoadFailed': {
      return {
        ...state,
        toxicities: action.payload,
      };
    }

    case 'Drugs/Interaction/LoadInitiated': {
      return {
        ...state,
        interactions: Loading,
      };
    }
    case 'Drugs/Interaction/Loaded':
    case 'Drugs/Interaction/LoadFailed': {
      return {
        ...state,
        interactions: action.payload,
      };
    }

    case 'Drugs/Classes/LoadInitiated': {
      return {
        ...state,
        classes: Loading,
      };
    }
    case 'Drugs/Classes/Loaded':
    case 'Drugs/Classes/LoadFailed': {
      return {
        ...state,
        classes: action.payload,
      };
    }

    case 'Drugs/Spectra/LoadInitiated': {
      return {
        ...state,
        spectra: Loading,
      };
    }
    case 'Drugs/Spectra/Loaded':
    case 'Drugs/Spectra/LoadFailed': {
      return {
        ...state,
        spectra: action.payload,
      };
    }

    case 'Drugs/Filter/LoadInitiated': {
      return { ...state, filteredDrugs: Loading };
    }
    case 'Drugs/Filter/Loaded':
    case 'Drugs/Filter/LoadFailed': {
      return {
        ...state,
        filteredDrugs: action.payload,
      };
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
