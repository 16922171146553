import { ReactComponent as TimeOut } from 'assets/UI/Time icon.svg';
import { contentClassNames, Modal } from 'Atoms/Modal';
import { DeepLinkRedirectState } from 'Atoms/routes/AuthRoute';
import React, { createContext, FC, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useContextAssert } from 'services/useContextAssert.hook';
import { useAccountController } from 'store/accountStore/hooks';
import styled from 'styled-components/macro';

import { Container, StyledCloseButton, Title } from './idleTimer.provider';
import { MagicTokenContext } from './MagicToken.provider';

const StyledModal = styled(Modal)`
  .${contentClassNames.base} {
    max-width: 528px;
  }
`;

type ModalContextProp = {
  isOpen: boolean;
  setOpen: (value: boolean, link?: string) => void;
};

export const ModalContext = createContext<ModalContextProp | undefined>(undefined);

export const ModalContextProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [magicToken, setMagicToken] = useContextAssert(MagicTokenContext);
  const location = useLocation<DeepLinkRedirectState | undefined>();

  useLayoutEffect(() => {
    if (magicToken == 'Expired') {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [magicToken, location.pathname, setMagicToken]);

  const setOpen = (value: boolean): void => {
    setIsOpen(value);
  };

  return <ModalContext.Provider value={{ isOpen, setOpen }}>{children}</ModalContext.Provider>;
};

export const LinkExpiredModal: FC = () => {
  const { isOpen, setOpen } = useContextAssert(ModalContext);
  const { setMagicToken } = useAccountController();

  const handleCloseModal = () => {
    setMagicToken(null);
    setOpen(false);
  };

  return (
    <StyledModal isOpen={isOpen} onClose={handleCloseModal}>
      <StyledCloseButton onClick={handleCloseModal} />
      <Container>
        <TimeOut />
        <Title>The link is invalid or has expired.</Title>
      </Container>
    </StyledModal>
  );
};
