import { Loader } from 'Atoms/Loader';
import { P } from 'Atoms/text';
import { AlphabetSelector } from 'Molecules/AlphabetSelector';
import { AZDownloadButtons } from 'Molecules/az/AZDownloadButtons';
import { AZListSection } from 'Molecules/az/AZListSection';
import { AZMobileResultsList } from 'Molecules/az/AZMobileResultsList';
import { AZResultsList } from 'Molecules/az/AZResultsList';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { match, Route, Switch } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { CategoryType, GroupedData, MicrobeType } from 'types/az';
import { getComparisonListsSection } from 'types/comparisonLists';
import { VirusType } from 'types/microbeDetails';
import { alphabet } from 'utils/alphabet';
import { formatNullableNumber } from 'utils/formatNumber';

const ResultsCountParagraph = styled(P)<{ isMobile?: boolean }>`
  ${props =>
    props.isMobile
      ? css`
          margin-bottom: 40px;
          text-align: center;
        `
      : css`
          margin-top: 20px;
        `}
`;

const categoryNames: Record<CategoryType | MicrobeType | VirusType, string> = {
  diseases: 'diseases',
  drugs: 'drugs',
  vaccines: 'vaccines and immunoglobulins',
  countries: 'countries and territories',
  microbes: 'microbes',
  bacteria: 'bacteria',
  mycobacteria: 'mycobacteria',
  yeasts: 'yeasts and algae',
  viruses: 'viruses',
};

interface Props {
  match: match;
  data: GroupedData;
  category: CategoryType | MicrobeType | VirusType;
  isMobile?: boolean;
  title: string;
}

export const AZList: FC<Props> = ({ match, data, category, isMobile = false, title }) => {
  if (!data) {
    return <div>Error...</div>;
  }

  if (data && data.total === 0) {
    return <Loader />;
  }

  const exploreUrlPrefix = match.url.replace('az', 'explore');
  const compareSection = getComparisonListsSection(match.url.split('/').pop() || '');

  return (
    <>
      <Helmet>
        <title>A-Z {title} - GIDEON</title>
      </Helmet>
      {isMobile ? (
        <Switch>
          <Route
            exact
            path={match.url}
            render={() => (
              <>
                <ResultsCountParagraph size="big" weight="500" isMobile>
                  GIDEON database contains <strong>{formatNullableNumber(data.total)}</strong>{' '}
                  {categoryNames[category]}.
                </ResultsCountParagraph>
                <AZMobileResultsList data={data.data} match={match} />
                <AZDownloadButtons category={category} />
              </>
            )}
          />
          {alphabet.map(letter => (
            <Route
              key={letter}
              path={`${match.url}/${letter}`}
              render={() => (
                <>
                  <AZListSection
                    letter={letter}
                    data={data && data.data[letter]}
                    mobile
                    exploreUrlPrefix={exploreUrlPrefix}
                    compareSection={compareSection}
                  />
                  <AZDownloadButtons category={category} />
                </>
              )}
            />
          ))}
        </Switch>
      ) : (
        <>
          <AlphabetSelector basePath={match.url} data={data.data} />
          <ResultsCountParagraph size="big">
            GIDEON database contains <strong>{formatNullableNumber(data.total)}</strong>{' '}
            {categoryNames[category]}.
          </ResultsCountParagraph>
          <AZResultsList
            data={data.data}
            basePath={match.url}
            exploreUrlPrefix={exploreUrlPrefix}
            compareSection={compareSection}
            useDOMOptimization={category === 'bacteria'}
          />
          <AZDownloadButtons category={category} />
        </>
      )}
    </>
  );
};
