import { ReactComponent as CloseIcon } from 'assets/Overlay/CloseX.svg';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon } from 'Atoms/Icon';
import { rgba } from 'polished';
import React, { FC } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

interface StyleProps {
  fill: keyof DefaultTheme['colors']['text'];
}

const StyledInvisibleButton = styled(InvisibleButton)<StyleProps>`
  ${Icon} {
    fill: ${props => props.theme.colors.text[props.fill]};
    &:hover {
      fill: ${props => rgba(props.theme.colors.text[props.fill], 0.6)};
    }
  }
`;

interface Props {
  className?: string;
  onClick?: () => void;
  fill?: keyof DefaultTheme['colors']['text'];
  'aria-label'?: string;
}

const ModalCloseButtonBase: FC<Props> = ({
  className,
  fill = 'modalCardTitle',
  onClick,
  ...rest
}) => (
  <StyledInvisibleButton className={className} onClick={onClick} fill={fill} {...rest}>
    <Icon svgComponent={CloseIcon} />
  </StyledInvisibleButton>
);

export const ModalCloseButton = styled(ModalCloseButtonBase)`
  width: 20px;
  height: 20px;

  position: absolute;
  top: 25px;
  right: 25px;
`;
