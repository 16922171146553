import { Arrow } from 'Atoms/NavigationArrow';
import { P } from 'Atoms/text/P';
import { SecondaryNavLink } from 'Molecules/SecondaryNavLink';
import React, { FC, KeyboardEvent, MutableRefObject } from 'react';
import { useMobile } from 'services/useMobile';
import styled, { css } from 'styled-components/macro';
import { SecondaryNavbarLink } from 'types/navbar';

const Title = styled(P)`
  line-height: 32px;
  margin: 15px 50px 0 32px;
  align-self: flex-start;
`;

interface NavigationProps {
  isOpen: boolean;
}

const StyledNavigation = styled.nav<NavigationProps>`
  width: 100%;
  min-height: 64px;
  display: flex;
  align-items: center;
  background: ${props => props.theme.colors.background.secondaryNavbar};

  display: flex;
  position: absolute;
  top: 64px;
  left: 0;
  z-index: 10;

  transition: all 0.1s ease-out;

  ${props =>
    props.isOpen
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

interface ArrowProps {
  arrowXPosition: number;
}

const StyledArrow = styled(Arrow)<ArrowProps>`
  position: absolute;
  left: ${props => `${props.arrowXPosition}px`};
  top: -12px;
`;

interface Props {
  className?: string;
  title: string;
  isOpen: boolean;
  links: SecondaryNavbarLink[];
  refs?: MutableRefObject<(HTMLElement | null)[]>;
  arrowXPosition?: number;
  onKeyDown?: (e: KeyboardEvent, index: number) => void;
}

export const SecondaryNavbar: FC<Props> = ({
  className,
  title,
  arrowXPosition,
  links,
  isOpen,
  refs,
  onKeyDown,
}) => {
  const isTablet = useMobile('l', 'max');
  return (
    <StyledNavigation className={className} isOpen={isOpen}>
      {arrowXPosition && <StyledArrow arrowXPosition={arrowXPosition} />}
      <Title size="big" weight="700" color="secondaryNavbar">
        {title}
      </Title>
      <Container>
        {links.map((link, i) => (
          <SecondaryNavLink
            key={link.to}
            to={link.to}
            title={isTablet && link.shortTitle ? link.shortTitle : link.title}
            icon={link.icon}
            ref={r => {
              if (!refs) {
                return;
              }

              refs.current[i] = r;
            }}
            onKeyDown={e => onKeyDown && onKeyDown(e, i)}
          />
        ))}
      </Container>
    </StyledNavigation>
  );
};
