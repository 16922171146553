import fscreen from 'fscreen';
import { RefObject, useEffect, useState } from 'react';

interface useFullscreenReturns {
  isFullscreenSupported: boolean;
  isFullscreenActive: boolean;
  enterFullscreen: () => void;
  exitFullscreen: () => void;
  toggleFullscreen: () => void;
}

/**
 * `useFullscreen` returns state and state handlers to track fullscreen state on a `ref` attached element,
 * `isFullscreenActive` to track fullscreen state and `enterFullscreen`, `exitFullscreen` and `toggleFullscreen` functions to change the fullscreen state.
 * `isFullscreenSupported` indicates if a device or browser supports fullscreen all together.
 */
export const useFullscreen = <T extends HTMLElement>(ref: RefObject<T>): useFullscreenReturns => {
  const [isFullscreenActive, setIsFullscreenActive] = useState(false);

  const handler = (): void => setIsFullscreenActive(!!fscreen.fullscreenElement);

  useEffect(() => {
    fscreen.addEventListener('fullscreenchange', handler, false);

    return () => {
      fscreen.removeEventListener('fullscreenchange', handler, false);
    };
  }, []);

  const enterFullscreen = (): void => {
    !isFullscreenActive && ref.current && fscreen.requestFullscreen(ref.current);
  };

  const exitFullscreen = (): void => {
    isFullscreenActive && fscreen.exitFullscreen();
  };

  const toggleFullscreen = (): void => {
    if (isFullscreenActive) {
      fscreen.exitFullscreen();
    } else if (ref.current) {
      fscreen.requestFullscreen(ref.current);
    }
  };

  return {
    isFullscreenSupported: fscreen.fullscreenEnabled,
    isFullscreenActive,
    enterFullscreen,
    exitFullscreen,
    toggleFullscreen,
  };
};
