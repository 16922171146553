import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'Vaccines/Coverage/SingleLoadInitiated': {
      return { ...state, vaccineCoverages: { ...state.vaccineCoverages, [action.payload.id]: 'Loading' } };
    }
    case 'Vaccines/Coverage/SingleLoaded': {
      return {
        ...state,
        vaccineCoverages: { ...state.vaccineCoverages, [action.payload.id]: action.payload.data },
      };
    }
    case 'Vaccines/Coverage/SingleLoadFailed': {
      return {
        ...state,
        vaccineCoverages: { ...state.vaccineCoverages, [action.payload.id]: action.payload.error },
      };
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
