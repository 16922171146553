import { Link } from 'Atoms/links/Link';
import { SmartCompareButton } from 'Organisms/SmartCompareButton';
import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';
import { ComparisonListsSection } from 'types/comparisonLists';

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  max-width: 350px;
  padding: 5px 0;
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.text.darkLink};
  font: ${props => props.theme.fonts.normalText};
  flex-grow: 1;
  padding-right: 8px;
  text-decoration: underline;
`;

interface Props {
  className?: string;
  name: string;
  to: string;
  compareButtonSection: ComparisonListsSection | undefined;
  compareButtonId: number | undefined;
}

export const ListItem = React.memo(
  ({ className, name, to, compareButtonSection, compareButtonId }: Props): ReactElement => (
    <Item className={className}>
      <StyledLink to={to}>{name}</StyledLink>
      {!!compareButtonSection && !!compareButtonId && (
        <SmartCompareButton section={compareButtonSection} id={compareButtonId} showTooltip />
      )}
    </Item>
  )
);
