import { getDiseaseOutbreaks } from 'apiServices/Diseases/diseases';
import React, { useEffect } from 'react';
import { newStoreError } from 'store/storeError';
import { Dispatch, Loading, Resource } from 'store/types';
import { DiseaseOutbreak } from 'types/disease';

import {
  Action,
  DiseaseOutbreaksDispatchContext,
  DiseaseOutbreaksStateContext,
  State,
} from './provider';

export const useState = (): State => {
  const state = React.useContext(DiseaseOutbreaksStateContext);
  if (state === undefined) {
    throw new Error('Disease outbreaks store is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(DiseaseOutbreaksDispatchContext);
  if (dispatch === undefined) {
    throw new Error('Disease outbreaks store is not initialized');
  }
  return dispatch;
};

export const useDiseaseOutbreaks = (diseaseId: number): Resource<DiseaseOutbreak[]> => {
  const state = useState();
  const dispatch = useDispatch();
  const diseaseOutbreaks = state[diseaseId];

  useEffect(() => {
    if (!diseaseOutbreaks) {
      dispatch({ type: 'DiseaseOutbreaks/SingleLoadInitiated', payload: { id: diseaseId } });
      getDiseaseOutbreaks(diseaseId)
        .then(data =>
          dispatch({ type: 'DiseaseOutbreaks/SingleLoaded', payload: { id: diseaseId, data } })
        )
        .catch(err => {
          dispatch({
            type: 'DiseaseOutbreaks/SingleLoadFailed',
            payload: { id: diseaseId, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, diseaseId, diseaseOutbreaks]);

  return diseaseOutbreaks || Loading;
};
