import { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface Props {
  className?: string;
  children: ReactNode;
  onClick?: () => void;
  isOpen: boolean;
}

export const AccountMenuContainer = styled.div<Props>`
  display: ${props => (props.isOpen ? 'unset' : 'none')};
  height: 50px;
  position: absolute;
  top: 64px;
  right: 0px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    top: 64px;
    right: 0;
  }
`;
