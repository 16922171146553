import { getMicrobeDetails, getMicrobeDetailsPublic } from 'apiServices/Microbes/microbes';
import React, { useEffect } from 'react';
import { newStoreError } from 'store/storeError';
import { Dispatch, Loading, Resource } from 'store/types';
import { MicrobeDetails, MicrobeDetailsPublic, MicrobeType } from 'types/microbeDetails';

import {
  Action,
  MicrobeDetailsDispatchContext,
  MicrobeDetailsStateContext,
  State,
} from './provider';

export const useState = (): State => {
  const state = React.useContext(MicrobeDetailsStateContext);
  if (state === undefined) {
    throw new Error('Microbe details store is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(MicrobeDetailsDispatchContext);
  if (dispatch === undefined) {
    throw new Error('Microbe details store is not initialized');
  }
  return dispatch;
};

export const useMicrobeDetails = (id: number, type: MicrobeType): Resource<MicrobeDetails> => {
  const state = useState();
  const dispatch = useDispatch();
  const microbeDetails = state.details[id];

  useEffect(() => {
    if (!microbeDetails) {
      dispatch({ type: 'MicrobeDetails/SingleLoadInitiated', payload: { id } });
      getMicrobeDetails(id, type)
        .then(data => dispatch({ type: 'MicrobeDetails/SingleLoaded', payload: { id, data } }))
        .catch(err => {
          dispatch({
            type: 'MicrobeDetails/SingleLoadFailed',
            payload: { id, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, id, microbeDetails, type]);

  return microbeDetails || Loading;
};

export const useMicrobeDetailsPublic = (
  id: number,
  type: MicrobeType
): Resource<MicrobeDetailsPublic> => {
  const state = useState();
  const dispatch = useDispatch();
  const microbeDetails = state.detailsPublic[id];

  useEffect(() => {
    if (!microbeDetails) {
      dispatch({ type: 'MicrobeDetails/Public/SingleLoadInitiated', payload: { id } });
      getMicrobeDetailsPublic(id, type)
        .then(data =>
          dispatch({ type: 'MicrobeDetails/Public/SingleLoaded', payload: { id, data } })
        )
        .catch(err => {
          dispatch({
            type: 'MicrobeDetails/Public/SingleLoadFailed',
            payload: { id, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, id, microbeDetails, type]);

  return microbeDetails || Loading;
};
