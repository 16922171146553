import { InlineInputWrapper, InlineLabel, StyledSelect } from 'Molecules/account/AccountFormInput';
import { Subsection } from 'Molecules/account/Subsection';
import { Props } from 'Organisms/AccountForm';
import React, { FC, useContext } from 'react';
import { OnChangeValue } from 'react-select';
import { DefaultStartPageContext } from 'services/localStorage/defaultStartPage.provider';
import { MotionContext } from 'services/motion.service';
import { ThemeModeContext } from 'services/theme';
import { useContextAssert } from 'services/useContextAssert.hook';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';
import { isStartPage, startLinks } from 'types/startPage';

const StyledSubsection = styled(Subsection)`
  margin-bottom: 30px;
`;

const colorThemeSelectOptions: SelectOption[] = [
  { value: 'light', label: 'Light' },
  { value: 'dark', label: 'Dark' },
];

const animationSelectOptions: SelectOption[] = [
  { value: 'on', label: 'On' },
  { value: 'off', label: 'Off' },
];

const paperSizeSelectOptions: SelectOption[] = [
  { value: 'A4', label: 'A4' },
  { value: 'Letter', label: 'Letter' },
];

export const Preferences: FC<Props> = ({ onInputChange, inputState }) => {
  const [currentStartPage, setStartPageValue] = useContextAssert(DefaultStartPageContext);
  const { isDarkMode, setIsDarkMode } = useContext(ThemeModeContext);
  const { isMotionReduced, setIsMotionReduced } = useContextAssert(MotionContext);

  const startPageOptions: SelectOption[] = Object.keys(startLinks).map(x => ({
    value: x,
    label: x === 'start' ? 'Not set' : x.charAt(0).toUpperCase() + x.slice(1),
  }));

  const onStartPageSelectChange = (selected: OnChangeValue<SelectOption, false>): void => {
    selected && isStartPage(selected.value) && setStartPageValue(selected.value);
  };

  const onThemeSelectChange = (selected: OnChangeValue<SelectOption, false>): void => {
    if (selected) {
      selected.value === 'dark' ? setIsDarkMode(true) : setIsDarkMode(false);
      onInputChange(selected.value, 'defaultColorTheme');
    }
  };

  const onAnimationSelectChange = (selected: OnChangeValue<SelectOption, false>): void => {
    if (selected) {
      setIsMotionReduced(selected.value === 'off');
    }
  };

  const onPaperSizeSelectChange = (selected: OnChangeValue<SelectOption, false>): void => {
    if (selected) {
      onInputChange(selected.value, 'paperSize');
    }
  };

  const colorThemeValue =
    inputState.defaultColorTheme === 'dark' || inputState.defaultColorTheme === 'light'
      ? inputState.defaultColorTheme
      : isDarkMode
      ? 'dark'
      : 'light';

  return (
    <StyledSubsection title="Personal preferences">
      <InlineInputWrapper>
        <InlineLabel htmlFor="page">Default page after sign in</InlineLabel>
        <StyledSelect
          classNamePrefix="account-settings-select"
          options={
            currentStartPage === 'start'
              ? startPageOptions.filter(x => x.value !== 'start')
              : startPageOptions
          }
          onChange={onStartPageSelectChange}
          noDefaultValue
          placeholder="Not set"
          isSearchable={false}
          value={currentStartPage === 'start' ? undefined : currentStartPage}
          inputId="page"
        />
      </InlineInputWrapper>
      <InlineInputWrapper>
        <InlineLabel htmlFor="color">Default color theme</InlineLabel>
        <StyledSelect
          classNamePrefix="account-settings-select"
          options={colorThemeSelectOptions}
          onChange={onThemeSelectChange}
          isSearchable={false}
          value={colorThemeValue}
          inputId="color"
        />
      </InlineInputWrapper>
      <InlineInputWrapper>
        <InlineLabel htmlFor="paper-size">Default paper size</InlineLabel>
        <StyledSelect
          classNamePrefix="account-settings-select"
          options={paperSizeSelectOptions}
          onChange={onPaperSizeSelectChange}
          isSearchable={false}
          value={inputState.paperSize}
          inputId="paper-size"
        />
      </InlineInputWrapper>
      <InlineInputWrapper>
        <InlineLabel htmlFor="animation">Animations</InlineLabel>
        <StyledSelect
          classNamePrefix="account-settings-select"
          options={animationSelectOptions}
          onChange={onAnimationSelectChange}
          isSearchable={false}
          value={isMotionReduced ? 'off' : 'on'}
          inputId="animation"
        />
      </InlineInputWrapper>
    </StyledSubsection>
  );
};
