import { ReactComponent as CombineIcon } from 'assets/UI/Combine.svg';
import { ReactComponent as StarIcon } from 'assets/UI/Star.svg';
import { ReactComponent as SurgeryIcon } from 'assets/UI/Surgery.svg';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon } from 'Atoms/Icon';
import { P, Span } from 'Atoms/text';
import { LightTooltip } from 'Atoms/tooltip/LightTooltip';
import { Popper } from 'Organisms/Popper';
import React, { FC, MouseEvent, ReactNode, useState } from 'react';
import styled from 'styled-components/macro';

const SymbolsKey = styled.div`
  background: ${props => props.theme.colors.table.compareTable.probabilityKeyBackground};
  border: 1px solid ${props => props.theme.colors.table.compareTable.probabilityKeyBorder};
  border-radius: 10px;

  padding: 10px 15px;
`;

const KeyItemStyled = styled.div`
  display: flex;
  align-items: center;

  margin-top: 15px;
`;

const SpanStyled = styled(Span)`
  margin-left: 15px;
`;

const StyledButton = styled(InvisibleButton)`
  cursor: pointer;
  font: ${props => props.theme.fonts.normalText};
  color: ${props => props.theme.colors.text.darkLink};
  text-decoration: underline;
`;

type KeyProps = {
  className?: string;
  children: ReactNode;
  svg: FC;
};

const KeyItem: FC<KeyProps> = ({ className, children, svg }) => (
  <KeyItemStyled className={className}>
    <Icon fill="lightLink" svgComponent={svg} size="smallMedium" />
    <SpanStyled>{children}</SpanStyled>
  </KeyItemStyled>
);

type Props = {
  className?: string;
};

export const DrugSymbolKey: FC<Props> = ({ className }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement>();

  return (
    <SymbolsKey className={className}>
      <P size="normal" weight="700">
        Symbols key
      </P>
      <KeyItem svg={StarIcon}>
        Drug of choice in{' '}
        <StyledButton
          onClick={(event: MouseEvent<HTMLButtonElement>): void => {
            setReferenceElement(event.currentTarget);
          }}
        >
          standard publications
        </StyledButton>
      </KeyItem>
      <KeyItem svg={CombineIcon}>To be used in combination </KeyItem>
      <KeyItem svg={SurgeryIcon}>Excision or surgery often required</KeyItem>
      <Popper referenceElement={referenceElement} isOpen={!!referenceElement}>
        <LightTooltip onClose={() => setReferenceElement(undefined)}>
          <P>
            Drug of choice in The Medical Letter on Drugs and Therapeutics, standard texts and other
            authoritative publications
          </P>
        </LightTooltip>
      </Popper>
    </SymbolsKey>
  );
};
