import { RelatedVaccine, VaccineAbbreviation } from 'types/vaccineAbbreviation';

import { RelatedVaccineDTO, VaccineAbbreviationDTO } from './abbreviations.dto';

const mapRelatedVaccine = (vaccine: RelatedVaccineDTO): RelatedVaccine => ({
  name: vaccine.vaccine,
  id: vaccine.vaccine_code,
});

export const mapAbbreviationDTO = (
  id: string,
  details: VaccineAbbreviationDTO
): VaccineAbbreviation => ({
  name: id,
  isComposite: details.is_composite,
  explanation: details.vaccine,
  relatedVaccines: details.related_vaccines.map(mapRelatedVaccine),
});
