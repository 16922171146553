import { contentClassNames, Modal } from 'Atoms/Modal';
import { H2, H3 } from 'Atoms/text';
import { darken } from 'polished';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { ScrollbarMixin } from 'utils/styleMixins';

import { ModalCloseButton } from '../buttons/ModalCloseButton';

interface Props {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: ReactNode;
  subTitle?: string;
  contentLabel?: string;
}

export const StyledModal = styled(Modal)`
  .${contentClassNames.base} {
    background: ${props => props.theme.colors.background.primary};
    max-width: 80%;
    max-height: 90%;
    width: 100%;
    height: 100%;
    ${ScrollbarMixin}
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

export const Title = styled(H2)`
  margin: 20px 0;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 10px 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 50px;
  }
`;

const SubTitle = styled(H3)`
  color: ${props => props.theme.colors.text.darkLink};
  margin: 0 0 10px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 0 0 10px 0;
  }
`;

export const ModalHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 450;
  padding: 1% 4%;
  background: ${props => darken(0.03, props.theme.colors.background.primary)};
  box-shadow: 0 6px 5px 0px rgba(0, 0, 0, 0.3);
`;

export const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 4%;
`;

export const StyledClosedButton = styled(ModalCloseButton)`
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    top: 15px;
    right: 10px;
  }
`;

export const ExploreLandingModal: FC<Props> = ({
  isOpen,
  onClose,
  children,
  title,
  subTitle,
  className,
  contentLabel,
}) => {
  return (
    <StyledModal
      className={className}
      isOpen={isOpen}
      onClose={onClose}
      contentLabel={contentLabel}
    >
      <ModalHeader>
        <StyledClosedButton onClick={onClose} aria-label="Modal close" />
        {title && (
          <Title font="Quicksand" weight="500" color="mainBold">
            {title}
          </Title>
        )}
        {subTitle && (
          <SubTitle font="Inter" weight="500" size="small">
            {subTitle}
          </SubTitle>
        )}
      </ModalHeader>
      <Content>{children}</Content>
    </StyledModal>
  );
};
