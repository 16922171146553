import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetCountryList } from 'store/countriesStore/hooks';
import { useGetDiseaseCountries } from 'store/diseaseDetailStore/hooks';
import { useGetDiseaseList } from 'store/diseasesStore/hooks';
import { Disease } from 'store/diseasesStore/provider';
import { useGetDrugList } from 'store/drugsStore/hooks';
import { Drug } from 'store/drugsStore/provider';
import { useGetMicrobeList } from 'store/microbesStore/hooks';
import { VaccineAbbreviations } from 'store/vaccineCoverageStore/hooks';
import { useGetVaccineList } from 'store/vaccinesStore/hooks';
import { Vaccine } from 'store/vaccinesStore/provider';
import { Country } from 'types/country';
import { DiseaseCountry } from 'types/disease';
import { DiseaseInnerParams, matchPathExtended, SlugParams } from 'types/pathParams';
import { SimpleMicrobes } from 'types/simpleMicrobe';

import { slugIdExtractor } from './slug/slug.service';

export interface PageName {
  path: string | string[];
  label: string;
}

export interface Options {
  diseases: () => Disease[];
  diseaseCountries: (id: number) => DiseaseCountry[];
  vaccines: () => Vaccine[];
  drugs: () => Drug[];
  countries: () => Country[];
  microbe: () => SimpleMicrobes;
}

export type PageNameFunction = (pathname: string, options: Options) => string | null;

export const isPageNameFunction = (
  value: PageName | PageNameFunction
): value is PageNameFunction => {
  return value && typeof value === 'function';
};

const pageNames: (PageName | PageNameFunction)[] = [
  { path: '/start', label: 'Start' },
  { path: '/explore/drugs', label: 'Explore Drugs' },
  { path: '/explore/diseases', label: 'Explore Diseases' },
  { path: '/explore/vaccines', label: 'Explore Vaccines' },
  { path: '/explore/microbes', label: 'Explore Microbe' },
  { path: '/explore/countries', label: 'Explore Countries' },
  { path: '/explore/countries/bioterrorism', label: 'Bioterrorism' },
  { path: '/explore', label: 'Explore' },
  { path: '/search', label: 'Search' },
  { path: ['/az/diseases', '/az/diseases/*'], label: 'AZ Diseases' },
  { path: ['/az/drugs', '/az/drugs/*'], label: 'AZ Drugs' },
  { path: ['/az/vaccines', '/az/vaccines/*'], label: 'AZ Vaccines' },
  { path: '/az/microbes', label: 'AZ Microbes' },
  { path: ['/az/microbes/bacteria', '/az/microbes/bacteria/*'], label: 'AZ Bacteria' },
  { path: ['/az/microbes/mycobacteria', '/az/microbes/mycobacteria/*'], label: 'AZ Mycobacteria' },
  { path: ['/az/microbes/yeasts', '/az/microbes/yeasts/*'], label: 'AZ Yeasts' },
  { path: ['/az/countries', '/az/countries/*'], label: 'AZ Countries' },
  { path: '/az/microbes', label: 'AZ Microbes' },
  { path: '/compare/diseases', label: 'Compare Diseases' },
  { path: '/compare/drugs', label: 'Compare Drugs' },
  { path: '/compare/bacteria', label: 'Compare Bacteria' },
  { path: '/compare/mycobacteria', label: 'Compare Mycobacteria' },
  { path: '/compare/yeasts', label: 'Compare Yeasts' },
  { path: '/diagnose', label: 'Diagnose' },
  { path: '/diagnose/steps', label: 'Step by step' },
  { path: '/diagnose/probability-engine', label: 'Probability engine' },
  { path: '/visualize', label: 'Visualize' },
  { path: '/visualize/disease-outbreaks', label: 'Disease outbreaks' },
  { path: '/visualize/graphs', label: 'Epidemiological graphs' },
  { path: '/visualize/vaccine-coverage-maps', label: 'Vaccine coverage' },
  { path: '/visualize/global-outbreaks-map', label: 'Global outbreaks' },
  { path: '/account', label: 'Account' },
  { path: '/lab', label: 'Lab' },
  { path: '/lab/bacteria/probability-engine', label: 'Lab bacteria probability engine' },
  { path: '/lab/bacteria/tree', label: 'Lab bacteria decision tree' },
  { path: '/lab/yeasts/probability-engine', label: 'Lab yeasts probability engine' },
  { path: '/lab/yeasts/tree', label: 'Lab yeasts decision tree' },
  { path: '/lab/mycobacteria/probability-engine', label: 'Lab mycobacteria probability engine' },
  { path: '/lab/mycobacteria/tree', label: 'Lab mycobacteria decision tree' },
  { path: '/updates', label: 'Updates' },
  { path: '/updates/data', label: 'Updates - Latest updates' },
  { path: '/updates/data/48-hours', label: 'Updates - Latest updates 48 hours' },
  { path: '/updates/data/7-days', label: 'Updates - Latest updates 7 days' },
  { path: '/updates/outbreaks', label: 'Updates - Outbreaks map' },
  { path: '/updates/content', label: 'Updates - Content by numbers' },
  { path: '/ebooks/countries', label: 'eBooks countries' },
  { path: '/ebooks/diseases', label: 'eBooks diseases' },
  { path: '/ebooks/other', label: 'GIDEON Guides' },
  { path: '/ebooks', label: 'eBooks' },
  { path: '/sitemap', label: 'Site Map' },
  (pathname, options) => {
    const match = matchPathExtended<SlugParams>('/visualize/disease-outbreaks/:slug', pathname);

    if (!match?.isExact) {
      return null;
    }

    const value = slugIdExtractor(match.params.slug);

    const label = options.diseases().find(d => d.id.toString() === value)?.name;

    return label || null;
  },
  (pathname) => {
    const match = matchPathExtended<SlugParams>('/visualize/vaccine-coverage-maps/:slug', pathname);

    if (!match?.isExact) {
      return null;
    }

    const abbrev = VaccineAbbreviations.find(item => item.url === match.params.slug);
    return abbrev?.label || 'Vaccine coverage';
  },
  (pathname) => {
    const match = matchPathExtended<SlugParams>('/visualize/global-outbreaks-map/:slug', pathname);

    if (!match?.isExact) {
      return null;
    }
    return 'Global outbreaks';
  },
  (pathname, options) => {
    const match = matchPathExtended<SlugParams>('/explore/diseases/:slug', pathname);

    if (!match?.isExact) {
      return null;
    }

    const value = slugIdExtractor(match.params.slug);

    const label = options.diseases().find(d => d.id.toString() === value)?.name;

    return label || null;
  },
  (pathname, options) => {
    const match = matchPathExtended<SlugParams>('/explore/diseases/:slug/outbreaks', pathname);

    if (!match?.isExact) {
      return null;
    }

    const value = slugIdExtractor(match.params.slug);

    const disease = options.diseases().find(d => d.id.toString() === value);

    return disease ? `${disease.name} Outbreaks` : null;
  },
  (pathname, options) => {
    const match = matchPathExtended<SlugParams>('/explore/diseases/:slug/bioterrorism', pathname);

    if (!match?.isExact) {
      return null;
    }

    const value = slugIdExtractor(match.params.slug);

    const disease = options.diseases().find(d => d.id.toString() === value);

    return disease ? `${disease.name} Bioterrorism` : null;
  },
  (pathname, options) => {
    const match = matchPathExtended<SlugParams>('/explore/diseases/:slug/worldwide', pathname);

    if (!match?.isExact) {
      return null;
    }

    const value = slugIdExtractor(match.params.slug);

    const disease = options.diseases().find(d => d.id.toString() === value);

    return disease ? `${disease.name} Worldwide Distribution` : null;
  },
  (pathname, options) => {
    const match = matchPathExtended<DiseaseInnerParams>(
      '/explore/diseases/:diseaseSlug/:countrySlug',
      pathname
    );

    if (!match?.isExact) {
      return null;
    }

    const diseaseId = slugIdExtractor(match.params.diseaseSlug);
    const countryId = slugIdExtractor(match.params.countrySlug);

    const disease = options.diseases().find(d => d.id.toString() === diseaseId);

    if (!disease) {
      return null;
    }

    const country = options
      .diseaseCountries(disease.id)
      .find(country => country.code === countryId);

    return country ? `${disease.name} in ${country.country}` : null;
  },
  (pathname, options) => {
    const match = matchPathExtended<SlugParams>('/explore/drugs/:slug', pathname);

    if (!match?.isExact) {
      return null;
    }

    const drugId = slugIdExtractor(match.params.slug);

    const label = options.drugs().find(d => d.id.toString() === drugId)?.name;

    return label || null;
  },
  (pathname, options) => {
    const match = matchPathExtended<SlugParams>('/explore/vaccines/:slug', pathname);

    if (!match?.isExact) {
      return null;
    }

    const vaccineId = slugIdExtractor(match.params.slug);

    const label = options.vaccines().find(v => v.id.toString() === vaccineId)?.name;

    return label || null;
  },
  (pathname, options) => {
    const match = matchPathExtended<SlugParams>('/explore/countries/:slug', pathname);

    if (!match?.isExact) {
      return null;
    }

    const countryId = slugIdExtractor(match.params.slug);

    const label = options.countries().find(c => c.id === countryId)?.name;

    return label || null;
  },
  (pathname, options) => {
    const match = matchPathExtended<SlugParams>('/explore/microbes/bacteria/:slug', pathname);

    if (!match?.isExact) {
      return null;
    }

    const bacteriaId = slugIdExtractor(match.params.slug);

    const label = options.microbe().bacteria.find(b => b.id.toString() === bacteriaId)?.name;

    return label || null;
  },
  (pathname, options) => {
    const match = matchPathExtended<SlugParams>('/explore/microbes/mycobacteria/:slug', pathname);

    if (!match?.isExact) {
      return null;
    }

    const mycobacteriaId = slugIdExtractor(match.params.slug);

    const label = options.microbe().mycobacteria.find(m => m.id.toString() === mycobacteriaId)
      ?.name;

    return label || null;
  },
  (pathname, options) => {
    const match = matchPathExtended<SlugParams>('/explore/microbes/yeasts/:slug', pathname);

    if (!match?.isExact) {
      return null;
    }

    const yeastId = slugIdExtractor(match.params.slug);

    const label = options.microbe().yeasts.find(y => y.id.toString() === yeastId)?.name;

    return label || null;
  },
  (pathname, options) => {
    const match = matchPathExtended<SlugParams>('/explore/microbes/viruses/:slug', pathname);

    if (!match?.isExact) {
      return null;
    }

    const virusId = slugIdExtractor(match.params.slug);

    const label = options.microbe().viruses.find(y => y.id.toString() === virusId)?.name;

    return label || null;
  },
];

/**
 * Resolves page name from current path.
 */
export const usePageName = (): string => {
  const { pathname } = useLocation();
  const getDiseaseCountries = useGetDiseaseCountries();
  const getDiseaseList = useGetDiseaseList();
  const getDrugList = useGetDrugList();
  const getVaccineList = useGetVaccineList();
  const getCountryList = useGetCountryList();
  const getMicrobeList = useGetMicrobeList();

  const [pageName, setPageName] = useState<string>('');

  useEffect(() => {
    const options: Options = {
      diseases: getDiseaseList,
      drugs: getDrugList,
      microbe: getMicrobeList,
      vaccines: getVaccineList,
      countries: getCountryList,
      diseaseCountries: getDiseaseCountries,
    };
    for (const pageName of pageNames) {
      if (isPageNameFunction(pageName)) {
        const labelValue = pageName(pathname, options);

        if (labelValue) {
          setPageName(`Back to ${labelValue}`);
          return;
        }
      } else {
        const matched = matchPathExtended(pageName.path, pathname)?.isExact || false;

        if (matched) {
          setPageName(`Back to ${pageName.label}`);
          return;
        }
      }
    }

    setPageName('Back');
  }, [
    setPageName,
    pathname,
    getDiseaseList,
    getDrugList,
    getMicrobeList,
    getVaccineList,
    getCountryList,
    getDiseaseCountries,
  ]);

  return pageName;
};
