import { ReactComponent as BioterrorismIcon } from 'assets/Button/Bioterrorism.svg';
import { ReactComponent as CountriesSvg } from 'assets/Navbar/Countries.svg';
import { ReactComponent as MapSvg } from 'assets/UI/Map.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { AsyncSelect } from 'Molecules/select/AsyncSelect';
import React, { FC } from 'react';
import { OnChangeValue } from 'react-select';
import { countryCodesISO2, countryCodesISO3 } from 'services/countryCodes';
import { searchSelectOptionSortCompare as sortCompare } from 'services/select.service';
import { useCountrySelectOptions } from 'services/useCountrySelectOptions';
import { useMobile } from 'services/useMobile';
import { usePushState } from 'services/usePushState.hook';
import { useTooltip } from 'services/useTooltip';
import styled from 'styled-components/macro';
import { CountrySelectOption } from 'types/country';
import { DiseaseCountries } from 'types/disease';
import { DiseaseCountry } from 'types/disease';
import { SelectOption } from 'types/select';
import { countriesSelectFilter, onCountryInputChange } from 'utils/countriesSelectFilter';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  background-color: ${props => props.theme.colors.background.modalCardBody};
  padding: 25px 43px;
  border: 1px solid ${props => props.theme.colors.recordSection.border};
  border-radius: 10px;
`;

const Row = styled.div`
  display: flex;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    flex-direction: column;
  }
`;

const SelectContainer = styled.div`
  min-width: 250px;
  width: 100%;
`;

const StyledCountrySelect = styled(AsyncSelect<SelectOption, false>())`
  z-index: 6;
  height: 50px;
  width: 100%;

  border-radius: 0;
  .${props => props.classNamePrefix}__control {
    border-radius: 4px;
    padding-left: 16px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 2px 1px rgba(0, 0, 0, 0.2);
    background-color: transparent;

    &--is-focused,
    &--has-value,
    &--menu-is-open {
      padding: 3px 9px 5px 13px;
      border-width: 4px;
    }
  }

  .${props => props.classNamePrefix}__menu {
    margin-top: 8px;
    border-width: 4px;
    border-radius: 4px;
    background-color: ${props => props.theme.colors.select.default.menuBackground};
  }

  .${props => props.classNamePrefix}__menu-separator {
    display: none;
  }

  .${props => props.classNamePrefix}__option--is-disabled {
    opacity: 0.5;
  }
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;

  margin: 0 10px;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    margin: 10px 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 0;
    flex-direction: column;
  }
`;

const StyledButton = styled(FilledButton)`
  padding: 12px 16px;

  padding-left: 10px;
  padding-right: 10px;

  white-space: nowrap;

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    &:not(:first-child) {
      margin: 0 0 0 10px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    white-space: wrap;
    margin: 10px 0 0 0;
  }
`;

const StyledIcon = styled(Icon)`
  width: 25px;
  height: 25px;
  margin-right: 15px;
`;

const mapCountriesToSelectOptions = (countries: DiseaseCountry[]): CountrySelectOption[] => {
  return countries
    .filter(country => country.code !== 'worldwide' && country.code !== 'bioterrorism')
    .map<CountrySelectOption>(x => ({
      value: x.code.toString(),
      label: x.country,
      imageUrl: `/Flags/${x.country}.svg`,
      iso2Code: countryCodesISO2[x.country],
      iso3Code: countryCodesISO3[x.country],
      disabled: !x.hasCountryNote,
    }))
    .sort(sortCompare);
};

interface Props {
  code: number;
  countries: DiseaseCountries;
  isOutbreakButtonDisabled?: boolean;
  isBioterrorismButtonDisabled?: boolean;
  isSelectDisabled?: boolean;
}

export const DistributionSection: FC<Props> = ({
  code,
  countries,
  isOutbreakButtonDisabled,
  isBioterrorismButtonDisabled,
  isSelectDisabled,
}) => {
  const { countriesOptions, loadCountriesOptions } = useCountrySelectOptions({
    mappedSelectOptions: mapCountriesToSelectOptions(countries.list),
  });
  const { push } = usePushState();
  const isMobile = useMobile('s');

  const handleCountryChange = (selected: OnChangeValue<SelectOption, false>): void => {
    if (selected) {
      push(`/explore/diseases/${code}/${selected.value}`);
    }
  };

  const [selectProps, selectPopper, setSelectElementReference] = useTooltip('Sign in to access');
  const [outbreaksProps, outbreaksPopper, setOutbreaksElementReference] = useTooltip(
    'Sign in to access this outbreaks map'
  );
  const [bioterrorProps, bioterrorPopper, setBioterrorElementReference] = useTooltip(
    'Sign in to access this bioterror note'
  );

  return (
    <Wrapper>
      <Row>
        <SelectContainer
          {...selectProps.events}
          {...selectProps.aria}
          ref={setSelectElementReference}
        >
          <StyledCountrySelect
            classNamePrefix="distribution-countries-select"
            placeholder={isMobile ? 'Find a country note' : 'Find a country-specific note'}
            loadOptions={loadCountriesOptions}
            onChange={handleCountryChange}
            onInputChange={value => onCountryInputChange(value, countriesOptions)}
            showIcon
            cacheOptions
            defaultOptions={countriesOptions}
            showDropdown
            openMenuOnClick={false}
            filterOption={countriesSelectFilter}
            aria-label="Country specific note"
            isDisabled={isSelectDisabled}
          />
        </SelectContainer>
        {isSelectDisabled && selectPopper}
        <Buttons>
          <StyledButton
            onClick={
              countries.isWorldwideAvailable
                ? () => push(`/explore/diseases/${code}/worldwide`)
                : undefined
            }
            disabled={!countries.isWorldwideAvailable}
          >
            <StyledIcon svgComponent={CountriesSvg} /> Worldwide note
          </StyledButton>
          <StyledButton
            disabled={isOutbreakButtonDisabled}
            {...outbreaksProps.events}
            {...outbreaksProps.aria}
            ref={setOutbreaksElementReference}
            onClick={() => push(`/explore/diseases/${code}/outbreaks`)}
          >
            <StyledIcon svgComponent={MapSvg} /> Outbreaks map
          </StyledButton>
          {isOutbreakButtonDisabled && outbreaksPopper}
          {countries.isBioterrorismAvailable && (
            <>
              <StyledButton
                disabled={isBioterrorismButtonDisabled}
                {...bioterrorProps.events}
                {...bioterrorProps.aria}
                ref={setBioterrorElementReference}
                onClick={() => push(`/explore/diseases/${code}/bioterrorism`)}
              >
                <StyledIcon svgComponent={BioterrorismIcon} /> Bioterror note
              </StyledButton>
              {isBioterrorismButtonDisabled && bioterrorPopper}
            </>
          )}
        </Buttons>
      </Row>
    </Wrapper>
  );
};
