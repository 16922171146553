import { ReactComponent as AlertSVG } from 'assets/Button/AlertIcon.svg';
import { Icon } from 'Atoms/Icon';
import { P } from 'Atoms/text';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const AlertContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-right: 10px;
  fill: ${props => props.theme.colors.text.main};
`;

const Description = styled(P)`
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-width: 80%;
  }
`;

interface Props {
  className?: string;
}

export const Disclaimer: FC<Props> = ({ className, children }) => {
  return (
    <AlertContainer className={className}>
      <StyledIcon svgComponent={AlertSVG} />
      <Description textAlign="left">{children}</Description>
    </AlertContainer>
  );
};
