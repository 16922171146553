import React, { useEffect } from 'react';
import { assertIsNotStoreError, newStoreError } from 'store/storeError';
import { Dispatch } from 'store/types';
import { CustomError, ErrorCodes } from 'types/errorTypes';
import { getContents } from 'Updates/apiServices/contents/contents';
import { Contents } from 'Updates/types/content';

import { Action, ContentsDispatchContext, ContentsStateContext, State } from './provider';

export const useState = (): State => {
  const state = React.useContext(ContentsStateContext);
  if (state === undefined) {
    throw new CustomError('ContentsStore', ErrorCodes.StoreNotInitialized);
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(ContentsDispatchContext);
  if (dispatch === undefined) {
    throw new CustomError('ContentsStore', ErrorCodes.StoreNotInitialized);
  }
  return dispatch;
};

export const useContents = (): Contents | 'Loading' | null => {
  const state = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!state) {
      dispatch({ type: 'Contents/LoadInitiated' });
      getContents()
        .then(data => dispatch({ type: 'Contents/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Contents/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    }
  }, [dispatch, state]);

  assertIsNotStoreError(state);

  return state;
};
