import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

interface Props {
  className?: string;
  id?: string;
  children: ReactNode;
}

const TRBase: FC<Props> = ({ className, children, id }) => {
  return (
    <tr className={className} id={id}>
      {children}
    </tr>
  );
};

export const TR = styled(TRBase)`
  background: ${props => props.theme.colors.table.row.backgroundColor};
  &:hover {
    background: ${props => props.theme.colors.table.row.backgroundHoverColor};
  }
`;
