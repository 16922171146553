import { invalidEmailDomains } from 'utils/invalidEmailDomains';

export const emailRegex = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

export const validateEmailDomain = (email: string): boolean => {
  const domain = email.split('@')[1];

  return !invalidEmailDomains.find(invalidDomain =>
    domain.toLocaleLowerCase().includes(invalidDomain.toLocaleLowerCase())
  );
};
