import { ReactComponent as CheckSvg } from 'assets/UI/Check.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon } from 'Atoms/Icon';
import { Tooltip } from 'Atoms/tooltip/Tooltip';
import React, { FC, useContext } from 'react';
import { useTooltip } from 'services/useTooltip';
import styled, { css, ThemeContext } from 'styled-components/macro';

const StyledTooltip = styled(Tooltip)`
  width: 185px;
  top: -2px;
  left: 27px;
  padding: 3px 5px 5px;
  transform: translateZ(0);
`;

const PlusImg = styled.img`
  width: 50%;
  height: 50%;
`;

const CheckmarkIcon = styled(Icon)`
  && {
    fill: ${props => props.theme.colors.button.hover.textColor};
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

export type CompareButtonSizes = 'big' | 'normal';
export type CompareButtonPositions = 'right' | 'top-right' | 'left';

interface Props {
  className?: string;
  clicked?: boolean;
  onClick?: () => void;
  showTooltip?: boolean;
  size?: CompareButtonSizes;
  position?: CompareButtonPositions;
}

const BaseDumbCompareButton: FC<Props> = ({ className, clicked, onClick, showTooltip }) => {
  const theme = useContext(ThemeContext);
  const [props, popper, setElement] = useTooltip('Add to compare list');

  if (clicked) {
    return (
      <InvisibleButton className={className} onClick={onClick} aria-label="Compare">
        <CheckmarkIcon className={className} svgComponent={CheckSvg} />
        {showTooltip && <StyledTooltip>Remove from compare list</StyledTooltip>}
      </InvisibleButton>
    );
  }

  return (
    <div {...props.events}>
      <FilledButton
        className={className}
        onClick={onClick}
        color="light"
        aria-label="Compare"
        ref={setElement}
        {...props.aria}
      >
        <PlusImg src={theme.images.compareButtonPlus} alt="Compare Button" aria-hidden />
      </FilledButton>
      {popper}
    </div>
  );
};

export const DumbCompareButton = styled(BaseDumbCompareButton)`
  ${props =>
    props.size === 'big'
      ? css`
          width: 35px;
          height: 35px;

          ${StyledTooltip} {
            top: 3px;
            left: 40px;
            }
          }
        `
      : css`
          width: 20px;
          height: 20px;
        `}

  ${StyledTooltip} {
    ${props =>
      props.position === 'top-right'
        ? css`
            top: -30px;
            left: 8px;
          `
        : props.position === 'left'
        ? css`
            right: 25px;
            left: unset;
          `
        : css``};
  }

  padding: 0;
  position: relative;
  user-select: none;
  flex: 0 0 auto;

  &:hover {
    ${StyledTooltip} {
      display: block;
    }
  }
`;
