import { AnimatedRow } from 'types/animated';
import { MicrobeType } from 'types/microbeDetails';
import { CellData } from 'types/table';

export interface PathogenCell extends CellData {
  type: 'Pathogen';
  microbeType: MicrobeType;
  hasCompare?: boolean;
  value: number;
  label: string;
  explanation?: string;
}

export interface ProbabilityCell extends CellData {
  type: 'Probability';
  hasProgressBar?: boolean;
  value: number;
  label: string;
}

export type PathogenRow = [PathogenCell, ProbabilityCell];

export type IdentifyRowVerticalOffset = AnimatedRow<PathogenRow>;

export const isPathogenCell = (
  value: PathogenCell | ProbabilityCell | undefined
): value is PathogenCell => {
  return !!value && value.type === 'Pathogen';
};

export const isProbabilityCell = (
  value: PathogenCell | ProbabilityCell | undefined
): value is ProbabilityCell => {
  return !!value && value.type === 'Probability';
};
