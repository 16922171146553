import { getVaccineSynonymsPublic } from 'apiServices/Vaccines/vaccines';
import { ReactComponent as ContraindicationsIcon } from 'assets/Button/ContraindicationsIcon.svg';
import { ReactComponent as ToxicityIcon } from 'assets/Button/ToxicityIcon.svg';
import {
  ExploreLayoutInnerContent,
  ExploreLayoutSelectTitle,
} from 'Atoms/explore/ExploreLayoutContent';
import { Label } from 'Atoms/Label';
import { H1 } from 'Atoms/text/H';
import { LandingMap } from 'Explore/Organisms/LandingMap';
import { TrendingNow } from 'Explore/Organisms/TrendingNow';
import { MapMainLayout } from 'layouts/MapMainLayout';
import { SearchByTypeButton } from 'Molecules/buttons/SearchByTypeButton';
import { AsyncSelect } from 'Molecules/select/AsyncSelect';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { LoginModalContext } from 'services/loginModal.service';
import { useContextAssert } from 'services/useContextAssert.hook';
import { useMobile } from 'services/useMobile';
import { useSelectSynonyms } from 'services/useSelectSynonyms.hook';
import { useVaccinesList } from 'store/vaccinesStore/hooks';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';
import { mapToSearchSelectOption } from 'utils/mapResource';

const MainLayoutStyled = styled(MapMainLayout)`
  min-height: 800px;
  position: relative;
`;

const StyledTitle = styled(H1)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 70px;
  z-index: 2;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    top: 20px;
  }
`;

const TrendingNowStyled = styled(TrendingNow)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 190px;
  z-index: 2;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    top: 120px;
  }
`;

const LabelStyled = styled(Label)`
  margin: 20px 0 0 0;
`;

const ButtonStyled = styled(SearchByTypeButton)`
  max-height: 44px;
  margin: 10px 10px 0;
  padding: 10px;
  opacity: 0.5;
`;

const ContentContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 260px;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 0;
    top: 180px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 400px;
  min-width: 280px;

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    margin: 30px 50px;
  }
`;

const SelectStyled = styled(AsyncSelect<SelectOption, false>())`
  max-height: 44px;

  justify-self: center;
  align-self: end;

  margin-top: 20px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .${props => props.classNamePrefix}__control {
      padding: 4px 5px 4px 9px;
    }
    .${props => props.classNamePrefix}__search-icon {
      margin-right: 8px;
    }
  }
`;

export const VaccinesPublic: FC = () => {
  const isMobile = useMobile('s');
  const vaccinesList = useVaccinesList();

  const vaccineOptions = mapToSearchSelectOption(vaccinesList);

  const { setOpen } = useContextAssert(LoginModalContext);

  const loadSynonymOptions = async (inputValue: string): Promise<SelectOption[]> => {
    const synonyms = await getVaccineSynonymsPublic(inputValue);

    return synonyms
      .filter(s => vaccineOptions.some(so => so.value === s.id.toString()))
      .map(x => ({
        label: x.name,
        value: x.id.toString(),
        synonymOf: vaccinesList.find(vaccine => vaccine.id === x.id)?.name,
      }));
  };

  const { onChange, loadOptions } = useSelectSynonyms(
    'vaccines',
    vaccineOptions,
    loadSynonymOptions
  );

  return (
    <MainLayoutStyled>
      <Helmet>
        <title>Explore vaccines - GIDEON</title>
        <meta
          name="description"
          content="GIDEON vaccines database contains data on vaccines and immunoglobulins. It also includes tools to find vaccines by toxicity and contraindications."
        />
      </Helmet>
      <StyledTitle color="exploreLandingTitle" font="Quicksand">
        Explore GIDEON data on vaccines and immunoglobulins
      </StyledTitle>
      <TrendingNowStyled section="vaccines" />
      <ContentContainer>
        <InnerContainer role="group" aria-labelledby="explore-vaccines-title">
          <ExploreLayoutSelectTitle id="explore-vaccines-title" />
          <LabelStyled size="small" color="main" htmlFor="explore-select-input">
            Filter and search across all vaccine records
          </LabelStyled>
          <SelectStyled
            classNamePrefix="explore-layout-select"
            placeholder={
              isMobile ? 'Enter generic | trade name' : 'Start typing generic or trade name'
            }
            loadOptions={loadOptions}
            onChange={onChange}
            showIcon
            cacheOptions
            defaultOptions={vaccineOptions}
            showDropdown
            openMenuOnClick={false}
            id="explore-select"
            inputId="explore-select-input"
          />
        </InnerContainer>
        <ExploreLayoutInnerContent>
          <ButtonStyled Icon={ToxicityIcon} onClick={() => setOpen(true)}>
            Toxicity
          </ButtonStyled>
          <ButtonStyled Icon={ContraindicationsIcon} onClick={() => setOpen(true)}>
            Contraindications
          </ButtonStyled>
        </ExploreLayoutInnerContent>
      </ContentContainer>
      <LandingMap section="vaccines" />
    </MainLayoutStyled>
  );
};
