import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Link } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { Span } from 'Atoms/text';
import { H2 } from 'Atoms/text/H';
import {
  accountSignUp,
  // getAccountEmailExists
} from 'Auth/apiServices/account';
import { SignUpError } from 'Auth/apiServices/account.dto';
import { FormInput } from 'Auth/Molecules/FormInput';
import { FormMessage } from 'Auth/Molecules/FormMessage';
import { SocialProof } from 'Auth/Molecules/SocialProof';
import { ContentContainer, ErrorRow, Row, Title, TrialLayout } from 'Auth/Molecules/Styles';
import { emailRegex, validateEmailDomain } from 'Auth/services/validation';
import { initialState, State } from 'Auth/store/provider';
import { reducer } from 'Auth/store/reducer';
import { AxiosError } from 'axios';
import React, { FC, FormEvent, ReactNode, useCallback, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet';
import { usePushState } from 'services/usePushState.hook';
import styled from 'styled-components/macro';
// import { EmailExists } from 'types/account';

const FormContainer = styled.div`
  width: 450px;
  height: 100%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

const Form = styled.form`
  width: 700px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    position: unset;
    width: 100%;

    align-items: center;
  }
`;

const TrialFormInputSpaced = styled(FormInput)`
  margin-left: 5px;
`;

const SpecialButton = styled(FilledButton)`
  width: 450px;
  height: 44px;
  margin-top: 20px;
`;

const CancelButton = styled(FilledButton)`
  width: 450px;
  height: 44px;
  margin-left: 10px;
  margin-top: 20px;
`;

export const SignUpHome: FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);

  const { push } = usePushState();

  const validateEmail = useCallback((): string | null => {
    if (!state.email) {
      return 'Email is required';
    } else {
      const emailIsValid = state.email.match(emailRegex);

      if (!emailIsValid) {
        return 'Email is not valid';
      } else {
        const isValid = validateEmailDomain(state.email);

        if (!isValid) {
          return 'Please use an institutional email account';
        }
      }
    }

    return null;
  }, [state.email]);

  const validateForm = (): State['errors'] => {
    const errors: State['errors'] = {
      firstName: null,
      lastName: null,
      position: null,
      email: null,
      institution: null,
      howHear: null,
      form: null,
    };

    if (!state.firstName) {
      errors.firstName = 'First name is required';
    }

    if (!state.lastName) {
      errors.lastName = 'Last name is required';
    }

    if (!state.institution) {
      errors.institution = 'Institution is required';
    }

    const emailError = validateEmail();

    if (emailError) {
      errors.email = emailError;
    }

    return errors;
  };

  const getFormError = (message: string | undefined): ReactNode => {
    if (message === 'Email already exists.') {
      return (
        <Span>
          Email already exists. Click <Link to="/login">here</Link> to sign in.
        </Span>
      );
    } else if (message === 'You are not human.') {
      return 'Suspicious behaviour detected. Please try again.';
    }

    return message || 'Server error';
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const { firstName, lastName, email, position, institution, howHear } = state;

    const errors = validateForm();
    dispatch({ type: 'SignUp/Set/Errors', payload: errors });

    if (Object.values(errors).some(error => error)) {
      return;
    }

    setIsLoading(true);
    accountSignUp(firstName, lastName, position, email, institution, howHear)
      .then(() => {
        setIsLoading(false);
        push('/signup/success', { email: email });
      })
      .catch((e: AxiosError<SignUpError> | null) => {
        dispatch({
          type: 'SignUp/Set/Error',
          payload: { field: 'form', value: getFormError(e?.response?.data?.error?.message) },
        });
        setIsLoading(false);
      });
  };

  // const getEmailError = (response: EmailExists): ReactNode => {
  //   if (response.domainExists) {
  //     return (
  //       <>
  //         You have access! <Link to="/login">Sign in</Link> through your library.
  //       </>
  //     );
  //   } else if (response.exists) {
  //     return (
  //       <>
  //         This email is already in use. <Link to="/login">Sign in</Link>
  //       </>
  //     );
  //   }
  // };

  // const onEmailBlur = useCallback((): void => {
  //   const emailError = validateEmail();

  //   if (emailError) {
  //     return;
  //   }

  //   getAccountEmailExists(state.email).then(res => {
  //     if (!res.exists && !res.domainExists) {
  //       return;
  //     }

  //     const emailError = getEmailError(res);

  //     if (!emailError) {
  //       return;
  //     }

  //     dispatch({
  //       type: 'SignUp/Set/Error',
  //       payload: {
  //         field: 'email',
  //         value: emailError,
  //       },
  //     });
  //   });
  // }, [state.email, validateEmail]);

  return (
    <TrialLayout>
      <Helmet>
        <title>Trial - GIDEON</title>
      </Helmet>
      <Title color="lightLink" weight="600" size="big" font="Inter">
        Ready to get started?
      </Title>
      <H2 color="input" weight="500" size="smallRegular" font="Inter">
        Claim your free demo today!
      </H2>
      <ContentContainer>
        <FormContainer>
          <Form onSubmit={onSubmit}>
            <Row>
              <FormInput
                id="signup-first-name"
                label="First name"
                value={state.firstName}
                onChange={firstName =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'firstName', value: firstName },
                  })
                }
                error={!!state.errors.firstName}
                autoComplete="given-name"
              />
              <TrialFormInputSpaced
                id="signup-last-name"
                label="Last name"
                value={state.lastName}
                onChange={lastName =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'lastName', value: lastName },
                  })
                }
                error={!!state.errors.lastName}
                autoComplete="family-name"
              />
            </Row>
            <ErrorRow>
              <FormMessage type="error" message={state.errors.firstName} />
            </ErrorRow>
            <ErrorRow>
              <FormMessage type="error" message={state.errors.lastName} />
            </ErrorRow>
            <Row>
              <FormInput
                id="signup-position"
                label="Position"
                value={state.position}
                onChange={position =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'position', value: position },
                  })
                }
                error={!!state.errors.position}
                autoComplete="position"
              />
            </Row>
            <Row>
              <FormInput
                id="signup-insitution"
                label="Institution"
                value={state.institution}
                onChange={institution =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'institution', value: institution },
                  })
                }
                error={!!state.errors.institution}
                autoComplete="organization"
              />
            </Row>
            <ErrorRow>
              <FormMessage type="error" message={state.errors.institution} />
            </ErrorRow>
            <Row>
              <FormInput
                id="signup-email"
                label="Institutional email"
                value={state.email}
                onChange={email =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'email', value: email.trim() },
                  })
                }
                error={!!state.errors.email}
                type="email"
                autoComplete="email"
              />
            </Row>
            <ErrorRow>
              <FormMessage type="error" message={state.errors.email} />
            </ErrorRow>
            <Row>
              <FormInput
                id="signup-howHear"
                label="How did you hear about us?"
                value={state.howHear}
                onChange={howHear =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'howHear', value: howHear },
                  })
                }
                error={!!state.errors.howHear}
                autoComplete="howHear"
              />
            </Row>
            <ErrorRow>
              <FormMessage type="error" message={state.errors.form} />
            </ErrorRow>
            <Row>
              <SpecialButton color="special">
                {isLoading ? <Loader /> : "Let's connect"}
              </SpecialButton>
              <CancelButton onClick={() => push('/login')} type="button">
                {isLoading ? <Loader /> : 'Cancel'}
              </CancelButton>
            </Row>
          </Form>
        </FormContainer>
        <SocialProof />
      </ContentContainer>
    </TrialLayout>
  );
};
