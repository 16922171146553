import { Loader } from 'Atoms/Loader';
import { SurveyTable } from 'Organisms/SurveyTable';
import React, { FC } from 'react';
import { useCountryAssociatedInfectionsSurveys } from 'store/countryNotesStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';

interface Props {
  diseaseId: number;
  countryId: string;
  title?: string;
  subTitle?: string;
}

const footnote1 = '* indicates publication year (not necessarily year of survey)';

export const AssociatedInfectionsSurveys: FC<Props> = ({
  diseaseId,
  countryId,
  title,
  subTitle,
}) => {
  const surveys = useCountryAssociatedInfectionsSurveys(diseaseId, countryId);
  assertIsNotStoreError(surveys);
  if (isLoading(surveys)) {
    return <Loader />;
  }

  if (!surveys || surveys.length === 0) {
    return <></>;
  }

  return (
    <SurveyTable
      associatedInfectionsSurveyData={surveys}
      title={title}
      subTitle={subTitle}
      footnote1={footnote1}
    />
  );
};
