import { Icon } from 'Atoms/Icon';
import { P } from 'Atoms/text';
import React, { FC, ReactNode } from 'react';
import styled, { css, DefaultTheme } from 'styled-components/macro';
import { FontSizeCollection } from 'types/fontSizeCollection';

type Size = 'normal';
const sizes: FontSizeCollection<Size> = {
  normal: {
    desktop: '20px',
    mobile: '20px',
  },
};

const ButtonContainer = styled.div`
  position: relative;
  width: 100%;
  box-sizing: content-box;
  padding: 0 0 100% 0;
`;

const Button = styled.button<Props>`
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  font-family: ${props => props.theme.fontFamily[props.font || 'Inter']};
  font-weight: ${props => props.weight || '500'};
  font-size: ${props => sizes[props.size || 'normal'].desktop};

  border: 1px solid ${props => props.theme.colors.circleButton.default.borderColor};

  background-color: ${props => props.theme.colors.circleButton.default.backgroundColor};
  color: ${props => props.theme.colors.circleButton.default.textColor};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;

  cursor: pointer;

  &:focus {
    border: 2px solid ${props => props.theme.colors.focus};
    outline: none;
  }
`;

const highlightButton = css`
  ${Icon} {
    transition: fill 0.25s linear;
    fill: ${props => props.theme.colors.circleButton.hover.textColor};
  }
  transition: transform 0.25s linear, background-color 0.25s linear, border 0.25s linear;

  background-color: ${props => props.theme.colors.circleButton.hover.backgroundColor};
  color: ${props => props.theme.colors.circleButton.hover.textColor};
  font-weight: 600;
  border: 2px solid ${props => props.theme.colors.circleButton.hover.borderColor};
`;

const ActiveButton = styled(Button)<Props>`
  ${props => props.active && highlightButton};
  &:hover,
  &:active {
    ${highlightButton}
    transform: scale(1.05);
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: ${props => sizes[props.size || 'normal'].desktop};
  }
`;

const DisabledButton = styled(Button)`
  opacity: 0.2;
`;

const DisabledText = styled(P)`
  position: absolute;
  width: 120px;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

interface Props {
  className?: string;
  children?: ReactNode;
  onClick?(): void;
  font?: keyof DefaultTheme['fontFamily'];
  weight?: '400' | '500' | '600' | '700';
  size?: Size;
  disabled?: boolean;
  active?: boolean;
  ariaLabel?: string;
  disabledText?: string;
}

export const CircleButton: FC<Props> = ({
  children,
  className,
  onClick,
  font,
  weight,
  size,
  disabled,
  active,
  ariaLabel,
  disabledText,
  ...rest
}) => (
  <ButtonContainer className={className}>
    {disabled ? (
      <>
        <DisabledText size="veryBig" weight="900" color="disabledNavLink">
          {disabledText}
        </DisabledText>
        <DisabledButton size={size} font={font} weight={weight} onClick={onClick}>
          {children}
        </DisabledButton>
      </>
    ) : (
      <ActiveButton
        size={size}
        font={font}
        weight={weight}
        onClick={onClick}
        disabled={disabled}
        active={active}
        aria-label={ariaLabel}
        {...rest}
      >
        {children}
      </ActiveButton>
    )}
  </ButtonContainer>
);
