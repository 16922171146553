import { AsyncSelect } from 'Molecules/select/AsyncSelect';
import { SearchSelect } from 'Molecules/select/SearchSelect';
import React, { FC } from 'react';
import { OnChangeValue } from 'react-select';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';

export const SelectStyled = styled(AsyncSelect<SelectOption, false>())`
  min-width: 350px;
  max-width: 500px;
  .${props => props.classNamePrefix}__control {
    border-left: 1px solid ${props => props.theme.colors.separator.light};
    border-radius: 0 10px 10px 0;
    &--menu-is-open {
      border-radius: 0 10px 0 0;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    min-width: 150px;
    max-width: 350px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .${props => props.classNamePrefix}__control {
      padding: 4px 5px 4px 9px;
    }
    .${props => props.classNamePrefix}__search-icon {
      margin-right: 8px;
    }
  }
`;

const FilterSelect = styled(SearchSelect)`
  width: 200px;

  .${props => props.classNamePrefix}__control {
    border-right: 1px solid ${props => props.theme.colors.separator.light};
    border-radius: 10px 0 0 10px;
    padding: 4px 12px 4px;

    &--has-value {
      border-color: ${props => props.theme.colors.select.default.border};

      .${props => props.classNamePrefix}__dropdown-icon {
        fill: ${props => props.theme.colors.select.default.border};
      }
    }

    &:hover,
    &--is-focused {
      border-color: ${props => props.theme.colors.select.active.border};
    }

    &--menu-is-open {
      border-radius: 10px 0 0 0;
      padding: 4px 12px 6px;
    }
  }

  .${props => props.classNamePrefix}__search-icon {
    width: 20px;
    height: 20px;

    margin-right: 10px;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-width: 50px;

    .${props => props.classNamePrefix}__single-value,
      .${props => props.classNamePrefix}__dropdown-indicator {
      display: none;
    }

    .${props => props.classNamePrefix}__menu {
      border-width: 2px;
      border-radius: 10px;
      top: 100%;
      top: calc(100% - 2px);

      min-width: 200px;
    }

    .${props => props.classNamePrefix}__control {
      padding: 4px 0 4px 12px;

      &--menu-is-open {
        border-width: 2px 1px 2px 2px;
        border-radius: 10px 0 0 10px;
      }
    }
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 20px 10px 0 10px;
  }
`;

interface Props {
  className?: string;
  placeholder?: string;
  filterOptions: SelectOption[];
  filterValue: SelectOption;
  onFilterChange: (selected: OnChangeValue<SelectOption, false>) => void;
  onChange: (selected: OnChangeValue<SelectOption, false>) => void;
  defaultOptions: SelectOption[];
  loadOptions: (inputValue: string) => Promise<SelectOption[]>;
  filterAriaLabel?: string;
  selectInputId?: string;
}

export const ExploreSelectWithFilter: FC<Props> = ({
  className,
  filterOptions,
  filterValue,
  onFilterChange,
  placeholder,
  onChange,
  defaultOptions,
  loadOptions,
  filterAriaLabel,
  selectInputId,
}) => (
  <SelectWrapper className={className}>
    <FilterSelect
      placeholder="Select filter"
      options={filterOptions}
      onChange={onFilterChange}
      value={filterValue}
      showIcon
      showDropdown
      classNamePrefix="filter-select"
      isSearchable={false}
      ariaLabel={filterAriaLabel}
    />
    <SelectStyled
      classNamePrefix="search-select"
      placeholder={placeholder}
      loadOptions={loadOptions}
      onChange={onChange}
      showIcon
      cacheOptions
      defaultOptions={defaultOptions}
      showDropdown
      openMenuOnClick={false}
      inputId={selectInputId}
    />
  </SelectWrapper>
);
